import {
  Add,
  ArrowBack,
  Edit,
  Search,
  SwapHoriz,
  Verified,
  Visibility,
} from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { Trash } from "react-bootstrap-icons";
import {
  deactivateItem,
  getItems,
  verifyItem,
} from "../../../../services/AdminService";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import { useState } from "react";
import { toast } from "react-toastify";
import RightFlotingAnalyticsDashboard from "../RightFlotingAnalyticsDashboard";
import { ReplaceItemModal } from "./ReplaceItemModal";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";

export default function ViewItems({
  handelOpenAddItems,
  handelCloseViewItems,
  setEditItem,
  shouldUpdateItemList,
  closeAll,
}) {
  const loginUserDetail = GetLoginUserDetails();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const { t } = useTranslation();
  const [itemList, setItemList] = useState([]);
  const [showReplaceModal, setShowReplaceModal] = useState(false);
  const [originalItemToReplace, setOriginalItemToReplace] = useState(null);
  const [searchString, setSearchString] = useState("");

  const filteredItemList = useMemo(() => {
    if (searchString === "") {
      return [...itemList];
    }
    const temp = itemList.filter((item) => {
      return item.itemName.toLowerCase().includes(searchString.toLowerCase());
    });
    return temp;
  }, [searchString, itemList]);

  const getAllItems = () => {
    getItems(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        setItemList(response.data);
      }
    });
  };

  useEffect(() => {
    getAllItems();
  }, [shouldUpdateItemList]);

  const handleAddDeactivateItem = (itemId) => {
    deactivateItem(itemId, loginUserDetail.userId).then((response) => {
      if (response.data.returnValue === "1") {
        toast.success(t("deactivate_item_success"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        getAllItems();
      } else {
        toast.error(t("something_went_wrong"));
      }
    });
  };

  const handleClickVerifyButton = (itemId) => {
    verifyItem(itemId, loginUserDetail.userId).then((response) => {
      if (response.data.returnValue === "1") {
        toast.success(t("verify_item_sucess"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        getAllItems();
      } else {
        toast.error(t("something_went_wrong"));
      }
    });
  };

  // useEffect(()=>{
  // setBookSteps([
  //   {
  //     target: '.viewItemStepOne',
  //     content: 'This is the Items List page.',
  //   },
  //   {
  //     target: '.viewItemStepTwo',
  //     content: 'You can use this button to go back.',
  //   },
  //   {
  //     target: '.viewItemStepThree',
  //     content: 'Use this search bar to filter through your items by name.',
  //   },
  //   {
  //     target: '.viewItemStepFour',
  //     content: 'Click here to add a new item to the list.',
  //   },
  //   {
  //     target: '.viewItemStepFive',
  //     content: 'Here, you can see all the items in the list, organized by name, description, category, etc.',
  //   },
  //   {
  //     target: '.viewItemStepSeven',
  //     content: 'Use these buttons to edit or deactivate an item as necessary.',
  //   },
  //   {
  //     target: '.viewItemStepSix', 
  //     content: 'Click this button to verify the item if it meets the necessary criteria.',
  //   },
  //   {
  //     target: '.viewItemStepEight',
  //     content: 'Click here to replace an item with another from the list.',
  //   },
  // ]
  // )
  // },[])

  useEffect(() => {
    setBookSteps([
      {
        target: ".viewItemStepOne",
        content: t("view_item_step_one"),
        disableBeacon: true,
      },
      {
        target: ".viewItemStepTwo",
        content: t("view_item_step_two"),
        disableBeacon: true,
      },
      {
        target: ".viewItemStepThree",
        content: t("view_item_step_three"),
        disableBeacon: true,
      },
      {
        target: ".viewItemStepFour",
        content: t("view_item_step_four"),
        disableBeacon: true,
      },
      {
        target: ".viewItemStepFive",
        content: t("view_item_step_five"),
        disableBeacon: true,
      },
      {
        target: ".viewItemStepSix",
        content: t("view_item_step_six"),
        disableBeacon: true,
      },
      {
        target: ".viewItemStepSeven",
        content: t("view_item_step_seven"),
        disableBeacon: true,
      },
      {
        target: ".viewItemStepEight",
        content: t("view_item_step_eight"),
        disableBeacon: true,
      },
    ]);
  }, [t]);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["viewItemsDS"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="AnalyticsDashboardContainer viewItemStepOne">
        <div className="AnalyticsDashboardSubHeader viewItemStepTwo">
          <IconButton
            onClick={() => {
              handelCloseViewItems();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("items_list")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              {/* <div className="cuhederTxt">Departmment List</div> */}
              <div className="searchTasklist viewItemStepThree">
                <input
                  type="text"
                  class="serchTaskInput"
                  placeholder={t("search_items")}
                  value={searchString}
                  onChange={(e) => {
                    setSearchString(e.target.value);
                  }}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              <div className="addUnitsBtn">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn viewItemStepFour"
                  onClick={() => {
                    handelOpenAddItems();
                  }}
                >
                  {t("add_items")}
                </Button>
              </div>
            </div>

            <div className="cuContainArea FRItem">
              <div className="TableContainer">
                <table className="taskListTable viewItemStepFive">
                  <thead className="taskListtableHeader">
                    <tr>
                      <th>{t("item_name")}</th>
                      <th>{t("description")}</th>
                      <th>{t("category")}</th>
                      <th>{t("type")}</th>
                      <th>{t("hsncd")}</th>
                      <th>{t("uom")}</th>
                      <th>{t("manufacturer")}</th>
                      <th>{t("expirable")}</th>
                      <th>{t("status")}</th>
                      <th className="width100"></th>
                    </tr>
                  </thead>
                  <tbody className="scrolableTableBody">
                    {filteredItemList &&
                      filteredItemList.map((item) => (
                        <>
                          <tr>
                            <td>
                              <div className="tskLstTaskNM">
                                {item.itemName}
                              </div>
                            </td>
                            <td>
                              <Tooltip title={item.itemName} arrow>
                                <div className="tskLstTaskDescription">
                                  {item.itemDescription}
                                </div>
                              </Tooltip>
                            </td>
                            <td>
                              <div className="tskLstTaskNM">
                                {item.category}
                              </div>
                            </td>
                            <td>
                              <div className="tskLstTaskNM">{item.type}</div>
                            </td>
                            <td>
                              <div className="tskLstTaskNM">{item.hsnCd}</div>
                            </td>
                            <td>
                              <div className="tskLstTaskNM">
                                {item.defaultUom}
                              </div>
                            </td>
                            <td>
                              <div className="tskLstTaskNM">
                                {item.manufacturer}
                              </div>
                            </td>
                            <td>
                              <div className="tskLstTaskNM">
                                {item.expirable === "Y" ? t("yes") : t("no")}
                              </div>
                            </td>

                            {item.verified === "Y" && (
                              <>
                                <td>
                                  <div className="tskLstTaskNM">
                                    <Verified />
                                    {t("verified")}
                                  </div>
                                </td>

                                <td>
                                  <div className="tblActionBtnGrp viewItemStepSeven">
                                    <IconButton
                                      onClick={(e) => {
                                        console.log("hello");
                                        setEditItem(item);
                                        handelOpenAddItems();
                                      }}
                                    >
                                      <Edit />
                                    </IconButton>
                                    <IconButton
                                      className="removeRowBtn"
                                      onClick={(e) => {
                                        handleAddDeactivateItem(item.itemId);
                                      }}
                                    >
                                      <Trash />
                                    </IconButton>
                                  </div>
                                </td>
                              </>
                            )}

                            {item.verified === "P" && (
                              <>
                                <td>
                                  <div className="tskLstTaskNM">
                                    <Button
                                      className="addNRwBtn viewItemStepSix"
                                      onClick={(e) => {
                                        handleClickVerifyButton(item.itemId);
                                      }}
                                    >
                                      {t("verify")}
                                    </Button>
                                  </div>
                                </td>
                                <td>
                                  <div className="tblActionBtnGrp">
                                    <Button
                                      className="addNRwBtn viewItemStepEight"
                                      startIcon={<SwapHoriz />}
                                      onClick={(e) => {
                                        setShowReplaceModal(true);
                                        setOriginalItemToReplace(item);
                                      }}
                                    >
                                      {t("replace")}
                                    </Button>
                                  </div>
                                </td>
                              </>
                            )}
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {showReplaceModal && (
          <RightFlotingAnalyticsDashboard>
            <ReplaceItemModal
              handelCloseReplaceItems={(e) => {
                setShowReplaceModal(false);
              }}
              itemList={itemList}
              originalItem={originalItemToReplace}
              refreshData={() => {
                getAllItems();
              }}
            />
          </RightFlotingAnalyticsDashboard>
        )}
      </div>
    </>
  );
}
