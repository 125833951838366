import { Add, Edit, Search } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Modal,
  Switch,
} from "@mui/material";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getOtpServices,
  activeDeactiveOtpService,
  getActiveOtpServices,
} from "../../../../services/AdminService";
import { checkOtp } from "../../../../utils/OtpServiceUtils";
import { useTranslation } from "react-i18next";
import SecureIndexedDB from "../../../../utils/IndexedDB";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";

export default function DefineOtpService() {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [otpData, setOtpData] = useState([]);
  const [otpFilterData, setOtpFilterData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  // const [otp, setOtp] = useState("5678");

  useEffect(() => {
    fetchlogData();
  }, [userDetails.orgId]);

  const fetchlogData = () => {
    if (userDetails.orgId) {
      getOtpServices(userDetails.orgId)
        .then((response) => {
          console.log("OTP Data ======", response.data);
          setOtpData(response.data);
          setOtpFilterData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching OTP data:", error);
        });
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = otpData.filter(
      (otp) =>
        otp.otpServiceName &&
        otp.otpServiceName.toLowerCase().includes(query.toLowerCase())
    );

    setOtpFilterData(filteredData);
  };

  useEffect(() => {
    setBookSteps([
      {
        target: ".viewOtpServiceStepOne",
        content: t('view_otp_service_step_one'),
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".viewOtpServiceStepTwo",
        content: t('view_otp_service_step_two'),
        placement: "right",
        disableBeacon: true,
      },
      {
        target: ".viewOtpServiceStepThree",
        content: t('view_otp_service_step_three'),
        placement: "left",
        disableBeacon: true,
      },
    ]);
  }, [t]);

  // useEffect(()=>{
  //   setBookSteps([
  //     {
  //       target: '.viewOtpServiceStepOne',
  //       content: 'Use this search bar to quickly find OTP services by name.',
  //       placement: 'bottom',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.viewOtpServiceStepTwo',
  //       content: 'Here, you can see all OTP services and manage their statuses.',
  //       placement: 'right',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.viewOtpServiceStepThree',
  //       content: 'Toggle this switch to activate or deactivate the OTP service.',
  //       placement: 'left',
  //       disableBeacon: true,
  //     },
  //     // {
  //     //   target: '.scrolableTableBody',
  //     //   content: 'Each row represents an OTP service. You can manage it by toggling the status.',
  //     //   placement: 'bottom',
  //     //   disableBeacon: true,
  //     // },
  //   ]);
    
  // },[])

  // const handleSwitchChange = (data) =>{
  //   console.log('data' , data);
  //   const reqDto = {
  //       otpServiceId: data.otpServiceId,
  //       otp: otp ,
  //       activeFlag: data.activeFlag
  //     }
  //     console.log('reqDto' , reqDto);

  //     activeDeactiveOtpService(userDetails.userId , reqDto)
  //     .then((response)=>{
  //       console.log('response' , response.data)
  //       if (response.data.returnValue === "1") {
  //           toast.success(response.data.message, {
  //             position: toast.POSITION.TOP_RIGHT,
  //           });
  //           fetchlogData();
  //       }
  //     })

  // }

  const fetchActiveOtpData = () => {
    if (userDetails.orgId) {
      getActiveOtpServices(userDetails.orgId).then((response) => {
        console.log("responseotpservicedata ===", response.data);
        if (Array.isArray(response.data) && response.data.length > 0) {
          SecureIndexedDB.setItem("otpServices", JSON.stringify(response.data));
        }
      });
    }
  };

  const handleSwitchChange = async (data) => {
    try {
      console.log("data", data);

      const { otp, requestId } = await checkOtp(
        "/otp/activeDeactiveOtpService"
      );
      console.log("salaryComponentOTP ------->>>>", otp, requestId);

      const reqDto = {
        otpServiceId: data.otpServiceId,
        otp: otp,
        activeFlag: data.activeFlag === "Y" ? "N" : "Y",
      };
      console.log("reqDto", reqDto);

      const response = await activeDeactiveOtpService(
        userDetails.userId,
        reqDto
      );

      if (response.data.returnValue === "1") {
        toast.success(t("record_updated_successfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        fetchlogData();
        fetchActiveOtpData();
      } else {
        toast.error(t("failed_to_update_otp_service_status"));
      }
    } catch (error) {
      console.error("Error updating OTP service status", error);
      toast.error(t("an_error_occurred_while_updating_the_otp_service_status"));
    }
  };

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
     <Joyride
        steps={booksteps}
        run={run["viewOTPServiceAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="fullContainArea">
        <div className="cuHeaderGrp">
          <div className="searchTasklist viewOtpServiceStepOne">
            <input
              type="text"
              className="serchTaskInput "
              placeholder={t("search_service")}
              value={searchQuery}
              onChange={handleSearch}
            />
            <IconButton className="seacrhIcon">
              <Search />
            </IconButton>
          </div>
        </div>

        <div className="cuContainArea">
          <div className="TableContainer">
            <table className="taskListTable viewOtpServiceStepTwo">
              <thead className="taskListtableHeader">
                <tr>
                  <th>{t("otp_service_name")}</th>
                  {/* <th>{t("api_name")}</th> */}
                  <th className="width100">{t("action")}</th>
                </tr>
              </thead>
              <tbody className="scrolableTableBody">
                {otpFilterData.map((service) => (
                  <tr key={service.otpServiceId}>
                    <td>
                      <div className="tskLstTaskNM">
                        {service.otpServiceName}
                      </div>
                    </td>
                    {/* <td>
                      <div className="tskLstTaskNM">{service.apiName}</div>
                    </td> */}
                    <td>
                      <div className="tblActionBtnGrp">
                        <FormControlLabel
                          className="couponStatusSwitch viewOtpServiceStepThree"
                          control={
                            <Switch
                              checked={service.activeFlag === "Y"}
                              onChange={() => handleSwitchChange(service)}
                              color="primary"
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
