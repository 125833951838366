import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { lazy, useContext, useEffect, useState } from "react";
import "../css/analyticsDashboard.css";
import RightFlotingAnalyticsDashboard from "./RightFlotingAnalyticsDashboard";
import AddBudget from "./component/AddBudget";
import AddItems from "./component/AddItems";
import AddLeaveRules from "./component/AddLeaveRules";
import AddPeople from "./component/AddPeople";
import AddRoutineWork from "./component/AddRoutineWork";
import EditLeaveRules from "./component/EditLeaveRules";
import ViewItems from "./component/ViewItems";
import ViewLeaveRules from "./component/ViewLeaveRules";
import ViewPeople from "./component/ViewPeople";
import ViewRoutineWork from "./component/ViewRoutineWork";
import CardAttendance from "./component/card/CardAttendance";
import CardBudget from "./component/card/CardBudget";
import CardItems from "./component/card/CardItems";
import CardLeaveRules from "./component/card/CardLeaveRules";
import CardPeople from "./component/card/CardPeople";
import CardRoutineWork from "./component/card/CardRoutineWork";
// import ViewAttendance from "./component/ViewAttendance";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import {
  getDashboardDataNew,
  getMenusWithModule,
  getUserMenus,
} from "../../../services/AdminService";
import AddContract from "./component/AddContract";
import AddExternalUser from "./component/AddExternalUser";
import AddHoliday from "./component/AddHoliday";
import AddShift from "./component/AddShift";
import AddWorkingDays from "./component/AddWorkingDays";
import EditExternalUser from "./component/EditExternalUser";
import EditHoliday from "./component/EditHoliday";
import EditPeople from "./component/EditPeople";
import SelectedViewBudget from "./component/SelectedViewBudget";
import SelectedViewLeaveRule from "./component/SelectedViewLeaveRule";
import ViewBudget from "./component/ViewBudget";
import ViewContract from "./component/ViewContract";
import ViewExternalUser from "./component/ViewExternalUser";
import ViewHolidayWorkingday from "./component/ViewHolidayWorkingday";
import ViewPayroll from "./component/ViewPayroll";
import ViewPayrollPayslip from "./component/ViewPayrollPayslip";
import ViewPayrollSalaryStructure from "./component/ViewPayrollSalaryStructure";
import ViewSelectedHoliday from "./component/ViewSelectedHoliday";
import ViewUserAttendance from "./component/ViewUserAttendance";
import CardContract from "./component/card/CardContract";
import CardExternalUser from "./component/card/CardExternalUser";
import CardHolidayWorkingday from "./component/card/CardHolidayWorkingday";
import CardPayroll from "./component/card/CardPayroll";
import AddAppointmentSettings from "./component/healthcare/AddAppointmentSettings";
import AddBookConsultation from "./component/healthcare/AddBookConsultation";
import AddRegisterPatient from "./component/healthcare/AddRegisterPatient";
import CardBookAppointment from "./component/healthcare/CardBookAppointment";
import CardBookConsultation from "./component/healthcare/CardBookConsultation";
import CardPatientDocument from "./component/healthcare/CardPatientDocument";
import CardRegisterPatient from "./component/healthcare/CardRegisterPatient";
import ViewBookAppointmentMoreSlots from "./component/healthcare/ViewBookAppointmentMoreSlots";
import ViewBookConsultation from "./component/healthcare/ViewBookConsultation";
import ViewPatientDocument from "./component/healthcare/ViewPatientDocument";

import DashboardServices from "../../../services/DashboardServices";
import CardWebsiteStatus from "./component/card/CardWebsiteStatus";
import CardWebsiteStatusUptimeDowntime from "./component/card/CardWebsiteStatusUptimeDowntime";
import AddAppointmentBooking from "./component/healthcare/AddAppointmentBooking";
import AddInvestigationAdmin from "./component/healthcare/AddInvestigationAdmin";
import AddInvestigationAdminUnit from "./component/healthcare/AddInvestigationAdminUnit";
import AddInvestigationParameter from "./component/healthcare/AddInvestigationParameter";
import AddInvestigationUnits from "./component/healthcare/AddInvestigationUnits";
import AddNewAppointBooking from "./component/healthcare/AddNewAppointBooking";
import { AddReviewPatientToQueue } from "./component/healthcare/AddReviewPatientToQueue";
import { AddTestData } from "./component/healthcare/AddTestData";
import CardDoctorConsultations from "./component/healthcare/CardDoctorConsultations";
import CardDrBookAppointment from "./component/healthcare/CardDrBookAppointment";
import CardInvestigationAdmin from "./component/healthcare/CardInvestigationAdmin";
import CardInvestigationUnits from "./component/healthcare/CardInvestigationUnits";
import CardManagementCenter from "./component/healthcare/CardManagementCenter";
import CardManagementConsultation from "./component/healthcare/CardManagementConsultation";
import CardManagementDisease from "./component/healthcare/CardManagementDisease";
import CardManagementDoctor from "./component/healthcare/CardManagementDoctor";
import CardManagementFeedback from "./component/healthcare/CardManagementFeedback";
import CardManagementTrends from "./component/healthcare/CardManagementTrends";

import CardReviewReferredPatient from "./component/healthcare/CardReviewReferredPatient";
import { Conversation } from "./component/healthcare/DoctorPatientConversation";
import ResheduleDoctorAppoinment from "./component/healthcare/ResheduleDoctorAppoinment";
import ViewBookAppointmentDrList from "./component/healthcare/ViewBookAppointmentDrList";
import ViewBookedAppointmentConsultation from "./component/healthcare/ViewBookedAppointmentConsultation";
import ViewDoctorConsultationList from "./component/healthcare/ViewDoctorConsultationList";
import ViewInvestigationAdmin from "./component/healthcare/ViewInvestigationAdmin";
import ViewNewAppointmentBooked from "./component/healthcare/ViewNewAppointmentBooked";
import ViewReferredPatient from "./component/healthcare/ViewReferredPatient";
import ViewReviewPatient from "./component/healthcare/ViewReviewPatient";

import { TourContext } from "../../../common/TourProvider";
import CardManagementSupportTicket from "./component/healthcare/CardManagementSupportTicket";
import ViewManagementAnalyticDashboard from "./component/healthcare/ViewManagementAnalyticDashboard";
import SecureIndexedDB from "../../../utils/IndexedDB";
import { GetLoginUserDetails, isLocalHost } from "../../../utils/Utils";
import { webApp } from "../../../constants/url_provider";
import CardTask from "./component/card/CardTask";
import DateUtils from "../../../utils/DateUtils";
import AppUtils from "../../../utils/AppUtils";

const ViewAttendance = lazy(() => import("./component/ViewAttendance"));

export default function AnalyticsDashboard() {
  const userDetails = GetLoginUserDetails();
  const loginUser = GetLoginUserDetails();

  const { t } = useTranslation();

  const [chartDataOfItem, setChartDataOfItem] = useState({});
  const [menuModuleList, setMenuModuleList] = useState(false);
  const [userMenuMap, setUserMenuMap] = useState(null);
  const [userMenuList, setUserMenuList] = useState(false);
  const [dataForpatient, setDataForPatient] = useState({
    patientGenderWiseRegistration: {},
    patientAgeGroupWiseRegistration: {},
    totalPatientRegistered: 0,
    totalPatientRegisteredThisMnth: 0,
    todayHourlyUnitAppointment: [],
    todayDoctorWiseUnitAppointment: [],
    todayUnitAppointmentCount: 0,
    todaySpecilizationWiseUnitConsultation: {},
    dayWiseUnitConsultation: [],
    thisMnthUnitConsultation: 0,
    todayUnitConsultation: 0,
    todayUnitQueue: 0,
  });

  const [reviewConsultationData, setReviewConsultationData] = useState(null);
  const [conversationData, setConversationData] = useState(null);
  const [primaryPosdate, setPrimaryPosdate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 90))
  );
  const [pdoctorPrimaryPosdate, setDoctorPrimaryPosdate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [secondaryPosdate, setSecondaryPosdate] = useState(new Date());

  const [routineWorkExpanded, setRoutineWorkExpanded] = useState(true);
  const [selectedContractData, setSelectedContractData] = useState(null);

  const [TaskCardprimaryPosdate, setTackCardPrimaryPosdate] = useState(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 3);
    return date;
  });

  const [filterBy, setFilterBy] = useState("Daily");
  const [filterByUnitDoc, setFilterByUnitDoc] = useState("Unit");

  useEffect(() => {
    // fetchDashBoardData();
    fetchDashBoardDataForPatient();
    // fetchrefreshUnitInvestigationData();
  }, [userDetails.orgId]);

  useEffect(() => {
    fetchMenuData();
  }, []);

  const fetchMenuData = () => {
    getMenusWithModule().then((menusModuleResponse) => {
      setMenuModuleList(menusModuleResponse.data);
      getUserMenus(userDetails.userId).then((userMenuResponse) => {
        const map = new Map();
        userMenuResponse.data.forEach((item) => {
          const foundItem = menusModuleResponse.data.find(
            (element) => element.menuId === item.menuId
          );
          if (!map.has(foundItem.moduleId)) {
            map.set(foundItem.moduleId, [item.menuId]);
          } else {
            map.get(foundItem.moduleId).push(item.menuId);
          }
        });

        // if(map.has())
        setUserMenuMap(map);
        setUserMenuList(userMenuResponse.data);
      });
    });
  };

  // const fetchDashBoardData = () => {
  //   getDashboardDataOfItem(userDetails.orgId, userDetails.userId).then(
  //     (response) => {
  //
  //       setChartDataOfItem(response.data);
  //     }
  //   );
  // };

  // const fetchDashBoardData = () => {
  //   const reqDto = {
  //     unitId:
  //       userDetails?.category === "Management" ? 0 : userDetails.signleUnitId,
  //     orgId: userDetails.orgId,
  //     userId: userDetails.userId,
  //     periodDailyMnthly: "Daily",
  //     dataLimit: 12,
  //   };
  //   getDashboardDataNew(reqDto).then((response) => {
  //     setChartDataOfItem(response.data || []);
  //   });
  // };

  const fetchDashBoardDataForPatient = () => {
    const reqDto = {
      unitId: userDetails.signleUnitId,
      orgId: userDetails.orgId,
      userId: userDetails.userId,
      periodDailyMnthly: "Daily",
      dataLimit: 12,
      taskOrgId: userDetails.orgId,
      taskStartDate: DateUtils.getDateInDDMMYYYY(TaskCardprimaryPosdate),
      taskCategory: "P",
      ticketStatus: "Open",
      ticketOrgId: userDetails.orgId,
      diseaseFromDate: DateUtils.getDateInDDMMYYYY(primaryPosdate),
      diseaseToDate: DateUtils.getDateInDDMMYYYY(secondaryPosdate),
      diseaseOrgId: userDetails.orgId,
      externalOrgId: userDetails.orgId,
      contractOrgId: userDetails.orgId,
      feedbackOrgId: userDetails.orgId,
      holidayOrgId: userDetails.orgId,
      employeeOrgId: userDetails.orgId,
      unitOrgId: userDetails.orgId,
      trendsPeriodDailyMnthly: filterBy,
      trendsOrgId: userDetails.orgId,
      trendsUnitDoctor: filterByUnitDoc,
      doctorOrgId: userDetails.orgId,
      doctorFromDate: DateUtils.getDateInDDMMYYYY(pdoctorPrimaryPosdate),
      doctorToDate: DateUtils.getDateInDDMMYYYY(secondaryPosdate),
      consultationOrgId: userDetails.orgId,
      consultationPeriodDailyMnthly: filterBy,
      consultationDataLimit: 0,
      consultationUnitId: 0,
      referReviewUnitId: userDetails.signleUnitId,
      investigationUnitId: userDetails.signleUnitId,
      registerPatientUnitId: userDetails.signleUnitId,
      unitConsultationUnitId: userDetails.signleUnitId,
      documentUnitId: userDetails.signleUnitId,
      appointmentUnitId: userDetails.signleUnitId,
      feedbackFromPatientDoctor: "Patient",
      appointmentDoctorUserId: userDetails.userId,
      consultationDoctorUserId: userDetails.userId,
    };
    getDashboardDataNew(reqDto).then((response) => {
      setChartDataOfItem(response.data || {});
      setDataForPatient({
        patientGenderWiseRegistration:
          response.data.patientGenderWiseRegistration,
        patientAgeGroupWiseRegistration:
          response.data.patientAgeGroupWiseRegistration,
        totalPatientRegistered: response.data.totalPatientRegistered,
        totalPatientRegisteredThisMnth:
          response.data.totalPatientRegisteredThisMnth,
        todayHourlyUnitAppointment: response.data.todayHourlyUnitAppointment,
        todayDoctorWiseUnitAppointment:
          response.data.todayDoctorWiseUnitAppointment,
        todayUnitAppointmentCount: response.data.todayUnitAppointmentCount,
        todaySpecilizationWiseUnitConsultation:
          response.data.todaySpecilizationWiseUnitConsultation,
        dayWiseUnitConsultation: response.data.dayWiseUnitConsultation,
        thisMnthUnitConsultation: response.data.thisMnthUnitConsultation,
        todayUnitConsultation: response.data.todayUnitConsultation,
        todayUnitQueue: response.data.todayUnitQueue,
        todayUnitRxMedInvRefCount: response.data.todayUnitRxMedInvRefCount,
        todayUnitDiagonistic: response.data.todayUnitDiagonistic,
        todayDoctorRxMedInvRefCount: response.data.todayDoctorRxMedInvRefCount,
        dayWiseDoctorConsultation: response.data.dayWiseDoctorConsultation,
        docAppointment: response.data.docAppointment,
        todayHourlyDoctorAppointment:
          response.data.todayHourlyDoctorAppointment,
        todayDoctorAppointmentCount: response.data.todayDoctorAppointmentCount,
        todayUnitWiseTestCount: response.data.todayUnitWiseTestCount,
      });
    });
  };

  // const [refreshUnitInvestigationData, setrefreshUnitInvestigationData] =
  //   useState([]);
  // const [unitRefreshReferReviewData, setUnitRefreshReferReviewData] = useState(
  //   []
  // );

  const [showAnalyticsDashboard, setAnalyticsDashboard] = useState(true);
  const [showViewPeople, setViewPeople] = useState(false);
  const [showViewPolicies, setViewPolicies] = useState(false);
  const [showAddPeople, setAddPeople] = useState(false);
  const [showAddLeaveRules, setAddLeaveRules] = useState(false);
  const [showViewLeaveRules, setViewLeaveRules] = useState(false);
  const [showAddBudget, setAddBudget] = useState(false);
  const [showViewBudget, setViewBudget] = useState(false);
  // const [showAddPolicies, setAddPolicies] = useState(false);

  const [showViewRoutineWork, setViewRoutineWork] = useState(false);
  const [showAddRoutineWork, setAddRoutineWork] = useState(false);

  const [showViewItems, setViewItems] = useState(false);
  const [showAddItems, setAddItems] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [shouldItemUpdateList, setShouldItemUpdateList] = useState(1);
  const [shouldUpdateQueueList, setShouldUpdateQueueList] = useState(1);

  const [showViewAttendance, setViewAttendance] = useState(false);
  const [viewUserAttendanceData, setShowViewUserAttendanceData] =
    useState(null);

  const [showViewHoliday, setViewHoliday] = useState(false);
  const [showAddHoliday, setAddHoliday] = useState(false);
  const [showAddWorkingday, setAddWorkingday] = useState(false);
  const [showEditHoliday, setEditHoliday] = useState(false);
  const [selecttedHolidayData, setSelectedHolidayData] = useState(null);
  const [refreshHoliday, setRefreshHoliday] = useState(false);
  const [showSelectedViewHoliday, setShowSelectedViewHoliday] = useState(false);
  const [selecttedViewHolidayData, setSelectedViewHolidayData] = useState(null);

  const [showViewExternalUser, setViewExternalUser] = useState(false);
  const [showAddExternalUser, setAddExternalUser] = useState(false);

  const [refreshBudget, setRefreshBudget] = useState(false);
  const [viewSelectedBudget, setViewSelectedBudget] = useState(false);
  const [viewSelectedBudgetData, setViewSelectedBudgetData] = useState(null);
  const [selectedDocId, setSelectedDocId] = useState("");
  const [refreshReschduleDocAppoint, setRefreshReschduleDocAppoint] =
    useState(false);

  const [refreshBookedAppoinments, setRefreshBookedAppoinments] = useState(1);
  const [viewNewAppointMentBookedVal, setViewNewAppointMentBooked] =
    useState(false);

  const refreshData = () => {
    setRefresh((prevRefresh) => !prevRefresh);
  };
  //ExternalUser start
  const [refreshExternalUser, setRefreshExternalUser] = useState(false);

  const refreshExternalUserData = () => {
    setRefreshExternalUser(
      (prevRefreshExternalUser) => !prevRefreshExternalUser
    );
  };

  const handelOpenViewExternalUser = () => {
    setViewExternalUser(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewExternalUser = () => {
    setViewExternalUser(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenAddExternalUser = () => {
    setAddExternalUser(true);
  };
  const handelCloseAddExternalUser = () => {
    setAddExternalUser(false);
  };

  const [showSelectedExternalUser, setShowSelectedExternalUser] =
    useState(false);
  const [selecttedViewExternalUser, setSelectedViewExternalUser] =
    useState(null);

  const handelOpenEditExternaluser = (userData) => {
    setSelectedViewExternalUser(userData);
    setShowSelectedExternalUser(true);
  };

  const handelCloseEditExternalUser = () => {
    setShowSelectedExternalUser(false);
    setSelectedViewExternalUser(null);
  };

  //ExternalUser end
  // holiday start

  const refresHolidayData = () => {
    setRefreshHoliday((prevRefreshHoliday) => !prevRefreshHoliday);
  };

  const handelOpenViewHoliday = () => {
    setViewHoliday(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewHoliday = () => {
    setViewHoliday(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenAddHoliday = () => {
    setAddHoliday(true);
  };
  const handelCloseAddHoliday = () => {
    setAddHoliday(false);
  };

  const handelOpenEditHoliday = (holidayData) => {
    setSelectedHolidayData(holidayData);
    setEditHoliday(true);
  };

  const handelCloseEditHoliday = () => {
    setEditHoliday(false);
    setSelectedHolidayData(null);
  };

  const handelOpenSelectedviewHoliday = (holidayData) => {
    setSelectedViewHolidayData(holidayData);
    setShowSelectedViewHoliday(true);
  };

  const handelCloseSelectedViewHoliday = () => {
    setShowSelectedViewHoliday(false);
    setSelectedViewHolidayData(null);
  };

  const handelOpenAddWorkingday = () => {
    setAddWorkingday(true);
  };
  const handelCloseAddWorkingday = () => {
    setAddWorkingday(false);
  };

  const [addWorkingShift, setAddWorkingShift] = useState(false);

  const handleOpenAddWorkingShift = () => {
    setAddWorkingShift(true);
  };

  const handleCloseAddWorkingShift = () => {
    setAddWorkingShift(false);
  };
  // holiday end

  // attandance start
  const handelOpenViewAttendance = () => {
    setViewAttendance(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewAttendance = () => {
    setViewAttendance(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenViewUserAttend = (data) => {
    setShowViewUserAttendanceData(data);
    setViewAttendance(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewUserAttend = () => {
    setShowViewUserAttendanceData(null);
    setViewAttendance(true);
    setAnalyticsDashboard(false);
  };
  // attandance end

  // add items start
  const handelOpenAddItems = () => {
    setAddItems(true);
  };
  const handelCloseAddItems = () => {
    setAddItems(false);
  };

  const handelOpenViewItems = () => {
    setViewItems(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewItems = () => {
    setViewItems(false);
    setAnalyticsDashboard(true);
  };
  // add items end
  // recurring task start
  const handelOpenAddRoutineWork = () => {
    setAddRoutineWork(true);
  };
  const handelCloseAddRoutineWork = () => {
    setAddRoutineWork(false);
  };

  const handelOpenViewRoutineWork = () => {
    setViewRoutineWork(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewRoutineWork = () => {
    setViewRoutineWork(false);
    setAnalyticsDashboard(true);
  };
  // recurring task end
  // const handelOpenAddPolicies = () => {
  //   setAddPolicies(true);
  // };
  // const handelCloseAddPolicies = () => {
  //   setAddPolicies(false);
  // };

  const handelOpenViewPolicies = () => {
    setViewPolicies(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewPolicies = () => {
    setViewPolicies(false);
    setAnalyticsDashboard(true);
  };

  const [refreshViewPeople, setRefreshViewPeople] = useState(false);

  const refreshViewPeopleData = () => {
    setRefreshViewPeople((prevRefreshViewPeople) => !prevRefreshViewPeople);
  };

  const handelOpenViewPeople = () => {
    setViewPeople(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewPeople = () => {
    setViewPeople(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenAddPeople = () => {
    setAddPeople(true);
  };
  const handelCloseAddPeople = () => {
    setAddPeople(false);
  };

  const [editEmployee, setEditEmployee] = useState(false);
  const [editEmployeesData, setEditEmployeesData] = useState(null);

  const handelOpenEditEmployee = (userData) => {
    setEditEmployeesData(userData);
    setEditEmployee(true);
  };

  const handelCloseEditEmployee = () => {
    setEditEmployee(false);
    setEditEmployeesData(null);
  };

  const [refreshAddLeaveRules, setRefreshAddLeaveRules] = useState(false);

  const refreshAddLeaveRulesData = () => {
    setRefreshAddLeaveRules(
      (prevRefreshAddLeaveRules) => !prevRefreshAddLeaveRules
    );
  };

  const handelOpenAddLeaveRules = () => {
    setAddLeaveRules(true);
  };
  const handelCloseAddLeaveRules = () => {
    setAddLeaveRules(false);
  };

  const [updateLeaveRule, setUpdateLeaverule] = useState(false);
  const [selectedUpdateLeaveRule, setSelectedUpdateLeaveRule] = useState(null);

  const handleCloseUpdateLeaveRule = () => {
    setUpdateLeaverule(false);
    setSelectedUpdateLeaveRule(null);
  };
  const handleOpenUpdateLeaveRule = (userData) => {
    setSelectedUpdateLeaveRule(userData);
    setUpdateLeaverule(true);
  };

  const [viewSelectedLeaveRule, setViewSelectedLeaveRule] = useState(false);
  const [viewSelectedLeaveRuleData, setViewSelectedLeaveRuleData] =
    useState(null);

  const handleCloseViewSelectedLeaveRule = () => {
    setViewSelectedLeaveRule(false);
    setViewSelectedLeaveRuleData(null);
  };
  const handleOpenViewSelectedLeaveRule = (userData) => {
    setViewSelectedLeaveRuleData(userData);
    setViewSelectedLeaveRule(true);
  };

  const handelOpenViewLeaveRules = () => {
    setViewLeaveRules(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewLeaveRules = () => {
    setViewLeaveRules(false);
    setAnalyticsDashboard(true);
  };

  const handleCloseViewSelectedBudget = () => {
    setViewSelectedBudget(false);
    setViewSelectedBudgetData(null);
  };
  const handleOpenViewSelectedBudget = (userData) => {
    setViewSelectedBudgetData(userData);
    setViewSelectedBudget(true);
  };

  const refreshBudgetData = () => {
    setRefreshBudget((prevRefreshBudget) => !prevRefreshBudget);
  };

  const handelOpenAddBudget = () => {
    setAddBudget(true);
  };
  const handelCloseAddBudget = () => {
    setAddBudget(false);
  };

  const handelOpenViewBudget = () => {
    setViewBudget(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewBudget = () => {
    setViewBudget(false);
    setAnalyticsDashboard(true);
  };

  const [showViewContract, setViewContract] = useState(false);
  const [showAddContract, setAddContract] = useState(false);

  const [showViewPayroll, setViewPayroll] = useState(false);
  const [showDetailPayroll, setDetailPayroll] = useState(false);
  const [showPayrollPayslip, setPayrollPayslip] = useState(false);
  const [selectedEmPayroll, setSelectedEmPayroll] = useState(null);

  const [refreshContract, setRefreshContract] = useState(false);

  const refreshContractData = () => {
    setRefreshContract((prevRefreshContract) => !prevRefreshContract);
  };

  const handelOpenViewPayroll = () => {
    setViewPayroll(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewPayroll = () => {
    setViewPayroll(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenDetailPayroll = (data) => {
    setDetailPayroll(true);
    setSelectedEmPayroll(data);
  };
  const handelCloseDetailPayroll = () => {
    setDetailPayroll(false);
    setSelectedEmPayroll(null);
  };
  const handelOpenPayrollPayslip = () => {
    setPayrollPayslip(true);
  };
  const handelClosePayrollPayslip = () => {
    setPayrollPayslip(false);
  };

  const handelOpenViewContract = () => {
    setViewContract(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewContract = () => {
    setViewContract(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenAddContract = () => {
    setAddContract(true);
  };
  const handelCloseAddContract = () => {
    setAddContract(false);
  };

  const closeAll = () => {
    setAddPeople(false);
    setAddLeaveRules(false);
    setUpdateLeaverule(false);
    setViewSelectedLeaveRule(false);
    setAddBudget(false);
    setAddItems(false);
    setAddRoutineWork(false);
    setAddHoliday(false);
    setEditHoliday(false);
    setShowSelectedViewHoliday(false);
    setAddWorkingday(false);
    setAddWorkingShift(false);
    setAddExternalUser(false);
    setShowSelectedExternalUser(false);
    setAddContract(false);
    setEditEmployee(false);
    setDetailPayroll(false);
  };

  const [refreshEmployPayroll, setRefreshEmployPayroll] = useState(false);

  const refreshEmployPayrollData = () => {
    setRefreshEmployPayroll(
      (prevRefreshEmployPayroll) => !prevRefreshEmployPayroll
    );
  };
  // healthcare coponent start ///////////////////////////////////////////////////////////
  const [viewRegisterPatient, setViewRegisterPatient] = useState(false);
  const [addRegisterPatient, setAddRegisterPatient] = useState(false);
  const [addAppointmentSettings, setAddAppointmentSettings] = useState(false);
  const [viewBookConsultation, setViewBookConsultation] = useState(false);
  const [viewPatientDocument, setViewPatientDocument] = useState(false);
  const [addBookConsultation, setAddBookConsultation] = useState(false);
  const [viewBookAppointment, setViewBookAppointment] = useState(false);
  const [viewBookAppointmentDrList, setViewBookAppointmentDrList] =
    useState(false);
  const [addAppointmentBooking, setAddAppointmentBooking] = useState(false);
  const [appoinmentData, setAppoinmentData] = useState(null);
  const [
    viewBookedAppointmentConsultation,
    setViewBookedAppointmentConsultation,
  ] = useState(false);
  const [openAddTestData, setOpenAddTestData] = useState(false);
  const [consultationData, setConsultationData] = useState();
  const handleOpenAddTestResult = (data) => {
    setOpenAddTestData(true);
    setConsultationData(data);
  };
  const handleCloseAddTestResult = () => {
    setOpenAddTestData(false);
  };
  const handelOpenViewRegisterPatient = () => {
    setViewRegisterPatient(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewRegisterPatient = () => {
    setViewRegisterPatient(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenViewBookAppointment = () => {
    setViewBookAppointment(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewBookAppointment = () => {
    setViewBookAppointment(false);
    setAnalyticsDashboard(false);
    setViewBookAppointmentDrList(true);
  };

  const handelCloseAllViewBookAppointment = () => {
    setViewBookAppointment(false);
    setViewBookAppointmentDrList(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenViewBookAppointmentDrList = () => {
    setViewBookAppointmentDrList(true);
    setAnalyticsDashboard(false);
  };

  const handelCloseViewBookAppointmentDrList = () => {
    setViewBookAppointmentDrList(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenViewBookedAppointmentConsultation = () => {
    setViewBookedAppointmentConsultation(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewBookedAppointmentConsultation = () => {
    setViewBookedAppointmentConsultation(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenAddRegisterPatient = () => {
    setAddRegisterPatient(true);
  };
  const handelCloseAddRegisterPatient = () => {
    setAddRegisterPatient(false);
  };
  const handelOpenAppointmentSettings = () => {
    setAddAppointmentSettings(true);
  };
  const handelCloseAppointmentSettings = () => {
    setAddAppointmentSettings(false);
  };

  const [dataForAppointBook, setDataForAppointBook] = useState(null);
  const [addAppointmBook, setAddAppointBook] = useState(false);

  const [refreshbookAppointMent, setRefreshBookAppointment] = useState(false);
  const [viewReferredPatient, setViewReferredPatient] = useState(false);
  const [viewReviewPatient, setViewReviewPatient] = useState(false);
  const [investigationUnits, setInvestigationUnits] = useState(false);
  const [viewInvestigationAdmin, setViewInvestigationAdmin] = useState(false);
  const [addInvestigationAdmin, setAddInvestigationAdmin] = useState(false);
  const [addInvestigationParameter, setAddInvestigationParameter] =
    useState(false);

  const [viewFullManagement, setViewFullManagement] = useState(null);

  const onOpenMore = (menu) => {
    setViewFullManagement(menu);
    setAnalyticsDashboard(false);
  };
  const onCloseMore = () => {
    setViewFullManagement(null);
    setAnalyticsDashboard(true);
  };

  const refreshBookAppointmentChange = () => {
    setRefreshBookAppointment(
      (prevRefreshbookAppointment) => !prevRefreshbookAppointment
    );
  };

  const handelOpenAppointmentBooking = () => {
    setAddAppointmentBooking(true);
  };
  const handelCloseAppointmentBooking = () => {
    setAddAppointmentBooking(false);
  };

  const [bookAppointClass, setBookAppointClass] = useState(false);

  const handelOpenAppointBook = (data) => {
    setAddAppointBook(true);
    setDataForAppointBook(data);
    setBookAppointClass(true);
  };

  const handelCloseAppointBooking = () => {
    setAddAppointBook(false);
    setDataForAppointBook(null);
    setBookAppointClass(false);
  };

  const handelOpenViewBookConsultation = () => {
    setViewBookConsultation(true);
    setAnalyticsDashboard(false);
  };
  const handelOpenViewPatientDocument = () => {
    setViewPatientDocument(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewPatientDocument = () => {
    setViewPatientDocument(false);
    setAnalyticsDashboard(true);
  };
  const handelCloseViewBookConsultation = () => {
    setViewBookConsultation(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenAddBookConsultation = () => {
    setAddBookConsultation(true);
  };
  const handelCloseAddBookConsultation = () => {
    setAddBookConsultation(false);
  };

  const openViewFullSlot = (data) => {
    setViewBookAppointmentDrList(false);
    setViewBookAppointment(true);
    setSelectedDocId(data);
  };

  const handleOpenViewNewAppointMentBooked = () => {
    setViewNewAppointMentBooked(true);
    setAnalyticsDashboard(false);
  };

  const handleCloseViewNewAppointMentBooked = () => {
    setViewNewAppointMentBooked(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenAddInvestigationUnit = () => {
    setInvestigationUnits(true);
  };

  const handelCloseAddInvestigationUnit = () => {
    setInvestigationUnits(false);
  };

  const handleOpenViewInvestigationAdmin = () => {
    setViewInvestigationAdmin(true);
    setAnalyticsDashboard(false);
  };

  const handleCloseViewInvestigationAdmin = () => {
    setViewInvestigationAdmin(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenAddInvestigationAdmin = () => {
    setAddInvestigationAdmin(true);
  };

  const handelCloseAddInvestigationAdmin = () => {
    setAddInvestigationAdmin(false);
  };

  const [resheduleDrAppointment, setResheduleDrAppointment] = useState(false);
  const [resheduleDrAppointmentData, setResheduleDrAppointmentData] =
    useState(null);
  const [selectSlot, setSelectSlot] = useState(true);

  const handleOpenResheduleDrAppointment = (data) => {
    setResheduleDrAppointment(true);
    setResheduleDrAppointmentData(data);
    setSelectSlot(true);
  };

  const handleCloseResheduleDrAppointment = () => {
    setResheduleDrAppointment(false);
    setResheduleDrAppointmentData(null);
    setSelectSlot(false);
  };

  const handleOpenViewReferredPatient = () => {
    setViewReferredPatient(true);
    setAnalyticsDashboard(false);
  };
  const handleCloseViewReferredPatient = () => {
    setViewReferredPatient(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenViewReviewPatient = () => {
    setViewReviewPatient(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewReviewPatient = () => {
    setViewReviewPatient(false);
    setAnalyticsDashboard(true);
  };

  const refresReschduleDocAppointData = () => {
    setRefreshReschduleDocAppoint(
      (prevRefreshReschduleDocAppoint) => !prevRefreshReschduleDocAppoint
    );
  };

  const [viewDoctorConsultList, setViewDoctorConsultList] = useState(false);

  const handleOpenViewDoctorConsultList = () => {
    setViewDoctorConsultList(true);
    setAnalyticsDashboard(false);
  };
  const handleCloseViewDoctorConsultList = () => {
    setViewDoctorConsultList(false);
    setAnalyticsDashboard(true);
  };

  const [addUnitInvestigationAdmin, setUnitInvestigationAdmin] =
    useState(false);
  const [addUnitInvestigationAdminData, setUnitInvestigationAdminData] =
    useState(null);

  const handleOpenUnitInvestigationAdmin = (data) => {
    setUnitInvestigationAdmin(true);
    setUnitInvestigationAdminData(data);
  };
  const handleCloseUnitInvestigationAdmin = () => {
    setUnitInvestigationAdmin(false);
    setUnitInvestigationAdminData(null);
  };
  const handelCloseAddInvestigationParameter = () => {
    setAddInvestigationParameter(false);
  };
  const handelOpenAddInvestigationParameter = () => {
    setAddInvestigationParameter(true);
  };

  const [registeredPatient, setRegisteredPatient] = useState(null);
  // healthcare coponent end /////////////////////////////////////////////////////////////

  // useEffect(() => { //start tour after 4sec
  //   setTimeout(() => {
  //     startTour();
  //   }, 4000);
  // }, []);
  // const [localSelectedTour, setLocalSelectedTour] = useState(
  //   SecureIndexedDB.getItem("tourValue") || ""
  // );

  const [localSelectedTour, setLocalSelectedTour] = useState("");

  // const handleTourChange = (event) => {
  //   const value = event.target.value;
  //   setLocalSelectedTour(value);
  //   startTour();
  //   SecureIndexedDB.setItem("tourValue", value);
  //   setTimeout(() => {
  //     startTour();
  //   }, 5000);
  // };

  // const handleTourChange = (event) => {
  //   const value = event.target.value;
  //   setLocalSelectedTour(value);
  //   SecureIndexedDB.setItem("tourValue", value);

  //   // Dispatch a custom event
  //   window.dispatchEvent(new Event("custom-tour-update"));

  //   // startTour();

  //   // Optional delay for restarting the tour
  //   // setTimeout(() => {
  //   //   startTour();
  //   // }, 5000);
  // };

  // useEffect(() => {
  //   const checkTourValue = () => {
  //     const updatedTourValue =  SecureIndexedDB.getItem("tourValue");
  //     if (updatedTourValue !== localSelectedTour) {
  //       setLocalSelectedTour(updatedTourValue);
  //       // startTour(); // Trigger the tour
  //     }
  //   };

  //   // Add event listeners for storage and custom event
  //   window.addEventListener("storage", checkTourValue);
  //   window.addEventListener("custom-tour-update", checkTourValue);

  //   // Cleanup listeners on component unmount
  //   return () => {
  //     window.removeEventListener("storage", checkTourValue);
  //     window.removeEventListener("custom-tour-update", checkTourValue);
  //   };
  // }, [localSelectedTour, startTour]);
  const { addStep, setRun } = useContext(TourContext);
  // useEffect(() => {
  //   addStep({
  //     target: ".nsBookConsultsteps",
  //     content: "Here is the Footer Section.",
  //   });
  // }, [addStep]);
  return (
    <>
      <Box
        className="AnalyticsDashboardContainerArea"
        sx={{ bgcolor: "dark.pageBg" }}
      >
        <Grid container spacing={0} className="AnalyticsDashboardContainArea">
          <Grid item xs={12} className="pRelative">
            {showAnalyticsDashboard && (
              <div className="AnalyticsDashboardContainer">
                <div className="AnalyticsDashboardHeader adHeaderWthBtn">
                  <div className="adHeaderTxt"> {t("dashboard")}</div>
                  {/* <div className="adHeaderBtn">
                    <Button
                      className="dfultPrimaryBtn"
                      startIcon={<VideoCall />}
                      onClick={startTour}
                    >
                      Start Tour
                    </Button>
                  </div> */}

                  {/* {userDetails.userType === "ADMIN" && (
                    <div className="formElement mxW-150">
                      <FormControl className="formControl">
                        <InputLabel id="selectOrdrs">Select A Tour</InputLabel>
                        <Select
                          className="formInputField"
                          variant="outlined"
                          labelId="selectOrdrs"
                          id="select-saltord"
                          label="Select A Tour"
                          value={localSelectedTour}
                          onChange={(e) => {
                           if (e.target.value === "ManagementConsult") {
                              setRun({
                                manageConsultAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            }
                            else if (e.target.value === "ManagementFeedback") {
                              setRun({
                                manageFeedBackAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "ManagementTrend") {
                              setRun({
                                manageTrendAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            }else if (e.target.value === "ManagementDoctor") {
                              setRun({
                                manageDoctorAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            }else if (e.target.value === "ManagementDisease") {
                              setRun({
                                manageDiseaseAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            }else if (e.target.value === "ManagementUnit") {
                              setRun({
                                manageUnitAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            }else if (e.target.value === "ManagementSupportTicket") {
                              setRun({
                                manageSupportTicketAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            }else if (e.target.value === "responseTimeMassalud") {
                              setRun({
                                manageresponseTimeMassaludAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            }else if (e.target.value === "uptimeDowntimeMassalud") {
                              setRun({
                                manageuptimeDowntimeMassaludAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            }
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select Tour
                          </MenuItem>
                          <MenuItem value="ManagementConsult">
                            Management Consultation
                          </MenuItem>
                          <MenuItem value="ManagementFeedback">
                            Management FeedBack
                          </MenuItem>
                          <MenuItem value="ManagementTrend">
                            Management Trend
                          </MenuItem>
                          <MenuItem value="ManagementDoctor">
                            Management Doctor
                          </MenuItem>
                          <MenuItem value="ManagementDisease">
                            Management Disease
                          </MenuItem>
                          <MenuItem value="ManagementUnit">
                            Management Unit
                          </MenuItem>
                          <MenuItem value="ManagementSupportTicket">
                            Management Support Ticket
                          </MenuItem>
                          <MenuItem value="responseTimeMassalud">
                          response time massalud.zoyel.health
                          </MenuItem>
                          <MenuItem value="uptimeDowntimeMassalud">
                          Uptime Downtime massalud.zoyel.health
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}  */}

                  {/* {userDetails.zhUserType === "DOCTOR" && (
                    <div className="formElement mxW-150">
                      <FormControl className="formControl">
                        <InputLabel id="selectOrdrs">Select A Tour</InputLabel>
                        <Select
                          className="formInputField"
                          variant="outlined"
                          labelId="selectOrdrs"
                          id="select-saltord"
                          label="Select A Tour"
                          value={localSelectedTour}
                          onChange={(e) => {
                            if (e.target.value === "appointBookdoctor") {
                              setRun({
                                appointBookdoctorDc: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "doctConsulation") {
                              setRun({
                                docConsultDc: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "doctorQueue") {
                              setRun({
                                docQueueDc: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "ManagementConsult") {
                              setRun({
                                manageConsultAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (
                              e.target.value === "ManagementFeedback"
                            ) {
                              setRun({
                                manageFeedBackAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "ManagementTrend") {
                              setRun({
                                manageTrendAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "ManagementDoctor") {
                              setRun({
                                manageDoctorAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "ManagementDisease") {
                              setRun({
                                manageDiseaseAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "ManagementUnit") {
                              setRun({
                                manageUnitAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (
                              e.target.value === "ManagementSupportTicket"
                            ) {
                              setRun({
                                manageSupportTicketAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (
                              e.target.value === "responseTimeMassalud"
                            ) {
                              setRun({
                                manageresponseTimeMassaludAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (
                              e.target.value === "uptimeDowntimeMassalud"
                            ) {
                              setRun({
                                manageuptimeDowntimeMassaludAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "supportAdmin") {
                              setRun({
                                supportAdminAs: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            }else if (e.target.value === "systemTask") {
                              setRun({
                                supportAdminAs: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            }
                            else if (e.target.value === "profileDetails") {
                              setRun({
                                profilepf: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            }else if (e.target.value === "systemChat") {
                              setRun({
                                supportAdminAs: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            }else if (e.target.value === "systemScheduler") {
                              setRun({
                                supportAdminAs: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            }else if (e.target.value === "systemCreateSupportTicket") {
                              setRun({
                                creatSupportTicketAs: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            }
                            else if (e.target.value === "systemCreateEmployee") {
                              setRun({
                                createEmployeeDS: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "systemCreateCardTask") {
                              setRun({
                                cardTaskDS: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            }
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select Tour
                          </MenuItem>
                          <MenuItem value="appointBookdoctor">
                            Appointment Book
                          </MenuItem>
                          <MenuItem value="doctConsulation">
                            Doctor Consulation
                          </MenuItem>
                          <MenuItem value="doctorQueue">
                            How To Start Consultation
                          </MenuItem>
                          <MenuItem value="ManagementConsult">
                            Management Consultation
                          </MenuItem>
                          <MenuItem value="ManagementFeedback">
                            Management FeedBack
                          </MenuItem>
                          <MenuItem value="ManagementTrend">
                            Management Trend
                          </MenuItem>
                          <MenuItem value="ManagementDoctor">
                            Management Doctor
                          </MenuItem>
                          <MenuItem value="ManagementDisease">
                            Management Disease
                          </MenuItem>
                          <MenuItem value="ManagementUnit">
                            Management Unit
                          </MenuItem>
                          <MenuItem value="ManagementSupportTicket">
                            Management Support Ticket
                          </MenuItem>
                          <MenuItem value="responseTimeMassalud">
                            response time massalud.zoyel.health
                          </MenuItem>
                          <MenuItem value="uptimeDowntimeMassalud">
                            Uptime Downtime massalud.zoyel.health
                          </MenuItem>
                          <MenuItem value="supportAdmin">Admin</MenuItem>
                          <MenuItem value="systemTask">
                          Task
                          </MenuItem>
                          {userDetails.userType !== "GUEST" && (
                          <MenuItem value="profileDetails">
                           Profile
                          </MenuItem>
                          )}
                           <MenuItem value="systemChat">
                            Chat
                          </MenuItem>
                          <MenuItem value="systemScheduler">
                            Scheduler
                          </MenuItem>
                          <MenuItem value="systemCreateSupportTicket">
                            Create Support Ticket
                          </MenuItem>
                          <MenuItem value="systemCreateEmployee">
                            Card Employee
                          </MenuItem>
                          <MenuItem value="systemCreateCardTask">
                            Card Task
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )} */}

                  {/* {(userDetails.zhUserType === "NURSE" || userDetails.zhUserType === "LT") && (
                    <div className="formElement mxW-150">
                      <FormControl className="formControl">
                        <InputLabel id="selectOrdrs">Select A Tour</InputLabel>
                        <Select
                          className="formInputField"
                          variant="outlined"
                          labelId="selectOrdrs"
                          id="select-saltord"
                          label="Select A Tour"
                          value={localSelectedTour}
                          onChange={(e) => {
                            if (e.target.value === "bookAppointment") {
                              setRun({
                                bookOrViewAppointMentNS: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "bokConsult") {
                              setRun({
                                consultStepsJr: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "patientDocm") {
                              setRun({
                                patientdocJr: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "revAndRefPat") {
                              setRun({
                                revAndRefJr: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "registerPatient") {
                              setRun({
                                registPatJr: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "investUnit") {
                              setRun({
                                investUnitJr: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            }
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select Tour
                          </MenuItem>
                          <MenuItem value="bookAppointment">
                            Book/View Appointment
                          </MenuItem>
                          <MenuItem value="registerPatient">
                            Register Patient
                          </MenuItem>
                          <MenuItem value="bokConsult">
                            Book Consultation
                          </MenuItem>
                          <MenuItem value="patientDocm">
                            Patient Document
                          </MenuItem>
                          <MenuItem value="revAndRefPat">
                            Review And Refer Patient
                          </MenuItem>
                          <MenuItem value="investUnit">
                            Investigation-Unit
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}  */}

                  {/* React Tour */}

                  {/* {isLocalHost() && (
                    <div className="formElement mxW-150">
                      <FormControl className="formControl">
                        <InputLabel id="selectOrdrs">{t("select_a_tour")}</InputLabel>
                        <Select
                          className="formInputField"
                          variant="outlined"
                          labelId="selectOrdrs"
                          id="select-saltord"
                          label={t("select_a_tour")}
                          value={localSelectedTour}
                          onChange={(e) => {
                            if (e.target.value === "appointBookdoctor") {
                              setRun({
                                appointBookdoctorDc: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "doctConsulation") {
                              setRun({
                                docConsultDc: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "doctorQueue") {
                              setRun({
                                docQueueDc: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "ManagementConsult") {
                              setRun({
                                manageConsultAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (
                              e.target.value === "ManagementFeedback"
                            ) {
                              setRun({
                                manageFeedBackAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "ManagementTrend") {
                              setRun({
                                manageTrendAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "ManagementDoctor") {
                              setRun({
                                manageDoctorAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "ManagementDisease") {
                              setRun({
                                manageDiseaseAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "ManagementUnit") {
                              setRun({
                                manageUnitAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (
                              e.target.value === "ManagementSupportTicket"
                            ) {
                              setRun({
                                manageSupportTicketAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (
                              e.target.value === "responseTimeMassalud"
                            ) {
                              setRun({
                                manageresponseTimeMassaludAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (
                              e.target.value === "uptimeDowntimeMassalud"
                            ) {
                              setRun({
                                manageuptimeDowntimeMassaludAD: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "supportAdmin") {
                              setRun({
                                supportAdminAs: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "bookAppointment") {
                              setRun({
                                bookOrViewAppointMentNS: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "bokConsult") {
                              setRun({
                                consultStepsJr: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "patientDocm") {
                              setRun({
                                patientdocJr: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "revAndRefPat") {
                              setRun({
                                revAndRefJr: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "registerPatient") {
                              setRun({
                                registPatJr: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "investUnit") {
                              setRun({
                                investUnitJr: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "supportAdmin") {
                              setRun({
                                supportAdminAs: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "systemTask") {
                              setRun({
                                supportTaskDash: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "profileDetails") {
                              setRun({
                                profilepf: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "systemChat") {
                              setRun({
                                supportChatDash: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "systemScheduler") {
                              setRun({
                                supportSchedulerDash: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (
                              e.target.value === "systemCreateSupportTicket"
                            ) {
                              setRun({
                                creatSupportTicketAs: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (
                              e.target.value === "systemCreateEmployee"
                            ) {
                              setRun({
                                createEmployeeDS: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (
                              e.target.value === "systemCreateCardTask"
                            ) {
                              setRun({
                                cardTaskDS: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (
                              e.target.value === "systemCreateCardLeaveRule"
                            ) {
                              setRun({
                                cardLeaveRuleDS: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (
                              e.target.value === "systemCreateCardBudget"
                            ) {
                              setRun({
                                cardBudgetDS: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (
                              e.target.value === "systemCreateCardItem"
                            ) {
                              setRun({
                                cardItemsDS: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (
                              e.target.value === "systemCreateCardHoliday"
                            ) {
                              setRun({
                                cardHolidayWorkingDaysDS: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (
                              e.target.value === "systemCreateCardExternalUser"
                            ) {
                              setRun({
                                cardExternalUserDS: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (
                              e.target.value === "systemCreateCardContract"
                            ) {
                              setRun({
                                cardContractCDS: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (
                              e.target.value === "systemCreateCardRoutineWork"
                            ) {
                              setRun({
                                cardRoutineWorkCDS: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (e.target.value === "supportDrive") {
                              setRun({
                                supportDriveDash: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (
                              e.target.value === "supportAppDashboard"
                            ) {
                              setRun({
                                supportTicketDash: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            } else if (
                              e.target.value === "supportMeetingRoom"
                            ) {
                              setRun({
                                supportMeetingRoomDash: true,
                              });
                              setLocalSelectedTour(e.target.value);
                            }
                          }}
                        >
                          <MenuItem value="" disabled>
                          {t("select_tour")}
                          </MenuItem>
                          {userMenuMap && userMenuMap.has(26) && (
                            <MenuItem value="appointBookdoctor">
                              {t("appointment_book")}
                            </MenuItem>
                          )}
                          {userMenuMap && userMenuMap.has(30) && (
                            <MenuItem value="doctConsulation">
                              {t("doctor_consulation")}
                            </MenuItem>
                          )}
                          {userDetails.zhUserType === "DOCTOR" && (
                            <MenuItem value="doctorQueue">
                              {t("how_to_start_consultation")}
                            </MenuItem>
                          )}
                          {userMenuMap &&
                            userMenuMap.get(31) &&
                            userMenuMap.get(31).includes(53) && (
                              <MenuItem value="ManagementConsult">
                                {t("management_consultation")}
                              </MenuItem>
                            )}
                          {userMenuMap &&
                            userMenuMap.get(31) &&
                            userMenuMap.get(31).includes(58) && (
                              <MenuItem value="ManagementFeedback">
                                {t("management_feedBack")}
                              </MenuItem>
                            )}
                          {userMenuMap &&
                            userMenuMap.get(31) &&
                            userMenuMap.get(31).includes(55) && (
                              <MenuItem value="ManagementTrend">
                               {t("management_trend")}
                              </MenuItem>
                            )}
                          {userMenuMap &&
                            userMenuMap.get(31) &&
                            userMenuMap.get(31).includes(54) && (
                              <MenuItem value="ManagementDoctor">
                                {t("management_doctor")}
                              </MenuItem>
                            )}
                          {userMenuMap &&
                            userMenuMap.get(31) &&
                            userMenuMap.get(31).includes(57) && (
                              <MenuItem value="ManagementDisease">
                                {t("management_disease")}
                              </MenuItem>
                            )}
                          {userMenuMap &&
                            userMenuMap.get(31) &&
                            userMenuMap.get(31).includes(56) && (
                              <MenuItem value="ManagementUnit">
                                {t("management_unit")}
                              </MenuItem>
                            )}
                          {userMenuMap &&
                            userMenuMap.get(31) &&
                            userMenuMap.get(31).includes(59) && (
                              <MenuItem value="ManagementSupportTicket">
                                {t("management_support_ticket")}
                              </MenuItem>
                            )}
                          {userMenuMap &&
                            userMenuMap.get(31) &&
                            userMenuMap.get(31).includes(60) &&
                            (window.location.hostname.includes("massalud") ||
                              window.location.hostname.includes(
                                "localhost"
                              )) && (
                              <MenuItem value="responseTimeMassalud">
                                {t("response_time_massalud_zoyel_health")}
                              </MenuItem>
                            )}
                          {userMenuMap &&
                            userMenuMap.get(31) &&
                            userMenuMap.get(31).includes(61) &&
                            (window.location.hostname.includes("massalud") ||
                              window.location.hostname.includes(
                                "localhost"
                              )) && (
                              <MenuItem value="uptimeDowntimeMassalud">
                                {t("uptime_downtime_massalud_zoyel_health")}
                              </MenuItem>
                            )}
                          {userMenuMap && userMenuMap.has(24) && (
                            <MenuItem value="bookAppointment">
                             {t("book_appointment")}
                            </MenuItem>
                          )}
                          {userMenuMap && userMenuMap.has(21) && (
                            <MenuItem value="registerPatient">
                              {t("register_patient")}
                            </MenuItem>
                          )}
                          {userMenuMap && userMenuMap.has(22) && (
                            <MenuItem value="bokConsult">
                              {t("book_consultation")}
                            </MenuItem>
                          )}
                          {userMenuMap && userMenuMap.has(23) && (
                            <MenuItem value="patientDocm">
                              {t("patient_document")}
                            </MenuItem>
                          )}
                          {userMenuMap && userMenuMap.has(27) && (
                            <MenuItem value="revAndRefPat">
                              {t("review_and_refer_patient")}
                            </MenuItem>
                          )}
                          {userMenuMap && userMenuMap.has(28) && (
                            <MenuItem value="investUnit">
                              {t('investigation_unit')}
                            </MenuItem>
                          )}
                          {userDetails.userType === "ADMIN" &&
                            !AppUtils.isPersonal(userDetails) && (
                              <MenuItem value="supportAdmin">{t("admin")}</MenuItem>
                            )}
                          {!AppUtils.isPersonal(userDetails) && (
                            <MenuItem value="systemTask">{t("task")}</MenuItem>
                          )}
                          {userDetails.userType !== "GUEST" && (
                            <MenuItem value="profileDetails">{t("profile")}</MenuItem>
                          )}
                          <MenuItem value="systemChat">{t("chat")}</MenuItem>
                          <MenuItem value="systemScheduler">{t("scheduler")}</MenuItem>
                          <MenuItem value="systemCreateSupportTicket">
                          {t("create_support_ticket")}
                          </MenuItem>
                          {userMenuMap && userMenuMap.has(1) && (
                            <MenuItem value="systemCreateEmployee">
                              {t("card_employee")}
                            </MenuItem>
                          )}
                          {userMenuMap &&
                            userMenuMap.get(31) &&
                            userMenuMap.get(31).includes(62) && (
                              <MenuItem value="systemCreateCardTask">
                                {t("card_task")}
                              </MenuItem>
                            )}
                          {userMenuMap && userMenuMap.has(2) && (
                            <MenuItem value="systemCreateCardLeaveRule">
                              {t("card_leave_rule")}
                            </MenuItem>
                          )}
                          {userMenuMap && userMenuMap.has(3) && (
                            <MenuItem value="systemCreateCardBudget">
                              {t("card_budget")}
                            </MenuItem>
                          )}
                          {userMenuMap && userMenuMap.has(4) && (
                            <MenuItem value="systemCreateCardItem">
                              {t("card_items")}
                            </MenuItem>
                          )}
                          {userMenuMap && userMenuMap.has(7) && (
                            <MenuItem value="systemCreateCardHoliday">
                              {t("card_holiday")}
                            </MenuItem>
                          )}
                          {userMenuMap && userMenuMap.has(8) && (
                            <MenuItem value="systemCreateCardExternalUser">
                              {t("card_external_user")}
                            </MenuItem>
                          )}
                          {userMenuMap && userMenuMap.has(9) && (
                            <MenuItem value="systemCreateCardContract">
                              {t("card_contract")}
                            </MenuItem>
                          )}
                          {userMenuMap && userMenuMap.has(5) && (
                            <MenuItem value="systemCreateCardRoutineWork">
                              {t("card_routine_work")}
                            </MenuItem>
                          )}
                          <MenuItem value="supportDrive">{t("drive")}</MenuItem>
                          {!AppUtils.isPersonal(loginUser) && "zoyel.one" !== webApp && (
                          <MenuItem value="supportAppDashboard">
                          {t('support_dashboard')}
                          </MenuItem>
                          )}
                          <MenuItem value="supportMeetingRoom">
                          {t('meeting_room')}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                   )} */}
                </div>

                <div className="anDashCardContainArea" id="noscroll">
                  {userMenuMap !== null && userMenuMap.size === 0 ? (
                    <div class="noDataCard ">
                      <span>{t("oops")}</span>
                      <br />
                      <span className="sallMsg">
                        {t("permission_for_dashboard_modules")}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {userMenuMap && userMenuMap.has(1) && (
                    <CardPeople
                      handelOpenAddPeople={handelOpenAddPeople}
                      handelOpenViewPeople={handelOpenViewPeople}
                      chartDataOfItem={chartDataOfItem}
                      menuModuleList={menuModuleList}
                      userMenuList={userMenuMap.get(1)}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(2) && (
                    <CardLeaveRules
                      handelOpenAddLeaveRules={handelOpenAddLeaveRules}
                      handelOpenViewLeaveRules={handelOpenViewLeaveRules}
                      chartDataOfItem={chartDataOfItem}
                      menuModuleList={menuModuleList}
                      userMenuList={userMenuMap.get(2)}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(3) && (
                    <CardBudget
                      handelOpenAddBudget={handelOpenAddBudget}
                      handelOpenViewBudget={handelOpenViewBudget}
                      chartDataOfItem={chartDataOfItem}
                      menuModuleList={menuModuleList}
                      userMenuList={userMenuMap.get(3)}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(4) && (
                    <CardItems
                      handelOpenAddItems={handelOpenAddItems}
                      handelOpenViewItems={handelOpenViewItems}
                      chartDataOfItem={chartDataOfItem}
                      menuModuleList={menuModuleList}
                      userMenuList={userMenuMap.get(4)}
                    />
                  )}
                  {/* 
                  <CardPolicy
                    handelOpenAddPolicies={handelOpenAddPolicies}
                    handelOpenViewPolicies={handelOpenViewPolicies}
                  /> */}
                  {userMenuMap && userMenuMap.has(5) && (
                    <CardRoutineWork
                      handelOpenAddRoutineWork={handelOpenAddRoutineWork}
                      handelOpenViewRoutineWork={handelOpenViewRoutineWork}
                      chartDataOfItem={chartDataOfItem}
                      menuModuleList={menuModuleList}
                      userMenuList={userMenuMap.get(5)}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(6) && (
                    <CardAttendance
                      handelOpenViewAttendance={handelOpenViewAttendance}
                      chartDataOfItem={chartDataOfItem}
                      menuModuleList={menuModuleList}
                      userMenuList={userMenuMap.get(6)}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(7) && (
                    <CardHolidayWorkingday
                      handelOpenViewHoliday={handelOpenViewHoliday}
                      handelOpenAddHoliday={handelOpenAddHoliday}
                      chartDataOfItem={chartDataOfItem}
                      menuModuleList={menuModuleList}
                      userMenuList={userMenuMap.get(7)}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(8) && (
                    <CardExternalUser
                      handelOpenViewExternalUser={handelOpenViewExternalUser}
                      handelOpenAddExternalUser={handelOpenAddExternalUser}
                      chartDataOfItem={chartDataOfItem}
                      menuModuleList={menuModuleList}
                      userMenuList={userMenuMap.get(8)}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(9) && (
                    <CardContract
                      handelOpenViewContract={handelOpenViewContract}
                      handelOpenAddContract={handelOpenAddContract}
                      chartDataOfItem={chartDataOfItem}
                      menuModuleList={menuModuleList}
                      userMenuList={userMenuMap.get(9)}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(10) && (
                    // webApp !== "zoyel.health" &&
                    <CardPayroll
                      handelOpenViewPayroll={handelOpenViewPayroll}
                      chartDataOfItem={chartDataOfItem}
                      menuModuleList={menuModuleList}
                      userMenuList={userMenuMap.get(10)}
                    />
                  )}
                  {/* dr dashaboard start */}
                  {/* {commented by Prashant for deploying in live server} */}
                  {userMenuMap && userMenuMap.has(21) && (
                    <CardRegisterPatient
                      handelOpenViewRegisterPatient={
                        handelOpenViewRegisterPatient
                      }
                      handelOpenAddRegisterPatient={
                        handelOpenAddRegisterPatient
                      }
                      chartDataOfItem={chartDataOfItem}
                      // fetchDashBoardData={fetchDashBoardDataForPatient}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(22) && (
                    <CardBookConsultation
                      handelOpenViewBookConsultation={
                        handelOpenViewBookConsultation
                      }
                      handelOpenAddBookConsultation={
                        handelOpenAddBookConsultation
                      }
                      chartDataOfItem={chartDataOfItem}
                      // fetchDashBoardData={fetchDashBoardDataForPatient}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(23) && (
                    <CardPatientDocument
                      handelOpenViewPatientDocument={
                        handelOpenViewPatientDocument
                      }
                      chartDataOfItem={chartDataOfItem}
                      // fetchDashBoardData={fetchDashBoardDataForPatient}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(24) && (
                    <CardBookAppointment
                      handelOpenViewBookedAppointmentConsultation={
                        handelOpenViewBookedAppointmentConsultation
                      }
                      handelOpenViewBookAppointmentDrList={
                        handelOpenViewBookAppointmentDrList
                      }
                      chartDataOfItem={chartDataOfItem}
                      // fetchDashBoardData={fetchDashBoardDataForPatient}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(26) && (
                    <CardDrBookAppointment
                      handleOpenViewNewAppointMentBooked={
                        handleOpenViewNewAppointMentBooked
                      }
                      handelOpenAppointmentSettings={
                        handelOpenAppointmentSettings
                      }
                      chartDataOfItem={chartDataOfItem}
                      // fetchDashBoardData={fetchDashBoardDataForPatient}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(27) && (
                    <CardReviewReferredPatient
                      handleOpenViewReferredPatient={
                        handleOpenViewReferredPatient
                      }
                      handelOpenViewReviewPatient={handelOpenViewReviewPatient}
                      chartDataOfItem={chartDataOfItem}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(28) && (
                    <CardInvestigationUnits
                      handelOpenAddInvestigationUnit={
                        handelOpenAddInvestigationUnit
                      }
                      chartDataOfItem={chartDataOfItem}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(29) && (
                    <CardInvestigationAdmin
                      handleOpenViewInvestigationAdmin={
                        handleOpenViewInvestigationAdmin
                      }
                      chartDataOfItem={dataForpatient}
                      fetchDashBoardData={fetchDashBoardDataForPatient}
                    />
                  )}

                  {userMenuMap && userMenuMap.has(30) && (
                    <CardDoctorConsultations
                      handleOpenViewDoctorConsultList={
                        handleOpenViewDoctorConsultList
                      }
                      chartDataOfItem={chartDataOfItem}
                      // fetchDashBoardData={fetchDashBoardDataForPatient}
                    />
                  )}
                  {/* <CardAnalyticsAdmin />
                  <CardAnalyticsUnits />
                  <CardDoctorEarnings /> */}
                  {/* , userMenuMap.get(31).includes(53))} */}

                  {/* start analitic dashboard with step only for managment*/}

                  {userMenuMap &&
                    userMenuMap.get(31) &&
                    userMenuMap.get(31).includes(53) && (
                      <CardManagementConsultation
                        chartDataOfItem={chartDataOfItem}
                        onOpenMore={() => onOpenMore(53)}
                      />
                    )}
                  {userMenuMap &&
                    userMenuMap.get(31) &&
                    userMenuMap.get(31).includes(58) && (
                      <CardManagementFeedback
                        chartDataOfItem={chartDataOfItem}
                      />
                    )}
                  {userMenuMap &&
                    userMenuMap.get(31) &&
                    userMenuMap.get(31).includes(55) && (
                      <CardManagementTrends chartDataOfItem={chartDataOfItem} />
                    )}
                  {userMenuMap &&
                    userMenuMap.get(31) &&
                    userMenuMap.get(31).includes(54) && (
                      <CardManagementDoctor
                        onOpenMore={() => onOpenMore(54)}
                        chartDataOfItem={chartDataOfItem}
                      />
                    )}
                  {userMenuMap &&
                    userMenuMap.get(31) &&
                    userMenuMap.get(31).includes(57) && (
                      <CardManagementDisease
                        chartDataOfItem={chartDataOfItem}
                      />
                    )}
                  {userMenuMap &&
                    userMenuMap.get(31) &&
                    userMenuMap.get(31).includes(56) && (
                      <CardManagementCenter chartDataOfItem={chartDataOfItem} />
                    )}
                  {userMenuMap &&
                    userMenuMap.get(31) &&
                    userMenuMap.get(31).includes(59) && (
                      <CardManagementSupportTicket
                        chartDataOfItem={chartDataOfItem}
                      />
                    )}

                  {userMenuMap &&
                    userMenuMap.get(31) &&
                    userMenuMap.get(31).includes(60) &&
                    (window.location.hostname.includes("massalud") ||
                      window.location.hostname.includes("localhost")) && (
                      <CardWebsiteStatus />
                    )}
                  {userMenuMap &&
                    userMenuMap.get(31) &&
                    userMenuMap.get(31).includes(61) &&
                    (window.location.hostname.includes("massalud") ||
                      window.location.hostname.includes("localhost")) && (
                      <CardWebsiteStatusUptimeDowntime />
                    )}
                  {/* { && <CardTask chartDataOfItem={chartDataOfItem} />} */}

                  {userMenuMap &&
                    userMenuMap.get(31) &&
                    userMenuMap.get(31).includes(62) && (
                      <CardTask chartDataOfItem={chartDataOfItem} />
                    )}
                </div>
              </div>
            )}
            {showViewPayroll && (
              <ViewPayroll
                handelOpenDetailPayroll={handelOpenDetailPayroll}
                handelOpenPayrollPayslip={handelOpenPayrollPayslip}
                handelCloseViewPayroll={handelCloseViewPayroll}
                closeAll={closeAll}
                refreshEmployPayroll={refreshEmployPayroll}
              />
            )}
            {showViewPeople && (
              <ViewPeople
                handelCloseViewPeople={handelCloseViewPeople}
                handelOpenAddPeople={handelOpenAddPeople}
                refreshViewPeople={refreshViewPeople}
                handelOpenEditEmployee={handelOpenEditEmployee}
                closeAll={closeAll}
              />
            )}
            {showViewBudget && (
              <ViewBudget
                handelCloseViewBudget={handelCloseViewBudget}
                handelOpenAddBudget={handelOpenAddBudget}
                refreshBudget={refreshBudget}
                handleOpenViewSelectedBudget={handleOpenViewSelectedBudget}
                closeAll={closeAll}
              />
            )}
            {viewSelectedBudget && (
              <RightFlotingAnalyticsDashboard>
                <SelectedViewBudget
                  refreshAddLeaveRulesData={refreshAddLeaveRulesData}
                  handleCloseViewSelectedBudget={handleCloseViewSelectedBudget}
                  viewSelectedBudgetData={viewSelectedBudgetData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showViewAttendance && (
              <ViewAttendance
                handelCloseViewAttendance={handelCloseViewAttendance}
                handelOpenViewUserAttend={handelOpenViewUserAttend}
              />
            )}
            {showViewLeaveRules && (
              <ViewLeaveRules
                handelOpenAddLeaveRules={handelOpenAddLeaveRules}
                handelCloseViewLeaveRules={handelCloseViewLeaveRules}
                refreshAddLeaveRules={refreshAddLeaveRules}
                handleOpenUpdateLeaveRule={handleOpenUpdateLeaveRule}
                handleOpenViewSelectedLeaveRule={
                  handleOpenViewSelectedLeaveRule
                }
                closeAll={closeAll}
              />
            )}
            {showViewItems && (
              <ViewItems
                handelOpenAddItems={handelOpenAddItems}
                handelCloseViewItems={handelCloseViewItems}
                setEditItem={setEditItem}
                shouldUpdateItemList={shouldItemUpdateList}
                closeAll={closeAll}
              />
            )}
            {/* {showViewPolicies && (
              <ViewPolicies
                handelCloseViewPolicies={handelCloseViewPolicies}
                handelOpenAddPolicies={handelOpenAddPolicies}
                refresh={refresh}
              />
            )} */}
            {showViewRoutineWork && (
              <ViewRoutineWork
                handelCloseViewRoutineWork={handelCloseViewRoutineWork}
                handelOpenAddRoutineWork={handelOpenAddRoutineWork}
                closeAll={closeAll}
              />
            )}
            {showViewHoliday && (
              <ViewHolidayWorkingday
                handelCloseViewHoliday={handelCloseViewHoliday}
                handelOpenAddHoliday={handelOpenAddHoliday}
                handelOpenAddWorkingday={handelOpenAddWorkingday}
                refreshHoliday={refreshHoliday}
                handelOpenEditHoliday={handelOpenEditHoliday}
                handelOpenSelectedviewHoliday={handelOpenSelectedviewHoliday}
                handleOpenAddWorkingShift={handleOpenAddWorkingShift}
                closeAll={closeAll}
              />
            )}
            {showViewExternalUser && (
              <ViewExternalUser
                handelCloseViewExternalUser={handelCloseViewExternalUser}
                handelOpenAddExternalUser={handelOpenAddExternalUser}
                refreshExternalUser={refreshExternalUser}
                handelOpenEditExternaluser={handelOpenEditExternaluser}
                closeAll={closeAll}
              />
            )}
            {showViewContract && (
              <ViewContract
                handelCloseViewContract={handelCloseViewContract}
                handelOpenAddContract={(data) => {
                  if (data) {
                    setSelectedContractData(data);
                  }
                  handelOpenAddContract();
                }}
                refreshContract={refreshContract}
                closeAll={closeAll}
              />
            )}
            {/* healthcare start /////////////////////////////////////////////////////////////////////////////// */}
            {/* {viewRegisterPatient && (
              <ViewRegisterPatient
                handelCloseViewRegisterPatient={handelCloseViewRegisterPatient}
                handelOpenAddRegisterPatient={handelOpenAddRegisterPatient}
                closeAll={closeAll}
              />
            )} */}
            {viewBookConsultation && (
              <ViewBookConsultation
                handelCloseViewBookConsultation={
                  handelCloseViewBookConsultation
                }
                handelOpenAddBookConsultation={handelOpenAddBookConsultation}
                handleOpenAddTestResult={handleOpenAddTestResult}
                closeAll={closeAll}
                shouldUpdateQueueList={shouldUpdateQueueList}
                openConversation={(data) => {
                  setConversationData(data);
                }}
              />
            )}
            {viewPatientDocument && (
              <ViewPatientDocument
                handelCloseViewPatientDocument={handelCloseViewPatientDocument}
                closeAll={closeAll}
              />
            )}
            {viewBookAppointment && (
              <ViewBookAppointmentMoreSlots
                handelCloseViewBookAppointment={handelCloseViewBookAppointment}
                closeAll={closeAll}
                handelOpenAppointmentBooking={handelOpenAppointBook}
                handelCloseAppointBooking={handelCloseAppointBooking}
                selectedDocId={selectedDocId}
                refreshbookAppointMent={refreshbookAppointMent}
                bookAppointClass={bookAppointClass}
                registeredPatient={registeredPatient}
              />
            )}
            {openAddTestData && (
              <RightFlotingAnalyticsDashboard>
                <AddTestData
                  handelCloseAddTestData={handleCloseAddTestResult}
                  consultationData={consultationData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {viewNewAppointMentBookedVal && (
              <ViewNewAppointmentBooked
                handleCloseViewNewAppointMentBooked={
                  handleCloseViewNewAppointMentBooked
                }
                closeAll={closeAll}
                handleOpenResheduleDrAppointment={
                  handleOpenResheduleDrAppointment
                }
                refreshReschduleDocAppoint={refreshReschduleDocAppoint}
                selectSlot={selectSlot}
              />
            )}

            {viewDoctorConsultList && (
              <ViewDoctorConsultationList
                handleCloseViewDoctorConsultList={
                  handleCloseViewDoctorConsultList
                }
              />
            )}

            {addRegisterPatient && (
              <RightFlotingAnalyticsDashboard>
                <AddRegisterPatient
                  handelCloseAddRegisterPatient={handelCloseAddRegisterPatient}
                  setSelectedPatientData={(patientData, bookingType) => {
                    if (bookingType === "bookConsultation") {
                      if (patientData.rowPatientId > 0) {
                        setRegisteredPatient(patientData);
                        handelOpenAddBookConsultation();
                        handelCloseAddRegisterPatient();
                      }
                    } else if (bookingType === "bookAppointment") {
                      if (patientData.rowPatientId > 0) {
                        setRegisteredPatient(patientData);
                        handelOpenViewBookAppointmentDrList();
                        handelCloseAddRegisterPatient();
                      }
                    } else if (bookingType === "bookInvestigation") {
                      if (patientData.rowPatientId > 0) {
                        setRegisteredPatient(patientData);
                        handelOpenAddInvestigationUnit();
                        handelCloseAddRegisterPatient();
                      }
                    }
                  }}
                  fetchDashBoardData={fetchDashBoardDataForPatient}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {addBookConsultation && (
              <RightFlotingAnalyticsDashboard>
                <AddBookConsultation
                  handelCloseAddBookConsultation={() => {
                    handelCloseAddBookConsultation();
                    setRegisteredPatient(null);
                  }}
                  registeredPatient={registeredPatient}
                  closeBookConsultation={() => {
                    setAddBookConsultation(false);
                    // setViewBookConsultation(true);
                    handelOpenViewBookConsultation();
                    setShouldUpdateQueueList((prev) => prev + 1);
                  }}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {addAppointmentSettings && (
              <RightFlotingAnalyticsDashboard>
                <AddAppointmentSettings
                  handelCloseAppointmentSettings={
                    handelCloseAppointmentSettings
                  }
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {viewBookAppointmentDrList && (
              <ViewBookAppointmentDrList
                handelCloseViewBookAppointmentDrList={() => {
                  handelCloseViewBookAppointmentDrList();
                  setRegisteredPatient(null);
                }}
                closeAll={closeAll}
                // openViewFullSlot={() => {
                //   setViewBookAppointmentDrList(false);
                //   setViewBookAppointment(true);
                // }}
                openViewFullSlot={openViewFullSlot}
                handelOpenAppointmentBooking={handelOpenAppointBook}
                handelCloseAppointBooking={handelCloseAppointBooking}
                refreshbookAppointMent={refreshbookAppointMent}
                bookAppointClass={bookAppointClass}
                registeredPatient={registeredPatient}
              />
            )}
            {viewBookedAppointmentConsultation && (
              <ViewBookedAppointmentConsultation
                handelCloseViewBookedAppointmentConsultation={
                  handelCloseViewBookedAppointmentConsultation
                }
                closeAll={closeAll}
                openAppoinmentBooking={(data) => {
                  setAddAppointmentBooking(true);
                  setAppoinmentData(data);
                }}
                refreshBookedAppoinments={refreshBookedAppoinments}
              />
            )}
            {addAppointmentBooking && (
              <RightFlotingAnalyticsDashboard>
                <AddAppointmentBooking
                  handelCloseAppointmentBooking={handelCloseAppointmentBooking}
                  appoinmentData={appoinmentData}
                  updateBookAppoinments={() => {
                    setRefreshBookedAppoinments((prev) => prev + 1);
                  }}
                  closeBookConsultation={() => {
                    // setAddBookConsultation(false);
                    handelCloseAppointmentBooking();
                    setViewBookConsultation(true);
                    setShouldUpdateQueueList((prev) => prev + 1);
                  }}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {addAppointmBook && (
              <RightFlotingAnalyticsDashboard>
                <AddNewAppointBooking
                  handelCloseAppointmentBooking={handelCloseAppointBooking}
                  appoinmentData={dataForAppointBook}
                  refreshBookAppointmentChange={refreshBookAppointmentChange}
                  handelCloseAllViewBookAppointment={
                    handelCloseAllViewBookAppointment
                  }
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {viewReferredPatient && (
              <ViewReferredPatient
                handleCloseViewReferredPatient={handleCloseViewReferredPatient}
                closeAll={closeAll}
              />
            )}

            {resheduleDrAppointment && (
              <RightFlotingAnalyticsDashboard>
                <ResheduleDoctorAppoinment
                  handleCloseResheduleDrAppointment={
                    handleCloseResheduleDrAppointment
                  }
                  appoinmentData={resheduleDrAppointmentData}
                  refresReschduleDocAppointData={refresReschduleDocAppointData}
                />
              </RightFlotingAnalyticsDashboard>
            )}

            {viewReviewPatient && (
              <ViewReviewPatient
                handelCloseViewReviewPatient={handelCloseViewReviewPatient}
                closeAll={closeAll}
                handleBookConsultationData={(data) => {
                  setReviewConsultationData(data);
                }}
              />
            )}

            {reviewConsultationData && (
              <RightFlotingAnalyticsDashboard>
                <AddReviewPatientToQueue
                  data={reviewConsultationData}
                  handleCloseAddReviewPatientToQueue={() => {
                    setReviewConsultationData(null);
                  }}
                  closeAll={closeAll}
                />
              </RightFlotingAnalyticsDashboard>
            )}

            {conversationData && (
              <RightFlotingAnalyticsDashboard>
                <Conversation
                  data={conversationData}
                  handleCloseConversation={() => {
                    setConversationData(null);
                  }}
                  closeAll={closeAll}
                />
              </RightFlotingAnalyticsDashboard>
            )}

            {investigationUnits && (
              <RightFlotingAnalyticsDashboard>
                <AddInvestigationUnits
                  registeredPatient={registeredPatient}
                  handelCloseAddInvestigationUnit={() => {
                    handelCloseAddInvestigationUnit();
                    setRegisteredPatient(null);
                  }}
                />
              </RightFlotingAnalyticsDashboard>
            )}

            {viewInvestigationAdmin && (
              <ViewInvestigationAdmin
                handleCloseViewInvestigationAdmin={
                  handleCloseViewInvestigationAdmin
                }
                closeAll={closeAll}
                handelOpenAddInvestigationAdmin={
                  handelOpenAddInvestigationAdmin
                }
                handleOpenUnitInvestigationAdmin={
                  handleOpenUnitInvestigationAdmin
                }
                handelOpenAddInvestigationParameter={
                  handelOpenAddInvestigationParameter
                }
              />
            )}

            {addInvestigationAdmin && (
              <RightFlotingAnalyticsDashboard>
                <AddInvestigationAdmin
                  handelCloseAddInvestigationAdmin={
                    handelCloseAddInvestigationAdmin
                  }
                />
              </RightFlotingAnalyticsDashboard>
            )}

            {addUnitInvestigationAdmin && (
              <RightFlotingAnalyticsDashboard>
                <AddInvestigationAdminUnit
                  handleCloseUnitInvestigationAdmin={
                    handleCloseUnitInvestigationAdmin
                  }
                  addUnitInvestigationAdminData={addUnitInvestigationAdminData}
                />
              </RightFlotingAnalyticsDashboard>
            )}

            {addInvestigationParameter && (
              <RightFlotingAnalyticsDashboard>
                <AddInvestigationParameter
                  handelCloseAddInvestigationParameter={
                    handelCloseAddInvestigationParameter
                  }
                />
              </RightFlotingAnalyticsDashboard>
            )}

            {viewFullManagement && (
              <ViewManagementAnalyticDashboard
                menuId={viewFullManagement}
                onCloseMore={onCloseMore}
                closeAll={closeAll}
              />
            )}
            {/* healthcare end  /////////////////////////////////////////////////////////////////////// */}
            {showDetailPayroll && (
              <RightFlotingAnalyticsDashboard>
                <ViewPayrollSalaryStructure
                  handelCloseDetailPayroll={handelCloseDetailPayroll}
                  selectedEmPayroll={selectedEmPayroll}
                  refreshEmployPayrollData={refreshEmployPayrollData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showPayrollPayslip && (
              <RightFlotingAnalyticsDashboard>
                <ViewPayrollPayslip
                  handelClosePayrollPayslip={handelClosePayrollPayslip}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showAddPeople && (
              <RightFlotingAnalyticsDashboard>
                <AddPeople
                  handelCloseAddPeople={handelCloseAddPeople}
                  refreshViewPeopleData={refreshViewPeopleData}
                  fetchDashBoardData={fetchDashBoardDataForPatient}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showAddLeaveRules && (
              <RightFlotingAnalyticsDashboard>
                <AddLeaveRules
                  handelCloseAddLeaveRules={handelCloseAddLeaveRules}
                  refreshAddLeaveRulesData={refreshAddLeaveRulesData}
                  fetchDashBoardData={fetchDashBoardDataForPatient}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {updateLeaveRule && (
              <RightFlotingAnalyticsDashboard>
                <EditLeaveRules
                  handleCloseUpdateLeaveRule={handleCloseUpdateLeaveRule}
                  refreshAddLeaveRulesData={refreshAddLeaveRulesData}
                  selectedUpdateLeaveRule={selectedUpdateLeaveRule}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {viewSelectedLeaveRule && (
              <RightFlotingAnalyticsDashboard>
                <SelectedViewLeaveRule
                  refreshAddLeaveRulesData={refreshAddLeaveRulesData}
                  handleCloseViewSelectedLeaveRule={
                    handleCloseViewSelectedLeaveRule
                  }
                  viewSelectedLeaveRuleData={viewSelectedLeaveRuleData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showAddBudget && (
              <RightFlotingAnalyticsDashboard>
                <AddBudget
                  handelCloseAddBudget={handelCloseAddBudget}
                  fetchDashBoardData={fetchDashBoardDataForPatient}
                  refreshBudgetData={refreshBudgetData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showAddItems && (
              <RightFlotingAnalyticsDashboard>
                <AddItems
                  handelCloseAddItems={handelCloseAddItems}
                  editItem={editItem}
                  resetEditItem={(e) => {
                    setEditItem(null);
                  }}
                  updateItemList={() => {
                    setShouldItemUpdateList((prev) => prev + 1);
                  }}
                  fetchDashBoardData={fetchDashBoardDataForPatient}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {/* {showAddPolicies && (
              <RightFlotingAnalyticsDashboard>
                <AddPolicies
                  handelCloseAddPolicies={handelCloseAddPolicies}
                  refreshData={refreshData}
                />
              </RightFlotingAnalyticsDashboard>
            )} */}
            {showAddRoutineWork && (
              <RightFlotingAnalyticsDashboard isExpanded={routineWorkExpanded}>
                <AddRoutineWork
                  isExpanded={routineWorkExpanded}
                  setIsExpanded={(data) => {
                    setRoutineWorkExpanded(data);
                  }}
                  handelCloseAddRoutineWork={handelCloseAddRoutineWork}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {viewUserAttendanceData != null && (
              <RightFlotingAnalyticsDashboard>
                <ViewUserAttendance
                  handelCloseViewUserAttend={handelCloseViewUserAttend}
                  viewUserAttendanceData={viewUserAttendanceData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showAddHoliday && (
              <RightFlotingAnalyticsDashboard>
                <AddHoliday
                  handelCloseAddHoliday={handelCloseAddHoliday}
                  refresHolidayData={refresHolidayData}
                  fetchDashBoardData={fetchDashBoardDataForPatient}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showEditHoliday && (
              <RightFlotingAnalyticsDashboard>
                <EditHoliday
                  handelCloseEditHoliday={handelCloseEditHoliday}
                  refresHolidayData={refresHolidayData}
                  selecttedHolidayData={selecttedHolidayData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showSelectedViewHoliday && (
              <RightFlotingAnalyticsDashboard>
                <ViewSelectedHoliday
                  handelCloseSelectedViewHoliday={
                    handelCloseSelectedViewHoliday
                  }
                  refresHolidayData={refresHolidayData}
                  selecttedViewHolidayData={selecttedViewHolidayData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showAddWorkingday && (
              <RightFlotingAnalyticsDashboard>
                <AddWorkingDays
                  handelCloseAddWorkingday={handelCloseAddWorkingday}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {addWorkingShift && (
              <RightFlotingAnalyticsDashboard>
                <AddShift
                  handleCloseAddWorkingShift={handleCloseAddWorkingShift}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showAddExternalUser && (
              <RightFlotingAnalyticsDashboard>
                <AddExternalUser
                  handelCloseAddExternalUser={handelCloseAddExternalUser}
                  refreshExternalUserData={refreshExternalUserData}
                  fetchDashBoardData={fetchDashBoardDataForPatient}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showSelectedExternalUser && (
              <RightFlotingAnalyticsDashboard>
                <EditExternalUser
                  handelCloseEditExternalUser={handelCloseEditExternalUser}
                  refreshExternalUserData={refreshExternalUserData}
                  selecttedViewExternalUser={selecttedViewExternalUser}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showAddContract && (
              <RightFlotingAnalyticsDashboard>
                <AddContract
                  handelCloseAddContract={() => {
                    handelCloseAddContract();
                    setSelectedContractData(null);
                  }}
                  refreshContractData={refreshContractData}
                  fetchDashBoardData={fetchDashBoardDataForPatient}
                  contractData={selectedContractData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {editEmployee && (
              <RightFlotingAnalyticsDashboard>
                <EditPeople
                  handelCloseEditEmployee={handelCloseEditEmployee}
                  refreshViewPeopleData={refreshViewPeopleData}
                  fetchDashBoardData={fetchDashBoardDataForPatient}
                  editEmployeesData={editEmployeesData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
