import React from "react";
import QueueHistoryTable from "./QueueHistoryTable";
import CardManagementConsultation from "../../CardManagementConsultation";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { useState } from "react";
import { useEffect } from "react";
import { GetLoginUserDetails } from "../../../../../../../utils/Utils";

export default function ConsultationAnalyticDetails({ selectedMenu }) {
const [managementData, setManagementData] = useState({});
const userDetails = GetLoginUserDetails();

  useEffect(() => {
    fetchRefreshManagementData();
  }, []);

  const fetchRefreshManagementData = () => {
    if (userDetails) {
      const reqDto = {
        unitId: 0,
        userId: userDetails.userId,
        consultationOrgId: userDetails.orgId,
        consultationPeriodDailyMnthly: "Daily",
        consultationDataLimit : 0,
        consultationUnitId: 0
      };
      console.log("reqDto", reqDto);
      DashboardServices.refreshManagementConsultation(reqDto).then(
        (response) => {
          console.log("response", response.data);
          setManagementData(response.data);
        }
      );
    }
  };

  return (
    <>
      <div className="overViewConatiner">
        {selectedMenu === "_const_graph_" && (
          <CardManagementConsultation
            showMoreBtn={false}
            chartDataOfItem={managementData}
          />
        )}
        {selectedMenu === "_queue_history_" && <QueueHistoryTable />}
      </div>
    </>
  );
}
