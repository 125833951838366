import React, { useRef, useState } from "react";
import styles from "../css/TicketForm.module.css";
import { GetLoginUserDetails } from "../../../utils/Utils";
import SupportService from "../../../services/SupportService";
import { toast } from "react-toastify";
import { Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { AttachFile } from "@mui/icons-material";

const TicketForm = ({ onTicketCreated }) => {
  const { t } = useTranslation();
  const loginUserDetails = GetLoginUserDetails();
  const supportImageRef = useRef(null);
  const [ticketType, setTicketType] = useState("Software");
  const [formData, setFormData] = useState({
    issueCategory: "Software",
    issueName: "",
    issueDescription: "",
    priority: "",
    uploadedFiles: [],
    orgId: loginUserDetails.orgId,
    unitId: loginUserDetails.signleUnitId,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleTicketTypeChange = (type) => {
    setTicketType(type);
    setFormData((prevData) => ({
      ...prevData,
      issueCategory: type,
    }));
  };

  const handleImageUpload = (e) => {
    const files = e.target.files;
    const fileArray = Array.from(files);

    fileArray.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const extension = file.name.split(".").pop();
        setFormData((prevData) => ({
          ...prevData,
          uploadedFiles: [
            ...prevData.uploadedFiles,
            {
              field1: file.name,
              field2: reader.result,
              field3: extension,
            },
          ],
        }));
      };
      reader.readAsDataURL(file); // Converts the image to base64
    });
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const toastLoaderId = toast.loading(
      t("creating_support_ticket_please_do_not_close_the_page")
    );
    console.log("Form data: ", formData);

    const response = await SupportService.createSupportTicket(
      formData,
      loginUserDetails.userId
    );
    console.log("createSupportTicket::", response.data);
    if (response.data.returnValue === "1") {
      setLoading(false);
      toast.update(toastLoaderId, {
        render: t("ticket_created_successfully"),
        type: "success",
        isLoading: false,
        autoClose: true,
      });
      onTicketCreated(response.data);
    } else {
      setLoading(false);
      toast.update(toastLoaderId, {
        render: t("something_went_wrong"),
        type: "error",
        isLoading: false,
        autoClose: true,
      });
    }
  };

  return (
    <div className={styles.ticketFormContainer}>
      <div className={styles.header}>
        {/* <h2>{ticketType} {t("ticket")}</h2> */}
        <h2>{t(`${ticketType.toLowerCase()}_ticket`)}</h2>
        <div className={styles.ticketSwitch}>
          <button
            className={ticketType === "Software" ? styles.active : ""}
            onClick={() => handleTicketTypeChange("Software")}
          >
            {t("software")}
          </button>
          <button
            className={ticketType === "Hardware" ? styles.active : ""}
            onClick={() => handleTicketTypeChange("Hardware")}
          >
            {t("hardware")}
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <label htmlFor="summary">{t("issue_name")}</label>
        <input
          type="text"
          id="issueName"
          name="issueName"
          value={formData.issueName}
          onChange={handleChange}
          required
        />

        <label htmlFor="description">{t("specifics_max_characters")}</label>
        <textarea
          id="issueDescription"
          name="issueDescription"
          value={formData.issueDescription}
          onChange={handleChange}
          maxLength="2000"
          required
        />

        <div className={styles.prioritySection}>
          <label>{t("priority")}</label>
          <div className={styles.priorityOptions}>
            <label>
              <input
                type="radio"
                name="priority"
                value="Low"
                checked={formData.priority === "Low"}
                onChange={handleChange}
                required
              />
              {t("low")}
            </label>
            <label>
              <input
                type="radio"
                name="priority"
                value="Medium"
                checked={formData.priority === "Medium"}
                onChange={handleChange}
                required
              />
              {t("medium")}
            </label>
            <label>
              <input
                type="radio"
                name="priority"
                value="High"
                checked={formData.priority === "High"}
                onChange={handleChange}
                required
              />
              {t("high")}
            </label>
            <label>
              <input
                type="radio"
                name="priority"
                value="Change request"
                checked={formData.priority === "Change request"}
                onChange={handleChange}
                required
              />
              {t("change_request")}
            </label>
          </div>
        </div>

        <div className={styles.uploadSection}>
          <label htmlFor="uploadedFiles">{t("images_of_the_issue")}:</label>
          <div className="formBtnElement">
                  <Button
                    variant="outlined"
                    startIcon={<AttachFile />}
                    // className="comBtnOutline addHolidayStepFive"
                    className={`comBtnOutline  ${
                      formData?.uploadedFiles && formData.uploadedFiles.length > 0
                        ? "activeStatus"
                        : ""
                    }`}
                    onClick={() => supportImageRef.current.click()}
                  >
                    {t("attach_file")}
                  </Button>
          <input
          ref={supportImageRef}
            hidden
            type="file"
            id="uploadedFiles"
            name="uploadedFiles"
            accept="image/*"
            multiple
            // onChange={handleImageUpload}
            onChange={(e) => {
              handleImageUpload(e);
              supportImageRef.current.value = null;
            }}
          />
          </div>
          <div className={styles.imagePreview}>
            {formData.uploadedFiles.map((file, index) => (
              <>
                <img src={`${file.field2}`} alt={`Uploaded Preview ${index}`} />
                {/* <Trash/> */}
              </>
            ))}
          </div>
        </div>

        <button type="submit" disabled={loading}>
          {loading ? t("please_wait") : t("submit_ticket")}
        </button>
      </form>
    </div>
  );
};

export default TicketForm;
