import { Add, Edit } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { all } from "axios";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import {
  getActiveSalaryComponents,
  getSalaryHeads,
} from "../../../../services/AdminService";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import { green } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import IntegerTextField from "../../../../common/IntegerTextField";
import { FloatTextField } from "../../../../common/FloatTextField";

export const LoiSalaryTable = ({
  salaryInfoList,
  setSalaryInfoList,
  gpm,
  salaryBrkUps,
  ctcAmouunt,
  shouldCalculate,
  setShouldCalculate,
  currency,
  // setCtc,
}) => {
  const loginUserDetails = GetLoginUserDetails();
  const { t } = useTranslation();
  const [salaryHeadList, setSalaryHeadList] = useState([]);
  const [allTimeFormData, setAllTimeFormData] = useState({
    salaryHead: "",
    percent: "",
    grossOrBasic: null,
    amount: 0,
    monthly: 0,
    annual: 0,
    editMode: true,
  });

  const [calculatedSalaryBasiId, setCalculatedSalaryBasiId] = useState([]);
  const [calculatedSalaryBreakup, setCalculatedSalaryBreakup] = useState([]);
  const [salComponent, setSalComponent] = useState([]);
  const [tempSelComponent, setTempSelComponent] = useState([]);

  const computeSalaryBreakupInfo = () => {
    let prevTotalSalaryAmount = 0;
    let temp = [...salaryBrkUps];
    //
    // const gpmWithoutComma = gpm ? gpm.replace(/,/g, "") : 0;
    while (prevTotalSalaryAmount < salaryBrkUps.length) {
      for (let i = 0; i < temp.length; i++) {
        let headItem = temp[i];
        const calculationBasisHeadId = headItem.pcOnSalaryHeadId;
        const basisHeadObj = salaryBrkUps.find(
          (salaryHead) => salaryHead.salaryHeadId === calculationBasisHeadId
        );

        temp[i] = {
          ...headItem,
          basisHeadObj,
        };
        prevTotalSalaryAmount++;
      }
      // return;
    }

    setCalculatedSalaryBreakup(temp);
    // setSalaryInfoList(temp);
    // return temp;
  };

  useEffect(() => {
    if (salaryBrkUps && salaryBrkUps.length > 0) {
      computeSalaryBreakupInfo();
    }
  }, [salaryBrkUps]);

  useEffect(() => {
    if (!salaryBrkUps || (salaryBrkUps && salaryBrkUps.length === 0)) {
      //
      let prevTotalSalaryAmount = 0;
      let temp = [...salaryHeadList];
      const gpmWithoutComma = gpm ? gpm.replace(/,/g, "") : 0;
      while (prevTotalSalaryAmount < salaryHeadList.length) {
        for (let i = 0; i < temp.length; i++) {
          let headItem = temp[i];
          const calculationBasisHeadId = headItem.calculationBasisHeadId;
          let parentamount = null;
          if (headItem.amount !== null || headItem.amount !== undefined) {
            if (calculationBasisHeadId == 0) {
              parentamount =
                (parseFloat(gpmWithoutComma) * headItem.calculationPercentage) /
                100;
              prevTotalSalaryAmount++;
            }
            const basisHeadObj = temp.find(
              (salaryHead) => salaryHead.salaryHeadId === calculationBasisHeadId
            );
            if (basisHeadObj && basisHeadObj.amount >= 0) {
              parentamount =
                (basisHeadObj.amount * headItem.calculationPercentage) / 100;
              if (isNaN(parentamount)) {
                parentamount = 0;
              }

              prevTotalSalaryAmount++;
            }
            temp[i] = {
              ...headItem,
              basisHeadObj,
            };
            if (parentamount !== null) {
              temp[i] = {
                ...headItem,
                basisHeadObj,
                amount: parseFloat(parentamount.toFixed(2)),
                annual: parseFloat((parentamount * 12).toFixed(2)),
              };
            }
          }
        }
        // return;
      }

      setCalculatedSalaryBasiId(temp);
      // setSalaryInfoList(temp);
    }
  }, [salaryHeadList, gpm]);

  useEffect(() => {
    getActiveSalaryComponents(
      loginUserDetails.orgId,
      loginUserDetails.userId,
      loginUserDetails.orgCountryCode
    ).then((response) => {
      if (response.data) {
        setSalComponent(response.data);
      }
    });

    getSalaryHeads(loginUserDetails.orgId).then((response) => {
      if (response.data) {
        setSalaryHeadList(response.data);
      }
    });
  }, []);

  const initializeAllTimeFormData = () => {
    setAllTimeFormData({
      salaryHead: "",
      percent: "",
      grossOrBasic: null,
      amount: 0,
      monthly: 0,
      annual: 0,
      editMode: true,
    });
  };

  const calculateMonthlyTotal = () => {
    let infoList = [];
    if (salaryBrkUps && salaryBrkUps.length > 0) {
      infoList = [...salaryBrkUps];
    } else {
      infoList = [...calculatedSalaryBasiId];
    }
    let total = 0;
    if (!isNaN(allTimeFormData.amount)) {
      total += allTimeFormData.amount;
    }
    for (let i = 0; i < infoList.length; i++) {
      if (salaryBrkUps && salaryBrkUps.length > 0) {
        total += infoList[i].monthly;
      } else {
        total += infoList[i].amount;
      }
    }

    return total;
  };

  const calculateAnnualTotal = () => {
    let total = 0;
    let infoList = [];
    //
    if (salaryBrkUps && salaryBrkUps.length > 0) {
      infoList = [...salaryBrkUps];
    } else {
      infoList = [...calculatedSalaryBasiId];
    }
    if (!isNaN(allTimeFormData.annual)) {
      total += allTimeFormData.annual;
    }
    for (let i = 0; i < infoList.length; i++) {
      if (salaryBrkUps && salaryBrkUps.length > 0) {
        total += infoList[i].yearly;
      } else {
        total += infoList[i].annual;
      }
    }
    // setCtc(total);
    return total;
  };

  const handleUpdateSalaryItem = (index) => {
    const temp = [...salaryInfoList];
    let isValid = true;
    if (temp[index].salaryHead === "") {
      toast.error(t("please_select_salary_head"));
      isValid = false;
    }
    if (temp[index].percent === "") {
      toast.error(t("please_select_percent"));
      isValid = false;
    }
    if (!temp[index].grossOrBasic) {
      toast.error(t("please_select_gross_or_basic"));
      isValid = false;
    }
    if (!temp[index].amount) {
      toast.error(t("please_select_amount"));
      isValid = false;
    }
    if (isValid) {
      temp[index].editMode = false;
      setSalaryInfoList(temp);
    }
  };

  const calculateCtcValues = (ctcVal, salComponent) => {
    if (ctcVal === null || ctcVal === undefined || ctcVal === "") {
      toast.error(t("please_provide_ctc_value"));
      setShouldCalculate(false);
      return;
    }
    let ctc = ctcVal?.replace(/,/g, "") || 0;
    let temp = [...salComponent];
    let basicAmount = 0;
    const ctcPercent = salComponent.filter(
      (item) => item.calculationType === "% of CTC"
    );
    const basedOnBasic = salComponent.filter(
      (item) => item.calculationType === "% of Basic"
    );
    const fixedValued = salComponent.filter(
      (item) => item.calculationType === "Flat" && item.flatAmountPc !== null
    );
    const nonFixed = salComponent.filter(
      (item) => item.calculationType === "Flat" && item.flatAmountPc === null
    );

    let remaining = parseFloat(ctc) || 0;
    ctcPercent.forEach((element) => {
      //
      const indx = temp.findIndex(
        (tmp) => element.salaryComponentId === tmp.salaryComponentId
      );
      if (indx >= 0) {
        const value = (parseFloat(ctc) * element.flatAmountPc) / 100;
        //
        if (value <= remaining) {
          temp[indx].monthlyAmt = Math.round(value / 12);
          temp[indx].anualAmt = value;
          remaining -= value;

          if (element.componentName?.toLowerCase() === "basic") {
            basicAmount = value;
          }
        } else {
          temp[indx].anualAmt = remaining;
          temp[indx].monthlyAmt = Math.round(remaining / 12);
          if (element.componentName?.toLowerCase() === "basic") {
            basicAmount = remaining;
          }
          remaining = 0;
        }
      }
    });

    basedOnBasic.forEach((element) => {
      const indx = temp.findIndex(
        (tmp) => element.salaryComponentId === tmp.salaryComponentId
      );
      if (indx) {
        const value = (parseFloat(basicAmount) * element.flatAmountPc) / 100;

        if (value <= remaining) {
          temp[indx].monthlyAmt = Math.round(value / 12);
          temp[indx].anualAmt = value;
          remaining -= value;
        } else {
          temp[indx].anualAmt = remaining;
          temp[indx].monthlyAmt = Math.round(remaining / 12);
          remaining = 0;
        }
      }
    });

    fixedValued.forEach((element) => {
      const indx = temp.findIndex(
        (tmp) => element.salaryComponentId === tmp.salaryComponentId
      );
      if (indx) {
        let value = element.flatAmountPc;
        if (element.periodicity == "Monthly") {
          value = element.flatAmountPc * 12;
        }

        if (value <= remaining) {
          temp[indx].monthlyAmt = Math.round(value / 12);
          temp[indx].anualAmt = value;
          remaining -= value;
        } else {
          temp[indx].anualAmt = remaining;
          temp[indx].monthlyAmt = Math.round(remaining / 12);
          remaining = 0;
        }
      }
    });

    nonFixed.forEach((element) => {
      const indx = temp.findIndex(
        (tmp) => element.salaryComponentId === tmp.salaryComponentId
      );
      if (indx) {
        const value = parseFloat(remaining) / nonFixed.length;
        if (value <= remaining) {
          temp[indx].monthlyAmt = Math.round(value / 12);
          temp[indx].anualAmt = value;
          remaining -= value;
        } else {
          temp[indx].anualAmt = remaining;
          temp[indx].monthlyAmt = Math.round(remaining / 12);
          remaining = 0;
        }
      }
    });

    // const temp = [...ctcPercent, ...basedOnBasic, ...fixedValued, ...nonFixed];

    setTempSelComponent(temp || []);
    setSalaryInfoList(temp || []);
    setShouldCalculate(false);
  };

  const monthlyTotal = useMemo(() => {
    // setTempSelComponent()
    const value = tempSelComponent?.reduce((acc, curr) => {
      const { monthlyAmt } = curr;
      let temp = 0;
      if (monthlyAmt) {
        temp = monthlyAmt;
      }
      return acc + temp;
    }, 0);
    return value;
  }, [tempSelComponent]);

  const yearlyTotal = useMemo(() => {
    // setTempSelComponent()
    const value = tempSelComponent?.reduce((acc, curr) => {
      const { anualAmt } = curr;
      let temp = 0;
      if (anualAmt) {
        temp = anualAmt;
      }
      return acc + temp;
    }, 0);
    return value;
  }, [tempSelComponent]);

  useEffect(() => {
    if (shouldCalculate) {
      // setSalaryInfoList(calculatedSalaryBasiId);
      calculateCtcValues(ctcAmouunt, salComponent);
      // setSalaryInfoList(calculatedSalaryBreakup);
    }
  }, [shouldCalculate]);

  return (
    <div className="indentFormtable autoHeight">
      <div className="indtableResponsive">
        <table>
          <thead>
            <tr>
              {/* <th className="salaryHead">
                <span>Salary Head</span>
 
              </th>
              <th>Salary Head Group</th>
              <th className="width250">Enter %</th>
              <th className="tright">Monthly Amount</th>
              <th className="tright">Annually</th>
              {(!salaryBrkUps ||
                (salaryBrkUps && salaryBrkUps.length === 0)) && (
                <th className="width100"></th>
              )} */}
              <th></th>
              <th className="curntStrct textCenter" colSpan={2}>
                {t("amount")}
              </th>
              <th className="w80"></th>
            </tr>
            <tr>
              <th>{t("salary_head")}</th>
              <th className="curntStrct w180">{t("monthly")}</th>
              <th className="curntStrct w180">{t("annually")}</th>
              <th className="w80">{t("action")}</th>
            </tr>
          </thead>
          <tbody>
            {tempSelComponent?.map((item, index) => {
              return (
                <>
                  <tr>
                    <td>
                      <div class="textOnly">{item.componentName}</div>
                    </td>
                    <td className="w180 tright">
                      {item.periodicity === "Monthly" ? (
                        <div className="formElement inputPadding ">
                          <FormControl className="formControl">
                            <IntegerTextField
                              label={t("monthly_amount")}
                              variant="outlined"
                              className="formTextFieldArea"
                              // disabled={!item.editMode}
                              // required
                              fullWidth
                              size="small"
                              autoComplete="off"
                              value={item?.monthlyAmt || 0}
                              onChange={(data) => {
                                const temp = [...tempSelComponent];
                                temp[index].monthlyAmt = data;
                                temp[index].anualAmt = data * 12;
                                setTempSelComponent(temp);
                              }}
                            />
                          </FormControl>
                        </div>
                      ) : (
                        <div>{item.monthlyAmt}</div>
                      )}
                    </td>
                    <td className="w180 tright">
                      {item.periodicity === "Annually" ? (
                        <div className="formElement inputPadding ">
                          <FormControl className="formControl">
                            <IntegerTextField
                              label={t("annual_amount")}
                              variant="outlined"
                              className="formTextFieldArea"
                              // disabled={!item.editMode}
                              // required
                              fullWidth
                              size="small"
                              autoComplete="off"
                              value={item?.anualAmt || 0}
                              onChange={(data) => {
                                const temp = [...tempSelComponent];
                                temp[index].anualAmt = data;
                                temp[index].monthlyAmt = parseInt(data) / 12;
                                setTempSelComponent(temp);
                              }}
                            />
                          </FormControl>
                        </div>
                      ) : (
                        <div>{item.anualAmt}</div>
                      )}
                    </td>
                    <td>
                      {item.deleteable === "Y" && (
                        <IconButton
                          onClick={(e) => {
                            const temp = [...salComponent];
                            const filteredData = temp.filter(
                              (element) =>
                                element.salaryComponentId !==
                                item.salaryComponentId
                            );

                            const temp2 = [...tempSelComponent];
                            temp2.splice(index, 1);
                            setSalComponent(filteredData);
                            setTempSelComponent(temp2);
                            // calculateCtcValues();
                          }}
                        >
                          <Trash />
                        </IconButton>
                      )}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={1} className="tright">
                <div class="textOnly strong">{t("total")}</div>
              </th>
              <th className="tright">
                <div class="textOnly">{monthlyTotal}</div>
              </th>
              <th className="tright">
                <div class="textOnly">{yearlyTotal}</div>
              </th>
              <th>
                <div className="textOnly">{currency}</div>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};
