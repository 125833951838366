import {
  Add,
  ArrowBack,
  DescriptionRounded,
  FileDownload,
  Search,
  TodayRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import {
  Download,
  FileEarmarkArrowDownFill,
  Play,
  Prescription,
} from "react-bootstrap-icons";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DashboardServices from "../../../../../services/DashboardServices";
import DateUtils from "../../../../../utils/DateUtils";
import { apiEndPoint } from "../../../../../constants/url_provider";
import { toast } from "react-toastify";
// import zhPatientService from "../../../../../services/ZhPatientService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";
import { patSrcDispName } from "../../../../../constants/const_string";
import { useContext } from "react";
import { TourContext } from "../../../../../common/TourProvider";
import Joyride from "react-joyride";
import { GetLoginUserDetails } from "../../../../../utils/Utils";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function ViewPatientDocument({
  handelCloseViewPatientDocument,
  closeAll,
}) {
  const audioUrl = useRef();
  const userDetails = GetLoginUserDetails();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const { t, i18n } = useTranslation();
  const [patientDocumentData, setPatientDocumentData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryTestName, setSearchQueryTestName] = useState("");
  const [filterpatientDocumentData, setFilterPatientDocumentData] = useState(
    []
  );
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [primaryPosdate, setPrimaryPosdate] = useState(new Date());
  const handleJoiningprimaryPosdate = (newDate) => {
    setPrimaryPosdate(newDate);
  };

  useEffect(() => {
    setBookSteps([
      {
        target: ".viewDocStepOne",
        content: t("view_doc_header_navigation"),
        disableBeacon: true,
      },
      {
        target: ".viewDocStepTwo",
        content: t("use_filters_to_search_by_date_range"),
        disableBeacon: true,
      },
      {
        target: ".viewDocStepThree",
        content: t("search_for_patient_by_name_or_id"),
        disableBeacon: true,
      },
      {
        target: ".viewDocStepFour",
        content: t("list_of_patient_documents"),
        disableBeacon: true,
      },
    ]);
  }, [t]);
  

  // useEffect(() => {
  //   setBookSteps([
  //     {
  //       target: ".viewDocStepOne",
  //       content:
  //         "This is the header where you can navigate back or view the document title.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewDocStepTwo",
  //       content: "Use these filters to search documents by date range.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewDocStepThree",
  //       content: "Search for a specific patient by name or ID here.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewDocStepFour",
  //       content: "This is the list of patient documents based on your search.",
  //       disableBeacon: true,
  //     },
  //     // {
  //     //   target: ".viewDocStepFive",
  //     //   content: "Click here to search documents based on the selected filters.",
  //     //   disableBeacon: true,
  //     // },
  //   ]);
  // }, []);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const [primaryToDate, setPrimaryToDate] = useState(new Date());

  const handleToDateChange = (newDate) => {
    setPrimaryToDate(newDate);
  };

  const [selectPatient, setSelectPatient] = useState(null);
  const handleChangselectPatient = (patient) => {
    console.log("patientId ====", patient);
    setSelectPatient();
  };
  const [documentType, setDocumentType] = useState("PRESCRIPTION");
  const handleChangDocumentType = (event) => {
    setDocumentType(event.target.value);
  };

  useEffect(() => {
    fetchPatientdocumentData();
  }, []);

  const getMaxDate = (date1) => {
    const date2 = new Date();
    if (!date1) {
      return date2;
    }
    const tempDate = date1 < date2 ? date1 : date2;
    return tempDate;
  };

  const fetchPatientdocumentData = () => {
    console.log("fetch data");

    // const reqDto = {
    //   formDate: DateUtils.getDateInDDMMYYYY(primaryPosdate),
    //   toDate: DateUtils.getDateInDDMMYYYY(primaryToDate),
    //   patientId: 0,
    //   documentType: documentType,
    //   unitId: userDetails.signleUnitId,
    // };

    // console.log("reqDto", reqDto);

    const fromDate = DateUtils.getDateInDDMMYYYY(primaryPosdate);
    const toDate = DateUtils.getDateInDDMMYYYY(primaryToDate);

    DashboardServices.getPatientDocumentsOfUnit(
      userDetails.signleUnitId,
      fromDate,
      toDate
    ).then((response) => {
      console.log("response of get patient", response.data);
      setPatientDocumentData(response.data);
      setFilterPatientDocumentData(response.data);
      setLoading(false);
    });
  };

  console.log("patientDocumentData", patientDocumentData);

  const handleDownLoadFile = (folderName, fileName) => {
    // console.log("prescription", patient);
    if (fileName !== null) {
      toast.info(t("please_wait_file_is_getting_get_downloaded"), {
        position: toast.POSITION.TOP_RIGHT,
      });

      const newLink = fileName.replace(/\//g, "~");
      console.log("newLink", newLink);

      let newFolderLink = folderName.replace(/\//g, "~").replace(/\\/g, "~");
      console.log("newLink", newFolderLink);

      // let reqDto = {
      //   field1: newLink ,
      //   field2: patient.folderName+"/Prescription" ,
      //   field3: 300
      // }
      // zhPatientService.presigned(reqDto)
      // .then((response)=>{
      //   console.log('response', response.data);
      //    window.open(
      //     response.data ,
      //   "_blank",
      //   "noopener,noreferrer"
      // );
      // })

      window.open(
        apiEndPoint + `/appTest/downloadFileTest/${newLink}/${newFolderLink}`,
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      toast.error(t("the_file_cannot_be_downloaded"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDownLoadInvesticationFile = (test) => {
    console.log("test", test);
    if (test.fileLink) {
      window.open(test.fileLink, "_blank");
    } else {
      console.error("No file link available for download");
      toast.error(t("the_file_cannot_be_downloaded"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const [selectedTests, setSelectedTests] = useState([]);
  const [selectedPatientId, setSelectedPatientId] = useState(0);

  // const handleCheckboxChange = (requsitionId , patientId) => {
  //   console.log('requsitionId' , requsitionId)
  //   console.log('patientId', patientId)
  //   setSelectedPatientId(patientId)
  //   setSelectedTests((prevSelectedTests) =>
  //     prevSelectedTests.includes(requsitionId)
  //       ? prevSelectedTests.filter((id) => id !== requsitionId)
  //       : [...prevSelectedTests, requsitionId]
  //   );
  // };

  const handleCheckboxChange = (requsitionId, patientId) => {
    console.log("requsitionId:", requsitionId);
    console.log("patientId:", patientId);
    setSelectedPatientId(patientId);

    const patient = patientDocumentData.find(
      (patient) => patient.patientId === patientId
    );

    console.log("patient:", patient);

    if (patient) {
      const testExists = patient.tests?.some(
        (test) => test.requsitionId === requsitionId
      );

      console.log("Test exists:", testExists);

      setSelectedTests((prevSelectedTests) => {
        if (testExists) {
          const matchingTests =
            patient.tests?.map((test) => test.requsitionId) || [];
          if (prevSelectedTests.includes(requsitionId)) {
            return prevSelectedTests.filter(
              (id) => id !== requsitionId && matchingTests.includes(id)
            );
          } else {
            return [
              ...prevSelectedTests.filter((id) => matchingTests.includes(id)),
              requsitionId,
            ];
          }
        } else {
          return prevSelectedTests;
        }
      });
    } else {
      console.log("Patient not found");
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = patientDocumentData.filter(
      (patientDocument) =>
        (patientDocument.patientName &&
          patientDocument.patientName
            .toLowerCase()
            .includes(query.toLowerCase())) ||
        (patientDocument.patientIdDisplay &&
          patientDocument.patientIdDisplay
            .toLowerCase()
            .includes(query.toLowerCase())) ||
        (patientDocument.patientExternalSourceId &&
          patientDocument.patientExternalSourceId
            .toLowerCase()
            .includes(query.toLowerCase()))
    );

    setFilterPatientDocumentData(filteredData);
  };

  const handleSearchTestName = (e) => {
    const query = e.target.value;
    setSearchQueryTestName(query);

    if (query.trim() === "") {
      setFilterPatientDocumentData(patientDocumentData);
      return;
    }

    const filteredData = patientDocumentData.filter((patientDocument) => {
      console.log("patientDocument?.tests", patientDocument?.tests);
      if (patientDocument?.tests) {
        for (let index = 0; index < patientDocument?.tests?.length; index++) {
          const element = patientDocument?.tests[index];
          return (
            element.testName &&
            element.testName.toLowerCase().includes(query.toLowerCase())
          );
        }
      } else {
        return false;
      }
    });

    setFilterPatientDocumentData(filteredData);
  };

  const handleGenerateReport = (patientId) => {
    console.log("selectedTests", selectedTests);
    console.log("patientId", patientId);

    DashboardServices.getTestReport(
      patientId,
      userDetails.signleUnitId,
      selectedTests
    )
      .then((response) => {
        console.log("response getTestReport", response.data);

        const byteCharacters = atob(response.data.fileInBytes);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], { type: "application/pdf" });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = response.data.fileName;
        link.click();

        window.URL.revokeObjectURL(link.href);
        setSelectedTests([]);
      })
      .catch((error) => {
        console.error("Error generating report:", error);
      });
  };

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["viewPatDocns"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('finish'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="AnalyticsDashboardContainer nspatientDocTwo">
        <div className="AnalyticsDashboardSubHeader viewDocStepOne">
          <IconButton
            onClick={() => {
              handelCloseViewPatientDocument();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("patient_documents")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              <div className="formSearchGrp viewDocStepTwo">
                <div className="formElement width180">
                  <FormControl className="formControl">
                    <InputLabel id="primPosition" className="setCompletionDate">
                      {t("from_date")}
                    </InputLabel>
                    <ReactDatePicker
                      locale={currentLocale}
                      toggleCalendarOnIconClick
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      showIcon
                      labelId="primPosition"
                      className="formDatePicker"
                      selected={primaryPosdate}
                      // minDate={new Date()}
                      // maxDate={primaryPosdate}
                      maxDate={primaryToDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        handleJoiningprimaryPosdate(date);
                      }}
                    />
                  </FormControl>
                </div>
                <div className="formElement width180">
                  <FormControl className="formControl">
                    <InputLabel id="primPosition" className="setCompletionDate">
                      {t("to_date")}
                    </InputLabel>
                    <ReactDatePicker
                      locale={currentLocale}
                      toggleCalendarOnIconClick
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      showIcon
                      labelId="primPosition"
                      className="formDatePicker"
                      selected={primaryToDate}
                      // minDate={new Date()}
                      // maxDate={new Date()}
                      minDate={primaryPosdate}
                      maxDate={getMaxDate(
                        primaryPosdate
                          ? new Date(
                              primaryPosdate.getFullYear(),
                              primaryPosdate.getMonth() + 3,
                              primaryPosdate.getDate()
                            )
                          : null
                      )}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        handleToDateChange(date);
                      }}
                    />
                  </FormControl>
                </div>
                <Button
                  className="dfultPrimaryBtn"
                  onClick={fetchPatientdocumentData}
                  startIcon={<Search />}
                >
                  {t("search")}
                </Button>
              </div>
              <div className="searchTasklist patDocSrchMainDiv">
                <div className="searchTasklist testNameSearch">
                  <input
                    type="text"
                    class="serchTaskInput "
                    placeholder={t("test_name")}
                    value={searchQueryTestName}
                    onChange={handleSearchTestName}
                  />
                  <IconButton className="seacrhIcon">
                    <Search />
                  </IconButton>
                </div>

                <div className="searchTasklist srcPatViewDoc viewDocStepThree">
                  <input
                    type="text"
                    class="serchTaskInput"
                    placeholder={
                      t("patient_name") +
                      " / " +
                      patSrcDispName +
                      " " +
                      t("patient_id")
                    }
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                  <IconButton className="seacrhIcon">
                    <Search />
                  </IconButton>
                </div>
              </div>
            </div>

            <div className="cuContainArea">
              {/* table prescription start */}
              {/* {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : patientDocumentData.length === 0 ? (
                <div class="noDataCard ">
                  <span>{t("oops")}</span>
                  <br />
                  <span className="sallMsg">
                    {t("no_patient_document_data_is_present")}
                  </span>
                </div>
              ) : (
                <div className="TableContainer">
                  <table className="taskListTable">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th>
                          <div className="textWsearch">
                            <span>{t("patient_name")}</span>

                            <div className="searchTasklist">
                              <input
                                type="text"
                                class="serchTaskInput"
                                placeholder={t("search_patient")}
                                value={searchQuery}
                                onChange={handleSearch}
                              />
                            </div>
                          </div>
                        </th>

                        <th>{t("consulting_doctor")}</th>
                        <th>{t("consulting_start_time")}</th>
                        <th>{t("consulting_end_time")}</th>

                        <th className="width100">{t("document")}</th>
                      </tr>
                    </thead>
                    <tbody className="scrolableTableBody">
                      {filterpatientDocumentData.map((patient, index) => (
                        <tr key={index}>
                          <td>
                            <div className="tskLstTaskNM tdFdC">
                              <span className="tdHtext">
                                {patient.patientName}
                              </span>
                              <span className="tdStext">
                                ({patient.gender}, {patient.age})
                              </span>
                            </div>
                          </td>

                          <td>
                            <div className="tskLstTaskNM tdFdC">
                              <span className="tdHtext">
                                {patient?.doctorName?.split(".").join(". ")}
                              </span>
                              <span className="tdStext">
                                ({patient?.doctorSpecilization})
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {patient.consultStartTime}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {patient.consultEndTime}
                            </div>
                          </td>

                          <td>
                            <div className="tblActionBtnGrp jcc">
                              <Tooltip title={t("download_prescription")} arrow>
                                <IconButton
                                  className="dnPresc"
                                  onClick={() => handleDownLoadFile(patient)}
                                >
                                  <FileEarmarkArrowDownFill />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )} */}
              {/* table prescription end */}

              {/* card patientDtl start */}
              {/* <div className="patDocList">
                <div className="patDocIndCard">
                  <div className="patDetails">
                    <div className="patname">Sanket Santra</div>
                    <div className="patAge">
                      <span>Age:</span> 33Y 6M 20D
                    </div>
                    <div className="patAge">
                      <span>Gender:</span> Male
                    </div>
                  </div>
                  <div className="patDocDetails">
                    <div className="patDocPrs">
                      <div className="docHead">Prescription</div>
                      <div className="docPrsGrp">
                        <div className="prsDtl">
                          <div className="docDtl">
                            <span className="docName">Dr. Sk Aftabuddin</span>
                            <span className="docSpecl">(General Medicine)</span>
                          </div>
                          <div className="docPrdDt">
                            <TodayRounded />
                            <span className="cdate">18-Sep-2024</span>
                            <span className="ctime">12:30pm</span>
                          </div>
                          <IconButton>
                            <Download />
                          </IconButton>
                        </div>

                        <div className="prsDtl">
                          <div className="docDtl">
                            <span className="docName">Dr. Sk Aftabuddin</span>
                            <span className="docSpecl">(General Medicine)</span>
                          </div>
                          <div className="docPrdDt">
                            <TodayRounded />
                            <span className="cdate">18-Sep-2024</span>
                            <span className="ctime">12:30pm</span>
                          </div>
                          <IconButton>
                            <Download />
                          </IconButton>
                        </div>
                      </div>
                    </div>

                    <div className="patDocInv">
                      <div className="docHead">Investigation</div>
                      <div className="docInvGrp">
                        <div className="invDtlInd">
                          <div className="invCheck">
                            <FormControlLabel
                              control={<Checkbox />}
                              label={
                                <>
                                  <div className="invDtl">
                                    <span className="invName">
                                      Lipid Profile
                                    </span>
                                    <span className="invDate">
                                      (20-Sep-2024)
                                    </span>
                                  </div>
                                </>
                              }
                            />
                          </div>

                          <IconButton>
                            <Download />
                          </IconButton>
                        </div>
                        <div className="invDtlInd">
                          <div className="invCheck">
                            <FormControlLabel
                              control={<Checkbox />}
                              label={
                                <>
                                  <div className="invDtl">
                                    <span className="invName">
                                      Liver Function Test(LFT)
                                    </span>
                                    <span className="invDate">
                                      (20-Sep-2024)
                                    </span>
                                  </div>
                                </>
                              }
                            />
                          </div>

                          <IconButton>
                            <Download />
                          </IconButton>
                        </div>
                        <Button
                          startIcon={<DescriptionRounded />}
                          className="dfultPrimaryBtn "
                        >
                          Genarate Report
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* card patientDtl end */}

              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : patientDocumentData.length === 0 ? (
                <div class="noDataCard ">
                  <span>{t("oops")}</span>
                  <br />
                  <span className="sallMsg">
                    {t("no_patient_document_data_is_present")}
                  </span>
                </div>
              ) : (
                <div className="patDocList viewDocStepFour">
                  {filterpatientDocumentData.map((patient, index) => (
                    <div className="patDocIndCard" key={index}>
                      <div className="patDetails">
                        <div className="patname">{patient.patientName}</div>
                        {patient.patientExternalSourceId && (
                          <div className="patname">
                            {patient.patientExternalSourceId}
                          </div>
                        )}
                        {!patient.patientExternalSourceId &&
                          patient.patientIdDisplay && (
                            <div className="patname">
                              {patient.patientIdDisplay}
                            </div>
                          )}
                        <div className="patAge">
                          <span>{t("age")}:</span> {patient.age}
                        </div>
                        <div className="patAge">
                          <span>{t("gender")}: </span>
                          {patient?.gender === "Male"
                            ? t("male")
                            : patient?.gender === "Female"
                            ? t("female")
                            : t("other")}
                        </div>
                      </div>
                      <div className="patDocDetails">
                        {Array.isArray(patient.prescriptions) &&
                          patient.prescriptions.length > 0 && (
                            <div className="patDocPrs">
                              <div className="docHead">{t("prescription")}</div>
                              <div className="docPrsGrp">
                                {Array.isArray(patient.prescriptions) &&
                                  patient.prescriptions.length > 0 &&
                                  patient.prescriptions.map(
                                    (prescription, idx) => (
                                      <div className="prsDtl" key={idx}>
                                        <div className="docDtl">
                                          <span className="docName">
                                            {prescription.doctorName}
                                          </span>
                                          <span className="docSpecl">
                                            ({prescription.specilization})
                                          </span>
                                        </div>
                                        <div className="docPrdDt">
                                          <TodayRounded />
                                          <span className="cdate">
                                            {prescription.consultStartTime}
                                          </span>
                                        </div>
                                        <IconButton
                                          onClick={() =>
                                            handleDownLoadFile(
                                              prescription.folderName,
                                              prescription.fileName
                                            )
                                          }
                                        >
                                          <Download />
                                        </IconButton>
                                        {prescription.pharmacyFileName && (
                                          <Tooltip
                                            title={t("phermacy_copy_of_rx")}
                                            arrow
                                          >
                                            <IconButton
                                              onClick={() =>
                                                handleDownLoadFile(
                                                  prescription.folderName,
                                                  prescription.pharmacyFileName
                                                )
                                              }
                                            >
                                              <Prescription />
                                            </IconButton>
                                          </Tooltip>
                                        )}

                                        {prescription.doctorAudioNoteFileUrl && (
                                          <Tooltip
                                            title={t("play_audio_message")}
                                            arrow
                                          >
                                            <IconButton
                                              onClick={() => {
                                                audioUrl.current =
                                                  prescription.doctorAudioNoteFileUrl;
                                                setShowAudioModal(true);
                                              }}
                                            >
                                              <Play />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          )}

                        {Array.isArray(patient.tests) &&
                          patient.tests.length > 0 && (
                            <div className="patDocInv">
                              <div className="docHead">
                                {t("investigation")}
                              </div>
                              <div className="docInvGrp">
                                {Array.isArray(patient.tests) &&
                                  patient.tests.length > 0 &&
                                  patient.tests.map((test, idx) => (
                                    <div className="invDtlInd">
                                      <div className="invCheck">
                                        <FormControlLabel
                                          control={
                                            test.fileLink === null ||
                                            test.fileLink === "" ? (
                                              <Checkbox
                                                checked={selectedTests.includes(
                                                  test.requsitionId
                                                )}
                                                onChange={() =>
                                                  handleCheckboxChange(
                                                    test.requsitionId,
                                                    patient.patientId
                                                  )
                                                }
                                              />
                                            ) : (
                                              <></>
                                            )
                                          }
                                          label={
                                            <>
                                              <div className="invDtl">
                                                <span className="invName">
                                                  {test.testName}
                                                </span>
                                                <span className="invDate">
                                                  ({test.requsitionTime})
                                                </span>
                                              </div>
                                            </>
                                          }
                                        />
                                      </div>
                                      {test.fileLink && (
                                        <IconButton
                                          onClick={() =>
                                            handleDownLoadInvesticationFile(
                                              test
                                            )
                                          }
                                        >
                                          <Download />
                                        </IconButton>
                                      )}
                                    </div>
                                  ))}

                                {selectedTests.length > 0 &&
                                  selectedPatientId === patient.patientId && (
                                    <Button
                                      startIcon={<DescriptionRounded />}
                                      className="dfultPrimaryBtn "
                                      onClick={() =>
                                        handleGenerateReport(patient.patientId)
                                      }
                                    >
                                      {t("generate_report")}
                                    </Button>
                                  )}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {/* {test} */}
            </div>
          </div>
        </div>
      </div>
      {showAudioModal && (
        <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
          <div className="addMoreAgendaModel">
            <div className="modelTitle">{t("audio_message")}</div>
            <div className="modActionBtnGrp">
              <audio controls>
                <source src={audioUrl.current} />
              </audio>
            </div>

            <div className="modActionBtnGrp">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => {
                  setShowAudioModal(false);
                }}
              >
                {t("cancel")}
              </Button>
            </div>
          </div>
        </Box>
      )}
    </>
  );
}
