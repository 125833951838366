import {
  ArrowBackIosNew,
  ArrowForwardIos,
  DescriptionOutlined,
  MonetizationOnOutlined,
  TranslateRounded,
} from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import { ConfirmProvider } from "material-ui-confirm";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Plus, XLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { TourContext } from "../../../../../common/TourProvider";
import DashboardServices from "../../../../../services/DashboardServices";
import zhPatientService from "../../../../../services/ZhPatientService";
import SecureIndexedDB from "../../../../../utils/IndexedDB";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import EXP from "../../../../dashboard/image/EXP";
import { AddTabContext } from "../../../../dashboard/view/DashboardPage";
import PatientMeetingsTabContainer from "../../../../scheduler/view/component/meetings/view/PatientMeetingsTabContainer";
import MedicalHistory from "../../../../scheduler/view/component/meetings/view/consultation/MedicalHistory";
import PatientComplaints from "../../../../scheduler/view/component/meetings/view/consultation/PatientComplaints";
import TestResultInput from "../../../../scheduler/view/component/meetings/view/consultation/TestResultInput";
import { getHealthDto } from "../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import PatientDrugAllergy from "../../../../scheduler/view/component/meetings/view/consultation/patient/PatientDrugAllurgy";
import PatientFamilyHistory from "../../../../scheduler/view/component/meetings/view/consultation/patient/PatientFamilyHistory";
import PatientFoodAllergy from "../../../../scheduler/view/component/meetings/view/consultation/patient/PatientFoodAllergy";
import { CustomModal } from "../../../../task/view/component/Modals/CustomModal";
import PatientFileUpload from "./PatientFileUpload";
import { PatientVitals } from "./PatientVitals";
import { useValidateVital } from "./hook/useValidateVital";
import { FileCursor } from "../../../../../common/FileCursor";
import VisualSelection from "./humanBody/VisualSelection";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const AddReviewPatientToQueue = ({
  data,
  handleCloseAddReviewPatientToQueue,
  closeReviewPatientToQueue,
}) => {
  const loginUserDetail = GetLoginUserDetails();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const { t } = useTranslation();
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [timezone, setTimezone] = useState();
  const [userInvestigationList, setUserInvestigationList] = useState();
  const [prescriptionData, setPrescriptionData] = useState({});
  const [patientInfo, setPatientInfo] = useState(null);

  const [systemicIllnessList, setSystemicIllnessList] = useState([]);
  const [systemicAbuseList, setSystemicAbuseList] = useState([]);

  const [illnessName, setIllnessName] = useState([]);
  const [systemicIllness, setSystemicIllness] = useState("no");

  const [abuseName, setAbuseName] = useState([]);
  const [substanceAbuse, setSubstanceAbuse] = useState("no");

  const [documents, setDocuments] = useState([]);
  const fileElementRef = useRef(null);

  const [previousMedicalRecords, setPreviousMedicalRecords] = useState(null);
  const [fileDivWidth, setFileDivWidth] = useState(0);

  const [filearrowDisable, setFilearrowDisable] = useState(false);
  const [showFileUploadDialog, setShowFileUploadDialog] = useState(false);
  const [referedBy, setReferedBy] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  const [showConfirmation, setShowConfirmation] = useState(false);
  // const [VitalErrorList, setVitalErrorList] = useState([]);
  const [vitals, setVitals] = useState({});

  const [sos, setSos] = useState(false);
  const [unitAllDoctorList, setUnitAllDoctorList] = useState([]);
  const [testGiven, setTestGiven] = useState();
  const [extraData, setExtraData] = useState();
  const [patient, setPatient] = useState(null);

  const [visualSymptoms, setVisualSymptoms] = useState(false);
  const [visualSymptomsClose, setVisualSymptomsClose] = useState(true);
  const [visualSymptomsData, setVisualSymptomsData] = useState([]);
  const [visualSymptomsCheckData, setVisualSymptomsCheckData] = useState([]);

  const [temperatureUnit, setTemperatureUnit] = useState(null);
  const [heightUnit, setHeightUnit] = useState(null);
  const [weightUnit, setWeightUnit] = useState(null);
  const [bloodGroup, setBloodGroup] = useState("");
  const { validateVitals, VitalErrorList } = useValidateVital();

  const { addTab } = useContext(AddTabContext);

  const updateData = (data) => {
    // toast.success("successfully added data");
    console.log("data =", data);
    setPrescriptionData((prev) => ({ ...prev, ...data }));
  };

  useEffect(() => {
    setBookSteps([
      {
        target: ".addRevPatStepOne",
        content: t("this_is_the_header_section_where_you_can_see_the_title_and_close_button"),
        // placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".addRevPatStepTwo",
        content: t("this_is_the_header_section_where_you_can_see_the_title_and_close_button"),
        // placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".addRevPatStepThree",
        content: t("this_section_contains_information_about_the_doctor_including_their_photo_name_and_specialization"),
        // placement: "right",
        disableBeacon: true,
      },
      {
        target: ".addRevPatStepFour",
        content: t("here_you_can_see_the_patient_details_like_their_name_age_and_gender"),
        // placement: "left",
        disableBeacon: true,
      },
      {
        target: ".taskElementGrp .tskElemHeding",
        content: t("this_section_covers_vital_signs_and_medical_history_including_patient_complaints"),
        // placement: "top",
        disableBeacon: true,
      },
      {
        target: ".elemntAddMrFiles",
        content: t("this_section_lets_you_upload_files_like_medical_records"),
        // placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".addRevPatStepSeven",
        content: t("here_you_can_input_details_about_systemic_illnesses_and_substance_abuse"),
        // placement: "top",
        disableBeacon: true,
      },
      {
        target: ".addRevPatStepEight",
        content: t("click_here_to_submit_the_form"),
        // placement: "top",
        disableBeacon: true,
      },
    ]);
  }, [t]);

  // useEffect(() => {
  //   setBookSteps([
  //     {
  //       target: ".addRevPatStepOne",
  //       content:
  //         "This is the header section where you can see the title and close button.",
  //       placement: "bottom",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addRevPatStepTwo",
  //       content:
  //         "This is the header section where you can see the title and close button.",
  //       placement: "bottom",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addRevPatStepThree",
  //       content:
  //         "This section contains information about the doctor, including their photo, name, and specialization.",
  //       placement: "right",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addRevPatStepFour",
  //       content:
  //         "Here, you can see the patient details like their name, age, and gender.",
  //       placement: "left",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".taskElementGrp .tskElemHeding",
  //       content:
  //         "This section covers vital signs and medical history, including patient complaints.",
  //       placement: "top",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".elemntAddMrFiles",
  //       content: "This section lets you upload files like medical records.",
  //       placement: "bottom",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addRevPatStepSeven",
  //       content:
  //         "Here, you can input details about systemic illnesses and substance abuse.",
  //       placement: "top",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addRevPatStepEight",
  //       content: "Click here to submit the form.",
  //       placement: "top",
  //       disableBeacon: true,
  //     },
  //   ]);
  // }, []);

  function calculateDateDifference(dateString) {
    // Parse the input date string "DD-MM-YYYY"
    if (!dateString) {
      return "";
    }
    const [day, month, year] = dateString?.split("-").map(Number);
    const givenDate = new Date(year, month - 1, day);
    const today = new Date();

    let years = today.getFullYear() - givenDate.getFullYear();
    let months = today.getMonth() - givenDate.getMonth();
    let days = today.getDate() - givenDate.getDate();

    // Adjust if days are negative
    if (days < 0) {
      months--;
      days += new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    }

    // Adjust if months are negative
    if (months < 0) {
      years--;
      months += 12;
    }

    return `${years}Y ${months}M ${days}D`;
  }

  const getAvailableDoctorsofUnit = async () => {
    const diseasetring = await SecureIndexedDB.getItem("unitDoctorList");
    if (diseasetring) {
      setUnitAllDoctorList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getAvailableDoctorsofUnit(
      loginUserDetail.signleUnitId
    ).then((response) => {
      if (response.data) {
        setUnitAllDoctorList(response.data);
        SecureIndexedDB.setItem(
          "unitDoctorList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const filehandleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setFilearrowDisable(true);
      } else {
        setFilearrowDisable(false);
      }
    }, speed);
  };

  const handlePatientInfo = (patientInfo) => {
    if (patientInfo) {
      const { systemicIllnesses, substanceAbuse } = patientInfo;
      let tempSelectedIllness = [];
      systemicIllnesses?.forEach((item) => {
        const illness = systemicIllnessList.find(
          (illness) => illness.systemicIllnessName === item
        );
        illness && tempSelectedIllness.push(illness);
      });
      if (tempSelectedIllness && tempSelectedIllness.length > 0) {
        setIllnessName(tempSelectedIllness);
        setSystemicIllness("yes");
      }

      tempSelectedIllness = [];

      substanceAbuse?.forEach((item) => {
        const illness = systemicAbuseList.find(
          (illness) => illness.substanceAbuseName === item
        );
        illness && tempSelectedIllness.push(illness);
      });
      if (tempSelectedIllness && tempSelectedIllness.length > 0) {
        setAbuseName(tempSelectedIllness);
        setSubstanceAbuse("yes");
      }
    }
  };

  const selectSubstanceAbuse = (event) => {
    const value = event.target.value;
    if (value === "no") {
      setAbuseName([]);
    }
    setSubstanceAbuse(value);
  };

  const selectSystemicIllness = (event) => {
    const value = event.target.value;
    if (value === "no") {
      setIllnessName([]);
    }
    setSystemicIllness(event.target.value);
  };

  const handleChangeAbuse = (event) => {
    const {
      target: { value },
    } = event;
    setAbuseName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeIllness = (event) => {
    const {
      target: { value },
    } = event;
    setIllnessName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const getSystemicIllness = async () => {
    const brandString = await SecureIndexedDB.getItem("substanceIllnessList");
    if (brandString) {
      setSystemicIllnessList(JSON.parse(brandString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getSystemicIllness(reqDto).then((response) => {
      if (response.data) {
        setSystemicIllnessList(response.data);
        SecureIndexedDB.setItem(
          "substanceIllnessList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const getSubstanceAbuse = async () => {
    const brandString = await SecureIndexedDB.getItem("substanceAbuseList");
    if (brandString) {
      setSystemicAbuseList(JSON.parse(brandString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getSubstanceAbuse(reqDto).then((response) => {
      if (response.data) {
        setSystemicAbuseList(response.data);
        SecureIndexedDB.setItem(
          "substanceAbuseList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  function dateDifferenceToDate(dateDifference) {
    const regex = /(\d+)Y (\d+)M (\d+)D/;
    const match = dateDifference.match(regex);
  
    if (!match) {
      return "Invalid format";
    }
  
    const [_, years, months, days] = match.map(Number);
  
    const today = new Date();
  
    const targetYear = today.getFullYear() - years;
    const targetMonth = today.getMonth() - months;
  
    const targetDate = new Date(targetYear, targetMonth, today.getDate());
  
    targetDate.setDate(targetDate.getDate() - days);
  
    const day = String(targetDate.getDate() + 1).padStart(2, '0'); 
    const month = String(targetDate.getMonth() + 1).padStart(2, '0'); 
    const year = targetDate.getFullYear();
  
    return `${day}-${month}-${year}`;
  }
  
  const submitFormData = async () => {
    // if (
    //   !prescriptionData.patientComplaint ||
    //   (prescriptionData && prescriptionData.patientComplaint === "")
    // ) {
    //   toast.error("Please Give Patient Complaints");
    //   return;
    // }

    const toastLoaderId = toast.loading(t("submitting_details_please_wait"));

    let tempVisistData = await DashboardServices.generateVisitId(
      loginUserDetail.orgId
    );
    tempVisistData = tempVisistData?.data;
    const itemData = prescriptionData;
    console.log("Prescription Data", itemData);

    let doc = documents?.map((item) => {
      return {
        fileName: item.fileName,
        fileLink: item.fileLink,
        fileData: item.fileData,
        id: item.id,
      };
    });

    const prevDoc =
      previousMedicalRecords
        ?.filter((item) => item.isChecked)
        ?.map((item) => {
          return {
            fileName: item.fileName,
            fileLink: item.fileLink,
            fileData: item.fileData,
            id: item.id,
          };
        }) || [];

    doc = [...doc, ...prevDoc];

    const illnesses = illnessName?.map((item) => item.systemicIllnessName);

    const abuseNameList = abuseName?.map((item) => item.substanceAbuseName);

    const foodAllergies = prescriptionData?.allergicFood?.map(
      (item) => item.foodName
    );

    let hereditaryDiseases =
      prescriptionData?.familyHistory?.map((item) => {
        return {
          diseaseName: item.disease.diseaseName,
          relationship: item.relationShip,
          id: item.disease.id,
        };
      }) || [];

    if (
      prescriptionData?.prevFamilyDisease &&
      prescriptionData?.prevFamilyDisease?.length > 0
    ) {
      hereditaryDiseases = [
        ...hereditaryDiseases,
        ...prescriptionData?.prevFamilyDisease,
      ];
    }

    let medicineAllergies =
      prescriptionData?.allergicDrug?.map((item) => {
        const { brand, genericItem } = item;
        let type = null;
        if (brand) {
          return {
            type: "BRAND",
            genericBrandId: brand.id,
            genericBrandName: brand.brandName,
          };
        } else {
          return {
            type: "GENERIC",
            genericBrandId: genericItem.id,
            genericBrandName: genericItem.generic,
          };
        }
      }) || [];
    if (
      prescriptionData?.previousDrugAllergy &&
      prescriptionData?.previousDrugAllergy?.length > 0
    ) {
      medicineAllergies = [
        ...medicineAllergies,
        ...prescriptionData?.previousDrugAllergy,
      ];
    }

    let diseases =
      prescriptionData?.pastIllness?.map((item) => {
        return {
          diseaseName: item.illness.diseaseName,
          diagnosisDate: dayjs(item.date).format("DD-MM-YYYY"),
          id: 0,
          nature: "CONFIRM",
        };
      }) || [];

    if (prescriptionData?.prevIllness) {
      diseases = [...diseases, ...prescriptionData?.prevIllness];
    }

    let procedures =
      prescriptionData?.prevSurgery?.map((item) => {
        return {
          id: 0,
          procedureName: item.surgery.procedureName,
          procedureDate: dayjs(item.date).format("DD-MM-YYYY"),
        };
      }) || [];

    if (prescriptionData?.prevSurgerry) {
      procedures = [...procedures, ...prescriptionData?.prevSurgerry];
    }

    let symptoms = prescriptionData.symptoms?.map((item, index) => {
      let symptomId = item.selectedSymptom.locallyAdded
        ? 0
        : item.selectedSymptom.id;
      return {
        symptomName: item.selectedSymptom.symptomName,
        symptomId,
        severity: item.severity,
        since: item.since,
        slNo: index + 1,
      };
    });

    const currentMedications = prescriptionData?.medication?.map((item) => {
      const { brandName, frequency, dosage, genericName } = item;

      let type = null;
      let genericBrandId = 0;
      let genericBrandName = "";
      if (brandName) {
        type = "BRAND";
        genericBrandId = brandName.id;
        genericBrandName = brandName.brandName;
      } else {
        type = "GENERIC";
        genericBrandId = genericName.id;
        genericBrandName = genericName.generic;
      }

      return {
        type: type,
        genericBrandId: genericBrandId,
        genericBrandName: genericBrandName,
        dosages: dosage.dosage,
        dosagesUnit: dosage.dosageUnit || "",
        since: dayjs(item.since).format("DD-MM-YYYY"),
        frequency: item.frequency.frequency,
      };
    });

    const tmpVitals = Object.values(vitals);

    const submitDto = {
      visitIdDisplay: tempVisistData,
      patientIdDisplay: patient.patientId || "",
      patientId: patient.patientId,
      unitId: loginUserDetail.signleUnitId,
      doctorId: selectedDoctor.doctorId,
      orgId: loginUserDetail.orgId,
      audioNote: prescriptionData.audioData || "",
      dob: patient?.patientAge ? dateDifferenceToDate(patient.patientAge) : "",
      bloodType: bloodGroup || "",
      patientName: patient.patientName,
      gender: patient.patientGender || "",
      patientProfileUrl: patient.patientImageUrl || "",
      consultationType: "OPD",
      referredBy: referedBy,
      clinicName: loginUserDetail.unitLocationName
        ? loginUserDetail.unitLocationName
        : "",
      clinicContactNo: loginUserDetail.unitContactNo
        ? loginUserDetail.unitContactNo
        : "",
      patientType: "Review",

      vitals: tmpVitals || [],
      documents: doc || [],
      systemicIllnesses: illnesses || [],
      substanceAbuse: abuseNameList || [],
      currentMedications: currentMedications || [],
      procedures: procedures || [],
      diseases: diseases || [],
      hereditaryDiseases: hereditaryDiseases || [],
      medicineAllergies: medicineAllergies || [],
      foodAllergies: foodAllergies || [],
      sos: sos ? "Y" : "N",
      doctorAvgConsultTime: selectedDoctor?.avgConsultTime || 0,
      doctorName: selectedDoctor?.doctorName,
      doctorSpecilization: selectedDoctor?.specializationName || "",
      slotId: 0,
      symptoms: symptoms || [],
    };

    console.log("submit Dto", submitDto);
    // return;

    DashboardServices.submitPatientVisit(submitDto).then((response) => {
      if (response.data.returnValue === "1") {
        toast.update(toastLoaderId, {
          render: t("patient_data_visit_sucess"),
          type: "success",
          isLoading: false,
          autoClose: true,
        });

        if (response.data.page === "Q") {
          // toast.success("Consultation booking complete");
          handleCloseAddReviewPatientToQueue();
          return;
        }

        const key = v4();
        const eventDto = {
          eventTitle: `${response.data.visitId}) Consultation with ${selectedDoctor.doctorName}`,
          eventDescription: `Consultation with ${selectedDoctor.doctorName}`,
          eventStartTime: new Date().toString(),
          eventEndTime: new Date(Date.now() + 20 * 60 * 1000).toString(),
          eventId: response.data.visitId,
          eventType: "Consultation",
          eventLength: 20,
          eventLink: response.data.roomId,
          patientName: patient.patientName,
          patientImageUrl: patient.patientProfileUrl || "",
        };

        const itemData = {
          queuePatientName: patient.patientName,
          // queueAttempsCount: data.queueAttempsCount,
          queuePatientAge: patient.patientAge,
          // queueAddTime: data.queueAddTime,
          queuePatientGender: patient.patientGender,
          queuePatientProfileUrl: patient.patientProfileUrl || "",
          queueConsultationType: "OPD",
          queueVitals: tmpVitals || [],
          // queueVitals: "",
          queueComplaints: "",
          queueReferredBy: referedBy || "",
          queueClinicName: loginUserDetail.unitLocationName || "",
          queueClinicContactNo: "",
          queuePatientType: "New",
          queueVisitId: response.data.visitId,
          queuePatientId: patient.patientId,
          queuePatientUnitId: loginUserDetail.signleUnitId,
          queueRoomId: response.data.roomId,
          reqValue: {},
          isDoctor: loginUserDetail.zhUserType === "DOCTOR" ? true : false,
          noFeedback: true,
          bloodGroup: bloodGroup,
          // symptoms: symptoms || [],
        };

        addTab({
          key: key,
          title: `${eventDto.eventTitle}`,
          content: (
            <ConfirmProvider>
              <PatientMeetingsTabContainer
                eventData={eventDto}
                tabKey={key}
                extraData={itemData}
              />
            </ConfirmProvider>
          ),
          isDraggable: true,
          type: "Meeting",
        });
      } else {
        // toast.error(response.data.message);
        toast.update(toastLoaderId, {
          render: t("something_went_wrong"),
          type: "error",
          isLoading: false,
          autoClose: true,
        });
      }
    });
  };

  const getAge = (ageString) => {
    // console.log("getAge", dob);
    // const ageString = calculateDateDifference(dob);
    const ageComponent = ageString?.split(" ");
    if (ageComponent?.[0]?.includes("Y")) {
      return parseInt(ageComponent[0].split("Y")[0]) || 0;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const tempSelectedDoctorList = unitAllDoctorList?.find((doctor) => {
      console.log("Doctor = ", doctor);
      return data.doctorId === doctor.doctorId;
    });

    setSelectedDoctor(tempSelectedDoctorList);
  }, [unitAllDoctorList]);

  useEffect(() => {
    console.log("preview data=", data);
    getSystemicIllness();
    getSubstanceAbuse();
    getAvailableDoctorsofUnit();

    const { patientAge, patientName, patientGender, patientImage } = data;
    setPatient({
      patientAge,
      patientName,
      patientGender,
      patientProfileUrl: patientImage,
    });
    setSelectedPatient({
      patientAge,
      patientName,
      patientGender,
      patientProfileUrl: patientImage,
    });

    const itemData = {
      queuePatientName: data.patientName,
      // queueAttempsCount: data.queueAttempsCount,
      queuePatientAge: data.patientAge,
      // queueAddTime: data.queueAddTime,
      queuePatientGender: data.patientGender,
      queuePatientProfileUrl: data.patientProfileUrl,
      queueConsultationType: data.consultationType,
      // queueVitals: data.vitals,
      queueVitals: "",
      queueComplaints: data.complaints,
      queueReferredBy: data.referredBy,
      queueClinicName: data.clinicName,
      queueClinicContactNo: data.clinicContactNo,
      queuePatientType: data.patientType,
      queueVisitId: data.visitId,
      queuePatientId: data.patientId,
      queuePatientUnitId: data.unitId,
      queueRoomId: data.roomId,
      doctorId: data.doctorId,
      doctorName: data.doctorName,
      // reqValue: response.data,
      isDoctor: loginUserDetail.zhUserType === "DOCTOR" ? true : false,
      bloodGroup: bloodGroup,
    };

    setExtraData(itemData);

    zhPatientService
      .getTestGivenByVisitId(data.visitId, loginUserDetail.signleUnitId)
      .then((response) => {
        if (response.data) {
          const uniqueTests = Array.from(
            new Set(response.data.map((item) => item.testId))
          ).map((testId) => {
            return response.data.find((item) => item.testId === testId);
          });

          setTestGiven(uniqueTests);
        }
      });

    //   DashboardServices.getPreviousMedicalRecords(
    //       patientDto.rowPatientId
    //     ).then((response) => {
    //       if (response.data) {
    //         let patientInfo = response.data;
    //         setPatientInfo(patientInfo);
    //         handlePatientInfo(patientInfo);
    //       }
    //     });
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };


  const handelfetchVisualSelectionData = (data) => {
    // setVisualSymptomsData(data);
    setVisualSymptomsCheckData(data);
  };

  const handleCloseModal = () => {
    setVisualSymptoms(false); 
    // setVisualSymptomsData([]);
    setVisualSymptomsCheckData([]);
    setVisualSymptomsClose(false);
    // dispatch(reset());
  };
  
  const handelAddVisualSelectionData = () => {
    setVisualSymptoms(false);
    setVisualSymptomsClose(true);
    if (visualSymptomsCheckData.length > 0) {
      setVisualSymptomsData([...visualSymptomsCheckData]);
    }
    setVisualSymptomsCheckData([]);
    // setVisualSymptomsData([...visualSymptomsCheckData]);
  };

  console.log("VisualSymptomsCheckData:", visualSymptomsCheckData);
  console.log("VisualSymptomsData:", visualSymptomsData);


  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["addReviewPatns"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        callback={handleJoyrideCallback}
        scrollTo={true}
        placement="auto"
        scrollOffset={200}
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('finish'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
        // showProgress={true}
        // callback={(data) => {
        //   if (data.status === "finished" || data.status === "skipped") {
        //     setRunTour(false);
        //   }
        // }}
      />
      <div className="rightFlotingPanel addRevPatStepOne">
        <div className="rightFlotingContainer">
          <div className="rfContHeader addRevPatStepTwo">
            <div className="rfcHeadText">{t("review_patient")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton
                  onClick={() => handleCloseAddReviewPatientToQueue()}
                >
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            <div className="rfContContainDetails">
              <div className="elementFormContainer">
                <div className="tskElemHeding ">{t("doctor_info")}</div>
                <div className="doctorDetails bookedSlot">
                  <div className="doctorInfoWthImg addRevPatStepThree">
                    <div className="docImage">
                      <img
                        src={
                          selectedDoctor?.doctorProfileImageUrl ||
                          data?.doctorImage
                        }
                        alt={t("doctor_image")}
                      />
                    </div>
                    <div className="doctorFullInfo">
                      <div className="doctorNmSpc">
                        <div className="docName">
                          {selectedDoctor?.doctorName}
                        </div>
                        <div className="docSpclty">
                          {` ( ${selectedDoctor?.specializationName || ""} )`}
                        </div>
                      </div>
                      <div className="docExpFeeLan">
                        <div className="docEFL">
                          <div className="eflicon fillnone">
                            <EXP />
                          </div>
                          <div className="eflText">
                            {selectedDoctor?.experience}
                          </div>
                        </div>

                        <div className="docEFL">
                          <div className="eflicon">
                            <MonetizationOnOutlined />
                          </div>
                          <div className="eflText">
                            {selectedDoctor?.fees}
                            <span> {selectedDoctor?.feesCurrency}</span>
                          </div>
                        </div>
                        <div className="docEFL">
                          <div className="eflicon">
                            <TranslateRounded />
                          </div>
                          <div className="eflText">
                            {selectedDoctor?.languages}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tskElemHeding ">{t("patient_info")}</div>

                <div className="filterPatlistInd appoinBookPatList addRevPatStepFour">
                  <div className="filPatFullinfo">
                    <div className="filPatImg">
                      <img
                        src={patient?.patientProfileUrl}
                        alt={t("patient_image")}
                      />
                    </div>
                    <div className="filtPatDtl">
                      <div className="filtPatNm">
                        {patient?.patientName}
                        {/* <span>{`(${
                          patient?.patientGender === "Male"
                            ? t("male")
                            : patient?.patientGender === "Female"
                            ? t("female")
                            : t("other")
                        }, ${patient?.patientAge} )`}</span> */}
                      </div>
                      <div className="filtPatNm">
                        <span>
                          {patient?.patientIdDisplay || data?.patientIdDisplay}
                        </span>
                        <span>{`(${
                          patient?.patientGender === "Male"
                            ? t("male")
                            : patient?.patientGender === "Female"
                            ? t("female")
                            : t("other")
                        }, ${patient?.patientAge} )`}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tskElemHeding">{t("vitals")}</div>

                <PatientVitals
                  data={[]}
                  patientAge={getAge(selectedPatient?.patientAge)}
                  patientGender={selectedPatient?.patientGender}
                  showBloodgroup={bloodGroup ? false : true}
                  handleDataChange={(data) => {}}
                  editMode={true}
                  handleSelectBloodGroup={(data) => {
                    if (data) {
                      setBloodGroup(data);
                    }
                  }}
                  handleSaveVitals={(data) => {
                    if (data) {
                      setVitals(data);
                    }
                  }}
                />

                <div className="bb"></div>

                <PatientComplaints
                  data={prescriptionData}
                  updateData={updateData}
                  extraData={patientInfo}
                  setVisualSymptoms={setVisualSymptoms}
                  visualSymptomsData={visualSymptomsData}
                  visualSymptomsClose={visualSymptomsClose}
                />

                {/* files start */}

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("files")}</div>

                  <div class="elementFileListedGrp">
                    <FileCursor>
                      <>
                        {documents?.map((item) => {
                          return (
                            <>
                              <div className="elemntFile">
                                <Tooltip
                                  title={item.fileName}
                                  arrow
                                  className="elmFileTolTip"
                                >
                                  <span className="elemntFilIoc">
                                    <DescriptionOutlined />
                                  </span>
                                  <span className="elemntFilNm">
                                    {item.fileName}
                                  </span>
                                </Tooltip>
                              </div>
                            </>
                          );
                        })}

                        {previousMedicalRecords
                          ?.filter((item) => item.isChecked)
                          ?.map((item) => {
                            return (
                              <>
                                <div className="elemntFile">
                                  <Tooltip
                                    title={item.fileName}
                                    arrow
                                    className="elmFileTolTip"
                                  >
                                    <span className="elemntFilIoc">
                                      <DescriptionOutlined />
                                    </span>
                                    <span className="elemntFilNm">
                                      {item.fileName}
                                    </span>
                                  </Tooltip>
                                </div>
                              </>
                            );
                          })}
                      </>
                    </FileCursor>

                    <div class="elmntAddMrFiles">
                      <Button
                        className="elemntMrFileBtn"
                        variant="outlined"
                        // onClick={handleAddnewFile}
                        onClick={(e) => {
                          setShowFileUploadDialog(true);
                        }}
                      >
                        <Plus />
                      </Button>
                      <input
                        type="file"
                        // ref={fileInputRef}
                        // onChange={handleChangeFile}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  </div>
                </div>

                {/* files end */}
                <div className="taskElementGrp mt10">
                  <div className="tskElemHeding">{t("systemic_illness")}</div>

                  <div className="formElementGrp flex-wrap">
                    <div className="formElement addRevPatStepSeven">
                      <FormControl className="formControl">
                        <RadioGroup
                          className="formRadioGroup"
                          labelId="setTasktype"
                          name="setTasktype"
                          value={systemicIllness}
                          onChange={selectSystemicIllness}
                        >
                          <FormControlLabel
                            className="formRadioField"
                            value="yes"
                            control={<Radio />}
                            label={t("yes")}
                          />
                          <FormControlLabel
                            className="formRadioField"
                            value="no"
                            control={<Radio />}
                            label={t("no")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    {systemicIllness === "yes" && (
                      <div className="formElement addRevPatStepSeven">
                        <FormControl className="formControl">
                          <InputLabel id="demo-multiple-checkbox-label">
                            {t("systemic_illness")}
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            className="formInputField"
                            variant="outlined"
                            value={illnessName}
                            onChange={handleChangeIllness}
                            input={
                              <OutlinedInput label={t("systemic_illness")} />
                            }
                            renderValue={(selected) =>
                              selected
                                .map((item) => item.systemicIllnessName)
                                .join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {systemicIllnessList.map((item) => (
                              <MenuItem key={item.id} value={item}>
                                <Checkbox
                                  checked={
                                    illnessName.findIndex(
                                      (element) => element.id === item.id
                                    ) > -1
                                  }
                                />
                                <ListItemText
                                  primary={`${item.systemicIllnessName}`}
                                />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    )}
                  </div>
                </div>

                <div className="taskElementGrp mt10">
                  <div className="tskElemHeding">{t("substance_abuse")}</div>

                  <div className="formElementGrp flex-wrap">
                    <div className="formElement addRevPatStepSeven">
                      <FormControl className="formControl">
                        <RadioGroup
                          className="formRadioGroup"
                          labelId="setTasktype"
                          name="setTasktype"
                          value={substanceAbuse}
                          onChange={selectSubstanceAbuse}
                        >
                          <FormControlLabel
                            className="formRadioField"
                            value="yes"
                            control={<Radio />}
                            label={t("yes")}
                          />
                          <FormControlLabel
                            className="formRadioField"
                            value="no"
                            control={<Radio />}
                            label={t("no")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    {substanceAbuse === "yes" && (
                      <div className="formElement addRevPatStepSeven">
                        <FormControl className="formControl">
                          <InputLabel id="demo-multiple-checkbox-label">
                            {t("substance_abuse")}
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            className="formInputField"
                            variant="outlined"
                            value={abuseName}
                            onChange={handleChangeAbuse}
                            input={
                              <OutlinedInput label={t("substance_abuse")} />
                            }
                            renderValue={(selected) =>
                              selected
                                .map((item) => item.substanceAbuseName)
                                .join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {systemicAbuseList.map((item) => (
                              <MenuItem key={item.id} value={item}>
                                <Checkbox
                                  checked={
                                    abuseName.findIndex(
                                      (element) => element.id === item.id
                                    ) > -1
                                  }
                                />
                                <ListItemText
                                  primary={item.substanceAbuseName}
                                />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    )}
                  </div>
                </div>

                <div className="taskElementGrp mt10">
                  <div className="tskElemHeding">{t("reference_details")}</div>
                  <div className="formElement addRevPatStepSeven">
                    <FormControl className="formControl">
                      <TextField
                        label={t("referred_by")}
                        autoComplete="off"
                        variant="outlined"
                        className="formTextFieldArea"
                        value={referedBy}
                        onChange={(e) => {
                          setReferedBy(e.target.value);
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="bb"></div>
                <MedicalHistory
                  data={patientInfo}
                  updateData={updateData}
                  previousInfo={patientInfo}
                />

                <PatientFamilyHistory
                  data={prescriptionData}
                  updateData={updateData}
                  previousInfo={patientInfo}
                />

                <PatientDrugAllergy
                  data={prescriptionData}
                  updateData={updateData}
                  extraData={patientInfo}
                  previousInfo={patientInfo}
                />
                <PatientFoodAllergy
                  data={prescriptionData}
                  updateData={updateData}
                  extraData={patientInfo}
                  previousInfo={patientInfo}
                />

                <TestResultInput
                  data={{ investigation: testGiven }}
                  extraData={extraData}
                />
              </div>
            </div>
          </div>
          <div className="rfContFooter">
            {/* <div className="formBtnElement">
            </div> */}
            <div className="formBtnElement addRevPatStepEight">
              <Button
                className={
                  sos ? "dfultPrimaryBtnSOS" : "dfultDarkSecondaryBtnSOS"
                }
                onClick={(e) => {
                  setSos((prev) => !prev);
                }}
              >
                {t("sos")}
              </Button>
              <Button
                className="dfultPrimaryBtn"
                onClick={async () => {
                  // setShowConfirmation(true)

                  const isValid = validateVitals(
                    vitals,
                    getAge(selectedPatient.patientAge),
                    selectedPatient.patientGender
                  );

                  if (!isValid) {
                    setShowConfirmation(true);

                    return;
                  }

                  submitFormData();

                  const promises = userInvestigationList?.map((test) => {
                    let taskResult = test?.parameterList
                      ?.filter((item) => (item.value ? true : false))
                      .map((parameterItem) => {
                        return {
                          parameterName: parameterItem.parameter,
                          parameterValue: parameterItem.value,
                          parameterUnit: parameterItem.unit,
                          parameterRefRange: parameterItem.refRange[0],
                          parameterId: 0,
                        };
                      });
                    const reqDto = {
                      sampleType: test.sampleType || "",
                      testId: test.testId,
                      requestionId: 0,
                      testName: test.testName,
                      // visitId: extraData?.queueVisitId,
                      patientId: patient?.patientId || 0,
                      outSource: test.outSource ? "Y" : "N",
                      fileName: test?.fileData?.fileName || "",
                      reagent: test.reagent || "",
                      sampleCollectionTime: dayjs(
                        test.sampleCollectionTime
                      ).format("DD-MM-YYYY HH:mm:ss"),
                      timezone: timezone,
                      fileData: test?.fileData?.fileDataLink || "",
                      orgId: loginUserDetail.orgId,
                      testResult: taskResult,
                    };
                    console.log("reqdto =", reqDto);
                    // return reqDto;
                    return zhPatientService.submitTestResultWithoutRequestion(
                      loginUserDetail.userId,
                      reqDto
                    );
                  });
                  console.log("********* promises =", promises);

                  try {
                    await Promise.all(promises);

                    console.log("All tests submitted successfully!");
                  } catch (error) {
                    console.error(
                      "Error occurred while submitting tests:",
                      error
                    );
                  }
                }}
              >
                {t("submit")}
              </Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handleCloseAddReviewPatientToQueue()}
              >
                {t("close")}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {showFileUploadDialog && (
        <div className="RightFlotingContainerArea">
          <PatientFileUpload
            documents={documents}
            setDocuments={setDocuments}
            onClose={(e) => {
              setShowFileUploadDialog(false);
            }}
            previousMedicalRecords={previousMedicalRecords}
            setPreviousMedicalRecords={setPreviousMedicalRecords}
          />
        </div>
      )}

      {showConfirmation && (
        <CustomModal
          modalTitle={t("following_vitals_are_missing_do_you_want_to_continue")}
          // style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowConfirmation(false);
          }}
          className={"width90"}
        >
          <div>
            <ul>
              {VitalErrorList &&
                VitalErrorList.map((item) => {
                  return <li>{item}</li>;
                })}
            </ul>
          </div>

          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => {
                submitFormData();
                setShowConfirmation(false);
              }}
            >
              {t("continue")}
            </Button>
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                setShowConfirmation(false);
              }}
            >
              {t("cancel")}
            </Button>
          </div>
        </CustomModal>
      )}

{visualSymptoms && (
        <CustomModal
          modalTitle={t("select_visual_symptoms")}
          // style={{ zIndex: 10001 }}
          // closeModal={() => {
          //   setVisualSymptoms(false);
          // }}
          closeModal={handleCloseModal}
          className={"width90vwModel"}
        >
          <div className="vsContainer">
            <VisualSelection patientGender={selectedPatient?.patientGender?.toLowerCase()} handelfetchVisualSelectionData={handelfetchVisualSelectionData}/>
          </div>

          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={handleCloseModal}
              // onClick={(e) => {
              //   setVisualSymptoms(false);
              // }}
            >
              {t("cancel")}
            </Button>
            {visualSymptomsCheckData.length > 0 && (
            <Button
              className="dfultPrimaryBtn"
              // onClick={() => {
              //   setVisualSymptoms(false);
              // }}
              onClick={handelAddVisualSelectionData}
            >
              {t("add_symtoms")}
            </Button>
            )}
          </div>
        </CustomModal>
      )}
    </>
  );
};
