// symptomsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    reduxSelectedSymptoms: [],
    reduxActiveIds: [],
    reduxActiveLabels: [],
    reduxSelectedSecondLevelKey: null,
    reduxSelectedPart: null,
    reduxSecondStepActiveIds: [],
    reduxSelectedPartForSecond: null,
    reduxActiveLabelsForSecond: [],
  };

const symptomsSlice = createSlice({
    name: 'visualSelection',
    initialState,
    reducers: {
      setReduxSelectedSymptoms(state, action) {
        state.reduxSelectedSymptoms = action.payload;
      },
      setReduxActiveIds(state, action) {
        state.reduxActiveIds = action.payload;
      },
      setReduxActiveLabels(state, action) {
        state.reduxActiveLabels = action.payload;
      },
      setReduxSelectedSecondLevelKey(state, action) {
        state.reduxSelectedSecondLevelKey = action.payload;
      },
      setReduxSelectedPart(state, action) {
        state.reduxSelectedPart = action.payload;
      },
      setReduxSelectedPartForSecond(state, action) {
        state.reduxSelectedPartForSecond = action.payload;
      },
      setReduxSecondStepActiveIds(state, action) {
        state.reduxSecondStepActiveIds = action.payload;
      },
      setreduxActiveLabelsForSecond(state, action) {
        state.reduxActiveLabelsForSecond = action.payload;
      },
      reset(state) {
        return initialState;
      },
    },
  });

export const {
    setReduxSelectedSymptoms,
    setReduxActiveIds,
    setReduxActiveLabels,
    setReduxSelectedSecondLevelKey,
    setReduxSelectedPart,
    setReduxSelectedPartForSecond,
    setReduxSecondStepActiveIds,
    setreduxActiveLabelsForSecond,
    reset
  } = symptomsSlice.actions;
export default symptomsSlice.reducer;
