import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import AddCompound from "./AddCompound";
import { CompoundItem } from "./CompoundItem";

export const CompoundComponent = ({
  compound,
  parentId,
  updateCompound,
  compoundList,
  mode,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="CompoundDtlgrp">
        <ul className="compoundGrpUl">
          {compound?.compound?.map((citm) => {
            return (
              <li className="compoundGrpList">
                <CompoundItem
                  compoundList={compoundList}
                  compoundItem={citm}
                  parentId={compound.localId}
                  updateCompound={updateCompound}
                  mode={mode}
                />

                <div className="addAndCompound">
                  <Button
                    startIcon={<Add />}
                    className="addAndCompBtn"
                    onClick={() => {
                      const dto = {
                        type: "addNewCompound",
                        payload: {
                          parentId: parentId,
                          data: true,
                        },
                      };
                      updateCompound(dto);
                    }}
                  >
                    {t("add")}
                  </Button>
                </div>
              </li>
            );
          })}

          {compound?.addNewCompound && (
            <li className="compoundGrpList">
              <AddCompound
                compoundList={compoundList}
                handleAddCompound={(data) => {
                  const dto = {
                    type: "addAnd",
                    payload: {
                      parentId,
                      data,
                    },
                  };
                  updateCompound(dto);
                }}
                closeAddCompound={() => {
                  const dto = {
                    type: "addNewCompound",
                    payload: {
                      parentId: parentId,
                      data: false,
                    },
                  };
                  updateCompound(dto);
                }}
              />
            </li>
          )}
        </ul>
      </div>
    </>
  );
};
