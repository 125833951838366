import { Add, ArrowBack, Edit, Search, Visibility } from "@mui/icons-material";
import { Button, CircularProgress, IconButton } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { Download, Trash } from "react-bootstrap-icons";
import {
  deleteContract,
  getContractList,
  getCountryList,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { downloadFileFromUrl } from "../../../../utils/FileUtils";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import Joyride from "react-joyride";
import { TourContext } from "../../../../common/TourProvider";

export default function ViewContract({
  handelCloseViewContract,
  handelOpenAddContract,
  refreshContract,
  closeAll,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [loading, setLoading] = useState(true);
  const [CountryList, setCountryList] = useState([]);

  const [contractData, setContractData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredContractData, setFilteredContractData] = useState([]);

  console.log("userDetails.orgId", userDetails.orgId);

  useEffect(() => {
    fetchContractData();
  }, [refreshContract]);

  const fetchContractData = () => {
    getContractList(userDetails.orgId)
      .then((response) => {
        console.log("getContractList", response.data);
        setContractData(response.data);
        setFilteredContractData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getCountryList().then((response) => {
      console.log("getCountryList", response.data);
      setCountryList(response.data);
    });
  }, []);

  useEffect(() => {
    const filteredData = contractData.filter(
      (contract) =>
        contract.contractName &&
        contract.contractName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredContractData(filteredData);
  }, [contractData, searchQuery]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  const getCountryName = (countryCode) => {
    if (CountryList && countryCode) {
      const result = CountryList.find((val) => val.countryCode === countryCode);
      return result ? result.countryName : t("country_not_found");
    }
    return t("country_not_found");
  };

  const handleDownLoadFile = (contract) => {
    console.log("download");
    if (contract) {
      const fileLink = contract.fileDataLink;
      const fileNames = contract.fileName;
      downloadFileFromUrl(fileLink, fileNames);
    } else {
      console.log("No File found.");
    }
  };

  // useEffect(()=>{
  //  setBookSteps([
  //   {
  //     target: ".viewContractsStepOne",
  //     content: "Here You can view All the List of Contract Here.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".viewContractsStepTwo",
  //     content: "Click here to go back to the previous page.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".viewContractsStepThree",
  //     content: "Use the search bar to filter contracts by name.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".viewContractsStepFour",
  //     content: "Click here to add a new contract.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".viewContractsStepFive",
  //     content: "Here you can view all your contracts. The table displays key contract details.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".viewContractsStepSix",
  //     content: "You can perform actions on each contract: download, edit, or delete.",
  //     disableBeacon: true,
  //   }
  // ])
  // },[])

  useEffect(() => {
  
    setBookSteps([
      {
        target: ".viewContractsStepOne",
        content: t("view_contracts_step_one"),
        disableBeacon: true,
      },
      {
        target: ".viewContractsStepTwo",
        content: t("view_contracts_step_two"),
        disableBeacon: true,
      },
      {
        target: ".viewContractsStepThree",
        content: t("view_contracts_step_three"),
        disableBeacon: true,
      },
      {
        target: ".viewContractsStepFour",
        content: t("view_contracts_step_four"),
        disableBeacon: true,
      },
      {
        target: ".viewContractsStepFive",
        content: t("view_contracts_step_five"),
        disableBeacon: true,
      },
      {
        target: ".viewContractsStepSix",
        content: t("view_contracts_step_six"),
        disableBeacon: true,
      }
    ]);
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["ViewContractCDS"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="AnalyticsDashboardContainer viewContractsStepOne">
        <div className="AnalyticsDashboardSubHeader viewContractsStepTwo">
          <IconButton
            onClick={() => {
              handelCloseViewContract();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("contract")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              <div className="searchTasklist viewContractsStepThree">
                <input
                  type="text"
                  class="serchTaskInput"
                  placeholder={t("search_contract")}
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              <div className="addUnitsBtn">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn viewContractsStepFour"
                  onClick={() => handelOpenAddContract()}
                >
                  {t("contract")}
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : contractData.length === 0 ? (
                <div class="noDataCard ">
                  <span>{t("oops")}</span>
                  {t("no_contract_added_yet")}
                  <br />
                  <span className="sallMsg">
                    {t("click_add_contract_button_to_create_new_contract")}
                  </span>
                </div>
              ) : (
                <div className="TableContainer">
                  <table className="taskListTable viewContractsStepFive">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th className="width100">{t("sl_no")}</th>
                        <th>{t("contract_name")}</th>
                        <th>{t("contract_description")}</th>
                        <th>{t("contract_value")}</th>
                        <th>{t("currency")}</th>
                        <th>{t("phone_no")}</th>
                        {/* <th>{t("email_id")}</th>x */}
                        <th className="width100">{t("action")}</th>
                      </tr>
                    </thead>

                    <tbody className="scrolableTableBody">
                      {filteredContractData.map((contract, index) => (
                        <tr key={contract.id}>
                          <td>
                            <div className="tskLstTaskNM">{index + 1}</div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {contract.contractName}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {contract.contractDescription}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {contract.contractValue}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {contract.currency}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {contract.callingCode}-{contract.mobileNo}
                            </div>
                          </td>
                          {/* <td>
                            <div className="tskLstTaskNM">
                              {contract.emailId}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {contract.address}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {getCountryName(contract.countryCode)}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">{contract.taxId}</div>
                          </td> */}
                          <td>
                            <div className="tblActionBtnGrp viewContractsStepSix">
                              <IconButton
                                onClick={() => handleDownLoadFile(contract)}
                              >
                                <Download />
                              </IconButton>

                              <IconButton
                                onClick={() => handelOpenAddContract(contract)}
                              >
                                <Edit />
                              </IconButton>

                              <IconButton
                                className="removeRowBtn"
                                onClick={() => {
                                  deleteContract(contract.id).then(
                                    (response) => {
                                      if (response.data) {
                                        toast.success(t("contract_deleted"));
                                        fetchContractData();
                                      } else {
                                        toast.error(
                                          t("fail_to_delete_contract")
                                        );
                                      }
                                    }
                                  );
                                }}
                              >
                                <Trash />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
