import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { ConsoleLogger } from "aws-amplify/utils";
import React, { useState } from "react";
import { useEffect } from "react";
import { Trash, XLg } from "react-bootstrap-icons";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import { getStaticDDOfOrg } from "../../../../../services/AdminService";
import { toast } from "react-toastify";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export const TermsAndConditions = ({
  showTermsAndConditions,
  handelCloseLeaveBalanceModel,
  conditionList,
  userNames,
  getTermsAndCondition,
  selectedConditionList,
  setSelectedConditionList,
  userAddedTermsAndConditions,
  setUserAddedTermsAndConditions,
}) => {
  const loginUserDetail = GetLoginUserDetails();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [localUserAddedTAC, setLocalUserAddedTAC] = useState([]);
  const [showAddConditionModal, setShowAddConditionModal] = useState(false);
  const [heading, setHeading] = useState("");
  const [condition, setCondition] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [typeList, setTypeList] = useState([]);
  const [maxSlNo, setMaxSlNo] = useState(0);
  const [currentSlNo, setCurrentSlNo] = useState(0);

  const getMaxSlNo = () => {
    let tempMax = 0;

    if (conditionList && conditionList.length > 0) {
      conditionList.forEach((element) => {
        if (element.slNo > tempMax) {
          tempMax = element.slNo;
        }
      });
    }

    if (localUserAddedTAC && localUserAddedTAC.length > 0) {
      localUserAddedTAC.forEach((element) => {
        if (element.slNo > tempMax) {
          tempMax = element.slNo;
        }
      });
    }
    return tempMax;
  };

  useEffect(() => {
    setLocalUserAddedTAC(userAddedTermsAndConditions || []);
  }, [userAddedTermsAndConditions]);

  useEffect(() => {
    if (conditionList) {
      setLoading(false);
    }
    getStaticDDOfOrg(loginUserDetail.orgId, "TERMS_CONDITIONS_TYPE").then(
      (response) => {
        if (response) {
          setTypeList(response.data);
        } else {
          toast.error(
            t("did_not_found_terms_and_conditions_types_for_this_organization")
          );
        }
      }
    );
    const maxSlNo = getMaxSlNo();
    setMaxSlNo(maxSlNo);
    setCurrentSlNo(maxSlNo + 1);
  }, []);

  const handleCheckUncheckCondition = (data) => {
    let tmpList = selectedConditionList;
    if (tmpList.includes(data)) {
      tmpList = tmpList.filter((obj) => obj !== data);
    } else {
      tmpList = [...tmpList, ...[data]];
    }
    setSelectedConditionList(tmpList);
  };

  const handleAddTermsAndCondition = (e) => {
    let isValidForm = true;

    if (heading === "") {
      toast.error(t("please_enter_heading"));
      isValidForm = false;
    }
    if (condition === "") {
      toast.error(t("please_enter_condition"));
      isValidForm = false;
    }
    if (selectedType === "") {
      toast.error(t("please_select_type"));
      isValidForm = false;
    }
    if (!isValidForm) {
      return;
    }

    const dto = {
      id: 0,
      slNo: currentSlNo,
      termsConditionsHeading: heading,
      termsConditions: condition,
      termsConditionsType: selectedType,
      transType: "RFP",
    };
    setLocalUserAddedTAC((prev) => (prev ? [...prev, dto] : []));
    setHeading("");
    setCondition("");
    setSelectedType("");
    setShowAddConditionModal(false);
  };

  const calculateQuotationValue = (item) => {
    // console.log("item=", item)
    let value = 0;
    value = item.quotationQty * item.quotationRate + item.taxAmt;
    item.quotationValue = value;
    return value ? value : 0;
  };

  return (
    <>
      <div
        className="modelContainer"
        style={{ zIndex: "9999" }}
        open={showTermsAndConditions}
      >
        <Box className="modelContain  width90vwModel">
          <IconButton
            className="closeModelIoc"
            onClick={(e) => {
              handelCloseLeaveBalanceModel();
              getTermsAndCondition([
                ...selectedConditionList,
                ...localUserAddedTAC,
              ]);
              setUserAddedTermsAndConditions(localUserAddedTAC);
            }}
          >
            <XLg />
          </IconButton>

          <div className="fwModelContainArea">
            <div className="modHeader">
              <div className="modHeaderText">{t("terms_and_conditions")}</div>
            </div>

            <div className="modBody">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : (
                <>
                  <div className="modResptable">
                    <table>
                      <thead>
                        <tr>
                          <th className="minWidth160">{t("sl_no")}</th>
                          <th>{t("type")}</th>
                          <th>{t("heading")}</th>
                          <th>{t("terms_and_conditions")}</th>
                          <th>
                            <Tooltip title={t("add_terms_and_conditions")}>
                              <IconButton
                                // className="dfultPrimaryBtn"
                                onClick={(e) => {
                                  console.log("Add condition clicked ");
                                  setCurrentSlNo(getMaxSlNo() + 1);
                                  setShowAddConditionModal(true);
                                }}
                              >
                                <Add />
                                {/* Add Conditions */}
                              </IconButton>
                            </Tooltip>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {conditionList &&
                          conditionList.map((condition, index) => (
                            <tr>
                              <td>{condition.slNo}</td>
                              <td>{condition.termsConditionsType}</td>
                              <td>{condition.termsConditionsHeading}</td>
                              <td>{condition.termsConditions}</td>
                              <td>
                                <Switch
                                  className="switch"
                                  inputProps={{ "aria-label": "controlled" }}
                                  checked={selectedConditionList.includes(
                                    condition
                                  )}
                                  onClick={(e) => {
                                    handleCheckUncheckCondition(condition);
                                  }}
                                />
                              </td>
                            </tr>
                          ))}

                        {localUserAddedTAC &&
                          localUserAddedTAC.map((condition, index) => (
                            <tr>
                              <td>{condition.slNo}</td>
                              <td>{condition.termsConditionsType}</td>
                              <td>{condition.termsConditionsHeading}</td>
                              <td>{condition.termsConditions}</td>
                              <td>
                                <IconButton>
                                  <Trash
                                    onClick={(e) => {
                                      const temp = [...localUserAddedTAC];
                                      temp.splice(index, 1);
                                      setLocalUserAddedTAC(temp);
                                    }}
                                  />
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          </div>
        </Box>
      </div>

      {showAddConditionModal && (
        <>
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4, zIndex: 10000 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("add_terms_and_condition")}</div>

              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      label={t("sl_no")}
                      variant="outlined"
                      multiline
                      // rows={3}
                      value={currentSlNo}
                      onChange={(e) => {
                        setCurrentSlNo(e.target.value);
                      }}
                      autoComplete="off"
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel id="setActivity">{t("type")}</InputLabel>
                    <Select
                      className="modelSelectFild"
                      label="Activity"
                      variant="outlined"
                      labelId="setActivity"
                      id="activity-select"
                      value={selectedType}
                      onChange={(e) => setSelectedType(e.target.value)}
                    >
                      <MenuItem value={""}>--{t("select_type")}--</MenuItem>
                      {typeList &&
                        typeList.length > 0 &&
                        typeList.map((item) => (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        ))}
                      {/* <MenuItem value={"Appointment"}>Appointment</MenuItem>
                      <MenuItem value={"Board Meeting"}>Board Meeting</MenuItem>
                      <MenuItem value={"Budget Requisition"}>
                        Budget Requisition
                      </MenuItem> */}
                    </Select>
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      label={t("heading")}
                      variant="outlined"
                      multiline
                      rows={3}
                      value={heading}
                      onChange={(e) => {
                        setHeading(e.target.value);
                      }}
                      autoComplete="off"
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      label={t("condition")}
                      variant="outlined"
                      multiline
                      rows={3}
                      value={condition}
                      onChange={(e) => {
                        setCondition(e.target.value);
                      }}
                      autoComplete="off"
                    />
                  </FormControl>
                </div>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={handleAddTermsAndCondition}
                >
                  {t("add")}
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowAddConditionModal(false);
                  }}
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </Box>
        </>
      )}
    </>
  );
};
