import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
} from "@mui/material";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import React, { useContext, useState } from "react";
import { ArrowRepeat, ArrowsAngleExpand } from "react-bootstrap-icons";
import { Bar } from "react-chartjs-2";
import { jwtDecode } from "jwt-decode";
import { getUnits } from "../../../../../services/AdminService";
import { useEffect } from "react";
import DashboardServices from "../../../../../services/DashboardServices";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import ReactDatePicker from "react-datepicker";
import DateUtils from "../../../../../utils/DateUtils";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController
);

export default function CardTask({
  chartDataOfItem,
  // showMoreBtn = true,
  // onOpenMore,
}) {
  const { t } = useTranslation();
  // stacked chart
  const userDetails = GetLoginUserDetails();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  console.log("userDetails", userDetails);

  console.log("TaskCard chartDataOfItem", chartDataOfItem);

  const [managementData, setManagementData] = useState({});
  const [showMoreAnchorEl, setShowMoreAnchorEl] = useState(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    setManagementData(chartDataOfItem?.axTaskDto || {});
  }, [chartDataOfItem]);

  // useEffect(() => {
  //   if (chartDataOfItem) {
  //     setManagementData(chartDataOfItem);
  //   }
  // }, [chartDataOfItem]);

  // const [primaryPosdate, setPrimaryPosdate] = useState(new Date());

  const [primaryPosdate, setPrimaryPosdate] = useState(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 3);
    return date;
  });

  const handleprimaryPosdate = (newDate) => {
    setPrimaryPosdate(newDate);
    setIsFirstLoad(false);
  };

  console.log("managementData  Task data", managementData);

  // const TaskData = managementData?.tasks || [];

  // let labelSet = new Map();
  // let investigationMap = new Map();

  // //   let dayWiseUnitInvestigations = chartDataOfItem?.dayWiseUnitInvestigations;

  // for (let itr of TaskData) {
  //   if (!labelSet.has(itr.xlabel)) {
  //     labelSet.set(itr.xlabel, true);
  //   }

  //   if (investigationMap.has(itr.label)) {
  //     let existingArray = investigationMap.get(itr.label);
  //     existingArray.push(itr.count);
  //     investigationMap.set(itr.label, existingArray);
  //   } else {
  //     let newArray = [itr.count];
  //     investigationMap.set(itr.label, newArray);
  //   }
  // }

  // console.log("labelSet", labelSet);
  // console.log("investigationMap", investigationMap);

  // const getRandomColor = () => {
  //   const hue = Math.floor(Math.random() * 360);
  //   const saturation = 70 + Math.floor(Math.random() * 30);
  //   const lightness = 50 + Math.floor(Math.random() * 20);

  //   return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  // };

  // let listlable = [];
  // labelSet.forEach((values, keys) => {
  //   listlable.push(keys);
  // });
  // let charconfidataset = [];
  // investigationMap.forEach((values, keys) => {
  //   var obj = {
  //     label: keys,
  //     type: "bar",
  //     data: values,
  //     //   borderColor: getRandomColor(),
  //     backgroundColor: getRandomColor(),
  //     fill: false,
  //     borderWidth: 1,
  //     tension: 0.3,
  //   };
  //   charconfidataset.push(obj);
  // });

  // console.log("listlable", listlable);
  // console.log("charconfidataset", charconfidataset);

  // const barLineData = {
  //   labels: listlable,
  //   datasets: charconfidataset,
  // };

  // Bar Chart Data
  //   const barLineData = {
  //     labels: [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //       "Oct",
  //       "Nov",
  //       "Dec",
  //     ],
  //     datasets: [
  //       {
  //         label: t("center"),
  //         type: "line",
  //         data: [8, 4, 10, 8, 2, 4, 3, 7, 2, 5, 6, 9],
  //         //   data: unitConsulted,
  //         borderColor: "rgba(255, 206, 86, 1)",
  //         backgroundColor: "rgba(255, 206, 86, .5)",
  //         fill: false,
  //         tension: 0.3,
  //         hidden: true,
  //       },
  //       {
  //         label: t("repeat"),
  //         type: "line",
  //         data: [150, 110, 130, 150, 120, 120, 180, 110, 160, 140, 120, 110],
  //         //   data: repeatConsultation,

  //         borderColor: "rgba(73, 245, 255, 1)",
  //         backgroundColor: "rgba(0, 158, 167,1)",
  //         fill: false,
  //         tension: 0.3,
  //       },
  //       {
  //         label: t("doctor"),
  //         type: "line",
  //         data: [120, 140, 160, 170, 110, 130, 160, 140, 150, 120, 140, 150],
  //         //   data: doctorConsulted,
  //         borderColor: "rgba(106, 251, 154, 1)",
  //         backgroundColor: "rgba(0, 154, 51, 1)",
  //         fill: false,
  //         tension: 0.3,
  //         hidden: true,
  //       },
  //       {
  //         label: t("consultation"),
  //         backgroundColor: "rgba(153, 153, 153, 1)",
  //         borderColor: "rgba(206,206,206,1)",
  //         borderWidth: 1,
  //         borderRadius: 4,
  //         hoverBackgroundColor: "rgba(153, 153, 153,0.4)",
  //         hoverBorderColor: "rgba(95,104,103,1)",
  //         data: [250, 210, 300, 350, 260, 200, 280, 310, 260, 240, 340, 280],
  //         //   data: consultation,
  //         barThickness: 16,
  //       },
  //     ],
  //   };

  // const barLineOptions = {
  //   cutout: "90%",

  //   plugins: {
  //     datalabels: {
  //       display: false,
  //     },
  //     legend: {
  //       display: false,
  //       position: "bottom",
  //       labels: {
  //         boxWidth: 10,
  //         color: "#d2d2d2",
  //         font: {
  //           size: 12,
  //           weight: "light",
  //         },
  //       },
  //     },
  //   },
  //   scales: {
  //     x: {
  //       stacked: true,
  //     },
  //     y: {
  //       stacked: true,
  //     },
  //   },
  //   borderRadius: 4,
  // };

  //   const barLineOptions = {
  //     responsive: true,
  //     plugins: {
  //       datalabels: {
  //         display: false,
  //       },
  //       legend: {
  //         display: true,
  //         position: "bottom",
  //         labels: {
  //           boxWidth: 16,
  //           padding: 10,
  //         },
  //       },
  //       title: {
  //         display: true,
  //         text: "",
  //       },
  //     },
  //     barThickness: 10,
  //     maxBarThickness: 20,
  //     minBarLength: 2,
  //   };
  const [filterBy, setFilterBy] = useState("P");
  const handleChangefilterop = (event) => {
    setFilterBy(event.target.value);
    setIsFirstLoad(false);
  };

  useEffect(() => {
    if (!isFirstLoad) {
      fetchRefreshManagementData();
    }
  }, [primaryPosdate, filterBy]);

  const fetchRefreshManagementData = () => {
    if (filterBy && userDetails) {
      // const reqDto = {
      //   unitId: 0,
      //   orgId: userDetails.orgId,
      //   userId: "",
      //   periodDailyMnthly: "",
      //   dataLimit: 0,
      //   feedbackFromPatientDoctor: "",
      //   fromDate: DateUtils.getDateInDDMMYYYY(primaryPosdate),
      //   toDate: "",
      //   unitDoctor: "",
      //   status: filterBy,
      // };
      const reqDto = {
        unitId: 0,
        orgId: "",
        userId: "",
        periodDailyMnthly: "",
        dataLimit: 0,
        feedbackFromPatientDoctor: "",
        fromDate: "",
        toDate: "",
        unitDoctor: "",
        status: "",
        taskOrgId: userDetails.orgId,
        taskStartDate: DateUtils.getDateInDDMMYYYY(primaryPosdate),
        taskCategory: filterBy,
      };
      console.log("reqDto", reqDto);
      DashboardServices.refreshManagementTasks(reqDto).then((response) => {
        console.log("response", response.data);
        setManagementData(response.data.axTaskDto || {});
      });
    }
  };

  const getRandomColor = () => {
    const hue = Math.floor(Math.random() * 360);
    return `hsl(${hue}, 70%, 60%)`;
  };

  const TaskData = managementData?.tasks || [];

  const formattedData = TaskData.map((task) => ({
    ...task,
    status: task.status === "C" ? "Close" : "Open",
  }));

  const groupedData = {};
  formattedData.forEach((task) => {
    const key = `${task.xlabel} (${task.status})`;
    if (!groupedData[key]) {
      groupedData[key] = { xlabel: task.xlabel, status: task.status, data: {} };
    }
    groupedData[key].data[task.stackLabel] =
      (groupedData[key].data[task.stackLabel] || 0) + task.count;
  });

  const xLabels = [
    ...new Set(formattedData.map((task) => `${task.xlabel} (${task.status})`)),
  ];
  const stackLabels = [...new Set(TaskData.map((task) => task.stackLabel))];

  const datasets = stackLabels.map((stackLabel) => ({
    label: stackLabel,
    data: xLabels.map((label) => groupedData[label]?.data[stackLabel] || 0),
    backgroundColor: getRandomColor(),
  }));

  const barLineData = {
    labels: xLabels,
    datasets,
  };

  const barLineOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
      },
    },
  };

  // const barLineOptions = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: "buttom",
  //     },
  //   },
  //   scales: {
  //     x: {
  //       stacked: true,
  //     },
  //     y: {
  //       stacked: true,
  //       beginAtZero: true,
  //     },
  //   },
  // };

  const transformDataForChart = (tasks) => {
    const uniqueLabels = [...new Set(tasks.map((task) => task.xlabel))];

    const uniqueStackLabels = [
      ...new Set(tasks.map((task) => task.stackLabel)),
    ];

    const datasetMapping = {
      open: {},
      close: {},
    };

    uniqueStackLabels.forEach((stackLabel) => {
      datasetMapping.open[stackLabel] = Array(uniqueLabels.length).fill(0); 
      datasetMapping.close[stackLabel] = Array(uniqueLabels.length).fill(0);
    });

    tasks.forEach((task) => {
      const labelIndex = uniqueLabels.indexOf(task.xlabel);
      const stack = task.status === "O" ? "open" : "close";
      datasetMapping[stack][task.stackLabel][labelIndex] += task.count;
    });

    const datasets = [];
    Object.entries(datasetMapping).forEach(([stack, stackData]) => {
      Object.entries(stackData).forEach(([stackLabel, data]) => {
        datasets.push({
          // label: `${
          //   stack.charAt(0).toUpperCase() + stack.slice(1)
          // } - ${stackLabel}`,
          label: `${t(stack)} - ${stackLabel}`,
          data,
          backgroundColor:
            stack === "open"
              ? stackLabel === "Overdue"
                ? "rgba(255, 99, 132, 0.6)"
                : stackLabel === "Ontime"
                ? "rgba(75, 192, 192, 0.6)"
                : "rgba(54, 162, 235, 0.6)"
              : stackLabel === "Overdue"
              ? "rgba(255, 159, 64, 0.6)"
              : "rgba(153, 102, 255, 0.6)",
          borderColor:
            stack === "open"
              ? stackLabel === "Overdue"
                ? "rgba(255, 99, 132, 1)"
                : stackLabel === "Ontime"
                ? "rgba(75, 192, 192, 1)"
                : "rgba(54, 162, 235, 1)"
              : stackLabel === "Overdue"
              ? "rgba(255, 159, 64, 1)"
              : "rgba(153, 102, 255, 1)",
          borderWidth: 1,
          stack,
        });
      });
    });

    return {
      labels: uniqueLabels, // X-axis labels
      datasets, // Dynamically created datasets
    };
  };

  // Example usage
  const testdata = transformDataForChart(managementData?.tasks || []);

  console.log(testdata);

  // const testdata = {
  //   labels: ["Regular", "Important", "Critical"], // X-axis labels
  //   datasets: [
  //     // Open Bar - Overdue
  //     {
  //       label: "Open - Overdue", // Segment for Overdue in Open
  //       data: [5, 3, 1], // Data for Overdue in Open
  //       backgroundColor: "rgba(255, 99, 132, 0.6)", // Color for Overdue
  //       borderColor: "rgba(255, 99, 132, 1)",
  //       borderWidth: 1,
  //       stack: "open", // Stack all Open bars together
  //     },
  //     {
  //       label: "Open - OnTime", // Segment for OnTime in Open
  //       data: [4, 2, 1], // Data for OnTime in Open
  //       backgroundColor: "rgba(75, 192, 192, 0.6)", // Color for OnTime
  //       borderColor: "rgba(75, 192, 192, 1)",
  //       borderWidth: 1,
  //       stack: "open",
  //     },
  //     {
  //       label: "Open - Delayed", // Segment for Delayed in Open
  //       data: [3, 2, 1], // Data for Delayed in Open
  //       backgroundColor: "rgba(54, 162, 235, 0.6)", // Color for Delayed
  //       borderColor: "rgba(54, 162, 235, 1)",
  //       borderWidth: 1,
  //       stack: "open",
  //     },
  //     // Close Bar - Overdue
  //     {
  //       label: "Close - Overdue", // Segment for Overdue in Close
  //       data: [3, 2, 1], // Data for Overdue in Close
  //       backgroundColor: "rgba(255, 159, 64, 0.6)", // Color for Overdue in Close
  //       borderColor: "rgba(255, 159, 64, 1)",
  //       borderWidth: 1,
  //       stack: "close", // Stack all Close bars together
  //     },
  //     {
  //       label: "Close - OnTime", // Segment for OnTime in Close
  //       data: [2, 5, 4], // Data for OnTime in Close
  //       backgroundColor: "rgba(153, 102, 255, 0.6)", // Color for OnTime in Close
  //       borderColor: "rgba(153, 102, 255, 1)",
  //       borderWidth: 1,
  //       stack: "close",
  //     },
  //   ],
  // };

  const testoptions = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: t("priority_levels_chart"),
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: false,
          text: t("priority"),
        },
        stacked: false,
      },
      y: {
        title: {
          display: true,
          text: t("count"),
        },
        stacked: true,
        beginAtZero: true, // Stack the bars
      },
    },
    indexAxis: "x", // Ensures bars are aligned horizontally
    barPercentage: 2, // Reduces the width of the bars
    categoryPercentage: 0.4, // Reduces the spacing between bars
    elements: {
      bar: {
        borderWidth: 1, // Adds a thin border around each bar
      },
    },
  };

//   useEffect(()=>{
//   setBookSteps([
//     {
//       target: ".taskCardStepOne",
//       content: "This is the Task Card header. Here, you can see the task category",
//       disableBeacon: true,
//     },
//     {
//       target: ".taskCardStepTwo",
//       content: "Click this icon to refresh the data displayed on this card.",
//       disableBeacon: true,
//     },
//     {
//       target: ".taskCardStepThree",
//       content: "Use this date picker to filter tasks by their start date.",
//       disableBeacon: true,
//     },
//     {
//       target: ".taskCardStepFour",
//       content: "Use this dropdown to filter tasks by category (e.g., Priority, Completed).",
//       disableBeacon: true,
//     },
//     {
//       target: ".taskCardStepFive",
//       content: "Here You can see about the details of Open And Closed Task",
//       disableBeacon: true,
//     },
//     {
//       target: ".taskCardStepSix",
//       content: "This bar chart shows a visual representation of task statuses and priorities. Hover over the bars to see detailed information.",
//       disableBeacon: true,
//     },
//   ])
// },[])

useEffect(() => {
  
  setBookSteps([
    {
      target: ".taskCardStepOne",
      content: t("task_card_step_one"),
      disableBeacon: true,
    },
    {
      target: ".taskCardStepTwo",
      content: t("task_card_step_two"),
      disableBeacon: true,
    },
    {
      target: ".taskCardStepThree",
      content: t("task_card_step_three"),
      disableBeacon: true,
    },
    {
      target: ".taskCardStepFour",
      content: t("task_card_step_four"),
      disableBeacon: true,
    },
    {
      target: ".taskCardStepFive",
      content: t("task_card_step_five"),
      disableBeacon: true,
    },
    {
      target: ".taskCardStepSix",
      content: t("task_card_step_six"),
      disableBeacon: true,
    },
  ]);
}, []);

const handleJoyrideCallback = (data) => {
  const { status, index , action} = data;
  console.log("status index", status, index , action);
  if (status == "skipped" || action === "close" || action === "skip") {
      setRun(false);
  }
};

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["cardTaskDS"] === true}
        continuous={true}
        showSkipButton={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        styles={{
          overlay: {
            height: "100vh",
          },
        }}
        locale={{
          back: t('back'),
          last: t('finish'),
          next: t('next'),
          skip: t('skip'),
        }}
      />
      <div className="anDashIndCard manageWidth50 taskCardStepOne">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM ">
              {t("task")}
              <IconButton
                className={`refreshIcon taskCardStepTwo`}
                onClick={() => {
                  setIsFirstLoad(false);
                  fetchRefreshManagementData();
                }}
              >
                <ArrowRepeat />
              </IconButton>
            </div>

            <div className="andCrdUnitCount cardFrom">
              <div className="formElement maxw140">
                <FormControl size="small" className="formControl w110 ">
                  <InputLabel id="primPosition" className="setCompletionDate">
                    {t("from_date")}
                  </InputLabel>
                  <ReactDatePicker
                    // locale={currentLocale}
                    toggleCalendarOnIconClick
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    showMonthDropdown
                    showIcon
                    labelId="primPosition"
                    className="formDatePicker taskCardStepThree"
                    selected={primaryPosdate}
                    // minDate={new Date()}
                    // maxDate={primaryPosdate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      handleprimaryPosdate(date);
                    }}
                  />
                </FormControl>
              </div>
              <div className="formElement maxw140">
                <FormControl
                  size="small"
                  className="formControl defultFrmHeight"
                >
                  <Select
                    className="formInputField taskCardStepFour"
                    labelId="filterBy"
                    id="filterBy-select"
                    value={filterBy}
                    onChange={handleChangefilterop}
                  >
                    <MenuItem value={"P"}>{t("priority_wise")}</MenuItem>
                    <MenuItem value={"C"}>{t("contract_wise")}</MenuItem>
                    <MenuItem value={"U"}>{t("user_wise")}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>

          <div className="andCrdGraf align-item-center">
            <div className="anDLftGrf taskCardStepFive">
              <div className="listOfTaskData">
                <div className="totalTaskCount">
                  <div className="tsklabel">{t("total_task")}</div>
                  <div className="tskValue">{managementData?.totalTask}</div>
                </div>
                <div className="ttlTskList">
                  <div className="tskCatg">{t("completed")}</div>
                  <ul>
                    <li>
                      <div className="ttlTskInd">
                        <div className="taskLabel">{t("no_of_task")}</div>
                        <div className="taskValue">
                          <div className="valCount">
                            {managementData?.completedCount}
                          </div>
                          <div className="valperc">
                            {managementData?.completedPc}%
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="ttlTskInd">
                        <div className="taskLabel">{t("overdue")}</div>
                        <div className="taskValue">
                          <div className="valCount">
                            {managementData?.overDueCompletedCount}
                          </div>
                          <div className="valperc">
                            {managementData?.overDueCompletedPc}%
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="ttlTskInd">
                        <div className="taskLabel">{t("ontime")}</div>
                        <div className="taskValue">
                          <div className="valCount">
                            {managementData?.inTimeCompletedCount}
                          </div>
                          <div className="valperc">
                            {managementData?.inTimeCompletedPc}%
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="ttlTskInd">
                        <div className="taskLabel">
                          {t("avg_completion_time")}
                        </div>
                        <div className="taskValue">
                          <div className="valCount">
                            {managementData?.avgCompletionTime}%
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="ttlTskList">
                  <div className="tskCatg">{t("open")}</div>
                  <ul>
                    <li>
                      <div className="ttlTskInd">
                        <div className="taskLabel">{t("no_of_task")}</div>
                        <div className="taskValue">
                          <div className="valCount">
                            {managementData?.openCount}
                          </div>
                          <div className="valperc">
                            {managementData?.openPc}%
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="ttlTskInd">
                        <div className="taskLabel">{t("overdue")}</div>
                        <div className="taskValue">
                          <div className="valCount">
                            {managementData?.overDueOpenCount}
                          </div>
                          <div className="valperc">
                            {managementData?.overDueOpenPc}%
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="ttlTskInd">
                        <div className="taskLabel">{t("delay")}</div>
                        <div className="taskValue">
                          <div className="valCount">
                            {managementData?.delayOpenCount}
                          </div>
                          <div className="valperc">
                            {managementData?.delayOpenPc}%
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="ttlTskInd">
                        <div className="taskLabel">{t("ontime")}</div>
                        <div className="taskValue">
                          <div className="valCount">
                            {managementData?.inTimeOpenCount}
                          </div>
                          <div className="valperc">
                            {managementData?.inTimeOpenPc}%
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="ttlTskInd">
                        <div className="taskLabel">{t("avg_progress")}</div>
                        <div className="taskValue">
                          <div className="valCount">
                            {managementData?.avgProgress}%
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="anDRightGrf taskCardStepSix">
              {/* <Bar
                className="hw100"
                type="bar"
                data={barLineData}
                options={barLineOptions}
              /> */}
              <Bar
                className="hw100"
                type="bar"
                data={testdata}
                options={testoptions}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
