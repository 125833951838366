import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { XLg } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import zhPatientService from "../../../../../services/ZhPatientService";
import { getLoginUserDetailsLocal } from "../../../../../utils/Utils";
import { useTranslation } from "react-i18next";

export const EditTest = ({ testData, handleClose }) => {
  const { t } = useTranslation();
  const loginUserDetails = getLoginUserDetailsLocal();
  const [localTestData, setLocalTestData] = useState({});

  useEffect(() => {
    console.log("test data =", testData);
    setLocalTestData(testData || {});
  }, [testData]);

  return (
    <div className="flotingAreaContainer">
      <div className="flotingAreaClose">
        <div className="facHeader">{t("edit_test")}</div>
        <IconButton className="CloseBtn" onClick={() => handleClose()}>
          <XLg />
        </IconButton>
      </div>

      <div className="elementFormContainer gap10">
        {/* <div className="tskElemHeding mb-8">{t("disease_with_icd11")}</div> */}

        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              // hiddenLabel
              label={t("test_name")}
              required
              autoComplete="off"
              variant="outlined"
              className="formTextFieldArea"
              value={localTestData?.testName || ""}
              onChange={(e) => {
                let temp = { ...localTestData };
                temp.testName = e.target.value;
                setLocalTestData(temp);
              }}
              //   disabled
            />
          </FormControl>
        </div>

        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              // hiddenLabel
              label={t("group_name")}
              //    required
              autoComplete="off"
              variant="outlined"
              className="formTextFieldArea"
              value={localTestData?.groupName || ""}
              onChange={(e) => {
                let temp = { ...localTestData };
                temp.groupName = e.target.value;
                setLocalTestData(temp);
              }}
              //   disabled
            />
          </FormControl>
        </div>

        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              // hiddenLabel
              label={t("disk_name")}
              //    required
              autoComplete="off"
              variant="outlined"
              className="formTextFieldArea"
              value={localTestData?.diskName || ""}
              onChange={(e) => {
                let temp = { ...localTestData };
                temp.diskName = e.target.value;
                setLocalTestData(temp);
              }}
              //   disabled
            />
          </FormControl>
        </div>

        <div className="formElement">
          <FormControl className="formControl">
            <InputLabel id="compTime">{t("parameter_gender")}</InputLabel>
            <Select
              className="formInputField"
              variant="outlined"
              labelId="compTime"
              id="compTime-select"
              value={localTestData?.gender || testData.gender}
              label={t("completion_time")}
              onChange={(e) => {
                const temp = { ...localTestData };
                temp.gender = e.target.value;
                setLocalTestData(temp);
              }}
            >
              <MenuItem value={"C"}>{t("common")}</MenuItem>
              <MenuItem value={"M"}>{t("male")}</MenuItem>
              <MenuItem value={"F"}>{t("female")}</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="formElementGrp mb-8">
          <div className="formElement"></div>
          {/* <Button
            startIcon={<Add />}
            className="dfultPrimaryBtn"
           
          >
            {t("add")}
          </Button> */}
        </div>

        {/* End procedure */}
      </div>

      <div className="elementFooter">
        <div className="formBtnElement">
          <Button
            className="dfultPrimaryBtn"
            onClick={() => {
              if (!localTestData?.testName?.trim()) {
                toast.error("Please Provide Test Name");
                return;
              }
              //   if (!localTestData?.groupName?.trim()) {
              //     toast.error("Please Provide Group Name");
              //     return;
              //   }
              //   if (!localTestData?.diskName?.trim()) {
              //     toast.error("Please Provide Disk Name");
              //     return;
              //   }

              console.log("test name =", localTestData);
              //   return;

              zhPatientService
                .editTest(
                  loginUserDetails.orgId,
                  loginUserDetails.orgCountryCode,
                  localTestData
                )
                .then((response) => {
                  if (response.data == "0") {
                    toast.error(t("test_could_not_be_updated"));
                    return;
                  } else if (response.data == "1") {
                    const str = t("test_updated");
                    toast.success(str);
                    handleClose();
                    return;
                  } else {
                    toast.error(t("test_already_exists"));
                    return;
                  }
                });
            }}
          >
            {t("submit")}
          </Button>

          <Button
            className="dfultDarkSecondaryBtn"
            onClick={() => handleClose()}
          >
            {t("cancel")}
          </Button>
        </div>
      </div>
    </div>
  );
};
