import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { Trash, XLg } from "react-bootstrap-icons";
import {
  getCallingCodeList,
  createVendorPartner,
  getCountryDtlByCode,
  getCountryList,
  getStateList,
} from "../../../../services/AdminService";
import { GetLoginUserDetails, validateEmail } from "../../../../utils/Utils";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { currentSubdomain } from "../../../../constants/const_string";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";

export default function AddExternalUser({
  handelCloseAddExternalUser,
  refreshExternalUserData,
  fetchDashBoardData,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const [loading, setLoading] = useState(false);

  const [exUsrType, setExUsrType] = useState("");
  const [exUsrTitle, setExUsrTitle] = useState("");
  const [userFirsName, setUserFirsName] = useState("");
  const [userMiddleName, setUserMiddleName] = useState("");
  const [userlastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [empUsrPhnCC, setEmpUsrPhnCC] = useState("");
  const [cdCodeList, setCdCodeList] = useState([]);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userFaxNumber, setUserFaxNumber] = useState("");
  const [userGSTNumber, setUserGSTNumber] = useState("");
  const [phoneRegexPattern, setPhoneRegexPattern] = useState(/^[0-9]{10,}$/);
  const [CountryList, setCountryList] = useState([]);
  const [selectCountry, setSelectCountry] = useState("");
  const [selectCountryInput, setSelectCountryInput] = useState("");
  const [selectStateId, setSelectStateId] = useState("");
  const [selectState, setSelectState] = useState([]);

  const handleChangeExUsrType = (event) => {
    console.log("handleChangeExUsrType", event.target.value);
    setExUsrType(event.target.value);
  };

  const handleChangeExUsrTitle = (event) => {
    console.log("handleChangeExUsrTitle", event.target.value);
    setExUsrTitle(event.target.value);
  };

  const handleChangeUserFirsName = (event) => {
    console.log("handleChangeUserFirsName", event.target.value);
    setUserFirsName(event.target.value);
  };

  const handleChangeUserMiddleName = (event) => {
    console.log("handleChangeUserMiddleName", event.target.value);
    setUserMiddleName(event.target.value);
  };

  const handleChangeUserLastName = (event) => {
    console.log("handleChangeUserLastName", event.target.value);
    setUserLastName(event.target.value);
  };

  const handleChangeUserEmail = (event) => {
    console.log("handleChangeUserEmail", event.target.value);
    setUserEmail(event.target.value);
  };

  useEffect(() => {
    getCallingCodeList().then((response) => {
      console.log("getCallingCodeList", response.data);
      setCdCodeList(response.data);
    });
  }, []);

  useEffect(() => {
    if (userDetails.orgCountryCode) {
      getCountryDtlByCode(userDetails.orgCountryCode).then((response) => {
        setEmpUsrPhnCC(response.data.callingCd);
        setSelectCountry(response.data);
        switch (response.data.callingCd) {
          case "+91": // India
            setPhoneRegexPattern(/^[6-9]\d{9}$/);
            break;
          case "+52": // Mexico
            setPhoneRegexPattern(/^[1-9]\d{9}$/);
            break;
          case "+971": // UAE
            setPhoneRegexPattern(/^(?:\50|51|52|55|56|2|3|4|6|7|9)\d{7}$/);
            break;
          default:
            setPhoneRegexPattern(/^[0-9]{10,}$/); // Default pattern for other countries
            break;
        }
      });
    }
  }, [userDetails.orgCountryCode]);

  useEffect(() => {
    getCountryList().then((response) => {
      console.log("getCountryList", response.data);
      setCountryList(response.data);
    });
  }, []);

  useEffect(() => {
    getStateList("356").then((response) => {
      console.log("getStateList", response.data);
      setSelectState(response.data);
    });
  }, []);

  const handleChangeEmpUsrPhnCC = (event) => {
    console.log("handleChangeEmpUsrPhnCC", event.target.value);
    setEmpUsrPhnCC(event.target.value);

    switch (event.target.value) {
      case "+91": // India
        setPhoneRegexPattern(/^[6-9]\d{9}$/);
        break;
      case "+52": // Mexico
        setPhoneRegexPattern(/^[1-9]\d{9}$/);
        break;
      case "+971": // UAE
        setPhoneRegexPattern(/^(?:\50|51|52|55|56|2|3|4|6|7|9)\d{7}$/);
        break;
      default:
        setPhoneRegexPattern(/^[0-9]{10,}$/); // Default pattern for other countries
        break;
    }
  };

  const handleChangeUserPhoneNumber = (event) => {
    console.log("handleChangeUserPhoneNumber", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setUserPhoneNumber(numericValue);
  };

  const handleChangeUserAddress = (event) => {
    console.log("handleChangeUserAddress", event.target.value);
    setUserAddress(event.target.value);
  };

  const handleChangeCountry = (event, newValue) => {
    console.log("handleChangeCountry - newValue:", newValue);
    if (newValue) {
      console.log("handleChangeCountry - countryCode:", newValue.countryCode);
      setSelectCountry(newValue);
      if (newValue.countryCode !== "356") {
        setSelectStateId("");
      }
    } else {
      console.log("handleChangeCountry - newValue is null or undefined");
    }
  };

  const handleChangeCountryInput = (event, newValue) => {
    console.log("handleChangeCountryInput", newValue);
    setSelectCountryInput(newValue);
  };

  const handleChangeState = (event) => {
    console.log("handleChangeState", event.target.value);
    setSelectStateId(event.target.value);
  };

  const handleChangeUserFaxNumber = (event) => {
    console.log("handleChangeUserFaxNumber", event.target.value);
    setUserFaxNumber(event.target.value);
  };

  const handleChangeUserGSTNumber = (event) => {
    console.log("handleChangeUserGSTNumber", event.target.value);
    setUserGSTNumber(event.target.value);
  };

  const finalSubmit = () => {
    setLoading(true);
    console.log("finalSubmit");

    if (exUsrType.trim() === "") {
      toast.error(t("please_select_user_type"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    // if (exUsrTitle.trim() === "") {
    //   toast.error(t("please_select_title"), {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setLoading(false);
    //   return;
    // }

    if (userFirsName.trim() === "") {
      toast.error(t("please_enter_first_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (userlastName.trim() === "") {
      toast.error(t("please_enter_last_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (userEmail.trim() === "") {
      toast.error(t("please_enter_email_id"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (!validateEmail(userEmail)) {
      toast.error(t("please_enter_correct_email"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (empUsrPhnCC.trim() === "") {
      toast.error(t("please_select_country_code"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (userPhoneNumber.trim() === "") {
      toast.error(t("please_enter_contact_number"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    // if (userPhoneNumber.length > 10) {
    //   toast.error("Contact Number should be of 10 Digit", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setLoading(false);
    //   return;
    // }

    // if (!phoneRegexPattern.test(userPhoneNumber)) {
    //   toast.error("Please enter a valid Phone Number", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setLoading(false);
    //   return;
    // }

    if (!phoneRegexPattern.test(userPhoneNumber)) {
      toast.error(
        t("please_enter_valid_phone_number_for_country_code", {
          countryCode: empUsrPhnCC,
        }),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setLoading(false);
      return;
    }

    if (userAddress.trim() === "") {
      toast.error(t("please_enter_address"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    const reqDto = {
      title: exUsrTitle,
      firstName: userFirsName,
      middleName: userMiddleName,
      lastName: userlastName,
      officialEmailId: userEmail,
      contactNo: userPhoneNumber,
      category: exUsrType,
      faxNo: userFaxNumber,
      gstinTaxId: userGSTNumber,
      address: userAddress,
      callingCode: empUsrPhnCC,
      countryCode: selectCountry.countryCode,
      stateId: selectStateId,
      unitId: userDetails.signleUnitId,
    };

    console.log("reqDto", reqDto);

    // setLoading(false);
    // return

    createVendorPartner(userDetails.userId, userDetails.orgId, reqDto).then(
      (response) => {
        console.log(response.data);
        if (response.data) {
          // success message
          if (response.data.returnValue === "1") {
            toast.success(t("external_user_added_successfully"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchDashBoardData();
            refreshExternalUserData();
            setLoading(false);
            setExUsrType("");
            setExUsrTitle("");
            setUserFirsName("");
            setUserMiddleName("");
            setUserLastName("");
            setUserEmail("");
            setEmpUsrPhnCC("");
            setUserPhoneNumber("");
            setUserAddress("");
            setUserFaxNumber("");
            setUserGSTNumber("");
          } else {
            toast.error(t("fail_to_add_external_user"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          }
        }
      }
    );
  };

  useEffect(() => {
  
    setBookSteps([
      {
        target: ".addExternalUserStepOne",
        content: t("add_external_user_step_one"),
        disableBeacon: true,
      },
      {
        target: ".addExternalUserStepTwo",
        content: t("add_external_user_step_two"),
        disableBeacon: true,
      },
      {
        target: ".addExternalUserStepThree",
        content: t("add_external_user_step_three"),
        disableBeacon: true,
      },
      {
        target: ".addExternalUserStepFour",
        content: t("add_external_user_step_four"),
        disableBeacon: true,
      },
      {
        target: ".addExternalUserStepFive",
        content: t("add_external_user_step_five"),
        disableBeacon: true,
      },
      {
        target: ".addExternalUserStepSix",
        content: t("add_external_user_step_six"),
        disableBeacon: true,
      },
      {
        target: ".addExternalUserStepSeven",
        content: t("add_external_user_step_seven"),
        disableBeacon: true,
      },
      {
        target: ".addExternalUserStepEight",
        content: t("add_external_user_step_eight"),
        disableBeacon: true,
      },
      {
        target: ".addExternalUserStepNine",
        content: t("add_external_user_step_nine"),
        disableBeacon: true,
      },
      {
        target: ".addExternalUserStepTen",
        content: t("add_external_user_step_ten"),
        disableBeacon: true,
      },
    ]);
  }, []);

  // useEffect(() => {
  //   setBookSteps([
  //     {
  //       target: ".addExternalUserStepOne",
  //       content: "You can add new external user details from Here.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addExternalUserStepTwo",
  //       content: "You can Select User Type from Here.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addExternalUserStepThree",
  //       content: "You can Enter User Name Here.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addExternalUserStepFour",
  //       content: "You can Enter Official Email Id of the User.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addExternalUserStepFive",
  //       content: "You can Enter Phone Number of the User.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addExternalUserStepSix",
  //       content: "You can Enter Full Address of the User.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addExternalUserStepSeven",
  //       content: "You can Select Country of the User.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addExternalUserStepEight",
  //       content: "You can Enter Fax Number Here.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addExternalUserStepNine",
  //       content: "You can Enter GST Number Here.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addExternalUserStepTen",
  //       content:
  //         "Submit All the Data You have Entered For the New External User",
  //       disableBeacon: true,
  //     },
  //   ]);
  // }, []);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["addExternalUserES"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('finish'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="rightFlotingPanel addExternalUserStepOne">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText"> {t("add_external_user")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAddExternalUser()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            {loading ? (
              <div className="meetingVEContainer">
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              </div>
            ) : (
              <div className="rfContContainDetails">
                <div className="elementFormContainer">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="exUserType">
                        {" "}
                        {t("select_user_type")}*
                      </InputLabel>
                      <Select
                        labelId="exUserType"
                        id="exUserType"
                        value={exUsrType}
                        label="Select User Type"
                        onChange={handleChangeExUsrType}
                        className="formInputField addExternalUserStepTwo"
                        variant="outlined"
                      >
                        <MenuItem value="vendor">{t("vendor")}</MenuItem>
                        <MenuItem value="partner">{t("partner")}</MenuItem>
                        <MenuItem value="Client">{t("client")}</MenuItem>
                        <MenuItem value="Adviser">{t("adviser")}</MenuItem>
                        <MenuItem value="BoardMember">
                          {t("board_Member")}
                        </MenuItem>
                        <MenuItem value="Consultant">
                          {t("consultant")}
                        </MenuItem>
                        <MenuItem value="Advisory Board Consultant">
                          {t("advisory_board_consultant")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="formElementGrp addExternalUserStepThree">
                    <div className="formElement mxW-100">
                      <FormControl className="formControl">
                        <InputLabel id="extitle">{t("title")}</InputLabel>
                        <Select
                          labelId="extitle"
                          id="exUserTitle"
                          value={exUsrTitle}
                          label={t("title")}
                          onChange={handleChangeExUsrTitle}
                          className="formInputField"
                          variant="outlined"
                        >
                          <MenuItem value="Mr">{t("mr")}</MenuItem>
                          <MenuItem value="Ms">{t("ms")}</MenuItem>
                          <MenuItem value="Mrs">{t("mrs")}</MenuItem>
                          <MenuItem value="Miss">{t("miss")}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          label={t("first_name")}
                          required
                          placeholder={t("first_name")}
                          variant="outlined"
                          value={userFirsName}
                          className="formTextFieldArea"
                          onChange={handleChangeUserFirsName}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel

                          label={
                            currentSubdomain === "massalud"
                              ? t("first_surname")
                              : t("middle_name")
                          }
                          placeholder={
                            currentSubdomain === "massalud"
                              ? t("first_surname")
                              : t("middle_name")
                          }
                          variant="outlined"
                          value={userMiddleName}
                          className="formTextFieldArea"
                          onChange={handleChangeUserMiddleName}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          required
                          label={
                            currentSubdomain === "massalud"
                              ? t("second_surname")
                              : t("last_name")
                          }
                          placeholder={
                            currentSubdomain === "massalud"
                              ? t("second_surname")
                              : t("last_name")
                          }
                          variant="outlined"
                          value={userlastName}
                          className="formTextFieldArea"
                          onChange={handleChangeUserLastName}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>
                  </div>

                  <div className="formElementGrp">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          label={t("official_email_id")}
                          required
                          placeholder={t("official_email_id")}
                          variant="outlined"
                          value={userEmail}
                          className="formTextFieldArea addExternalUserStepFour"
                          onChange={handleChangeUserEmail}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>

                    <div className="formElement width120">
                      <FormControl className="formControl">
                        <InputLabel id="ccode">{t("calling_code")}*</InputLabel>
                        <Select
                          labelId="ccode"
                          required
                          id="demo-simple-select"
                          value={empUsrPhnCC}
                          label={t("calling_code")}
                          onChange={handleChangeEmpUsrPhnCC}
                          className="formInputField"
                          variant="outlined"
                        >
                          {/* <MenuItem value="91">+91</MenuItem>
                        <MenuItem value="1">+1</MenuItem>
                        <MenuItem value="374">+374</MenuItem> */}
                          {cdCodeList.map((cdCodeList) => (
                            <MenuItem key={cdCodeList} value={cdCodeList}>
                              {cdCodeList}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          required
                          label={t("phone_number")}
                          variant="outlined"
                          className="formTextFieldArea addExternalUserStepFive"
                          value={userPhoneNumber}
                          onChange={handleChangeUserPhoneNumber}
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        // hiddenLabel
                        label={t("full_address")}
                        required
                        variant="outlined"
                        value={userAddress}
                        className="formTextFieldArea addExternalUserStepSix"
                        onChange={handleChangeUserAddress}
                        autoComplete="off"
                      />
                    </FormControl>
                  </div>

                  <div className="formElementGroup addExternalUserStepSeven">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <Autocomplete
                          disablePortal
                          id="selectEmployee"
                          className="formAutocompleteField"
                          variant="outlined"
                          options={CountryList}
                          getOptionLabel={(option) => option.countryName || ""}
                          value={selectCountry}
                          inputValue={selectCountryInput}
                          onInputChange={handleChangeCountryInput}
                          // filterOptions={filterEmplyOptions}
                          onChange={handleChangeCountry}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("select_country")}
                              variant="outlined"
                              className="formAutoComInputField autocompFildPlaceholder"
                            />
                          )}
                        />
                      </FormControl>
                    </div>

                    {selectCountry.countryCode == "356" && (
                      <div className="formElement">
                        <FormControl className="formControl">
                          <InputLabel id="select_state">
                            {t("select_state")}
                          </InputLabel>
                          <Select
                            className="formInputField"
                            variant="outlined"
                            labelId="select_state"
                            id="unitSelectState"
                            value={selectStateId}
                            label={t("select_state")}
                            onChange={handleChangeState}
                          >
                            {selectState.map((NewState) => (
                              <MenuItem key={NewState.id} value={NewState.id}>
                                {NewState.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    )}
                  </div>

                  <div className="formElementGrp">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          label={t("fax_number")}
                          variant="outlined"
                          value={userFaxNumber}
                          className="formTextFieldArea addExternalUserStepEight"
                          onChange={handleChangeUserFaxNumber}
                          inputProps={{ maxLength: 12 }}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          label={t("gst_number")}
                          variant="outlined"
                          value={userGSTNumber}
                          className="formTextFieldArea addExternalUserStepNine"
                          onChange={handleChangeUserGSTNumber}
                          inputProps={{ maxLength: 15 }}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="rfContFooter">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn addExternalUserStepTen"
                onClick={() => finalSubmit()}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <>{t("submit")}</>
                )}
              </Button>
              {!loading && (
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => handelCloseAddExternalUser()}
                >
                  {t("cancel")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
