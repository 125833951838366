import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Title,
  Filler,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import { Bar, Chart, Doughnut, Line, Pie } from "react-chartjs-2";
import { Button, IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import {
  getDashboardDataOfItem,
  refreshHoliday,
} from "../../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { ArrowRepeat } from "react-bootstrap-icons";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../../common/TourProvider";

export default function CardHolidayWorkingday({
  handelOpenViewHoliday,
  handelOpenAddHoliday,
  chartDataOfItem,
  menuModuleList,
  userMenuList,
}) {
  const userDetails = GetLoginUserDetails();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const [chartDataOfHoliday, setchartDataOfHoliday] = useState([]);
  console.log("menuModuleList", menuModuleList);
  const { t } = useTranslation();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  // const userDetails = jwtDecode( SecureIndexedDB.getItem("token"));

  // const [chartDataOfHoliday, setchartDataOfHoliday] = useState([]);

  // useEffect(() => {
  //   getDashboardDataOfItem(userDetails.orgId, userDetails.userId).then(
  //     (response) => {
  //       console.log("getDashboardDataOfItem", response.data);
  //       setchartDataOfHoliday(response.data);
  //     }
  //   );
  // }, [userDetails.orgId, userDetails.userId]);

  ChartJS.register(
    ArcElement,
    Tooltip,
    Title,
    Filler,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );

  useEffect(() => {
    setchartDataOfHoliday(chartDataOfItem || {});
  }, [chartDataOfItem]);

  useEffect(() => {
    if (!isFirstLoad){
    fetchEmplyCardData();
    }
  }, []);

  const fetchEmplyCardData = () => {
    const reqDto = {
      unitId: userDetails.signleUnitId,
      // orgId: userDetails.orgId,
      userId: userDetails.userId,
      periodDailyMnthly: "",
      dataLimit: 0,
      feedbackFromPatientDoctor: "",
      fromDate: "",
      toDate: "",
      unitDoctor: "",
      holidayOrgId: userDetails.orgId,
    };
    refreshHoliday(reqDto).then((response) => {
      console.log("response", response.data);
      setchartDataOfHoliday(response.data);
    });
  };

  const data = {
    labels: ["Holiday", "Working Day"],
    datasets: [
      {
        data: [30, 70],
        backgroundColor: ["#8c7ae6", "#aaa"],
        borderWidth: 1,
        borderColor: ["#292929", "#292929"],
      },
    ],
  };

  const options = {
    cutout: "40%",

    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  // curent People status bar and line chart combo start

  const optionsLeave = {
    responsive: true,
    borderRadius: 4,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 12,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
      title: {
        display: false,
        text: "FYQ 2nd",
      },
    },
  };

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const mnthWiseHolidayCountData = chartDataOfHoliday.mnthWiseHolidayCount || [];
  console.log("mnthWiseHolidayCountData", mnthWiseHolidayCountData);

  const dataLeave = {
    labels: mnthWiseHolidayCountData.map((item) => item.label.trim()),
    datasets: [
      {
        label: t("holiday_count"),
        data: mnthWiseHolidayCountData.map((item) => item.count),
        backgroundColor: "rgba(243, 153, 18, 0.8)",
      },
    ],
  };

  // const dataLeave = {
  //   labels,
  //   datasets: [
  //     {
  //       fill: false,
  //       label: "Expense",
  //       data: [1, 2, 3, 4, 2, 1, 2, 3, 1, 2, 4, 5],
  //       borderColor: "rgb(255, 255, 255)",
  //       backgroundColor: "rgba(255, 241, 195, 0.5)",
  //     },
  //     {
  //       label: "Working",
  //       data: [20, 21, 24, 24, 30, 30, 30, 30, 30, 25, 24, 21],
  //       backgroundColor: "rgba(245, 66, 161, 0.8)",
  //       type: "bar",
  //       barThickness: 6,
  //     },
  //     {
  //       label: "Holiday",
  //       data: [2, 3, 1, 4, 2, 1, 1, 3, 4, 2, 5, 2],
  //       backgroundColor: "rgba(243, 153, 18, 0.8)",
  //       type: "bar",
  //       barThickness: 6,
  //     },
  //   ],
  // };
  // curent People status bar and line chart combo end

  // useEffect(()=>{
  // setBookSteps([
  //   {
  //     target: ".cardHolidayStepOne",
  //     content: "This section provides an overview of holidays and working days",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".cardHolidayStepTwo",
  //     content: "Click the refresh button to fetch the latest data.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".cardHolidayStepThree",
  //     content: "This graph visualizes monthly holiday data. Hover over the bars for detailed insights.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".cardHolidayStepFour",
  //     content: "The number of holidays for the selected period is displayed here.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".cardHolidayStepFive",
  //     content: "Use these buttons to view holidays as per your permissions.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".cardHolidayStepSix",
  //     content: "Use these buttons to add holidays as per your permissions.",
  //     disableBeacon: true,
  //   }
  // ]
  // )
  // },[])

  useEffect(() => {
  
    setBookSteps([
      {
        target: ".cardHolidayStepOne",
        content: t("card_holiday_step_one"),
        disableBeacon: true,
      },
      {
        target: ".cardHolidayStepTwo",
        content: t("card_holiday_step_two"),
        disableBeacon: true,
      },
      {
        target: ".cardHolidayStepThree",
        content: t("card_holiday_step_three"),
        disableBeacon: true,
      },
      {
        target: ".cardHolidayStepFour",
        content: t("card_holiday_step_four"),
        disableBeacon: true,
      },
      {
        target: ".cardHolidayStepFive",
        content: t("card_holiday_step_five"),
        disableBeacon: true,
      },
      {
        target: ".cardHolidayStepSix",
        content: t("card_holiday_step_six"),
        disableBeacon: true,
      },
    ]);
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["cardHolidayWorkingDaysDS"] === true}
        continuous={true}
        showSkipButton={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        styles={{
          overlay: {
            height: "100vh",
          },
        }}
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
      />
      <div className="anDashIndCard cardHolidayStepOne">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">
              {t("holiday_working_day")}
              <IconButton
                className={`refreshIcon cardHolidayStepTwo`}
                onClick={()=>{setIsFirstLoad(false); fetchEmplyCardData();}}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount">
              {/* <span title="Attrition">
                <Male />
                Avl Stock: <span className="fbold">42%</span>
              </span> */}

              <span title="Tenure cardHolidayStepFour">
                {/* <Female /> */}
                {t("no_of_holiday")} :{" "}
                <span className="fbold">{chartDataOfHoliday.holiday}</span>
              </span>
            </div>
          </div>

          <div className="andCrdGraf cardHolidayStepThree">
            {/* <div className="anDLftGrf">
              <div className="centTxtShow">
                <Pie
                  className="hw100"
                  type="doughnut"
                  data={data}
                  options={options}
                />
                <div className="highlighted-text"></div>
              </div>
            </div> */}
            <div className="anDRightGrf oneGraph100">
              {/* <Line
                className="hw100"
                type="line"
                data={dataLeave}
                options={optionsLeave}
              /> */}
              <Bar
                className="hw100"
                type="bar"
                data={dataLeave}
                options={optionsLeave}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>
          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find(
                  (item) => item.menuName === "Holiday / Working Day View"
                ).menuId
              ) && (
                <Button
                  className="dfultPrimaryBtn cardHolidayStepFive"
                  onClick={() => {handelOpenViewHoliday();
                    if (run["cardHolidayWorkingDaysDS"] === true) {
                      setRun({
                        ViewHolidayworkingDays: true,
                      });
                    }
                  }}
                >
                  {t("view")}
                </Button>
              )}

            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find(
                  (item) => item.menuName === "Holiday / Working Day Add"
                ).menuId
              ) && (
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn cardHolidayStepSix"
                  onClick={() => {handelOpenAddHoliday();
                    if (run["cardHolidayWorkingDaysDS"] === true) {
                      setRun({
                        addHolidayworkingDays: true,
                      });
                    }
                  }}
                >
                  {t("add")}
                </Button>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
