import {
  Add,
  ArrowBack,
  BrowseGallery,
  CalendarMonth,
  Edit,
  Search,
  Settings,
  Visibility,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Eye, Trash } from "react-bootstrap-icons";
import { jwtDecode } from "jwt-decode";
import {
  getHolidaysList,
  deleteHoliday,
} from "../../../../services/AdminService";
import { toast } from "react-toastify";
import AppUtils from "../../../../utils/AppUtils";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";

export default function ViewHolidayWorkingday({
  handelCloseViewHoliday,
  handelOpenAddHoliday,
  handelOpenAddWorkingday,
  refreshHoliday,
  handelOpenEditHoliday,
  handelOpenSelectedviewHoliday,
  handleOpenAddWorkingShift,
  closeAll,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [loading, setLoading] = useState(true);

  const [holidaysData, setHolidaysData] = useState([]);

  const [filterHolidaysData, setFilterHolidaysData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const [openModal, setOpenModal] = useState(false);

  const [holidayToDelete, setHolidayToDelete] = useState(null);

  useEffect(() => {
    fetchHolidayData();
  }, [refreshHoliday]);

  const fetchHolidayData = () => {
    getHolidaysList(userDetails.orgId).then(async (response) => {
      console.log("getHolidayList", response.data);
      const sortedData = response.data.sort((a, b) => {
        const dateA = a.holidayDate.split("-").reverse().join("-");
        const dateB = b.holidayDate.split("-").reverse().join("-");
        return dateA.localeCompare(dateB);
      });
      setHolidaysData(sortedData);
      setFilterHolidaysData(sortedData);
      setLoading(false);
    });
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = holidaysData.filter(
      (holiday) =>
        holiday.holidayName &&
        holiday.holidayName.toLowerCase().includes(query.toLowerCase())
    );

    setFilterHolidaysData(filteredData);
  };

  const handleDelete = (holidayDate) => {
    console.log("handleDelete", holidayDate);
    setHolidayToDelete(holidayDate);
    setOpenModal(true);
  };

  const confirmDelete = () => {
    setOpenModal(false);
    if (holidayToDelete) {
      deleteHoliday(
        userDetails.orgId,
        userDetails.userId,
        holidayToDelete
      ).then((response) => {
        console.log("data deleted", response.data);
        if (response.data.returnValue === "1") {
          toast.success(t("holiday_deleted_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchHolidayData();
          // setOpenModal(false);
        } else {
          toast.error(t("fail_to_delete_holiday"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          // setOpenModal(false);
        }
      });
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    setBookSteps([
      {
        target: ".viewHolidaysStepOne",
        content: t("view_holidays_step_one"),
        disableBeacon: true,
      },
      {
        target: ".viewHolidaysStepTwo",
        content: t("view_holidays_step_two"),
        disableBeacon: true,
      },
      {
        target: ".viewHolidaysStepThree",
        content: t("view_holidays_step_three"),
        disableBeacon: true,
      },
      {
        target: ".viewHolidaysStepFour",
        content: t("view_holidays_step_four"),
        disableBeacon: true,
      },
      {
        target: ".viewHolidaysStepFive",
        content: t("view_holidays_step_five"),
        disableBeacon: true,
      },
      {
        target: ".viewHolidaysStepSix",
        content: t("view_holidays_step_six"),
        disableBeacon: true,
      },
      {
        target: ".viewHolidaysStepSeven",
        content: t("view_holidays_step_seven"),
        disableBeacon: true,
      },
      {
        target: ".viewHolidaysStepEight",
        content: t("view_holidays_step_eight"),
        disableBeacon: true,
      },
    ]);
  }, [t]);

  // useEffect(() => {
  //   setBookSteps([
  //     {
  //       target: ".viewHolidaysStepOne",
  //       content: "Here You can view All the Holiday of The Organization",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewHolidaysStepTwo",
  //       content: "Click here to go back to the previous page.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewHolidaysStepThree",
  //       content: "Use the search bar to quickly find specific holidays.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewHolidaysStepFour",
  //       content: "Create new shifts using this buttons.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewHolidaysStepFive",
  //       content: "Create working days using this buttons.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewHolidaysStepSix",
  //       content: "Create new holidays using this buttons.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewHolidaysStepSeven",
  //       content: "You Can View all holidays in this table.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewHolidaysStepEight",
  //       content: "You can View, edit, or delete holidays from this table.",
  //       disableBeacon: true,
  //     },
  //   ]);
  // }, []);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["ViewHolidayworkingDays"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="AnalyticsDashboardContainer viewHolidaysStepOne">
        <div className="AnalyticsDashboardSubHeader viewHolidaysStepTwo">
          <IconButton
            onClick={() => {
              handelCloseViewHoliday();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("holiday_working_day")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              {/* <div className="cuhederTxt">Departmment List</div> */}
              <div className="searchTasklist viewHolidaysStepThree">
                <input
                  type="text"
                  class="serchTaskInput"
                  placeholder={t("search_holiday")}
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              <div className="addUnitsBtn">
                <Button
                  startIcon={<BrowseGallery />}
                  className="dfultPrimaryBtn viewHolidaysStepFour"
                  onClick={() => handleOpenAddWorkingShift()}
                >
                  {t("shift")}
                </Button>
                <Button
                  startIcon={<CalendarMonth />}
                  className="dfultPrimaryBtn viewHolidaysStepFive"
                  onClick={() => handelOpenAddWorkingday()}
                >
                  {t("working_days")}
                </Button>
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn viewHolidaysStepSix"
                  onClick={() => handelOpenAddHoliday()}
                >
                  {t("holiday")}
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : holidaysData.length === 0 ? (
                <div class="noDataCard ">
                  <span>{t("oops")}</span>
                  {t("no_holiday_define_yet")}
                  <br />
                  <span className="sallMsg">
                    {t("click_add_holiday_button_to_create_new_holiday")}
                  </span>
                </div>
              ) : (
                <div className="TableContainer">
                  <table className="taskListTable viewHolidaysStepSeven">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th className="width100">{t("sl_no")}</th>
                        <th>{t("holiday_name")}</th>
                        <th>{t("date")}</th>
                        <th>{t("description")}</th>
                        <th className="width100"></th>
                      </tr>
                    </thead>
                    <tbody className="scrolableTableBody">
                      {filterHolidaysData.map((holiday, index) => (
                        <tr key={index}>
                          <td>
                            <div className="tskLstTaskNM">{index + 1}.</div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {holiday.holidayName}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {holiday.holidayDate}
                            </div>
                          </td>
                          <td>
                            <Tooltip title={holiday.holidayEmailContent} arrow>
                              <div className="tskLstTaskDescription">
                                {holiday.holidayEmailContent}
                              </div>
                            </Tooltip>
                          </td>
                          <td>
                            <div className="tblActionBtnGrp viewHolidaysStepEight">
                              <IconButton
                                onClick={() =>
                                  handelOpenSelectedviewHoliday(holiday)
                                }
                              >
                                <Eye />
                              </IconButton>
                              <IconButton
                                onClick={() => handelOpenEditHoliday(holiday)}
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                className="removeRowBtn"
                                onClick={() =>
                                  handleDelete(holiday.holidayDate)
                                }
                              >
                                <Trash />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>

                    {/* <tbody className="scrolableTableBody">
                    <tr>
                      <td>
                        <div className="tskLstTaskNM">1.</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">INDEPENDENCE DAY</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">15-08-2024</div>
                      </td>
                      <td>
                        <Tooltip
                          title="Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lore"
                          arrow
                        >
                          <div className="tskLstTaskDescription">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged. It was popularised in the 1960s with the
                            release of Letraset sheets containing Lore
                          </div>
                        </Tooltip>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <IconButton>
                            <Eye />
                          </IconButton>
                          <IconButton>
                            <Edit />
                          </IconButton>
                          <IconButton className="removeRowBtn">
                            <Trash />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody> */}
                  </table>
                </div>
              )}

              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    // width: "250px",
                    // height: "250px",
                    maxWidth: "600px",
                    minHeight: "150px",
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <h2 id="modal-modal-title">
                    {t("do_you_want_to_delete_this_holiday")}
                  </h2>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      // variant="contained"
                      // color="secondary"
                      className="dfultPrimaryBtn"
                      onClick={confirmDelete}
                    >
                      {t("yes")}
                    </Button>
                    <Button
                      //  variant="contained"
                      className="dfultDarkSecondaryBtn"
                      onClick={handleCloseModal}
                    >
                      {t("no")}
                    </Button>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
