import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
  RadialLinearScale,
  PolarAreaController,
} from "chart.js";
import React from "react";

import { Add } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { Bar, Pie, PolarArea } from "react-chartjs-2";
import { ArrowRepeat } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../../common/TourProvider";
import { useEffect } from "react";
import { useState } from "react";
import { refreshHoliday, refreshPatientDocument } from "../../../../../services/AdminService";
import { GetLoginUserDetails } from "../../../../../utils/Utils";

export default function CardPatientDocument({
  handelOpenViewPatientDocument,
  chartDataOfItem,
  // fetchDashBoardData,
}) {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController,
    RadialLinearScale,
    PolarAreaController
  );

  const { t } = useTranslation();
  const userDetails = GetLoginUserDetails();

  const [patientDocumentData , setPatientDocumentData] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    setPatientDocumentData(chartDataOfItem || {});
  }, [chartDataOfItem]);

  useEffect(() => {
    if (!isFirstLoad){
      fetchPatientDocCardData();
    }
  }, []);

  const fetchPatientDocCardData = () => {
    const reqDto = {
      documentUnitId: userDetails.signleUnitId,
      // orgId: userDetails.orgId,
      // userId: userDetails.userId,
      periodDailyMnthly: "",
      // dataLimit: 0,
      feedbackFromPatientDoctor: "",
      fromDate: "",
      toDate: "",
      unitDoctor: "",
      // holidayOrgId: userDetails.orgId,
    };
    refreshPatientDocument(reqDto).then((response) => {
      console.log("response", response.data);
      setPatientDocumentData(response.data);
    });
  };


  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  useEffect(() => {
    setBookSteps([
      {
        target: ".carddocOne",
        content: t("this_section_displays_key_metrics_for_patient_documents"),
        disableBeacon: true,
      },
      {
        target: ".carddocTwo",
        content: t("this_section_displays_key_metrics_for_patient_documents"),
        disableBeacon: true,
      },
      {
        target: ".carddocThree",
        content: t("click_here_to_refresh_patient_dashboard"),
        disableBeacon: true,
      },
      {
        target: ".carddocFour",
        content: t("this_chart_shows_distribution_of_patient_data"),
        disableBeacon: true,
      },
      {
        target: ".carddocFive",
        content: t("this_bar_chart_shows_diagnostic_statistics"),
        disableBeacon: true,
      },
      {
        target: ".carddocSix",
        content: t("click_here_to_view_detailed_patient_documents"),
        disableBeacon: true,
      },
    ]);
  }, [t]);

  // useEffect(() => {
  //   setBookSteps([
  //     {
  //       target: ".carddocOne",
  //       content:
  //         "This section displays key metrics and visualizations for patient documents.",
  //       // placement: "bottom",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".carddocTwo",
  //       content:
  //         "This section displays key metrics and visualizations for patient documents.",
  //       // placement: "bottom",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".carddocThree",
  //       content: "Click here to refresh the patient document dashboard.",
  //       // placement: "right",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".carddocFour",
  //       content:
  //         "This chart shows the distribution of patient data across different categories. Hover over a segment to view details.",
  //       // placement: "top",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".carddocFive",
  //       content:
  //         "This bar chart provides insights into diagnostic statistics. The x-axis shows categories, and the y-axis represents counts.",
  //       // placement: "top",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".carddocSix",
  //       content: "Click here to view detailed patient documents.",
  //       // placement: "bottom",
  //       disableBeacon: true,
  //     },
  //   ]);
  // }, []);

  // const polarChartlabel = patientDocumentData?.todayUnitRxMedInvRefCount ? Object.keys(patientDocumentData.todayUnitRxMedInvRefCount) : [];

  // const polartChartData = patientDocumentData?.todayUnitRxMedInvRefCount ? Object.values(patientDocumentData.todayUnitRxMedInvRefCount) : [];

  // const data = {
  //   // labels: ["Completed", "Queue", "New", "Review"],
  //   labels : polarChartlabel ,
  //   datasets: [
  //     {
  //       label: "",
  //       // data: [12, 19, 3, 5],
  //       data : polartChartData ,
  //       backgroundColor: [
  //         "rgba(79, 212, 0, 1)",
  //         "rgba(234, 184, 0, 1)",
  //         "rgba(241, 61, 60, 1)",
  //         "rgba(254, 162, 59, 1)",
  //       ],
  //       borderColor: [
  //         "rgba(255, 255, 255, .6)",
  //         "rgba(255, 255, 255, .6)",
  //         "rgba(255, 255, 255, .6)",
  //         "rgba(255, 255, 255, .6)",
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  const { todayUnitRxMedInvRefCount } = patientDocumentData || {};

  const noOfPrescription = todayUnitRxMedInvRefCount?.noOfPrescription || 0;

  const labels = [
    todayUnitRxMedInvRefCount?.noMedicine === 0 ? "" : t("no_medicine"),
    todayUnitRxMedInvRefCount?.overmedication === 0 ? "" : t("overmedication"),
    todayUnitRxMedInvRefCount?.test === 0 ? "" : t("test"),
    todayUnitRxMedInvRefCount?.refer === 0 ? "" : t("refer"),
  ];

  const dataValues = [
    todayUnitRxMedInvRefCount?.noMedicine === 0
      ? noOfPrescription
      : todayUnitRxMedInvRefCount?.noMedicine,
    todayUnitRxMedInvRefCount?.overmedication === 0
      ? noOfPrescription
      : todayUnitRxMedInvRefCount?.overmedication,
    todayUnitRxMedInvRefCount?.test === 0
      ? noOfPrescription
      : todayUnitRxMedInvRefCount?.test,
    todayUnitRxMedInvRefCount?.refer === 0
      ? noOfPrescription
      : todayUnitRxMedInvRefCount?.refer,
  ];

  const backgroundColors = [
    todayUnitRxMedInvRefCount?.noMedicine === 0
      ? "rgb(84, 84, 84, 1)"
      : "rgba(79, 212, 0, 1)",
    todayUnitRxMedInvRefCount?.overmedication === 0
      ? "rgb(84, 84, 84 , 1)"
      : "rgba(241, 61, 60, 1)",
    todayUnitRxMedInvRefCount?.test === 0
      ? "rgb(84, 84, 84, 1)"
      : "rgba(254, 162, 59, 1)",
    todayUnitRxMedInvRefCount?.refer === 0
      ? "rgb(84, 84, 84, 1)"
      : "rgba(234, 184, 0, 1)",
  ];

  const data = todayUnitRxMedInvRefCount
    ? {
        labels,
        datasets: [
          {
            label: "",
            data: dataValues,
            backgroundColor: backgroundColors,
            borderColor: "rgba(255, 255, 255, .6)",
            borderWidth: 1,
          },
        ],
      }
    : { labels: [], datasets: [] };

  const options = {
    responsive: true,
    scales: {
      r: {
        ticks: {
          display: false,
        },
        max: todayUnitRxMedInvRefCount?.noOfPrescription || 100,
        min: 0,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
  };

  // const options = {
  //   cutout: "80%",
  //   responsive: true,
  //   scales: {
  //     r: {
  //       ticks: {
  //         display: false,
  //         stepSize: 10,
  //         max: 100,
  //         min: 0,
  //       },
  //       // grid: {
  //       //   color: "rgba(255, 255, 255, 0.2)",
  //       //   lineWidth: 1,
  //       // },
  //       // angleLines: {
  //       //   color: '#292929',
  //       //   lineWidth: 1,
  //       // },
  //     },
  //   },
  //   plugins: {
  //     datalabels: {
  //       display: false,
  //     },
  //     legend: {
  //       display: false,
  //       position: "bottom",
  //       labels: {
  //         boxWidth: 6,
  //         color: "#d2d2d2",
  //         font: {
  //           size: 12,
  //           weight: "light",
  //         },
  //       },
  //     },
  //   },
  // };

  // const options = {
  //   cutout: "80%",
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       display: false,
  //       position: "bottom",
  //       labels: {
  //         boxWidth: 6,
  //         color: "#d2d2d2",
  //         font: {
  //           size: 12,
  //           weight: "light",
  //         },
  //       },
  //     },
  //   },
  // };

  const barLineData = {
    labels:
      patientDocumentData?.todayUnitDiagonistic?.length > 0
        ? patientDocumentData.todayUnitDiagonistic.map((item) => item.label)
        : [],
    datasets: [
      {
        label: "",
        data:
          patientDocumentData?.todayUnitDiagonistic?.length > 0
            ? patientDocumentData.todayUnitDiagonistic.map((item) => item.count)
            : [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const barLineOoptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["patientdocJr"] === true}
        continuous={true}
        showSkipButton={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        styles={{
          overlay: {
            height: "100vh",
          },
        }}
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
      />
      <div className="anDashIndCard carddocOne">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM carddocTwo">
              {t("patient_documents")}
              <IconButton
                className={`refreshIcon carddocThree`}
                onClick={()=>{setIsFirstLoad(false); fetchPatientDocCardData()}}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount">
              {/* <span title="Attrition"> */}
              {/* <Male /> */}
              {/* This Month: <span className="fbold"> 215421</span> */}
              {/* </span> */}

              {/* <span title="Tenure"> */}
              {/* <Female /> */}
              {/* This week: <span className="fbold">12154</span> */}
              {/* </span> */}
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf carddocFour">
              <div className="centTxtShow">
                {/* <Pie
                  className="hw100"
                  type="doughnut"
                  data={data}
                  options={options}
                /> */}
                <PolarArea
                  className="hw100"
                  type="polarArea"
                  data={data}
                  options={options}
                />
                <div className="highlighted-text">
                  {/* <span>Total</span> */}
                  {/* <span className="highDataCount">1602</span> */}
                </div>
              </div>
            </div>
            <div className="anDRightGrf carddocFive">
              <Bar
                className="hw100"
                type="bar"
                data={barLineData}
                options={barLineOoptions}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>

          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            <Button
              className="dfultPrimaryBtn carddocSix"
              onClick={() => {
                handelOpenViewPatientDocument();
                if (run["patientdocJr"] === true) {
                  setRun({
                    viewPatDocns: true,
                  });
                }
              }}
            >
              {t("view")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
