import React, { useContext, useEffect, useState } from "react";
import { AddTabContext } from "./DashboardPage";
import { GetLoginUserDetails } from "../../../utils/Utils";
import AppUtils from "../../../utils/AppUtils";
import { v4 as uuidv4 } from "uuid";
import TaskTabContainer from "../../task/view/TaskTabContainer";
import { ListTask } from "react-bootstrap-icons";
import EmailContainerWrapper from "../../email/view/EmailContainerWrapper";
import {
  AdminPanelSettings,
  CalendarMonth,
  ChatBubbleOutline,
  EmailOutlined,
  Event,
  Help,
  PlaylistAddOutlined,
  StorageOutlined,
  Support,
  VideocamOutlined,
} from "@mui/icons-material";
import ChatContainer from "../../chat/view/ChatContainer";
import SchedulerContainer from "../../scheduler/view/SchedulerContainer";
import { ConfirmProvider } from "material-ui-confirm";
import MeetingsTabContainer from "../../scheduler/view/component/meetings/view/MeetingsTabContainer";
import DriveContainer from "../../drive/view/DriveContainer";
import CreateTaskContainer from "../../task/view/CreateTaskContainer";
import Admin from "../../admin/view/Admin";
import { VendorMenu } from "../../vendor/VendorMenu";
import SupportTicketView from "../../support/view/SupportTicketView";
import { useTranslation } from "react-i18next";
import { webApp } from "../../../constants/url_provider";
import Joyride from "react-joyride";
import { TourContext } from "../../../common/TourProvider";
// import "./styles.css";

function NewTabContent({ tabIndex }) {
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const { t } = useTranslation();
  const { addTab, getAllTabs } = useContext(AddTabContext);
  const loginUser = GetLoginUserDetails();
  const loginUserDetail = GetLoginUserDetails();

  const [stepForTaskApp, setStepForTaskApp] = useState([]);
  const [stepForAdminApp, setStepForAdminApp] = useState([]);
  const [stepForChatApp, setStepForChatApp] = useState([]);
  const [stepForSchedulerApp, setStepForSchedulerApp] = useState([]);
  const [stepForDriveApp, setStepForDriveApp] = useState([]);
  const [stepForSupportTicketApp, setStepForSupportTicketApp] = useState([]);
  const [stepForMeetingRoomApp, setStepForMeetingRoomApp] = useState([]);

  useEffect(() => {
    setStepForTaskApp([
      {
        target: "#tabConTentTaskStepOne",
        content: t("task_app_instructions"),
        disableBeacon: true,
      },
    ]);

    setStepForAdminApp([
      {
        target: "#tabConTentAdminStepOne",
        content: t("admin_app_instructions"),
        disableBeacon: true,
      },
    ]);

    setStepForChatApp([
      {
        target: "#tabConTentChatStepOne",
        content: t("chat_app_instructions"),
        disableBeacon: true,
      },
    ]);

    setStepForSchedulerApp([
      {
        target: "#tabConTentSChedulerStepOne",
        content: t("scheduler_app_instructions"),
        disableBeacon: true,
      },
    ]);

    setStepForDriveApp([
      {
        target: "#tabConTentDriveStepOne",
        content: t("drive_app_instructions"),
        disableBeacon: true,
      },
    ]);

    setStepForSupportTicketApp([
      {
        target: "#tabConTentSupportTicketStepOne",
        content:  t("support_ticket_app_instructions"),
        disableBeacon: true,
      },
    ]);

    setStepForMeetingRoomApp([
      {
        target: "#tabConTentMeetingRoomStepOne",
        content: t("meeting_room_app_instructions"),
        disableBeacon: true,
      },
    ]);
  }, []);

  console.log("Support New Tab", run["NewTabTaskDc"], run);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={
          run["NewTabTaskDc"]
            ? stepForTaskApp
            : run["NewTabAdminDc"]
            ? stepForAdminApp
            : run["NewTabChatDc"]
            ? stepForChatApp
            : run["NewTabSchedulerDc"]
            ? stepForSchedulerApp
            : run["NewTabDriveDc"]
            ? stepForDriveApp
            : run["NewTabSupportTicketDc"]
            ? stepForSupportTicketApp
            : run["NewTabMeetingRoomDc"]
            ? stepForMeetingRoomApp
            : []
        }
        run={
          run["NewTabTaskDc"] === true ||
          run["NewTabAdminDc"] === true ||
          run["NewTabChatDc"] === true ||
          run["NewTabSchedulerDc"] === true ||
          run["NewTabDriveDc"] === true ||
          run["NewTabSupportTicketDc"] === true ||
          run["NewTabMeetingRoomDc"] === true
        }
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        callback={handleJoyrideCallback}
        disableOverlayClose
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },  
        // }}
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
      />
      <div className="bottomMnuPopover">
        <div class="bottomMnuPopoverContainer tabConTentStepOne">
          <div class="apps">
            <h2>{t("Apps")}</h2>
            <ul>
              {!AppUtils.isPersonal(loginUser) && (
                <li
                  id="tabConTentTaskStepOne"
                  onClick={() => {
                    addTab({
                      key: uuidv4(),
                      title: "Task",
                      content: <TaskTabContainer />,
                      isDraggable: true,
                      // //type: "New",
                      tabIndex: getAllTabs().length,
                    });
                    if (run["NewTabTaskDc"] === true) {
                      setRun({
                        TaskDetailsAppAs: true,
                      });
                    }
                  }}
                >
                  <ListTask className="btmMenuAppsIcon" /> {t("task")}
                </li>
              )}
              {/* <li
                onClick={() => {
                  addTab({
                    key: uuidv4(),
                    title: "Email",
                    content: <EmailContainerWrapper />,
                    isDraggable: true,
                    //type: "New",
                    tabIndex: getAllTabs().length,
                  });
                }}
              >
                <EmailOutlined className="btmMenuAppsIcon" /> Email
              </li> */}
              <li
                id="tabConTentChatStepOne"
                onClick={() => {
                  addTab({
                    key: uuidv4(),
                    title: "Chat",
                    content: <ChatContainer />,
                    isDraggable: true,
                    //type: "New",
                    tabIndex: getAllTabs().length,
                  });
                  if (run["NewTabChatDc"] === true) {
                    setRun({
                      ChatDetailsAppCT: true,
                    });
                  }
                }}
              >
                <ChatBubbleOutline className="btmMenuAppsIcon" /> {t("Chat")}
              </li>

              <li
                id="tabConTentSChedulerStepOne"
                onClick={() => {
                  addTab({
                    key: uuidv4(),
                    title: "Scheduler",
                    content: <SchedulerContainer />,
                    isDraggable: true,
                    //type: "New",
                    tabIndex: getAllTabs().length,
                  });
                  if (run["NewTabSchedulerDc"] === true) {
                    setRun({
                      SchedulerDetailsAppSC: true,
                    });
                  }
                }}
              >
                <CalendarMonth className="btmMenuAppsIcon" /> {t("scheduler")}
              </li>
              <li
               id="tabConTentMeetingRoomStepOne"
                onClick={() => {
                  addTab({
                    key: uuidv4(),
                    title: "Meeting Room",
                    content: (
                      <ConfirmProvider>
                        <MeetingsTabContainer />
                      </ConfirmProvider>
                    ),
                    isDraggable: true,
                    //type: "New",
                    tabIndex: getAllTabs().length,
                  });
                  if (run["NewTabMeetingRoomDc"] === true) {
                    setRun({
                      MeetingRoomDetailsAppAs: true,
                    });
                  }
                }}
              >
                <VideocamOutlined className="btmMenuAppsIcon" />{" "}
                {t("meeting_room")}
              </li>
              <li
                 id="tabConTentDriveStepOne"
                  onClick={() => {
                  const tabkey = uuidv4();
                  console.log("key", tabkey);
                  addTab({
                    key: tabkey,
                    title: "Drive",
                    content: <DriveContainer addTab={addTab} tabkey={tabkey} />,
                    isDraggable: true,
                    //type: "New",
                    tabIndex: getAllTabs().length,
                    driveKey: tabkey,
                  });
                  if (run["NewTabDriveDc"] === true) {
                    setRun({
                      DriveDetailsAppDV: true,
                    });
                  }
                }}
              >
                <StorageOutlined className="btmMenuAppsIcon" /> {t("drive")}
              </li>

              {!AppUtils.isPersonal(loginUser) && "zoyel.one" !== webApp && (
                <li
                  id="tabConTentSupportTicketStepOne"
                  onClick={() => {
                    const tabkey = uuidv4();
                    console.log("key", tabkey);
                    addTab({
                      key: tabkey,
                      title: t("support_dashboard"),
                      content: <SupportTicketView />,
                      isDraggable: true,
                      tabIndex: getAllTabs().length,
                      driveKey: tabkey,
                    });
                    if (run["NewTabSupportTicketDc"] === true) {
                      setRun({
                        supportDashDetailsAppSDV: true,
                      });
                    }
                  }}
                >
                  <Help className="btmMenuAppsIcon" /> {t("support_dashboard")}
                </li>
              )}

              {loginUserDetail.userType === "ADMIN" &&
                !AppUtils.isPersonal(loginUser) && (
                  <li
                    id="tabConTentAdminStepOne"
                    onClick={() => {
                      const tabkey = uuidv4();
                      console.log("key", tabkey);
                      addTab({
                        key: tabkey,
                        title: "Admin",
                        content: <Admin />,
                        isDraggable: true,
                        //type: "New",
                        tabIndex: getAllTabs().length,
                        driveKey: tabkey,
                      });
                      if (run["NewTabAdminDc"] === true) {
                        setRun({
                          AdminDetailsAppAs: true,
                        });
                      }
                    }}
                  >
                    <AdminPanelSettings className="btmMenuAppsIcon" />{" "}
                    {t("Admin")}
                  </li>
                )}

              {loginUserDetail.userType === "EXTERNAL" &&
                loginUserDetail.externalCategory === "vendor" && (
                  <li
                    onClick={() => {
                      const tabkey = uuidv4();
                      console.log("key", tabkey);
                      addTab({
                        key: tabkey,
                        title: t("vendor_menu"),
                        content: <VendorMenu />,
                        isDraggable: true,
                        //type: "New",
                        tabIndex: getAllTabs().length,
                        driveKey: tabkey,
                      });
                    }}
                  >
                    <StorageOutlined className="btmMenuAppsIcon" />{" "}
                    {t("vendor_menu")}
                  </li>
                )}
            </ul>
          </div>
          <div class="quick-actions">
            <h2>{t("quick_actions")}</h2>
            <ul>
              {!AppUtils.isPersonal(loginUser) && (
                <li
                  onClick={() => {
                    addTab({
                      key: uuidv4(),
                      title: t("create_task"),
                      content: <CreateTaskContainer />,
                      isDraggable: true,
                      //type: "New",
                      tabIndex: getAllTabs().length,
                    });
                  }}
                >
                  <PlaylistAddOutlined className="btmMenuAppsIcon" />
                  {t("create_a_new_task")}
                </li>
              )}
              <li
                onClick={() => {
                  addTab({
                    key: uuidv4(),
                    title: "Scheduler",
                    content: (
                      <SchedulerContainer
                        bottomShortcutDto={{ eventType: "Event" }}
                      />
                    ),
                    isDraggable: true,
                    //type: "New",
                    tabIndex: getAllTabs().length,
                  });
                }}
              >
                <Event className="btmMenuAppsIcon" /> {t("create_a_new_event")}
              </li>
              <li
                onClick={() => {
                  const key = uuidv4();
                  addTab({
                    key: key,
                    title: t("instant_meeting"),
                    content: (
                      <ConfirmProvider>
                        <MeetingsTabContainer
                          startInstantMeeting={true}
                          tabKey={key}
                        />
                      </ConfirmProvider>
                    ),
                    isDraggable: true,
                    //type: "New",
                    tabIndex: getAllTabs().length,
                  });
                }}
              >
                <VideocamOutlined className="btmMenuAppsIcon" />
                {t("start_instant_meeting")}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewTabContent;
