import { Add, Close } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Bookmark, Plus, XLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import DashboardServices from "../../../../../services/DashboardServices";
import zhPatientService from "../../../../../services/ZhPatientService";
import SecureIndexedDB from "../../../../../utils/IndexedDB";
import { GetLoginUserDetails, isLocalHost } from "../../../../../utils/Utils";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import AddCompound from "./AddCompound";
import { CompoundComponent } from "./CompoundComponent";
import ListAgeGroupModel from "./ListAgeGroupModel";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../../common/TourProvider";

export default function CreateSTP({ handelCloseAddNewSTP }) {
  const loginUserDetails = GetLoginUserDetails();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const { t } = useTranslation();
  const [invAplyGender, setInvAplyGenders] = useState("");
  const [listAgeGroup, setListAgeGroup] = useState(false);

  const [selectAgeGroup, setSelectAgeGroup] = useState("");

  const [diseaseList, setDiseaseList] = useState([]);
  const [investigationList, setInvestigationList] = useState([]);
  const [userInvestigationList, setUserInvestigationList] = useState([]);

  const [selectedInvestigation, setSelectedInvestigation] = useState(null);
  const [investigationSearchString, setInvestigtionSearchString] = useState("");

  const [selectedDisease, setSelectedDisease] = useState(null);

  const [selectedLifeStyle, setSelectedLifeStyle] = useState(null);
  const [lifeStyleString, setLifeStyleString] = useState("");
  const [lifeStyleList, setLifeStyleList] = useState([]);
  const [recommandedLifeStyle, setRecomandedLifeStyle] = useState([]);

  const [monitorNames, setMonitorNames] = useState([]);
  const [parameterMonitor, setParameterMonitor] = useState([]);
  const [ageGroupList, setAgeGroupList] = useState([]);
  const [regime, setRegime] = useState("");
  const [medicationList, setMedicationList] = useState([]);
  const [showAddRegime, setShowAddRegime] = useState(true);
  const [currentStpIndex, setCurrentIndex] = useState(1);
  const [compoundList, setCompoundList] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [procedureString, setProcedureString] = useState("");
  const [procedureList, setProcedureList] = useState([]);
  const [selectedProcedureList, setSelectedProcedureList] = useState([]);
  const [procedureGender, setProcedureGender] = useState("");
  const [selectedParameter, setSelectedParameter] = useState(null);
  const [parameterString, setParameterString] = useState("");
  const [vitalUnitList, setVitalUnitList] = useState([]);
  const [regimeGender, setRegimeGender] = useState("C");
  const [instruction, setInstruction] = useState("");

  const suggestedParameterList = useMemo(() => {
    const filteredNames = monitorNames?.filter((item) => {
      const alreadyTaken = parameterMonitor.some((param) => param === item);
      return !alreadyTaken;
    });
    return filteredNames;
  }, [parameterMonitor, monitorNames]);

  const suggestedLifeStyleList = useMemo(() => {
    const filteredNames = lifeStyleList?.filter((item) => {
      const alreadyTaken = recommandedLifeStyle.some(
        (lifestyle) => lifestyle === item
      );
      return !alreadyTaken;
    });
    return filteredNames;
  }, [recommandedLifeStyle, lifeStyleList]);

  const handleCloseListAgeGroupModel = () => {
    setListAgeGroup(false);
  };

  const initializeInvestigation = () => {
    setSelectedInvestigation(null);
    setInvestigtionSearchString("");
    setInvAplyGenders("");
  };

  const initializeLifeStyle = () => {
    setLifeStyleString("");
    setSelectedLifeStyle(null);
  };

  const getDiseases = async () => {
    const diseasetring = await SecureIndexedDB.getItem("diseaseList");
    if (diseasetring) {
      setDiseaseList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getDiseases(reqDto).then((response) => {
      if (response.data) {
        setDiseaseList(response.data);

        SecureIndexedDB.setItem("diseaseList", JSON.stringify(response.data));
      }
    });
  };

  const getLifeStyles = async () => {
    const lifeStyleString = await SecureIndexedDB.getItem("lifeStyleList");
    if (lifeStyleString) {
      setLifeStyleList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = await getHealthDto();
    DashboardServices.getLifeStyles(reqDto).then((response) => {
      if (response.data) {
        setLifeStyleList(response.data);
        SecureIndexedDB.setItem("lifeStyleList", JSON.stringify(response.data));
      }
    });
  };

  const updateCompound = (data) => {
    const { type, payload } = data;
    switch (type) {
      case "addAnd":
        addAndComponent(medicationList, payload.parentId, payload.data);
        break;

      case "changeCompoundType":
        handleOrThenRadioButton(medicationList, payload.itemId, payload.data);
        break;
      case "addNewCompound":
        handleOpenAddNewCompound(
          medicationList,
          payload.parentId,
          payload.data
        );
        break;
      case "deleteCompound":
        deleteCompound(medicationList, payload.parentId, payload.itemId);
        break;

      case "addThen":
        addThenCompound(
          medicationList,
          payload.itemId,
          payload.prevIndex,
          payload.data
        );
        break;
      case "addOr":
        addOrCompound(
          medicationList,
          payload.itemId,
          payload.prevIndex,
          payload.data
        );
        break;
      case "addInnerAnd":
        addAndCompound(
          medicationList,
          payload.itemId,
          payload.prevIndex,
          payload.data
        );
        break;
      case "updateItemdData":
        handleChangeItemData(medicationList, payload.itemId, payload.data);
        break;
      default:
        break;
    }
  };

  const getVitalUnits = async () => {
    const diseasetring = await SecureIndexedDB.getItem("vitalUnitList");
    let tempVitalUnitlist = [];
    if (diseasetring) {
      tempVitalUnitlist = JSON.parse(diseasetring);
      setVitalUnitList(tempVitalUnitlist);
      return tempVitalUnitlist;
    }

    const reqDto = await getHealthDto();
    tempVitalUnitlist = await DashboardServices.getVitalUnits(reqDto).then(
      (response) => {
        if (response.data) {
          setVitalUnitList(response.data);
          SecureIndexedDB.setItem(
            "vitalUnitList",
            JSON.stringify(response.data)
          );
        }
      }
    );
    return tempVitalUnitlist;
  };
  const computeMonitors = async () => {
    let investigationList = [];
    let vitalsList = [];
    if (userInvestigationList) {
      investigationList = userInvestigationList.map(
        (item) => item.investigation.testName
      );
    }
    let unitVitalList = [];
    if (vitalUnitList?.length === 0) {
      unitVitalList = await getVitalUnits();
    } else {
      unitVitalList = vitalUnitList;
    }

    const vitalNameMaps =
      unitVitalList?.reduce((acc, curr) => {
        const temp = { ...acc };
        const { vitalCode, vitalName } = curr;

        if (temp[vitalCode]) {
          return temp;
        } else {
          temp[vitalCode] = vitalName;
          return temp;
        }
      }, {}) || {};

    const vitalNames = [
      "TEMPERATURE",
      "BP",
      "HEART_RATE",
      "RESPIRATION_RATE",
      "SPO2",
    ];

    vitalsList = vitalNames.map((item) => vitalNameMaps[item]);

    setMonitorNames([...vitalsList, ...investigationList]);
  };

  const getProcedures = async () => {
    const lifeStyleString = await SecureIndexedDB.getItem("procedureList");
    if (lifeStyleString) {
      setProcedureList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = await getHealthDto();
    DashboardServices.getProcedures(reqDto).then((response) => {
      if (response.data) {
        setProcedureList(response.data);
        SecureIndexedDB.setItem("procedureList", JSON.stringify(response.data));
      }
    });
  };

  useEffect(() => {
    computeMonitors();
  }, [userInvestigationList]);

  const getMedicalTestList = async () => {
    const symptomString = await SecureIndexedDB.getItem("medicalTestList");
    if (symptomString) {
      setInvestigationList(JSON.parse(symptomString));
      return;
    }

    const reqDto = await getHealthDto();

    DashboardServices.getMedicalTestList(reqDto).then((response) => {
      if (response.data) {
        setInvestigationList(response.data);
        SecureIndexedDB.setItem(
          "medicalTestList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const addAndComponent = (medList, parentId, data) => {
    medList?.forEach((element, index) => {
      if (element.localId === parentId) {
        const temp = [...medList];
        temp[index].compound.push(data);
        temp[index].addNewCompound = false;
        setMedicationList(temp);
      }
    });
  };

  const recursiveHandler = (item, itemId, callbackParams) => {
    const temp = { ...item };
    if (temp.localId === itemId) {
      return callbackParams.callback(temp, callbackParams);
    } else {
      temp.compoundOrThen = "";
      const { thenList, orList, andList } = temp;
      const thList = thenList
        ?.map((item) => recursiveHandler(item, itemId, callbackParams))
        ?.flat();
      const orlst = orList
        ?.map((item) => recursiveHandler(item, itemId, callbackParams))
        ?.flat();

      const andlst = andList
        ?.map((item) => recursiveHandler(item, itemId, callbackParams))
        ?.flat();
      temp.thenList = thList;
      temp.orList = orlst;
      temp.andList = andlst;
    }
    return temp;
  };

  const deleteCompound = (medList, parentId, itemId) => {
    // const recursiveHandler = (item, itemId) => {
    //   const temp = { ...item };
    //   if (temp.localId === itemId) {
    //     return temp?.orList || [];
    //   } else {
    //     temp.compoundOrThen = "";
    //     const { thenList, orList, andList } = temp;
    //     const thList = thenList
    //       ?.map((item) => recursiveHandler(item, itemId))
    //       ?.flat();
    //     const orlst = orList
    //       ?.map((item) => recursiveHandler(item, itemId))
    //       ?.flat();

    //     const andlst = andList
    //       ?.map((item) => recursiveHandler(item, itemId))
    //       ?.flat();
    //     temp.thenList = thList;
    //     temp.orList = orlst;
    //     temp.andList = andlst;
    //   }
    //   return temp;
    // };

    const deleteCallback = (temp) => {
      return temp?.orList || [];
    };

    medList?.forEach((element, index) => {
      const { compound } = element;

      let temp = [...medList];

      if (element.localId === parentId) {
        const itemIndex = temp?.[index]?.compound?.findIndex((item) => {
          return item.localId === itemId;
        });
        if (itemIndex >= 0) {
          if (temp?.[index]?.compound?.[itemIndex]?.orList?.length > 0) {
            temp[index].compound =
              temp[index].compound?.[itemIndex]?.orList?.flat() || [];
          } else {
            temp?.[index]?.compound?.splice(itemIndex, 1);
            // temp[index].compound = [];
          }
        }
        // setMedicationList(temp);
      } else {
        const compountList = compound
          ?.map((item) => {
            const hanlerValue = recursiveHandler(item, itemId, {
              callback: deleteCallback,
            });
            if (hanlerValue?.length === 0) {
              return null;
            }
            return hanlerValue;
          })
          ?.filter((item) => item);
        temp[index].compound = compountList.filter((item) => item !== null);
      }

      temp = temp.filter((item) =>
        item?.compound?.length > 0 || item.instruction ? true : false
      );
      setMedicationList(temp);
    });
  };

  const addThenCompound = (medList, itemId, prevIndex, data) => {
    // const recursiveHandler = (item, itemId, prevIndex, data) => {
    //   const temp = { ...item };
    //   if (temp.localId === itemId) {
    //     const tempData = {
    //       ...data,
    //       index: currentStpIndex,
    //       prevIndex: prevIndex,
    //     };
    //     setCurrentIndex((prev) => prev + 1);
    //     if (temp.thenList) {
    //       temp.thenList.push(tempData);
    //     } else {
    //       temp.thenList = [tempData];
    //     }
    //     temp.compoundOrThen = "";
    //   } else {
    //     temp.compoundOrThen = "";
    //     const { thenList, orList, andList } = temp;
    //     const thList = thenList?.map((item) => {
    //       const tmp = recursiveHandler(item, itemId, prevIndex, data);
    //       return tmp;
    //     });

    //     const orlst = orList?.map((item) => {
    //       const tmp = recursiveHandler(item, itemId, prevIndex, data);
    //       return tmp;
    //     });
    //     const andLst = andList?.map((item) => {
    //       const tmp = recursiveHandler(item, itemId, prevIndex, data);
    //       return tmp;
    //     });

    //     temp.thenList = thList;
    //     temp.orList = orlst;
    //     temp.andList = andLst;
    //   }
    //   return temp;
    // };

    const addThenCallback = (temp, { data, prevIndex }) => {
      const tempData = {
        ...data,
        index: currentStpIndex,
        prevIndex: prevIndex,
      };
      setCurrentIndex((prev) => prev + 1);
      temp.thenList = temp.thenList ? [...temp.thenList, tempData] : [tempData];
      temp.compoundOrThen = "";
      return temp;
    };

    const medLst = medList?.map((element, index) => {
      const { compound } = element;
      const temp = { ...element };
      const comp = compound?.map((item, idx) => {
        const temp = { ...item };
        if (item.localId === itemId) {
          const tempData = {
            ...data,
            index: currentStpIndex,
            prevIndex: prevIndex,
          };
          setCurrentIndex((prev) => prev + 1);
          if (temp.thenList) {
            temp.thenList?.push(tempData);
          } else {
            temp.thenList = [tempData];
          }
          temp.compoundOrThen = "";
        } else {
          temp.compoundOrThen = "";
          return recursiveHandler(item, itemId, {
            callback: addThenCallback,
            data,
            prevIndex,
          });
        }
        return temp;
      });
      temp.compound = comp;
      return temp;
    });
    setMedicationList(medLst);
  };

  const addOrCompound = (medList, itemId, prevIndex, data) => {
    // const recursiveHandler = (item, itemId, prevIndex, data) => {
    //   const temp = { ...item };
    //   if (temp.localId === itemId) {
    //     const tempData = {
    //       ...data,
    //       index: currentStpIndex,
    //       prevIndex: prevIndex,
    //     };
    //     setCurrentIndex((prev) => prev + 1);
    //     if (temp.orList) {
    //       temp.orList.push(tempData);
    //     } else {
    //       temp.orList = [tempData];
    //     }
    //     temp.compoundOrThen = "";
    //   } else {
    //     temp.compoundOrThen = "";
    //     const { thenList, orList, andList } = temp;
    //     const thList = thenList?.map((item) => {
    //       const tmp = recursiveHandler(item, itemId, prevIndex, data);
    //       return tmp;
    //     });
    //     const orlst = orList?.map((item) => {
    //       const tmp = recursiveHandler(item, itemId, prevIndex, data);
    //       return tmp;
    //     });
    //     const andLst = andList?.map((item) => {
    //       const tmp = recursiveHandler(item, itemId, prevIndex, data);
    //       return tmp;
    //     });
    //     temp.thenList = thList;
    //     temp.orList = orlst;
    //     temp.andList = andLst;
    //   }
    //   return temp;
    // };

    const addOrCallback = (temp, { data, prevIndex }) => {
      const tempData = {
        ...data,
        index: currentStpIndex,
        prevIndex: prevIndex,
      };
      setCurrentIndex((prev) => prev + 1);
      if (temp.orList) {
        temp.orList.push(tempData);
      } else {
        temp.orList = [tempData];
      }
      temp.compoundOrThen = "";
    };

    const medLst = medList?.map((element, index) => {
      const { compound } = element;
      const temp = { ...element };
      const comp = compound?.map((item, idx) => {
        const temp = { ...item };
        if (item.localId === itemId) {
          const tempData = {
            ...data,
            index: currentStpIndex,
            prevIndex: prevIndex,
          };
          setCurrentIndex((prev) => prev + 1);
          if (temp.orList) {
            temp.orList?.push(tempData);
          } else {
            temp.orList = [tempData];
          }
          temp.compoundOrThen = "";
        } else {
          temp.compoundOrThen = "";
          const value = recursiveHandler(item, itemId, {
            callback: addOrCallback,
            prevIndex,
            data,
          });
          return value;
        }
        return temp;
      });
      temp.compound = comp;
      return temp;
    });
    setMedicationList(medLst);
  };

  const addAndCompound = (medList, itemId, prevIndex, data) => {
    // const recursiveHandler = (item, itemId, prevIndex, data) => {
    //   const temp = { ...item };
    //   if (temp.localId === itemId) {
    //     const tempData = {
    //       ...data,
    //       index: currentStpIndex,
    //       prevIndex: prevIndex,
    //     };
    //     setCurrentIndex((prev) => prev + 1);
    //     if (temp.andList) {
    //       temp.andList.push(tempData);
    //     } else {
    //       temp.andList = [tempData];
    //     }
    //     temp.compoundOrThen = "";
    //   } else {
    //     temp.compoundOrThen = "";
    //     const { thenList, orList, andList } = temp;
    //     const thList = thenList?.map((item) => {
    //       const tmp = recursiveHandler(item, itemId, prevIndex, data);
    //       return tmp;
    //     });
    //     const orlst = orList?.map((item) => {
    //       const tmp = recursiveHandler(item, itemId, prevIndex, data);
    //       return tmp;
    //     });
    //     const andLst = andList?.map((item) => {
    //       const tmp = recursiveHandler(item, itemId, prevIndex, data);
    //       return tmp;
    //     });
    //     temp.thenList = thList;
    //     temp.orList = orlst;
    //     temp.andList = andLst;
    //   }
    //   return temp;
    // };

    const addAndCallback = (temp, { data, prevIndex }) => {
      const tempData = {
        ...data,
        index: currentStpIndex,
        prevIndex: prevIndex,
      };
      setCurrentIndex((prev) => prev + 1);
      if (temp.andList) {
        temp.andList.push(tempData);
      } else {
        temp.andList = [tempData];
      }
      temp.compoundOrThen = "";
    };

    const medLst = medList?.map((element, index) => {
      const { compound } = element;
      const temp = { ...element };
      const comp = compound?.map((item, idx) => {
        const temp = { ...item };
        if (item.localId === itemId) {
          const tempData = {
            ...data,
            index: currentStpIndex,
            prevIndex: prevIndex,
          };
          setCurrentIndex((prev) => prev + 1);
          if (temp.andList) {
            temp.andList?.push(tempData);
          } else {
            temp.andList = [tempData];
          }
          temp.compoundOrThen = "";
        } else {
          temp.compoundOrThen = "";
          const value = recursiveHandler(item, itemId, {
            callback: addAndCallback,
            prevIndex,
            data,
          });
          return value;
        }
        return temp;
      });
      temp.compound = comp;
      return temp;
    });
    setMedicationList(medLst);
  };

  const handleOrThenRadioButton = (medList, itemId, data) => {
    const recursiveHandler = (item, itemId, data) => {
      const temp = { ...item };
      if (temp.localId === itemId) {
        temp.compoundOrThen = data;
        // temp.compoundOrThen = false;
      } else {
        temp.compoundOrThen = "";
        const { thenList, orList, andList } = temp;
        const thList = thenList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, data);
          return tmp;
        });

        const orlst = orList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, data);
          return tmp;
        });
        const andLst = andList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, data);
          return tmp;
        });

        temp.thenList = thList;
        temp.orList = orlst;
        temp.andList = andLst;
      }
      return temp;
    };
    // const handlechangeItem = () => {};
    const medlst = medList?.map((element, index) => {
      const { compound } = element;
      const elmnt = { ...element };
      const cmpnd = compound?.map((item, idx) => {
        // if (item.localId === itemId) {
        //   item.compoundOrThen = data;
        // }
        const value = recursiveHandler(item, itemId, data);
        return value;
      });
      elmnt.compound = cmpnd;
      return elmnt;
    });
    setMedicationList(medlst);
  };
  const handleChangeItemData = (medList, itemId, data) => {
    const recursiveHandler = (item, itemId, data) => {
      const temp = { ...item };
      if (temp.localId === itemId) {
        data?.forEach((fieldObj) => {
          const { field, value } = fieldObj;
          temp[field] = value;
        });
        // temp.compoundOrThen = false;
      } else {
        temp.compoundOrThen = "";
        const { thenList, orList, andList } = temp;
        const thList = thenList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, data);
          return tmp;
        });

        const orlst = orList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, data);
          return tmp;
        });
        const andLst = andList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, data);
          return tmp;
        });

        temp.thenList = thList;
        temp.orList = orlst;
        temp.andList = andLst;
      }
      return temp;
    };
    // const handlechangeItem = () => {};
    const medlst = medList?.map((element, index) => {
      const { compound } = element;
      const elmnt = { ...element };
      const cmpnd = compound?.map((item, idx) => {
        // if (item.localId === itemId) {
        //   item.compoundOrThen = data;
        // }
        const value = recursiveHandler(item, itemId, data);
        return value;
      });
      elmnt.compound = cmpnd;
      return elmnt;
    });
    setMedicationList(medlst);
  };

  const handleOpenAddNewCompound = (medList, parentId, data) => {
    medList?.forEach((element, index) => {
      if (element.localId === parentId) {
        const temp = [...medList];
        temp[index].addNewCompound = data;
        setMedicationList(temp);
      }
    });
  };

  const suggestedProcedure = useMemo(() => {
    const filteredList = getFilteredList(
      procedureList,
      procedureString,
      "procedureName"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [procedureList, procedureString]);

  const handleChangselectAgeGroup = (event) => {
    setSelectAgeGroup(event.target.value);
  };

  const handleChangeinvAplyGender = (event) => {
    setInvAplyGenders(event.target.value);
  };
  const handleChangeProcedureGender = (event) => {
    setProcedureGender(event.target.value);
  };

  useEffect(() => {
    getDiseases();
    getMedicalTestList();
    getProcedures();
    getLifeStyles();
    const reqDto = {
      orgId: loginUserDetails.orgId,
      countryCode: loginUserDetails.orgCountryCode,
    };
    zhPatientService.getAgeForSTP(reqDto).then((response) => {
      setAgeGroupList(response.data);
      setSelectAgeGroup(response.data?.[0]);
    });
    zhPatientService.getCompounds(loginUserDetails.orgId).then((response) => {
      if (response.data) {
        setCompoundList(response.data);
      }
    });
  }, []);

  useEffect(() => {
    setBookSteps([
      {
        target: ".createSTPStepOne",
        content: t("this_is_where_you_can_add_a_new_stp"),
        disableBeacon: true,
      },
      {
        target: ".createSTPStepTwo",
        content: t("click_here_to_close_the_add_new_stp_section"),
        disableBeacon: true,
      },
      {
        target: ".createSTPStepThree",
        content: t(
          "start_by_selecting_a_disease_along_with_its_icd_code_from_the_list"
        ),
        disableBeacon: true,
      },
      {
        target: ".createSTPStepFour",
        content: t("here_you_can_select_the_required_investigations"),
        disableBeacon: true,
      },
      {
        target: ".createSTPStepFive",
        content: t("here_you_can_select_the_gender"),
        disableBeacon: true,
      },
      {
        target: ".createSTPStepSix",
        content: t("click_the_add_button_to_add_investigations"),
        disableBeacon: true,
      },
      {
        target: ".createSTPStepSevenOne",
        content: t("select_a_regime_from_here"),
        disableBeacon: true,
      },
      {
        target: ".createSTPStepSevenTwo",
        content: t("select_all_age_groups_from_here"),
        disableBeacon: true,
      },
      {
        target: ".createSTPStepSevenThree",
        content: t("select_the_gender_from_here"),
        disableBeacon: true,
      },
      {
        target: ".createSTPStepSevenFour",
        content: t("enter_non_medication_instructions_here"),
        disableBeacon: true,
      },
      {
        target: "#createSTPStepSevenFive",
        content: t("add_all_compounds_accordingly"),
        disableBeacon: true,
      },
      {
        target: ".createSTPStepEight",
        content: t(
          "enter_the_parameters_to_be_monitored_here_you_can_select_from_suggestions_or_add_new_ones"
        ),
        disableBeacon: true,
      },
      {
        target: ".createSTPStepNine",
        content: t(
          "click_here_to_save_or_add_the_selected_parameters_to_be_monitored"
        ),
        disableBeacon: true,
      },
      {
        target: ".createSTPStepTen",
        content: t(
          "enter_the_lifestyle_recommendations_here_you_can_select_from_suggestions"
        ),
        disableBeacon: true,
      },
      {
        target: ".createSTPStepEleven",
        content: t(
          "click_here_to_save_or_add_the_selected_lifestyle_recommendations"
        ),
        disableBeacon: true,
      },
      {
        target: ".createSTPStepTwelve",
        content: t(
          "enter_the_procedure_name_here_you_can_select_from_suggestions"
        ),
        disableBeacon: true,
      },
      {
        target: ".createSTPStepThirteen",
        content: t("please_select_the_gender_for_the_procedure"),
        disableBeacon: true,
      },
      {
        target: ".createSTPStepFourteen",
        content: t("click_here_to_save_or_add_the_selected_procedure"),
        disableBeacon: true,
      },
      {
        target: ".createSTPStepFifteen",
        content: t("finally_click_here_to_save_or_add_all_the_user_inputs"),
        disableBeacon: true,
      },
    ]);
  }, [t]);

  const handleJoyrideCallback = (data) => {
    const { status, index, action } = data;
    console.log("status index", status, index, action);

    if (status === "skipped" || action === "close" || action === "skip") {
      setRun(false);
    }

    if (status === "finished") {
      setRun({
        viewVitalConfigAD: true,
        viewUnitDoctorConfigAD: true,
        viewUnitInvesticationConfigAD: true,
        viewInvesticationConfigAD: true,
        viewParameterConfigAD: true,
      });
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["addhealthCareConfigSTPAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        callback={handleJoyrideCallback}
        scrollTo={true}
        placement="auto"
        disableOverlayClose
        scrollOffset={200}
        locale={{
          back: t("back"),
          last: t("next"),
          next: t("next"),
          skip: t("skip"),
        }}
      />
      <div className="flotingAreaContainer createSTPStepOne">
        <div className="flotingAreaClose">
          <div className="facHeader">{t("add_new_stp")}</div>
          <IconButton
            className="CloseBtn createSTPStepTwo"
            onClick={() => handelCloseAddNewSTP()}
          >
            <XLg />
          </IconButton>
        </div>

        <div className="elementFormContainer gap10">
          <div className="tskElemHeding mb-8">{t("disease_with_icd11")}</div>
          <div className="formElement ">
            <FormControl className="formControl">
              <Autocomplete
                // freeSolo
                className="formAutocompleteField createSTPStepThree"
                variant="outlined"
                value={selectedDisease}
                // options={suggestedDiseaseList}
                options={diseaseList}
                filterOptions={(option, state) => {
                  let temp = option;
                  if (state.inputValue) {
                    const includes = option.filter((disease) => {
                      return disease?.diseaseName
                        ?.toLowerCase()
                        ?.includes(state.inputValue?.toLowerCase());
                    });
                    const startsWith = option.filter((disease) => {
                      return disease?.diseaseName
                        ?.toLowerCase()
                        ?.startsWith(state.inputValue?.toLowerCase());
                    });
                    temp = [...startsWith, includes];
                  }

                  return temp.slice(0, MAX_SUGGESTED_RESULT);
                }}
                filterSelectedOptions={true}
                onChange={(e, newValue) => {
                  setSelectedDisease(newValue);
                }}
                getOptionLabel={(option) =>
                  `${option.diseaseName} ${
                    option.icd11code ? `(${option.icd11code})` : ""
                  }`
                }
                renderOption={(props, item) => {
                  return (
                    <li {...props} key={item.id}>
                      {`${item.diseaseName} ${
                        item.icd11code ? `(${item.icd11code})` : ""
                      }`}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="formAutoComInputField autocompFildPlaceholder"
                    label={t("disease_name")}
                    placeholder={t("please_select_disease")}
                    // required
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </FormControl>
          </div>

          {/* start Investigation */}
          <div className="tskElemHeding mb-8">{t("investigation")}</div>
          <div className="formElementGrp mb-8">
            <div className="formElement">
              <FormControl className="formControl">
                <Autocomplete
                  // freeSolo
                  className="formAutocompleteField createSTPStepFour"
                  variant="outlined"
                  value={selectedInvestigation}
                  options={investigationList}
                  inputValue={investigationSearchString}
                  onChange={(e, newValue) => {
                    setSelectedInvestigation(newValue);
                    if (newValue) {
                      const { gender } = newValue;
                      setInvAplyGenders(gender);
                    }
                  }}
                  onInputChange={(e, value, reason) => {
                    setInvestigtionSearchString(value);
                  }}
                  getOptionLabel={(option) => option.testName}
                  renderOption={(props, item) => {
                    return (
                      <li
                        {...props}
                        key={item.id}
                        // className={
                        //   item.isAvailableInCenter ? "available" : ""
                        // }
                      >
                        {item.testName}
                        {item.isAvailableInCenter && (
                          <Bookmark className="centAvlTest" />
                        )}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder={t("search_investigations")}
                      label={t("investigation")}
                      // required
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
            <div className="formElement mxW-100">
              <FormControl className="formControl">
                <InputLabel id="demo-simple-select-label">
                  {t("gender")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={invAplyGender}
                  label={t("gender")}
                  onChange={handleChangeinvAplyGender}
                  className="formInputField createSTPStepFive"
                  variant="outlined"
                >
                  <MenuItem value="M">{t("male")}</MenuItem>
                  <MenuItem value="F">{t("female")}</MenuItem>
                  <MenuItem value="C">{t("common")}</MenuItem>
                </Select>
              </FormControl>
            </div>

            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn createSTPStepSix"
              onClick={() => {
                let isValid = true;
                if (!selectedInvestigation) {
                  toast.error(t("please_select_investigation"));
                  isValid = false;
                  // return
                }
                if (!invAplyGender) {
                  toast.error(t("please_select_gender"));
                  isValid = false;
                }
                if (!isValid) {
                  return;
                }

                const temp = {
                  investigation: selectedInvestigation,
                  gender: invAplyGender,
                };
                const tempIvn = [...userInvestigationList];
                tempIvn.push(temp);
                setUserInvestigationList(tempIvn);
                initializeInvestigation();
              }}
            >
              {t("add")}
            </Button>
          </div>
          <div className="invAddedListGrp">
            {userInvestigationList?.map((item, index) => {
              return (
                <div className="invAddedIndv">
                  <div className="invAddName">
                    {item?.investigation?.testName}
                  </div>
                  <IconButton
                    onClick={() => {
                      const temp = [...userInvestigationList];
                      temp.splice(index, 1);
                      setUserInvestigationList(temp);
                    }}
                  >
                    <Close />
                  </IconButton>
                </div>
              );
            })}
          </div>
          {/* End Investigation*/}

          {/* start medication */}
          <div className="tskElemHeding mb-8">{t("medication")}</div>

          <div className="regimeWithComponent">
            <ul className="regimeList">
              {medicationList?.map((item, index) => {
                return (
                  <li>
                    <div className="regimeDtl">
                      <div className="regimeName">{item.regime}</div>

                      <div className="regControl">
                        {!item?.compound?.length && (
                          <Tooltip title={t("add_compound")} arrow>
                            <IconButton
                              className="editTimelineBtn"
                              onClick={() => {
                                const temp = [...medicationList];
                                temp[index].addOuterCompound = true;
                                setMedicationList(temp);
                              }}
                            >
                              <Add />
                            </IconButton>
                          </Tooltip>
                        )}

                        <Tooltip title={t("remove_regime")} arrow>
                          <div
                            className="rmvRegim"
                            onClick={() => {
                              const temp = [...medicationList];
                              temp.splice(index, 1);
                              setMedicationList(temp);
                            }}
                          >
                            {t("remove")}
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="medRecStpGrp">
                      {item.gender && (
                        <div className="medRecStpInd">
                          <span>{t("gender")}:</span>
                          {item.gender === "C"
                            ? "Common"
                            : item.gender === "M"
                            ? t("male")
                            : t("female")}
                        </div>
                      )}
                      {item.ageGroup && (
                        <div className="medRecStpInd">
                          <span>{t("age_group")}:</span>
                          {item.ageGroup}
                        </div>
                      )}
                      {item.instruction && (
                        <div className="medRecStpInd">
                          <span>{t("instruction")}:</span>
                          {item.instruction}
                        </div>
                      )}
                    </div>
                    {/* <div className="medRecStpGrp"></div> */}
                    <CompoundComponent
                      compoundList={compoundList}
                      compound={item}
                      parentId={item.localId}
                      updateCompound={updateCompound}
                    />
                    {item?.addOuterCompound && (
                      <AddCompound
                        compoundList={compoundList}
                        handleAddCompound={(data) => {
                          if (!data?.compound) {
                            toast.error("Please give compound");
                            return;
                          }

                          const temp = {
                            ...data,
                            index: currentStpIndex,
                            prevIndex: 0,
                          };
                          setCurrentIndex((prev) => prev + 1);

                          const tempMed = [...medicationList];
                          tempMed[index].compound = [temp];
                          tempMed[index].addOuterCompound = false;
                          setMedicationList(tempMed);
                        }}
                        closeAddCompound={() => {
                          const temp = [...medicationList];
                          temp[index].addOuterCompound = false;
                          setMedicationList(temp);

                          setShowAddRegime(false);
                        }}
                        shouldVerify={false}
                      />
                    )}
                  </li>
                );
              })}
            </ul>
            {!showAddRegime && medicationList?.length > 0 && (
              <div className="addAndCompound">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    setShowAddRegime(true);
                  }}
                >
                  {t("new_regime")}
                </Button>
              </div>
            )}
          </div>

          {(showAddRegime || !medicationList?.length > 0) && (
            <div className="medicationGroup">
              <div className="formElementGrp">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      // hiddenLabel
                      label={t("regime")}
                      required
                      autoComplete="off"
                      variant="outlined"
                      className="formTextFieldArea createSTPStepSevenOne"
                      value={regime}
                      onChange={(e) => {
                        setRegime(e.target.value);
                      }}
                    />
                  </FormControl>
                </div>
                <div className="formElement width13 createSTPStepSevenTwo">
                  <FormControl className="formControl">
                    <InputLabel id="selectAgeGroup">
                      {t("age_group")}
                    </InputLabel>
                    <Select
                      required
                      labelId="selectAgeGroup"
                      id="select_AgeGroup"
                      value={selectAgeGroup}
                      label={t("age_group")}
                      onChange={handleChangselectAgeGroup}
                      className="formInputField"
                      variant="outlined"
                    >
                      {ageGroupList?.map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className="formElement mxW-100 createSTPStepSevenThree">
                  <FormControl className="formControl">
                    <InputLabel id="demo-simple-select-label">
                      {t("gender")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={regimeGender}
                      label={t("gender")}
                      onChange={(e) => {
                        setRegimeGender(e.target.value);
                      }}
                      className="formInputField"
                      variant="outlined"
                    >
                      <MenuItem value="M">{t("male")}</MenuItem>
                      <MenuItem value="F">{t("female")}</MenuItem>
                      <MenuItem value="C">{t("common")}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="formElementGrp">
                <div className="formElement">
                  <FormControl className="formControl pRelative">
                    <TextField
                      label={t("non_medicational_instruction")}
                      variant="outlined"
                      className="descptionTextFild createSTPStepSevenFour"
                      multiline
                      minRows={2}
                      maxRows={7}
                      value={instruction}
                      onChange={(e) => setInstruction(e.target.value)}
                    />
                  </FormControl>
                </div>
              </div>

              <div id="createSTPStepSevenFive">
                <AddCompound
                  compoundList={compoundList}
                  handleAddCompound={(data) => {
                    if (!regime.trim()) {
                      toast.error(t("please_add_regime_name"));
                      setRegime("");
                      return;
                    }

                    if (!data?.compound && !instruction) {
                      toast.error("please give insturction or compound");
                      return;
                    }

                    const temp = {
                      ...data,
                      index: currentStpIndex,
                      prevIndex: 0,
                    };
                    setCurrentIndex((prev) => prev + 1);
                    const dto = {
                      localId: uuidv4(),
                      regime,
                      gender: regimeGender,
                      instruction: instruction,
                      compound: data?.compound ? [temp] : [],
                      ageGroup: selectAgeGroup,
                    };

                    setMedicationList((prev) => [...prev, dto]);
                    setShowAddRegime(false);
                    setRegime("");
                  }}
                  closeAddCompound={() => {
                    setShowAddRegime(false);
                  }}
                  shouldVerify={false}
                />
              </div>
            </div>
          )}

          {/* end medication */}

          {/* Start Parameter to be Monitor */}
          <div className="tskElemHeding mb-8">
            {t("parameter_to_be_monitored")}
          </div>
          <div className="formElementGrp mb-8">
            <div className="formElement">
              <FormControl className="formControl">
                <Autocomplete
                  freeSolo
                  className="formAutocompleteField createSTPStepEight"
                  variant="outlined"
                  value={selectedParameter}
                  options={suggestedParameterList}
                  inputValue={parameterString}
                  onChange={(e, newValue) => {
                    setSelectedParameter(newValue);
                  }}
                  onInputChange={(e, value) => {
                    setParameterString(value);
                  }}
                  // getOptionLabel={(option) => option.lifeStyle}
                  renderOption={(props, item) => {
                    return (
                      <li {...props} key={item}>
                        {item}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder={t("search_parameter")}
                      label={t("parameter_name")}
                      // required
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>

            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn createSTPStepNine"
              onClick={() => {
                const parameterItem = parameterString;
                if (!parameterString) {
                  toast.error(t("please_provide_parameter_name"));
                  return;
                }
                const temp = [...parameterMonitor];
                temp.push(parameterString);
                setParameterMonitor(temp);
                setParameterString("");
                setSelectedParameter(null);
              }}
            >
              {t("add")}
            </Button>
          </div>
          <div className="invAddedListGrp">
            {parameterMonitor?.map((item, index) => {
              return (
                <div className="invAddedIndv">
                  <div className="invAddName">{item}</div>
                  <IconButton
                    onClick={() => {
                      const temp = [...parameterMonitor];
                      temp.splice(index, 1);
                      setParameterMonitor(temp);
                    }}
                  >
                    <Close />
                  </IconButton>
                </div>
              );
            })}
          </div>
          {/* end Parameter to be Monitor */}

          {/* Start lifestyle */}
          <div className="tskElemHeding mb-8">
            {t("lifestyle_recommendations")}
          </div>
          <div className="formElementGrp mb-8">
            <div className="formElement">
              <FormControl className="formControl">
                {/* <InputLabel id="selectparam">Select Lifestyle</InputLabel> */}

                <Autocomplete
                  freeSolo
                  className="formAutocompleteField createSTPStepTen"
                  variant="outlined"
                  value={selectedLifeStyle}
                  options={suggestedLifeStyleList}
                  inputValue={lifeStyleString}
                  onChange={(e, newValue) => {
                    setSelectedLifeStyle(newValue);
                  }}
                  onInputChange={(e, value) => {
                    setLifeStyleString(value);
                    // else {
                    //   setSymptomSearchString("");
                    // }
                  }}
                  getOptionLabel={(option) => option.lifeStyle}
                  renderOption={(props, item) => {
                    return (
                      <li {...props} key={item.id}>
                        {item.lifeStyle}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder={t("search_lifestyle")}
                      label={t("life_style_name")}
                      // required
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>

            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn createSTPStepEleven"
              onClick={() => {
                if (!lifeStyleString || lifeStyleString === "") {
                  toast.error(t("please_provide_lifestyle"));
                  return;
                }
                let tempLifeStyle = selectedLifeStyle;

                setRecomandedLifeStyle((prev) => {
                  const temp = [...prev, tempLifeStyle];
                  return temp;
                });
                initializeLifeStyle();
              }}
            >
              {t("add")}
            </Button>
          </div>
          <div className="invAddedListGrp">
            {recommandedLifeStyle?.map((item, index) => {
              return (
                <div className="invAddedIndv">
                  <div className="invAddName">{item?.lifeStyle}</div>
                  <IconButton
                    onClick={() => {
                      const temp = [...recommandedLifeStyle];
                      temp.splice(index, 1);
                      setRecomandedLifeStyle(temp);
                    }}
                  >
                    <Close />
                  </IconButton>
                </div>
              );
            })}
          </div>
          {/* end lifestyle */}

          {/* start procedure */}
          <div className="tskElemHeding mb-8">{t("procedure")}</div>
          <div className="formElementGrp mb-8">
            <div className="formElement">
              <FormControl className="formControl">
                <Autocomplete
                  // freeSolo
                  className="formAutocompleteField createSTPStepTwelve"
                  variant="outlined"
                  value={selectedProcedure}
                  options={suggestedProcedure}
                  onChange={(e, newValue) => {
                    setSelectedProcedure(newValue);
                    setProcedureGender(newValue?.gender);
                  }}
                  inputValue={procedureString}
                  onInputChange={(e, value) => {
                    setProcedureString(value);
                  }}
                  getOptionLabel={(option) => option.procedureName}
                  renderOption={(props, item) => {
                    return (
                      <li {...props} key={item.id}>
                        {item.procedureName}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder={t("add_procedure")}
                      label={t("procedure_name")}
                      // required
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
            <div className="formElement mxW-100">
              <FormControl className="formControl">
                <InputLabel id="demo-simple-select-label">
                  {t("gender")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={procedureGender}
                  label={t("gender")}
                  onChange={handleChangeProcedureGender}
                  className="formInputField createSTPStepThirteen"
                  variant="outlined"
                >
                  <MenuItem value="M">{t("male")}</MenuItem>
                  <MenuItem value="F">{t("female")}</MenuItem>
                  <MenuItem value="C">{t("common")}</MenuItem>
                </Select>
              </FormControl>
            </div>

            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn createSTPStepFourteen"
              onClick={() => {
                let tempProcedure = selectedProcedure;
                if (!tempProcedure) {
                  toast.error(t("please_provide_procedure_name"));
                  return;
                }

                setSelectedProcedureList((prev) => {
                  const temp = [
                    ...prev,
                    {
                      procedure: tempProcedure,
                      gender: procedureGender,
                    },
                  ];
                  return temp;
                });
                // initializeForm();
                setSelectedProcedure(null);
                setProcedureGender("");
                setProcedureString("");
              }}
            >
              {t("add")}
            </Button>
          </div>
          <div className="invAddedListGrp">
            {selectedProcedureList?.map((item, index) => (
              <>
                <div className="invAddedIndv">
                  <div className="invAddName">
                    {item?.procedure?.procedureName}
                  </div>
                  <IconButton
                    onClick={() => {
                      const temp = [...selectedProcedureList];
                      temp.splice(index, 1);
                      setSelectedProcedureList(temp);
                    }}
                  >
                    <Close />
                  </IconButton>
                </div>
              </>
            ))}
          </div>
          {/* End procedure */}
        </div>

        <div className="elementFooter">
          <div className="formBtnElement">
            <Button
              className="dfultPrimaryBtn createSTPStepFifteen"
              onClick={() => {
                const recursiveHandler = (
                  item,
                  regimeName,
                  ageGroup,
                  gender,
                  diseaseId,
                  operator
                ) => {
                  const compound = {
                    regimeName: regimeName,
                    ageGroup: ageGroup,
                    weightMeasured: item?.weightMeasured ? "Y" : "N",
                    diseaseId: diseaseId || "",
                    compoundId: item?.compound?.compoundId || 0,
                    formId: item?.form?.formId || 0,
                    routeId: item?.route?.id || 0,
                    dosages: item?.dose || "",
                    dosagesUnit: item?.doseUnit || "",
                    dosagesLimit: item?.doseLimit || "",
                    durationId: item?.duration?.id || 0,
                    frequencyId: item?.frequency?.id || 0,
                    operator: operator || "",
                    indexId: item.index,
                    previousIndexId: item.prevIndex,
                    formName: item?.form?.formName || "",
                    routeName: item?.route?.route || "",
                    duration: item?.duration?.durationName || "",
                    frequency: item?.frequency?.frequency,
                    compoundName: item?.compound?.compoundName || "",
                    notes: item?.note || "",
                    gender: gender,
                    instruction: instruction,
                  };
                  let temp = [];
                  const value = item?.thenList
                    ?.map((item) => {
                      const tempValue = recursiveHandler(
                        item,
                        regimeName,
                        ageGroup,
                        gender,
                        diseaseId,
                        "Then"
                      );
                      return tempValue.flat();
                    })
                    ?.flat();

                  const value2 = item?.orList
                    ?.map((item) => {
                      const tempValue = recursiveHandler(
                        item,
                        regimeName,
                        ageGroup,
                        gender,
                        diseaseId,
                        "Or"
                      );
                      return tempValue.flat();
                    })
                    ?.flat();
                  const value3 = item?.andList
                    ?.map((item) => {
                      const tempValue = recursiveHandler(
                        item,
                        regimeName,
                        ageGroup,
                        gender,
                        diseaseId,
                        "Along With"
                      );
                      return tempValue.flat();
                    })
                    ?.flat();
                  if (value) {
                    temp = [...temp, ...value];
                  }
                  if (value2) {
                    temp = [...temp, ...value2];
                  }
                  if (value3) {
                    temp = [...temp, ...value3];
                  }
                  if (temp?.length > 0) {
                    return [compound, ...temp];
                  } else {
                    return [compound];
                  }
                };
                // ;
                if (!selectedDisease) {
                  toast.error(t("please_select_disease"));
                  return;
                }

                // if (!userInvestigationList?.length) {
                //   toast.error(t("please_select_investigation"));
                //   return;
                // }

                // if (!medicationList?.length) {
                //   toast.error(t("please_select_compounds"));
                //   return;
                // }

                if (
                  [
                    userInvestigationList,
                    medicationList,
                    parameterMonitor,
                    recommandedLifeStyle,
                    selectedProcedureList,
                  ].every((list) => !list?.length)
                ) {
                  toast.error(
                    t(
                      "at_least_one_of_the_following_must_be_provided_investigation_medication_parameter_lifestyle_or_procedure"
                    )
                  );
                  return;
                }
                const compoundList = medicationList
                  ?.map((item) => {
                    const { compound } = item;
                    let tempCompounds = [];
                    compound?.forEach((comp) => {
                      const value = recursiveHandler(
                        comp,
                        item.regime,
                        item.ageGroup,
                        item.gender,
                        selectedDisease?.id
                      );
                      tempCompounds = [...tempCompounds, ...value];
                      // return value;
                    });
                    return tempCompounds;
                  })
                  .flat();

                const reqDto = {
                  diseaseId: selectedDisease?.id || 0,
                  orgId: loginUserDetails.orgId,
                  lifeStyles: recommandedLifeStyle || [],
                  parameters: parameterMonitor || [],
                  tests:
                    userInvestigationList?.map((item) => ({
                      testId: item.investigation.testId,
                      testName: item.investigation.testName,
                      gender: item.gender,
                    })) || [],
                  procedures:
                    selectedProcedureList?.map((item) => {
                      return {
                        procedureId: item.procedure.id,
                        gender: item.gender,
                        procedureName: item.procedure.procedureName,
                      };
                    }) || [],
                  compounds: compoundList || [],
                  stpId: 0,
                };

                if (isLocalHost()) {
                  console.log("reqDto=", reqDto);
                  // return;
                }

                zhPatientService
                  .submitStp(loginUserDetails.userId, reqDto)
                  .then((response) => {
                    if (response.data) {
                      toast.success(t("data_submitted_successfully"));
                      handelCloseAddNewSTP(true);
                    }
                  });
              }}
            >
              {t("submit")}
            </Button>

            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => handelCloseAddNewSTP()}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </div>

      {listAgeGroup && (
        <>
          <ListAgeGroupModel
            handleCloseListAgeGroupModel={handleCloseListAgeGroupModel}
            handleClose={() => {
              setListAgeGroup(false);
            }}
          />
        </>
      )}
    </>
  );
}
