import {
  CenterFocusStrongOutlined,
  Close,
  CropFreeOutlined,
  FilterCenterFocusOutlined,
} from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import React from "react";
import {
  ArrowClockwise,
  ArrowDown,
  ArrowDownLeft,
  ArrowDownRight,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowUpLeft,
  ArrowUpRight,
  Controller,
  ZoomIn,
  ZoomOut,
} from "react-bootstrap-icons";

export default function ZoyeMedController({ handleConstMenuShow }) {
  return (
    <>
      <div className="zoyeControlerbtnGroup">
        <div className="zcbgSection">
          <Tooltip followCursor title="Camera Up Left">
            <IconButton className="navIcoBtn">
              <ArrowUpLeft />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera Up ">
            <IconButton className="navIcoBtn">
              <ArrowUp />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera Up Right">
            <IconButton className="navIcoBtn">
              <ArrowUpRight />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera Left">
            <IconButton className="navIcoBtn">
              <ArrowLeft />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera Reset position">
            <IconButton className="navIcoBtn">
              <ArrowClockwise />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera Right">
            <IconButton className="navIcoBtn">
              <ArrowRight />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera Down Left">
            <IconButton className="navIcoBtn">
              <ArrowDownLeft />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera Down">
            <IconButton className="navIcoBtn">
              <ArrowDown />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera Down Right">
            <IconButton className="navIcoBtn">
              <ArrowDownRight />
            </IconButton>
          </Tooltip>

          {/* other button as like zoomIn ZoomOut etc */}
          <Tooltip followCursor title="Camera ZoomIn">
            <IconButton className="navIcoBtn zoomIn">
              <ZoomIn />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera ZoomOut">
            <IconButton className="navIcoBtn zoomOut">
              <ZoomOut />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera FocusIn">
            <IconButton className="navIcoBtn focusIn">
              <FilterCenterFocusOutlined />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera FocusOut">
            <IconButton className="navIcoBtn focusOut">
              <CenterFocusStrongOutlined />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera Auto Focus">
            <IconButton className="navIcoBtn autoFocus">
              <CropFreeOutlined />
            </IconButton>
          </Tooltip>
          <IconButton className="navIcoBtn emptyCell"></IconButton>
          <IconButton className="navIcoBtn emptyCell2"></IconButton>
          {/* close */}
          <Tooltip followCursor title="Back To main menu">
            <Button className="backmainMenu" onClick={handleConstMenuShow}>
              Close
            </Button>
          </Tooltip>

          {/* PTZ Controlar Head start */}
          <div className="ptzContHead">PTZ Controller</div>
          {/* PTZ Controlar Head end */}
        </div>
      </div>
    </>
  );
}
