import React from "react";
import SupportTicketAreYouSatisfied from "./SupportTicketAreYouSatisfied";

export default function RightSideSupportTicketAreYouSatisfied({
  setShowSupportTicketAreYouSatisfied,
  supportTicketData,
  handleCloseAreYouSatisfied,
}) {
  return (
    <>
      <div className="RightFlotingContainerArea feedbackFormSupport">
        <SupportTicketAreYouSatisfied
          setShowSupportTicketAreYouSatisfied={
            setShowSupportTicketAreYouSatisfied
          }
          supportTicketData={supportTicketData}
          handleCloseAreYouSatisfied={handleCloseAreYouSatisfied}
        />
      </div>
    </>
  );
}
