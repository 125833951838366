import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import { ThumbsDown, ThumbsUp } from "iconoir-react";
import { React, useEffect, useState } from "react";
import { XLg } from "react-bootstrap-icons";
import RightFlotingAdmin from "../../../../../../admin/view/component/RightFlotingAdmin";
import zhAIService from "../../../../../../../services/ZhAIService";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../../../../utils/Utils";

export default function DrugSafety({ data, closeDrugSafety, updateData }) {
  const userLoginDetails = GetLoginUserDetails();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  // const [drugSafetyData, setDrugSafetyData] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [drugSafetyData1, setDrugSafetyData1] = useState(null);
  const [drugSafetyData2, setDrugSafetyData2] = useState(null);
  const [drugSafetyData3, setDrugSafetyData3] = useState(null);
  const [drugSafetyData4, setDrugSafetyData4] = useState(null);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  useEffect(() => {
    const processData = async () => {
      if (data.medication && data.medication.length > 0) {
        // setLoading(true);
        fetchDrugSafetyDataInChunk(1);
        fetchDrugSafetyDataInChunk(2);
        fetchDrugSafetyDataInChunk(3);
        fetchDrugSafetyDataInChunk(4);
      }
    };
    processData();
  }, [data]);

  // const fetchDrugSafetyData = async () => {
  //   const genericNameIds = data.medication.map((med) => med.genericName.id);
  //   console.log("genericNameIds", genericNameIds, i18n.language);
  //   let locale = "en";
  //   if (
  //     userLoginDetails.orgId === "ORG000000000319" ||
  //     userLoginDetails.orgId === "ORG000000000309"
  //   ) {
  //     locale = "es";
  //   }

  //   const response = await zhAIService.getDrugSafetyDataByLocaleInChunks(
  //     genericNameIds,
  //     locale
  //   );
  //   console.log("getDrugSafetyData", response.data);
  //   setDrugSafetyData(response.data);
  //   setLoading(false);
  // };

  const fetchDrugSafetyDataInChunk = async (chunk) => {
    if (chunk === 1) {
      setLoading1(true);
    }
    if (chunk === 2) {
      setLoading2(true);
    }
    if (chunk === 3) {
      setLoading3(true);
    }
    if (chunk === 4) {
      setLoading4(true);
    }
    const genericNameIds = data.medication.map((med) => med.genericName.id);
    console.log("genericNameIds", genericNameIds, i18n.language);
    let locale = "en";
    if (
      userLoginDetails.orgId === "ORG000000000319" ||
      userLoginDetails.orgId === "ORG000000000309"
    ) {
      locale = "es";
    }

    const response = await zhAIService.getDrugSafetyDataByLocaleInChunks(
      genericNameIds,
      locale,
      chunk
    );
    console.log("getDrugSafetyData-->>>>", chunk, response.data);

    if (chunk === 1) {
      setDrugSafetyData1(response.data);
      setLoading1(false);
    }
    if (chunk === 2) {
      setDrugSafetyData2(response.data);
      setLoading2(false);
    }
    if (chunk === 3) {
      setDrugSafetyData3(response.data);
      setLoading3(false);
    }
    if (chunk === 4) {
      setDrugSafetyData4(response.data);
      setLoading4(false);
    }
  };

  //   return (
  //     <Card
  //       className="drgSafeSkeletonCard"
  //       variant="outlined"
  //       style={{ marginBottom: "16px" }}
  //     >
  //       <CardContent className="drgSafeSkeletonCardContent">
  //         {info.contraindications && (
  //           <>
  //             <Typography variant="h6" gutterBottom>
  //               Contraindications
  //             </Typography>
  //             <Typography variant="body1" paragraph className="op7">
  //               {info.contraindications}
  //             </Typography>
  //             <Divider />
  //           </>
  //         )}
  //         {info.adverse_reactions && (
  //           <>
  //             <Typography variant="h6" gutterBottom>
  //               Adverse Reactions
  //             </Typography>
  //             <Typography variant="body1" paragraph className="op7">
  //               {info.adverse_reactions}
  //             </Typography>
  //             <Divider />
  //           </>
  //         )}
  //         {info.drug_interactions && (
  //           <>
  //             <Typography variant="h6" gutterBottom>
  //               Drug Interactions
  //             </Typography>
  //             <Typography variant="body1" className="op7">
  //               {info.drug_interactions}
  //             </Typography>
  //           </>
  //         )}
  //       </CardContent>
  //     </Card>
  //   );
  // };

  // const MedicationInfoList = ({ data, loading }) => {
  //   const isEmpty = data.every(
  //     (item) =>
  //       !item.contraindications &&
  //       !item.adverse_reactions &&
  //       !item.drug_interactions
  //   );

  //   if (loading) {
  //     return (
  //       <div className="drgSafeSkeleton">
  //         {Array.from(new Array(3)).map((_, index) => (
  //           <Card
  //             className="drgSafeSkeletonCard"
  //             variant="outlined"
  //             style={{ marginBottom: "16px" }}
  //             key={index}
  //           >
  //             <CardContent className="drgSafeSkeletonCardContent">
  //               <Skeleton variant="text" width="60%" height={30} />
  //               <Skeleton variant="text" width="100%" height={15} />
  //               <Skeleton variant="text" width="100%" height={15} />
  //               <Skeleton variant="text" width="80%" height={15} />
  //               <Divider />
  //               <Skeleton
  //                 variant="text"
  //                 width="60%"
  //                 height={30}
  //                 style={{ marginTop: "16px" }}
  //               />
  //               <Skeleton variant="text" width="100%" height={15} />
  //               <Skeleton variant="text" width="80%" height={15} />
  //               <Divider />
  //               <Skeleton
  //                 variant="text"
  //                 width="60%"
  //                 height={30}
  //                 style={{ marginTop: "16px" }}
  //               />
  //               <Skeleton variant="text" width="100%" height={15} />
  //               <Skeleton variant="text" width="90%" height={15} />
  //             </CardContent>
  //           </Card>
  //         ))}
  //       </div>
  //     );
  //   }

  //   if (isEmpty) {
  //     return (
  //       <Box textAlign="center" mt={4}>
  //         <Typography variant="h6" style={{ color: "white" }}>
  //           No data found
  //         </Typography>
  //       </Box>
  //     );
  //   }

  //   return (
  //     <div>
  //       {data.map((item, index) => (
  //         <MedicationInfoCard key={index} info={item} />
  //       ))}
  //     </div>
  //   );
  // };

  const DrugInfo = ({ drugSafetyDataTemp }) => {
    // Filter out null values from the data
    const filteredData = Object.entries(drugSafetyDataTemp).filter(
      ([key, value]) =>
        value !== null &&
        value !== "" &&
        key !== "active_flag" &&
        key !== "generic_name" &&
        key !== "route" &&
        key !== "product_type"
    );

    console.log("DrugInfo filteredData", filteredData, drugSafetyDataTemp);
    if (
      drugSafetyDataTemp.stop_use &&
      drugSafetyDataTemp.stop_use.trim() !== ""
    ) {
      // updateData({
      //   watchoutFor: drugSafetyDataTemp.stop_use,
      // });
    }

    return (
      <Grid container spacing={3}>
        {filteredData.map(([key, value]) => (
          <Grid item xs={12} md={6} key={key}>
            <Card>
              <CardContent>
                <Typography variant="h5" color="textPrimary">
                  {t(key.replace(/_/g, " ").replace(/ /g, "_"))}
                </Typography>
                <Typography variant="body2">{value}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  const DrugInfoSkeleton = () => {
    // Placeholder skeletons to display while data is loading
    return (
      <Grid container spacing={3}>
        {Array.from(new Array(4)).map((_, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card>
              <CardContent>
                <Skeleton variant="text" width={150} />
                <Skeleton variant="text" height={40} />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };
  return (
    <>
      <RightFlotingAdmin className="ddFlotContainer dsHeight">
        <div className="flotingAreaContainer">
          <div className="flotingAreaClose dsFloatingAreaClose">
            <div className="facHeader dsHeader">{t("drug_safety")}</div>
            <IconButton
              className="CloseBtn dsCloseBtn"
              onClick={() => closeDrugSafety(drugSafetyData3)}
            >
              <XLg />
            </IconButton>
          </div>

          <div className="elementFormContainer">
            {/* {loading ? (
              <DrugInfoSkeleton />
            ) : drugSafetyData ? (
              <DrugInfo drugSafetyDataTemp={drugSafetyData} />
            ) : (
              <Typography>{t("no_data_found")}</Typography>
            )} */}
            {loading1 ? (
              <DrugInfoSkeleton />
            ) : drugSafetyData1 ? (
              <DrugInfo drugSafetyDataTemp={drugSafetyData1} />
            ) : (
              <Typography>{t("no_data_found")}</Typography>
            )}
            {loading2 ? (
              <DrugInfoSkeleton />
            ) : drugSafetyData2 ? (
              <DrugInfo drugSafetyDataTemp={drugSafetyData2} />
            ) : (
              <Typography>{t("no_data_found")}</Typography>
            )}
            {loading3 ? (
              <DrugInfoSkeleton />
            ) : drugSafetyData3 ? (
              <DrugInfo drugSafetyDataTemp={drugSafetyData3} />
            ) : (
              <Typography>{t("no_data_found")}</Typography>
            )}
            {loading4 ? (
              <DrugInfoSkeleton />
            ) : drugSafetyData4 ? (
              <DrugInfo drugSafetyDataTemp={drugSafetyData4} />
            ) : (
              <Typography>{t("no_data_found")}</Typography>
            )}
          </div>

          <div className="elementFooter">
            <div className="formBtnElement">
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => closeDrugSafety(drugSafetyData3)}
              >
                {t("close")}
              </Button>
            </div>
          </div>
        </div>
      </RightFlotingAdmin>
    </>
  );
}
