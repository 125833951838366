import {
  Add,
  ExpandMoreRounded,
  HealthAndSafety,
  IntegrationInstructions,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { staticDataSocket } from "../../../../../../../socket";
import SecureIndexedDB from "../../../../../../../utils/IndexedDB";
import {
  GetLoginUserDetails,
  isLocalHost,
} from "../../../../../../../utils/Utils";
import { CustomModal } from "../../../../../../task/view/component/Modals/CustomModal";
import { getHealthDto, MAX_SUGGESTED_RESULT } from "./consultationUtil";
import { MedicationBody } from "./MedicationBody";
import { useConfirm } from "material-ui-confirm";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const timings = [
//   "Stat",
//   "Before Breakfast",
//   "After Breakfast",
//   "Before Lunch",
//   "After Lunch",
//   "Before Evening",
//   "After Evening",
//   "Before Dinner",
//   "After Dinner",
//   "After Food",
//   "Empty Stomach",
// ];

const timings = [
  "stat",
  "before_breakfast",
  "after_breakfast",
  "before_lunch",
  "after_lunch",
  "before_evening",
  "after_evening",
  "before_dinner",
  "after_dinner",
  "after_food",
  "empty_stomach",
];

export default function Medication({
  updateData,
  data,
  setShowDrugSafety,
  setShowSTP,
  extraData,
}) {
  const confirm = useConfirm();

  const loginUserDetail = GetLoginUserDetails();
  const { t } = useTranslation();
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [accordionString, setAccordionString] = useState("");

  const [dosage, setDosage] = useState("");
  const [mForm, setMForm] = useState("");
  const [frequency, setFrequency] = useState("");
  const [regimen, setRegimen] = useState("");

  const [mTiming, setMTiming] = useState([]);
  const [doctorGenericList, setDoctorGenericList] = useState([]);
  const [genericList, setGenericList] = useState([]);
  const [brandList, setBrandList] = useState([]);

  const [genericSearchInput, setGenericSearchInput] = useState("");
  const [selectedGeneric, setSelectedGeneric] = useState(null);
  const [medicationNote, setMedicationNote] = useState("");

  const [medicationList, setMedicationList] = useState([]);
  const [prevMedicationList, setPrevMedicationList] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [frequencyList, setFrequencyList] = useState([]);

  const [dosageList, setDosageList] = useState([]);
  const [suggestedDosage, setSuggestedDosage] = useState([]);

  const [routeList, setRoutelist] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [showUpdateModule, setShowUpdateModule] = useState(false);
  const editIndex = useRef(null);
  const editData = useRef();
  const [timingList, setTimingList] = useState([]);
  const [showConfirm, setShowConfirmation] = useState(false);

  //Brand item

  const [suggestedBrand, setSuggestedBrand] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [brandInputString, setBrandInputString] = useState("");
  const confirmString = useRef("");

  const [isAllLoaded, setIsAllLoaded] = useState(false);

  const specialCharMap = new Map([
    ["Á", "A"],
    ["È", "E"],
    ["É", "E"],
    ["Í", "I"],
    ["Ñ", "N"],
    ["Ó", "O"],
    ["Ô", "O"],
    ["Ú", "U"],
    ["Ü", "U"],
  ]);
  const specialCharKeys = Array.from(specialCharMap.keys());

  const getFilteredList = (data, str) => {
    let searchString = "" + str;

    for (let i = 0; i < specialCharKeys.length; i++) {
      if (searchString.includes(specialCharKeys[i])) {
        searchString = searchString.replace(
          specialCharKeys[i],
          specialCharMap.get(specialCharKeys[i])
        );
      }
    }

    if (searchString === "" || searchString === null) {
      const temp = data?.slice(0, MAX_SUGGESTED_RESULT);
      return { startsWithList: temp, containsList: [] };
    }
    const startsWithList = [];
    const containsList = [];

    data?.forEach((element) => {
      let genericName = element.generic;

      for (let i = 0; i < specialCharKeys.length; i++) {
        if (genericName.includes(specialCharKeys[i])) {
          genericName = genericName.replace(
            specialCharKeys[i],
            specialCharMap.get(specialCharKeys[i])
          );
        }
      }
      if (genericName.toLowerCase().startsWith(searchString.toLowerCase())) {
        startsWithList.push(element);
      } else if (
        genericName.toLowerCase().includes(searchString.toLowerCase())
      ) {
        containsList.push(element);
      }
    });

    return { startsWithList, containsList };
  };

  const getTimingList = async () => {
    const reqDto = await getHealthDto();
    DashboardServices.getMedicationTiming(reqDto).then((response) => {
      if (response.data) {
        setTimingList(response.data);
      }
    });
  };

  const getFilteredBrand = (data, str) => {
    const searchString = "" + str;
    if (searchString === "" || searchString === null) {
      return data.slice(0, MAX_SUGGESTED_RESULT);
    }
    const startsWithList = [];
    const containsList = [];
    data.forEach((element) => {
      let brandName = element.brandName;
      for (let i = 0; i < specialCharKeys.length; i++) {
        if (brandName.includes(specialCharKeys[i])) {
          brandName = brandName.replace(
            specialCharKeys[i],
            specialCharMap.get(specialCharKeys[i])
          );
        }
      }
      if (brandName.toLowerCase().startsWith(searchString.toLowerCase())) {
        startsWithList.push(element);
      } else if (brandName.toLowerCase().includes(searchString.toLowerCase())) {
        containsList.push(element);
      }
    });

    return [...startsWithList, ...containsList];
  };

  const setUniqueDoses = () => {
    const uniqueDosage = {};
    dosageList.forEach((item) => {
      if (!uniqueDosage[item.dosage]) {
        uniqueDosage[item.dosage] = item;
      }
    });
    const uniqueItems = Object.values(uniqueDosage);
    setSuggestedDosage(uniqueItems);
    return uniqueItems;
  };

  const suggestedMedicineList = useMemo(() => {
    // let userAllergicIdList = medicationList?.map(
    //   (item) => item?.genericName?.id
    // );

    let userAllergicIdList = medicationList;
    let filteredGeneric = [];

    let filteredDoctor = [];

    if (!selectedBrand || (selectedBrand && selectedBrand?.genericId)) {
      if (selectedBrand) {
        filteredGeneric = genericList?.filter((procedure) => {
          return procedure.id === selectedBrand?.genericId;
          // return !alreadyTaken;
        });
        filteredDoctor = doctorGenericList?.filter((procedure) => {
          return procedure.id === selectedBrand?.genericId;
          // return !alreadyTaken;
        });
      } else {
        filteredGeneric = genericList;
        filteredDoctor = doctorGenericList;
      }
    }

    const genericListFilteredData = getFilteredList(
      filteredGeneric,
      genericSearchInput,
      "generic"
    );
    const doctorFilteredData = getFilteredList(
      filteredDoctor,
      genericSearchInput
    );
    const tempValue = [
      ...(doctorFilteredData.startsWithList || []),
      ...(genericListFilteredData.startsWithList || []),
      ...(doctorFilteredData.containsList || []),
      ...(genericListFilteredData.containsList || []),
    ];
    if (tempValue.length === 0) {
      setSuggestedBrand([]);
      // setBrandInputString("");
      // setSelectedBrand(null);
      setUniqueDoses();
    }
    return tempValue.slice(0, MAX_SUGGESTED_RESULT);
  }, [genericSearchInput, doctorGenericList, genericList, selectedBrand]);

  const updateSuggestedBrand = (data, str) => {
    let tempBrandList = [];
    if (!str) {
      tempBrandList = data;
    } else {
      tempBrandList = getFilteredBrand(data, str);
    }

    let temp = tempBrandList;
    if (selectedBrand) {
      const alreadyTaken = tempBrandList?.filter(
        (item) => item.id === selectedBrand.id
      );
      const notAlreadyTaken = tempBrandList?.filter(
        (item) => item.id !== selectedBrand.id
      );
      temp = [...alreadyTaken, ...notAlreadyTaken];
    }

    setSuggestedBrand(temp?.slice(0, MAX_SUGGESTED_RESULT));
  };

  useEffect(() => {
    try {
      let filteredBrand = brandList;

      if (selectedGeneric) {
        filteredBrand = brandList?.filter(
          (item) => item.genericId === selectedGeneric.id
        );
      }

      updateSuggestedBrand(filteredBrand, brandInputString);
    } catch (err) {
      console.log(err);
    }
  }, [brandList, brandInputString, medicationList]);

  const initializeAllList = async () => {
    let doctorGenList = JSON.parse(
      await SecureIndexedDB.getItem("doctor_generic")
    );
    setDoctorGenericList(doctorGenList);
    let genList = JSON.parse(await SecureIndexedDB.getItem("generic"));
    setGenericList(genList);

    // await getBrands();
    // await getFrequencies();
    // await getDurations();
    // await getFormDosages();
    // await getRoutes();

    const brandProm = getBrands();
    const freqProm = getFrequencies();
    const durationProm = getDurations();
    const dosageProm = getFormDosages();
    const routesProm = getRoutes();
    await Promise.all([
      brandProm,
      freqProm,
      durationProm,
      dosageProm,
      routesProm,
    ]);

    setIsAllLoaded(true);
  };

  const initializePreviousMedicineList = async () => {
    let doctorGenList = JSON.parse(
      await SecureIndexedDB.getItem("doctor_generic")
    );
    setDoctorGenericList(doctorGenList);
    let genList = JSON.parse(await SecureIndexedDB.getItem("generic"));
    const bndList = brandList;

    const frqList = frequencyList;
    const dsgList = dosageList;

    if (extraData?.reqValue?.currentMedications) {
      const medicationList = extraData?.reqValue?.currentMedications?.map(
        (item) => {
          const { dosages, frequency, genericBrandId, type } = item;

          let selectedGeneric = null;
          if (type === "GENERIC") {
            selectedGeneric = genList?.find(
              (item) => item.id === genericBrandId
            );
            if (!selectedGeneric) {
              selectedGeneric = doctorGenList?.find(
                (item) => item.id === genericBrandId
              );
            }
            const selectedFrequency = frqList?.find(
              (item) => item.frequency === frequency
            );
            let dosage = "";
            if (selectedGeneric) {
              const { formId } = selectedGeneric;
              dosage = dsgList?.find(
                (item) => item.formId === formId && item.dosage === dosages
              );
            }

            const dto = {
              frequency: selectedFrequency || "",
              dosage,
              root: "",
              note: "",
              duration: "",
              timing: [],
              genericName: selectedGeneric || "",
              brandName: "",
            };
            return dto;
          } else if (type === "BRAND") {
            let selectedBrand = bndList?.find(
              (item) => item.id === genericBrandId
            );

            const selectedFrequency = frqList?.find(
              (item) => item.frequency === frequency
            );
            let dosage = "";
            if (selectedBrand) {
              const { formId, genericId } = selectedBrand;

              selectedGeneric = genList?.find((item) => item.id === genericId);
              if (!selectedGeneric) {
                selectedGeneric = doctorGenList?.find(
                  (item) => item.id === genericId
                );
              }

              dosage = dsgList?.find(
                (item) => item.formId === formId && item.dosage === dosages
              );
            }

            const dto = {
              frequency: selectedFrequency || "",
              dosage,
              root: "",
              note: "",
              duration: "",
              timing: [],
              genericName: selectedGeneric || "",
              brandName: selectedBrand || "",
            };
            return dto;
          } else {
            return [];
          }
        }
      );

      // setMedicationList(medicationList);
      setPrevMedicationList(medicationList);
    }
  };

  useEffect(() => {
    getTimingList();
    initializeAllList();
  }, []);

  useEffect(() => {
    if (isAllLoaded) {
      initializePreviousMedicineList();
    }
  }, [isAllLoaded]);

  useEffect(() => {
    if (data.medication) {
      setMedicationList(data.medication);
    }
  }, [data.medication]);

  const getFrequencies = async () => {
    const diseasetring = await SecureIndexedDB.getItem("frequencyList");
    if (diseasetring) {
      const tempFreq = JSON.parse(diseasetring);
      setFrequencyList(tempFreq);
      return tempFreq;
    }

    const reqDto = await getHealthDto();
    const response = await DashboardServices.getFrequencies(reqDto);
    if (response.data) {
      setFrequencyList(response.data);
      SecureIndexedDB.setItem("frequencyList", JSON.stringify(response.data));
      return response.data;
    }
  };

  const getDurations = async () => {
    const diseasetring = await SecureIndexedDB.getItem("durationList");
    if (diseasetring) {
      const tempDuration = JSON.parse(diseasetring);
      setDurationList(tempDuration);
      return tempDuration;
    }

    const reqDto = await getHealthDto();
    const response = await DashboardServices.getDurations(reqDto);
    if (response.data) {
      setDurationList(response.data);
      SecureIndexedDB.setItem("durationList", JSON.stringify(response.data));
      return response.data;
    }
  };

  const getFormDosages = async () => {
    const diseasetring = await SecureIndexedDB.getItem("dosageList");
    if (diseasetring) {
      const tempDosage = JSON.parse(diseasetring);
      setDosageList(tempDosage);
      return tempDosage;
    }

    const reqDto = await getHealthDto();
    const response = await DashboardServices.getFormDosages(reqDto);
    if (response.data) {
      setDosageList(response.data);
      SecureIndexedDB.setItem("dosageList", JSON.stringify(response.data));
      return response.data;
    }
  };

  // const getBrands = async () => {
  //   return new Promise((resolve, reject) => {
  //     staticDataSocket.emit("requestData", {
  //       orgId: loginUserDetail.orgId,
  //       countryCode: loginUserDetail.orgCountryCode,
  //     });
  //     staticDataSocket.on("responseData", async (obj) => {
  //
  //       if (obj && obj.brands && obj.brands.length > 0) {
  //         setBrandList(obj.brands);
  //         resolve(obj.brands);
  //       } else {
  //         const reqDto = await getHealthDto();
  //         DashboardServices.getBrands(reqDto).then((response) => {
  //           if (response.data) {
  //             setBrandList(response.data);
  //             resolve(response.data);
  //           }
  //         });
  //       }
  //     });
  //   });
  // };

  const getBrands = async () => {
    const diseasetring = await SecureIndexedDB.getItem("brandList");
    if (diseasetring) {
      const tempDosage = JSON.parse(diseasetring);
      setBrandList(tempDosage);
      return tempDosage;
    }

    const reqDto = await getHealthDto();
    const response = await DashboardServices.getBrands(reqDto);
    if (response.data) {
      setBrandList(response.data);
      SecureIndexedDB.setItem("brandList", JSON.stringify(response.data));
      return response.data;
    }
  };

  const getRoutes = async () => {
    const brandString = await SecureIndexedDB.getItem("routeList");
    if (brandString) {
      const tempRoute = JSON.parse(brandString);
      setRoutelist(tempRoute);
      return tempRoute;
    }

    const reqDto = await getHealthDto();
    const response = await DashboardServices.getRoutes(reqDto);
    if (response.data) {
      setRoutelist(response.data);
      SecureIndexedDB.setItem("routeList", JSON.stringify(response.data));
      return response.data;
    }
  };

  const handleChangemTiming = (event) => {
    const {
      target: { value },
    } = event;
    setMTiming(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeDosage = (event) => {
    setDosage(event.target.value);
  };

  const handleChangemForm = (event) => {
    setMForm(event.target.value);
  };

  const handleChangeFrequency = (event) => {
    setFrequency(event.target.value);
  };

  const handleChangeRegimen = (event) => {
    setRegimen(event.target.value);
  };

  const initializeForm = () => {
    setDosage("");
    setMForm("");
    setFrequency("");
    setRegimen("");
    setGenericSearchInput("");
    setSelectedGeneric(null);
    setMedicationNote("");
    setMTiming([]);
    setSelectedBrand(null);
    setBrandInputString("");
    setSuggestedDosage([]);
  };

  const setDosageAccordingToFormId = (formId) => {
    let dosageId = [];
    if (formId) {
      dosageId = dosageList?.filter((item) => item.formId === formId);
    } else {
      dosageId = dosageList?.filter((item) => item.formId === 1);
    }
    setSuggestedDosage(dosageId);
    // return dosageId;
  };

  const handleAddMedication = () => {
    if (!selectedBrand) {
      if (!selectedGeneric && !genericSearchInput?.trim()) {
        setGenericSearchInput("");
        toast.error(t("please_select_medicine_name"));
        return;
      }
    }
    if (!dosage || dosage === "") {
      toast.error(t("please_select_dosage"));
      return;
    }

    if (!mForm) {
      toast.error(t("please_select_route"));
      return;
    }

    if (!frequency || frequency === "") {
      toast.error(t("please_select_frequency"));
      return;
    }

    if (!regimen || regimen === "") {
      toast.error(t("please_select_duration"));
      return;
    }

    if (selectedBrand) {
      const alreadyAvailableBrand = medicationList?.find(
        (item) => item?.brandName?.id === selectedBrand.id
      );

      if (alreadyAvailableBrand) {
        confirmString.current = t(
          "this_brand_is_already_present_do_you_want_to_continue"
        );
        setShowConfirmation(true);

        return;
      }

      const alreadyAvailableGeneric = medicationList?.find(
        (item) => item?.brandName?.genericId === selectedBrand.genericId
      );
      if (alreadyAvailableGeneric) {
        confirmString.current = t(
          "another_brand_with_same_generic_name_is_already_added_do_you_want_to_continue"
        );

        setShowConfirmation(true);
        return;
      }
    } else {
      const alreadyAvailableGeneric = medicationList?.find(
        (item) => item?.genericName?.id === selectedGeneric.id
      );
      if (alreadyAvailableGeneric) {
        confirmString.current = t(
          "medicine_with_this_generic_name_already_present_do_you_want_to_continue"
        );

        setShowConfirmation(true);
        return;
      }
    }
    addMedicine();
  };
  const addMedicine = () => {
    const tempFreq = frequencyList.find((item) => item.id === frequency);
    const tempDuration = durationList.find((item) => item.id === regimen);

    // const isMedicineAlreadyAdded = medicationList?.some((item) => {
    //
    //   if (
    //     item?.genericName?.id === selectedGeneric?.id &&
    //     item?.dosage?.dosage === dosage.dosage &&
    //     item?.frequency?.id === tempFreq?.id
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });
    // if (isMedicineAlreadyAdded) {
    //   toast.error(t("medicine_with_same_dosage_and_frequency_already_added"));
    //   return;
    // }

    let genericName = selectedGeneric;
    if (!genericName) {
      genericName = {
        id: 0,
        generic: genericSearchInput,
        formId: -1,
        locallyAdded: true,
      };
    }

    const dto = {
      frequency: tempFreq,
      dosage,
      root: mForm,
      note: medicationNote,
      duration: tempDuration,
      timing: mTiming,
      genericName,
      brandName: selectedBrand,
    };

    setMedicationList((prev) => {
      const temp = [...prev, dto];
      updateData({ medication: temp });
      return temp;
    });
    initializeForm();
  };

  useEffect(() => {
    const str = medicationList
      ?.map((item) => {
        const generic = item.genericName?.generic || "";
        const dosage = item.dosage?.dosage ? `${item.dosage.dosage}` : "";
        const frequency = item.frequency?.frequency
          ? `${item.frequency.frequency}`
          : "";
        const duration = item.duration?.durationName
          ? `${item.duration.durationName}`
          : "";

        // Construct the string for each medication item, with hyphens only if the part exists
        const details = [dosage, frequency, duration]
          .filter(Boolean)
          .join(" - ");
        return `${generic}${details ? ` (${details})` : ""}`;
      })
      .join(", ");
    setAccordionString(str);
  }, [medicationList]);

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            {t("medication")}: {"  "}
            {/* <span>symptoms gose here when acordion not expend</span> */}
            {!accordionExpanded && <span>{accordionString}</span>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {extraData?.isDoctor && (
            <>
              <div className="complaintList mt10">
                <ul>
                  {prevMedicationList &&
                    prevMedicationList.map((item, index) => (
                      <li>
                        <div className="complstDtlGrp ">
                          <div className="complstDtl">
                            {item?.genericName?.generic && (
                              <div className="compntName ">
                                {item?.genericName?.generic ||
                                  item?.brandName?.brandName}
                              </div>
                            )}
                            <div className="compntName BrandMed">
                              {item.brandName && item.brandName.brandName}
                            </div>
                            <div className="compinfoGRP">
                              {item?.dosage && (
                                <div className="compStime">
                                  <span>{t("dosage")}:</span>
                                  <span className="data">
                                    {item.dosage.dosage}
                                  </span>
                                </div>
                              )}
                              {item?.frequency && (
                                <div className="compStime">
                                  <span>{t("frequency")}:</span>
                                  <span className="data">
                                    {item.frequency.frequency}
                                  </span>
                                </div>
                              )}

                              {item.duration && (
                                <div className="compStime">
                                  <span>{t("duration")}:</span>
                                  <span className="data">
                                    {item.duration.durationName}
                                  </span>
                                </div>
                              )}

                              {item.timing && item.timing.length !== 0 && (
                                <div className="compStime">
                                  <span>{t("time")}:</span>
                                  <span className="data">
                                    {item.timing.join(", ")}
                                  </span>
                                </div>
                              )}
                              {item.note && item.note !== "" && (
                                <div className="compStime">
                                  <span>{t("notes")}:</span>
                                  <span className="data">{item.note}</span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="d-flex">
                            <Tooltip arrow title={t("add_to_medicine_list")}>
                              <IconButton
                                // className="removeItemBtn"
                                className="editTimelineBtn"
                                onClick={() => {
                                  const genericItem =
                                    suggestedMedicineList?.find(
                                      (med) => med.id === item?.genericName?.id
                                    );

                                  if (genericItem) {
                                    setSelectedGeneric(genericItem || null);
                                    setGenericSearchInput(
                                      genericItem?.generic || ""
                                    );
                                  } else {
                                    setSelectedGeneric(
                                      item.genericName || null
                                    );
                                    setGenericSearchInput(
                                      item.genericName.generic || ""
                                    );
                                  }

                                  //brand

                                  if (item.brandName) {
                                    const brandItem = brandList?.find(
                                      (brand) =>
                                        brand.id === item?.brandName?.id
                                    );
                                    if (brandItem) {
                                      setSelectedBrand(brandItem);
                                      setBrandInputString(brandItem.brandName);
                                    }
                                  } else {
                                    setSelectedBrand(null);
                                    setBrandInputString("");
                                  }

                                  //Dosage

                                  setDosageAccordingToFormId(
                                    item?.dosage?.formId
                                  );

                                  if (item.dosage) {
                                    setDosage(item.dosage);
                                  }

                                  if (item.frequency) {
                                    setFrequency(item.frequency.id);
                                  }
                                }}
                              >
                                <Add />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="addNewformGrp">
                <div className="formElement">
                  <FormControl className="formControl">
                    <Autocomplete
                      // freeSolo
                      className="formAutocompleteField"
                      variant="outlined"
                      value={selectedGeneric}
                      options={suggestedMedicineList}
                      inputValue={genericSearchInput}
                      onChange={(e, newValue) => {
                        setSelectedGeneric(newValue);
                        if (newValue) {
                          const { id, formId, routeId } = newValue;
                          const route = routeList?.find(
                            (item) => item.id == routeId
                          );
                          setDosageAccordingToFormId(formId);
                          if (route) {
                            setMForm(route);
                          }
                          const tempBrand = brandList.filter(
                            (item) => item.genericId === id
                          );

                          if (tempBrand) {
                            setSuggestedBrand(
                              tempBrand.slice(0, MAX_SUGGESTED_RESULT)
                            );
                          } else {
                            setBrandInputString("");
                            setSelectedBrand(null);
                          }
                        } else {
                          setBrandInputString("");
                          setSelectedBrand(null);
                        }
                      }}
                      onInputChange={(e, value, reason) => {
                        setGenericSearchInput(value);
                        if (value === "" || !value) {
                          // setBrandInputString("");
                          updateSuggestedBrand(brandList, "");
                        }
                        if (reason === "clear") {
                          setSuggestedDosage([]);
                          setMForm("");
                        }
                      }}
                      getOptionLabel={(option) => option.generic}
                      renderOption={(props, item) => {
                        return (
                          <li {...props} key={item.id}>
                            {item.generic}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="formAutoComInputField autocompFildPlaceholder"
                          placeholder={t("search_generic_medicine")}
                          label={t("generic_name")}
                          required
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="addNewformGrp">
                <div className="formElement">
                  <FormControl className="formControl">
                    <Autocomplete
                      className="formAutocompleteField"
                      variant="outlined"
                      value={selectedBrand}
                      options={suggestedBrand}
                      inputValue={brandInputString}
                      onChange={(e, newValue) => {
                        setSelectedBrand(newValue);
                        if (newValue) {
                          const { genericId, formId } = newValue;
                          setDosageAccordingToFormId(formId);
                          let tempDoctorGeneric = doctorGenericList?.find(
                            (item) => item.id === genericId
                          );
                          if (tempDoctorGeneric) {
                            setSelectedGeneric(tempDoctorGeneric);
                            const { routeId } = tempDoctorGeneric;
                            const route = routeList?.find(
                              (item) => item.id == routeId
                            );
                            if (route) {
                              setMForm(route);
                            }
                          } else {
                            tempDoctorGeneric = genericList?.find(
                              (item) => item.id === genericId
                            );
                            if (tempDoctorGeneric) {
                              setSelectedGeneric(tempDoctorGeneric);
                            } else {
                              // toast.error(
                              //   t(
                              //     "this_brand_generic_name_is_not_present_in_the_table"
                              //   )
                              // );
                              setSelectedGeneric(null);
                              setGenericSearchInput("");
                            }
                          }
                        }
                      }}
                      onInputChange={(e, value) => {
                        setBrandInputString(value);
                      }}
                      getOptionLabel={(option) => option.brandName}
                      renderOption={(props, item) => {
                        return (
                          <li {...props} key={item.id}>
                            {item.brandName}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="formAutoComInputField autocompFildPlaceholder"
                          placeholder={t("search_by_brand")}
                          lable={t("branded_medicine")}
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="formElementGroup ">
                <div className="formElement minW90px">
                  <FormControl className="formControl">
                    <InputLabel id="severity-select-label">
                      {t("dosage") + "*"}
                    </InputLabel>
                    <Select
                      labelId="severity-select-label"
                      id="severity-select"
                      value={dosage}
                      label={t("dosage")}
                      onChange={handleChangeDosage}
                      className="formInputField"
                      variant="outlined"
                    >
                      {suggestedDosage &&
                        suggestedDosage.map((item) => {
                          return (
                            <MenuItem value={item}>{item.dosage} </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
                <div className="formElement minW150px">
                  <FormControl className="formControl">
                    <InputLabel id="Form-select-label">
                      {t("rout") + "*"}
                    </InputLabel>
                    <Select
                      labelId="Form-select-label"
                      id="Form-select"
                      value={mForm}
                      label={t("rout") + "*"}
                      onChange={handleChangemForm}
                      className="formInputField"
                      variant="outlined"
                    >
                      <MenuItem key={"selectRoute"} value={""}>
                        {t("select_route")}
                      </MenuItem>
                      {routeList &&
                        routeList.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item}>
                              {item.route}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
                <div className="formElement minW120px">
                  <FormControl className="formControl">
                    <InputLabel id="frequency-select-label">
                      {t("frequency") + "*"}
                    </InputLabel>
                    <Select
                      labelId="frequency-select-label"
                      id="frequency-select"
                      value={frequency}
                      label={t("frequency")}
                      onChange={handleChangeFrequency}
                      className="formInputField"
                      variant="outlined"
                    >
                      <MenuItem value={""}>--Select Frequency--</MenuItem>
                      {frequencyList &&
                        frequencyList.map((item) => {
                          return (
                            <MenuItem value={item.id}>
                              {item.frequency}{" "}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
                <div className="formElement minW110px">
                  <FormControl className="formControl">
                    <InputLabel id="regimen-select-label">
                      {t("duration") + "*"}
                    </InputLabel>
                    <Select
                      labelId="regimen-select-label"
                      id="regimen-select"
                      value={regimen}
                      label={t("duration")}
                      onChange={handleChangeRegimen}
                      className="formInputField"
                      variant="outlined"
                    >
                      <MenuItem value={""}>--Select Duration--</MenuItem>
                      {durationList &&
                        durationList.map((item) => {
                          return (
                            <MenuItem value={item.id}>
                              {item.durationName}{" "}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>

                {/* <div className="formElement mw212">
                  <FormControl className="formControl">
                    <InputLabel id="demo-multiple-checkbox-label">
                      {t("timing")}
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={mTiming}
                      onChange={handleChangemTiming}
                      input={<OutlinedInput label={t("timing")} />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      className="formInputField"
                      variant="outlined"
                    >
                      {timingList?.map((time) => (
                        <MenuItem key={time} value={time}>
                          <Checkbox checked={mTiming.indexOf(time) > -1} />
                          <ListItemText primary={time} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div> */}
                <div className="formElement mw212 minW150px">
                  <FormControl className="formControl">
                    <InputLabel id="demo-multiple-checkbox-label">
                      {t("timing")}
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={mTiming}
                      onChange={handleChangemTiming}
                      input={<OutlinedInput label={t("timing")} />}
                      renderValue={(selected) =>
                        selected.map((item) => t(item)).join(", ")
                      }
                      MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                      className="formInputField"
                      variant="outlined"
                    >
                      {timingList.map((key) => (
                        <MenuItem key={key} value={key}>
                          <Checkbox checked={mTiming.indexOf(key) > -1} />
                          <ListItemText primary={key} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="addNewformGrp">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      // hiddenLabel
                      label={t("notes")}
                      autoComplete="off"
                      variant="outlined"
                      value={medicationNote}
                      onChange={(e) => {
                        setMedicationNote(e.target.value);
                      }}
                      className="formTextFieldArea"
                    />
                  </FormControl>
                </div>

                <Button
                  className="dfultPrimaryBtn "
                  startIcon={<Add />}
                  onClick={handleAddMedication}
                >
                  {t("add")}
                </Button>
              </div>
              <div className="bb"></div>
            </>
          )}

          {medicationList?.length > 0 && (
            <>
              <div className="complaintList mt10">
                <ul>
                  {medicationList?.map((item, index) => {
                    console.log("medication =", item);
                    return (
                      <>
                        <li>
                          <div className="complstDtlGrp ">
                            <div className="complstDtl">
                              {item?.genericName?.generic && (
                                <div className="compntName ">
                                  {item?.genericName?.generic ||
                                    item?.brandName?.brandName}
                                </div>
                              )}
                              <div className="compntName BrandMed">
                                {item?.brandName && item?.brandName?.brandName}
                              </div>
                              <div className="compinfoGRP">
                                {item?.dosage && (
                                  <div className="compStime">
                                    <span>{t("dosage")}:</span>
                                    <span className="data">
                                      {item?.dosage?.dosage}
                                    </span>
                                  </div>
                                )}

                                {item?.root && (
                                  <div className="compStime">
                                    <span>{t("rout")}:</span>
                                    <span className="data">
                                      {item.root.route}
                                    </span>
                                  </div>
                                )}
                                {item?.frequency && (
                                  <div className="compStime">
                                    <span>{t("frequency")}:</span>
                                    <span className="data">
                                      {item?.frequency?.frequency}
                                    </span>
                                  </div>
                                )}

                                {item.duration && (
                                  <div className="compStime">
                                    <span>{t("duration")}:</span>
                                    <span className="data">
                                      {item?.duration?.durationName}
                                    </span>
                                  </div>
                                )}

                                {item.timing && item.timing.length !== 0 && (
                                  <div className="compStime">
                                    <span>{t("time")}:</span>
                                    <span className="data">
                                      {item?.timing?.join(", ")}
                                    </span>
                                  </div>
                                )}
                                {item.note && item.note !== "" && (
                                  <div className="compStime">
                                    <span>{t("notes")}:</span>
                                    <span className="data">{item.note}</span>
                                  </div>
                                )}
                              </div>
                            </div>

                            {extraData?.isDoctor && (
                              <IconButton
                                className="removeItemBtn"
                                onClick={() => {
                                  const temp = [...medicationList];
                                  temp.splice(index, 1);
                                  updateData({ medication: temp });
                                  setMedicationList(temp);
                                }}
                              >
                                <Trash />
                              </IconButton>
                            )}
                          </div>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </div>
              <div className="bb"></div>
            </>
          )}

          {extraData?.isDoctor && (
            <>
              <div className="ddBtnGroup flxSPBTW">
                <div className="buttonwithWarning">
                  <Button
                    className="differnDiagnosBtn"
                    startIcon={<HealthAndSafety />}
                    onClick={() => setShowDrugSafety(true)}
                  >
                    {t("drug_safety_check")}
                  </Button>
                  <div className="warnigMsg">
                    {t(
                      "these_features_are_experimental_it_would_be_best_if_you_reviewed_and_recommended_any_necessary_changes_the_data_has_been_generated_using_ai"
                    )}
                  </div>
                </div>
                {/* {isLocalHost() && (
                )} */}
                <div className="buttonwithWarning alignItemFlexEnd minW150px">
                  <Button
                    className="differnDiagnosBtn"
                    startIcon={<IntegrationInstructions />}
                    onClick={() => {
                      if (
                        !data?.diagonesis?.length > 0 &&
                        !data?.possibleDiagonesis?.length > 0
                      ) {
                        toast.error("please_select_disease");
                        return;
                      }

                      setShowSTP(true);
                    }}
                  >
                    {t("view_stp")}
                  </Button>
                </div>
              </div>
            </>
          )}
        </AccordionDetails>
      </Accordion>
      {showUpdateModule && (
        <CustomModal
          modalTitle={t("edit_medication")}
          closeModal={() => {
            setShowUpdateModule(false);
          }}
          // className={"width90"}
        >
          <MedicationBody
            handleEditData={(data) => {
              const temp = [...medicationList];
              temp[editIndex.current] = { ...data };
              setMedicationList(temp);
              editData.current = null;
              editIndex.current = null;
              setShowUpdateModule(false);
            }}
            data={editData.current}
          />
        </CustomModal>
      )}

      {showConfirm && (
        <CustomModal
          modalTitle={confirmString.current}
          // style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowConfirmation(false);
          }}
          className={"width90"}
        >
          <div></div>

          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => {
                addMedicine();
                setShowConfirmation(false);
              }}
            >
              {t("continue")}
            </Button>
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                setShowConfirmation(false);
              }}
            >
              {t("cancel")}
            </Button>
          </div>
        </CustomModal>
      )}
    </>
  );
}
