import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

class ZhPatientService {
  getPatDtlsByFaceData(reqDto) {
    return http.put(`zhPatientReg/getPatDtlsByFaceData`, reqDto, {
      headers: authHeader(),
    });
  }

  registerPatient(reqDto, creatorUserId) {
    return http.post(`/zhPatientReg/registerPatient/${creatorUserId}`, reqDto, {
      headers: authHeader(),
    });
  }

  updatePatientData(reqDto, creatorUserId) {
    return http.put(
      `/zhPatientReg/updatePatientData/${creatorUserId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  getPatListInUnitByDate(fromDate, toDate, unitId, orgId) {
    return http.get(
      `/zhPatientReg/getPatListInUnitByDate/${fromDate}/${toDate}/${unitId}/${orgId}`,
      {
        headers: authHeader(),
      }
    );
  }

  updatePatientDataLastAccessTime(rowPatientId) {
    return http.put(
      `zhPatientReg/updatePatientDataLastAccessTime/${rowPatientId}`,
      {
        headers: authHeader(),
      }
    );
  }
  presigned(reqDto) {
    return http.put(`misc/generatePreSignedUrlS3ObjectV2`, reqDto, {
      headers: authHeader(),
    });
  }

  bookAppointmentFromUnit(userId, reqDto) {
    return http.post(
      `zhBookAppointment/bookAppointmentFromUnit/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  cancelAppointment(slotId, patientId, userId, reqDto) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };

    return http.put(
      `zhBookAppointment/cancelAppointment/${slotId}/${patientId}/${userId}`,
      reqDto,
      config
      // {
      //   headers: authHeader(),
      // }
    );
  }

  rescheduleAppointment(fromSlotId, toSlotId, patientId, userId, reqDto) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };

    return http.put(
      `zhBookAppointment/rescheduleAppointment/${fromSlotId}/${toSlotId}/${patientId}/${userId}`,
      reqDto,
      config
    );
  }

  getPatDtlsByRowPatientId(reqDto) {
    return http.put(`zhPatientReg/getPatDtlsByRowPatientId`, reqDto, {
      headers: authHeader(),
    });
  }

  logRX(reqDto) {
    return http.post(`zhRx/logRX`, reqDto, {
      headers: authHeader(),
    });
  }

  getRX(visitId) {
    return http.get(`zhRx/getRX/${visitId}`, {
      headers: authHeader(),
    });
  }

  getUnitTestIds(visitId) {
    return http.get(`zoyelHealthapi/getUnitTestIds/${visitId}`, {
      headers: authHeader(),
    });
  }

  getOrgTestParametersWithReferanceRange(visitId) {
    return http.get(
      `zoyelHealthapi/getOrgTestParametersWithReferanceRange/${visitId}`,
      {
        headers: authHeader(),
      }
    );
  }

  getTestParameters(reqDto) {
    return http.post(`zhTestController/getTestParameters`, reqDto, {
      headers: authHeader(),
    });
  }

  submitTestResultWithoutRequestion(userId, reqDto) {
    return http.post(
      `zhTestController/submitTestResultWithoutRequestion/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  getTestResultsExceptRequisitionIds(patientId, reqDto) {
    return http.post(
      `zhTestController/getTestResultsExceptRequisitionIds/${patientId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  getTestGivenByVisitId(visitId, unitId) {
    return http.get(
      `zhTestController/getTestGivenByVisitId/${visitId}/${unitId}`,
      {
        headers: authHeader(),
      }
    );
  }

  getPatientPrescriptionSuggested(patientId) {
    return http.get(
      `zhTestController/getPatientPrescriptionSuggested/${patientId}`,
      {
        headers: authHeader(),
      }
    );
  }

  connectDoctorAfterTest(visitId) {
    return http.put(
      `zhPatientVisit/connectDoctorAfterTest/${visitId}`,

      {
        headers: authHeader(),
      }
    );
  }
  getDocumentsInConsultationOnRefresh(visitId) {
    return http.get(
      `zhPatientVisit/getDocumentsInConsultationOnRefresh/${visitId}`,

      {
        headers: authHeader(),
      }
    );
  }
  assignDoctorToNDQueue(visitId, reqDto) {
    return http.put(
      `zhPatientVisit/assignDoctorToNDQueue/${visitId}`,
      reqDto,

      {
        headers: authHeader(),
      }
    );
  }

  completeRegBookAppointment(reqDto, userId) {
    return http.post(
      `zhPatientReg/completeRegBookAppointment/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  updateBookedAppForFaceAlreadyPresent(prevPatientId, newPatientId, slotId) {
    return http.put(
      `zhBookAppointment/updateBookedAppForFaceAlreadyPresent/${prevPatientId}/${newPatientId}/${slotId}`,
      {
        headers: authHeader(),
      }
    );
  }

  uploadDocumentsInVC(visitId, patientId, unitId, reqDto) {
    return http.post(
      `zhPatientVisit/uploadDocumentsInVC/${visitId}/${patientId}/${unitId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  sendMessage(visitId, reqDto) {
    return http.post(`zhPatientVisit/sendMessage/${visitId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getAgeForSTP(reqDto) {
    return http.post(`zoyelHealthapi/getAgeForStp`, reqDto, {
      headers: authHeader(),
    });
  }
  saveParametersForTest(testId, reqDto) {
    return http.post(`zoyelHealthapi/saveParametersForTest/${testId}`, reqDto, {
      headers: authHeader(),
    });
  }
  addParameter(orgId, countryCode, reqDto) {
    return http.post(
      `zoyelHealthapi/addParameter/${orgId}/${countryCode}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }
  getMessages(visitId) {
    return http.get(`zhPatientVisit/getMessages/${visitId}`, {
      headers: authHeader(),
    });
  }

  submitStp(userId, reqDto) {
    return http.post(`zhStp/submitStp/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }
  getCompounds(orgId, reqDto) {
    return http.post(`zhStp/getCompounds/${orgId}`, reqDto, {
      headers: authHeader(),
    });
  }
  getDDOfRouteFormDosagesOfCompound(compoundId) {
    return http.post(`zhStp/getDDOfRouteFormDosagesOfCompound/${compoundId}`, {
      headers: authHeader(),
    });
  }
  deactivatStp(userId, stpId) {
    return http.post(`zhStp/deactivatStp/${userId}/${stpId}`, {
      headers: authHeader(),
    });
  }
  getCompoundsOfRegime(reqDto) {
    return http.post(`zhStp/getCompoundsOfRegime`, reqDto, {
      headers: authHeader(),
    });
  }

  getStpList(orgId) {
    return http.get(`zhStp/getStpList/${orgId}`, {
      headers: authHeader(),
    });
  }
  getStpbyId(stpId) {
    return http.post(`zhStp/getStpbyId/${stpId}`, {
      headers: authHeader(),
    });
  }
  getStp(reqDto) {
    return http.post(`zhStp/getStp`, reqDto, {
      headers: authHeader(),
    });
  }
  getGenericFromStp(reqDto) {
    return http.post(`zhStp/getGenericFromStp`, reqDto, {
      headers: authHeader(),
    });
  }

  getMassaludPatientDataByPatientId(patientId) {
    return http.get(`clientApiController/massalud/getPatDetails/${patientId}`, {
      headers: authHeader(),
    });
  }

  getPatDtlsByExternalSourcePatientId(reqDto) {
    return http.put(
      `zhPatientReg/getPatDtlsByExternalSourcePatientId`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }
  getPatientListForBookAppointment(orgId, reqDto) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.post(
      `zhPatientReg/getPatientListForBookAppointment/${orgId}`,
      reqDto,
      config
    );
  }

  getOrgTestList(reqDto) {
    return http.post(`zoyelHealthapi/getOrgTestList`, reqDto, {
      headers: authHeader(),
    });
  }
  getParameterMaster(reqDto) {
    return http.post(`zoyelHealthapi/getParameterMaster`, reqDto, {
      headers: authHeader(),
    });
  }
  editParameter(orgId, countryCode, reqDto) {
    return http.put(
      `zoyelHealthapi/editParameter/${orgId}/${countryCode}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }
  editTest(orgId, countryCode, reqDto) {
    return http.put(`zoyelHealthapi/editTest/${orgId}/${countryCode}`, reqDto, {
      headers: authHeader(),
    });
  }

  getPatientPreviousPrescriptions(patientId, visitId) {
    return http.get(
      `zhPatientDocument/getPatientPreviousPrescriptions/${patientId}/${visitId}`,
      {
        headers: authHeader(),
      }
    );
  }
}
const zhPatientService = new ZhPatientService();
export default zhPatientService;
