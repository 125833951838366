import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import SupportService from "../../../../services/SupportService";
import { GetLoginUserDetails } from "../../../../utils/Utils";

export default function ForwardSupportModal({
  data,
  // userList,
  handleClose,
  refreshData,
  clearStoreData,
}) {
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language === "es" ? "es" : "en";
  const loginUserDetail = GetLoginUserDetails();
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [assigneeList, setAssigneeList] = useState([]);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [completion, setCompletion] = useState(new Date());
  const [compTime, setCompTime] = useState("20:00");
  const [timeSlots, setTimeSlots] = useState([]);
  const [userList, setUserList] = useState([]);

  const getFilteredData = () => {
    const taskCreator = data.assignByUserId;

    const filteredData = userList.filter((item) => {
      const isCreatorOrUser =
        item.userId !== loginUserDetail.userId && item.userId !== taskCreator;
      const isAlreadyAssigned = assigneeList.some((assignee) => {
        return assignee.userId === item.userId;
      });

      return isCreatorOrUser && !isAlreadyAssigned;
    });
    console.log("filtered data=", filteredData);
    return filteredData;
  };

  function generateTimeSlots() {
    const slots = [];
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0); // Set start time to midnight

    for (let i = 0; i < 48; i++) {
      // 48 slots for 30-minute intervals in a day
      const currentSlot = new Date(startDate.getTime() + i * 30 * 60 * 1000);
      const hours = currentSlot.getHours().toString().padStart(2, "0");
      const minutes = currentSlot.getMinutes().toString().padStart(2, "0");
      const timeString = `${hours}:${minutes}`;
      slots.push(timeString);
    }

    return slots;
  }

  const shouldDisable = (complTime, time) => {
    const now = new Date();
    const [hours, minutes] = time.split(":").map(Number);
    complTime.setHours(hours, minutes, 0, 0);
    if (now - complTime > 0) {
      return true;
    }
    return false;
  };

  const filteredUsers = useMemo(getFilteredData, [
    data,
    userList,
    assigneeList,
    loginUserDetail.userId,
  ]);

  const computedList = useMemo(() => {
    let filtered = [];
    if (
      autocompleteInputValue.length > 0 &&
      autocompleteInputValue.trim() !== ""
    ) {
      filtered =
        filteredUsers?.filter((option) =>
          option?.userName
            ?.toLowerCase()
            ?.includes(autocompleteInputValue?.toLowerCase())
        ) || [];
    }

    return filtered;
  }, [autocompleteInputValue, userList]);

  const handleChangeAutoCompleteInputValue = (event, newInputValue) => {
    setAutocompleteInputValue(newInputValue);
  };

  const selectCompTime = (event) => {
    setCompTime(event.target.value);
  };

  const getMiddleTime = (endTime) => {
    let givenTime = new Date(endTime);

    // Current time
    let currentTime = new Date();

    let givenTimestamp = givenTime.getTime();
    let currentTimestamp = currentTime.getTime();

    const midTime = parseInt((givenTimestamp + currentTimestamp) / 2);
    const midtimeObj = new Date(midTime);
    setCompletion(midtimeObj);

    let hours = midtimeObj.getHours();
    let minutes = midtimeObj.getMinutes();

    if (minutes < 15) {
      minutes = 0;
    } else if (minutes < 45) {
      minutes = 30;
    } else {
      minutes = 0;
      hours += 1;
    }

    if (hours === 24) hours = 0;

    const formattedTimeSlot = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;
    setCompTime(formattedTimeSlot);
  };

  useEffect(() => {
    // DashboardServices.getAssigneesOfTask(data.taskId).then((response) => {
    //   setAssigneeList(response.data);
    // });

    getMiddleTime(data.completionTime);

    SupportService.getUsersForTicketForwarding(data.taskId).then((response) => {
      setAssigneeList(response.data);
      setUserList(response.data);
    });
    setTimeSlots(generateTimeSlots());
  }, []);
  return (
    <>
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">{t("forward")}</div>

          <div className="elementFormContainer customCalender">
            <div className="formElement">
              <FormControl className="formControl">
                {/* <InputLabel id="adduser">Add User</InputLabel> */}
                <Autocomplete
                  id="adduser-select"
                  className="formAutocompleteField"
                  variant="outlined"
                  noOptionsText={t("please_type_recipient_name")}
                  value={selectedUsers}
                  options={assigneeList}
                  getOptionLabel={(option) => option.fieldName}
                  inputValue={autocompleteInputValue}
                  onChange={(e, newValue) => {
                    console.log("new value = ", newValue);
                    setSelectedUsers(newValue);
                  }}
                  onInputChange={handleChangeAutoCompleteInputValue}
                  // getOptionSelected={(option) => {
                  //   return option.fieldName;
                  // }}
                  renderOption={(props, option) => {
                    const userDtlStr = option.userDtl
                      ? `(${option.userDtl})`
                      : "";
                    return (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {`${option.fieldName} ${userDtlStr}`}
                      </Box>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="modelformAutocompleteField"
                      placeholder={t("assign_recipient")}
                      required
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  className="modelTextFild"
                  label={t("comments")}
                  variant="outlined"
                  multiline
                  rows={3}
                  maxRows={7}
                  value={taskDescription}
                  onChange={(e) => {
                    setTaskDescription(e.target.value);
                  }}
                  autoComplete="off"
                />
              </FormControl>
            </div>

            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel
                    id="setCompletionDate"
                    className="setCompletionDate"
                  >
                    {t("completion_date")}
                  </InputLabel>
                  <ReactDatePicker
                    locale={currentLocale}
                    showIcon
                    labelId="setCompletionDate"
                    className="formDatePicker"
                    selected={completion}
                    minDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      console.log("new date = ", date);
                      setCompletion(date);
                    }}
                  />
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="compTime">{t("completion_time")}</InputLabel>
                  <Select
                    className="modelSelectFild"
                    variant="outlined"
                    labelId="compTime"
                    id="compTime-select"
                    value={compTime}
                    label={t("completion_time")}
                    onChange={selectCompTime}
                  >
                    {timeSlots &&
                      timeSlots.map((time) => (
                        <MenuItem
                          value={time}
                          disabled={shouldDisable(completion, time)}
                        >
                          {time}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>

          <div className="modActionBtnGrp">
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                // handleSubmitTransferTask();
                let tempPostPoneTill = completion;
                const [hours, minutes] = compTime.split(":");
                tempPostPoneTill.setHours(hours, minutes, 0, 0);
                if (!selectedUsers) {
                  toast.error(t("please_select_user"));
                  return;
                }

                const reqDto = {
                  forwardToUserId: selectedUsers.fieldId || "",
                  forwardToPkId: selectedUsers.pkId || "",
                  scheduleResolveTime: tempPostPoneTill.toISOString(),
                  taskId: data.taskId,
                  rmks: taskDescription || "",
                  forwardByUserName: loginUserDetail.userName,
                  forwardByOrgName: loginUserDetail.orgName,
                  forwardByOrgId: loginUserDetail.orgId,
                  taskName: data.taskName || "",
                  taskDescription: data.taskDescription || "",
                  priority: data.priority || 0,
                };
                console.log("reqDto =", reqDto);
                // return;

                SupportService.forwardSupportTicket(reqDto).then((response) => {
                  console.log("response data =", response.data);
                  if (response.data == "1") {
                    toast.success(t("support_ticket_forwarded_successfully"));
                    refreshData();
                    clearStoreData();
                    handleClose();
                  } else {
                    toast.error(t("support ticket could not be forwarded"));
                  }
                });
              }}
            >
              {t("submit")}
            </Button>
            <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
              {t("cancel")}
            </Button>
          </div>
        </div>
        {loading && (
          <div className="elementFormContainer">
            <CircularProgress />
          </div>
        )}
      </Box>
    </>
  );
}
