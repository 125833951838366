import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Title,
  Filler,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import { Bar, Chart, Doughnut, Line, Pie } from "react-chartjs-2";
import { Button, IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
// import { getDashboardDataOfItem } from "../../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
// import faker from "faker";
import { useTranslation } from "react-i18next";
import { refreshContract } from "../../../../../services/AdminService";
import { ArrowRepeat } from "react-bootstrap-icons";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../../common/TourProvider";

export default function CardContract({
  handelOpenAddContract,
  chartDataOfItem,
  handelOpenViewContract,
  menuModuleList,
  userMenuList,
}) {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Title,
    Filler,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );

  const userDetails = GetLoginUserDetails();
  const [chartDataOfContract, setchartDataOfContract] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  useEffect(() => {
    setchartDataOfContract(chartDataOfItem || {});
  }, [chartDataOfItem]);

  useEffect(() => {
    if (!isFirstLoad){
    fetchEmplyCardData();
    }
  }, []);

  const fetchEmplyCardData = () => {
    const reqDto = {
      unitId: userDetails.signleUnitId,
      orgId: userDetails.orgId,
      userId: userDetails.userId,
      periodDailyMnthly: "",
      dataLimit: 0,
      feedbackFromPatientDoctor: "",
      fromDate: "",
      toDate: "",
      unitDoctor: "",
      contractOrgId: userDetails.orgId,
    };
    refreshContract(reqDto).then((response) => {
      console.log("response", response.data);
      setchartDataOfContract(response.data);
    });
  };

  console.log("menuModuleList", menuModuleList);
  const { t } = useTranslation();

  const typeWiseContractData = chartDataOfContract.typeWiseContract || [];

  const labels = typeWiseContractData.map((item) => item.label);
  const counts = typeWiseContractData.map((item) => item.count);

  const data = {
    labels: labels,
    datasets: [
      {
        data: counts,
        backgroundColor: ["#9B59B6", "#F39C12", "#00C2D7", "#FF6B6B"],
        hoverBackgroundColor: ["#9B59B6", "#F39C12", "#00C2D7", "#FF6B6B"],
        borderWidth: 0,
        type: "pie",
      },
    ],
  };

  //  const data = {
  //   labels: ["january", "February"],
  //       datasets: [
  //         {
  //           // label: ["january", "February"],
  //           // label:"Total Contract",
  //           data: [20 , 30],
  //           // data : [chartDataOfContract.totalContract],
  //           backgroundColor: ["#9B59B6", "#F39C12", "#00C2D7", "#FF6B6B"],
  //           hoverBackgroundColor: ["#9B59B6", "#F39C12", "#00C2D7", "#FF6B6B"],
  //           borderWidth: 0,
  //           type: "pie",
  //         },
  //       ],
  //     };

  const options = {
    cutout: "80%",

    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  // curent People status bar and line chart combo start

  const optionsLeave = {
    responsive: true,
    borderRadius: 4,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 12,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
      title: {
        display: false,
        text: "FYQ 2nd",
      },
    },
  };

  // const labels = ["January", "February", "March"];

  // const dataLeave = {
  //   labels,
  //   datasets: [
  //     {
  //       label: "contract",
  //       data: [400, 500, 340],
  //       backgroundColor: "rgba(0, 194, 215, 0.8)",
  //       type: "bar",
  //       barThickness: 30,
  //     },
  //   ],
  // };

  const mnthWiseContractData = chartDataOfContract.mnthWiseContract || [];

  const label = mnthWiseContractData.map((item) => item.label);
  const count = mnthWiseContractData.map((item) => item.count);

  const dataLeave = {
    labels: label,
    datasets: [
      {
        label: t("contracts"),
        data: count,
        backgroundColor: "rgba(0, 194, 215, 0.8)",
        type: "bar",
        barThickness: 16,
      },
    ],
  };


  useEffect(() => {
  
    setBookSteps([
      {
        target: ".cardContractStepOne",
        content: t("card_contract_step_one"),
        disableBeacon: true,
      },
      {
        target: ".cardContractStepTwo",
        content: t("card_contract_step_two"),
        disableBeacon: true,
      },
      {
        target: ".cardContractStepThree",
        content: t("card_contract_step_three"),
        disableBeacon: true,
      },
      {
        target: ".cardContractStepFour",
        content: t("card_contract_step_four"),
        disableBeacon: true,
      },
      {
        target: ".cardContractStepFive",
        content: t("card_contract_step_five"),
        disableBeacon: true,
      },
      {
        target: ".cardContractStepSix",
        content: t("card_contract_step_six"),
        disableBeacon: true,
      },
      {
        target: ".cardContractStepSeven",
        content: t("card_contract_step_seven"),
        disableBeacon: true,
      },
    ]);
  }, []);

  // useEffect(()=>{
  // setBookSteps([
  //   {
  //     target: '.cardContractStepOne',
  //     content: 'This section displays the contract overview.',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.cardContractStepTwo',
  //     content: 'Click here to refresh the contract data.',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.cardContractStepThree',
  //     content: 'This pie chart shows the contract distribution by type.',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.cardContractStepFour',
  //     content: 'This is the total number of contracts.',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.cardContractStepFive',
  //     content: 'This bar chart represents monthly contract data.',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.cardContractStepSix',
  //     content: 'Click here to view more details about the contract.',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.cardContractStepSeven',
  //     content: 'Click here to add a new contract.',
  //     disableBeacon: true,
  //   },
  // ]
  // )
  // },[])

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["cardContractCDS"] === true}
        continuous={true}
        showSkipButton={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        styles={{
          overlay: {
            height: "100vh",
          },
        }}
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
      />
      <div className="anDashIndCard cardContractStepOne">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">
              {t("contract")}
              <IconButton
                className={`refreshIcon cardContractStepTwo`}
                onClick={()=>{setIsFirstLoad(false); fetchEmplyCardData();}}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount">
              {/* <span title="Attrition">
                Salary: <span className="fbold">42%</span>
              </span>

              <span title="Tenure">
                FYQ: <span className="fbold">2nd</span>
              </span> */}
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf cardContractStepThree">
              <div className="centTxtShow">
                <Pie
                  className="hw100"
                  type="doughnut"
                  data={data}
                  options={options}
                />
                <div className="highlighted-text cardContractStepFour">
                  {/* <span>Total</span> */}
                  <span className="highDataCount">
                    {chartDataOfContract.totalContract}
                  </span>
                </div>
              </div>
            </div>
            <div className="anDRightGrf cardContractStepFive">
              <Bar
                className="hw100"
                type="bar"
                data={dataLeave}
                options={optionsLeave}
              />
              {/* <Line
                className="hw100"
                type="line"
                data={dataLeave}
                options={optionsLeave}
              /> */}
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>
          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find((item) => item.menuName === "Contract View")
                  .menuId
              ) && (
                <Button
                  className="dfultPrimaryBtn cardContractStepSix"
                  onClick={() => {handelOpenViewContract();
                    if (run["cardContractCDS"] === true) {
                      setRun({
                        ViewContractCDS: true,
                      });
                    }
                  }}
                >
                  {t("view")}
                </Button>
              )}

            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find((item) => item.menuName === "Contract Add")
                  .menuId
              ) && (
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn cardContractStepSeven"
                  onClick={() => {handelOpenAddContract();
                    if (run["cardContractCDS"] === true) {
                      setRun({
                        addContractCDS: true,
                      });
                    }
                  }}
                >
                  {t("add")}
                </Button>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
