import { Add } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Trash } from "react-bootstrap-icons";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import {
  getPmsConfig,
  submitPmsConfig,
} from "../../../../services/AdminService";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";
import Joyride from "react-joyride";
import { TourContext } from "../../../../common/TourProvider";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function DefinePMS() {
  const loginUser = GetLoginUserDetails();
  const { t, i18n } = useTranslation();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [oldDataObject, setOldDataObject] = useState(null);
  const [pmsFromDate, setPmsFromDate] = useState(new Date());
  const [pmsToDate, setPmsToDate] = useState(new Date());
  const [isEnabled, setIsEnabled] = useState(true);
  const [maxRating, setMaxRating] = useState(5);
  const [selectedMetricsList, setSelectedMetricsList] = useState([]);

  const [isAnyUpdate, setIsAnyUpdate] = useState(false);

  useEffect(() => {
    _fetchData();
  }, []);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const _fetchData = async () => {
    const response = await getPmsConfig(loginUser.orgId, loginUser.userId);
    if (response && response.data != null) {
      setOldDataObject(response.data);
      setPmsFromDate(_convertToDateFromMMMM(response.data.cycleStartDayMnth));
      setPmsToDate(_convertToDateFromMMMM(response.data.cycleEndDayMnth));
      setIsEnabled(response.data.activeFlag === "Y");
      setSelectedMetricsList(response.data.metrics);
      if (response.data.metrics && response.data.metrics.length > 0) {
        setMaxRating(response.data.metrics[0].maxRating);
      }
      setTimeout(() => setIsAnyUpdate(false), 500);
    }
  };

  const addNewMetric = () => {
    const newMetric = {
      metrics: "",
      metricsType: "",
      maxRating: 0,
      metricsId: new Date().valueOf(),
      isNew: true,
    };
    setSelectedMetricsList([...selectedMetricsList, newMetric]);
  };

  const handleNameChange = (e, index) => {
    const tempList = [...selectedMetricsList];
    tempList[index].metrics = e.target.value;
    setSelectedMetricsList(tempList);
  };

  const handleChangeMetricType = (e, index) => {
    const value = e.target.value;
    const tempList = [...selectedMetricsList];
    tempList[index].metricsType = value;
    if (value === "rating" || value === "both") {
      tempList[index].maxRating = 5;
    } else {
      tempList[index].maxRating = 0;
    }
    setSelectedMetricsList(tempList);
  };

  const deleteMetric = (index) => {
    const tempList = [...selectedMetricsList];
    tempList.splice(index, 1);
    setSelectedMetricsList(tempList);
  };

  useEffect(() => {
    if (
      oldDataObject &&
      oldDataObject.cycleStartDayMnth !== _convertToMMMMFromDate(pmsFromDate)
    ) {
      setIsAnyUpdate(true);
    } else {
      setIsAnyUpdate(false);
    }
  }, [pmsFromDate]);

  useEffect(() => {
    if (
      oldDataObject &&
      oldDataObject.cycleEndDayMnth !== _convertToMMMMFromDate(pmsToDate)
    ) {
      setIsAnyUpdate(true);
    } else {
      setIsAnyUpdate(false);
    }
  }, [pmsToDate]);

  useEffect(() => {
    if (oldDataObject && (oldDataObject.activeFlag === "Y") !== isEnabled) {
      setIsAnyUpdate(true);
    } else {
      setIsAnyUpdate(false);
    }
  }, [isEnabled]);

  useEffect(() => {
    if (
      selectedMetricsList &&
      selectedMetricsList.length > 0 &&
      selectedMetricsList[0].maxRating !== maxRating
    ) {
      setIsAnyUpdate(true);
    } else {
      setIsAnyUpdate(false);
    }
  }, [maxRating]);

  useEffect(() => {
    if (oldDataObject != null && selectedMetricsList) {
      if (!_deepArrayEqual(...oldDataObject.metrics, selectedMetricsList)) {
        setIsAnyUpdate(false);
      } else {
        setIsAnyUpdate(true);
      }
    }
  }, [selectedMetricsList]);

  // useEffect(()=>{
  // setBookSteps([
  //   {
  //     target: '.viewPMSStepOne',
  //     content: 'Welcome to the Performance Management System Setup',
  //     placement: 'bottom',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewPMSStepTwo',
  //     content: 'Select the start date for the PMS cycle.',
  //     placement: 'right',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewPMSStepThree',
  //     content: 'Select the end date for the PMS cycle.',
  //     placement: 'right',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewPMSStepFour',
  //     content: 'Use this toggle to enable or disable the PMS system.',
  //     placement: 'top',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewPMSStepFive',
  //     content: 'Set the maximum rating for the PMS cycle.',
  //     placement: 'bottom',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewPMSStepSix',
  //     content: 'Add evaluation metrics for the PMS cycle.',
  //     placement: 'left',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewPMSStepSixSeven',
  //     content: 'Fill all the Details ',
  //     placement: 'left',
  //     disableBeacon: true,
  //   }
  // ])
  // },[])

  useEffect(() => {
    setBookSteps([
      {
        target: ".viewPMSStepOne",
        content: t('view_pms_step_one'),
        // placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".viewPMSStepTwo",
        content: t('view_pms_step_two'),
        // placement: "right",
        disableBeacon: true,
      },
      {
        target: ".viewPMSStepThree",
        content: t('view_pms_step_three'),
        // placement: "right",
        disableBeacon: true,
      },
      {
        target: ".viewPMSStepFour",
        content: t('view_pms_step_four'),
        // placement: "top",
        disableBeacon: true,
      },
      {
        target: ".viewPMSStepFive",
        content: t('view_pms_step_five'),
        // placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".viewPMSStepSix",
        content: t('view_pms_step_six'),
        // placement: "left",
        disableBeacon: true,
      },
      {
        target: ".viewPMSStepSixSeven",
        content: t('view_pms_step_six_seven'),
        // placement: "left",
        disableBeacon: true,
      }
    ]);
  }, [t]);

  const handleUpdate = async () => {
    let isValid = true;

    if (maxRating === "") {
      toast.error(t("please_select_max_rating"));
      isValid = false;
    }

    for (let i = 0; i < selectedMetricsList.length; i++) {
      const data = selectedMetricsList[i];
      if (!data.metricsType || data.metricsType === "") {
        // toast.error(`Please select any option for ${data.metrics}`);
        t("please_select_option", { metrics: data.metrics });
        isValid = false;
      }
      if (data.metricsType === "rating" || data.metricsType === "both") {
        data.maxRating = maxRating;
      }
    }

    if (!isValid) return;

    let metrics = [];
    for (let i = 0; i < selectedMetricsList.length; i++) {
      const data = selectedMetricsList[i];
      data.metricsId = 0;
      delete data.isNew;
      metrics.push(data);
    }

    const newObj = {
      pmsConfigId: oldDataObject.pmsConfigId,
      cycleStartDayMnth: _convertToMMMMFromDate(pmsFromDate),
      cycleEndDayMnth: _convertToMMMMFromDate(pmsToDate),
      activeFlag: isEnabled ? "Y" : "N",
      metrics: metrics,
    };

    const response = await submitPmsConfig(
      loginUser.orgId,
      loginUser.userId,
      newObj
    );
    if (response.data != null && response.data > 0) {
      toast.success(t("data_updated_successfully"));
      await _fetchData();
    }
  };

  const _convertToDateFromMMMM = (dateString) => {
    const currentYear = new Date().getFullYear();
    const dateWithYear = `${dateString}-${currentYear}`;
    return new Date(dateWithYear);
  };

  const _convertToMMMMFromDate = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = date.getDate().toString().padStart(2, "0");
    const month = monthNames[date.getMonth()];

    return `${day}-${month}`;
  };

  const _deepArrayEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; i++) {
      if (!_deepEqual(arr1[i], arr2[i])) {
        return false;
      }
    }

    return true;
  };

  const _deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true;

    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
      if (!keys2.includes(key) || !_deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
    <Joyride
        steps={booksteps}
        run={run["viewPMSAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="fullContainArea viewPMSStepOne">
        <div className="cuHeaderGrp">
          <div className="cuhederTxt">{t("performance_management_system")}</div>
        </div>

        <div className="cuContainArea">
          <div className="PmsContainer">
            <div className="elementFormContainer">
              <div className="formElementGroup pmItemBorder">
                {/* select from date start */}
                <div className="labelText">{t("pms_cycle_from")}</div>
                <div className="formElement minMax150">
                  <FormControl className="formControl">
                    <InputLabel
                      id="setCompletionDate"
                      className="setCompletionDate"
                    >
                      {t("start_date")}*
                    </InputLabel>
                    <ReactDatePicker
                      locale={currentLocale}
                      showIcon
                      toggleCalendarOnIconClick
                      labelId="setCompletionDate"
                      className="formDatePicker viewPMSStepTwo"
                      selected={pmsFromDate}
                      dateFormat="dd-MMMM"
                      onChange={(date) => setPmsFromDate(date)}
                      disabled={!isEnabled}
                    />
                  </FormControl>
                </div>
                {/* select from date end */}

                {/* select to date start */}
                <div className="labelText">{t("to")}</div>
                <div className="formElement minMax150">
                  <FormControl className="formControl">
                    <InputLabel
                      id="setCompletionDate"
                      className="setCompletionDate"
                    >
                      {t("end_date")}*
                    </InputLabel>
                    <ReactDatePicker
                      locale={currentLocale}
                      showIcon
                      toggleCalendarOnIconClick
                      labelId="setCompletionDate"
                      className="formDatePicker viewPMSStepThree"
                      selected={pmsToDate}
                      dateFormat="dd-MMMM"
                      onChange={(date) => setPmsToDate(date)}
                      disabled={!isEnabled}
                    />
                  </FormControl>
                </div>
                {/* select to date end */}

                {/* enable disabled pms start */}
                <div className="accessControlBtn tblActionBtnGrp">
                  <FormControlLabel
                    className="couponStatusSwitch viewPMSStepFour"
                    control={
                      <Switch
                        checked={isEnabled}
                        onChange={(e) => setIsEnabled(e.target.checked)}
                        color="primary"
                      />
                    }
                    label={isEnabled ? t("enable") : t("disable")}
                  />
                </div>
                {/* enable disabled pms end */}
              </div>

              {/* select max rating start */}
              <div className="evaluationMetrics viewPMSStepFive">
                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel id="compTime">
                      {t("select_max_rating")}
                    </InputLabel>
                    <Select
                      className="formInputField"
                      variant="outlined"
                      labelId="compTime"
                      id="compTime-select"
                      label={t("select_max_rating")}
                      value={maxRating}
                      onChange={(e) => setMaxRating(e.target.value)}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              {/* select max rating end */}

              {/* add button start */}
              <div className="evaluationMetrics viewPMSStepSix">
                <div className="evmTitle">{t("evaluation_metrics")}</div>
                <Button
                  className="dfultPrimaryBtn"
                  startIcon={<Add />}
                  onClick={addNewMetric}
                  disabled={!isEnabled} // Disable button based on isEnabled state
                >
                  {t("new_metrics")}
                </Button>
              </div>
              {/* add button end */}

              {/* Evaluation data list start */}
              <ol className="evmList viewPMSStepSixSeven">
                {selectedMetricsList &&
                  selectedMetricsList.map((item, index) => (
                    <li className="pmItemlist" key={index}>
                      <div className="formElementGroup">
                        <div className="formElement ">
                          <FormControl className="formControl">
                            <div className="itemNMActionGrp">
                              {item.isNew ? (
                                <TextField
                                  className="formAutoComInputField autocompFildPlaceholder"
                                  placeholder={t("metric_name")}
                                  label={t("metric_name")}
                                  value={item.metrics}
                                  onChange={(e) => handleNameChange(e, index)}
                                  disabled={!isEnabled}
                                />
                              ) : (
                                <div className="itemName">{item.metrics}</div>
                              )}
                              <IconButton
                                onClick={() => deleteMetric(index)}
                                disabled={!isEnabled}
                              >
                                <Trash />
                              </IconButton>
                            </div>
                            <RadioGroup
                              className="formRadioGroup"
                              labelId="setTasktype"
                              name="setTasktype"
                              value={item.metricsType}
                              onChange={(e) => handleChangeMetricType(e, index)}
                              disabled={!isEnabled}
                            >
                              <FormControlLabel
                                className="formRadioField"
                                value="rating"
                                control={<Radio />}
                                label={t("rating")}
                                disabled={!isEnabled}
                              />
                              <FormControlLabel
                                className="formRadioField"
                                value="text"
                                control={<Radio />}
                                label={t("feedback")}
                                disabled={!isEnabled}
                              />
                              <FormControlLabel
                                className="formRadioField"
                                value="both"
                                control={<Radio />}
                                label={t("both")}
                                disabled={!isEnabled}
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </li>
                  ))}
              </ol>
              {/* Evaluation data list end */}
            </div>

            {/* update button start */}
            <div className="formBtnElement">
              {isAnyUpdate && (
                <Button className="dfultPrimaryBtn viewPMSStepSeven" onClick={handleUpdate}>
                  {t("update")}
                </Button>
              )}
            </div>
            {/* update button end */}
          </div>
        </div>
      </div>
    </>
  );
}
