import { Box, Button, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import { XLg } from "react-bootstrap-icons";
import { Call, Email, Language } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  getTempNo,
  issueProformaInvoice,
} from "../../../../../services/AdminService";
import DateUtils from "../../../../../utils/DateUtils";

export const PreviewPorforma = ({
  handleClosePreviewProforma,
  previewData,
  activity,
  onSubmitSuccess,
}) => {
  const loginUserDetails = GetLoginUserDetails();
  const { t } = useTranslation();
  const [pageId, setPageId] = useState(1);
  const [tempNo, setTempNo] = useState();

  useEffect(() => {
    getTempNo("PROFORMA_INVOICE", loginUserDetails.orgId).then((response) => {
      setTempNo(response.data);
    });
    console.log("previewData =", previewData);
  }, []);

  function convertNumberToWords(num) {
    if (!num) {
      return;
    }
    let number;
    let numberArray;
    if (typeof num === "string") {
      numberArray = num.split(",");
      let str = "";
      numberArray.forEach((element) => {
        str += element;
      });
      number = parseInt(str);
    } else {
      number = num;
    }
    // console.log("nubmers =", typeof number);
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    function convertThreeDigits(num) {
      let str = "";

      if (num >= 100) {
        str += units[Math.floor(num / 100)] + " Hundred ";
        num %= 100;
      }

      if (num >= 11 && num <= 19) {
        str += teens[num - 10] + " ";
      } else if (num === 10 || num >= 20) {
        str += tens[Math.floor(num / 10)] + " ";
        num %= 10;
      }

      if (num >= 1 && num <= 9) {
        str += units[num] + " ";
      }

      return str;
    }

    if (number === 0) {
      return "Zero";
    }

    let words = "";
    let crore = Math.floor(number / 10000000);
    number %= 10000000;
    let lakh = Math.floor(number / 100000);
    number %= 100000;
    let thousand = Math.floor(number / 1000);
    let remainder = number % 1000;

    if (crore > 0) {
      words += convertThreeDigits(crore) + "Crore ";
    }

    if (lakh > 0) {
      words += convertThreeDigits(lakh) + "Lac ";
    }

    if (thousand > 0) {
      words += convertThreeDigits(thousand) + "Thousand ";
    }

    if (remainder > 0) {
      words += convertThreeDigits(remainder);
    }

    return words.trim();
  }

  return (
    <>
      <div className="modelContainer previewDocument">
        <Box className="modelContain animated fullwidthModel">
          <IconButton
            className="closeModelIoc"
            onClick={() => handleClosePreviewProforma()}
          >
            <XLg />
          </IconButton>

          <div className="fwModelContainArea">
            <div
              className="orgDocheaderPrv"
              style={{
                backgroundColor: "black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1
                style={{
                  color: "#fff ",
                }}
              >
                {t("proforma_invoice")}
              </h1>
            </div>
            <div className="orgDocheaderPrv">
              <div className="orgDocHedLogo">
                <img src={loginUserDetails.orgLogo} alt="org Logo" />
                <div>{loginUserDetails.orgName}</div>
              </div>
              <div className="orgDocHedInfo invoiceHeadElement">
                <div className="orgDocHedInfoElement">
                  <span className="invoTxtSpan">{t("invoice_no")}:</span>
                  <span className="invoTxthighlight">{tempNo}</span>
                </div>
                <div className="orgDocHedInfoElement">
                  <span className="invoTxtSpan">{t("invoice_date")}:</span>
                  <span className="invoTxthighlight">
                    {DateUtils.getDateInFormalFormat(previewData.invoiceDate)}
                  </span>
                </div>
              </div>
            </div>

            <div className="invoiceBuyerSellerDtl">
              <div className="buySellInfo">
                <div className="bsdtlHed">{t("seller_details")}</div>
                <div className="bsname">{previewData.sellerName}</div>
                <div className="bsAddress">{previewData.sellerAdress}</div>
                <div className="bsEmail">{previewData.sellerEmailId}</div>
                <div className="bsGst">
                  <span>{t("gstin")}:</span> {previewData.sellerTaxId}
                </div>
              </div>

              <div className="buySellInfo">
                <div className="bsdtlHed">{t("buyer_details")}</div>
                <div className="bsname">{previewData.buyerName}</div>
                <div className="bsAddress">{previewData.buyerAddress}</div>
                <div className="bsEmail">{previewData.buyerEmailId}</div>
                <div className="bsGst">
                  <span>{t("gstin")}:</span> {previewData.buyerTaxId}
                </div>
              </div>

              <div className="buySellInfo">
                <div className="bsdtlHed">{t("place_of_supply")}</div>
                {/* <div className="bsname">{previewData.buyerName}</div> */}
                <div className="bsAddress">{previewData.placeOfSupply}</div>
                {/* <div className="bsEmail">info@miniontek.com</div> */}
                {/* <div className="bsGst">
                  <span>GSTIN:</span> WB00212002125511
                </div> */}
              </div>
            </div>

            <div className="invoiceTable">
              <table className="invo-Table">
                <thead>
                  {/* <tr>
                    <th>No</th>
                    <th>Product</th>
                    <th>Uom</th>
                    <th>Qty</th>
                    <th className="textRight">Rate</th>
                    <th>Disc</th>
                    <th>Tax</th>
                    <th className="textRight">Amount</th>
                  </tr> */}
                  <tr>
                    <th>{t("no")}</th>
                    <th>{t("product")}</th>
                    <th>{t("uom")}</th>
                    <th>{t("qty")}</th>
                    <th className="textRight">{t("rate")}</th>
                    <th>{t("disc")}</th>
                    <th>{t("tax")}</th>
                    <th className="textRight">{t("amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                    <td>
                      <div className="invotext">1</div>
                    </td>
                    <td>
                      <div className="invotext productInfoDtl">
                        <div className="proDName">
                          Windows 10 Professional- Legalization{" "}
                        </div>
                        <div className="proDDec">DG7GMGF0CGSH:0005</div>
                        <div className="proDhsn">
                          <span>HSN:</span>002545
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="invotext">NOS</div>
                    </td>
                    <td>
                      <div className="invotext">15</div>
                    </td>
                    <td>
                      <div className="invotext invoAmount">9200.00</div>
                    </td>
                    <td>
                      <div className="invotext productDis">
                        <div className="dicValu">1200</div>
                        <div className="dicPers">@10%</div>
                      </div>
                    </td>
                    <td>
                      <div className="invotext productTax">
                        <div className="taxValu">1800</div>
                        <div className="taxPersnt">CGST@9%</div>
                        <div className="taxPersnt">SGST@9%</div>
                      </div>
                    </td>
                    <td>
                      <div className="invotext invoAmount">138000.00</div>
                    </td>
                  </tr> */}

                  {previewData?.tableData?.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>
                            <div className="invotext">{index + 1}</div>
                          </td>
                          <td>
                            <div className="invotext productInfoDtl">
                              <div className="proDName">{item.itemName}</div>
                              {item?.item?.itemDescription && (
                                <div className="proDDec">
                                  {item.item.itemDescription}
                                </div>
                              )}

                              {item?.item?.hsnCd && (
                                <div className="proDhsn">
                                  <span>{t("hsn")}:</span>
                                  {item.item.hsnCd}
                                </div>
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="invotext">{item.uom}</div>
                          </td>
                          <td>
                            <div className="invotext">{item.quantity}</div>
                          </td>
                          <td>
                            <div className="invotext invoAmount">
                              {item.rate}
                            </div>
                          </td>
                          <td>
                            <div className="invotext productDis">
                              <div className="dicValu">{item.diseAmt}</div>
                              <div className="dicPers">@{item.disePc}%</div>
                            </div>
                          </td>
                          <td>
                            <div className="invotext productTax">
                              <div className="taxValu">
                                {parseFloat(item.sgstAmt) +
                                  parseFloat(item.igstAmt) +
                                  parseFloat(item.cgstAmt) +
                                  parseFloat(item.taxAmt)}
                              </div>
                              {item.cgstAmt != 0 && (
                                <div className="taxPersnt">
                                  CGST@{item.cgstPc}%
                                </div>
                              )}
                              {item.igstAmt != 0 && (
                                <div className="taxPersnt">
                                  IGST@{item.igstPc}%
                                </div>
                              )}
                              {item.sgstAmt != 0 && (
                                <div className="taxPersnt">
                                  SGST@{item.sgstPc}%
                                </div>
                              )}
                              {item.taxAmt != 0 && (
                                <div className="taxPersnt">
                                  TAX@{item.taxPc}%
                                </div>
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="invotext invoAmount">
                              {item.amount}
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                  <tr>
                    <td colSpan={7}>
                      <div className="subtotalText">{t("subtotal")}</div>
                      <div className="subtotalText">{t("roundoff")}</div>
                    </td>
                    <td className="subtotalTD">
                      <div className="subtotalAmount">
                        {previewData?.subTotalAmount}
                      </div>
                      <div className="subtotalAmount">
                        {previewData?.roundOffAmount}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="invoCurrencyinfo" colSpan={7}>
                      <div className="invoCurInfoGrp">
                        <div className="curnvyInfo">
                          {`*All figures in ${previewData?.currency}*`}
                        </div>

                        <div className="totalText">{t("total")}</div>
                      </div>
                    </td>
                    <td className="invoTotalAmount">
                      <div className="totalAmount">
                        {previewData?.totalAmount}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={8}>
                      <div className="amountWord">
                        <span> {t("in_word")}:</span>
                        {convertNumberToWords(previewData?.totalAmount)}{" "}
                        {t("only")}/-
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="invoiceOtherInfoGrp">
              <div className="invoOthInfoDtl">
                <div className="invoOtheDtlHeading">
                  {t("bank_and_payment_details")}:
                </div>

                <div className="invoTranDtlgrp">
                  <div className="invoTranDtlInd">
                    {console.log(
                      "payment mode =",
                      previewData?.bankingMethod?.paymentMode
                    )}
                    {previewData?.bankingMethod?.paymentMode === "B" && (
                      <>
                        <h3>
                          {t("payment_mode")} : {t("internet_banking")}
                        </h3>
                        <ul>
                          <div>
                            <span className="text">
                              {t("beneficiary_name")}:
                            </span>

                            <span className="text">
                              {previewData.bankingMethod.accountHolderName}
                            </span>
                          </div>

                          <div>
                            <span className="text">
                              {t("account_number")}:{" "}
                            </span>
                            <span className="text">
                              {previewData.bankingMethod.accountNo}
                            </span>
                          </div>
                          <div>
                            <span className="text">{t("bank_name")}: </span>
                            <span className="text">
                              {previewData.bankingMethod.bankName}
                            </span>
                          </div>
                          <div>
                            <span className="text">{t("address")}: </span>
                            <span className="text">
                              {previewData.bankingMethod.bankBranch}
                            </span>
                          </div>
                          {previewData.bankingMethod.ifscCd !== "" && (
                            <div>
                              <span className="text">{t("ifsc_code")}: </span>
                              <span className="text">
                                {previewData.bankingMethod.ifscCd}
                              </span>
                            </div>
                          )}
                          {previewData.bankingMethod.rtgsCd !== "" && (
                            <div>
                              <span className="text">{t("rtgs_code")}: </span>
                              <span className="text">
                                {previewData.bankingMethod.rtgsCd}
                              </span>
                            </div>
                          )}
                          {previewData.bankingMethod.swiftCd !== "" && (
                            <div>
                              <span className="text">{t("swift_code")}: </span>
                              <span className="text">
                                {previewData.bankingMethod.swiftCd}
                              </span>
                            </div>
                          )}
                        </ul>
                      </>
                    )}

                    {previewData?.bankingMethod?.paymentMode === "U" && (
                      <>
                        <h3>
                          {t("payment_mode")} : {t("upi")}
                        </h3>
                        <ul>
                          <li>
                            <span className="text">{t("upi_id")}: </span>
                            {previewData.bankingMethod.upiId}
                          </li>
                        </ul>
                      </>
                    )}

                    {previewData?.bankingMethod?.paymentMode === "C" && (
                      <>
                        <h3>
                          {t("payment_mode")} : {t("cheque")}
                        </h3>
                        <ul>
                          <li>
                            <span className="text">
                              {t("beneficiary_name")}:{" "}
                            </span>
                            {previewData.bankingMethod.payeeNameInCheque}
                          </li>
                        </ul>
                      </>
                    )}

                    {/* <div className="invoInfoitem">
                      <span className="text">Payee Name:</span>
                      <span className="value">
                        Minion Technologies Pvt. Ltd.
                      </span>
                    </div>
                    <div className="invoInfoitem">
                      <span className="text">Bank Name:</span>
                      <span className="value">State Bank of india</span>
                    </div>
                    <div className="invoInfoitem">
                      <span className="text">A/C Number:</span>
                      <span className="value">0215245584556</span>
                    </div>
                    <div className="invoInfoitem">
                      <span className="text">IFSC Code:</span>
                      <span className="value">SBIN00695</span>
                    </div>
                    <div className="invoInfoitem">
                      <span className="text">SWIFT Code:</span>
                      <span className="value">SBIN00254456548554</span>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* <div className="orgDocHedInfo invoiceHeadElement">
                <div className="orgDocHedInfoElement">
                  <span className="invoTxtSpan">PO No:</span>
                  <span className="invoTxthighlight">PROI004581242</span>
                </div>
                <div className="orgDocHedInfoElement">
                  <span className="invoTxtSpan">Challan No:</span>
                  <span className="invoTxthighlight">1255448456</span>
                </div>
              </div> */}
            </div>

            <div className="invoOthInfoDtl">
              <div className="invoOtheDtlHeading">
                {t("terms_and_conditions")}:
              </div>

              <div className="invoTramsC">
                <div className="invoTramsC">
                  <ul>
                    {previewData?.allTermsAndConditions?.map((item) => {
                      return <li>{item.termsConditions}</li>;
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="modActionBtnGrp actionsCreateOrg">
              {pageId === 1 && (
                <>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={handleClosePreviewProforma}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={(e) => {
                      const dto = {
                        clientId: previewData.buyerId || 0,
                        clientEmail: previewData.buyerEmailId || "",
                        clientTaxId: previewData.buyerTaxId || "",
                        placeOfSupply: previewData.placeOfSupply || "",
                        invoiceDate: dayjs(previewData.invoiceDate).format(
                          "DD-MM-YYYY"
                        ),
                        sellerEmail: previewData.sellerEmailId || "",
                        sellerTaxId: previewData.sellerTaxId || "",
                        activityId: activity.id,
                        activityName: activity.name,
                        paymentId: previewData?.bankingMethod?.id || 0,
                        goodsService: previewData.itemType || "",
                        referenceNo: previewData.refNo,
                        referenceDocumentData:
                          previewData.refDocument?.fileDataLink || "",
                        referenceDocumentFileName:
                          previewData.refDocument?.fileName || "",
                        items:
                          previewData?.tableData?.map((item) => {
                            return {
                              itemId: item.itemId || 0,
                              itemName: item.itemName || "",
                              itemDescription: item.itemDescription || "",
                              hsnCd: item.item.hsnCd || "",
                              uom: item.uom || "",
                              quantity: parseFloat(item.quantity) || 0,
                              rate: parseFloat(item.rate) || 0,
                              disPc: parseFloat(item.disePc) || 0,
                              sgstPc: parseFloat(item.sgstPc) || 0,
                              cgstPc: parseFloat(item.cgstPc) || 0,
                              igstPc: parseFloat(item.igstPc) || 0,
                              taxPc: parseFloat(item.taxPc) || 0,
                              disAmt: parseFloat(item.diseAmt) || 0,
                              sgstAmt: parseFloat(item.sgstAmt) || 0,
                              igstAmt: parseFloat(item.igstAmt) || 0,
                              cgstAmt: parseFloat(item.cgstAmt) || 0,
                              taxAmt: parseFloat(item.taxAmt) || 0,
                              value: parseFloat(item.value) || 0,
                            };
                          }) || [],
                        termsConditions:
                          previewData.allTermsAndConditions?.map((item) => {
                            return {
                              slNo: item.slNo,
                              termsConditionType:
                                item.termsConditionsType || "",
                              termsConditionHeading:
                                item.termsConditionsHeading || "",
                              termsCondition: item.termsConditions || "",
                            };
                          }) || [],
                        subTotal: previewData.subTotalAmount,
                        roundOff: previewData.roundOffAmount,
                        totalAmount: previewData.totalAmount,
                        currency: previewData.currency,
                      };

                      console.log("dto value =", dto);
                      // return;

                      issueProformaInvoice(
                        loginUserDetails.orgId,
                        loginUserDetails.signleUnitId,
                        loginUserDetails.userId,
                        dto
                      ).then((response) => {
                        if (
                          response.data &&
                          response.data.returnValue === "1"
                        ) {
                          console.log("submitted data =", response.data);
                          toast.success(
                            t("proforma_invoice_issued_successfully")
                          );
                          onSubmitSuccess();
                        } else {
                          toast.error(t("could_not_submit_proforma"));
                        }
                      });
                    }}
                  >
                    {t("submit")}
                  </Button>
                </>
              )}
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};
