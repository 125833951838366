import { Add, Edit, Search } from "@mui/icons-material";
import { Button, CircularProgress, IconButton } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import {
  getAllDriveDetailsOfOrgUsers,
  getOrgTotalStorage,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";

export default function DefineStorage({
  handleOpenCreateOrgStorage,
  handleOpenUpdateOrgStorage,
  refreshStorage,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [allDriveData, setDriveData] = useState([]);
  const [filterAllDriveData, setFilterDriveData] = useState([]);
  const [totalUsedStorage, setTotalUsedStorage] = useState(0);
  const [progressWidth, setProgressWidth] = useState(0);

  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   getAllDriveDetailsOfOrgUsers(userDetails.orgId).then((response) => {
  //     console.log("Get all drive Data", response.data);
  //     setDriveData(response.data);
  //     setFilterDriveData(response.data);

  //   });
  // }, [userDetails.orgId]);

  const [TotalStorageData, setTotalStorageData] = useState(0);

  // useEffect(()=>{
  //   setBookSteps([
  //     {
  //       target: '.orgStorageStepOne', 
  //       content: 'Search for users by their name to quickly find their storage details.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.orgStorageStepTwo', 
  //       content: 'This progress bar shows the percentage of used storage for your organization.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.orgStorageStepThree', 
  //       content: 'Click here to add new storage space for your organization.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.orgStorageStepFour', 
  //       content: 'Here you can see the storage allocated, used, and the option to edit user storage.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.orgStorageStepFive', 
  //       content: 'Click on the edit button to modify the storage allocated to a user.',
  //       disableBeacon: true,
  //     }
  //   ]
  //   );
  // },[])

  useEffect(() => {
    setBookSteps([
      {
        target: '.orgStorageStepOne',
        content: t('org_storage_step_one'),
        disableBeacon: true,
      },
      {
        target: '.orgStorageStepTwo',
        content: t('org_storage_step_two'),
        disableBeacon: true,
      },
      {
        target: '.orgStorageStepThree',
        content: t('org_storage_step_three'),
        disableBeacon: true,
      },
      {
        target: '.orgStorageStepFour',
        content: t('org_storage_step_four'),
        disableBeacon: true,
      },
      {
        target: '.orgStorageStepFive',
        content: t('org_storage_step_five'),
        disableBeacon: true,
      }
    ]);
  }, [t]);

  console.log("refreshStorage", refreshStorage);

  const totalStorageRef = useRef(null);

  useEffect(() => {
    getTotalStorage();
    // fetchAllStorageData();
    // handleProgresss();
  }, [refreshStorage]);

  const getTotalStorage = () => {
    getOrgTotalStorage(userDetails.orgId).then((response) => {
      console.log("Total Storage", response.data);
      setTotalStorageData(response.data.storage);
      totalStorageRef.current = response.data.storage;
      // setLoading(false);
      fetchAllStorageData();
    });
  };

  const fetchAllStorageData = () => {
    getAllDriveDetailsOfOrgUsers(userDetails.orgId)
      .then((response) => {
        console.log("getAllDriveDetailsOfOrgUsers", response.data);
        if (response && response.data !== null) {
          let totalStorageAllocated = 0;
          let totalUsedStorageTemp = 0;
          response.data.forEach((usrDriveDto) => {
            totalStorageAllocated += usrDriveDto.storage;
            let usedStorage = usrDriveDto.usedStorage / 1024 / 1024;
            usedStorage =
              Math.round((usedStorage + Number.EPSILON) * 100) / 100;
            totalUsedStorageTemp += usedStorage;
          });
          setDriveData(response.data);
          setFilterDriveData(response.data);
          setTotalUsedStorage(totalStorageAllocated);
          setLoading(false);
          handleProgresss(totalStorageAllocated);
        }
      })
      .catch((error) => {
        console.error("Error fetching drive details:", error);
        setLoading(false);
      });
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = allDriveData.filter(
      (DriveData) =>
        DriveData.userName &&
        DriveData.userName.toLowerCase().includes(query.toLowerCase())
    );

    setFilterDriveData(filteredData);
  };

  const handleProgresss = (totalStorageAllocated) => {
    const percentageUsed =
      (totalStorageAllocated / totalStorageRef.current) * 100;
    setProgressWidth(percentageUsed);
    console.log("percentageUsed", percentageUsed);
  };

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
    <Joyride
        steps={booksteps}
        run={run["viewStorageAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="fullContainArea">
        <div className="cuHeaderGrp">
          {/* <div className="cuhederTxt">Departmment List</div> */}
          <div className="strogeSearchSpace ">
            <div className="searchTasklist orgStorageStepOne">
              <input
                type="text"
                class="serchTaskInput"
                placeholder={t("search_user")}
                value={searchQuery}
                onChange={handleSearch}
              />
              <IconButton className="seacrhIcon">
                <Search />
              </IconButton>
            </div>

            <div className="storeTimeline orgStorageStepTwo">
              <div className="storeTimBar">
                <div className="progress">
                  {/* <div
                    //   add class "storageFull" when storage  90% and avobe
                    className="progress-done "
                    style={{ width: "50%" }}
                  ></div> */}
                  <div
                    className={
                      progressWidth > 90
                        ? "progress-done storageFull"
                        : "progress-done"
                    }
                    style={{ width: `${progressWidth}%` }}
                  ></div>
                </div>
              </div>
              <div className="storeValueTxt">
                {/* <div className="stvStart">
                 Organization Storage - {totalUsedStorage} GB out of {TotalStorageData} GB is used{" "}
                </div> */}
                <div className="stvStart">
                  {t("organization_storage", {
                    totalUsedStorage,
                    totalStorage: TotalStorageData,
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="addUnitsBtn orgStorageStepThree">
            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => {handleOpenCreateOrgStorage();
                if (run["viewStorageAD"] === true) {
                  setRun({
                    addStorageAD: true,
                  });
                }
              }}
            >
              {t("storage")}
            </Button>
          </div>
        </div>

        <div className="cuContainArea">
          {loading ? (
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          ) : allDriveData.length === 0 ? (
            <div class="noDataCard ">
              <span>{t("oops")}</span>
              {t("no_storage_define_yet")}
              <br />
              <span className="sallMsg">
                {t("click_add_storage_button_to_create_new_storage")}
              </span>
            </div>
          ) : (
            <div className="TableContainer">
              <table className="taskListTable orgStorageStepFour">
                <thead className="taskListtableHeader">
                  <tr>
                    <th>{t("user_name")}</th>
                    <th>{t("email_id")}</th>
                    <th className="TDtextright">{t("storage")}</th>
                    <th className="TDtextright">{t("used")}</th>
                    <th className="TDtextright">{t("modify")}</th>
                  </tr>
                </thead>
                <tbody className="scrolableTableBody">
                  {filterAllDriveData.map((user) => (
                    <tr key={user.userId}>
                      <td>
                        <div className="tdText">{user.userName}</div>
                      </td>
                      <td>
                        <div className="tdText">{user.userLoginId}</div>
                      </td>
                      <td className="TDtextright">
                        <div className="tdText">{user.storage} GB</div>
                      </td>
                      <td className="TDtextright">
                        <div className="tdText">
                          {(user.usedStorage / 1024).toFixed()} GB
                        </div>
                      </td>
                      <td className="TDtextright">
                        <div className="tblActionBtnGrp orgStorageStepFive">
                          <IconButton
                            onClick={() =>
                              {handleOpenUpdateOrgStorage(
                                user,
                                totalUsedStorage,
                                TotalStorageData
                              );
                              if (run["viewStorageAD"] === true) {
                                setRun({
                                  addUserStorageAD: true,
                                });
                              }
                              }
                            }
                          >
                            <Edit />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  ))}

                  {/* <tr>
                  <td>
                    <div className="tdText">Sanket Santra</div>
                  </td>
                  <td>
                    <div className="tdText">uiux-designer@miniontek.com</div>
                  </td>
                  <td className="TDtextright">
                    <div className="tdText">30 GB</div>
                  </td>
                  <td className="TDtextright">
                    <div className="tdText">.512 Gb</div>
                  </td>

                  <td className="TDtextright">
                    <div className="tblActionBtnGrp">
                      <IconButton>
                        <Edit />
                      </IconButton>
                    </div>
                  </td>
                </tr> */}
                  {/* <tr>
                  <td>
                    <div className="tdText">Sk Aftabuddin</div>
                  </td>
                  <td>
                    <div className="tdText">chief-developer@miniontek.com</div>
                  </td>
                  <td className="TDtextright">
                    <div className="tdText">30 GB</div>
                  </td>
                  <td className="TDtextright">
                    <div className="tdText">2.512 Gb</div>
                  </td>

                  <td className="TDtextright">
                    <div className="tblActionBtnGrp">
                      <IconButton>
                        <Edit />
                      </IconButton>
                    </div>
                  </td>
                </tr> */}
                  {/* <tr>
                  <td>
                    <div className="tdText">Prashant Saha</div>
                  </td>
                  <td>
                    <div className="tdText">sr-tech-lead@miniontek.com</div>
                  </td>
                  <td className="TDtextright">
                    <div className="tdText">30 GB</div>
                  </td>
                  <td className="TDtextright">
                    <div className="tdText">3.4 Gb</div>
                  </td>

                  <td className="TDtextright">
                    <div className="tblActionBtnGrp">
                      <IconButton>
                        <Edit />
                      </IconButton>
                    </div>
                  </td>
                </tr> */}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
