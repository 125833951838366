import { FormControl, IconButton, MenuItem, Select } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { ArrowRepeat } from "react-bootstrap-icons";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import { Bar, Chart, Doughnut, Line, Pie } from "react-chartjs-2";

import "../../../css/websiteStatusCard.css";
import { Web, WebTwoTone } from "@mui/icons-material";
import Button from "../../../../RTE/ui/Button";
import miscService from "../../../../../services/MiscService";
import DateUtils from "../../../../../utils/DateUtils";
import CardWebsiteStatusLineGraph from "./CardWebsiteStatusLineGraph";
import {
  format,
  subDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
} from "date-fns";
import CardWebsiteStatusLineGraphUptimeDowntime from "./CardWebsiteStatusLineGraphUptimeDowntime";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";

export default function CardWebsiteStatusUptimeDowntime() {
  const { t } = useTranslation();
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );
  const [websiteStatus, setWebsiteStatus] = useState(null);
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const [websiteSnapshotData, setWebsiteSnapshotData] = useState(null);
  const [selectedRange, setSelectedRange] = useState("Today");
  const [dataRangeOverallAvg, setDataRangeOverallAvg] = useState(null);
  useEffect(() => {
    miscService.getMassaludWebsiteStatus().then((response) => {
      if (response && response.data) {
        const monitor = response.data.find(
          (item) => item.url === "https://massalud.zoyel.health"
        );
        if (monitor) {
          setWebsiteStatus(monitor);
        }
      }
    });
  }, []);

  useEffect(() => {
  
    setBookSteps([
      {
        target: ".msUptimeDowntime",
        content: t('ms_uptime_downtime'),
        disableBeacon: true,
      },
      {
        target: '.msUptimeDowntimeOne',
        content: t('ms_uptime_downtime_one'),
        disableBeacon: true,
      },
      {
        target: '.msUptimeDowntimeTwo',
        content: t('ms_uptime_downtime_two'),
        disableBeacon: true,
      },
      {
        target: '.msUptimeDowntimeThree',
        content: t('ms_uptime_downtime_three'),
        disableBeacon: true,
      },
      {
        target: '.msUptimeDowntimeFour',
        content: t('ms_uptime_downtime_four'),
        disableBeacon: true,
      },
    ]);
  }, []);

  // useEffect(() => {
  //   setBookSteps([
  //     {
  //       target: ".msUptimeDowntime",
  //       content: "This section displays card management Uptime and Downtime of massalud.zoyel.health.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.msUptimeDowntimeOne',
  //       content: 'This is the website status overview for massalud.zoyel.health.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.msUptimeDowntimeTwo',
  //       content: 'Select the desired date range for website data.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.msUptimeDowntimeThree',
  //       content: 'This section shows the overall status and response times of the website.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.msUptimeDowntimeFour',
  //       content: 'View the graphical representation of uptime and downtime data.',
  //       disableBeacon: true,
  //     },
  //   ]);
  // }, []);

  useEffect(() => {
    fetchWebsiteSnapshotData(selectedRange);
  }, [selectedRange]);

  const fetchWebsiteSnapshotData = (range) => {
    const dateRange = calculateDateRange(range);
    let reqDto = { field1: dateRange.field1, field2: dateRange.field2 };
    // let reqDto = {
    //   field1: "2024-11-11 09:00:00",
    //   field2: "2024-11-11 17:00:00",
    // };
    console.log("fetchWebsiteSnapshotData", reqDto);
    miscService.getMassaludWebsiteSnapshotData(reqDto).then((response) => {
      console.log("getMassaludWebsiteSnapshotData::", response.data);
      if (response && response.data) {
        const filtered = response.data.filter((dataItem) =>
          ["sao-paulo", "los-angeles", "new_york"].includes(dataItem.node)
        );
        setWebsiteSnapshotData(filtered);
        const totalSum = filtered.reduce((acc, snapshot) => {
          const avgTimeTotal = parseFloat(snapshot.average_time_total);
          return acc + (isNaN(avgTimeTotal) ? 0 : avgTimeTotal);
        }, 0);
        const overallAverage = totalSum / filtered.length;
        console.log("overallAverage", overallAverage);
        setDataRangeOverallAvg(overallAverage);
      }
    });
  };

  const calculateDateRange = (range) => {
    const now = new Date();
    let startDate, endDate;

    switch (selectedRange) {
      case "Today":
        startDate = new Date(now.setHours(0, 0, 0, 0));
        endDate = new Date(now.setHours(23, 59, 59, 999));
        break;
      case "Yesterday":
        startDate = new Date(now.setDate(now.getDate() - 1));
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(now.setHours(23, 59, 59, 999));
        break;
      case "Last 7 Days":
        startDate = new Date(now.setDate(now.getDate() - 7));
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        break;
      case "This Week":
        startDate = new Date(now.setDate(now.getDate() - now.getDay()));
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        break;
      case "Last Week":
        const lastWeekStart = new Date(
          now.setDate(now.getDate() - now.getDay() - 7)
        );
        startDate = new Date(lastWeekStart.setHours(0, 0, 0, 0));
        endDate = new Date(now.setDate(lastWeekStart.getDate() + 6));
        endDate.setHours(23, 59, 59, 999);
        break;
      case "This Month":
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        break;
      case "Last Month":
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(now.getFullYear(), now.getMonth(), 0);
        endDate.setHours(23, 59, 59, 999);
        break;
      case "Last 30 Days":
        startDate = new Date(now.setDate(now.getDate() - 30));
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        break;
      case "All Time":
        startDate = new Date(2024, 11, 1, 0, 0, 0, 0);
        endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        break;
      default:
        return;
    }

    return {
      field1: DateUtils.getDateInYYYYMMDDHH24MMForwebsiteStatus(startDate),
      field2: DateUtils.getDateInYYYYMMDDHH24MMForwebsiteStatus(endDate),
    };
  };

  // const handleTourCallback = (data) => {
  //   const { status, index } = data;
  //   if (status === "finished" || (status === "step:after" && index === booksteps.length - 1)) {
  //     setRun(false);
  //   }
  // };

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
     <Joyride
        steps={booksteps}
        run={run["manageuptimeDowntimeMassaludAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        styles={{
          overlay: {
            height: "100vh",
          },
        }}
        locale={{
          back: t('back'),
          last: t('finish'),
          next: t('next'),
          skip: t('skip'),
        }}
      />
      <div className="anDashIndCard manageWidth50 msUptimeDowntime">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM msUptimeDowntimeOne">
              {t("Uptime_Downtime")} massalud.zoyel.health
            </div>
            <div className="andCrdUnitCount cardFrom msUptimeDowntimeTwo">
              <FormControl size="small" className="formControl w110 ">
                <Select
                  className="formInputField"
                  labelId="filterBy"
                  id="dateRange"
                  value={selectedRange}
                  onChange={(e) => setSelectedRange(e.target.value)}
                >
                  {/* <MenuItem value="Today">Today</MenuItem>
                  <MenuItem value="Yesterday">Yesterday</MenuItem>
                  <MenuItem value="Last 7 Days">Last 7 Days</MenuItem>
                  <MenuItem value="This Week">This Week</MenuItem>
                  <MenuItem value="Last Week">Last Week</MenuItem>
                  <MenuItem value="This Month">This Month</MenuItem>
                  <MenuItem value="Last Month">Last Month</MenuItem>
                  <MenuItem value="Last 30 Days">Last 30 Days</MenuItem>
                  <MenuItem value="All Time">All Time</MenuItem> */}
                  <MenuItem value="Today">{t("Today")}</MenuItem>
                  <MenuItem value="Yesterday">{t("Yesterday")}</MenuItem>
                  <MenuItem value="Last 7 Days">{t("Last_7_Days")}</MenuItem>
                  <MenuItem value="This Week">{t("This_Week")}</MenuItem>
                  <MenuItem value="Last Week">{t("Last_Week")}</MenuItem>
                  <MenuItem value="This Month">{t("This_Month")}</MenuItem>
                  <MenuItem value="Last Month">{t("Last_Month")}</MenuItem>
                  <MenuItem value="Last 30 Days">{t("Last_30_Days")}</MenuItem>
                  <MenuItem value="All Time">{t("All_Time")}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="andCrdGraf align-item-center msUptimeDowntimeFour">
            <div className="anDCenterGrf   pRelative">
              {false && dataRangeOverallAvg && (
                <div className="website-status-card-parent msUptimeDowntimeThree">
                  <div className="website-status-card">
                    <p className="website-status-card__title">
                      Average Response Time
                    </p>
                    <div className="website-status-card__value">
                      <span className="website-status-card__time">
                        {dataRangeOverallAvg?.toFixed(2)}
                      </span>
                      <div className="website-status-card__unit_status">
                        <span className="website-status-card__unit">
                          Seconds
                        </span>
                        <span
                          className={`website-status-card__status ${
                            websiteStatus?.status === "online"
                              ? "status-online"
                              : "status-offline"
                          }`}
                        >
                          {websiteStatus?.status === "online"
                            ? "Online"
                            : "Offline"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="website-status-card">
                    <p className="website-status-card__title">Response Time</p>
                    <div className="website-status-card__value">
                      <span className="website-status-card__time">
                        {websiteStatus &&
                          websiteStatus.response_time &&
                          parseFloat(websiteStatus?.response_time).toFixed(2)}
                      </span>
                      <div className="website-status-card__unit_status">
                        <span className="website-status-card__unit">
                          Seconds
                        </span>
                        <span className="website-status-card__status lastChecked">
                          {websiteStatus &&
                            websiteStatus.checked_at &&
                            DateUtils.getDateInDDMMYYYYHH12MMSSMonthNameWebsiteResponseTime(
                              websiteStatus.checked_at
                            )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <br></br>
              {websiteSnapshotData && websiteSnapshotData.length > 0 && (
                <CardWebsiteStatusLineGraphUptimeDowntime
                  data={websiteSnapshotData}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
