import { ArrowOutward, CheckCircle, Edit, Send } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ArrowLeft, People, Play, XLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearTaskModalData,
  moveToPreviousTask,
  pushLinkTask,
  setTaskModalData,
} from "../../../../../redux/reducers/taskTab";
import DashboardServices from "../../../../../services/DashboardServices";
import SupportService from "../../../../../services/SupportService";
import { supportSocket } from "../../../../../socket";
import {
  getFormattedDate,
  GetLoginUserDetails,
} from "../../../../../utils/Utils";
import "../../../css/task.css";
import isAssignedByMe from "../../../taskUtil";
import { taskTabContext } from "../../TaskTabContainer";
import AssigneeModal from "../AssigneeModal";
import ConcernTaskModal from "../ConcernTaskModal";
import FileList from "../FileList";
import ForwardSupportModal from "../ForwardSupportModal";
import ForwardSupportModule from "../ForwardSupportModule";
import { LinkedTaskModule } from "../LinkedTaskModule";
import { DescriptionComponent } from "../Modals/DescriptionComponent";
import { EditDeadLineModal } from "../Modals/EditDeadLineModal";
import MeetingModal from "../Modals/MeetingModal";
import { TaskContractModal } from "../Modals/TaskContractModal";
import { TaskNameModal } from "../Modals/TaskNameModal";
import RightTaskFileUpload from "../RightTaskFileUpload";
import { PriorityComponent } from "../TaskComponent/PriorityComponent";

export default function TaskTabSupportRequest({
  data,
  refreshData,
  handleClose,
  removeFromStore,
}) {
  let { t } = useTranslation();
  const context = useContext(taskTabContext);
  const { setShowConvenientMeetingView } = context;
  const convinientSelecteduser = context.setSelectedUsers;
  const [showMeetingModal, setShowMeetingsModal] = useState(false);

  const [showFileModal, setShowFileUploadModal] = useState(false);

  const scrollRef = useRef();

  const audioUrl = useRef();
  const loginUserDetail = GetLoginUserDetails();
  const progress = useRef(0);

  const [dateRemaning, setDateRemaning] = useState(0);
  // left right tags scroll
  const elementRef = useRef(null);
  const [assigneeList, setAssigneeList] = useState([]);
  const commentInputRef = useRef();
  const dispatch = useDispatch();
  const fileDocuments = useRef(null);
  const [userList, setUserList] = useState([]);

  const [showConcernModal, setShowConcernModal] = useState(false);
  const [showEditDeadline, setShowEditDeadLine] = useState(false);

  const [approvalConPop, setApprovalConPop] = React.useState(null);
  const open = Boolean(approvalConPop);
  const id = open ? "simple-popover" : undefined;

  const [showAudioModal, setShowAudioModal] = useState(false);
  const [showAssigneeList, setShowAssigneeList] = useState(false);
  const [showForwardSupport, setShowForwardSupport] = useState(false);
  const [commentsAndDocuments, setCommentsAndDocuments] = useState(null);
  const [showSupportFileUploadDialog, setSupportShowFileUploadDialog] =
    useState(false);
  const [prevDocuments, setPrevDocuments] = useState([]);
  const [ticketResolvedRemark, setTicketResolvedRemark] = useState("");
  const [loadingCloseTicket, setLoadingCloseTicket] = useState(false);
  const [supportPersonDocument, setSupportPersonData] = useState(null);

  const links = useSelector((state) => state.taskList.taskLinks);
  // const linkTaskHtml=useMemo(()=>{

  // },data)

  const handleUpdateSupport = async (fileList) => {
    const reqDto = fileList.map((file) => ({
      id: 0,
      taskDtlId: data.taskId,
      fileName: file.fileName,
      fileDataLink: file.fileDataLink,
      uploadedBy: loginUserDetail.userId,
      fileDisplayName: file.fileName,
      uploadTime: "",
      fileFolder: "",
    }));

    console.log("reqDto = ", reqDto);

    SupportService.uploadFileOfSupportTask(
      data.taskId,
      supportPersonDocument?.forwardToPkId,
      reqDto
    ).then((response) => {
      if (response.data) {
        setSupportPersonData(null);
        const tempData = { ...data };
        if (tempData.documents === null) {
          tempData.documents = [...response.data];
        } else {
          tempData.documents = [...data.documents, ...response.data];
        }
        dispatch(setTaskModalData(tempData));
      }
    });

    console.log("file document = ", fileDocuments.current);
  };

  const closeSupportTicket = async () => {
    if (ticketResolvedRemark.trim() === "") {
      toast.error(t("please_enter_some_comments"));
      return false;
    }
    setLoadingCloseTicket(true);
    let reqDto = {
      taskId: data.taskId,
      comments: ticketResolvedRemark,
    };
    const responseDto = await SupportService.closeSupportTicket(
      reqDto,
      loginUserDetail.userId
    );
    console.log("closeSupportTicket responseDto", responseDto.data);
    if (responseDto.data.returnValue === "1") {
      toast.success(t("ticket_closed_successfully"));
      refreshData();
      handleClose();
      removeFromStore();
      try {
        supportSocket.emit("updateSupportList", {
          unitId: data.taskLeftActionByUserName.split("~")[5],
          taskId: data.taskId,
          userId: loginUserDetail.userId,
          closeTicket: true,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      toast.error(t("something_went_wrong"));
    }
    setLoadingCloseTicket(false);
  };

  const fetchCommentsAndDocuments = () => {
    SupportService.getCommentsDocumentsOfForwardedTask(data.taskId).then(
      (response) => {
        setCommentsAndDocuments(response.data);
      }
    );
  };

  const gotoPreviousTAsk = () => {
    dispatch(moveToPreviousTask());
  };

  const clearStoreData = () => {
    dispatch(clearTaskModalData());
  };

  const closeApprovalConPop = () => {
    setApprovalConPop(null);
  };
  const openApprovalConPop = (event) => {
    setApprovalConPop(event.currentTarget);
  };

  const intervalProgressor = (timeDelay) => {
    const intervalId = setInterval(() => {
      setDateRemaning((prev) => {
        console.log("prev =", prev);
        if (prev < 100) {
          return prev + 1;
        }
        clearInterval(intervalId);
        return prev;
      });
      // if (progress.current < 100) {
      //   setDateRemaning((prev) => prev + 1);
      //   // setProgressWidth((prev) => prev + 1);
      //   progress.current = progress.current + 1;
      // } else {
      //   clearInterval(intervalId);
      // }
    }, timeDelay);
  };

  const clearAllModals = () => {
    setShowConcernModal(false);
    setShowAssigneeList(false);
    closeApprovalConPop();
  };

  const handleChangeFile = async (fileList) => {
    const reqDto = fileList.map((file) => ({
      id: 0,
      taskDtlId: data.taskId,
      fileName: file.fileName,
      fileDataLink: file.fileDataLink,
      uploadedBy: loginUserDetail.userId,
      fileDisplayName: file.fileName,
    }));
    console.log("reqDto = ", reqDto);

    DashboardServices.uploadDocuments(
      loginUserDetail.orgId,
      loginUserDetail.userId,
      data.taskId,
      reqDto
    ).then((response) => {
      console.log("document sent is ", response.data);
      if (response.data) {
        const tempData = { ...data };
        if (tempData.documents === null) {
          tempData.documents = [...response.data];
        } else {
          tempData.documents = [...data.documents, ...response.data];
        }
        dispatch(setTaskModalData(tempData));
      }
    });
    console.log("file document = ", fileDocuments.current);
  };

  const getTaskDifference = () => {
    // const time1=task.taskCreationTime
    // const time2 =task.taskCompletionTime
    const creationTime = new Date(data && data.createdAt);
    const completionTime = new Date(data && data.completionTime);
    const currentTime = new Date();

    //check if already time passed

    const diffFromCurrent = currentTime - completionTime;
    if (diffFromCurrent > 0) {
      setDateRemaning(100);
      return;
    }

    // Calculate the time difference in milliseconds
    const totalTimeDifference = completionTime - creationTime;
    const timeDifferenceFromNow = currentTime - creationTime;
    const milisecondPerPercentIncrease = totalTimeDifference / 100;
    const currentPercent = (timeDifferenceFromNow * 100) / totalTimeDifference;
    setDateRemaning(
      Math.floor(currentPercent) < 100 ? Math.floor(currentPercent) : 100
    );
    progress.current = Math.floor(currentPercent);
    const nextPercent = Math.ceil(currentPercent);
    const difference = nextPercent - currentPercent;
    const diffInMilisecond = milisecondPerPercentIncrease * difference;
    const clearTimeoutVal = setTimeout(() => {
      if (currentPercent < 100) {
        console.log("before interval progress", milisecondPerPercentIncrease);
        clearTimeout(clearTimeoutVal);
        setDateRemaning((prev) => prev + 1);
        progress.current = progress.current + 1;
        if (milisecondPerPercentIncrease > 0) {
          intervalProgressor(milisecondPerPercentIncrease);
        }
      }
    }, diffInMilisecond);
    console.log("currentPercent", currentPercent);
    console.log(
      "milisecondPerPercentIncrease = ",
      milisecondPerPercentIncrease / 1000
    );
  };

  const sendComment = (e) => {
    if (!data) {
      toast.error("something went wrong ");
      return;
    }
    const comment = commentInputRef.current.value;
    const reqDto = {
      taskDetailId: data.taskId,
      progress: data.progress,
      comments: comment,
      referenceId: 0,
      commentsType: null,
    };
    console.log("reqDto = ", reqDto);
    const commentsAt = new Date().toISOString();
    // return;
    if (comment.trim() !== "") {
      DashboardServices.sendTaskComments(loginUserDetail.userId, reqDto).then(
        (response) => {
          console.log("out response data= ", response);
          if (response.data == 1) {
            const tempData = { ...data };
            tempData.comments = [
              ...tempData.comments,
              {
                comments: comment,
                commentsType: null,
                commentsByUserName: loginUserDetail.userName,
                commentsByUserProfileUrl: loginUserDetail.userProfileImage,
                progress: data.progress,
                commentsAt: commentsAt,
              },
            ];
            dispatch(setTaskModalData(tempData));
            commentInputRef.current.value = "";

            const timeoutValue = setTimeout(() => {
              scrollRef.current.scrollIntoView({ behavior: "smooth" });
              clearTimeout(timeoutValue);
            }, 500);
          } else {
            toast.error("something went wrong");
          }
        }
      );
    }
  };

  const calculateDateProgress = () => {};
  const handleLinkTaskClick = async (task) => {
    // console.log("task id =", taskId);
    const values = await DashboardServices.getTaskById(task.taskId);
    dispatch(pushLinkTask(data));
    dispatch(setTaskModalData({ ...task, ...values.data }));
  };

  const [tempDivWidth, setTempDivWidth] = useState(0);

  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  useEffect(() => {
    console.log("in task Container");
    calculateDateProgress();
    DashboardServices.getAssigneesOfTask(data.taskId).then((response) => {
      setAssigneeList(response.data);
    });

    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        console.log("all contract  = ", response.data);
        setUserList(response.data);
      }
    );

    fetchCommentsAndDocuments();
    getTaskDifference();
  }, [data]);

  return (
    <>
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskControlBtnGrp">
              <div className="taskVDContBtn">
                <Button
                  variant="contained"
                  startIcon={<CheckCircle />}
                  onClick={(e) => {
                    clearAllModals();
                    openApprovalConPop(e);
                  }}
                >
                  {t("mark_ticket_resolved")}
                </Button>

                <Popover
                  id={id}
                  open={open}
                  anchorEl={approvalConPop}
                  onClose={closeApprovalConPop}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <div style={{ padding: "20px" }}>
                    <div className="modelTitle">{t("confirmation")}</div>
                    <div
                      className="modInputFild"
                      style={{ padding: "20px 10px 10px 0px" }}
                    >
                      {t("please_give_closing_remarks")}
                    </div>
                    <div className="elementFormContainer">
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            className="modelTextFild"
                            required
                            label={t("ticket_resolved_remark")}
                            variant="outlined"
                            multiline
                            rows={4}
                            maxRows={7}
                            value={ticketResolvedRemark}
                            onChange={(e) =>
                              setTicketResolvedRemark(e.target.value)
                            }
                          />
                        </FormControl>
                      </div>
                    </div>
                    <br></br>

                    <div className="modActionBtnGrp">
                      <Button
                        className="dfultPrimaryBtn"
                        onClick={closeSupportTicket}
                        disabled={loadingCloseTicket}
                      >
                        {loadingCloseTicket ? t("please_wait") : t("submit")}
                      </Button>
                      <Button
                        className="dfultDarkSecondaryBtn"
                        onClick={closeApprovalConPop}
                        disabled={loadingCloseTicket}
                      >
                        {t("cancel")}
                      </Button>
                    </div>
                  </div>
                </Popover>
              </div>
            </div>

            <div className="taskActionBtnGrp">
              <div className="taskActBtn">
                <IconButton
                  aria-label="Forward"
                  onClick={() => {
                    clearAllModals();
                    setShowForwardSupport(true);
                  }}
                >
                  <Tooltip title={t("forward")} arrow>
                    <ArrowOutward />
                  </Tooltip>
                </IconButton>
              </div>

              <div className="taskActBtn">
                <IconButton
                  aria-label="User"
                  onClick={(e) => {
                    // setOpenUserList(true);
                    // handleClickUl(e);
                    clearAllModals();
                    setShowAssigneeList(true);
                  }}
                >
                  <Tooltip title={t("user")} arrow>
                    <People />
                  </Tooltip>
                </IconButton>
              </div>

              <div className="taskActBtn closeBtnD10">
                <IconButton
                  aria-label="close"
                  onClick={(e) => {
                    setShowConvenientMeetingView(false);
                    convinientSelecteduser([]);
                    dispatch(clearTaskModalData());
                  }}
                >
                  <Tooltip title={t("close")} arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>
          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                {links && links.length > 0 && (
                  <div className="linktaskBreadCrumb">
                    <IconButton onClick={gotoPreviousTAsk}>
                      <ArrowLeft />
                    </IconButton>
                    <div className="prentTaskNM">
                      {links &&
                        links.length > 0 &&
                        links[links.length - 1].taskName}
                    </div>
                  </div>
                )}

                {/* <div className="taskTitleContrct">
                  <div className="taskTitleNM">{data.taskName}</div>
                </div>

                {data && data.taskDescription && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Description</div>
                    <div className="tskElemntInfo">{data.taskDescription}</div>
                  </div>
                )} */}

                <TaskNameModal
                  data={data}
                  refreshData={refreshData}
                  removeFromStore={removeFromStore}
                />

                <DescriptionComponent
                  data={data}
                  refreshData={refreshData}
                  removeFromStore={removeFromStore}
                />

                {data && data.audioNoteFileName && (
                  <div className="taskElementGrp">
                    <div className="tskElemntInfo">
                      <div className="playAudioNotes">
                        <IconButton
                          variant="outlined"
                          onClick={(e) => {
                            DashboardServices.generatePreSignedUrlS3Object(
                              data.audioNoteFileName,
                              loginUserDetail.orgId
                            ).then((response) => {
                              audioUrl.current = response.data;
                              setShowAudioModal(true);
                            });
                          }}
                        >
                          <Play />
                        </IconButton>
                        <span>{t("play_audio_message")}</span>
                      </div>
                    </div>
                  </div>
                )}
                <TaskContractModal
                  data={data}
                  refreshData={refreshData}
                  removeFromStore={removeFromStore}
                />

                {/* {data && data.contractName && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Contract</div>
                    <div className="taskContractNm">{data.contractName}</div>
                  </div>
                )} */}

                <div className="taskElementGrp">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div className="tskElemHeding"> {t("timeline")}</div>
                    {data.incoming === "N" && (
                      <Tooltip arrow title={t("edit_timeline")}>
                        <IconButton
                          className="editTimelineBtn"
                          onClick={() => {
                            setShowEditDeadLine(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                  <div className="tskTimeline">
                    <div className="tskTimBar">
                      <div className="progress">
                        <div
                          // up50 up80 up100
                          className={`progress-done ${
                            dateRemaning < 50
                              ? "up50"
                              : dateRemaning < 80
                              ? "up80"
                              : "up100"
                          }`}
                          style={{ width: `${dateRemaning}%`, opacity: 1 }}
                        ></div>
                      </div>
                    </div>
                    <div className="tskDtSE">
                      <div className="tskDTStart">
                        {getFormattedDate(data.createdAt)}
                      </div>
                      <div className="tskDTEnd">
                        {getFormattedDate(data.completionTime)}
                      </div>
                    </div>
                  </div>
                </div>

                {/* files start */}

                <FileList
                  data={data}
                  tempDivWidth={tempDivWidth}
                  handleChangeFile={handleChangeFile}
                  setShowFileUploadModal={setShowFileUploadModal}
                />

                {/* files end */}

                <PriorityComponent
                  data={data}
                  refreshData={refreshData}
                  removeFromStore={removeFromStore}
                />

                <div className="taskElementsGrpWithTwo">
                  {/* <div className="taskElementGrp">
                    <div className="tskElemHeding">Department</div>
                    <div className="tskElemntInfo">Administration</div>
                  </div> */}
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("activity")}</div>
                    <div className="tskElemntInfo">{data.activityName}</div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  {/* <div className="taskElementGrp">
                    <div className="tskElemHeding">Assigned to</div>
                    <div className="tskElemntInfo">Me</div>
                  </div> */}
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("assigned_by")}</div>
                    <div className="tskElemntInfo">
                      {data && isAssignedByMe(data.assignByUserId)
                        ? t("me")
                        : data.assignByUserName}
                    </div>
                  </div>
                </div>

                {/* {(data.taskForwardStatus === "Y" ||
                  data.taskForwardStatus === "C") && (
                  <>
                  </>
                )} */}
                <ForwardSupportModule
                  commentsAndDocuments={commentsAndDocuments}
                  showSupportFileUploadDialog={showSupportFileUploadDialog}
                  setSupportShowFileUploadDialog={
                    setSupportShowFileUploadDialog
                  }
                  updateSupportDocumentId={(item) => {
                    setSupportPersonData(item);
                  }}
                  prevDocuments={prevDocuments}
                  setPrevDocuments={setPrevDocuments}
                  taskId={data.taskId}
                  // data={item}
                  refreshFormData={() => {
                    fetchCommentsAndDocuments();
                  }}
                />

                <LinkedTaskModule
                  data={data}
                  handleLinkTaskClick={(data) => handleLinkTaskClick(data)}
                />

                {data && data.comments && data.comments.length && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("last_updated")}</div>
                    <div className="tskElemntInfo">
                      {data &&
                        data.comments &&
                        data.comments.length > 0 &&
                        `${
                          data.comments[data.comments.length - 1]
                            .commentsByUserName
                        } - ${getFormattedDate(
                          data.comments[data.comments.length - 1].commentsAt
                        )}`}
                      {/* Sandeep Mohapatra - 2:15PM, 06-12-2023 */}
                    </div>
                  </div>
                )}

                <div className="taskUpCommentGrp">
                  {data &&
                    data.comments.length > 0 &&
                    data.comments.map((comment) => (
                      <div className="taskUserComment">
                        <div className="tskUderImgDT">
                          <div className="tskUseComImg">
                            <img
                              src={comment.commentsByUserProfileUrl}
                              alt=""
                            />
                          </div>
                          <div className="tskUsrCNM">
                            <div className="tskUCNM">
                              {comment.commentsByUserName}
                            </div>
                            <div className="tskUCDT">
                              {comment && getFormattedDate(comment.commentsAt)}
                            </div>
                          </div>
                        </div>
                        <div className="taskUPComnt">{comment.comments}</div>
                      </div>
                    ))}
                </div>
                <div ref={scrollRef}></div>
              </div>
            </div>
          </div>
          <div className="taskFooterPanel">
            <div className="tskInputGrp">
              <input
                ref={commentInputRef}
                type="text"
                className="tskComntInput"
                placeholder={t("update_comment_here")}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    console.log("Enter key pressed");
                    sendComment(event);
                  }
                }}
              />
              <IconButton className="tskComtSndBtn" onClick={sendComment}>
                <Send />
              </IconButton>
            </div>
          </div>
        </div>

        {showMeetingModal && (
          <MeetingModal
            handleClose={(e) => setShowMeetingsModal(false)}
            assigneeList={assigneeList}
            taskId={data.taskId}
          />
        )}

        {showConcernModal && (
          <ConcernTaskModal
            data={data}
            refreshData={refreshData}
            handleClose={(e) => setShowConcernModal(false)}
            removeFromStore={removeFromStore}
          />
        )}

        {showAssigneeList && (
          <AssigneeModal
            assigneeList={assigneeList}
            handleClose={(e) => setShowAssigneeList(false)}
          />
        )}

        {showAudioModal && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("audio_message")}</div>
              <div className="modActionBtnGrp">
                <audio controls>
                  <source src={`${audioUrl.current}`} />
                </audio>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    setShowAudioModal(false);
                  }}
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </Box>
        )}

        {showEditDeadline && (
          <EditDeadLineModal
            createdAt={data.createdAt}
            completionTime={data.completionTime}
            closeModal={() => {
              setShowEditDeadLine(false);
            }}
            onClose={(e) => setShowEditDeadLine(false)}
            userList={userList}
            handleClose={(e) => setShowEditDeadLine(false)}
            previousAssigness={assigneeList}
            data={data}
            refreshData={refreshData}
            removeFromStore={removeFromStore}
          />
        )}
      </div>

      {showFileModal && (
        <div className="RightFlotingContainerArea">
          <RightTaskFileUpload
            documents={data.documents}
            onClose={(e) => setShowFileUploadModal(false)}
            uploadFile={(data) => {
              handleChangeFile(data);
            }}
          />
        </div>
      )}

      {showForwardSupport && (
        <ForwardSupportModal
          data={data}
          userList={userList}
          refreshData={refreshData}
          handleClose={() => {
            setShowForwardSupport(false);
          }}
          clearStoreData={clearStoreData}
        />
      )}

      {showSupportFileUploadDialog && (
        <div className="RightFlotingContainerArea">
          <RightTaskFileUpload
            documents={prevDocuments}
            onClose={(e) => setSupportShowFileUploadDialog(false)}
            uploadFile={(data) => {
              handleUpdateSupport(data);
            }}
          />
        </div>
      )}
    </>
  );
}
