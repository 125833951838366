import {
  AccessibleRounded,
  ArrowBack,
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  FemaleRounded,
  MaleRounded,
  MonetizationOnOutlined,
  StarRounded,
  TranslateRounded,
} from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";

import { useState } from "react";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Slider from "react-slick";
import DashboardServices from "../../../../../services/DashboardServices";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import EXP from "../../../../dashboard/image/EXP";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";
import { useContext } from "react";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

const GenderIcon = ({ patientGender }) => {
  const { t } = useTranslation();
  let icon;

  if (patientGender.toLowerCase() === t("male").toLowerCase()) {
    icon = <MaleRounded />;
  } else if (patientGender.toLowerCase() === t("female").toLowerCase()) {
    icon = <FemaleRounded />;
  }

  return icon;
};

export default function ViewBookedAppointmentConsultation({
  handelCloseViewBookedAppointmentConsultation,
  closeAll,
  openAppoinmentBooking,
  refreshBookedAppoinments,
}) {
  const [monthSelect, setMonthSelect] = useState(new Date());
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const { t, i18n } = useTranslation();
  const [selectDoctor, setSelectDoctor] = useState("");
  const loginUserDetail = GetLoginUserDetails();
  const [doctors, setDoctors] = useState([]);
  const [searchDoctorString, setSearchDoctorString] = useState("");

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  useEffect(() => {
    setBookSteps([
      {
        target: ".nsviewAppointmentstepOne",
        content: t("this_is_where_you_can_view_all_the_appointments"),
        // placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".nsviewAppointmentstepTwo",
        content: t("search_for_a_doctor_by_name_or_specialty_here"),
        // placement: "right",
        disableBeacon: true,
      },
      {
        target: ".nsviewAppointmentstepThree",
        content: t("you_can_select_date_to_filter_appointments_by_selected_date"),
        // placement: "right",
        disableBeacon: true,
      },
      {
        target: ".nsviewAppointmentstepFour",
        content: t("here_you_can_see_a_list_of_booked_doctors_slots"),
        // placement: "top",
        disableBeacon: true,
      },
      {
        target: ".nsviewAppointmentstepFive",
        content: t("click_on_a_doctors_card_to_view_booked_slots"),
        // placement: "right",
        disableBeacon: true,
      },
      {
        target: ".nsviewAppointmentstepSix",
        content: t("select_a_time_slot_for_viewing_details_of_the_booked_slot"),
        // placement: "top",
        disableBeacon: true,
      },
    ]);
  }, [t]);
  

  // useEffect(() => {
  //   setBookSteps([
  //     {
  //       target: ".nsviewAppointmentstepOne",
  //       content: "This is where you can view All the Appointments",
  //       placement: "bottom",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".nsviewAppointmentstepTwo",
  //       content: "Search for a doctor by name or specialty here.",
  //       placement: "right",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".nsviewAppointmentstepThree",
  //       content: "You can select Date to filter appointments by Selected date.",
  //       placement: "right",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".nsviewAppointmentstepFour",
  //       content: "Here you can see a list of Booked doctors Slots.",
  //       placement: "top",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".nsviewAppointmentstepFive",
  //       content: "Click on a doctor's card to view Booked slots.",
  //       placement: "right",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".nsviewAppointmentstepSix",
  //       content: "Select a time slot for viewing details of the Booked Slot.",
  //       placement: "top",
  //       disableBeacon: true,
  //     },
  //   ]);
  // }, []);

  const filteredDoctor = useMemo(() => {
    let temp = [];
    if (!searchDoctorString || searchDoctorString === "") {
      return doctors;
    } else {
      temp = doctors?.filter((item) => {
        return (
          item.doctorName
            ?.toLowerCase()
            .includes(searchDoctorString?.toLowerCase()) ||
          item.specialization
            ?.toLowerCase()
            .includes(searchDoctorString?.toLowerCase())
        );
      });
    }
    console.log("filtered doctor list =", temp);
    return temp || [];
  }, [searchDoctorString, doctors]);

  const handleChangselectDoctor = (event) => {
    setSelectDoctor(event.target.value);
  };
  const handleMonthSelect = (newDate) => {
    setMonthSelect(newDate);
  };

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 600,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    draggable: false,
    nextArrow: <ArrowForwardIosRounded />,
    prevArrow: <ArrowBackIosNewRounded />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 0,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  const getDoctorAppoinment = (date) => {
    // const toastLoaderId = toast.loading("Fetching appoinment data");
    DashboardServices.getDoctorsAppointmentOnDate(
      loginUserDetail.signleUnitId,
      date
    )
      .then((response) => {
        if (response.data) {
          console.log("Dcotor data =", response.data);
          let tempDoctorData = [];
          response.data.forEach((item) => {
            let languages = item?.language?.split(",") || [];
            const temp = item?.slots?.filter((slot) => {
              // slot.
              return slot.patientId !== 0;
            });
            if (temp?.length > 0) {
              tempDoctorData.push({
                ...item,
                slots: temp,
                language: languages,
              });
            }
          });

          setDoctors(tempDoctorData);
        }
      })
      .catch((e) => {
        console.log(e);
        // toast.update(toastLoaderId, {
        //   render: "Could not fetch data",
        //   type: "error",
        //   isLoading: false,
        //   autoClose: true,
        // });
      });
  };

  useEffect(() => {
    getDoctorAppoinment(dayjs(monthSelect).format("DD-MM-YYYY"));
  }, [refreshBookedAppoinments]);

  useEffect(() => {
    getDoctorAppoinment(dayjs(new Date()).format("DD-MM-YYYY"));
  }, []);

  console.log("poiuytrewq", run["viewappointNs"], run);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["viewappointNs"] === true}
        continuous={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="AnalyticsDashboardContainer nsviewAppointmentstepOne">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton
            onClick={() => {
              handelCloseViewBookedAppointmentConsultation();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("view_appointments")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea viewAppointmentsContainer">
            <div className="cuHeaderGrp">
              <div className="formSearchGrp">
                <div className="formElement width250">
                  <FormControl className="formControl">
                    {/* <InputLabel id="selectDoctor">Select Doctor</InputLabel> */}
                    <TextField
                      label={t("search_doctor_or_speciality")}
                      variant="outlined"
                      className="formTextFieldArea nsviewAppointmentstepTwo"
                      value={searchDoctorString}
                      // onChange={(e) => setTaskName(e.target.value)}
                      onChange={(e) => {
                        setSearchDoctorString(e.target.value);
                        // handelChangeTaskName(e);
                      }}
                      autoComplete="off"
                    />
                  </FormControl>
                </div>
                <div className="formElement width180">
                  <FormControl className="formControl selectMonth">
                    <InputLabel id="monthSelect" className="setCompletionDate">
                      {t("select_date")}
                    </InputLabel>
                    <ReactDatePicker
                      locale={currentLocale}
                      showYearDropdown
                      scrollableYearDropdown
                      showMonthDropdown
                      toggleCalendarOnIconClick
                      yearDropdownItemNumber={100}
                      scrollableMonthYearDropdown
                      showIcon
                      labelId="monthSelect"
                      className="formDatePicker nsviewAppointmentstepThree"
                      selected={monthSelect}
                      minDate={
                        new Date(new Date().setMonth(new Date().getMonth() - 4))
                      }
                      maxDate={
                        new Date(new Date().setMonth(new Date().getMonth() + 4))
                      }
                      dateFormat="dd-MMM-yyyy"
                      onChange={(date) => {
                        handleMonthSelect(date);
                        getDoctorAppoinment(dayjs(date).format("DD-MM-YYYY"));
                      }}
                    />
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="cuContainArea">
              {filteredDoctor?.length > 0 && (
                <div className="doctor-carousel BaDocCarousel nsviewAppointmentstepFour">
                  <Slider
                    {...settings}
                    className="doclistCusSlider nsviewAppointmentstepFour"
                  >
                    {filteredDoctor?.map((doctor, index) => (
                      <div
                        key={index}
                        className="doctor-cardBA nsviewAppointmentstepFive"
                      >
                        <div className="doctor-infoBA">
                          <div className="docImage">
                            <img
                              src={doctor.imageLink}
                              alt={doctor.doctorName}
                            />
                          </div>

                          <div className="docNameOther">
                            <div className="doctorNmSpc">
                              <div className="docName">{doctor.doctorName}</div>
                              <div className="docSpclty">
                                {doctor.specialization}
                              </div>
                            </div>
                            <div className="docExpFeeLan">
                              {doctor?.language &&
                                doctor?.language.length > 0 && (
                                  <div className="docEFL langW100p">
                                    <div className="eflicon">
                                      <TranslateRounded />
                                    </div>
                                    <div className="eflText">
                                      {/* {doctor.language.join(", ")} */}
                                      {doctor?.language &&
                                        doctor?.language.length > 0 &&
                                        doctor?.language.map(
                                          (langName, index) => {
                                            return (
                                              t(
                                                langName
                                                  .replace(/\s+\(/g, "_")
                                                  .replace(/\)/g, "")
                                                  .replace(/\s+/g, "_")
                                              ) +
                                              (index ===
                                              doctor?.language.length - 1
                                                ? ""
                                                : ", ")
                                            );
                                          }
                                        )}
                                    </div>
                                  </div>
                                )}
                              <div className="docEFL">
                                <div className="eflicon fillnone">
                                  <EXP />
                                </div>
                                <div className="eflText">
                                  {doctor.experience}
                                </div>
                              </div>

                              <div className="docEFL">
                                <div className="eflicon">
                                  <MonetizationOnOutlined />
                                </div>
                                <div className="eflText">
                                  {doctor.fees}
                                  <span>{doctor.feesCurrency}</span>
                                </div>
                              </div>
                              <div className="docEFL">
                                <div className=" docRatvalue">
                                  <StarRounded />
                                </div>
                                <div className="eflText">
                                  {(parseFloat(doctor.rating) || 0).toFixed(1)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="slots-container nsviewAppointmentstepSix">
                          {doctor.slots.map((slot, idx) => (
                            <div
                              onClick={() => {
                                const { slots, ...doctorData } = doctor;
                                openAppoinmentBooking({
                                  ...doctorData,
                                  slot,
                                  date: monthSelect,
                                });
                                if (run["viewappointNs"] === true) {
                                  setRun({
                                    viewappointDetailsNs: true,
                                  });
                                }
                              }}
                              key={idx}
                              // className={`slot-box ${
                              //   !slot.patientId ? "" : "booked"
                              // }`}
                              className={`slot-box ${
                                !slot.patientId ? "" : "booked"
                              } ${
                                slot.rescheduleDate && slot.rescheduleReqRmks
                                  ? "RescheduleRequest"
                                  : ""
                              }`}
                            >
                              <div className="slotInfo">
                                <div class="icon">
                                  <AccessibleRounded />
                                </div>
                                <div className="slotTime">{`${slot.fromTime}-${slot.toTime}`}</div>
                              </div>
                              <div className="bookedPatInfo">
                                <div className="patDtl">
                                  <div className="patgender">
                                    <GenderIcon
                                      patientGender={
                                        slot?.patientGender === "Male"
                                          ? t("male")
                                          : slot?.patientGender === "Female"
                                          ? t("female")
                                          : t("other")
                                      }
                                    />
                                  </div>

                                  <div className="patName">
                                    {slot.patientName}{" "}
                                    {slot.rescheduleDate &&
                                      slot.rescheduleReqRmks && (
                                        <AutorenewIcon />
                                      )}
                                  </div>
                                </div>
                                <div className="patId">
                                  {" "}
                                  {slot.patientExternalSourceId &&
                                    slot.patientExternalSourceId}
                                  {!slot.patientExternalSourceId &&
                                    slot.patientIdDisplay &&
                                    slot.patientIdDisplay}
                                </div>
                                <div className="patAge">{slot.patientAge}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              )}
              {!filteredDoctor ||
                (filteredDoctor?.length === 0 && (
                  <div class="noDataCard ">
                    {/* <span>Oops !!</span> */}
                    <br />
                    <span className="sallMsg">
                      {t("no_appointments_found")}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
