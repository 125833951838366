import {
  AccountTreeRounded,
  Add,
  Biotech,
  Cancel,
  Edit,
  Save,
} from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import zhPatientService from "../../../../../services/ZhPatientService";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import { Search, Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import DashboardServices from "../../../../../services/DashboardServices";
import RightFlotingAdmin from "../RightFlotingAdmin";
// import UnitWiseInvestication from "./unitWiseInvestication";
import UnitWiseInvesticationTest from "./UnitWiseInvesticationTest";
import { EditTestParameters } from "./EditTestParameters";
import { EditTest } from "./EditTest";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../../common/TourProvider";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 320,
    maxHeight: 200,
    overflow: "overlay",
    padding: "6px 10px",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export default function HealthCareConfigInvestigation() {
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const { t } = useTranslation();
  const loginUserDetails = GetLoginUserDetails();
  const [investicationData, setInvesticationData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [testNameValue, setTestNameValue] = useState("");
  const [groupNameValue, setGroupNameValue] = useState("");
  const [diskNameValue, setDiskNameValue] = useState("");
  const [genderValue, setGenderValue] = useState("");
  const [unitList, setUnitList] = useState(false);
  const [unitDataList, setUnitDataList] = useState(null);
  const [editTestParameter, setEditTestParameter] = useState(false);
  const [testData, setTestData] = useState(null);
  const [page, setPage] = useState(1);
  const rowsPerPage = 50;
  const [showEditTest, setShowEditTest] = useState();
  const [searchTestString, setSearchTestString] = useState("");
  const [showTestString, setShowTestString] = useState(false);

  const fetchOrgTests = () => {
    const reqDto = {
      orgId: loginUserDetails.orgId,
      countryCode: loginUserDetails.orgCountryCode,
    };
    zhPatientService.getOrgTestList(reqDto).then((response) => {
      console.log("response.data", response.data);
      setInvesticationData(response.data);
    });
  };

  useEffect(() => {
    fetchOrgTests();
  }, []);

  useEffect(() => {
    if (!searchTestString) {
    }
  }, [searchTestString]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const filteredTests = useMemo(() => {
    if (searchTestString === "") {
      return investicationData;
    } else {
      const temp = investicationData?.filter((item) =>
        item.testName?.toLowerCase()?.includes(searchTestString.toLowerCase())
      );
      return temp || [];
    }
  }, [searchTestString, investicationData]);

  const displayedRows = useMemo(() => {
    return filteredTests?.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  }, [filteredTests, page]);

  const handleEdit = (index, value) => {
    // setEditIndex(index);
    // setTestNameValue(value.testName || "");
    // setGroupNameValue(value.groupName || "");
    // setDiskNameValue(value.diskName || "");
    // setGenderValue(value.gender || "");
    setShowEditTest(true);
  };

  const handleSave = (index) => {
    setEditIndex("");
    setTestNameValue("");
    setGroupNameValue("");
    setDiskNameValue("");
    setGenderValue("");
  };

  useEffect(() => {
    setBookSteps([
      {
        target: ".ViewInvestConfigStepOne",
        content: t('view_invest_config_step_one'), // Use translation keys
        disableBeacon: true,
      },
      {
        target: ".ViewInvestConfigStepTwo",
        content: t('view_invest_config_step_two'), // Use translation keys
        disableBeacon: true,
      },
      {
        target: ".ViewInvestConfigStepThree",
        content: t('view_invest_config_step_three'), // Use translation keys
        disableBeacon: true,
      },
    ]);
  }, [t]);

  // useEffect(() => {
  //   setBookSteps([
  //     {
  //       target: ".ViewInvestConfigStepOne",
  //       content:
  //         "This is the table where you can view all test configurations.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".ViewInvestConfigStepTwo",
  //       content: "Use this pagination to navigate between pages of data.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".ViewInvestConfigStepThree",
  //       content:
  //         "These buttons allow you to edit, manage test parameters, or assign tests to units.",
  //       disableBeacon: true,
  //     },
  //   ]);
  // }, []);

  // useEffect(() => {
  //   fetchMapMenuForUser();
  // }, [loginUserDetails.orgId]);

  // const fetchMapMenuForUser = () => {
  //   return;
  //   if (loginUserDetails.orgId) {
  //     DashboardServices.getAllUnitsMappedWithTest(
  //       loginUserDetails.orgId
  //       // addUnitInvestigationAdminData.testId
  //     )
  //       .then((response) => {
  //         console.log("response", response.data);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching activeFlag menus:", error);
  //       });
  //   }
  // };

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["viewInvesticationConfigAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="TableContainer organizationPosition responsiveTable ViewInvestConfigStepOne">
        <table className="taskListTable ">
          <thead className="taskListtableHeader">
            <tr>
              <th className="mxWidth250">
                {!showTestString && (
                  <div className="orgPText">
                    <div className="txtWithBtn">{t("test_name")}</div>

                    <ButtonGroup
                      variant="contained"
                      className="buttonContainerGrp"
                    >
                      <Tooltip
                        arrow
                        title={t("search")}
                        onClick={() => {
                          setShowTestString(true);
                        }}
                      >
                        <IconButton>
                          <Search />
                        </IconButton>
                      </Tooltip>
                    </ButtonGroup>
                  </div>
                )}
                {showTestString && (
                  <div className="orgPText">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          label={t("test_name")}
                          required
                          autoComplete="off"
                          variant="outlined"
                          className="formTextFieldArea"
                          value={searchTestString}
                          onChange={(e) => {
                            setSearchTestString(e.target.value);
                          }}
                          //   disabled
                        />
                      </FormControl>
                    </div>
                    <ButtonGroup
                      variant="contained"
                      className="buttonContainerGrp"
                    >
                      <Tooltip arrow title={t("save")}>
                        <IconButton
                          onClick={() => {
                            setShowTestString(false);
                            setSearchTestString("");
                          }}
                        >
                          <Cancel />
                        </IconButton>
                      </Tooltip>
                    </ButtonGroup>
                  </div>
                )}
              </th>
              <th className="mxWidth250">
                <div className="txtWithBtn ">{t("group_name")}</div>
              </th>
              <th className="mxWidth150">
                <div className="txtWithBtn">{t("parameter_gender")}</div>
              </th>
              <th className="mxWidth150">
                <div className="txtWithBtn">{t("disk_name")}</div>
              </th>
              <th className="mxWidth150">
                <div className="txtWithBtn">{t("action")}</div>
              </th>
            </tr>
          </thead>
          <tbody className="scrolableTableBody">
            {displayedRows.map((vital, index) => (
              <tr key={index}>
                <td>
                  {editIndex === index ? (
                    <div className="orgPText">
                      <TextField
                        className="formInputSelect"
                        autoComplete="off"
                        size="small"
                        value={testNameValue}
                        onChange={(e) => setTestNameValue(e.target.value)}
                      />
                    </div>
                  ) : (
                    <div className="orgPText">{vital.testName}</div>
                  )}
                </td>
                <td>
                  {editIndex === index ? (
                    <div className="orgPText">
                      <TextField
                        className="formInputSelect"
                        autoComplete="off"
                        size="small"
                        value={groupNameValue}
                        onChange={(e) => setGroupNameValue(e.target.value)}
                      />
                    </div>
                  ) : (
                    <div className="orgPText">
                      {vital.groupName ? vital.groupName : ""}
                    </div>
                  )}
                </td>
                <td>
                  {editIndex === index ? (
                    <Select
                      className="formInputSelect"
                      hiddenLabel
                      size="small"
                      value={genderValue}
                      onChange={(e) => setGenderValue(e.target.value)}
                    >
                      <MenuItem value={"C"}>{t("both")}</MenuItem>
                      <MenuItem value={"F"}>{t("female")}</MenuItem>
                      <MenuItem value={"M"}>{t("male")}</MenuItem>
                    </Select>
                  ) : (
                    <div className="orgPText">
                      {" "}
                      {vital?.gender
                        ? vital.gender.toLowerCase() === "c"
                          ? t("both")
                          : vital.gender.toLowerCase() === "m"
                          ? t("male")
                          : t("female")
                        : ""}
                    </div>
                  )}
                </td>
                <td>
                  {editIndex === index ? (
                    <div className="orgPText">
                      <TextField
                        className="formInputSelect"
                        autoComplete="off"
                        size="small"
                        value={diskNameValue}
                        onChange={(e) => setDiskNameValue(e.target.value)}
                      />
                    </div>
                  ) : (
                    <div className="orgPText">
                      {vital.diskName ? vital.diskName : ""}
                    </div>
                  )}
                </td>
                <td>
                  <div className="orgPText">
                    <ButtonGroup
                      variant="contained"
                      className="buttonContainerGrp ViewInvestConfigStepThree"
                    >
                      {editIndex === index ? (
                        <Tooltip arrow title={t("save")}>
                          <IconButton onClick={() => handleSave(index)}>
                            <Save />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip arrow title={t("edit")}>
                          <IconButton
                            onClick={() => {
                              handleEdit(index, vital);
                              setTestData(vital);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      )}

                      <Tooltip arrow title={t("edit_test_parameters")}>
                        <IconButton
                          onClick={() => {
                            setEditTestParameter(true);
                            setTestData(vital);
                          }}
                        >
                          <Biotech />
                        </IconButton>
                      </Tooltip>

                      <Tooltip arrow title={t("add_test_to_unit")}>
                        <IconButton
                          onClick={() => {
                            setUnitList(true);
                            setUnitDataList(vital);
                          }}
                        >
                          <AccountTreeRounded />
                        </IconButton>
                      </Tooltip>
                    </ButtonGroup>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="paginationContainer ViewInvestConfigStepTwo">
          <Pagination
            count={Math.ceil(filteredTests.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
          />
        </div>
      </div>

      {unitList && (
        <RightFlotingAdmin>
          <UnitWiseInvesticationTest
            handleCloseUnitInvestigationAdmin={() => setUnitList(false)}
            addUnitInvestigationAdminData={unitDataList}
          />
        </RightFlotingAdmin>
      )}

      {editTestParameter && (
        <RightFlotingAdmin>
          <EditTestParameters
            handleClose={(shouldUpdate = false) => {
              setEditTestParameter(false);
              if (shouldUpdate) {
                fetchOrgTests();
              }
              setTestData(null);
            }}
            testData={testData}
          />
        </RightFlotingAdmin>
      )}
      {showEditTest && (
        <RightFlotingAdmin>
          <EditTest
            handleClose={(shouldUpdate = false) => {
              setShowEditTest(false);
              if (shouldUpdate) {
                fetchOrgTests();
              }
              setTestData(null);
              fetchOrgTests();
            }}
            testData={testData}
          />
        </RightFlotingAdmin>
      )}
    </>
  );
}
