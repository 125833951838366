import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Trash, XLg } from "react-bootstrap-icons";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import { useTranslation } from "react-i18next";
import zhPatientService from "../../../../../services/ZhPatientService";
import { getHealthDto } from "../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import { Add } from "@mui/icons-material";
import { toast } from "react-toastify";

export const EditTestParameters = ({ handleClose, testData }) => {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();
  const [userData, setUserData] = useState([]);
  const [parameterData, setParameterData] = useState([]);
  const [parameterReferenceForTest, setParameterReferemceForTest] = useState(
    []
  );

  const [selectedParameter, setSelectedParameter] = useState(null);
  const [parameterInputString, setParameterInputString] = useState("");

  const [showParameterToAdd, setShowParameterToAdd] = useState(true);

  const [loading, setLoading] = useState(false);

  function convertDaysToYMD(totalDays) {
    const daysInYear = 365;
    const daysInMonth = 30; // Approximation for a month

    const years = Math.floor(totalDays / daysInYear);
    totalDays %= daysInYear; // Remaining days after calculating years

    const months = Math.floor(totalDays / daysInMonth);
    const days = totalDays % daysInMonth; // Remaining days after calculating months

    return { years, months, days };
  }

  const fetchParameters = async () => {
    const reqDto = await getHealthDto();
    zhPatientService.getParameterMaster(reqDto).then((response) => {
      if (response.data) {
        setParameterData(response.data);
      }
    });
  };

  const calculateValueRange = (ll, ul) => {
    if (ul?.includes("<")) {
      return `${ll}${ul}`;
    } else {
      return `${ll}-${ul}`;
    }
  };

  const calculateAgeLimit = (startAge, endAge) => {
    // let endString = endAge;

    // if (endAge?.includes("<")) {
    //   const endDate = endAge.split("<");
    //   endString = endDate[endDate.length - 1];
    // }

    const startAgeYMD = convertDaysToYMD(parseInt(startAge));
    // const endAgeYMD = convertDaysToYMD(parseInt(endString));
    const endAgeYMD = convertDaysToYMD(parseInt(endAge));

    return `${startAgeYMD.years}Y ${startAgeYMD.months}M ${startAgeYMD.days}D -  ${endAgeYMD.years}Y ${endAgeYMD.months}M ${endAgeYMD.days}D`;
  };

  useEffect(() => {
    fetchParameters();
  }, []);

  useEffect(() => {
    if (testData) {
      console.log("test data =", testData);
      zhPatientService
        .getOrgTestParametersWithReferanceRange(testData.testId)
        .then((response) => {
          if (response.data) {
            setParameterReferemceForTest(response.data || []);
          }
        });
    }
  }, [testData]);

  const filteredParameterList = useMemo(() => {
    const alreadySelectedParameterIds = parameterReferenceForTest?.map(
      (item) => item.parameterId
    );
    const notSelectedParameters = parameterData?.filter((item) => {
      if (alreadySelectedParameterIds.includes(item.parameterId)) {
        return false;
      }
      return true;
    });

    let filteredAccordingToSearchString = notSelectedParameters;

    if (parameterInputString) {
      filteredAccordingToSearchString = notSelectedParameters?.filter((item) =>
        item.parameterName.includes(parameterInputString)
      );
    }

    return filteredAccordingToSearchString?.slice(0, 30);
  }, [parameterData, parameterInputString, parameterReferenceForTest]);

  return (
    <div className="flotingAreaContainer">
      <div className="flotingAreaClose">
        <div className="facHeader">{t("edit_test")}</div>
        <IconButton className="CloseBtn" onClick={() => handleClose()}>
          <XLg />
        </IconButton>
      </div>

      <div className="elementFormContainer gap10">
        {/* <div className="tskElemHeding mb-8">{t("disease_with_icd11")}</div> */}

        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              // hiddenLabel
              label={t("test_name")}
              //    required
              autoComplete="off"
              variant="outlined"
              className="formTextFieldArea"
              value={testData.testName}
              //   onChange={(e) => setTestNameData(e.target.value)}
              disabled
            />
          </FormControl>
        </div>

        <div className="taskContainEditBtnGrp">
          <div className="tskElemHeding">{t("parameter")}</div>
          {/* <Tooltip arrow title={t("add")}>
            <IconButton
              className="editTimelineBtn"
              onClick={() => {
                // setShowEditContract((prev) => !prev);
                setShowParameterToAdd(true);
              }}
            >
              <Add />
            </IconButton>
          </Tooltip> */}
        </div>

        <div className="formElementGrp mb-8">
          <div className="formElement">
            <FormControl className="formControl">
              <Autocomplete
                freeSolo
                className="formAutocompleteField"
                variant="outlined"
                options={filteredParameterList}
                value={selectedParameter}
                onChange={(e, newValue) => {
                  setSelectedParameter(newValue);
                }}
                inputValue={parameterInputString}
                onInputChange={(e, value) => {
                  setParameterInputString(value);
                }}
                getOptionLabel={(option) => option.parameterName || ""}
                filterOptions={(options, { inputValue }) => {
                  return (
                    options?.filter((option) =>
                      option?.parameterName
                        ?.toLowerCase()
                        ?.includes(inputValue?.toLowerCase())
                    ) || []
                  );
                }}
                renderOption={(props, option) => {
                  return (
                    <li
                      {...props}
                      style={{ padding: "4px 12px" }}
                      className="sDocOptList"
                    >
                      <div className="sDname">{option.parameterName}</div>
                      {/* <div className="sDname">{"Reference Name"}</div> */}

                      <div className="sDElmGrp">
                        <div className="sDElm">
                          {t("parameter_references")}:{" "}
                          {/* <span>{option.experience}</span> */}
                        </div>
                      </div>

                      {option.parameterReferances?.map((item) => {
                        return (
                          <div className="sDElmGrp">
                            <div className="sDElm">
                              {`${item.rangeType} -- ( gender: ${
                                item.gender === "C"
                                  ? "Both"
                                  : item.gender === "M"
                                  ? "Male"
                                  : "Female"
                              } ) - (${calculateAgeLimit(
                                item.ageLl,
                                item.ageUl
                              )})`}
                            </div>
                          </div>
                        );
                      })}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("search_parameter")}
                    className="formAutoComInputField autocompFildPlaceholder"
                  />
                )}
              />
            </FormControl>
          </div>
          <Button
            startIcon={<Add />}
            className="dfultPrimaryBtn"
            onClick={() => {
              if (!selectedParameter) {
                toast.error(t("please_add_parameter"));
                return;
              }
              const temp = [...parameterReferenceForTest];
              temp.push(selectedParameter);
              setParameterReferemceForTest(temp);
              setSelectedParameter(null);
              setParameterInputString("");
            }}
          >
            {t("add")}
          </Button>
        </div>

        <div className="formElement">
          {parameterReferenceForTest?.map((item, index) => {
            return (
              <div>
                <div className="taskContainEditBtnGrp">
                  <div className="tskElemHeding">{item.parameterName}</div>

                  <Tooltip arrow title={t("delete_parameter")}>
                    <IconButton
                      className="editTimelineBtn"
                      onClick={() => {
                        const temp = [...parameterReferenceForTest];
                        temp.splice(index, 1);
                        setParameterReferemceForTest(temp);
                      }}
                    >
                      <Trash />
                    </IconButton>
                  </Tooltip>
                </div>
                <div>
                  {item.parameterReferances?.map((reference) => {
                    return (
                      <>
                        <div className="orgPText">
                          <span>{`Gender: ${
                            reference.gender === "C"
                              ? t("both")
                              : reference.gender === "M"
                              ? t("male")
                              : t("female")
                          } ,  Range Type:  ${reference.rangeType}`}</span>
                        </div>
                        {/* {reference.pregnantFlag === "Y" && (
                          <div className="orgPText">
                            {reference.pregnantFlag}
                          </div>
                        )} */}
                        {/* <div className="orgPText">
                          <span>Range Type: </span>
                          <span>{reference.paramUnit}</span>
                        </div> */}
                        <div className="orgPText">
                          <span>{`Unit:  ${
                            reference.paramUnit
                          },  Value: ${calculateValueRange(
                            reference.valLl,
                            reference.valUl
                          )}`}</span>
                          <span></span>
                        </div>
                        {/* <div className="orgPText">
                          <span>Value: </span>
                          <span>
                            
                          </span>
                        </div> */}
                        <div className="orgPText">
                          <span>Age Limit: </span>
                          <span>
                            {calculateAgeLimit(
                              reference.ageLl,
                              reference.ageUl
                            )}
                          </span>
                        </div>
                      </>
                    );
                  })}
                </div>

                <div className="bb"></div>
              </div>
            );
          })}
        </div>

        {/* End procedure */}
      </div>

      <div className="elementFooter">
        <div className="formBtnElement">
          <Button
            className="dfultPrimaryBtn"
            onClick={() => {
              if (!parameterReferenceForTest?.length > 0) {
                toast.error("please_add_parameter");
              }
              const reqDto = parameterReferenceForTest?.map(
                (item) => item.parameterId
              );
              // return;

              zhPatientService
                .saveParametersForTest(testData.testId, reqDto)
                .then((response) => {
                  if (response.data) {
                    toast.success(t("test_parameter_saved"));
                    handleClose(true);
                  } else {
                    toast.error(t("test_parameter_couldnt_be_saved"));
                  }
                });
            }}
          >
            {t("save")}
          </Button>

          <Button
            className="dfultDarkSecondaryBtn"
            onClick={() => handleClose()}
          >
            {t("cancel")}
          </Button>
        </div>
      </div>
    </div>
  );
};
