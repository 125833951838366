import { Edit, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  colors,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import React, { useState, useEffect, useRef } from "react";
import { Download, Upload, XLg } from "react-bootstrap-icons";
import {
  getLeaveBalanceOfAllEmployee,
  getLeaveRulesOfOrg,
  updateLeaveBalance,
  uploadLeaveBalance,
} from "../../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { MAX_FILE_SIZE_LEAVE_REQUEST } from "../../../../../constants/const_values";
import { convertFileToBufferArray } from "../../../../../utils/FileUtils";

import { read, utils, write, writeFile } from "xlsx";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import { getLeaveRulesOfUser } from "../../../../../services/SchedulerService";

export default function ShowLeaveBalance({
  showLeaveBalance,
  handelCloseLeaveBalanceModel,
}) {
  const userDetails = GetLoginUserDetails();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const [showLeavebalanceData, setshowLeavebalanceData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterShowLeavebalanceData, setFilterShowLeavebalanceData] = useState(
    []
  );

  const [excelDataList, setExcelDataList] = useState([]);
  const [downloadExcelDataList, setDownloadExcelDataList] = useState([]);
  const inputExcelRef = useRef(null);
  const [leaveRuleData, setLeaveRuleData] = useState([]);
  const [userLeaveRuleList, setUserLeaveRuleList] = useState([]);

  // useEffect(() => {
  //   fetchLeaveRuleData();
  // }, []);

  // const fetchLeaveRuleData = () => {
  //   getLeaveRulesOfOrg(userDetails.orgId).then((response) => [
  //     setLeaveRuleData(response.data),
  //   ]);
  // };

  useEffect(() => {
    fetchLeaveRuleData();
  }, []);
  
  const fetchLeaveRuleData = () => {
    getLeaveRulesOfOrg(userDetails.orgId).then((response) => {
      const uniqueData = removeDuplicates(response.data);
      setLeaveRuleData(uniqueData);
    });
  };
  
  const removeDuplicates = (data) => {
    const seenIds = new Set();
    const seenDisplays = new Set();
  
    return data.filter((item) => {
      const isDuplicateId = seenIds.has(item.id);
      const isDuplicateDisplay = seenDisplays.has(item.leaveTypeDisplay);
  
      if (!isDuplicateId && !isDuplicateDisplay) {
        seenIds.add(item.id);
        seenDisplays.add(item.leaveTypeDisplay);
        return true;
      }
      return false;
    });
  };
  


  useEffect(() => {
    fetchLeaveBalnOfEmployee();
  }, [userDetails.orgId]);

  const fetchLeaveBalnOfEmployee = () => {
    getLeaveBalanceOfAllEmployee(userDetails.orgId).then((response) => {
      console.log("getLeaveBalanceOfAllEmployee", response.data);
      setshowLeavebalanceData(response.data);
      setFilterShowLeavebalanceData(response.data);
      setLoading(false);
    });
  };

  const [editLeaveBalance, setEditLeaveBalance] = useState(false);
  const [selectLeaveType, setSelectLeaveType] = React.useState("");

  const handleChangeLeaveType = (event) => {
    setSelectLeaveType(event.target.value);
  };

  /* global XLSX */

  const handleDownloadExcelData = () => {
    const data = [];

    // Iterate over each entry in leaveBalanceByType
    for (const key in leaveBalanceByType) {
      if (Object.hasOwnProperty.call(leaveBalanceByType, key)) {
        const entry = leaveBalanceByType[key];
        // Construct the row object
        const row = {
          EMPLOYEE_ID: entry.employeeId,
          EMPLOYEE_NAME: entry.employeeName,
          ...entry.leaveBalances, // Spread the leaveBalances object
        };
        data.push(row); // Push the row object to the data array
      }
    }

    // Check if data array is not empty
    if (data.length > 0) {
      // Convert data to worksheet
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "LEAVE_BALANCE_UPLOAD_TEMPLATE");
      // Generate XLSX file and download
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const dataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(dataBlob);
      link.download = "LEAVE_BALANCE_UPLOAD_TEMPLATE.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("No Data To Download");
    }
  };

  // const handleDownloadExcelData = () => {
  //   if (leaveBalanceByType.length > 0) {
  //     const csvContent =
  //       "data:text/csv;charset=utf-8," + convertToCSV(showLeavebalanceData);
  //     const encodedUri = encodeURI(csvContent);
  //     const link = document.createElement("a");
  //     link.setAttribute("href", encodedUri);
  //     link.setAttribute("download", "LEAVE_BALANCE_UPLOAD_TEMPLATE.csv");
  //     document.body.appendChild(link);
  //     link.click();
  //   } else {
  //     console.error("No Data To Download");
  //   }
  // };

  // const convertToCSV = (data) => {
  //   const header = Object.keys(data[0]).join(",");
  //   const rows = data.map((obj) => Object.values(obj).join(","));
  //   return header + "\n" + rows.join("\n");
  // };

  console.log("showLeavebalanceData", showLeavebalanceData);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (
      !["xls", "xlsx", "xlsm", "xlsb", "xltx"].includes(
        file.name.split(".").pop()
      )
    ) {
      toast.error(t("please_upload_excel_file"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (file.size > 1024 * 1024 * MAX_FILE_SIZE_LEAVE_REQUEST) {
      toast.error(
        // `Please Upload Less Then ${MAX_FILE_SIZE_LEAVE_REQUEST}MB File`,
        t("please_upload_less_than", { size: MAX_FILE_SIZE_LEAVE_REQUEST }),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else {
      setModalOpen(true);
      const byteData = await convertFileToBufferArray(file);
      const wb = read(byteData, { type: "buffer" }); // parse the array buffer
      const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
      const data = utils.sheet_to_json(ws);

      // verify the data and set
      let tmpList = [];
      if (data && data.length > 0) {
        data.forEach((item) => {
          // if (typeof item.leaveBalance !== "number") {
          //   toast.warning(
          //     `Leave Balance Should Be a Number On Row No ${item.__rowNum__}`,
          //     {
          //       position: toast.POSITION.TOP_RIGHT,
          //     }
          //   );
          // } else if (typeof item.LEAVE_RULE_ID !== "number") {
          //   toast.warning(
          //     `Leave Rule ID Should Be a Number On Row No ${item.__rowNum__}`,
          //     {
          //       position: toast.POSITION.TOP_RIGHT,
          //     }
          //   );
          // } else {
          tmpList.push(item);
          // }
        });
      }
      setExcelDataList(tmpList);
      inputExcelRef.current.value = null;
    }
  };

  // const handleFileUpload = (e) => {
  //   console.log('handleFileUpload')
  //   setModalOpen(true);
  //   const file = e.target.files[0];
  //   if (file) {
  //     // Process the file here, e.g., read its contents or upload it to a server
  //     const reader = new FileReader();
  //     reader.onload = function (e) {
  //       const data = new Uint8Array(e.target.result);
  //       const workbook = xlsx.read(data, { type: "array" });

  //       // Assuming you want data from the first sheet
  //       const sheetName = workbook.SheetNames[0];
  //       const sheet = workbook.Sheets[sheetName];

  //       // Parse sheet data
  //       const parsedData = xlsx.utils.sheet_to_json(sheet, { header: 1 });

  //       // Set the fileData state with the parsed data
  //       setFileData(parsedData);
  //     };
  //     reader.readAsArrayBuffer(file);
  //   }
  // };

  const openFileInput = () => {
    inputExcelRef.current.click();
  };

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleExcelFileSubmit = () => {
    const leaveRuleMapping = leaveRuleData.reduce((mapping, rule) => {
      mapping[rule.leaveTypeDisplay] = rule.id;
      return mapping;
    }, {});

    const reqDto = excelDataList.flatMap((employee) => {
      return Object.keys(employee)
        .filter((key) => key !== "EMPLOYEE_ID" && key !== "EMPLOYEE_NAME")
        .map((leaveType) => ({
          employeeId: employee.EMPLOYEE_ID,
          leaveRuleId: leaveRuleMapping[leaveType],
          leaveBalance: employee[leaveType],
        }))
        .filter((record) => record.leaveBalance > 0);
    });

    console.log("New reqDto", reqDto);

    uploadLeaveBalance(userDetails.userId, userDetails.orgId, reqDto).then(
      (response) => {
        console.log("uploadLeaveBalance", response);
        if (response.data) {
          // success message
          if (response.data.returnValue === "1") {
            toast.success(t("leave_balance_upload_successfully"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchLeaveBalnOfEmployee();
            // this.setState({ excelDataList: [] });
            // this.fetchData();
            setModalOpen(false);
          }
          // error message
          if (response.data.returnValue === "0") {
            toast.error(t("failed_to_update_leave_balance_upload"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          if (response.data.returnValue === "-1") {
            toast.error(t("leave_balance_upload_already_exists"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          if (response.data.returnValue === "-2") {
            toast.error(t("failed_to_update_leave_balance_upload"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      }
    );
  };

  // const handleExcelFileSubmit = () => {
  //   console.log("handleExcelFileSubmit");
  //   console.log("excelDataList" , excelDataList);

  //   const transformedData = excelDataList.flatMap((data) => {
  //     const leaveTypes = Object.keys(data).filter(
  //       (key) => key !== "EMPLOYEE_ID" && key !== "EMPLOYEE_NAME"
  //     );
  //     const result = [];

  //     console.log("leaveTypes" , leaveTypes)

  //     leaveTypes.forEach((leaveType) => {
  //       const leaveBalance = data[leaveType];
  //       if (leaveBalance !== undefined && leaveBalance !== null) {
  //         result.push({
  //           employeeId: data["EMPLOYEE_ID"],
  //           employeeName: data["EMPLOYEE_NAME"],
  //           leaveBalance: leaveBalance,
  //           leaveRuleId: 0,
  //           leaveType: leaveType,
  //           userId: "",
  //         });
  //       }
  //     });

  //     return result;
  //   });

  //   console.log("transformedData", transformedData);

  //   const result = transformedData.map((data) => {
  //     const match = showLeavebalanceData.find(
  //       (item) =>
  //         item.employeeId === data.employeeId &&
  //         item.employeeName === data.employeeName &&
  //         item.leaveType === data.leaveType
  //     );

  //     console.log("match" , match);

  //     if (match) {
  //       return {
  //         ...data,
  //         leaveRuleId: match.leaveRuleId,
  //         userId: match.userId, // Assuming userId exists in showLeavebalanceData
  //       };
  //     } else {
  //       return data;
  //     }
  //   });

  //   console.log("result", result);

  //   const reqDTO = result.map((item) => {
  //     return {
  //       employeeId: item.employeeId,
  //       leaveRuleId: item.leaveRuleId,
  //       leaveBalance: item.leaveBalance,
  //     };
  //   });

  //   console.log("reqDTO", reqDTO);
  //   return ;

  //   uploadLeaveBalance(userDetails.userId, userDetails.orgId, reqDTO).then(
  //     (response) => {
  //       console.log("uploadLeaveBalance", response);
  //       if (response.data) {
  //         // success message
  //         if (response.data.returnValue === "1") {
  //           toast.success(t("leave_balance_upload_successfully"), {
  //             position: toast.POSITION.TOP_RIGHT,
  //           });
  //           fetchLeaveBalnOfEmployee();
  //           // this.setState({ excelDataList: [] });
  //           // this.fetchData();
  //           setModalOpen(false);
  //         }
  //         // error message
  //         if (response.data.returnValue === "0") {
  //           toast.error(t("failed_to_update_leave_balance_upload"), {
  //             position: toast.POSITION.TOP_RIGHT,
  //           });
  //         }
  //         if (response.data.returnValue === "-1") {
  //           toast.error(t("leave_balance_upload_already_exists"), {
  //             position: toast.POSITION.TOP_RIGHT,
  //           });
  //         }
  //         if (response.data.returnValue === "-2") {
  //           toast.error(t("failed_to_update_leave_balance_upload"), {
  //             position: toast.POSITION.TOP_RIGHT,
  //           });
  //         }
  //       }
  //     }
  //   );
  // };

  const leaveBalanceByType = filterShowLeavebalanceData.reduce((acc, item) => {
    const key = item.employeeId + "-" + item.employeeName;
    if (!acc[key]) {
      acc[key] = {
        employeeId: item.employeeId,
        employeeName: item.employeeName,
        leaveBalances: {},
        userId: item.userId,
      };
    }
    acc[key].leaveBalances[item.leaveType] = item.leaveBalance;
    return acc;
  }, {});

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = showLeavebalanceData.filter(
      (showLeavebalance) =>
        showLeavebalance.employeeName &&
        showLeavebalance.employeeName
          .toLowerCase()
          .includes(query.toLowerCase())
    );

    setFilterShowLeavebalanceData(filteredData);
  };

  const [selectedEmployee, setSelectedEmployee] = useState({});

  // const updateLeaveBalanceFunction = (employee) => {
  //   console.log("updateLeaveBalanceFunction", employee);
  //   console.log(
  //     "updateLeaveBalanceFunction employeeName",
  //     employee.employeeName
  //   );
  //   console.log("selectedEmployee.employeeId", selectedEmployee.employeeId);
  //   setSelectedEmployee(employee);
  //   setEditLeaveBalance(true);
  //   console.log("EditLeaveBalance set to true:", editLeaveBalance);
  //   getLeaveRulesOfUser(employee.userId).then((response) => {
  //     if (response.data && response.data.length > 0) {
  //       setUserLeaveRuleList(response.data);
  //       console.log("response", response.data);
  //     }
  //   });
  // };

  const updateLeaveBalanceFunction = async (employee) => {
    console.log("updateLeaveBalanceFunction called with employee:", employee);
    
    // Log employee details
    console.log("Employee Name:", employee.employeeName);
    
    // Update the selected employee
    setSelectedEmployee(employee);
    
    // Set the edit leave balance flag
    setEditLeaveBalance(true);
    
    // Fetch and update leave rules
    try {
      const response = await getLeaveRulesOfUser(employee.userId);
      if (response.data && response.data.length > 0) {
        setUserLeaveRuleList(response.data);
        console.log("Fetched Leave Rules:", response.data);
      } else {
        console.log("No leave rules found for the user.");
      }
    } catch (error) {
      console.error("Error fetching leave rules:", error);
    }
  
  };

  useEffect(() => {
    console.log("EditLeaveBalance updated to:", editLeaveBalance);
  }, [editLeaveBalance]);
  
  

  const handleChangeLeaveBalance = (event) => {
    const newValue = event.target.value;
    const updatedLeaveBalances = {
      ...selectedEmployee.leaveBalances,
      [selectLeaveType]: newValue,
    };
    setSelectedEmployee((prevEmployee) => ({
      ...prevEmployee,
      leaveBalances: updatedLeaveBalances,
    }));
  };

  const finalUpdatedSubmit = () => {
    console.log("finalUpdatedSubmit");

    const employeeDataLeaveId = leaveRuleData.find(
      (data) => data.leaveTypeDisplay === selectLeaveType
    );

    // console.log("employeeDataLeaveId", employeeDataLeaveId);

    const reqDto = {
      employeeId: selectedEmployee.employeeId,
      leaveRuleId: employeeDataLeaveId.id,
      leaveBalance: selectedEmployee.leaveBalances[selectLeaveType] || 0,
    };

    console.log("updateLeaveBalance reqDto", reqDto);

    // return

    updateLeaveBalance(userDetails.userId, userDetails.orgId, reqDto).then(
      (response) => {
        console.log(response.data);
        if (response.data) {
          if (response.data.returnValue === "1") {
            toast.success(t("leave_balance_update_successfully"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchLeaveBalnOfEmployee();
            setEditLeaveBalance(false);
            setSelectLeaveType("");
          }
          if (response.data.returnValue === "0") {
            toast.error(t("failed_to_updated_leave_balance_upload"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          if (response.data.returnValue === "-1") {
            toast.error(t("leave_balance_update_already_exists"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          if (response.data.returnValue === "-2") {
            toast.error(t("failed_to_updated_leave_balance_upload"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      }
    );
  };

  return (
    <>
      <div className="modelContainer" open={showLeaveBalance}>
        <Box className="modelContain animated width90vwModel">
          <IconButton
            className="closeModelIoc"
            onClick={handelCloseLeaveBalanceModel}
          >
            <XLg />
          </IconButton>

          <div className="fwModelContainArea">
            <div className="modHeader">
              <div className="modHeaderText">{t("leave_balance")}</div>
              <div className="modHeaderWithAction">
                <div className="modSearchInputgrp">
                  <input
                    type="text"
                    class="modSerchInput"
                    placeholder={t("search_leave_rules")}
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                  <IconButton className="seacrhIcon">
                    <Search />
                  </IconButton>
                </div>
                <div className="modbtnGrp">
                  <Button
                    className="dfultPrimaryBtn"
                    startIcon={<Download />}
                    onClick={handleDownloadExcelData}
                  >
                    {t("download")}
                  </Button>

                  <Button
                    className="dfultPrimaryBtn"
                    startIcon={<Upload />}
                    onClick={openFileInput}
                  >
                    {t("upload")}
                  </Button>
                  <input
                    type="file"
                    accept=".xls,.xlsx,.xlsm,.xlsb,.xltx"
                    ref={inputExcelRef}
                    style={{ display: "none" }} // Optionally hide the input field
                    onChange={handleFileUpload}
                  />
                </div>
              </div>
            </div>
            <div className="modBody">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : (
                <div className="modResptable">
                  <table>
                    {/* <thead>
                      <tr>
                        <th className="minWidth160">{t("employee_name")}</th>
                        <th>{t("employee_id")}</th>
                        <th>{t("privilege_leave")}</th>
                        <th>{t("casual_leave")}</th>
                        <th>{t("sick_leave")}</th>
                        <th>{t("maternity_leave")}</th>
                        <th>{t("Comp_off")}</th>
                        <th>{t("marriage_leave")}</th>
                        <th>{t("paternity_leave")}</th>
                        <th>{t("bereavement_leave")}</th>
                        <th>{t("loss_of_pay")}</th>
                        <th></th>
                      </tr>
                    </thead> */}
                    <thead>
                      <tr>
                        <th className="minWidth160">{t("employee_name")}</th>
                        <th>{t("employee_id")}</th>
                        {leaveRuleData.map((leave) => (
                          <th key={leave.id}>{t(leave.leaveTypeDisplay)}</th>
                        ))}
                        <th></th> {/* Keep the last column as-is */}
                      </tr>
                    </thead>

                    <tbody>
                      {/* {showLeavebalanceData.map((item, index) => (
                     <tr key={index}>
                     <td>{item.employeeName}</td>
                     <td>{item.employeeId}</td>
                     <td className="textAlign">{item.leaveType === 'Privilege Leave' ? item.leaveBalance : '-'}</td>
                     <td className="textAlign">{item.leaveType === 'Casual Leave' ? item.leaveBalance : '-'}</td>
                     <td className="textAlign">{item.leaveType === 'Sick Leave' ? item.leaveBalance : '-'}</td>
                     <td className="textAlign">{item.leaveType === 'Maternity Leave' ? item.leaveBalance : '-'}</td>
                     <td className="textAlign">{item.leaveType === 'Comp-off' ? item.leaveBalance : '-'}</td>
                     <td className="textAlign">{item.leaveType === 'Marriage Leave' ? item.leaveBalance : '-'}</td>
                     <td className="textAlign">{item.leaveType === 'Paternity Leave' ? item.leaveBalance : '-'}</td>
                     <td className="textAlign">{item.leaveType === 'Bereavement Leave' ? item.leaveBalance : '-'}</td>
                     <td className="textAlign">{item.leaveType === 'Loss of Pay (LOP)' ? item.leaveBalance : '-'}</td>
                     <td>
                       <IconButton onClick={() => setEditLeaveBalance(true)}>
                         <Edit />
                       </IconButton>
                     </td>
                   </tr>
                   ))}   */}
                      {console.log("leaveBalanceByType", leaveBalanceByType)}
                      {Object.values(leaveBalanceByType).map(
                        (employee, index) => (
                          <tr key={index}>
                            <td>{employee.employeeName}</td>
                            <td>{employee.employeeId}</td>
                            {leaveRuleData.map((leave) => (
                              <td className="textAlign" key={leave.id}>
                                {employee.leaveBalances[
                                  leave.leaveTypeDisplay
                                ] || "-"}
                              </td>
                            ))}
                            {/* <td className="textAlign">
                              {employee.leaveBalances["Privilege Leave"] || "-"}
                            </td> */}
                            {/* <td className="textAlign">
                              {employee.leaveBalances["Casual Leave"] || "-"}
                            </td>  */}
                            {/* <td className="textAlign">
                              {employee.leaveBalances[
                                "Medical Leave/ Sick Leave"
                              ] || "-"}
                            </td> */}
                            {/* <td className="textAlign">
                              {employee.leaveBalances[
                                "Maternity Leave (Biological Child)"
                              ] || "-"}
                            </td> */}
                            {/* <td className="textAlign">
                              {employee.leaveBalances["Compensatory Leave"] ||
                                "-"}
                            </td> */}
                            {/* <td className="textAlign">
                              {employee.leaveBalances["Marriage Leave"] || "-"}
                            </td> */}
                            {/* <td className="textAlign">
                              {employee.leaveBalances["Paternity Leave"] || "-"}
                            </td> */}
                            {/* <td className="textAlign">
                              {employee.leaveBalances["Bereavement Leave"] ||
                                "-"}
                            </td> */}
                            {/* <td className="textAlign">
                              {employee.leaveBalances["Loss of Pay (LOP)"] ||
                                "-"}
                            </td> */}
                            <td>
                              <IconButton
                                onClick={() => {
                                  updateLeaveBalanceFunction(employee);
                                  // setEditLeaveBalance(true);
                                }}
                              >
                                <Edit />
                              </IconButton>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="modActionBtnGrp actionsCreateOrg">
              {/* <Button
                className="dfultDarkSecondaryBtn"
                onClick={handelCloseLeaveBalanceModel}
              >
                Cancel
              </Button> */}
              {/* <Button className="dfultPrimaryBtn">Submit</Button> */}
            </div>
          </div>
        </Box>
      </div>

      {/* {modalOpen && (
        <div className={`modelContainer ${modalOpen ? "open" : ""}`}>
          <div className="modelContain animated width90vwModel">
            <IconButton className="closeModelIoc" onClick={handleCloseModal}>
              <XLg />
            </IconButton>
            <div className="fwModelContainArea">
              <div className="modHeader">
                <div className="modHeaderText">Uploaded File Data</div>
              </div>
              <div className="modBody">
                {console.log("fileData", fileData)}
                {fileData && (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow key={-1}>
                
                          <TableCell style={{ color: "black" }}>
                            {fileData[0][1]}
                          </TableCell>
                          <TableCell style={{ color: "black" }}>
                            {fileData[0][0]}
                          </TableCell>
                          <TableCell style={{ color: "black" }}>
                            {fileData[0][2]}
                          </TableCell>
                          <TableCell style={{ color: "black" }}>
                            {fileData[0][3]}
                          </TableCell>
                          <TableCell style={{ color: "black" }}>
                            {fileData[0][4]}
                          </TableCell>
                          <TableCell style={{ color: "black" }}>
                            {fileData[0][5]}
                          </TableCell>
                          <TableCell style={{ color: "black" }}>
                            {fileData[0][6]}
                          </TableCell>
                          <TableCell style={{ color: "black" }}>
                            {fileData[0][7]}
                          </TableCell>
                          <TableCell style={{ color: "black" }}>
                            {fileData[0][8]}
                          </TableCell>
                          <TableCell style={{ color: "black" }}>
                            {fileData[0][9]}
                          </TableCell>
                          <TableCell style={{ color: "black" }}>
                            {fileData[0][10]}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                  
                        {fileData.slice(1).map((row, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ color: "black" }}>
                              {row[1]}
                            </TableCell>
                            <TableCell style={{ color: "black" }}>
                              {row[0]}
                            </TableCell>
                            <TableCell style={{ color: "black" }}>
                              {row[2]}
                            </TableCell>
                            <TableCell style={{ color: "black" }}>
                              {row[3]}
                            </TableCell>
                            <TableCell style={{ color: "black" }}>
                              {row[4]}
                            </TableCell>
                            <TableCell style={{ color: "black" }}>
                              {row[5]}
                            </TableCell>
                            <TableCell style={{ color: "black" }}>
                              {row[6]}
                            </TableCell>
                            <TableCell style={{ color: "black" }}>
                              {row[7]}
                            </TableCell>
                            <TableCell style={{ color: "black" }}>
                              {row[8]}
                            </TableCell>
                            <TableCell style={{ color: "black" }}>
                              {row[9]}
                            </TableCell>
                            <TableCell style={{ color: "black" }}>
                              {row[10]}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </div>
              <div className="modActionBtnGrp actionsCreateOrg">
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={handleCloseModal}
                >
                  Close
                </Button>
                <Button className="dfultPrimaryBtn" onClick={handleExcelFileSubmit}>
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      )} */}

      {modalOpen && (
        <div className={`modelContainer ${modalOpen ? "open" : ""}`}>
          <div className="modelContain animated width90vwModel">
            <IconButton className="closeModelIoc" onClick={handleCloseModal}>
              <XLg />
            </IconButton>
            <div className="fwModelContainArea">
              <div className="modHeader">
                <div className="modHeaderText">{t("uploaded_file_data")}</div>
              </div>
              <div className="modBody">
                {console.log("excelDataList", excelDataList)}
                {excelDataList && (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("employee_id")}</TableCell>
                          <TableCell>{t("employee_name")}</TableCell>
                          {leaveRuleData.map((leave) => (
                            <TableCell key={leave.id}>
                              {t(leave.leaveTypeDisplay)}
                            </TableCell>
                          ))}
                          {/* <TableCell>{t("privilege_leave")}</TableCell>
                          <TableCell>{t("casual_leave")}</TableCell>
                          <TableCell>{t("sick_leave")}</TableCell>
                          <TableCell>{t("maternity_leave")}</TableCell>
                          <TableCell>{t("Comp_off")}</TableCell>
                          <TableCell>{t("marriage_leave")}</TableCell>
                          <TableCell>{t("paternity_leave")}</TableCell>
                          <TableCell>{t("bereavement_leave")}</TableCell>
                          <TableCell>{t("loss_of_pay")}</TableCell> */}
                          {/* <TableCell>employeeId</TableCell>
                    <TableCell>employeeName</TableCell>
                    <TableCell>leaveRuleId</TableCell>
                    <TableCell>leaveType</TableCell>
                    <TableCell>leaveBalance</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {excelDataList.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ color: "black" }}>
                              {item.EMPLOYEE_ID}
                            </TableCell>
                            <TableCell style={{ color: "black" }}>
                              {item.EMPLOYEE_NAME}
                            </TableCell>
                            {/* <TableCell style={{ color: "black" }}>
                              {item["Privilege Leave"]}
                            </TableCell> */}
                            {leaveRuleData.map((leave) => (
                              <TableCell
                                style={{ color: "black" }}
                                key={leave.id}
                              >
                                {item[leave.leaveTypeDisplay] || "-"}
                              </TableCell>
                            ))}
                            {/* <TableCell style={{ color: "black" }}>
                              {item["Casual Leave"]}
                            </TableCell> */}
                            {/* <TableCell style={{ color: "black" }}>
                              {item["Sick Leave"]}
                            </TableCell> */}
                            {/* <TableCell style={{ color: "black" }}>
                              {item["Maternity Leave"]}
                            </TableCell> */}
                            {/* <TableCell style={{ color: "black" }}>
                              {item["Compensatory Leave"]}
                            </TableCell> */}
                            {/* <TableCell style={{ color: "black" }}>
                              {item["Marriage Leave"]}
                            </TableCell> */}
                            {/* <TableCell style={{ color: "black" }}>
                              {item["Paternity Leave"]}
                            </TableCell> */}
                            {/* <TableCell style={{ color: "black" }}>
                              {item["Bereavement Leave"]}
                            </TableCell> */}
                            {/* <TableCell style={{ color: "black" }}>
                              {item["Loss of Pay (LOP)"]}
                            </TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                      {/* <TableBody>
                  
                  {excelDataList.map((item) => (
                    <TableRow key={JSON.stringify(item)}>
                      <TableCell style={{ color: "black" }}>
                      {item.employeeId}
                      </TableCell>
                      <TableCell style={{ color: "black" }}>
                      {item.employeeName}
                      </TableCell>
                      <TableCell style={{ color: "black" }}>
                      {item.leaveRuleId}
                      </TableCell>
                      <TableCell style={{ color: "black" }}>
                      {item.leaveType}
                      </TableCell>
                      <TableCell style={{ color: "black" }}>
                      {item.leaveBalance}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody> */}
                    </Table>
                  </TableContainer>
                )}
              </div>
              <div className="modActionBtnGrp actionsCreateOrg">
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={handleCloseModal}
                >
                  {t("close")}
                </Button>
                <Button
                  className="dfultPrimaryBtn"
                  onClick={handleExcelFileSubmit}
                >
                  {t("submit")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {console.log("editLeaveBalance", editLeaveBalance)}
      {console.log("selectedEmployee", selectedEmployee)} */}

      {editLeaveBalance && selectedEmployee && (
        <>
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4, zIndex: 999 }}>
            {console.log("editLeaveBalance222", editLeaveBalance)}
            {console.log("selectedEmployee222", selectedEmployee)}
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("edit_leave_balance")}</div>

              <div className="employeeInfo">
                <div className="empInElm">
                  <span className="titl">{t("name")}</span>:
                  <span>{selectedEmployee.employeeName}</span>
                </div>
                <div className="empInElm">
                  <span className="titl">{t("id")}</span>:
                  <span>{selectedEmployee.employeeId}</span>
                </div>
              </div>

              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel id="leaveType">{t("leave_type")}</InputLabel>
                    <Select
                      className="modelSelectFild"
                      variant="outlined"
                      labelId="leaveType"
                      id="selectleaveType"
                      value={selectLeaveType}
                      label={t("leave_type")}
                      onChange={handleChangeLeaveType}
                    >
                      {/* <MenuItem value={"PL"}>Privilege Leave</MenuItem>
                      <MenuItem value={"EL"}> Earned Leave </MenuItem>
                      <MenuItem value={"CL"}> Casual Leave </MenuItem>
                      <MenuItem value={"SL"}> Sick Leave </MenuItem>
                      <MenuItem value={"ML"}> Maternity Leave </MenuItem>
                      <MenuItem value={"Comp-off"}> Comp-off </MenuItem> */}
                      {/* {Object.entries(selectedEmployee.leaveBalances).map(
                        ([leaveType, leaveBalance]) => (
                          <MenuItem value={leaveType}>{leaveType}</MenuItem>
                        )
                      )} */}
                      {userLeaveRuleList.map((leave) => (
                        <MenuItem value={leave.leaveTypeDisplay}>
                          {leave.leaveTypeDisplay}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {console.log("selectedEmployee", selectedEmployee)}
                {console.log(
                  "selectedEmployee.leaveBalances",
                  selectedEmployee.leaveBalances
                )}
                {console.log(
                  "selectedEmployee.leaveBalances[selectLeaveType]",
                  selectedEmployee.leaveBalances[selectLeaveType]
                )}
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      required
                      className="modelTextFild"
                      label={t("leave_balance")}
                      variant="outlined"
                      multiline
                      value={
                        selectedEmployee.leaveBalances[selectLeaveType] || ""
                      }
                      onChange={handleChangeLeaveBalance}
                    />
                  </FormControl>
                </div>
              </div>

              <div className="modActionBtnGrp actionsCreateOrg">
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => setEditLeaveBalance(false)}
                >
                  {t("close")}
                </Button>
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => finalUpdatedSubmit()}
                >
                  {t("update")}
                </Button>
              </div>
            </div>
          </Box>
        </>
      )}
    </>
  );
}
