import { Add, CloseFullscreen, Edit, OpenInFull } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Trash, XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PreviewLOI from "./model/PreviewLOI";
import SalaryHeadAdd from "./model/SalaryHeadAdd";
import { useEffect } from "react";
import { GetLoginUserDetails, validateEmail } from "../../../../utils/Utils";
import {
  getAllDepartment,
  getCountryList,
  getPositionsOfOrganization,
  getRoutineActivity,
  getStateList,
  getStaticDDOfOrg,
} from "../../../../services/AdminService";
import { Controller, useForm } from "react-hook-form";
import { NumericFormatCustom } from "../../../task/view/component/Forms/NumericFormatCustom";
import { SalaryTable } from "./SalaryTable";
import { toast } from "react-toastify";
import { LOIForm } from "./LOIForm";
import { AppoinmentLetter } from "./AppoinmentLetter";
import PreviewAppointment from "./model/PreviewAppointment";
// import PreviewAppointment from "./model/PreviewAppointment";
import { useTranslation } from "react-i18next";
import { PorformaInvoice } from "./PorformaInvoice";
import { PreviewTax } from "./model/PreviewTax";
import { TaxInvoice } from "./TaxInvoice";
import { PreviewPorforma } from "./model/PreviewPorforma";
import { GrnInvoice } from "./GrnInvoice";
import { ReleaseLetter } from "./ReleaseLetter";
import { PoInvoice } from "./PoInvoice";
import { ExperienceLetter } from "./ExperienceLetter";
import { RecurringTask } from "./healthcare/RecurringTask";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";

export default function AddRoutineWork({
  handelCloseAddRoutineWork,
  isExpanded,
  setIsExpanded,
}) {
  const loginUserDetails = GetLoginUserDetails();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const { t } = useTranslation();
  const [selectActivity, setSelectActivity] = useState("");
  const [routineActivityList, setRoutineActivityList] = useState([]);
  const [previewData, setPreviewData] = useState([]);
  const [formData, setFormData] = useState();
  const [showAddSalaryHeadModel, setAddSalaryHeadModel] = useState(false);
  const [showAppoinmentPreview, setAppoinmentPreview] = useState(false);
  const loiRef = useRef();
  const appointRef = useRef();
  const taxRef = useRef();
  const grnRef = useRef();
  const recurringRef = useRef();
  const proformaRef = useRef();
  const releaseLetterRef = useRef();
  const poRef = useRef();
  const experienceLetterRef = useRef();

  const [showTaxInvoice, setShowTaxInvoice] = useState(false);
  const [showPorformaInvoice, setShowPorformaInvoice] = useState(false);

  const buttonNameMap = (activityId) => {
    switch (activityId) {
      case "TASK_FORM_0000000020":
        return "Submit";
      case "TASK_FORM_0000000021":
        return "Submit";
      default:
        break;
    }
  };

  const handleChangeSelectActivity = (event) => {
    setSelectActivity(event.target.value);
  };

  const [showPreviewLOIModel, setPreviewLOIModel] = useState(false);
  const handelClosePreviewLOI = () => {
    setPreviewLOIModel(false);
  };
  const handleClosePreviewApoinment = () => {
    setAppoinmentPreview(false);
  };
  const handleClosePreviewTax = () => {
    setShowTaxInvoice(false);
  };
  const handleClosePreviewProforma = () => {
    setShowPorformaInvoice(false);
  };

  const handelOpenPreviewLOI = (data) => {
    let isValid = true;
    console.log("data", data);
    if (data.firstName?.trim() === "") {
      toast.error(t("please_enter_first_name"));
      isValid = false;
      return;
    }
    // if (data.middleName === "") {
    //   toast.error("Please Enter Middle Name");
    //   isValid = false;
    // }
    if (data.lastName?.trim() === "") {
      toast.error(t("please_enter_last_name"));
      isValid = false;
      return;
    }
    if (!data.dob) {
      toast.error(t("please_enter_date_of_birth"));
      isValid = false;
      return;
    }

    if (data.address1?.trim() === "") {
      toast.error(t("please_enter_address_one"));
      isValid = false;
      return;
    }
    if (data.address2?.trim() === "") {
      toast.error(t("please_enter_address_two"));
      isValid = false;
      return;
    }
    if (data.city?.trim() === "") {
      toast.error(t("please_enter_city"));
      isValid = false;
      return;
    }
   
    if (!data.country || data.country === "") {
      toast.error(t("please_enter_country"));
      isValid = false;
      return;
    }
    if (data?.country && data?.country?.countryCode === "356") {
      if (!data.state || data.state === "") {
        toast.error(t("please_enter_state"));
        isValid = false;
        return;
      }
    }
    if (data.pin?.trim() === "") {
      toast.error(t("please_enter_pin"));
      isValid = false;
      return;
    }
    // if (!data.department || data.department === "") {
    //   toast.error(t("please_enter_department"));
    //   isValid = false;
    // }

    if (!data.position || data.position === "") {
      toast.error(t("please_enter_position"));
      isValid = false;
      return;
    }

    if (data.email === "") {
      toast.error(t("please_enter_email"));
      isValid = false;
      return;
    }

    if (data.email !== "" && !validateEmail(data.email)) {
      toast.error(t("please_enter_valid_emailid"));
      isValid = false;
      return;
    }

    if (
      data.cor_address1 === "" ||
      data.cor_address1 === undefined
    ) {
      toast.error(t("please_fill_corresponding_address_one"));
      isValid = false;
      return;
    }
    if (
      data?.cor_address2.trim() === "" ||
      data?.cor_address2 === undefined
    ) {
      toast.error(t("please_fill_corresponding_address_two"));
      isValid = false;
      return;
    }
    if (
      data.cor_pin === "" ||
      data.cor_pin === undefined
    ) {
      toast.error(t("please_fill_corresponding_pin"));
      isValid = false;
      return;
    }
    if (
      data?.cor_city === "" ||
      data?.cor_city === undefined
    ) {
      toast.error(t("please_fill_the_corresponding_city"));
      isValid = false;
      return;
    }
    if (
      data?.corCountry?.countryName === "" ||
      data?.corCountry?.countryName === undefined
    ) {
      toast.error(t("please_fill_the_corresponding_country"));
      isValid = false;
      return;
    }
    if (
      data?.corCountry?.countryCode === "356" &&
      (data?.corState?.name === "" ||
        data?.corState?.name === undefined)
    ) {
      toast.error(t("please_fill_the_corresponding_state"));
      isValid = false;
      return;
    }


    if (data.gpm === "") {
      toast.error(t("please_enter_gross_per_month"));
      isValid = false;
      return;
    }

    if (data.ctc === "") {
      toast.error(t("please_enter_ctc"));
      isValid = false;
      return;
    }

    if (data.salaryInfoList.length === 0) {
      toast.error(t("please_provide_salary_informations"));
      isValid = false;
      return;
    }
    if (data.staticDodList?.length === 0) {
      toast.error(t("Please provide atleast one document"));
      isValid = false;
      return;
    }

    if (isValid) {
      setPreviewLOIModel(true);
    }
  };

  const handelCloseSalaryHeadModel = () => {
    setAddSalaryHeadModel(false);
  };
  const handelOpenAppointmentPreview = (data) => {
    // showAddSalaryHeadModel(true);
    //
    console.log("data value =", data);
    let isValid = true;
    if (data?.firstName?.trim() === "") {
      toast.error(t("please_enter_first_name"));
      isValid = false;
      return;
    }
    // if (data.middleName === "") {
    //   toast.error("Please Enter Middle Name");
    //   isValid = false;
    // }
    if (data.lastName?.trim() === "") {
      toast.error(t("please_enter_last_name"));
      isValid = false;
      return;
    }

    if (data.email === "") {
      toast.error(t("please_enter_email"));
      isValid = false;
      return;
    }

    if (data.email !== "" && !validateEmail(data.email)) {
      toast.error(t("please_enter_valid_emailid"));
      isValid = false;
      return;
    }

    if (!data.position || data.position === "") {
      toast.error(t("please_enter_position"));
      isValid = false;
      return;
    }

    if (!data.reportingHead) {
      toast.error(t("please_select_reporting_head"));
      isValid = false;
      return;
    }

    if (data.address1?.trim() === "") {
      toast.error(t("please_enter_address_one"));
      isValid = false;
      return;
    }
    if (data.address2?.trim() === "") {
      toast.error(t("please_enter_address_two"));
      isValid = false;
      return;
    }
    if (data.city?.trim() === "") {
      toast.error(t("please_enter_city"));
      isValid = false;
      return;
    }
    if (!data.country || data.country === "") {
      toast.error(t("please_enter_country"));
      isValid = false;
      return;
    }
    if (data?.country?.countryCode === "356") {
      if (!data.state || data.state === "") {
        toast.error(t("please_enter_state"));
        isValid = false;
        return;
      }
    }
   
    if (data.pin === "") {
      toast.error(t("please_enter_pin"));
      isValid = false;
      return;
    }
    // if (!data.department || data.department === "") {
    //   toast.error(t("please_enter_department"));
    //   isValid = false;
    // }

    // if (!data.position || data.position === "") {
    //   toast.error(t("please_enter_position"));
    //   isValid = false;
    //   return;
    // }
    if (!data.location || data.location === "") {
      toast.error(t("please_select_location"));
      isValid = false;
      return;
    }

    // if (data.email === "") {
    //   toast.error(t("please_enter_email"));
    //   isValid = false;
    //   return;
    // }

    // if (data.email !== "" && !validateEmail(data.email)) {
    //   toast.error(t("please_enter_valid_emailid"));
    //   isValid = false;
    //   return;
    // }

    // if (!data.reportingHead) {
    //   toast.error(t("please_select_reporting_head"));
    //   isValid = false;
    //   return;
    // }

    if (
      data.corAddress.cor_address1 === "" ||
      data.corAddress.cor_address1 === undefined
    ) {
      toast.error(t("please_fill_corresponding_address_one"));
      isValid = false;
      return;
    }
    if (
      data?.corAddress?.cor_address2?.trim() === "" ||
      data?.corAddress?.cor_address2 === undefined
    ) {
      toast.error(t("please_fill_corresponding_address_two"));
      isValid = false;
      return;
    }
   
    if (
      data.corAddress.cor_city === "" ||
      data.corAddress.cor_city === undefined
    ) {
      toast.error(t("please_fill_the_corresponding_city"));
      isValid = false;
      return;
    }
    if (
      data.corAddress.cor_country === "" ||
      data.corAddress.cor_country === undefined
    ) {
      toast.error(t("please_fill_the_corresponding_country"));
      isValid = false;
      return;
    }
    if (
      data.corAddress.cor_country.countryCode === "356" &&
      (data.corAddress.cor_state === "" ||
        data.corAddress.cor_state === undefined)
    ) {
      toast.error(t("please_fill_the_corresponding_state"));
      isValid = false;
      return;
    }

    if (
      data.corAddress.cor_pin === "" ||
      data.corAddress.cor_pin === undefined
    ) {
      toast.error(t("please_fill_corresponding_pin"));
      isValid = false;
      return;
    }


    if (data.probationDay === "") {
      toast.error(t("please_select_probation_time"));
      isValid = false;
      return;
    }

    if (data.priorAddressChangeDays === "") {
      toast.error(t("please_select_address_change_notice_period"));
      isValid = false;
      return;
    }
    if (data.priorLeaveNoticeDays === "") {
      toast.error(t("please_select_leave_notice_period"));
      isValid = false;
      return;
    }

    // if (
    //   data.corAddress.cor_address1 === "" ||
    //   data.corAddress.cor_address1 === undefined
    // ) {
    //   toast.error(t("please_fill_corresponding_address_one"));
    //   isValid = false;
    //   return;
    // }
    // if (
    //   data?.corAddress?.cor_address2?.trim() === "" ||
    //   data?.corAddress?.cor_address2 === undefined
    // ) {
    //   toast.error(t("please_fill_corresponding_address_two"));
    //   isValid = false;
    //   return;
    // }
    // if (
    //   data.corAddress.cor_pin === "" ||
    //   data.corAddress.cor_pin === undefined
    // ) {
    //   toast.error(t("please_fill_corresponding_pin"));
    //   isValid = false;
    //   return;
    // }
    // if (
    //   data.corAddress.cor_city === "" ||
    //   data.corAddress.cor_city === undefined
    // ) {
    //   toast.error(t("please_fill_the_corresponding_city"));
    //   isValid = false;
    //   return;
    // }
    // if (
    //   data.corAddress.cor_country === "" ||
    //   data.corAddress.cor_country === undefined
    // ) {
    //   toast.error(t("please_fill_the_corresponding_country"));
    //   isValid = false;
    //   return;
    // }
    // if (
    //   data.corAddress.cor_country.countryCode === "356" &&
    //   (data.corAddress.cor_state === "" ||
    //     data.corAddress.cor_state === undefined)
    // ) {
    //   toast.error(t("please_fill_the_corresponding_state"));
    //   isValid = false;
    //   return;
    // }

    // if (data.gpm === "") {
    //   toast.error(t("please_enter_gross_per_month"));
    //   isValid = false;
    // }

    if (data.ctc === "") {
      toast.error(t("please_enter_ctc"));
      isValid = false;
      return;
    }
    if (data.salaryInfoList.lengh === 0) {
      toast.error(t("please_provide_salary_informations"));
      isValid = false;
      return;
    }
    if (data.absentDays === "" || data.absentDays === undefined) {
      toast.error(t("please_provide_absent_days"));
      isValid = false;
      return;
    }
    if (data.noticePeriod === "" || data.noticePeriod === undefined) {
      toast.error(t("please_provide_notice_period"));
      isValid = false;
      return;
    }
    // if (data.salaryLieu === "" || data.salaryLieu === undefined) {
    //   toast.error("Please Provide Salary In Lieu");
    //   isValid = false;
    // }

    if (data.salaryInfoList.length === 0) {
      toast.error(t("please_provide_salary_informations"));
      isValid = false;
      return;
    }

    if (data.staticDodList?.length === 0) {
      toast.error(t("please_provide_atleast_one_document"));
      isValid = false;
      return;
    }

    if (isValid) {
      setAppoinmentPreview(true);
    }
  };

  const handleOpenProformaPreview = (data) => {
    const {
      bankingMethod,
      allTermsAndConditions,
      tableData,
      sellerName,
      buyerName,
      sellerAdress,
      sellerTaxId,
      sellerEmailId,
      buyerTaxId,
      buyerEmailId,
      placeOfSupply,
    } = data;

    let isValid = true;
    if (!sellerName) {
      toast.error(t("please_select_seller"));
      isValid = false;
      return;
    }
    if (!sellerTaxId) {
      toast.error(t("please_add_seller_tax_gst_id"));
      isValid = false;
      return;
    }
    if (sellerEmailId && !validateEmail(sellerEmailId)) {
      toast.error(t("invalid_seller_email_id"));
      isValid = false;
      return;
    }
    if (buyerEmailId && !validateEmail(buyerEmailId)) {
      toast.error(t("invalid_buyer_email_id"));
      isValid = false;
      return;
    }

    if (!buyerName) {
      toast.error(t("please_select_buyer"));
      isValid = false;
      return;
    }
    if (!buyerTaxId) {
      toast.error(t("please_add_buyer_tax_gst_id"));
      isValid = false;
      return;
    }
    if (!tableData || tableData?.length == 0) {
      toast.error(t("please_add_items"));
      isValid = false;
      return;
    }

    if (!placeOfSupply || !placeOfSupply?.trim()) {
      toast.error(t("please_add_place_of_supply"));
      isValid = false;
      return;
    }

    if (!bankingMethod) {
      toast.error(t("please_select_banking_data"));
      isValid = false;
      return;
    }
    if (!allTermsAndConditions || allTermsAndConditions?.lengh == 0) {
      toast.error(t("please_select_terms_and_conditions"));
      isValid = false;
      return;
    }

    if (!isValid) {
      return;
    }
    setShowPorformaInvoice(true);
  };

  const handleOpenTaxPreview = (data) => {
    const {
      bankingMethod,
      allTermsAndConditions,
      tableData,
      subTotalAmount,
      totalAmount,
      roundOffAmount,
      sellerName,
      buyerName,
      sellerTaxId,
      sellerEmailId,
      buyerTaxId,
      buyerEmailId,
    } = data;

    let isValid = true;
    if (!sellerName) {
      toast.error(t("please_select_seller"));
      isValid = false;
      return;
    }

    if (!sellerTaxId) {
      toast.error(t("please_add_seller_tax_gst_id"));
      isValid = false;
      return;
    }
    if (sellerEmailId && !validateEmail(sellerEmailId)) {
      toast.error(t("invalid_seller_email_id"));
      isValid = false;
      return;
    }

    if (!buyerName) {
      toast.error(t("please_select_buyer"));
      isValid = false;
      return;
    }
    if (buyerEmailId && !validateEmail(buyerEmailId)) {
      toast.error(t("invalid_buyer_email_id"));
      isValid = false;
      return;
    }

    if (!buyerTaxId) {
      toast.error(t("please_add_buyer_tax_gst_id"));
      isValid = false;
      return;
    }

    if (!tableData || tableData?.length == 0) {
      toast.error(t("please_add_items"));
      isValid = false;
      return;
    }
    if (!bankingMethod) {
      toast.error(t("please_select_banking_data"));
      isValid = false;
      return;
    }
    if (!allTermsAndConditions || allTermsAndConditions?.lengh == 0) {
      toast.error(t("please_select_terms_and_conditions"));
      isValid = false;
      return;
    }

    if (!isValid) {
      return;
    }
    setShowTaxInvoice(true);
  };

  const handleOpenGrnPreview = (data) => {
    console.log("Grn Data =", data);
    // setPreviewData([]);
    // setSelectActivity("");
  };

  useEffect(() => {
    getRoutineActivity(loginUserDetails.orgId).then((response) => {
      if (response.data) {
        setRoutineActivityList(response.data);
      }
    });
  }, []);

  const onSubmit = (data) => {
    console.log("data =", data);
  };

  const [minprovp, setMinprovp] = useState("");

  const handleChangeMinProvp = (event) => {
    setMinprovp(event.target.value);
  };
  const [maxprovp, setMaxprovp] = useState("");

  const handleChangeMaxProvp = (event) => {
    setMaxprovp(event.target.value);
  };

  // const [isExpanded, setIsExpanded] = useState(false);
  const fullScreenStyle = {
    width: "100%",
  };

  // useEffect(()=>{
  //  setBookSteps([
  //   {
  //     target: ".addRoutineStepOne",
  //     content: "This is where you can add routine work.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addRoutineStepTwo",
  //     content: "Choose the activity from the dropdown to start configuring routine work. And Fill The Necessary Details",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addRoutineStepThree",
  //     content: "Click here to preview your entries before submission.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addRoutineStepFour",
  //     content: "If you wish to cancel, click here to close this form without saving any changes.",
  //     disableBeacon: true,
  //   }
  // ])
  // },[])

  useEffect(() => {
  
    setBookSteps([
      {
        target: ".addRoutineStepOne",
        content: t("add_routine_step_one"), 
        disableBeacon: true,
      },
      {
        target: ".addRoutineStepTwo",
        content: t("add_routine_step_two"), 
        disableBeacon: true,
      },
      {
        target: ".addRoutineStepThree",
        content: t("add_routine_step_three"), 
        disableBeacon: true,
      },
      {
        target: ".addRoutineStepFour",
        content: t("add_routine_step_four"), 
        disableBeacon: true,
      }
    ]);
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["addRoutineWorkCDS"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('finish'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div
        className="rightFlotingPanel"
        // style={isExpanded ? fullScreenStyle : {}}
      >
        <div className="rightFlotingContainer addRoutineStepOne">
          <div className="rfContHeader">
            <div className="rfcHeadText">{t("add_routine_work")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <Tooltip
                  arrow
                  title={
                    isExpanded ? t("exit_full_screen") : t("enter_full_screen")
                  }
                >
                  <IconButton
                    onClick={(e) => {
                      if (isExpanded) {
                        setIsExpanded(false);
                      } else {
                        setIsExpanded(true);
                      }
                    }}
                  >
                    {isExpanded ? <CloseFullscreen /> : <OpenInFull />}
                  </IconButton>
                </Tooltip>
              </div>
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAddRoutineWork()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="rfContContain">
            <div className="rfContContainDetails">
              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel id="SelectActivity">
                      {t("select_activity")}
                    </InputLabel>
                    <Select
                      labelId="SelectActivity"
                      id="Select-Activity"
                      value={selectActivity}
                      label={t("select_activity")}
                      onChange={handleChangeSelectActivity}
                      className="formInputField addRoutineStepTwo"
                      variant="outlined"
                    >
                      {routineActivityList &&
                        routineActivityList.map((activity) => (
                          <MenuItem value={activity}>{activity.name}</MenuItem>
                        ))}
                      {/* <MenuItem value="TASK_FORM_0000000020">
                         Release Letter/ Relieving Letter
                      </MenuItem> */}
                      {/* <MenuItem value="TASK_FORM_0000000021">
                         Service Certificate/ Experience Letter
                      </MenuItem> */}
                      {/* <MenuItem value="TASK_FORM_0000000017">
                        Tax Invoice
                      </MenuItem> */}
                      {/* <MenuItem value="AppointmentLetter">
                        Appointment Letter
                      </MenuItem> */}
                    </Select>
                  </FormControl>
                </div>

                {selectActivity.formId === "TASK_FORM_0000000014" && (
                  <LOIForm
                    ref={loiRef}
                    formData={formData}
                    setFormData={setFormData}
                    handleShowPreview={(data) => {
                      console.log("handlePreview =", data);
                      setPreviewData(data);
                      handelOpenPreviewLOI(data);
                    }}
                  />
                )}

                {selectActivity.formId === "TASK_FORM_0000000015" && (
                  <AppoinmentLetter
                    ref={appointRef}
                    formData={formData}
                    setFormData={setFormData}
                    handleShowPreview={(data) => {
                      setPreviewData(data);
                      // handelOpenPreviewLOI(data);
                      handelOpenAppointmentPreview(data);
                    }}
                  />
                )}
                {selectActivity.formId === "TASK_FORM_0000000017" && (
                  <PorformaInvoice
                    ref={proformaRef}
                    formData={formData}
                    setFormData={setFormData}
                    handleShowPreview={(data) => {
                      setPreviewData(data);
                      // handelOpenPreviewLOI(data);
                      handleOpenProformaPreview(data);
                    }}
                  />
                )}
                {selectActivity.formId === "TASK_FORM_0000000018" && (
                  <TaxInvoice
                    ref={taxRef}
                    formData={formData}
                    setFormData={setFormData}
                    handleShowPreview={(data) => {
                      setPreviewData(data);
                      // handelOpenPreviewLOI(data);
                      handleOpenTaxPreview(data);
                    }}
                  />
                )}

                {selectActivity.formId === "TASK_FORM_0000000019" && (
                  <ExperienceLetter
                    ref={experienceLetterRef}
                    formData={formData}
                    setFormData={setFormData}
                    handleShowPreview={(data) => {
                      setPreviewData(data);
                      // handelOpenPreviewLOI(data);
                      handleOpenTaxPreview(data);
                    }}
                    setSelectActivity={setSelectActivity}
                  />
                )}
                {selectActivity.formId === "TASK_FORM_0000000020" && (
                  <GrnInvoice
                    ref={grnRef}
                    formData={formData}
                    setFormData={setFormData}
                    handleShowPreview={(data) => {
                      setPreviewData(data);
                      // handelOpenPreviewLOI(data);
                      handleOpenGrnPreview(data);
                    }}
                  />
                )}
                {selectActivity.formId === "TASK_FORM_0000000023" && (
                  <RecurringTask
                    ref={recurringRef}
                    formData={formData}
                    setFormData={setFormData}
                    handleShowPreview={(data) => {
                      setPreviewData(data);
                      // handelOpenPreviewLOI(data);
                      handleOpenGrnPreview(data);
                    }}
                  />
                )}

                {selectActivity.formId === "TASK_FORM_0000000021" && (
                  <ReleaseLetter
                    ref={releaseLetterRef}
                    formData={formData}
                    setFormData={setFormData}
                    handleShowPreview={(data) => {
                      setPreviewData(data);
                      // handelOpenPreviewLOI(data);
                      handleOpenTaxPreview(data);
                    }}
                    setSelectActivity={setSelectActivity}
                  />
                )}

                {selectActivity.formId === "TASK_FORM_0000000022" && (
                  <PoInvoice
                    ref={poRef}
                    handleShowPreview={(data) => {
                      setPreviewData(data);
                      // handelOpenPreviewLOI(data);
                      handleOpenGrnPreview(data);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="rfContFooter">
            <div className="formBtnElement">
              {selectActivity && (
                <Button
                  className="dfultPrimaryBtn addRoutineStepThree"
                  onClick={() => {
                    // let data = getValues();
                    // data.salaryInfoList = salaryInfoList;
                    // data.staticDodList = staticDodList;
                    // data.joiningdate = joiningdate;
                    // data.loiexpdate = loiexpdate;
                    if (selectActivity.formId === "TASK_FORM_0000000015") {
                      appointRef.current.click();
                    } else if (
                      selectActivity.formId === "TASK_FORM_0000000014"
                    ) {
                      loiRef.current.click();
                    } else if (
                      selectActivity.formId === "TASK_FORM_0000000018"
                    ) {
                      taxRef.current.click();
                    } else if (
                      selectActivity.formId === "TASK_FORM_0000000017"
                    ) {
                      proformaRef.current.click();
                    } else if (
                      selectActivity.formId === "TASK_FORM_0000000019"
                    ) {
                      experienceLetterRef.current.click();
                    } else if (
                      selectActivity.formId === "TASK_FORM_0000000021"
                    ) {
                      releaseLetterRef.current.click();
                    } else if (
                      selectActivity.formId === "TASK_FORM_0000000021"
                    ) {
                      grnRef.current.click();
                    } else if (
                      selectActivity.formId === "TASK_FORM_0000000023"
                    ) {
                      recurringRef.current.click();
                    }

                    // setPreviewData(data);
                    // handelOpenPreviewLOI(data);
                  }}
                >
                  {[
                    "TASK_FORM_0000000020",
                    "TASK_FORM_0000000021",
                    "TASK_FORM_0000000019",
                    "TASK_FORM_0000000023",
                  ].includes(selectActivity.formId)
                    ? t("submit")
                    : t("preview")}
                  {/* {selectActivity.formId === "TASK_FORM_0000000020" ||
                selectActivity === "TASK_FORM_0000000021"
                  ? t("submit")
                  : t("preview")} */}
                </Button>
              )}
              <Button
                className="dfultDarkSecondaryBtn addRoutineStepFour"
                onClick={() => handelCloseAddRoutineWork()}
              >
                {t("cancel")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {showPreviewLOIModel && (
        <>
          <PreviewLOI
            activity={selectActivity}
            showPreviewLOIModel={showPreviewLOIModel}
            handelClosePreviewLOI={handelClosePreviewLOI}
            previewData={previewData}
            onSubmitSuccess={() => {
              setPreviewLOIModel(false);
              setPreviewData([]);
              setSelectActivity("");
            }}
          />
        </>
      )}
      {showAppoinmentPreview && (
        <PreviewAppointment
          activity={selectActivity}
          handleClosePreviewApoinment={handleClosePreviewApoinment}
          previewData={previewData}
          onSubmitSuccess={() => {
            setAppoinmentPreview(false);
            setPreviewData([]);
            setSelectActivity("");
          }}
        />
      )}

      {showTaxInvoice && (
        <PreviewTax
          activity={selectActivity}
          previewData={previewData}
          handleClosePreviewTax={handleClosePreviewTax}
          onSubmitSuccess={() => {
            setShowTaxInvoice(false);
            setPreviewData([]);
            setSelectActivity("");
          }}
        />
      )}
      {showPorformaInvoice && (
        <PreviewPorforma
          activity={selectActivity}
          previewData={previewData}
          handleClosePreviewProforma={handleClosePreviewProforma}
          onSubmitSuccess={() => {
            setShowPorformaInvoice(false);
            setPreviewData([]);
            setSelectActivity("");
          }}
        />
      )}

      {showAddSalaryHeadModel && (
        <SalaryHeadAdd
          showAddSalaryHeadModel={showAddSalaryHeadModel}
          handelCloseSalaryHeadModel={handelCloseSalaryHeadModel}
        />
      )}
    </>
  );
}
