import { Add, ExpandMoreRounded, MicOutlined } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useRef } from "react";
import { useState, useEffect, useMemo } from "react";
import { Trash } from "react-bootstrap-icons";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "./consultationUtil";
import DashboardServices from "../../../../../../../services/DashboardServices";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { GetLoginUserDetails } from "../../../../../../../utils/Utils";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";
import { staticDataSocket } from "../../../../../../../socket";
import SecureIndexedDB from "../../../../../../../utils/IndexedDB";
import { CustomModal } from "../../../../../../task/view/component/Modals/CustomModal";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const timings = [
  "Stat",
  "Before Breakfast",
  "After Breakfast",
  "Before Lunch",
  "After Lunch",
];

export default function MedicalHistory({ data, updateData, previousInfo }) {
  const specialCharMap = new Map([
    ["Á", "A"],
    ["È", "E"],
    ["É", "E"],
    ["Í", "I"],
    ["Ñ", "N"],
    ["Ó", "O"],
    ["Ô", "O"],
    ["Ú", "U"],
    ["Ü", "U"],
  ]);
  const specialCharKeys = Array.from(specialCharMap.keys());

  const loginUserDetail = GetLoginUserDetails();
  const { t, i18n } = useTranslation();
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [dosage, setDosage] = useState("");
  const [mForm, setMForm] = useState("");
  const [frequency, setFrequency] = useState("");
  const [regimen, setRegimen] = useState("");
  const [pastIllnessDate, setPastIllnessDate] = useState(null);
  const [sincemedtakeDate, setSincemedtakeDate] = useState(null);
  const [surgeryDate, setSurgeryDate] = useState(null);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  //Generic

  const [genericSearchInput, setGenericSearchInput] = useState("");
  const [selectedGeneric, setSelectedGeneric] = useState(null);
  const [medicationNote, setMedicationNote] = useState("");

  const [medicationList, setMedicationList] = useState([]);

  const [mTiming, setMTiming] = useState([]);
  const [doctorGenericList, setDoctorGenericList] = useState([]);
  const [genericList, setGenericList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [suggestedDosage, setSuggestedDosage] = useState([]);

  //brand

  const [suggestedBrand, setSuggestedBrand] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [brandInputString, setBrandInputString] = useState("");

  //past illness

  const [pastIllness, setPassIllness] = useState("");
  const [userPastIllnessList, setUserPassIllnessList] = useState([]);

  const [selectedPastIllness, setSelectedPastIllness] = useState(null);
  const [pastIllnessString, setPastIllnessString] = useState("");
  const [diseaseList, setDiseaseList] = useState([]);

  //Previous procedure

  const [previousSurgery, setPreviousSurgery] = useState("");
  const [previousSurgeryList, setPreviousSurgeryList] = useState([]);

  const [procedureList, setProcedureList] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [userProcedureList, setUserProcedureList] = useState([]);
  const [procedureString, setProcedureString] = useState("");

  const [frequencyList, setFrequencyList] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [dosageList, setDosageList] = useState([]);
  const [routeList, setRoutelist] = useState([]);

  const [previousProcedure, setPreviousProcedure] = useState([]);
  const [previousIllness, setPreviousIllness] = useState([]);
  const [showConfirm, setShowConfirmation] = useState(false);
  const confirmString = useRef("");

  // const [userPrevProcedures,setUserPreviousProcedures]=useState([])

  const getFilteredMedication = (data, str) => {
    let searchString = "" + str;

    for (let i = 0; i < specialCharKeys.length; i++) {
      if (searchString.includes(specialCharKeys[i])) {
        searchString = searchString.replace(
          specialCharKeys[i],
          specialCharMap.get(specialCharKeys[i])
        );
      }
    }
    if (searchString === "" || searchString === null) {
      const temp = data?.slice(0, MAX_SUGGESTED_RESULT);
      return { startsWithList: temp, containsList: [] };
    }
    const startsWithList = [];
    const containsList = [];

    data?.forEach((element) => {
      let genericName = element.generic;

      for (let i = 0; i < specialCharKeys.length; i++) {
        if (genericName.includes(specialCharKeys[i])) {
          genericName = genericName.replace(
            specialCharKeys[i],
            specialCharMap.get(specialCharKeys[i])
          );
        }
      }
      if (genericName?.toLowerCase()?.startsWith(searchString.toLowerCase())) {
        startsWithList.push(element);
      } else if (
        genericName?.toLowerCase()?.includes(searchString.toLowerCase())
      ) {
        containsList.push(element);
      }
    });
    return { startsWithList, containsList };
  };

  const setUniqueDoses = () => {
    const uniqueDosage = {};
    dosageList?.forEach((item) => {
      if (!uniqueDosage[item.dosage]) {
        uniqueDosage[item.dosage] = item;
      }
    });
    const uniqueItems = Object.values(uniqueDosage);
    setSuggestedDosage(uniqueItems);
    return uniqueItems;
  };

  const suggestedDiseaseList = useMemo(() => {
    const pastIllnessIdList = userPastIllnessList?.map(
      (item) => item.illness.id
    );

    let filteredData = diseaseList?.filter((disease) => {
      // let symptomVal = "C";
      // if (extraData.queuePatientGender === "Male") {
      //   symptomVal = "M";
      // }
      // if (extraData.queuePatientGender === "Female") {
      //   symptomVal = "F";
      // }
      const alreadyTaken = pastIllnessIdList?.some(
        (item) => item === disease.id
      );
      return !alreadyTaken;
    });

    const filteredList = getFilteredList(
      filteredData,
      pastIllnessString,
      "diseaseName"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [pastIllnessString, diseaseList, userPastIllnessList]);

  const suggestedProcedure = useMemo(() => {
    const userProcedureIdList = userProcedureList.map(
      (item) => item.surgery.id
    );

    let filteredData = procedureList.filter((procedure) => {
      let symptomVal = "C";
      // if (extraData.queuePatientGender === "Male") {
      //   symptomVal = "Male";
      // }
      // if (extraData.queuePatientGender === "Female") {
      //   symptomVal = "Female";
      // }

      const alreadyTaken = userProcedureIdList.some(
        (item) => item === procedure.id
      );
      return !alreadyTaken;
      // &&
      // (procedure.gender === symptomVal || procedure.gender === "C")
    });

    const filteredList = getFilteredList(
      filteredData,
      procedureString,
      "procedureName"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [procedureList, procedureString, userProcedureList]);

  const suggestedMedicineList = useMemo(() => {
    // const userAllergicIdList = medicationList?.map(
    //   (item) => item.genericName.id
    // );
    let userAllergicIdList = [];
    let filteredGeneric = [];

    let filteredDoctor = [];
    if (!selectedBrand || (selectedBrand && selectedBrand?.genericId)) {
      if (selectedBrand) {
        filteredGeneric = genericList?.filter((procedure) => {
          return procedure.id === selectedBrand?.genericId;
          // return !alreadyTaken;
        });
        filteredDoctor = doctorGenericList?.filter((procedure) => {
          return procedure.id === selectedBrand?.genericId;
          // return !alreadyTaken;
        });
      } else {
        filteredGeneric = genericList;
        filteredDoctor = doctorGenericList;
      }
    }

    const genericListFilteredData = getFilteredMedication(
      filteredGeneric,
      genericSearchInput,
      "generic"
    );
    const doctorFilteredData = getFilteredMedication(
      filteredDoctor,
      genericSearchInput,
      "generic"
    );
    const tempValue = [
      ...(doctorFilteredData?.startsWithList || []),
      ...(genericListFilteredData?.startsWithList || []),
      ...(doctorFilteredData?.containsList || []),
      ...(genericListFilteredData?.containsList || []),
    ];
    if (tempValue.length === 0) {
      setSuggestedBrand([]);
      // setBrandInputString("");
      // setSelectedBrand(null);
      setUniqueDoses();
    }
    return tempValue?.slice(0, MAX_SUGGESTED_RESULT);
  }, [genericSearchInput, doctorGenericList, genericList, selectedBrand]);

  const setDosageAccordingToFormId = (formId) => {
    let dosageId = [];
    if (formId) {
      dosageId = dosageList?.filter((item) => item.formId === formId);
    } else {
      dosageId = dosageList?.filter((item) => item.formId === 1);
    }
    setSuggestedDosage(dosageId);
    // return dosageId;
  };

  const handleChangemTiming = (event) => {
    const {
      target: { value },
    } = event;
    setMTiming(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeFrequency = (event) => {
    setFrequency(event.target.value);
  };

  const handleChangeRegimen = (event) => {
    setRegimen(event.target.value);
  };

  const handleChangemForm = (event) => {
    setMForm(event.target.value);
  };
  const handleChangeDosage = (event) => {
    setDosage(event.target.value);
  };

  const initializePastIllness = () => {
    setSelectedPastIllness(null);
    setPastIllnessString("");
    setPastIllnessDate(null);
  };

  const initializeProcedure = () => {
    setSelectedProcedure(null);
    setProcedureString("");
    setSurgeryDate(null);
  };

  useEffect(() => {
    getDiseases();
    getProcedures();
  }, []);

  useEffect(() => {
    if (previousInfo) {
      if (previousInfo?.procedures) {
        setPreviousProcedure(previousInfo.procedures);
        updateData({ prevSurgerry: previousInfo.procedures });
      }
      if (previousInfo?.diseases) {
        setPreviousIllness(previousInfo.diseases);
        updateData({ prevIllness: previousInfo.diseases });
      }
    }
  }, [procedureList, previousInfo]);

  const getDiseases = async () => {
    const diseasetring = await SecureIndexedDB.getItem("diseaseList");
    if (diseasetring) {
      setDiseaseList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getDiseases(reqDto).then((response) => {
      if (response.data) {
        setDiseaseList(response.data);
        SecureIndexedDB.setItem("diseaseList", JSON.stringify(response.data));
      }
    });
  };

  const getProcedures = async () => {
    const lifeStyleString = await SecureIndexedDB.getItem("procedureList");
    if (lifeStyleString) {
      setProcedureList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = await getHealthDto();
    DashboardServices.getProcedures(reqDto).then((response) => {
      if (response.data) {
        setProcedureList(response.data);
        SecureIndexedDB.setItem("procedureList", JSON.stringify(response.data));
      }
    });
  };

  const getFrequencies = async () => {
    const diseasetring = await SecureIndexedDB.getItem("frequencyList");
    if (diseasetring) {
      setFrequencyList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getFrequencies(reqDto).then((response) => {
      if (response.data) {
        setFrequencyList(response.data);
        SecureIndexedDB.setItem("frequencyList", JSON.stringify(response.data));
      }
    });
  };

  const getDurations = async () => {
    const diseasetring = await SecureIndexedDB.getItem("durationList");
    if (diseasetring) {
      setDurationList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getDurations(reqDto).then((response) => {
      if (response.data) {
        setDurationList(response.data);
        SecureIndexedDB.setItem("durationList", JSON.stringify(response.data));
      }
    });
  };

  const getFormDosages = async () => {
    const diseasetring = await SecureIndexedDB.getItem("dosageList");
    if (diseasetring) {
      setDosageList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getFormDosages(reqDto).then((response) => {
      if (response.data) {
        setDosageList(response.data);
        SecureIndexedDB.setItem("dosageList", JSON.stringify(response.data));
      }
    });
  };

  // const getBrands = async () => {
  //   console.log("getBrands Medical History");
  //   staticDataSocket.emit("requestData", {
  //     orgId: loginUserDetail.orgId,
  //     countryCode: loginUserDetail.orgCountryCode,
  //   });
  //   staticDataSocket.on("responseData", async (obj) => {
  //     console.log("staticDataSocket ", obj);
  //     const brandObjList = obj.brands;
  //     if (brandObjList) {
  //       setBrandList(brandObjList);
  //       return;
  //     }

  //     const reqDto = await getHealthDto();
  //     DashboardServices.getBrands(reqDto).then((response) => {
  //       if (response.data) {
  //         setBrandList(response.data);
  //         // SecureIndexedDB.setItem("brandList", JSON.stringify(response.data));
  //       }
  //     });
  //   });

  //   return;

  //   const brandString = await SecureIndexedDB.getItem("brandList");
  //   if (brandString) {
  //     setBrandList(JSON.parse(brandString));
  //     return;
  //   }

  //   const reqDto = await getHealthDto();
  //   DashboardServices.getBrands(reqDto).then((response) => {
  //     if (response.data) {
  //       setBrandList(response.data);
  //       SecureIndexedDB.setItem("brandList", JSON.stringify(response.data));
  //     }
  //   });
  // };

  const getBrands = async () => {
    const diseasetring = await SecureIndexedDB.getItem("brandList");
    if (diseasetring) {
      const tempDosage = JSON.parse(diseasetring);
      setBrandList(tempDosage);
      return tempDosage;
    }

    const reqDto = await getHealthDto();
    const response = await DashboardServices.getBrands(reqDto);
    if (response.data) {
      setBrandList(response.data);
      SecureIndexedDB.setItem("brandList", JSON.stringify(response.data));
      return response.data;
    }
  };

  const getGenerics = async () => {
    let doctorGenList = JSON.parse(
      await SecureIndexedDB.getItem("doctor_generic")
    );
    let genList = JSON.parse(await SecureIndexedDB.getItem("generic"));

    if (doctorGenList && genList) {
      setDoctorGenericList(doctorGenList);
      setGenericList(genList);
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getGenerics(reqDto).then((response) => {
      if (response.data) {
        const genericList = response.data;
        DashboardServices.getGenericswithRanking(loginUserDetail.userId).then(
          (rankingResponse) => {
            if (rankingResponse) {
              let rankedMed = rankingResponse.data;
              let rankedMedFullData = [];

              let notRankedMedFullData = [];

              genericList.forEach((item) => {
                const index = rankedMed.findIndex((medId) => medId === item.id);
                if (index >= 0) {
                  rankedMedFullData.push(item);
                  rankedMed.splice(index, 1);
                } else {
                  notRankedMedFullData.push(item);
                }
              });
              console.log("ranked total=", genericList.length);

              console.log(
                "ranked Item len=",
                rankedMedFullData.length,
                " non ranked item len= ",
                notRankedMedFullData.length
              );

              console.log("ranked Item =", rankedMedFullData);
              SecureIndexedDB.setItem(
                "doctor_generic",
                JSON.stringify(rankedMedFullData)
              );
              SecureIndexedDB.setItem(
                "generic",
                JSON.stringify(notRankedMedFullData)
              );

              setDoctorGenericList(rankedMedFullData);
              setGenericList(notRankedMedFullData);

              // toast.success("successfully fetched Data");
            } else {
              toast.error(t("could_not_fetch_generic_ranking"));
              return;
            }
          }
        );
      } else {
        toast.error(t("could_not_fetch_generic_data"));
      }
    });
  };

  const getRoutes = async () => {
    const brandString = await SecureIndexedDB.getItem("routeList");
    if (brandString) {
      setRoutelist(JSON.parse(brandString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getRoutes(reqDto).then((response) => {
      if (response.data) {
        setRoutelist(response.data);
        SecureIndexedDB.setItem("routeList", JSON.stringify(response.data));
      }
    });
  };

  // const initializeAllList = async () => {};

  useEffect(() => {
    if (data && data.medication) {
      setMedicationList(data.medication);
    }
    getGenerics();
    getBrands();
    getFrequencies();
    getDurations();
    getFormDosages();
    getRoutes();
  }, []);

  const getFilteredBrand = (data, str) => {
    const searchString = "" + str;
    if (searchString === "" || searchString === null) {
      return data.slice(0, MAX_SUGGESTED_RESULT);
    }
    const startsWithList = [];
    const containsList = [];

    data.forEach((element) => {
      let brandName = element.brandName;
      for (let i = 0; i < specialCharKeys.length; i++) {
        if (brandName.includes(specialCharKeys[i])) {
          brandName = brandName.replace(
            specialCharKeys[i],
            specialCharMap.get(specialCharKeys[i])
          );
        }
      }
      if (brandName.toLowerCase().startsWith(searchString.toLowerCase())) {
        startsWithList.push(element);
      } else if (brandName.toLowerCase().includes(searchString.toLowerCase())) {
        containsList.push(element);
      }
    });
    return [...startsWithList, ...containsList];
  };

  const updateSuggestedBrand = (data, str) => {
    const tempBrandList = getFilteredBrand(data, str);
    setSuggestedBrand(tempBrandList?.slice(0, MAX_SUGGESTED_RESULT));
  };

  useEffect(() => {
    const userAllergicIdList = medicationList?.map(
      (item) => item.genericName.id
    );

    let filteredBrand = brandList?.filter((brnd) => {
      const alreadyTaken = userAllergicIdList?.some(
        (item) => item && item === brnd.genericId
      );
      return !alreadyTaken;
    });
    updateSuggestedBrand(filteredBrand, brandInputString);
  }, [brandList, brandInputString, medicationList]);

  const initializeForm = () => {
    setDosage("");
    setMForm("");
    setFrequency("");
    setRegimen("");
    setGenericSearchInput("");
    setSelectedGeneric(null);
    setMedicationNote("");
    setMTiming([]);
    setSelectedBrand(null);
    setBrandInputString("");
    setSuggestedDosage([]);
    setSincemedtakeDate(null);
  };

  const handleAddMedication = () => {
    if (!selectedBrand) {
      if (
        !selectedGeneric &&
        (!genericSearchInput || genericSearchInput === "")
      ) {
        toast.error(t("please_select_medicine_name"));
        return;
      }
    }

    if (selectedBrand) {
      const alreadyAvailableBrand = medicationList?.find(
        (item) => item?.brandName?.id === selectedBrand.id
      );

      if (alreadyAvailableBrand) {
        confirmString.current = t(
          "this_brand_is_already_present_do_you_want_to_continue"
        );
        setShowConfirmation(true);

        return;
      }
      console.log("medical list =", medicationList);
      console.log("medical list brand =", selectedBrand);

      const alreadyAvailableGeneric = medicationList?.find(
        (item) => item?.brandName?.genericId === selectedBrand.genericId
      );
      if (alreadyAvailableGeneric) {
        confirmString.current = t(
          "another_brand_with_same_generic_name_is_already_added_do_you_want_to_continue"
        );

        setShowConfirmation(true);
        return;
      }
    } else {
      const alreadyAvailableGeneric = medicationList?.find(
        (item) => item?.genericName?.id === selectedGeneric.id
      );
      if (alreadyAvailableGeneric) {
        confirmString.current = t(
          "medicine_with_this_generic_name_already_present_do_you_want_to_continue"
        );

        setShowConfirmation(true);
        return;
      }
    }

    addMedicineToList();
  };

  const addMedicineToList = () => {
    const tempFreq = frequencyList?.find((item) => item.id === frequency);
    const tempDuration = durationList?.find((item) => item.id === regimen);
    let genericName = selectedGeneric;
    if (!genericName) {
      genericName = {
        id: 0,
        generic: genericSearchInput,
        formId: -1,
        locallyAdded: true,
      };
    }

    const dto = {
      frequency: tempFreq,
      dosage,
      genericName,
      brandName: selectedBrand,
      since: sincemedtakeDate,
    };
    setMedicationList((prev) => {
      const temp = [...prev, dto];
      updateData({ medication: temp });
      return temp;
    });
    initializeForm();
  };

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            {t("medical_history")}
            {!accordionExpanded && <span></span>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="tskElemHeding hilightHeading">{t("medicine")}</div>

          <div className="complaintList mt10">
            <ul>
              {medicationList?.map((item, index) => {
                return (
                  <>
                    <li>
                      <div className="complstDtlGrp ">
                        <div className="complstDtl">
                          {item?.genericName?.generic && (
                            <div className="compntName ">
                              {item.genericName.generic ||
                                item?.brandName?.brandName}
                            </div>
                          )}
                          <div className="compntName BrandMed">
                            {item.brandName && item.brandName.brandName}
                          </div>
                          <div className="compinfoGRP">
                            {item?.dosage && (
                              <div className="compStime">
                                <span>{t("dosage")}:</span>
                                <span className="data">
                                  {item.dosage.dosage}
                                </span>
                              </div>
                            )}
                            {item?.frequency && (
                              <div className="compStime">
                                <span>{t("frequency")}:</span>
                                <span className="data">
                                  {item.frequency.frequency}
                                </span>
                              </div>
                            )}
                            {item?.since && (
                              <div className="compStime">
                                <span>{t("since")}:</span>
                                <span className="data">
                                  {dayjs(item.since).format("DD-MMM-YYYY")}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                        <IconButton
                          className="removeItemBtn"
                          onClick={() => {
                            const temp = [...medicationList];
                            temp.splice(index, 1);
                            updateData({ medication: temp });
                            setMedicationList(temp);
                          }}
                        >
                          <Trash />
                        </IconButton>
                      </div>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>

          <div className="addNewformGrp">
            <div className="formElement">
              <FormControl className="formControl">
                <Autocomplete
                  className="formAutocompleteField"
                  variant="outlined"
                  value={selectedGeneric}
                  options={suggestedMedicineList}
                  inputValue={genericSearchInput}
                  onChange={(e, newValue) => {
                    setSelectedGeneric(newValue);
                    if (newValue) {
                      const { id, formId } = newValue;
                      setDosageAccordingToFormId(formId);
                      const tempBrand = brandList.filter(
                        (item) => item.genericId === id
                      );
                      if (tempBrand && tempBrand.length > 0) {
                        setSuggestedBrand(
                          tempBrand.slice(0, MAX_SUGGESTED_RESULT)
                        );
                      } else {
                        setSuggestedBrand(
                          tempBrand?.slice(0, MAX_SUGGESTED_RESULT) || []
                        );
                        setSelectedBrand(null);
                        setBrandInputString("");
                      }
                    } else {
                      setBrandInputString("");
                      setSelectedBrand(null);
                    }
                  }}
                  onInputChange={(e, value, reason) => {
                    setGenericSearchInput(value);
                    if (value === "" || !value) {
                      // setBrandInputString("");
                      updateSuggestedBrand(brandList, "");
                    }
                    if (reason === "clear") {
                      setSuggestedDosage([]);
                    }
                  }}
                  getOptionLabel={(option) => option.generic}
                  renderOption={(props, item) => {
                    return (
                      <li {...props} key={item.id}>
                        {item.generic}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder={t("generic_name")}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>
          <div className="addNewformGrp">
            <div className="formElement">
              <FormControl className="formControl">
                <Autocomplete
                  className="formAutocompleteField"
                  variant="outlined"
                  value={selectedBrand}
                  options={suggestedBrand}
                  inputValue={brandInputString}
                  onChange={(e, newValue) => {
                    setSelectedBrand(newValue);
                    if (newValue) {
                      const { genericId, formId } = newValue;
                      setDosageAccordingToFormId(formId);
                      let tempDoctorGeneric = doctorGenericList.find(
                        (item) => item.id === genericId
                      );
                      if (tempDoctorGeneric) {
                        setSelectedGeneric(tempDoctorGeneric);
                      } else {
                        tempDoctorGeneric = genericList.find(
                          (item) => item.id === genericId
                        );
                        if (tempDoctorGeneric) {
                          setSelectedGeneric(tempDoctorGeneric);
                        } else {
                          // toast.error(
                          //   t(
                          //     "this_brands_generic_name_is_not_present_in_the_table"
                          //   )
                          // );
                          setSelectedGeneric(null);
                          setGenericSearchInput("");
                        }
                      }
                    }
                  }}
                  onInputChange={(e, value) => {
                    setBrandInputString(value);
                  }}
                  getOptionLabel={(option) => option.brandName}
                  renderOption={(props, item) => {
                    return (
                      <li {...props} key={item.id}>
                        {item.brandName}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder={t("search_by_brand")}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>
          <div className="formElementGroup ">
            <div className="formElement ">
              <FormControl className="formControl">
                <InputLabel id="severity-select-label">
                  {t("dosage")}
                </InputLabel>
                <Select
                  labelId="severity-select-label"
                  id="severity-select"
                  // required
                  value={dosage}
                  label={t("dosage")}
                  onChange={handleChangeDosage}
                  className="formInputField"
                  variant="outlined"
                >
                  {suggestedDosage &&
                    suggestedDosage.map((item) => {
                      return <MenuItem value={item}>{item.dosage} </MenuItem>;
                    })}
                  {/* <MenuItem value="2">Two</MenuItem>
                  <MenuItem value="3"> Three </MenuItem> */}
                </Select>
              </FormControl>
            </div>

            <div className="formElement ">
              <FormControl className="formControl">
                <InputLabel id="frequency-select-label">
                  {t("frequency")}
                </InputLabel>
                <Select
                  labelId="frequency-select-label"
                  id="frequency-select"
                  value={frequency}
                  label={t("frequency")}
                  onChange={handleChangeFrequency}
                  className="formInputField"
                  variant="outlined"
                >
                  {frequencyList &&
                    frequencyList.map((item) => {
                      return (
                        <MenuItem value={item.id}>{item.frequency} </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>

            <div className="formElement ">
              <FormControl className="formControl">
                <InputLabel id="sincemedtakeDate" className="setCompletionDate">
                  {t("since")}
                </InputLabel>
                <ReactDatePicker
                  locale={currentLocale}
                  showYearDropdown
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  showMonthDropdown
                  showIcon
                  toggleCalendarOnIconClick
                  labelId="sincemedtakeDate"
                  className="formDatePicker"
                  selected={sincemedtakeDate}
                  maxDate={new Date()}
                  dateFormat="dd-MMM-yyyy"
                  onChange={(date) => {
                    console.log("new date = ", date);
                    setSincemedtakeDate(date);
                  }}
                />
              </FormControl>
            </div>

            <Button
              className="dfultPrimaryBtn "
              startIcon={<Add />}
              onClick={handleAddMedication}
            >
              {t("add")}
            </Button>
          </div>

          <div className="bb"></div>

          <div className="tskElemHeding hilightHeading">
            {t("past_illness")}
          </div>

          <ul className="withOutList pastIllness">
            {previousIllness &&
              previousIllness.map((item, index) => {
                return (
                  <li>
                    <div className="complstDtlGrp">
                      <div className="complstDtl  ">
                        <div className="compntName">{item.diseaseName}</div>
                        {item.diagnosisDate && (
                          <div className="compinfoGRP">
                            <div className="compStime">
                              <span>{t("reported_date")}:</span>
                              <span className="data">{item.diagnosisDate}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      <IconButton
                        className="removeItemBtn"
                        onClick={() => {
                          const temp = [...previousIllness];
                          temp.splice(index, 1);
                          updateData({ prevIllness: temp });
                          setPreviousIllness(temp);
                        }}
                      >
                        <Trash />
                      </IconButton>
                    </div>
                  </li>
                );
              })}

            {userPastIllnessList &&
              userPastIllnessList.map((item, index) => {
                return (
                  <li>
                    {/* <div className="valuDateRow">
                      <div className="piValu">{item.illness.diseaseName}</div>
                      <div className="piDate">
                        {dayjs(item.date).format("DD-MMM-YYYY")}
                      </div>
                      <IconButton
                        className="removeItemBtn"
                        onClick={() => {
                          const temp = [...userPastIllnessList];
                          temp.splice(index, 1);
                          setUserPassIllnessList(temp);
                        }}
                      >
                        <Trash />
                      </IconButton>
                    </div> */}

                    <div className="complstDtlGrp">
                      <div className="complstDtl  ">
                        <div className="compntName">
                          {item.illness.diseaseName}
                        </div>
                        {item.date && (
                          <div className="compinfoGRP">
                            <div className="compStime">
                              <span>{t("reported_date")}:</span>
                              <span className="data">
                                {dayjs(item.date).format("DD-MMM-YYYY")}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <IconButton
                        className="removeItemBtn"
                        onClick={() => {
                          const temp = [...userPastIllnessList];
                          temp.splice(index, 1);
                          updateData({ prevIllness: temp });
                          setUserPassIllnessList(temp);
                        }}
                      >
                        <Trash />
                      </IconButton>
                    </div>
                  </li>
                );
              })}

            <li>
              <div className="addNewformGrp">
                <div className="formElement">
                  <FormControl className="formControl">
                    {/* <TextField
                      // hiddenLabel
                      label="Past illness"
                      required
                      autoComplete="off"
                      placeholder="Add Past illness"
                      variant="outlined"
                      className="formTextFieldArea"
                      value={pastIllness}
                      onChange={(e) => {
                        setPassIllness(e.target.value);
                      }}
                    /> */}

                    <Autocomplete
                      freeSolo
                      className="formAutocompleteField"
                      variant="outlined"
                      value={selectedPastIllness}
                      options={suggestedDiseaseList}
                      inputValue={pastIllnessString}
                      onChange={(e, newValue) => {
                        setSelectedPastIllness(newValue);
                      }}
                      onInputChange={(e, value) => {
                        setPastIllnessString(value);
                      }}
                      getOptionLabel={(option) => option.diseaseName}
                      renderOption={(props, item) => {
                        return (
                          <li {...props} key={item.id}>
                            {item.diseaseName}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="formAutoComInputField autocompFildPlaceholder"
                          placeholder={t("add_past_illness")}
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>

                <div className="formElement mxW-150">
                  <FormControl className="formControl">
                    <InputLabel
                      id="pastIllnessDate"
                      className="setCompletionDate"
                    >
                      {t("select_date")}
                    </InputLabel>
                    <ReactDatePicker
                      locale={currentLocale}
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      showIcon
                      toggleCalendarOnIconClick
                      labelId="pastIllnessDate"
                      className="formDatePicker"
                      selected={pastIllnessDate}
                      maxDate={new Date()}
                      dateFormat="dd-MMM-yyyy"
                      onChange={(date) => {
                        console.log("new date = ", date);
                        setPastIllnessDate(date);
                      }}
                    />
                  </FormControl>
                </div>

                <Button
                  className="dfultPrimaryBtn "
                  startIcon={<Add />}
                  onClick={() => {
                    if (!pastIllnessString || pastIllnessString === "") {
                      toast.error(t("please_provide_illness_name"));
                      return;
                    }
                    let tempPastIllness = selectedPastIllness;
                    if (!tempPastIllness) {
                      tempPastIllness = {
                        id: uuidv4(),
                        diseaseName: pastIllnessString,
                        icd11code: "",
                        snowmedCt: "",
                      };
                    }
                    setUserPassIllnessList((prev) => {
                      const temp = [
                        ...prev,
                        { illness: tempPastIllness, date: pastIllnessDate },
                      ];

                      updateData({ pastIllness: temp });
                      return temp;
                    });
                    initializePastIllness();
                  }}
                >
                  {t("add")}
                </Button>
              </div>
            </li>
          </ul>

          <div className="tskElemHeding hilightHeading">
            {t("previous_procedure")}
          </div>

          <ul className="withOutList pastIllness">
            {previousProcedure?.map((item, index) => {
              return (
                <li>
                  <div className="complstDtlGrp">
                    <div className="complstDtl  ">
                      <div className="compntName">{item.procedureName}</div>
                      {item.procedureDate && (
                        <div className="compinfoGRP">
                          <div className="compStime">
                            <span>{t("reported_date")}:</span>
                            <span className="data">{item.procedureDate}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <IconButton
                      className="removeItemBtn"
                      onClick={() => {
                        const temp = [...previousProcedure];
                        temp.splice(index, 1);
                        updateData({ prevSurgerry: temp });
                        setPreviousProcedure(temp);
                      }}
                    >
                      <Trash />
                    </IconButton>
                  </div>
                </li>
              );
            })}

            {userProcedureList &&
              userProcedureList.map((item, index) => {
                return (
                  <li>
                    {/* <div>
                      <div className="valuDateRow">
                        <div className="piValu">
                          {item.surgery.procedureName}
                        </div>
                        <div className="piDate">
                          {dayjs(item.date).format("DD-MMM-YYYY")}
                        </div>
                      </div>
                      <IconButton
                        className="removeItemBtn"
                        onClick={() => {
                          const temp = [...userProcedureList];
                          temp.splice(index, 1);
                          setUserProcedureList(temp);
                        }}
                      >
                        <Trash />
                      </IconButton>
                    </div> */}
                    <div className="complstDtlGrp">
                      <div className="complstDtl  ">
                        <div className="compntName">
                          {item.surgery.procedureName}
                        </div>
                        {item.date && (
                          <div className="compinfoGRP">
                            <div className="compStime">
                              <span>{t("reported_date")}:</span>
                              <span className="data">
                                {dayjs(item.date).format("DD-MMM-YYYY")}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <IconButton
                        className="removeItemBtn"
                        onClick={() => {
                          const temp = [...userProcedureList];
                          temp.splice(index, 1);
                          updateData({ prevSurgery: temp });

                          setUserProcedureList(temp);
                        }}
                      >
                        <Trash />
                      </IconButton>
                    </div>
                  </li>
                );
              })}

            <li>
              <div className="addNewformGrp">
                <div className="formElement">
                  <FormControl className="formControl">
                    {/* <TextField
                      // hiddenLabel
                      label="Previous procedure"
                      required
                      autoComplete="off"
                      placeholder="Add Previous procedure"
                      variant="outlined"
                      className="formTextFieldArea"
                      value={previousSurgery}
                      onChange={(e) => {
                        setPreviousSurgery(e.target.value);
                      }}
                    /> */}

                    <Autocomplete
                      freeSolo
                      className="formAutocompleteField"
                      variant="outlined"
                      value={selectedProcedure}
                      options={suggestedProcedure}
                      inputValue={procedureString}
                      onChange={(e, newValue) => {
                        setSelectedProcedure(newValue);
                      }}
                      onInputChange={(e, value) => {
                        setProcedureString(value);
                      }}
                      getOptionLabel={(option) => option.procedureName}
                      renderOption={(props, item) => {
                        return (
                          <li {...props} key={item.id}>
                            {item.procedureName}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="formAutoComInputField autocompFildPlaceholder"
                          placeholder={t("add_previous_procedure")}
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>

                <div className="formElement mxW-150">
                  <FormControl className="formControl">
                    <InputLabel
                      id="setsurgeryDate"
                      className="setCompletionDate"
                    >
                      {t("surgery_date")}
                    </InputLabel>
                    <ReactDatePicker
                      locale={currentLocale}
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      showIcon
                      toggleCalendarOnIconClick
                      labelId="setsurgeryDate"
                      className="formDatePicker"
                      selected={surgeryDate}
                      maxDate={new Date()}
                      dateFormat="dd-MMM-yyyy"
                      onChange={(date) => {
                        console.log("new date = ", date);
                        setSurgeryDate(date);
                      }}
                    />
                  </FormControl>
                </div>

                <Button
                  className="dfultPrimaryBtn "
                  startIcon={<Add />}
                  onClick={() => {
                    let tempProcedure = selectedProcedure;
                    if (!procedureString || procedureString === "") {
                      toast.error(t("please_provide_procedure_name"));
                      return;
                    }
                    if (!tempProcedure) {
                      tempProcedure = {
                        id: uuidv4(),
                        // foodName: procedureString,
                        procedureName: procedureString,
                        gender: "",
                        locallyAdded: true,
                      };
                    }
                    setUserProcedureList((prev) => {
                      const temp = [
                        ...prev,
                        { surgery: tempProcedure, date: surgeryDate },
                      ];
                      updateData({ prevSurgery: temp });
                      return temp;
                    });
                    initializeProcedure();
                  }}
                >
                  {t("add")}
                </Button>
              </div>
            </li>
          </ul>
          {showConfirm && (
            <CustomModal
              modalTitle={confirmString.current}
              // style={{ zIndex: 10001 }}
              closeModal={() => {
                setShowConfirmation(false);
              }}
              className={"width90"}
            >
              <div></div>

              <div className="modActionBtnGrp actionsCreateOrg">
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    addMedicineToList();
                    setShowConfirmation(false);
                  }}
                >
                  {t("continue")}
                </Button>
                <Button
                  className="dfultPrimaryBtn"
                  onClick={(e) => {
                    setShowConfirmation(false);
                  }}
                >
                  {t("cancel")}
                </Button>
              </div>
            </CustomModal>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
