import { Add, ArrowBack, Search, Visibility } from "@mui/icons-material";
import { Button, CircularProgress, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Download, Trash } from "react-bootstrap-icons";
import { getBudget } from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";

export default function ViewBudget({
  handelCloseViewBudget,
  handelOpenAddBudget,
  refreshBudget,
  handleOpenViewSelectedBudget,
  closeAll,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [budgetData, setBudgetData] = useState([]);

  const [filteredBudgetData, setFilteredBudgetData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBudgetData();
  }, [refreshBudget]);

  const fetchBudgetData = () => {
    getBudget(userDetails.orgId)
      .then((response) => {
        console.log("getBudget", response.data);
        setBudgetData(response.data);
        setFilteredBudgetData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        setLoading(false);
      });
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = budgetData.filter(
      (budget) =>
        budget.budgetName &&
        budget.budgetName.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredBudgetData(filteredData);
  };
  
  // useEffect(()=>{
  // setBookSteps([
  //   {
  //     target: ".viewBudgetStepOne", 
  //     content: "You can View All the Budget Here.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".viewBudgetStepTwo",
  //     content: "Click here to go back to the previous screen.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".viewBudgetStepThree",
  //     content: "Use this search bar to filter budgets by name.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".viewBudgetStepFour", 
  //     content: "Click here to create a new budget.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".viewBudgetStepFive",
  //     content: "Here is a list of your budgets, including details like name, unit, start and end dates, and amounts.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".viewBudgetStepSix",
  //     content: "Click this button to view detailed information for a specific budget.",
  //     disableBeacon: true,
  //   },
  // ])
  // },[])

  useEffect(() => {
  
    setBookSteps([
      {
        target: ".viewBudgetStepOne",
        content: t("view_budget_step_one"), 
        disableBeacon: true,
      },
      {
        target: ".viewBudgetStepTwo",
        content: t("view_budget_step_two"), 
        disableBeacon: true,
      },
      {
        target: ".viewBudgetStepThree",
        content: t("view_budget_step_three"), 
        disableBeacon: true,
      },
      {
        target: ".viewBudgetStepFour",
        content: t("view_budget_step_four"), 
        disableBeacon: true,
      },
      {
        target: ".viewBudgetStepFive",
        content: t("view_budget_step_five"), 
        disableBeacon: true,
      },
      {
        target: ".viewBudgetStepSix",
        content: t("view_budget_step_six"), 
        disableBeacon: true,
      }
    ]);
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["viewBudgetDS"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        locale={{
          back: t('back'),
          last: t('finish'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="AnalyticsDashboardContainer viewBudgetStepOne">
        <div className="AnalyticsDashboardSubHeader viewBudgetStepTwo">
          <IconButton
            onClick={() => {
              handelCloseViewBudget();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("budget")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              <div className="searchTasklist viewBudgetStepThree">
                <input
                  type="text"
                  class="serchTaskInput "
                  placeholder={t("search_budget")}
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              <div className="addUnitsBtn">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn viewBudgetStepFour"
                  onClick={() => handelOpenAddBudget()}
                >
                  {t("budget")}
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : budgetData.length === 0 ? (
                <div class="noDataCard ">
                  <span>{t("oops")}</span>
                  {t("no_budget_define_yet")}
                  <br />
                  <span className="sallMsg">
                    {t("click_add_budget_button_to_create_new_budget")}
                  </span>
                </div>
              ) : (
                <div className="TableContainer viewBudgetStepFive">
                  <table className="taskListTable">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th className="width100">{t("sl_no")}</th>
                        <th>{t("budget_name")}</th>
                        <th>{t("budget_for")}</th>
                        <th>{t("unit_name")}</th>
                        <th>{t("start_date")}</th>
                        <th>{t("end_date")}</th>
                        <th>{t("budget_amount")}</th>
                        <th className="width100"></th>
                      </tr>
                    </thead>
                    {/* <tbody className="scrolableTableBody">
                    <tr>
                      <td>
                        <div className="tskLstTaskNM">1.</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">C</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Unit</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">New Budget</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          18-03-2024 &nbsp; 5:30PM
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          18-03-2024 &nbsp; 5:30PM
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">2000</div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <IconButton>
                            <Visibility />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody> */}
                    <tbody className="scrolableTableBody">
                      {filteredBudgetData.map((budget, index) => (
                        <tr key={index}>
                          <td>
                            <div className="tskLstTaskNM">{index + 1}.</div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {budget.budgetName}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {budget.budgetFor === "C"
                                ? t("consolidated")
                                : t("unit")}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {budget.unitName === ""
                                ? t("N_A")
                                : budget.unitName}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {budget.startDate}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">{budget.endDate}</div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {budget.budgetAmout}
                            </div>
                          </td>
                          <td>
                            <div className="tblActionBtnGrp viewBudgetStepSix">
                              <IconButton
                                onClick={() =>
                                  handleOpenViewSelectedBudget(budget)
                                }
                              >
                                <Visibility />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
