import React from "react";
import SupportTicketCloseFeedbackForm from "./SupportTicketCloseFeedbackForm";

export default function RightSideSupportTicketCloseFeedbackForm({
  setShowSupportTicketCloseFeedbackForm,
  supportTicketData,
  handleCloseFeedbackAndCloseTicket,
}) {
  return (
    <>
      <div className="RightFlotingContainerArea feedbackFormSupport">
        <SupportTicketCloseFeedbackForm
          setShowSupportTicketCloseFeedbackForm={
            setShowSupportTicketCloseFeedbackForm
          }
          supportTicketData={supportTicketData}
          handleCloseFeedbackAndCloseTicket={handleCloseFeedbackAndCloseTicket}
        />
      </div>
    </>
  );
}
