const subdomain = window.location.host;
let host = window.location.host.replace("www.", "");
const domainArray = host.split(".");
const collaboraLink = subdomain;

export const exportConstants = (env) => {
  if (env === "ahlan.work") {
    return {
      stripeKey: `pk_test_51MHn49EJpxma8o1MhWPAn6yBo0o4qpMqDKf2bln8Rqe6TodwjPSRiW9QEiDpwbohrA6YBtlWfX8G8v47MmHNUEns00o447ysov`,
      collaboraDomain: `https://${collaboraLink}`,
      collabraApiEndPoint: "https://api.ahlan.work/zoyelapi",
      apiEndPoint: "https://api.ahlan.work/zoyelapi",
      socketEndPoint: "https://chatserver.ahlan.work",
      apiEndPointSSE: "https://ahlan.asia/ahlansse",
      mailTopicName: "projects/zoyel-app-9272528/topics/zoyel-app-mail",
      jitsiDomain: "https://video.zoyel.one",
      jitsiHostName: "video.zoyel.one",
      domainArray: window.location.host.replace("www.", "").split("."),
      publicUrl: domainArray.length === 3 ? "/app" : "",
      webApp: "ahlan.work",
    };
  } else if (env === "zoyel.one") {
    return {
      stripeKey: `pk_live_51MHn49EJpxma8o1MI5c83xKUHzGWCZNiD43W8q1YVmeYjLKd9PO8643s8o65dzVx5V4nmXilA5MzoMOQFGmFlyt500R2oZNIou`,
      collaboraDomain: `https://${collaboraLink}`,
      collabraApiEndPoint: "https://api.zoyel.one/z1",
      apiEndPoint: "https://api.zoyel.one/z1",
      socketEndPoint: "https://websocket.zoyel.one",
      apiEndPointSSE: "https://ahlan.asia/ahlansse",
      mailTopicName: "projects/zoyel-app-9272528/topics/zoyel-app-mail",
      jitsiDomain: "https://video.zoyel.one",
      jitsiHostName: "video.zoyel.one",
      domainArray: window.location.host.replace("www.", "").split("."),
      publicUrl: domainArray.length === 3 ? "/app" : "",
      webApp: "zoyel.one",
    };
  } else if (env === "zoyel.health") {
    return {
      stripeKey: `pk_live_51MHn49EJpxma8o1MI5c83xKUHzGWCZNiD43W8q1YVmeYjLKd9PO8643s8o65dzVx5V4nmXilA5MzoMOQFGmFlyt500R2oZNIou`,
      collabraApiEndPoint: "https://api.zoyel.health/zoyelapi", //has to be changed in future
      collaboraDomain: `https://${collaboraLink}`,
      // apiEndPoint: "https://api.zoyel.health/zoyelapi",
      apiEndPoint: "https://api.zoyel.online/zoyelapi",
      socketEndPoint: "https://chatserver.zoyel.health",
      apiEndPointSSE: "https://ahlan.asia/ahlansse",
      mailTopicName: "projects/zoyel-app-9272528/topics/zoyel-app-mail",
      jitsiDomain: "https://video.zoyel.one",
      jitsiHostName: "video.zoyel.one",
      domainArray: window.location.host.replace("www.", "").split("."),
      publicUrl: domainArray.length === 3 ? "/app" : "",
      webApp: "zoyel.health",
    };
  } else if (env === "local") {
    return {
      stripeKey: `pk_test_51MHn49EJpxma8o1MhWPAn6yBo0o4qpMqDKf2bln8Rqe6TodwjPSRiW9QEiDpwbohrA6YBtlWfX8G8v47MmHNUEns00o447ysov`,
      collaboraDomain: `https://${collaboraLink}`,
      collabraApiEndPoint: "https://api.ahlan.work/zoyelapi",
      apiEndPoint: "https://api.ahlan.work/zoyelapi",

      socketEndPoint: "https://chatserver.ahlan.work",
      // socketEndPoint: "http://localhost:4000",

      apiEndPointSSE: "https://ahlan.asia/ahlansse",
      mailTopicName: "projects/zoyel-app-9272528/topics/zoyel-app-mail",
      jitsiDomain: "https://video.zoyel.one",
      jitsiHostName: "video.zoyel.one",
      domainArray: window.location.host.replace("www.", "").split("."),
      publicUrl: domainArray.length === 3 ? "/app" : "",
      webApp: "zoyel.health",
    };
  } else if (env === "local-prashant") {
    return {
      stripeKey: `pk_test_51MHn49EJpxma8o1MhWPAn6yBo0o4qpMqDKf2bln8Rqe6TodwjPSRiW9QEiDpwbohrA6YBtlWfX8G8v47MmHNUEns00o447ysov`,
      collaboraDomain: `https://${collaboraLink}`,
      collabraApiEndPoint: "http://localhost:8080/zoyelapi",
      apiEndPoint: "http://localhost:8080/zoyelapi",
      socketEndPoint: "https://chatserver.ahlan.work",
      apiEndPointSSE: "https://ahlan.asia/ahlansse",
      mailTopicName: "projects/zoyel-app-9272528/topics/zoyel-app-mail",
      jitsiDomain: "https://video.zoyel.one",
      jitsiHostName: "video.zoyel.one",
      domainArray: window.location.host.replace("www.", "").split("."),
      publicUrl: domainArray.length === 3 ? "/app" : "",
      webApp: "localhost:3000",
    };
  }
};
