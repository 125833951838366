import authHeader from "../config/auth-header-config";
import http from "../config/http-common";
import { getLoginUserDetailsLocal } from "../utils/Utils";

class SupportService {
  createSupportTicket(reqDto, userId) {
    return http.post(`/support/createSupportTicket/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getSupportTicketsOfUnit(unitId, fromDate, toDate) {
    let userDetails = getLoginUserDetailsLocal();
    if (
      userDetails.userType &&
      userDetails.userType.toLowerCase() === "admin"
    ) {
      return http.get(
        `/support/getSupportTicketsOfAdmin/${userDetails.orgId}/${fromDate}/${toDate}`,
        {
          headers: authHeader(),
        }
      );
    } else {
      return http.get(
        `/support/getSupportTicketsOfUnit/${unitId}/${fromDate}/${toDate}`,
        {
          headers: authHeader(),
        }
      );
    }
  }

  getSupportTicketDetailsByTicketRowId(unitId) {
    return http.get(`/support/getSupportTicketDetailsByTicketRowId/${unitId}`, {
      headers: authHeader(),
    });
  }

  getSupportTicketTaskComments(taskId) {
    return http.get(`/support/getSupportTicketTaskComments/${taskId}`, {
      headers: authHeader(),
    });
  }

  getLastTicketUpdateByUserDetails(taskId) {
    return http.get(`/support/getLastTicketUpdateByUserDetails/${taskId}`, {
      headers: authHeader(),
    });
  }

  sendSupportTaskComments(reqDto, userId, ticketRowId) {
    return http.put(
      `/support/sendSupportTaskComments/${userId}/${ticketRowId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  closeSupportTicket(reqDto, userId) {
    return http.post(`/support/closeSupportTicket/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  submitFeedbackSupport(reqDto) {
    return http.post(`/zhFeedback/submitFeedbackSupport`, reqDto, {
      headers: authHeader(),
    });
  }

  checkIfUserSubmittedFeedbackAgainstTicket(userId, supportTicketRowId) {
    return http.get(
      `/zhFeedback/checkIfUserSubmittedFeedbackAgainstTicket/${userId}/${supportTicketRowId}`,
      {
        headers: authHeader(),
      }
    );
  }
  forwardSupportTicket(reqDto) {
    return http.post(`/support/forwardSupportTicket`, reqDto, {
      headers: authHeader(),
    });
  }

  getUsersForTicketForwarding(taskId) {
    return http.get(`support/getUsersForTicketForwarding/${taskId}`, {
      headers: authHeader(),
    });
  }

  getUserListForDelegateSupportTask(taskId, userId) {
    return http.get(
      `task/getUserListForDelegateSupportTask/${taskId}/${userId}`,
      {
        headers: authHeader(),
      }
    );
  }
  getCommentsDocumentsOfForwardedTask(taskId) {
    return http.get(`support/getCommentsDocumentsOfForwardedTask/${taskId}`, {
      headers: authHeader(),
    });
  }

  commentsOnForwardedTask(taskId, forwardToPkId, reqDto) {
    return http.post(
      `/support/commentsOnForwardedTask/${taskId}/${forwardToPkId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }
  commentsOnCompletedForwardedTask(taskId, forwardToPkId, reqDto) {
    return http.post(
      `/support/commentsOnCompletedForwardedTask/${taskId}/${forwardToPkId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }
  uploadFileOfSupportTask(taskId, forwardToPkId, reqDto) {
    return http.post(
      `/support/uploadFileOfSupportTask/${taskId}/${forwardToPkId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }
  reopenSupportTicket(userId, reqDto) {
    return http.post(`/support/reopenSupportTicket/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }
  getSupportTicketDetailsByTaskId(taskId) {
    return http.get(`/support/getSupportTicketDetailsByTaskId/${taskId}`, {
      headers: authHeader(),
    });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SupportService();
