import { Save } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DashboardServices from "../../../../../../services/DashboardServices";
import SecureIndexedDB from "../../../../../../utils/IndexedDB";
import { getHealthDto } from "./consultation/consultationUtil";
import { isLocalHost } from "../../../../../../utils/Utils";
import { toast } from "react-toastify";

export const MedicationModule = ({ medicineInfo, updateItem }) => {
  const { t } = useTranslation();

  const [dosage, setDosage] = useState("");
  const [route, setRoute] = useState("");
  const [frequency, setFrequency] = useState("");
  const [duration, setDuration] = useState("");
  const [dosageList, setDosageList] = useState([]);

  const [mTiming, setMTiming] = useState([]);

  const [medicationNote, setMedicationNote] = useState("");

  const [durationList, setDurationList] = useState([]);
  const [frequencyList, setFrequencyList] = useState([]);

  const [suggestedDosage, setSuggestedDosage] = useState([]);

  const [routeList, setRoutelist] = useState([]);
  const [timingList, setTimingList] = useState([]);
  const [allDataLoaded, setAllDataLoaded] = useState();

  const handleChangeDosage = (event) => {
    // ;
    // updateItem({ dosages: event.target.value });
    setDosage(event.target.value);
  };

  const handleChangemForm = (event) => {
    setRoute(event.target.value);
  };

  const handleChangeFrequency = (event) => {
    setFrequency(event.target.value);
  };

  const handleChangeRegimen = (event) => {
    setDuration(event.target.value);
  };

  const handleChangemTiming = (event) => {
    const {
      target: { value },
    } = event;
    setMTiming(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const getTimingList = async () => {
    const reqDto = await getHealthDto();
    DashboardServices.getMedicationTiming(reqDto).then((response) => {
      if (response.data) {
        setTimingList(response.data);
      }
    });
  };

  const getFormDosages = async () => {
    const diseasetring = await SecureIndexedDB.getItem("dosageList");
    if (diseasetring) {
      const tempDosage = JSON.parse(diseasetring);
      setDosageList(tempDosage);
      return tempDosage;
    }

    const reqDto = await getHealthDto();
    const response = await DashboardServices.getFormDosages(reqDto);
    if (response.data) {
      setDosageList(response.data);
      SecureIndexedDB.setItem("dosageList", JSON.stringify(response.data));
      return response.data;
    }
  };

  const getRoutes = async () => {
    const brandString = await SecureIndexedDB.getItem("routeList");
    if (brandString) {
      const tempRoute = JSON.parse(brandString);
      setRoutelist(tempRoute);
      return tempRoute;
    }

    const reqDto = await getHealthDto();
    const response = await DashboardServices.getRoutes(reqDto);
    if (response.data) {
      setRoutelist(response.data);
      SecureIndexedDB.setItem("routeList", JSON.stringify(response.data));
      return response.data;
    }
  };

  const setDosageAccordingToFormId = (formId) => {
    let dosageId = [];
    if (formId) {
      dosageId = dosageList?.filter((item) => item.formId === formId);
    } else {
      dosageId = dosageList?.filter((item) => item.formId === 1);
    }
    setSuggestedDosage(dosageId);
    return dosageId;
  };

  const getFrequencies = async () => {
    const diseasetring = await SecureIndexedDB.getItem("frequencyList");
    if (diseasetring) {
      const tempFreq = JSON.parse(diseasetring);
      setFrequencyList(tempFreq);
      return tempFreq;
    }

    const reqDto = await getHealthDto();
    const response = await DashboardServices.getFrequencies(reqDto);
    if (response.data) {
      setFrequencyList(response.data);
      SecureIndexedDB.setItem("frequencyList", JSON.stringify(response.data));
      return response.data;
    }
  };

  const getDurations = async () => {
    const diseasetring = await SecureIndexedDB.getItem("durationList");
    if (diseasetring) {
      const tempDuration = JSON.parse(diseasetring);
      setDurationList(tempDuration);
      return tempDuration;
    }

    const reqDto = await getHealthDto();
    const response = await DashboardServices.getDurations(reqDto);
    if (response.data) {
      setDurationList(response.data);
      SecureIndexedDB.setItem("durationList", JSON.stringify(response.data));
      return response.data;
    }
  };

  useEffect(() => {
    const loadAllData = async () => {
      await getFormDosages();
      await getRoutes();
      await getFrequencies();
      await getDurations();
      await getTimingList();
      setAllDataLoaded(true);
    };
    loadAllData();
  }, []);

  useEffect(() => {
    const {
      generic: { formId },
      // form: { formId },
      route,
      frequency,
      duration,
      note,
      timing,
      dosages,
    } = medicineInfo;

    let tempSuggestedDosages = [];
    if (formId && dosageList?.length > 0) {
      tempSuggestedDosages = setDosageAccordingToFormId(formId);
      console.log("temp Suggested dosages =", tempSuggestedDosages);
    }

    if (route && routeList) {
      const selectedRoute = routeList?.find((item) => {
        return item.id === route.id;
      });
      if (selectedRoute) {
        setRoute(selectedRoute);
      }
    }

    if (frequency) {
      // const selectedFrequency=frequencyList?find(item=>{
      //   item.id===frequency.frequencyId
      // })
      setFrequency(frequency.id);
    }
    if (duration) {
      setDuration(duration.id);
    }

    if (note) {
      setMedicationNote(note);
    }
    if (timing) {
      setMTiming(timing);
    }

    if (dosages) {
      let tempSelectedDosage = tempSuggestedDosages?.find(
        (item) =>
          item.formId === dosages.formId && item.dosage === dosages.dosage
      );
      if (tempSelectedDosage) {
        setDosage(tempSelectedDosage);
      } else {
        setDosage(dosages);
      }
    }

    // if (dosages) {
    //   tempSuggestedDosages?.find(item=>item.)
    //   dosage
    // }
  }, [medicineInfo, allDataLoaded]);

  return (
    <>
      <div className="formElementGroup ">
        <div className="formElement minW90px">
          <FormControl className="formControl">
            <InputLabel id="severity-select-label">
              {t("dosage") + "*"}
            </InputLabel>
            <Select
              labelId="severity-select-label"
              id="severity-select"
              value={dosage}
              label={t("dosage")}
              onChange={handleChangeDosage}
              className="formInputField"
              variant="outlined"
            >
              {suggestedDosage &&
                suggestedDosage.map((item) => {
                  return <MenuItem value={item}>{item.dosage} </MenuItem>;
                })}
            </Select>
          </FormControl>
        </div>
        <div className="formElement minW150px">
          <FormControl className="formControl">
            <InputLabel id="Form-select-label">{t("rout") + "*"}</InputLabel>
            <Select
              labelId="Form-select-label"
              id="Form-select"
              value={route}
              label={t("rout") + "*"}
              onChange={handleChangemForm}
              className="formInputField"
              variant="outlined"
            >
              <MenuItem key={"selectRoute"} value={""}>
                {t("select_route")}
              </MenuItem>
              {routeList &&
                routeList.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item}>
                      {item.route}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
        <div className="formElement minW120px">
          <FormControl className="formControl">
            <InputLabel id="frequency-select-label">
              {t("frequency") + "*"}
            </InputLabel>
            <Select
              labelId="frequency-select-label"
              id="frequency-select"
              value={frequency}
              label={t("frequency")}
              onChange={handleChangeFrequency}
              className="formInputField"
              variant="outlined"
            >
              <MenuItem value={""}>--Select Frequency--</MenuItem>
              {frequencyList &&
                frequencyList.map((item) => {
                  return <MenuItem value={item.id}>{item.frequency} </MenuItem>;
                })}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="addNewformGrp">
        <div className="formElement minW110px">
          <FormControl className="formControl">
            <InputLabel id="regimen-select-label">
              {t("duration") + "*"}
            </InputLabel>
            <Select
              labelId="regimen-select-label"
              id="regimen-select"
              value={duration}
              label={t("duration")}
              onChange={handleChangeRegimen}
              className="formInputField"
              variant="outlined"
            >
              <MenuItem value={""}>--Select Duration--</MenuItem>
              {durationList &&
                durationList.map((item) => {
                  return (
                    <MenuItem value={item.id}>{item.durationName} </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>

        <div className="formElement mw212 minW150px">
          <FormControl className="formControl">
            <InputLabel id="demo-multiple-checkbox-label">
              {t("timing")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={mTiming}
              onChange={handleChangemTiming}
              input={<OutlinedInput label={t("timing")} />}
              renderValue={(selected) =>
                selected.map((item) => t(item)).join(", ")
              }
              MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
              className="formInputField"
              variant="outlined"
            >
              {timingList.map((key) => (
                <MenuItem key={key} value={key}>
                  <Checkbox checked={mTiming.indexOf(key) > -1} />
                  <ListItemText primary={key} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="addNewformGrp">
        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              // hiddenLabel
              label={t("notes")}
              autoComplete="off"
              variant="outlined"
              value={medicationNote}
              onChange={(e) => {
                setMedicationNote(e.target.value);
              }}
              className="formTextFieldArea"
            />
          </FormControl>
        </div>

        <Button
          className="dfultPrimaryBtn "
          startIcon={<Save />}
          onClick={() => {
            const tempRoute = routeList?.find((item) => item.id === route.id);
            const tempDuration = durationList?.find(
              (item) => item.id === duration
            );
            const tempFreq = frequencyList?.find(
              (item) => item.id === frequency
            );

            if (!dosage || !dosage?.dosage) {
              toast.error(t("please_select_dosage"));
              return;
            }

            if (!tempRoute) {
              toast.error(t("please_select_route"));
              return;
            }

            if (!frequency || frequency === "") {
              toast.error(t("please_select_frequency"));
              return;
            }

            if (!tempDuration || tempDuration === "") {
              toast.error(t("please_select_duration"));
              return;
            }
            const dto = {
              route: tempRoute,
              dosages: dosage,
              duration: tempDuration,
              frequency: tempFreq,
              timing: mTiming || [],
              note: medicationNote || "",
              isEditing: false,
            };

            if (isLocalHost()) {
              console.log("dto =", dto);
              //   return;
            }
            updateItem(dto);
          }}
        >
          {t("save")}
        </Button>
      </div>
      {/* <div className="bb"></div> */}
    </>
  );
};
