import React, { useEffect, useState } from "react";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { getHealthDto } from "./consultationUtil";
import { v4 as uuidv4 } from "uuid";
import DateUtils from "../../../../../../../utils/DateUtils";
import { staticDataSocket } from "../../../../../../../socket";
import { GetLoginUserDetails } from "../../../../../../../utils/Utils";
import SecureIndexedDB from "../../../../../../../utils/IndexedDB";

export const useLogRxHandler = (logData, extraData) => {
  const loginUserDetail = GetLoginUserDetails();
  const [symptomList, setSymptomList] = useState([]);
  const [symptomSinceList, setSymptomSinceList] = useState([]);
  const [severityList, setSeverityList] = useState([]);
  const [signList, setSignList] = useState();
  const [prescriptionData, setPrescriptionData] = useState(null);
  const [physicalExamList, setPhysicalExamList] = useState([]);
  const [comorbidityList, setComorbidityList] = useState([]);
  const [allergicFoodList, setAllergicFoodList] = useState([]);
  const [diseaseList, setDiseaseList] = useState([]);
  const [procedureList, setProcedureList] = useState([]);
  const [riskFactorSince, setRiskFactorSicne] = useState([]);
  const [riskFactorList, setRiskFactorList] = useState([]);
  const [investigationList, setInvestigationList] = useState([]);
  const [genericList, setGenericList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [relationshipList, setRelationshipList] = useState([]);
  const [heriditoryDiseaseList, setHeriditoryDiseaseList] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [frequencyList, setFrequencyList] = useState([]);
  const [routeList, setRoutelist] = useState([]);
  const [dietList, setDietList] = useState([]);
  const [lifeStyleList, setLifeStyleList] = useState([]);
  const [specilizationList, setSpecializationList] = useState([]);
  const [hospitalList, setHospitalizationList] = useState();

  const [dosageList, setDosageList] = useState([]);
  const [isDataReady, setIsDataReady] = useState(false);

  const getSymptomSince = async () => {
    const symptomSinceString = await SecureIndexedDB.getItem("symptomSince");
    if (symptomSinceString) {
      setSymptomSinceList(JSON.parse(symptomSinceString));
      return;
    }
    const reqDto = await getHealthDto();
    DashboardServices.getSymptomSince(reqDto).then((response) => {
      if (response.data) {
        setSymptomSinceList(response.data);
        SecureIndexedDB.setItem("symptomSince", JSON.stringify(response.data));
      }
    });
  };

  const getSymptomSeverity = async () => {
    const symptomSeverityString = await SecureIndexedDB.getItem(
      "symptomSeverity"
    );
    if (symptomSeverityString) {
      setSeverityList(JSON.parse(symptomSeverityString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getSymptomSeverity(reqDto).then((response) => {
      if (response.data) {
        setSeverityList(response.data);
        SecureIndexedDB.setItem(
          "symptomSeverity",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const getSymptoms = async () => {
    const symptomString = await SecureIndexedDB.getItem("symptomList");
    if (symptomString) {
      setSymptomList(JSON.parse(symptomString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getSymptoms(reqDto).then((response) => {
      if (response.data) {
        setSymptomList(response.data);
        SecureIndexedDB.setItem("symptomList", JSON.stringify(response.data));
      }
    });
  };

  const getSigns = async () => {
    const symptomString = await SecureIndexedDB.getItem("signList");
    if (symptomString) {
      setSignList(JSON.parse(symptomString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getSigns(reqDto).then((response) => {
      if (response.data) {
        setSignList(response.data);
        SecureIndexedDB.setItem("signList", JSON.stringify(response.data));
      }
    });
  };

  const getPhyExams = async () => {
    const symptomString = await SecureIndexedDB.getItem("physicalExamList");
    if (symptomString) {
      setPhysicalExamList(JSON.parse(symptomString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getPhyExams(reqDto).then((response) => {
      if (response.data) {
        setPhysicalExamList(response.data);
        SecureIndexedDB.setItem(
          "physicalExamList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const getComorbidities = async () => {
    const diseasetring = await SecureIndexedDB.getItem("comorbodityList");
    if (diseasetring) {
      setComorbidityList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getComorbidities(reqDto).then((response) => {
      if (response.data) {
        setComorbidityList(response.data);
        SecureIndexedDB.setItem(
          "comorbodityList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const getFoodAllergies = async () => {
    const foodAlleergyString = await SecureIndexedDB.getItem("foodAllergies");
    if (foodAlleergyString) {
      setAllergicFoodList(JSON.parse(foodAlleergyString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getFoodAllergies(reqDto).then((response) => {
      if (response.data) {
        setAllergicFoodList(response.data);
        SecureIndexedDB.setItem("foodAllergies", JSON.stringify(response.data));
      }
    });
  };

  const getProcedures = async () => {
    const lifeStyleString = await SecureIndexedDB.getItem("procedureList");
    if (lifeStyleString) {
      setProcedureList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = await getHealthDto();
    DashboardServices.getProcedures(reqDto).then((response) => {
      if (response.data) {
        setProcedureList(response.data);
        SecureIndexedDB.setItem("procedureList", JSON.stringify(response.data));
      }
    });
  };

  const getDiseases = async () => {
    const diseasetring = await SecureIndexedDB.getItem("diseaseList");
    if (diseasetring) {
      setDiseaseList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getDiseases(reqDto).then((response) => {
      if (response.data) {
        setDiseaseList(response.data);
        SecureIndexedDB.setItem("diseaseList", JSON.stringify(response.data));
      }
    });
  };

  const getRiskFactors = async () => {
    const riskString = await SecureIndexedDB.getItem("riskList");
    if (riskString) {
      setRiskFactorList(JSON.parse(riskString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getRiskFactors(reqDto).then((response) => {
      if (response.data) {
        setRiskFactorList(response.data);
        SecureIndexedDB.setItem("riskList", JSON.stringify(response.data));
      }
    });
  };

  const getRiskFactorSince = async () => {
    const symptomString = await SecureIndexedDB.getItem("riskFactorSince");
    if (symptomString) {
      setRiskFactorSicne(JSON.parse(symptomString));
      return;
    }
    const reqDto = await getHealthDto();
    DashboardServices.getRiskFactorSince(reqDto).then((response) => {
      if (response.data) {
        setRiskFactorSicne(response.data);
        SecureIndexedDB.setItem(
          "riskFactorSince",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const getMedicalTestList = async () => {
    const symptomString = await SecureIndexedDB.getItem("medicalTestList");
    if (symptomString) {
      setInvestigationList(JSON.parse(symptomString));
      return;
    }

    const reqDto = await getHealthDto();

    DashboardServices.getMedicalTestList(reqDto).then((response) => {
      if (response.data) {
        setInvestigationList(response.data);
        SecureIndexedDB.setItem(
          "medicalTestList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const getGenericList = async () => {
    let doctorGenList =
      JSON.parse(await SecureIndexedDB.getItem("doctor_generic")) || [];
    let genList = JSON.parse(await SecureIndexedDB.getItem("generic")) || [];
    setGenericList([...doctorGenList, ...genList]);
  };

  const getBrands = async () => {
    return new Promise((resolve, reject) => {
      staticDataSocket.emit("requestData", {
        orgId: loginUserDetail.orgId,
        countryCode: loginUserDetail.orgCountryCode,
      });
      staticDataSocket.on("responseData", async (obj) => {
        console.log("staticDataSocket ", obj);
        if (obj && obj.brands && obj.brands.length > 0) {
          setBrandList(obj.brands);
          resolve(obj.brands);
        } else {
          const reqDto = await getHealthDto();
          DashboardServices.getBrands(reqDto).then((response) => {
            if (response.data) {
              setBrandList(response.data);
              resolve(response.data);
            }
          });
        }
      });
    });
  };

  const getHereditaryDiseases = async () => {
    const diseasetring = await SecureIndexedDB.getItem("heriditaryDisease");
    if (diseasetring) {
      setHeriditoryDiseaseList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getHereditaryDiseases(reqDto).then((response) => {
      if (response.data) {
        setHeriditoryDiseaseList(response.data);
        SecureIndexedDB.setItem(
          "heriditaryDisease",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const getRelationships = async () => {
    const diseasetring = await SecureIndexedDB.getItem("relationshipList");
    if (diseasetring) {
      setRelationshipList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getRelationships(reqDto).then((response) => {
      if (response.data) {
        setRelationshipList(response.data);
        SecureIndexedDB.setItem(
          "relationshipList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const getFrequencies = async () => {
    const diseasetring = await SecureIndexedDB.getItem("frequencyList");
    if (diseasetring) {
      setFrequencyList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getFrequencies(reqDto).then((response) => {
      if (response.data) {
        setFrequencyList(response.data);
        SecureIndexedDB.setItem("frequencyList", JSON.stringify(response.data));
      }
    });
  };

  const getDurations = async () => {
    const diseasetring = await SecureIndexedDB.getItem("durationList");
    if (diseasetring) {
      setDurationList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getDurations(reqDto).then((response) => {
      if (response.data) {
        setDurationList(response.data);
        SecureIndexedDB.setItem("durationList", JSON.stringify(response.data));
      }
    });
  };

  const getFormDosages = async () => {
    const diseasetring = await SecureIndexedDB.getItem("dosageList");
    if (diseasetring) {
      setDosageList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getFormDosages(reqDto).then((response) => {
      if (response.data) {
        setDosageList(response.data);
        SecureIndexedDB.setItem("dosageList", JSON.stringify(response.data));
      }
    });
  };

  const getRoutes = async () => {
    const brandString = await SecureIndexedDB.getItem("routeList");
    if (brandString) {
      setRoutelist(JSON.parse(brandString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getRoutes(reqDto).then((response) => {
      if (response.data) {
        setRoutelist(response.data);
        SecureIndexedDB.setItem("routeList", JSON.stringify(response.data));
      }
    });
  };

  const getDiets = async () => {
    const reqDto = await getHealthDto();
    const dietString = await SecureIndexedDB.getItem("dietList");
    if (dietString) {
      setDietList(JSON.parse(dietString));
      return;
    }
    DashboardServices.getDiets(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem("dietList", JSON.stringify(response.data));
        setDietList(response.data);
      }
    });
  };

  const getLifeStyles = async () => {
    const lifeStyleString = await SecureIndexedDB.getItem("lifeStyleList");
    if (lifeStyleString) {
      setLifeStyleList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = await getHealthDto();
    DashboardServices.getLifeStyles(reqDto).then((response) => {
      if (response.data) {
        setLifeStyleList(response.data);
        SecureIndexedDB.setItem("lifeStyleList", JSON.stringify(response.data));
      }
    });
  };

  const getSpecializations = async () => {
    const lifeStyleString = await SecureIndexedDB.getItem("specializationList");
    if (lifeStyleString) {
      setSpecializationList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = await getHealthDto();
    DashboardServices.getSpecializations(reqDto).then((response) => {
      if (response.data) {
        setSpecializationList(response.data || []);
        SecureIndexedDB.setItem(
          "specializationList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const getHospitalsOfOrg = async () => {
    const lifeStyleString = await SecureIndexedDB.getItem("hospitalList");
    if (lifeStyleString) {
      setHospitalizationList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = await getHealthDto();
    DashboardServices.getHospitalsOfOrg(reqDto.orgId).then((response) => {
      if (response.data) {
        setHospitalizationList(response.data);
        SecureIndexedDB.setItem("hospitalList", JSON.stringify(response.data));
      }
    });
  };

  useEffect(() => {
    const fetchAllData = async () => {
      await getSymptoms();
      await getSymptomSince();
      await getSymptomSeverity();
      await getSigns();
      await getPhyExams();
      await getComorbidities();
      await getFoodAllergies();
      await getDiseases();
      await getProcedures();
      await getRiskFactors();
      await getRiskFactorSince();
      await getMedicalTestList();
      await getGenericList();
      await getBrands();
      await getHereditaryDiseases();
      await getRelationships();
      await getFrequencies();
      await getDurations();
      await getFormDosages();
      await getRoutes();
      await getDiets();
      await getLifeStyles();
      await getHospitalsOfOrg();
      await getSpecializations();
      setIsDataReady(true);
    };
    fetchAllData();
  }, []);

  const handleSymptoms = (symptoms) => {
    const tempSymp = symptoms?.map((item) => {
      return {
        selectedSymptom: {
          id: item.symptomId,
          symptomName: item.symptomName,
          gender: "C",
        },
        severity: item.severity,
        since: item.since,
      };
    });
    return tempSymp || [];
  };

  const handleSigns = (signs) => {
    const tempSign = signs?.map((item) => {
      let matchedSign = null;
      if (signList) {
        matchedSign = signList.find((sign) => {
          return sign.id === item.signId;
        });
      }

      let testSign = {
        sign: {
          id: item.signId,
          signName: item.signName,
          gender: "C",
        },
        userNote: item.signNote,
      };

      //if you find the sign update gender to make it consistent
      if (matchedSign) {
        testSign.sign.gender = matchedSign.gender;
      }
      return testSign;
    });
    return tempSign || [];
  };

  const handlePhysicalExamList = (phyExams) => {
    let tmpPhysicalExmList = [];
    if (!phyExams) {
      return [];
    } else {
      phyExams?.forEach((element) => {
        const issue = physicalExamList?.find(
          (item) => item.phyExamId == element.phyExamId
        );
        let system = null;
        if (issue) {
          system = physicalExamList?.find(
            (item) => item.phyExamId === issue.phyExamTopParId
          );
        }
        if (issue && system) {
          tmpPhysicalExmList.push({
            system,
            issue,
            subsystem: null,
            parameter: null,
          });
        }
      });
    }
    return tmpPhysicalExmList;
  };

  const handleComorbidityList = (comorbidities) => {
    let tmpComorbidityList = [];
    if (!comorbidities) {
      return [];
    } else {
      comorbidities?.forEach((element) => {
        const tempComorbidity = comorbidityList?.find(
          (item) => item.comorbiditiesDiseasesName == element.diseaseName
        );

        if (tempComorbidity) {
          tmpComorbidityList.push({
            disease: tempComorbidity,
            since: element.since,
          });
        }
      });
    }
    return tmpComorbidityList;
  };

  const handleFoodAllergies = (foodAllergies) => {
    let tempFoodList = [];

    if (extraData && extraData?.reqValue?.foodAllergies) {
      const tempAllergicFood =
        extraData?.reqValue?.foodAllergies?.map((item) => {
          return {
            id: uuidv4(),
            foodName: item,
            locallyAdded: true,
            previouslyAdded: true,
          };
        }) || [];
      tempFoodList = [...tempFoodList, ...tempAllergicFood];
    }
    if (!foodAllergies) {
      return [];
    } else {
      foodAllergies.forEach((food) => {
        tempFoodList.push({
          id: uuidv4(),
          foodName: food,
          locallyAdded: true,
        });
      });
    }

    return tempFoodList;
  };

  const handlePreviousIllness = (pastDieseases) => {
    const tempDis1 =
      extraData?.reqValue?.diseases?.map((item, index) => {
        const prevDiseases = diseaseList.find((disease) => {
          return disease.diseaseName === item.diseaseName;
        });

        let tempPastIllness = null;
        if (prevDiseases) {
          tempPastIllness = {
            id: prevDiseases.id,
            diseaseName: item.diseaseName,
            icd11code: item.icd11code,
            snowmedCt: item.snowmedCt,
            previouslyAdded: true,
            prevId: item.id,
          };
        } else {
          tempPastIllness = {
            id: 0,
            diseaseName: item.diseaseName,
            icd11code: item.icd11code,
            snowmedCt: item.snowmedCt,
            previouslyAdded: true,
            prevId: item.id,
          };
        }

        const temp = {
          illness: tempPastIllness,
          date: item.diagnosisDate
            ? DateUtils.convertToDate(item.diagnosisDate)
            : null,
        };
        return temp;
      }) || [];

    const tempDis2 =
      pastDieseases?.map((item) => {
        const prevDiseases = diseaseList?.find((disease) => {
          return disease.diseaseName === item.diseaseName;
        });

        let tempPastIllness = null;
        if (prevDiseases) {
          tempPastIllness = {
            id: prevDiseases?.id,
            diseaseName: item.diseaseName,
            icd11code: prevDiseases?.icd11code,
            snowmedCt: prevDiseases?.snowmedCt,
          };
        } else {
          tempPastIllness = {
            id: 0,
            diseaseName: item.diseaseName,
            icd11code: prevDiseases?.icd11code,
            snowmedCt: prevDiseases?.snowmedCt,
          };
        }

        const temp = {
          illness: tempPastIllness,
          date: item.diagnosisDate
            ? DateUtils.convertToDate(item.diagnosisDate)
            : null,
        };
        return temp;
      }) || [];
    return [...tempDis1, ...tempDis2];
  };

  const handlePreviousProcedure = (pastProcedures) => {
    const tempProc1 =
      extraData?.reqValue?.procedures?.map((item) => {
        const procedureObj = procedureList?.find(
          (procdure) =>
            procdure.procedureName === item.procedureName &&
            procdure.gender === extraData.queuePatientGender
        );
        let tempProcedure = null;
        if (procedureObj) {
          tempProcedure = {
            id: procedureObj.id,
            procedureName: item.procedureName,
            gender: procedureObj.gender,
            locallyAdded: false,
            previouslyAdded: true,
          };
        } else {
          tempProcedure = {
            id: uuidv4(),
            procedureName: item.procedureName,
            gender: "",
            locallyAdded: true,
            previouslyAdded: true,
          };
        }

        const temp = {
          surgery: tempProcedure,
          date: item.diagnosisDate
            ? DateUtils.convertToDate(item.procedureDate)
            : null,
        };
        return temp;
      }) || [];

    const tempProc2 =
      pastProcedures?.map((item) => {
        const procedureObj = procedureList?.find(
          (procdure) =>
            procdure.procedureName === item.procedureName &&
            procdure.gender === extraData.queuePatientGender
        );
        let tempProcedure = null;

        if (procedureObj) {
          tempProcedure = {
            id: procedureObj.id,
            procedureName: item.procedureName,
            gender: procedureObj.gender,
            locallyAdded: false,
          };
        } else {
          tempProcedure = {
            id: uuidv4(),
            procedureName: item.procedureName,
            gender: "",
            locallyAdded: true,
          };
        }

        const temp = {
          surgery: tempProcedure,
          date: item.diagnosisDate
            ? DateUtils.convertToDate(item.procedureDate)
            : null,
        };
        return temp;
      }) || [];

    return [...tempProc1, ...tempProc2];
  };

  const handleRiskFactor = (riskfactors) => {
    let tempRiskFactorList = [];

    tempRiskFactorList = riskfactors.map((item) => {
      const tmpValue = riskFactorList.find((risk) => {
        return (
          risk.procedureName === item.procedureName &&
          risk.gender === extraData.queuePatientGender
        );
      });
      if (tmpValue) {
        return {
          riskFactor: tmpValue,
          since: item.since,
        };
      } else {
        return {
          riskFactor: {
            id: 0,
            riskFactor: item.riskFactorName,
            gender: "C",
          },
          since: item.since,
        };
      }
    });

    return tempRiskFactorList || [];
  };

  const handlePossibleConditions = (possibleConditions) => {
    if (!possibleConditions) {
      return [];
    } else {
      const tempPossCond = possibleConditions?.map((item) => {
        const tmpDisease = diseaseList?.find((disease) => {
          return disease.id === item.diseaseId;
        });

        if (tmpDisease) {
          return {
            ...tmpDisease,
            showInDiagonesis: true,
            status: "Provisional",
          };
        } else {
          return {
            id: item.diseaseId,
            diseaseName: item.diseaseName,
            icd11code: null,
            snowmedCt: null,
            showInDiagonesis: true,
            status: "Provisional",
          };
        }
      });
      return tempPossCond || [];
    }
  };

  const handlePossibleInvestigation = (possibleInvestigation) => {
    if (!possibleInvestigation) {
      return [];
    } else {
      const tempPossCond = possibleInvestigation?.map((item) => {
        const tmpDisease = investigationList?.find((investigation) => {
          return investigation.testId === item.testId;
        });

        if (tmpDisease) {
          return tmpDisease;
        } else {
          return {
            testId: item.testId,
            testName: item.testName,
            location: item.location,
            view: item.view,
            technique: item.technique,
            locationId: 0,
            viewId: 0,
            techinqueId: 0,
          };
        }
      });
      return tempPossCond || [];
    }
  };

  const handleDiseases = (diseases) => {
    if (diseases?.length === 0) {
      return [];
    } else {
      const tempPossCond = diseases?.map((item) => {
        const tmpDisease = diseaseList?.find((disease) => {
          return disease.id === item.diseaseId;
        });

        if (tmpDisease) {
          return {
            diseaseObj: tmpDisease,
            status: item.nature,
            isProvisional: item.nature === "Provisional" ? true : false,
            posIndex: 0,
            showInDiagonesis: true,
            updateData: {
              status: "Provisional",
            },
          };
        } else {
          return {
            diseaseObj: {
              id: item.diseaseId,
              diseaseName: item.diseaseName,
              icd11code: item.icd11,
              snowmedCt: item.snowmedCt,
            },
            status: item.nature,
            isProvisional: item.nature === "Provisional" ? true : false,
            posIndex: 0,
            showInDiagonesis: true,
            updateData: {
              status: "Provisional",
            },
          };
        }
      });
      return tempPossCond;
    }
  };

  const handleMedicineAllergy = (medicineAllergies) => {
    const tempAllergyList1 =
      extraData?.reqValue?.medicineAllergies?.map((item) => {
        let allergicGeneric = {
          id: 0,
          generic: "",
        };
        let brand = null;
        if (item.type.toLowerCase() === "generic") {
          allergicGeneric = {
            id: item.genericBrandId,
            generic: item?.genericBrandName,
          };
        } else if (item.type === "Compound") {
          allergicGeneric = {
            compoundId: item.genericBrandId,
            compoundName: item?.genericBrandName,
          };
        } else {
          const brandId = item?.genericBrandId;
          const brandObj = brandList?.find((item) => item.id === brandId);
          if (brandObj) {
            brand = brandObj;
            const genericObj = genericList?.find(
              (item) => item.id === brandObj.genericId
            );
            if (genericObj) {
              allergicGeneric = genericObj;
            }
          } else {
            brand = {
              brandName: item.genericBrandName,
              id: item.genericBrandId,
              genericId: 0,
              generic: "",
              formId: 0,
            };
          }
        }
        let retValue = {
          genericItem: allergicGeneric,
          brand: brand,
          type:
            item.type.toLowerCase() === "generic"
              ? "GENERIC"
              : item.type === "Compound"
              ? "Compound"
              : "BRAND",
          previouslyAdded: true,
        };
        return retValue;
      }) || [];

    const tempAllergyList2 =
      medicineAllergies?.map((item) => {
        let allergicGeneric = {
          id: 0,
          generic: "",
        };
        let brand = null;
        if (item.type.toLowerCase() === "generic") {
          allergicGeneric = {
            id: item.genericBrandId,
            generic: item?.genericBrandName,
          };
        } else if (item.type === "Compound") {
          allergicGeneric = {
            compoundId: item.genericBrandId,
            compoundName: item?.genericBrandName,
          };
        } else {
          const brandId = item?.genericBrandId;
          const brandObj = brandList?.find((item) => item.id === brandId);
          if (brandObj) {
            brand = brandObj;
            const genericObj = genericList?.find(
              (item) => item.id === brandObj.genericId
            );
            if (genericObj) {
              allergicGeneric = genericObj;
            }
          } else {
            brand = {
              brandName: item.genericBrandName,
              id: item.genericBrandId,
              genericId: 0,
              generic: "",
              formId: 0,
            };
          }
        }
        let retValue = {
          genericItem: allergicGeneric,
          brand: brand,
          type:
            item.type.toLowerCase() === "generic"
              ? "GENERIC"
              : item.type === "Compound"
              ? "Compound"
              : "BRAND",
        };
        return retValue;
      }) || [];

    return [...tempAllergyList1, ...tempAllergyList2];
  };

  const handleHeriditoryDiseases = (hereditaryDiseases) => {
    let tempUserDiseaseList1 =
      extraData?.reqValue?.hereditaryDiseases?.map((item) => {
        const { diseaseName } = item;
        const diseaseObj = heriditoryDiseaseList.find(
          (disease) => disease.diseaseName === diseaseName
        );
        let temp = null;
        if (diseaseObj) {
          temp = {
            disease: diseaseObj,
            relationShip: item.relationship,
            previouslyAdded: true,
          };
        } else {
          temp = {
            disease: {
              id: uuidv4(),
              diseaseName: diseaseName,
              locallyAdded: true,
            },
            relationShip: item.relationship,
            previouslyAdded: true,
          };
        }
        return temp;
      }) || [];

    let tempUserDiseaseList2 =
      hereditaryDiseases?.map((item) => {
        const { diseaseName } = item;
        const diseaseObj = heriditoryDiseaseList.find(
          (disease) => disease.diseaseName === diseaseName
        );
        let temp = null;
        if (diseaseObj) {
          temp = {
            disease: diseaseObj,
            relationShip: item.relationship,
          };
        } else {
          temp = {
            disease: {
              id: uuidv4(),
              diseaseName: diseaseName,
              locallyAdded: true,
            },
            relationShip: item.relationship,
          };
        }
        return temp;
      }) || [];

    return [...tempUserDiseaseList1, ...tempUserDiseaseList2];
  };

  const handleMedicines = (medicines) => {
    console.log("handleMedicines", medicines);
    // ;
    const tempMedicine =
      medicines?.map((item) => {
        let genericItem = null;
        let brandItem = null;
        let routeItem = null;
        let dosageItem = null;
        let timingItem = null;
        let frequencyItem = null;
        let formId = null;
        let durationItem = null;
        if (item.genericId) {
          genericItem = genericList?.find((gen) => gen.id === item.genericId);
          formId = genericItem?.formId;
        }
        console.log("handleMedicines brand list =", brandList);
        if (item.brandId) {
          brandItem = brandList?.find((brand) => brand.id === item.brandId);
        }

        if (item.route) {
          routeItem = routeList?.find((route) => route.route === item.route);
        }

        if (formId && item.dosages) {
          dosageItem = dosageList?.find(
            (dose) => dose.formId == formId && dose.dosage == item.dosages
          );
        }

        if (item.frequency) {
          frequencyItem = frequencyList?.find(
            (frequency) => frequency.frequency == item.frequency
          );
        }

        if (item.timing) {
          timingItem = item?.timing?.split(",");
        }
        if (item.durationLabel) {
          durationItem = durationList?.find(
            (duration) => duration.durationName == item.durationLabel
          );
        }

        return {
          frequency: frequencyItem,
          dosage: dosageItem,
          root: routeItem,
          note: item.note,
          duration: durationItem,
          timing: timingItem,
          genericName: genericItem,
          brandName: brandItem,
        };
      }) || [];

    console.log("handleMedicines tempMed", tempMedicine);

    return tempMedicine;
  };

  const handleDiet = (diets) => {
    let tempDietList =
      diets?.map((item) => {
        let tmp = dietList?.find((diet) => diet.diet === item.dietName) || {};
        return tmp;
      }) || [];
    return tempDietList;
  };

  const handleLifeStyle = (lifeStyles) => {
    let tempLifestyleList =
      lifeStyles?.map((item) => {
        let tmp =
          lifeStyleList?.find(
            (lifeStyle) => lifeStyle.lifeStyle === item.lifeStyleName
          ) || {};
        return tmp;
      }) || [];
    return tempLifestyleList || [];
  };

  const handleProcedureList = (procedures) => {
    let tempLifestyleList =
      procedures?.map((item) => {
        let tmp =
          procedureList?.find(
            (procedure) => procedure.id === item.procedureId
          ) || {};

        if (tmp) {
          return {
            procedure: tmp,
            date: item.planDate ? DateUtils.convertToDate(item.planDate) : null,
          };
        } else {
          return {
            procedure: {
              id: 0,
              procedureName: item.procedureName,
              gender: "Common",
            },
            date: item.planDate ? DateUtils.convertToDate(item.planDate) : null,
          };
        }
      }) || [];
    return tempLifestyleList || [];
  };

  const getReferStringFromType = (refer) => {
    switch (refer) {
      case "Advise higher facility":
        return "higherFacility";

      case "Advise immediate hospitalization":
        return "immediateHospitalization";

      case "Refer to other speciality":
        return "otherSpeciality";

      case "Refer to medico legal":
        return "medicoLegal";

      default:
        return null;
    }
  };

  const handleTempHospital = (refferedToHospital) => {
    if (!refferedToHospital) {
      return null;
    }
    const tempHospital = hospitalList?.find(
      (hospital) => hospital.hospitalName == refferedToHospital
    );
    return tempHospital || null;
  };

  const handleSpecialist = (refferedToSpecialist) => {
    if (!refferedToSpecialist) {
      return null;
    }
    const tempSpecialization = specilizationList?.find(
      (specialization) =>
        specialization.specializationName == refferedToSpecialist
    );
    return tempSpecialization || null;
  };

  useEffect(() => {
    if (logData && isDataReady) {
      const {
        symptoms,
        signs,
        phyExams,
        comorbidities,
        foodAllergies,
        pastDieseases,
        pastProcedures,
        riskfactors,
        possibleConditions,
        possibleInvestigations,
        dieseases,
        medicineAllergies,
        hereditaryDiseases,
        medicines,
        diets,
        lifeStyles,
        paramsToMonitor,
        procedures,
        doctorNote,
        referralNote,
        refferedToHospital,
        refferedToSpecialist,
        refferedToDoctor,
      } = logData;
      let tempSymptom = handleSymptoms(symptoms);
      let tempSigns = handleSigns(signs);
      let tempPhysicalExamList = handlePhysicalExamList(phyExams);
      let tempComorbidityList = handleComorbidityList(comorbidities);
      let tempFoodAllergies = handleFoodAllergies(foodAllergies);

      let tempPreviousIllness = handlePreviousIllness(pastDieseases);
      let tempPrevProcedures = handlePreviousProcedure(pastProcedures);
      let tempRiskFactor = handleRiskFactor(riskfactors);
      let tempPossibleCondition = handlePossibleConditions(possibleConditions);
      let tempPossibleInvestigation = handlePossibleInvestigation(
        possibleInvestigations
      );
      let tempDiseases = handleDiseases(dieseases);
      let tempMedicineAllergy = handleMedicineAllergy(medicineAllergies);
      let tempHereditaryDiseases = handleHeriditoryDiseases(hereditaryDiseases);
      let tempMedicines = handleMedicines(medicines);
      let tempDietList = handleDiet(diets);
      let tempLifeStyles = handleLifeStyle(lifeStyles);
      let tempProcedures = handleProcedureList(procedures);
      let tempHospital = handleTempHospital(refferedToHospital);
      let tempReferedToSpecialist = handleSpecialist(refferedToSpecialist);

      const tmpObj = {
        symptoms: tempSymptom,
        singObj: tempSigns,
        physicalExamList: tempPhysicalExamList,
        comorbidity: tempComorbidityList,
        allergicFood: tempFoodAllergies,
        pastIllness: tempPreviousIllness,
        prevSurgery: tempPrevProcedures,
        riskFactor: tempRiskFactor,
        possibleDiagonesis: tempPossibleCondition,
        investigation: tempPossibleInvestigation,
        diagonesis: tempDiseases,
        allergicDrug: tempMedicineAllergy,
        familyHistory: tempHereditaryDiseases,
        medication: tempMedicines,
        diet: tempDietList,
        lifeStyle: tempLifeStyles,
        itemToMonitor: paramsToMonitor,
        procedure: tempProcedures,
        doctorNote: doctorNote,
        referType: getReferStringFromType(referralNote),
        hospital: tempHospital,
        referDoctorSpeciality: tempReferedToSpecialist,
        doctorName: refferedToDoctor || "",
      };

      setPrescriptionData({ ...tmpObj });
    }
  }, [extraData, logData, isDataReady]);

  return prescriptionData;
};
