import { Add, Close } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Bookmark, Plus, XLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import DashboardServices from "../../../../../services/DashboardServices";
import zhPatientService from "../../../../../services/ZhPatientService";
import SecureIndexedDB from "../../../../../utils/IndexedDB";
import { GetLoginUserDetails, isLocalHost } from "../../../../../utils/Utils";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import AddCompound from "./AddCompound";
import { CompoundComponent } from "./CompoundComponent";
import ListAgeGroupModel from "./ListAgeGroupModel";

export default function EditStp({ handelCloseEditStp, editStpData }) {
  const loginUserDetails = GetLoginUserDetails();
  const { t } = useTranslation();
  const [invAplyGender, setInvAplyGenders] = useState("");
  const [selectInvestigation, setSelectInvestigation] = useState("");
  const [listAgeGroup, setListAgeGroup] = useState(false);

  const [selectAgeGroup, setSelectAgeGroup] = useState("");

  const [selectParameter, setSelectParameter] = useState("");
  const [selectLifestyle, setSelectLifestyle] = useState("");
  const [selectProcedure, setSelectProcedure] = useState("");
  const [diseaseList, setDiseaseList] = useState([]);
  const [investigationList, setInvestigationList] = useState([]);
  const [userDiseaseList, setUserDiseaseList] = useState([]);
  const [userInvestigationList, setUserInvestigationList] = useState([]);

  const [selectedInvestigation, setSelectedInvestigation] = useState(null);
  const [investigationSearchString, setInvestigtionSearchString] = useState("");

  const [selectedDisease, setSelectedDisease] = useState(null);
  const [diseaseInputString, setDiseaseInputString] = useState("");

  const [selectedLifeStyle, setSelectedLifeStyle] = useState(null);
  const [lifeStyleString, setLifeStyleString] = useState("");
  const [lifeStyleList, setLifeStyleList] = useState([]);
  const [recommandedLifeStyle, setRecomandedLifeStyle] = useState([]);

  const [monitorNames, setMonitorNames] = useState([]);
  const [parameterMonitor, setParameterMonitor] = useState([]);
  const [ageGroupList, setAgeGroupList] = useState(
    //   [
    //   { name: "For All", value: "" },
    //   { name: "New Born", value: "upto 3 month" },
    //   { name: "Infent", value: "3 - 12 months" },
    //   { name: "Toddler", value: "1 - 5 Years old" },
    //   { name: "Child", value: "6 - 12 Years old" },
    //   { name: "Teen", value: "13 - 19 Years old" },
    //   { name: "Adult", value: "20 - 39 Years old" },
    //   { name: "Mid Adult", value: "40 - 59 Years old" },
    //   { name: "Senior Adult", value: "60+ Years old" },
    // ]
    []
  );
  const [regime, setRegime] = useState("");
  const [medicationList, setMedicationList] = useState([]);
  const [showAddRegime, setShowAddRegime] = useState(false);
  const [currentStpIndex, setCurrentIndex] = useState(1);
  const [compoundList, setCompoundList] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [procedureString, setProcedureString] = useState("");
  const [procedureList, setProcedureList] = useState([]);
  const [selectedProcedureList, setSelectedProcedureList] = useState([]);
  const [procedureGender, setProcedureGender] = useState("");
  const [selectedParameter, setSelectedParameter] = useState(null);
  const [parameterString, setParameterString] = useState("");
  const [stpData, setSptData] = useState(null);
  const [regimeGender, setRegimeGender] = useState("C");
  const [instruction, setInstruction] = useState("");

  const handleCloseListAgeGroupModel = () => {
    setListAgeGroup(false);
  };

  const suggestedParameterList = useMemo(() => {
    const filteredNames = monitorNames?.filter((item) => {
      const alreadyTaken = parameterMonitor.some((param) => param === item);
      return !alreadyTaken;
    });
    return filteredNames;
  }, [parameterMonitor, monitorNames]);

  const suggestedLifeStyleList = useMemo(() => {
    const filteredNames = lifeStyleList?.filter((item) => {
      const alreadyTaken = recommandedLifeStyle.some(
        (lifestyle) => lifestyle === item
      );
      return !alreadyTaken;
    });
    return filteredNames;
  }, [recommandedLifeStyle, lifeStyleList]);

  //  const [isDoseDippentOnAge, setIsDoseDippentOnAge] = useState(false);

  // const handleDoseDippentOnAge = (event) => {
  //   setIsDoseDippentOnAge(event.target.checked);
  // };
  // const handleChangeUpperAgeLimit = (event) => {
  //   const { name, value } = event.target;
  //   setUpperAgeLimit((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  const initializeInvestigation = () => {
    setSelectedInvestigation(null);
    setInvestigtionSearchString("");
    setInvAplyGenders("");
  };

  const initializeLifeStyle = () => {
    setLifeStyleString("");
    setSelectedLifeStyle(null);
  };

  const getDiseases = async () => {
    const diseasetring = await SecureIndexedDB.getItem("diseaseList");
    if (diseasetring) {
      setDiseaseList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getDiseases(reqDto).then((response) => {
      if (response.data) {
        setDiseaseList(response.data);
        SecureIndexedDB.setItem("diseaseList", JSON.stringify(response.data));
      }
    });
  };
  const getLifeStyles = async () => {
    const lifeStyleString = await SecureIndexedDB.getItem("lifeStyleList");
    if (lifeStyleString) {
      setLifeStyleList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = await getHealthDto();
    DashboardServices.getLifeStyles(reqDto).then((response) => {
      if (response.data) {
        setLifeStyleList(response.data);
        SecureIndexedDB.setItem("lifeStyleList", JSON.stringify(response.data));
      }
    });
  };

  const computeMonitors = () => {
    let investigationList = [];
    let vitalsList = [];
    if (userInvestigationList) {
      investigationList = userInvestigationList.map(
        (item) => item?.investigation?.testName
      );
    }
    const vitalNameMaps = {
      TEMPERATURE: "Temperature",
      BP: "Blood Pressure",
      HEART_RATE: "Heart Rate",
      RESPIRATION_RATE: "Respiration Rate",
      SPO2: "SPO2",
    };
    const vitalNames = [
      "TEMPERATURE",
      "BP",
      "HEART_RATE",
      "RESPIRATION_RATE",
      "SPO2",
    ];
    vitalsList = vitalNames.map((item) => vitalNameMaps[item]);

    setMonitorNames([...vitalsList, ...investigationList]);
  };

  const getProcedures = async () => {
    const lifeStyleString = await SecureIndexedDB.getItem("procedureList");
    if (lifeStyleString) {
      setProcedureList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = await getHealthDto();
    DashboardServices.getProcedures(reqDto).then((response) => {
      if (response.data) {
        setProcedureList(response.data);
        SecureIndexedDB.setItem("procedureList", JSON.stringify(response.data));
      }
    });
  };

  const updateCompound = (data) => {
    const { type, payload } = data;
    switch (type) {
      case "addAnd":
        addAndComponent(medicationList, payload.parentId, payload.data);
        break;

      case "changeCompoundType":
        handleOrThenRadioButton(medicationList, payload.itemId, payload.data);
        break;
      case "addNewCompound":
        handleOpenAddNewCompound(
          medicationList,
          payload.parentId,
          payload.data
        );
        break;
      case "deleteCompound":
        deleteCompound(medicationList, payload.parentId, payload.itemId);
        break;

      case "addThen":
        addThenCompound(
          medicationList,
          payload.itemId,
          payload.prevIndex,
          payload.data
        );
        break;
      case "addOr":
        addOrCompound(
          medicationList,
          payload.itemId,
          payload.prevIndex,
          payload.data
        );
        break;
      case "addInnerAnd":
        addAndCompound(
          medicationList,
          payload.itemId,
          payload.prevIndex,
          payload.data
        );
        break;
      case "updateItemdData":
        handleChangeItemData(medicationList, payload.itemId, payload.data);
        break;
      default:
        break;
    }
  };

  const handleChangeItemData = (medList, itemId, data) => {
    const recursiveHandler = (item, itemId, data) => {
      const temp = { ...item };
      if (temp.localId === itemId) {
        data?.forEach((fieldObj) => {
          const { field, value } = fieldObj;
          temp[field] = value;
        });
        // temp.compoundOrThen = false;
      } else {
        temp.compoundOrThen = "";
        const { thenList, orList, andList } = temp;
        const thList = thenList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, data);
          return tmp;
        });

        const orlst = orList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, data);
          return tmp;
        });
        const andLst = andList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, data);
          return tmp;
        });

        temp.thenList = thList;
        temp.orList = orlst;
        temp.andList = andLst;
      }
      return temp;
    };
    // const handlechangeItem = () => {};
    const medlst = medList?.map((element, index) => {
      const { compound } = element;
      const elmnt = { ...element };
      const cmpnd = compound?.map((item, idx) => {
        // if (item.localId === itemId) {
        //   item.compoundOrThen = data;
        // }
        const value = recursiveHandler(item, itemId, data);
        return value;
      });
      elmnt.compound = cmpnd;
      return elmnt;
    });
    setMedicationList(medlst);
  };

  const addAndCompound = (medList, itemId, prevIndex, data) => {
    const recursiveHandler = (item, itemId, prevIndex, data) => {
      const temp = { ...item };
      if (temp.localId === itemId) {
        const tempData = {
          ...data,
          index: currentStpIndex,
          prevIndex: prevIndex,
        };
        setCurrentIndex((prev) => prev + 1);
        if (temp.andList) {
          temp.andList.push(tempData);
        } else {
          temp.andList = [tempData];
        }
        temp.compoundOrThen = "";
      } else {
        temp.compoundOrThen = "";
        const { thenList, orList, andList } = temp;
        const thList = thenList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, prevIndex, data);
          return tmp;
        });
        const orlst = orList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, prevIndex, data);
          return tmp;
        });
        const andLst = andList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, prevIndex, data);
          return tmp;
        });
        temp.thenList = thList;
        temp.orList = orlst;
        temp.andList = andLst;
      }
      return temp;
    };

    const medLst = medList?.map((element, index) => {
      const { compound } = element;
      const temp = { ...element };
      const comp = compound?.map((item, idx) => {
        const temp = { ...item };
        if (item.localId === itemId) {
          const tempData = {
            ...data,
            index: currentStpIndex,
            prevIndex: prevIndex,
          };
          setCurrentIndex((prev) => prev + 1);
          if (temp.andList) {
            temp.andList?.push(tempData);
          } else {
            temp.andList = [tempData];
          }
          temp.compoundOrThen = "";
        } else {
          temp.compoundOrThen = "";
          const value = recursiveHandler(item, itemId, prevIndex, data);
          return value;
        }
        return temp;
      });
      temp.compound = comp;
      return temp;
    });
    setMedicationList(medLst);
  };

  useEffect(() => {
    computeMonitors();
  }, [userInvestigationList]);

  const getMedicalTestList = async () => {
    const symptomString = await SecureIndexedDB.getItem("medicalTestList");
    if (symptomString) {
      setInvestigationList(JSON.parse(symptomString));
      return;
    }

    const reqDto = await getHealthDto();

    DashboardServices.getMedicalTestList(reqDto).then((response) => {
      if (response.data) {
        setInvestigationList(response.data);
        SecureIndexedDB.setItem(
          "medicalTestList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const addAndComponent = (medList, parentId, data) => {
    medList?.forEach((element, index) => {
      if (element.localId === parentId) {
        const temp = [...medList];
        temp[index].compound.push(data);
        temp[index].addNewCompound = false;
        setMedicationList(temp);
      }
    });
  };

  const deleteCompound = (medList, parentId, itemId) => {
    const recursiveHandler = (item, itemId) => {
      const temp = { ...item };
      if (temp.localId === itemId) {
        return temp?.orList || [];
      } else {
        temp.compoundOrThen = "";
        const { thenList, orList, andList } = temp;
        const thList = thenList
          ?.map((item) => {
            const tmp = recursiveHandler(item, itemId);
            return tmp;
          })
          ?.flat();
        const orlst = orList
          ?.map((item) => {
            const tmp = recursiveHandler(item, itemId);
            return tmp;
          })
          ?.flat();

        const andlst = andList
          ?.map((item) => {
            const tmp = recursiveHandler(item, itemId);
            return tmp;
          })
          ?.flat();
        temp.thenList = thList;
        temp.orList = orlst;
        temp.andList = andlst;
      }
      return temp;
    };

    medList?.forEach((element, index) => {
      const { compound } = element;

      let temp = [...medList];

      if (element.localId === parentId) {
        const itemIndex = temp?.[index]?.compound?.findIndex((item) => {
          return item.localId === itemId;
        });
        if (itemIndex >= 0) {
          if (temp?.[index]?.compound?.[itemIndex]?.orList?.length > 0) {
            temp[index].compound =
              temp[index].compound?.[itemIndex]?.orList?.flat() || [];
          } else {
            temp?.[index]?.compound?.splice(itemIndex, 1);
          }
        }
        // setMedicationList(temp);
      } else {
        const compountList = compound
          ?.map((item) => {
            const hanlerValue = recursiveHandler(item, itemId);
            if (hanlerValue?.length === 0) {
              return null;
            }
            return hanlerValue;
          })
          ?.filter((item) => item);
        temp[index].compound = compountList.filter((item) => item !== null);
      }

      temp = temp.filter((item) =>
        item?.compound?.length > 0 || item.instruction ? true : false
      );

      setMedicationList(temp);
    });
  };

  const addThenCompound = (medList, itemId, prevIndex, data) => {
    const recursiveHandler = (item, itemId, prevIndex, data) => {
      const temp = { ...item };
      if (temp.localId === itemId) {
        const tempData = {
          ...data,
          index: currentStpIndex,
          prevIndex: prevIndex,
        };
        setCurrentIndex((prev) => prev + 1);
        if (temp.thenList) {
          temp.thenList.push(tempData);
        } else {
          temp.thenList = [tempData];
        }
        temp.compoundOrThen = "";
      } else {
        temp.compoundOrThen = "";
        const { thenList, orList, andList } = temp;
        const thList = thenList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, prevIndex, data);
          return tmp;
        });

        const orlst = orList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, prevIndex, data);
          return tmp;
        });

        const andLst = andList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, prevIndex, data);
          return tmp;
        });

        temp.thenList = thList;
        temp.orList = orlst;
        temp.andList = andLst;
      }
      return temp;
    };

    const medLst = medList?.map((element, index) => {
      const { compound } = element;
      const temp = { ...element };
      const comp = compound?.map((item, idx) => {
        const temp = { ...item };
        if (item.localId === itemId) {
          const tempData = {
            ...data,
            index: currentStpIndex,
            prevIndex: prevIndex,
          };
          setCurrentIndex((prev) => prev + 1);
          if (temp.thenList) {
            temp.thenList?.push(tempData);
          } else {
            temp.thenList = [tempData];
          }
          temp.compoundOrThen = "";
        } else {
          temp.compoundOrThen = "";
          const value = recursiveHandler(item, itemId, prevIndex, data);
          return value;
        }
        return temp;
      });
      temp.compound = comp;
      return temp;
    });
    setMedicationList(medLst);
  };

  const addOrCompound = (medList, itemId, prevIndex, data) => {
    const recursiveHandler = (item, itemId, prevIndex, data) => {
      const temp = { ...item };
      if (temp.localId === itemId) {
        const tempData = {
          ...data,
          index: currentStpIndex,
          prevIndex: prevIndex,
        };
        setCurrentIndex((prev) => prev + 1);
        if (temp.orList) {
          temp.orList.push(tempData);
        } else {
          temp.orList = [tempData];
        }
        temp.compoundOrThen = "";
      } else {
        temp.compoundOrThen = "";
        const { thenList, orList, andList } = temp;
        const thList = thenList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, prevIndex, data);
          return tmp;
        });
        const orlst = orList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, prevIndex, data);
          return tmp;
        });

        const andLst = andList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, prevIndex, data);
          return tmp;
        });
        temp.thenList = thList;
        temp.orList = orlst;
        temp.andList = andLst;
      }
      return temp;
    };

    const medLst = medList?.map((element, index) => {
      const { compound } = element;
      const temp = { ...element };
      const comp = compound?.map((item, idx) => {
        const temp = { ...item };
        if (item.localId === itemId) {
          const tempData = {
            ...data,
            index: currentStpIndex,
            prevIndex: prevIndex,
          };
          setCurrentIndex((prev) => prev + 1);
          if (temp.orList) {
            temp.orList?.push(tempData);
          } else {
            temp.orList = [tempData];
          }
          temp.compoundOrThen = "";
        } else {
          temp.compoundOrThen = "";
          const value = recursiveHandler(item, itemId, prevIndex, data);
          return value;
        }
        return temp;
      });
      temp.compound = comp;
      return temp;
    });
    setMedicationList(medLst);
  };

  const handleOrThenRadioButton = (medList, itemId, data) => {
    const recursiveHandler = (item, itemId, data) => {
      const temp = { ...item };
      if (temp.localId === itemId) {
        temp.compoundOrThen = data;
        // temp.compoundOrThen = false;
      } else {
        temp.compoundOrThen = "";
        const { thenList, orList, andList } = temp;
        const thList = thenList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, data);
          return tmp;
        });

        const orlst = orList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, data);
          return tmp;
        });

        const andLst = andList?.map((item) => {
          const tmp = recursiveHandler(item, itemId, data);
          return tmp;
        });

        temp.thenList = thList;
        temp.orList = orlst;
        temp.andList = andLst;
      }
      return temp;
    };
    // const handlechangeItem = () => {};
    const medlst = medList?.map((element, index) => {
      const { compound } = element;
      const elmnt = { ...element };
      const cmpnd = compound?.map((item, idx) => {
        // if (item.localId === itemId) {
        //   item.compoundOrThen = data;
        // }
        const value = recursiveHandler(item, itemId, data);
        return value;
      });
      elmnt.compound = cmpnd;
      return elmnt;
    });
    setMedicationList(medlst);
  };

  const handleOpenAddNewCompound = (medList, parentId, data) => {
    medList?.forEach((element, index) => {
      if (element.localId === parentId) {
        const temp = [...medList];
        temp[index].addNewCompound = data;
        setMedicationList(temp);
      }
    });
  };

  const suggestedProcedure = useMemo(() => {
    const filteredList = getFilteredList(
      procedureList,
      procedureString,
      "procedureName"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [procedureList, procedureString]);

  // const suggestedInvestigation = useMemo(() => {
  //   const userIdInvestigationList = userInvestigationList.map(
  //     (item) => item.testId
  //   );

  //   let filteredInvestigation = processedTestList.filter((disease) => {
  //     const alreadyTaken = userIdInvestigationList.some(
  //       (item) => item === disease.testId
  //     );
  //     return !alreadyTaken;
  //   });

  //   const filteredList = getFilteredList(
  //     filteredInvestigation,
  //     investigationSearchString,
  //     "testName"
  //   );
  //   return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  // }, [investigationSearchString, processedTestList, userInvestigationList]);

  const handleChangselectAgeGroup = (event) => {
    setSelectAgeGroup(event.target.value);
  };

  const handleChangeinvAplyGender = (event) => {
    setInvAplyGenders(event.target.value);
  };
  const handleChangeProcedureGender = (event) => {
    setProcedureGender(event.target.value);
  };

  function buildTree(data) {
    const map = {}; // A map to store nodes by indexId
    const tree = []; // Array to hold the root nodes
    if (data.length === 1 && data[0].compoundId === 0) {
      return tree;
    }
    // Initialize nodes and store in map
    data.forEach((item) => {
      const compoundItem = compoundList.find(
        (cmpnd) => cmpnd.compoundId === item.compoundId
      );

      const form = {
        formId: item.formId,
        formName: item.formName,
      };

      const tmpValues = {
        route: {
          id: item.routeId,
          route: item.routeName,
        },
        dose: item.dosages,
        dosagesUnit: item.dosagesUnit,

        doseUnit: item.dosagesUnit,
        doseLimit: item.dosagesLimit,

        duration: {
          id: item.durationId,
          durationName: item.duration,
          days: item.duration,
        },
        frequency: {
          id: item.frequencyId,
          frequency: item.frequency,
          fixedDurationFlag: "Y",
        },
        weightMeasured: false,
        index: item.indexId,
        prevIndex: item.previousIndexId,
        operator: item.operator,
      };

      const tempNode = {
        localId: uuidv4(),
        compound: compoundItem,
        form,
        ...tmpValues,
        note: item.notes,
      };

      map[item.indexId] = { ...tempNode };
    });

    // const selectedCompound=compoundList?.find(item=>item.)

    // Construct tree structure
    data.forEach((item) => {
      const node = map[item.indexId];
      if (item.previousIndexId === 0) {
        // No parent, so it is a root node
        // tree.push(tempNode);
        tree.push(node);
      } else {
        // Find the parent node using previousIndexId
        const parent = map[item.previousIndexId];
        if (parent) {
          if (node.operator === "Then") {
            if (parent.thenList) {
              // parent.thenList.push(tempNode);
              parent.thenList.push(node);
            } else {
              // parent.thenList = [tempNode];
              parent.thenList = [node];
            }
          } else if (node.operator === "Or") {
            if (parent.orList) {
              // parent.orList.push(tempNode);
              parent.orList.push(node);
            } else {
              // parent.orList = [tempNode];
              parent.orList = [node];
            }
          } else if (node.operator === "Along With") {
            if (parent.andList) {
              // parent.andList.push(tempNode);
              parent.andList.push(node);
            } else {
              // parent.andList = [tempNode];
              parent.andList = [node];
            }
          }
        }
      }
    });

    return tree;
  }

  const handleCompoundData = (data) => {
    const availableRegimes = {};
    let tempMaxIndex = 0;
    data.forEach((item) => {
      const { regimeName, ageGroup, indexId } = item;
      const key = `${regimeName}~${ageGroup}`;
      if (availableRegimes[key]) {
        availableRegimes[key].push(item);
      } else {
        availableRegimes[key] = [item];
      }
      if (tempMaxIndex < indexId) {
        tempMaxIndex = indexId;
      }
    });
    setCurrentIndex(tempMaxIndex + 1);

    const bushes = {};
    let tmpMedList = [];
    const keys = Object.keys(availableRegimes);
    keys?.forEach((item) => {
      const compoundData = availableRegimes[item];
      const { regimeName, ageGroup, instruction, gender } = compoundData?.[0];

      const tree = buildTree(availableRegimes[item]);
      bushes[item] = tree;
      const dto = {
        regime: regimeName,
        ageGroup: ageGroup,
        compound: [...tree],
        instruction,
        gender,
        addNewCompound: false,
      };
      tmpMedList.push(dto);
    });
    setMedicationList(tmpMedList);
  };

  useEffect(() => {
    const { diseaseName } = editStpData;
    const diseaseSlices = diseaseName.split(" (");
    const tempDiseaseName = diseaseSlices[0];
    if (diseaseList) {
      const selectedDisease = diseaseList?.find(
        (item) => item.diseaseName === tempDiseaseName
      );

      // const selectedDisease = diseaseList?.find(
      //   (item) => item.id === stpData.diseaseId
      // );

      setSelectedDisease(selectedDisease);
    }
  }, [diseaseList]);

  useEffect(() => {
    if (investigationList?.length > 0 && stpData) {
      const invList = stpData?.tests?.map((test) => {
        const testItem = investigationList?.find(
          (item) => item.testId === test.testId
        );
        if (testItem) {
          return {
            investigation: testItem,
            gender: test.gender,
          };
        } else {
          toast.error(
            t("could_not_find_investgation_of_test_id") + test.testId
          );
          return null;
        }
      });
      setUserInvestigationList(invList);
    }
  }, [investigationList, stpData]);

  useEffect(() => {
    if (procedureList && stpData) {
      const tempProcedure = stpData?.procedures?.map((procedure) => {
        const tempProcedure = procedureList.find(
          (item) => item.id === procedure.procedureId
        );

        const dto = {
          procedure: tempProcedure,
          gender: procedure.gender,
        };
        return dto;
      });

      setSelectedProcedureList(tempProcedure);
    }
  }, [procedureList, stpData]);

  const getGenderName = (genderCode) => {
    if (genderCode === "C") return "Common";
    else if (genderCode === "M") return "Male";
    else if (genderCode === "F") return "Female";
    else return "";
  };

  useEffect(() => {
    if (stpData && compoundList) {
      const { compounds } = stpData;
      if (compoundList) {
        handleCompoundData(compounds);
      }
    }
  }, [compoundList, stpData]);

  useEffect(() => {
    getDiseases();
    getMedicalTestList();
    getProcedures();
    getLifeStyles();
    const reqDto = {
      orgId: loginUserDetails.orgId,
      countryCode: loginUserDetails.orgCountryCode,
    };
    zhPatientService.getAgeForSTP(reqDto).then((response) => {
      setAgeGroupList(response.data);
      setSelectAgeGroup(response.data?.[0]);
    });
    zhPatientService.getCompounds(loginUserDetails.orgId).then((response) => {
      if (response.data) {
        setCompoundList(response.data);
      }
    });

    zhPatientService.getStpbyId(editStpData?.stpId).then((response) => {
      if (response.data) {
        setSptData(response.data);
        const { lifeStyles, parameters, compounds } = response.data;
        setRecomandedLifeStyle(lifeStyles);
        setParameterMonitor(parameters);
        handleCompoundData(compounds);
      }
    });
  }, []);

  // const suggestedLifeStyleList = useMemo(() => {
  //   const userLifeStyleIdList = recommandedLifeStyle.map((item) => item.id);

  //   let filteredDietList = lifeStyleList.filter((lifeStyle) => {
  //     const alreadyTaken = userLifeStyleIdList.some(
  //       (item) => item === lifeStyle.id
  //     );
  //     return !alreadyTaken;
  //   });

  //   const filteredList = getFilteredList(
  //     filteredDietList,
  //     lifeStyleString,
  //     "lifeStyle"
  //   );
  //   return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  // }, [lifeStyleString, lifeStyleList, recommandedLifeStyle]);

  const suggestedDiseaseList = useMemo(() => {
    const userDiseaseIdList = userDiseaseList.map((item) => item.diseaseObj.id);

    const filteredDiseaseList = diseaseList.filter((disease) => {
      const alreadyTaken = userDiseaseIdList.some(
        (item) => item === disease.id
      );
      return !alreadyTaken;
    });

    const filteredList = getFilteredList(
      filteredDiseaseList,
      diseaseInputString,
      "diseaseName"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [diseaseInputString, diseaseList, userDiseaseList]);

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">{t("edit_stp")}</div>
          <IconButton className="CloseBtn" onClick={() => handelCloseEditStp()}>
            <XLg />
          </IconButton>
        </div>

        <div className="elementFormContainer gap10">
          <div className="tskElemHeding mb-8">{t("disease_with_icd11")}</div>
          <div className="formElement ">
            <FormControl className="formControl">
              {/* <InputLabel id="demo-simple-select-label">
                Select Disease
              </InputLabel> */}
              {/* <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectDiseas}
                label="Select Disease"
                onChange={handleChangeSelectDiseas}
                className="formInputField"
                variant="outlined"
              >
                <MenuItem value="d1">Disease one</MenuItem>
                <MenuItem value="d2">Disease two</MenuItem>
              </Select> */}

              <Autocomplete
                freeSolo
                className="formAutocompleteField"
                variant="outlined"
                value={selectedDisease}
                options={suggestedDiseaseList}
                // inputValue={diseaseInputString}
                onChange={(e, newValue) => {
                  setSelectedDisease(newValue);
                }}
                // onInputChange={(e, value, reason) => {
                //   // if (e && e.target) {
                //   //   setDiseaseInputString(e.target.value);
                //   // }
                //   setDiseaseInputString(value);
                //   // else {
                //   //   setSymptomSearchString("");
                //   // }
                // }}
                getOptionLabel={(option) =>
                  `${option.diseaseName} ${
                    option.icd11code ? `(${option.icd11code})` : ""
                  }`
                }
                renderOption={(props, item) => {
                  return (
                    <li {...props} key={item.id}>
                      {`${item.diseaseName} ${
                        item.icd11code ? `(${item.icd11code})` : ""
                      }`}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="formAutoComInputField autocompFildPlaceholder"
                    label={t("disease_name")}
                    placeholder={t("please_select_disease")}
                    // required
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </FormControl>
          </div>

          {/* start Investigation */}
          <div className="tskElemHeding mb-8">{t("investigation")}</div>
          <div className="formElementGrp mb-8">
            <div className="formElement">
              <FormControl className="formControl">
                {/* <InputLabel id="selectInv">Investigation</InputLabel> */}
                {/* <Select
                  required
                  labelId="selectInv"
                  id="select_Inv"
                  value={selectInvestigation}
                  label="Investigation"
                  onChange={handleChangselectInvestigation}
                  className="formInputField"
                  variant="outlined"
                >
                  <MenuItem value="inv1">Investigation one</MenuItem>
                  <MenuItem value="inv2">Investigation two</MenuItem>
                </Select> */}

                <Autocomplete
                  // freeSolo
                  className="formAutocompleteField"
                  variant="outlined"
                  value={selectedInvestigation}
                  options={investigationList}
                  inputValue={investigationSearchString}
                  onChange={(e, newValue) => {
                    setSelectedInvestigation(newValue);
                    if (newValue) {
                      const { gender } = newValue;
                      setInvAplyGenders(gender);
                    }
                  }}
                  onInputChange={(e, value, reason) => {
                    setInvestigtionSearchString(value);
                  }}
                  getOptionLabel={(option) => option.testName}
                  renderOption={(props, item) => {
                    return (
                      <li
                        {...props}
                        key={item.id}
                        // className={
                        //   item.isAvailableInCenter ? "available" : ""
                        // }
                      >
                        {item.testName}
                        {/* {`(${item.gender})`} */}
                        {item.isAvailableInCenter && (
                          <Bookmark className="centAvlTest" />
                        )}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder={t("search_investigations")}
                      label={t("investigation")}
                      // required
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
            <div className="formElement mxW-100">
              <FormControl className="formControl">
                <InputLabel id="demo-simple-select-label">
                  {t("gender")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={invAplyGender}
                  label="Gender"
                  onChange={handleChangeinvAplyGender}
                  className="formInputField"
                  variant="outlined"
                >
                  <MenuItem value="M">{t("male")}</MenuItem>
                  <MenuItem value="F">{t("female")}</MenuItem>
                  <MenuItem value="C">{t("common")}</MenuItem>
                </Select>
              </FormControl>
            </div>

            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => {
                let isValid = true;
                // if (!selectedInvestigation) {
                //   toast.error(t("please_select_investigation"));
                //   isValid = false;
                //   // return
                // }
                if (!invAplyGender) {
                  toast.error(t("please_select_gender"));
                  isValid = false;
                }
                if (!isValid) {
                  return;
                }

                const temp = {
                  investigation: selectedInvestigation,
                  gender: invAplyGender,
                };
                const tempIvn = [...userInvestigationList];
                tempIvn.push(temp);
                setUserInvestigationList(tempIvn);
                initializeInvestigation();
              }}
            >
              {t("add")}
            </Button>
          </div>
          <div className="invAddedListGrp">
            {userInvestigationList?.map((item, index) => {
              return (
                <div className="invAddedIndv">
                  <div className="invAddName">
                    {`${item?.investigation?.testName}${
                      item?.gender
                        ? ` ( ${t("gender")}: ${getGenderName(item.gender)} ) `
                        : ""
                    }`}
                  </div>
                  <IconButton
                    onClick={() => {
                      const temp = [...userInvestigationList];
                      temp.splice(index, 1);
                      setUserInvestigationList(temp);
                    }}
                  >
                    <Close />
                  </IconButton>
                </div>
              );
            })}

            {/* <div className="invAddedIndv">
              <div className="invAddName">
                Blood Glucose (Sugar) - Fasting (C)
              </div>
              <IconButton>
                <Close />
              </IconButton>
            </div> */}
          </div>
          {/* End Investigation*/}

          {/* start medication */}
          <div className="tskElemHeding mb-8">{t("medication")}</div>

          <div className="regimeWithComponent">
            {medicationList?.map((item, index) => {
              return (
                <ul className="regimeList">
                  <li>
                    <div className="regimeDtl">
                      <div className="regimeName">{item.regime}</div>
                      <div className="regControl">
                        {!item?.compound?.length && (
                          <Tooltip title={t("add_compound")} arrow>
                            <IconButton
                              className="editTimelineBtn"
                              onClick={() => {
                                const temp = [...medicationList];
                                temp[index].addOuterCompound = true;
                                setMedicationList(temp);
                              }}
                            >
                              <Add />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title={t("remove_regime")} arrow>
                          <div
                            className="rmvRegim"
                            onClick={() => {
                              const temp = [...medicationList];
                              temp.splice(index, 1);
                              setMedicationList(temp);
                            }}
                          >
                            {t("remove")}
                          </div>
                        </Tooltip>
                      </div>
                    </div>

                    <div className="medRecStpGrp">
                      {item.gender && (
                        <div className="medRecStpInd">
                          <span>{t("gender")}:</span>
                          {item.gender === "C"
                            ? "Common"
                            : item.gender === "M"
                            ? t("male")
                            : t("female")}
                        </div>
                      )}
                      {item.ageGroup && (
                        <div className="medRecStpInd">
                          <span>{t("age_group")}:</span>
                          {item.ageGroup}
                        </div>
                      )}
                      {item.instruction && (
                        <div className="medRecStpInd">
                          <span>{t("instruction")}:</span>
                          {item.instruction}
                        </div>
                      )}
                    </div>

                    <CompoundComponent
                      compoundList={compoundList}
                      compound={item}
                      parentId={item.localId}
                      updateCompound={updateCompound}
                      mode={"edit"}
                    />
                    {item?.addOuterCompound && (
                      <AddCompound
                        compoundList={compoundList}
                        handleAddCompound={(data) => {
                          if (!data?.compound) {
                            toast.error("please give compound");
                            return;
                          }

                          const temp = {
                            ...data,
                            index: currentStpIndex,
                            prevIndex: 0,
                          };
                          setCurrentIndex((prev) => prev + 1);

                          const tempMed = [...medicationList];
                          tempMed[index].compound = [temp];
                          tempMed[index].addOuterCompound = false;
                          setMedicationList(tempMed);
                        }}
                        closeAddCompound={() => {
                          const temp = [...medicationList];
                          temp[index].addOuterCompound = false;
                          setMedicationList(temp);

                          setShowAddRegime(false);
                        }}
                        shouldVerify={false}
                      />
                    )}
                  </li>
                </ul>
              );
            })}
            {!showAddRegime && (
              <div className="addAndCompound">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    setShowAddRegime(true);
                  }}
                >
                  {t("new_regime")}
                </Button>
              </div>
            )}
          </div>

          {showAddRegime && (
            <div className="medicationGroup">
              <div className="formElementGrp">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      // hiddenLabel
                      label={t("regime")}
                      required
                      autoComplete="off"
                      variant="outlined"
                      className="formTextFieldArea"
                      value={regime}
                      onChange={(e) => {
                        setRegime(e.target.value);
                      }}
                    />
                  </FormControl>
                </div>
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <InputLabel id="selectAgeGroup">
                      {t("age_group")}
                    </InputLabel>
                    <Select
                      required
                      labelId="selectAgeGroup"
                      id="select_AgeGroup"
                      value={selectAgeGroup}
                      label={t("age_group")}
                      onChange={handleChangselectAgeGroup}
                      className="formInputField"
                      variant="outlined"
                    >
                      {ageGroupList?.map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </div>

                <div className="formElement mxW-100">
                  <FormControl className="formControl">
                    <InputLabel id="demo-simple-select-label">
                      {t("gender")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={regimeGender}
                      label={t("gender")}
                      onChange={(e) => {
                        setRegimeGender(e.target.value);
                      }}
                      className="formInputField"
                      variant="outlined"
                    >
                      <MenuItem value="M">{t("male")}</MenuItem>
                      <MenuItem value="F">{t("female")}</MenuItem>
                      <MenuItem value="C">{t("common")}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="formElementGrp">
                <div className="formElement">
                  <FormControl className="formControl pRelative">
                    <TextField
                      label={t("non_medicational_instruction")}
                      variant="outlined"
                      className="descptionTextFild"
                      multiline
                      minRows={2}
                      maxRows={7}
                      value={instruction}
                      onChange={(e) => setInstruction(e.target.value)}
                    />
                  </FormControl>
                </div>
              </div>

              <AddCompound
                compoundList={compoundList}
                handleAddCompound={(data) => {
                  if (!regime.trim()) {
                    toast.error(t("please_add_regime_name"));
                    setRegime("");
                    return;
                  }

                  if (!data?.compound && !instruction) {
                    toast.error("please give insturction or compound");
                    return;
                  }

                  const temp = {
                    ...data,
                    index: currentStpIndex,
                    prevIndex: 0,
                  };
                  setCurrentIndex((prev) => prev + 1);
                  const dto = {
                    localId: uuidv4(),
                    regime,
                    gender: regimeGender,
                    instruction: instruction,
                    compound: data?.compound ? [temp] : [],
                    ageGroup: selectAgeGroup,
                  };

                  setMedicationList((prev) => [...prev, dto]);
                  setShowAddRegime(false);
                  setRegime("");
                }}
                closeAddCompound={() => {
                  setShowAddRegime(false);
                }}
                shouldVerify={false}
              />
            </div>
          )}

          {/* end medication */}

          {/* Start Parameter to be Monitor */}
          <div className="tskElemHeding mb-8">
            {t("parameter_to_be_monitored")}
          </div>
          <div className="formElementGrp mb-8">
            <div className="formElement">
              <FormControl className="formControl">
                {/* <InputLabel id="selectparam">Select parameter</InputLabel>
                <Select
                  labelId="selectparam"
                  id="select_param"
                  multiple
                  value={parameterMonitor}
                  renderValue={(selected) => ""}
                  label="Select parameter"
                  onChange={handleChangeparameterMonitor}
                  className="formInputField"
                  variant="outlined"
                >
                  {monitorNames.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={parameterMonitor.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select> */}
                <Autocomplete
                  freeSolo
                  className="formAutocompleteField"
                  variant="outlined"
                  value={selectedParameter}
                  options={suggestedParameterList}
                  inputValue={parameterString}
                  onChange={(e, newValue) => {
                    setSelectedParameter(newValue);
                  }}
                  onInputChange={(e, value) => {
                    setParameterString(value);
                  }}
                  // getOptionLabel={(option) => option.lifeStyle}
                  renderOption={(props, item) => {
                    return (
                      <li {...props} key={item}>
                        {item}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder={t("search_parameter")}
                      label={t("parameter_name")}
                      // required
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>

            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => {
                const parameterItem = parameterString;
                if (!parameterString) {
                  toast.error(t("please_provide_parameter_name"));
                  return;
                }
                const temp = [...parameterMonitor];
                temp.push(parameterString);
                setParameterMonitor(temp);
                setParameterString("");
                setSelectedParameter(null);
              }}
            >
              {t("add")}
            </Button>
          </div>
          <div className="invAddedListGrp">
            {parameterMonitor?.map((item, index) => {
              return (
                <div className="invAddedIndv">
                  <div className="invAddName">{item}</div>
                  <IconButton
                    onClick={() => {
                      const temp = [...parameterMonitor];
                      temp.splice(index, 1);
                      setParameterMonitor(temp);
                    }}
                  >
                    <Close />
                  </IconButton>
                </div>
              );
            })}

            {/* <div className="invAddedIndv">
              <div className="invAddName">Respiration Rate</div>
              <IconButton>
                <Close />
              </IconButton>
            </div>
            <div className="invAddedIndv">
              <div className="invAddName">SPO2</div>
              <IconButton>
                <Close />
              </IconButton>
            </div> */}
          </div>
          {/* end Parameter to be Monitor */}

          {/* Start lifestyle */}
          <div className="tskElemHeding mb-8">
            {t("lifestyle_recommendations")}
          </div>
          <div className="formElementGrp mb-8">
            <div className="formElement">
              <FormControl className="formControl">
                {/* <InputLabel id="selectparam">Select Lifestyle</InputLabel> */}
                {/* <Select
                  labelId="selectparam"
                  id="select_Lifestyle"
                  value={selectLifestyle}
                  label="Select Lifestyle"
                  onChange={handleChangselectLifestyle}
                  className="formInputField"
                  variant="outlined"
                >
                  <MenuItem value="Lifestyle1">Lifestyle one</MenuItem>
                  <MenuItem value="Lifestyle2">Lifestyle two</MenuItem>
                </Select> */}
                <Autocomplete
                  freeSolo
                  className="formAutocompleteField"
                  variant="outlined"
                  value={selectedLifeStyle}
                  options={suggestedLifeStyleList}
                  inputValue={lifeStyleString}
                  onChange={(e, newValue) => {
                    setSelectedLifeStyle(newValue);
                  }}
                  onInputChange={(e, value) => {
                    setLifeStyleString(value);
                    // else {
                    //   setSymptomSearchString("");
                    // }
                  }}
                  getOptionLabel={(option) => option.lifeStyle}
                  renderOption={(props, item) => {
                    return (
                      <li {...props} key={item.id}>
                        {item.lifeStyle}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder={t("search_lifestyle")}
                      label={t("life_style_name")}
                      // required
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>

            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => {
                if (!lifeStyleString || lifeStyleString === "") {
                  toast.error(t("please_provide_lifestyle"));
                  return;
                }
                let tempLifeStyle = selectedLifeStyle;

                setRecomandedLifeStyle((prev) => {
                  const temp = [...prev, tempLifeStyle];
                  return temp;
                });
                initializeLifeStyle();
              }}
            >
              {t("add")}
            </Button>
          </div>
          <div className="invAddedListGrp">
            {recommandedLifeStyle?.map((item, index) => {
              return (
                <div className="invAddedIndv">
                  <div className="invAddName">{item?.lifeStyle}</div>
                  <IconButton
                    onClick={() => {
                      const temp = [...recommandedLifeStyle];
                      temp.splice(index, 1);
                      setRecomandedLifeStyle(temp);
                    }}
                  >
                    <Close />
                  </IconButton>
                </div>
              );
            })}
          </div>
          {/* end lifestyle */}

          {/* start procedure */}
          <div className="tskElemHeding mb-8">{t("procedure")}</div>
          <div className="formElementGrp mb-8">
            <div className="formElement">
              <FormControl className="formControl">
                <Autocomplete
                  // freeSolo
                  className="formAutocompleteField"
                  variant="outlined"
                  value={selectedProcedure}
                  options={suggestedProcedure}
                  onChange={(e, newValue) => {
                    setSelectedProcedure(newValue);
                    setProcedureGender(newValue?.gender);
                  }}
                  inputValue={procedureString}
                  onInputChange={(e, value) => {
                    setProcedureString(value);
                  }}
                  getOptionLabel={(option) => option.procedureName}
                  renderOption={(props, item) => {
                    return (
                      <li {...props} key={item.id}>
                        {item.procedureName}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder={t("add_procedure")}
                      label={t("procedure_name")}
                      // required
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
            <div className="formElement mxW-100">
              <FormControl className="formControl">
                <InputLabel id="demo-simple-select-label">
                  {t("gender")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={procedureGender}
                  label={t("gender")}
                  onChange={handleChangeProcedureGender}
                  className="formInputField"
                  variant="outlined"
                >
                  <MenuItem value="M">{t("male")}</MenuItem>
                  <MenuItem value="F">{t("female")}</MenuItem>
                  <MenuItem value="C">{t("common")}</MenuItem>
                </Select>
              </FormControl>
            </div>

            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => {
                let tempProcedure = selectedProcedure;
                if (!tempProcedure) {
                  toast.error(t("please_provide_procedure_name"));
                  return;
                }

                setSelectedProcedureList((prev) => {
                  const temp = [
                    ...prev,
                    {
                      procedure: tempProcedure,
                      gender: procedureGender,
                    },
                  ];
                  return temp;
                });
                // initializeForm();
                setSelectedProcedure(null);
                setProcedureGender("");
                setProcedureString("");
              }}
            >
              {t("add")}
            </Button>
          </div>
          <div className="invAddedListGrp">
            {selectedProcedureList?.map((item, index) => (
              <>
                <div className="invAddedIndv">
                  <div className="invAddName">
                    {`${item?.procedure?.procedureName} ${
                      item.gender
                        ? ` ( ${t("gender")}: ${getGenderName(item.gender)} ) `
                        : ""
                    }`}
                  </div>
                  <IconButton
                    onClick={() => {
                      const temp = [...selectedProcedureList];
                      temp.splice(index, 1);
                      setSelectedProcedureList(temp);
                    }}
                  >
                    <Close />
                  </IconButton>
                </div>
              </>
            ))}
          </div>
          {/* End procedure */}
        </div>

        <div className="elementFooter">
          <div className="formBtnElement">
            <Button
              className="dfultPrimaryBtn"
              onClick={() => {
                const recursiveHandler = (
                  item,
                  regimeName,
                  ageGroup,
                  gender,
                  instruction,
                  diseaseId,
                  operator
                ) => {
                  const compound = {
                    regimeName: regimeName,
                    ageGroup: ageGroup,
                    weightMeasured: item?.weightMeasured ? "Y" : "N",
                    diseaseId: diseaseId,
                    compoundId: item?.compound?.compoundId || 0,
                    formId: item?.form?.formId || 0,
                    routeId: item?.route?.id || 0,
                    dosages: item?.dose || "",
                    dosagesUnit: item?.doseUnit || "",
                    dosagesLimit: item?.doseLimit || "",
                    durationId: item?.duration?.id || 0,
                    frequencyId: item?.frequency?.id || 0,
                    operator: operator || "",
                    indexId: item.index,
                    previousIndexId: item.prevIndex,
                    formName: item?.form?.formName || "",
                    routeName: item?.route?.route || "",
                    duration: item?.duration?.durationName || "",
                    frequency: item?.frequency?.frequency,
                    compoundName: item?.compound?.compoundName || "",
                    notes: item?.note || "",
                    gender: gender,
                    instruction: instruction,
                  };
                  let temp = [];
                  const value = item?.thenList
                    ?.map((item) => {
                      const tempValue = recursiveHandler(
                        item,
                        regimeName,
                        ageGroup,
                        gender,
                        instruction,
                        diseaseId,
                        "Then"
                      );
                      return tempValue.flat();
                    })
                    ?.flat();

                  const value2 = item?.orList
                    ?.map((item) => {
                      const tempValue = recursiveHandler(
                        item,
                        regimeName,
                        ageGroup,
                        gender,
                        instruction,
                        diseaseId,
                        "Or"
                      );
                      return tempValue.flat();
                    })
                    ?.flat();

                  const value3 = item?.andList
                    ?.map((item) => {
                      const tempValue = recursiveHandler(
                        item,
                        regimeName,
                        ageGroup,
                        gender,
                        instruction,
                        diseaseId,
                        "Along With"
                      );
                      return tempValue.flat();
                    })
                    ?.flat();

                  if (value) {
                    temp = [...temp, ...value];
                  }
                  if (value2) {
                    temp = [...temp, ...value2];
                  }

                  if (value3) {
                    temp = [...temp, ...value3];
                  }
                  if (temp?.length > 0) {
                    return [compound, ...temp];
                  } else {
                    return [compound];
                  }
                };
                // ;
                const compoundList = medicationList
                  ?.map((item) => {
                    const { compound, regime, gender, instruction, ageGroup } =
                      item;
                    let tempCompounds = [];
                    if (compound?.length == 0) {
                      tempCompounds = [
                        {
                          regimeName: regime || "",
                          ageGroup: ageGroup,
                          weightMeasured: "N",
                          diseaseId: 0,
                          compoundId: 0,
                          formId: 0,
                          routeId: 0,
                          dosages: "",
                          dosagesUnit: "",
                          dosagesLimit: "",
                          durationId: 0,
                          frequencyId: 0,
                          operator: "",
                          indexId: currentStpIndex,
                          previousIndexId: 0,
                          formName: "",
                          routeName: "",
                          duration: "",
                          frequency: null,
                          compoundName: "",
                          notes: "",
                          gender: gender,
                          instruction: instruction || "",
                        },
                      ];
                      setCurrentIndex((prev) => prev + 1);
                    }
                    compound?.forEach((comp) => {
                      const value = recursiveHandler(
                        comp,
                        item.regime,
                        item.ageGroup,
                        item.gender,
                        item.instruction || "",
                        selectedDisease.id
                      );
                      tempCompounds = [...tempCompounds, ...value];
                      // return value;
                    });
                    return tempCompounds;
                  })
                  .flat();
                const reqDto = {
                  diseaseId: selectedDisease.id,
                  orgId: loginUserDetails.orgId,
                  lifeStyles: recommandedLifeStyle || [],
                  parameters: parameterMonitor || [],
                  tests:
                    userInvestigationList?.map((item) => ({
                      testId: item.investigation.testId,
                      testName: item.investigation.testName,
                      gender: item.gender,
                    })) || [],
                  procedures:
                    selectedProcedureList?.map((item) => {
                      return {
                        procedureId: item.procedure.id,
                        gender: item.gender,
                        procedureName: item.procedure.procedureName,
                      };
                    }) || [],
                  compounds: compoundList || [],
                  stpId: editStpData?.stpId,
                };

                if (isLocalHost()) {
                  console.log("compound list =", compoundList);
                  // return;
                }

                zhPatientService
                  .submitStp(loginUserDetails.userId, reqDto)
                  .then((response) => {
                    if (response.data) {
                      toast.success(t("data_submitted_successfully"));
                      handelCloseEditStp(true);
                    }
                  });
              }}
            >
              {t("save")}
            </Button>

            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => handelCloseEditStp()}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </div>

      {listAgeGroup && (
        <>
          <ListAgeGroupModel
            handleCloseListAgeGroupModel={handleCloseListAgeGroupModel}
            handleClose={() => {
              setListAgeGroup(false);
            }}
          />
        </>
      )}
    </>
  );
}
