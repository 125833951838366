import { StarBorderRounded, StarRateRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import DashboardServices from "../../../../../services/DashboardServices";
import { toast } from "react-toastify";
import { AddTabContext } from "../../../../dashboard/view/DashboardPage";
import { useTranslation } from "react-i18next";
import SecureIndexedDB from "../../../../../utils/IndexedDB";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";

export default function DrFeedback({ feedbackFrom, visitId, tabKey, data }) {
  const { t } = useTranslation();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const loginUserDetail = GetLoginUserDetails();
  const { closeTab } = useContext(AddTabContext);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [feedbackQuestion, setFeedbackQuestion] = useState(null);
  const [heading, setHeading] = useState(null);
  const [remark, setRemark] = useState(null);
  const [visId, setVisId] = useState();

  // useEffect(()=>{
  //   setBookSteps([
  //     {
  //       target: ".docFeedBackStepOne",
  //       content: "This is the feedback page. Please provide your valuable input to improve our services.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".docFeedBackStepTwo",
  //       content: "Please rate your overall experience with the video consultation.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".docFeedBackStepThree",
  //       content: "Select the issues you faced during the consultation, if any.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".docFeedBackStepFour",
  //       content: "You can leave additional comments or suggestions here.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".docFeedBackStepFive",
  //       content: "Once you're done, click here to submit your feedback.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".docFeedBackStepSix",
  //       content: "Thank you for your feedback! We appreciate your input.",
  //       placement: "center",
  //       disableBeacon: true,
  //     },
  //   ]
  //   )
  // },[])

  useEffect(() => {
    setBookSteps([
      {
        target: ".docFeedBackStepOne",
        content: t("feedback_page_overview"),
        disableBeacon: true,
      },
      {
        target: ".docFeedBackStepTwo",
        content: t("rate_experience"),
        disableBeacon: true,
      },
      {
        target: ".docFeedBackStepThree",
        content: t("select_issues_encountered"),
        disableBeacon: true,
      },
      {
        target: ".docFeedBackStepFour",
        content: t("leave_additional_comments"),
        disableBeacon: true,
      },
      {
        target: ".docFeedBackStepFive",
        content: t("submit_feedback"),
        disableBeacon: true,
      },
      {
        target: ".docFeedBackStepSix",
        content: t("thank_you_feedback"),
        // placement: "center",
        disableBeacon: true,
      },
    ]);
  }, [t]);

  const renderStars = () => {
    return [...Array(5)].map((star, index) => {
      const ratingValue = index + 1;

      return (
        <label key={index}>
          <input
            type="radio"
            name={t("rating")}
            value={ratingValue}
            onClick={() => setRating(ratingValue)}
            style={{ display: "none" }}
          />
          {ratingValue <= (hover || rating) ? (
            <StarRateRounded
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(0)}
              onClick={() => setRating(ratingValue)}
            />
          ) : (
            <StarBorderRounded
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(0)}
              onClick={() => setRating(ratingValue)}
            />
          )}
        </label>
      );
    });
  };

  useEffect(() => {
    getfeedbackMaster();
    setVisId(visitId);
  }, []);

  const getfeedbackMaster = async () => {
    let localStorageKey = "";
    let tempFeedbackFrom = "";

    if (feedbackFrom === "Doctor") {
      localStorageKey = "drFeedbackQuestions";
      tempFeedbackFrom = "DOCTOR";
    } else {
      localStorageKey = "patientFeedbackQuestion";
      tempFeedbackFrom = "PATIENT";
    }

    const diseasetring = await SecureIndexedDB.getItem(localStorageKey);
    if (diseasetring) {
      const feedbackQuestionObj = JSON.parse(diseasetring);
      const heading = Object.keys(feedbackQuestionObj);
      const questions = Object.values(feedbackQuestionObj)?.[0]?.map(
        (item) => ({
          value: item,
          selected: false,
        })
      );
      console.log("Question =", Object.values(feedbackQuestionObj));
      setFeedbackQuestion(questions);
      setHeading(heading);
      return;
    }

    DashboardServices.getfeedbackMaster(
      loginUserDetail.orgId,
      tempFeedbackFrom
    ).then((response) => {
      if (response.data) {
        setFeedbackQuestion(response.data);
        SecureIndexedDB.setItem(localStorageKey, JSON.stringify(response.data));
      }
    });
  };

  function decodeHTMLEntities(text) {
    const entities = {
      "&#39;": "'",
      "&quot;": '"',
      "&amp;": "&",
      "&lt;": "<",
      "&gt;": ">",
      // Add more entities as needed
    };

    return text.replace(
      /&#39;|&quot;|&amp;|&lt;|&gt;/g,
      (match) => entities[match]
    );
  }

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
        <Joyride
        steps={booksteps}
        run={run["drFeeDbackDOC"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('finish'),
          next: t('next'),
          skip: t('skip'),
        }}
      />
      <Box className="feedBackContainerArea" sx={{ bgcolor: "dark.pageBg" }}>
        <Grid container spacing={0} className="feedBackContainArea">
          <Grid item xs={12} sx={{ color: "dark.contrastText" }}>
            <div className="feedBackContainer">
              <div className="feedBackCarea">
                <div className="feedbackHeader docFeedBackStepOne">
                  <div className="fbhTxt docFeedBackStepSix">
                    {visId ? t("feedback") : t("thank_you")}
                  </div>
                </div>

                {visId && (
                  <>
                    <div className="userRating">
                      <div className="fbshTxt">
                        {t(
                          "please_provide_a_rating_for_your_overall_experience_with_the_video_consultation"
                        )}
                      </div>
                      <div className="reatingStar docFeedBackStepTwo">{renderStars()}</div>
                      {/* {rating > 0
                        ? `Rating: ${rating} / 5`
                        : "No rating selected"} */}
                      {rating > 0
                        ? t("with_rating", { rating })
                        : t("no_rating")}
                    </div>
                    {rating < 5 && (
                      <>
                        <div className="textleft85">
                          {t(
                            "please_select_below_issues_you_faces_during_consultation"
                          )}
                        </div>
                        {/* doctor give this feedback  start*/}
                        <div className="feedbackOption docFeedBackStepThree">
                          <FormGroup>
                            {feedbackQuestion?.map((item, index) => {
                              return (
                                <>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={item.selected}
                                        onChange={(e) => {
                                          const temp = [...feedbackQuestion];
                                          temp[index].selected =
                                            e.target.checked;
                                          setFeedbackQuestion(temp);
                                        }}
                                      />
                                    }
                                    label={decodeHTMLEntities(item.value)}
                                  />
                                </>
                              );
                            })}

                            {/* <FormControlLabel
                          control={<Checkbox />}
                          label="The patient's video was occasionally lost."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The video occasionally froze."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The audio quality was below expectations."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The patient's audio was unclear."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The patient's audio was intermittently lost."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Operator was not following the instructions."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The operator's overall communication and liaison were below acceptable standards."
                        /> */}
                          </FormGroup>
                        </div>
                        {/* doctor give this feedback  end*/}

                        {/* Patient Feedback for doctor start */}
                        {/* <div className="feedbackOption">
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The video quality fell short of expectations."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The Doctor's video was occasionally lost."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The video occasionally froze."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The audio quality was below expectations."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The Doctor's audio was unclear."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The Doctor's audio was intermittently lost."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The Doctor lacked empathy."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The Doctor appeared to be in a rush."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="I was dissatisfied with the resolution provided by the Doctor."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Operator was not cooperative."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The operator's overall communication and liaison were below acceptable standards."
                        />
                      </FormGroup>
                    </div> */}
                        {/* Patient Feedback for doctor end */}
                      </>
                    )}
                    <div className="formElement userComment">
                      <FormControl className="formControl pRelative">
                        <TextField
                          label={t("please_leave_your_valuable_feedback")}
                          placeholder={t(
                            "please_leave_your_valuable_feedback_regarding_your_overall_experience_with_the_video_consultation"
                          )}
                          variant="outlined"
                          className="descptionTextFild docFeedBackStepFour"
                          multiline
                          rows={3}
                          maxRows={5}
                          value={remark}
                          onChange={(e) => {
                            setRemark(e.target.value);
                          }}
                        ></TextField>
                      </FormControl>
                    </div>
                    <Button
                      className="submitFeedbackBtn docFeedBackStepFive"
                      onClick={() => {
                        const filteredFeedback = feedbackQuestion?.filter(
                          (item) => item.selected === true
                        );
                        if (!visId) {
                          return;
                        }
                        if (!rating) {
                          toast.error(t("please_select_rating"));
                          return;
                        }

                        // if (rating === 5 && !remark) {
                        //   toast.error("Please provide remark");
                        //   return;
                        // }

                        // if (
                        //   rating < 5 &&
                        //   (!filteredFeedback ||
                        //     filteredFeedback.length === 0) &&
                        //   !remark
                        // ) {
                        //   toast.error(
                        //     "Please select at least one option below or provide your own feedbackd"
                        //   );
                        //   return;
                        // }

                        let reqDto = {
                          visitId: visId,
                          feedbackFrom: feedbackFrom,
                          userId: loginUserDetail.userId,
                          questions:
                            feedbackQuestion
                              ?.filter((item) => item.selected === true)
                              ?.map((item) => {
                                return item.value;
                              }) || [],
                          rmks: remark || "",
                          rating: rating || 0,
                          feedbackHdr: heading[0] || "",
                          orgId: loginUserDetail.orgId,
                          unitId: loginUserDetail.signleUnitId,
                        };

                        if (feedbackFrom === "Patient") {
                          reqDto.doctorId = data?.doctorId;
                        }

                        console.log("reqDto =", reqDto);
                        // return;
                        DashboardServices.submitFeedback(reqDto).then(
                          (response) => {
                            if (response.data == 1) {
                              toast.success(
                                t("feedback_submitted_successfully")
                              );
                              setFeedbackQuestion(null);
                              setRemark("");
                              setRating(0);
                              setVisId(null);
                              closeTab(tabKey);
                            } else {
                              toast.error(
                                t("feedback_submission_unsuccessful")
                              );
                            }
                          }
                        );
                      }}
                    >
                      {t("submit_your_feedback")}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
