import { Add, ArrowBack, Search, Visibility } from "@mui/icons-material";
import { Button, CircularProgress, FormControl, IconButton, InputLabel } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Download, Trash } from "react-bootstrap-icons";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import DashboardServices from "../../../../services/DashboardServices";
import { enIN, es } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import DateUtils from "../../../../utils/DateUtils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";

registerLocale("en", enIN);
registerLocale("es", es);

export default function ViewRoutineWork({
  handelCloseViewRoutineWork,
  handelOpenAddRoutineWork,
  closeAll,
}) {
  const { t, i18n } = useTranslation();
  const userDetails = GetLoginUserDetails();
  const [loading, setLoading] = useState(true);
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [primaryPosdate, setPrimaryPosdate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [secondaryPosdate, setSecondaryPosdate] = useState(new Date());
  const [dataForRoutineWork, SetDataForRoutineWork] = useState([]);

  const currentLocale = i18n.language === "es" ? "es" : "en";

  const handleFromDateChange = (newDate) => {
    setPrimaryPosdate(newDate);
  };

  const handleToDateChange = (newDate) => {
    setSecondaryPosdate(newDate);
  };

  useEffect(() => {
    fetchDataForRoutineWork();
  }, [primaryPosdate , secondaryPosdate]);

  const fetchDataForRoutineWork = () => {
    const fromDate = DateUtils.getDateInDDMMYYYY(primaryPosdate);
    const toDate = DateUtils.getDateInDDMMYYYY(secondaryPosdate);
    DashboardServices.getListOfRoutineWork(
      userDetails.userId,
      fromDate,
      toDate
    ).then((response) => {
      console.log(response.data);
      SetDataForRoutineWork(response.data);
      setLoading(false);
    });
  };

  // const handleFileDownload = (url) => {
  //   const fileName = url.split("/").pop() || "download";
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.download = fileName;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const handleFileDownload = (url) => {
    window.open(url, "_blank");
  };


  useEffect(() => {
    setBookSteps([
      {
        target: '.viewRoutineStepOne',
        content: t('view_routine_step_one'),
        disableBeacon: true,
      },
      {
        target: '.viewRoutineStepTwo',
        content: t('view_routine_step_two'),
        disableBeacon: true,
      },
      {
        target: '.viewRoutineStepThree',
        content: t('view_routine_step_three'),
        disableBeacon: true,
      },
      {
        target: '.viewRoutineStepFour',
        content: t('view_routine_step_four'),
        disableBeacon: true,
      },
      {
        target: '.viewRoutineStepFive',
        content: t('view_routine_step_five'),
        disableBeacon: true,
      },
      {
        target: '.viewRoutineStepSix',
        content: t('view_routine_step_six'),
        disableBeacon: true,
      },
      {
        target: '.viewRoutineStepSeven',
        content: t('view_routine_step_seven'),
        disableBeacon: true,
      },
      {
        target: '.viewRoutineStepEight',
        content: t('view_routine_step_eight'),
        disableBeacon: true,
      },
    ]);
  }, [t]);
  

  // useEffect(()=>{
  // setBookSteps([
  //   {
  //     target: ".viewRoutineStepOne",
  //     content: "Here You can view All the Routine Work Here",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".viewRoutineStepTwo",
  //     content: "Click here to go back to the previous page.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewRoutineStepThree',
  //     content: "Search for specific activities by name.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewRoutineStepFour',
  //     content: "Select the start date for filtering the routine work.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewRoutineStepFive',
  //     content: "Choose the end date for your filter.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewRoutineStepSix',
  //     content: "Click here to add a new routine work.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewRoutineStepSeven', 
  //     content: "Here’s the list of routine works.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewRoutineStepEight',
  //     content: "Click the download button to download related files.",
  //     disableBeacon: true,
  //   }
  // ])
  // },[])

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };
  

  return (
    <>
        <Joyride
        steps={booksteps}
        run={run["viewRoutineWorkCDS"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('finish'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="AnalyticsDashboardContainer viewRoutineStepOne">
        <div className="AnalyticsDashboardSubHeader viewRoutineStepTwo">
          <IconButton
            onClick={() => {
              handelCloseViewRoutineWork();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("routine_work")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              {/* <div className="cuhederTxt">Departmment List</div> */}
              <div className="formSearchGrp">
              <div className="searchTasklist viewRoutineStepThree">
                <input
                  type="text"
                  class="serchTaskInput"
                  placeholder={t("search_activity")}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              <div className="formElement width180">
                <FormControl className="formControl">
                  <InputLabel id="primPosition" className="setCompletionDate">
                    {t("from_date")}
                  </InputLabel>
                  <ReactDatePicker
                    locale={currentLocale}
                    toggleCalendarOnIconClick
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    showMonthDropdown
                    showIcon
                    labelId="primPosition"
                    className="formDatePicker viewRoutineStepFour"
                    selected={primaryPosdate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      handleFromDateChange(date);
                    }}
                  />
                </FormControl>
              </div>
              <div className="formElement width180">
                <FormControl className="formControl">
                  <InputLabel id="primPosition" className="setCompletionDate">
                    {t("to_date")}
                  </InputLabel>
                  <ReactDatePicker
                    locale={currentLocale}
                    toggleCalendarOnIconClick
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    showMonthDropdown
                    showIcon
                    labelId="primPosition"
                    className="formDatePicker viewRoutineStepFive"
                    selected={secondaryPosdate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      handleToDateChange(date);
                    }}
                  />
                </FormControl>
              </div>
              </div>
          

              <div className="addUnitsBtn">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn viewRoutineStepSix"
                  onClick={() => handelOpenAddRoutineWork()}
                >
                  {t("routine_work")}
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
            {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : dataForRoutineWork.length === 0 ? (
                <div class="noDataCard ">
                  <span>{t("oops")}</span>
                   {t("no_routine_work_define_yet")}
                  <br />
                  <span className="sallMsg">
                    {t("click_add_routine_work_button_to_create_new_routine_work")}
                  </span>
                </div>
              ) : (
              <div className="TableContainer">
                <table className="taskListTable viewRoutineStepSeven">
                  <thead className="taskListtableHeader">
                    <tr>
                      <th className="width100">{t("sl_no")}</th>
                      <th>{t("activity_name")}</th>
                      <th>{t("description")}</th>
                      <th>{t("date_time")}</th>
                      <th className="width100"></th>
                    </tr>
                  </thead>
                  <tbody className="scrolableTableBody">
                    {dataForRoutineWork.map((item, index) => (
                        <tr key={item.workDtlId}>
                          <td>
                            <div className="tskLstTaskNM">{index + 1}.</div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {item.activityName}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {item.description}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {item.createdTime}
                            </div>
                          </td>
                          {item.fileUrl && (
                          <td>
                            <div className="tblActionBtnGrp viewRoutineStepEight">
                              <IconButton onClick={() => handleFileDownload(item.fileUrl)}>
                                <Download />
                              </IconButton>
                            </div>
                          </td>
                          )}
                        </tr>
                      ))
                    }

                  </tbody>
                </table>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
