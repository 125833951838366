export const esLang = {
  this_brand_generic_name_is_not_present_in_the_table:
    "El nombre genérico de esta marca no está presente en la tabla",
  content_quality:
    "El Contenido contenido o puesto a disposición a través del Sitio web o cualquier artículo(s) puesto a disposición en o a través del Sitio web será de calidad comerciable y/o apto para un propósito particular.",
  website_change_cancelled: "Cambio de sitio web cancelado",
  salary_type: "Tipo de Salario",
  ms: "Sra.",
  code: "Código",
  symptom_name: "Nombre del síntoma",
  no_files_were_uploaded_during_the_meeting:
    "No se subieron archivos durante la reunión",
  virtual: "Virtual",
  reviewerName: "Nombre del Revisor",
  add_resolution: "Agregar Resolución",
  monthly: "Mensual",
  edit_terms_and_conditions: "Editar Términos y Condiciones",
  template_list: "Lista de Plantillas",
  probationConfirmation: "Período de prueba / Confirmación",
  martial_status: "Estado Civil",
  reimbursementAmount: "Monto del reembolso",
  systemic_illness: "Enfermedad Sistémica",
  invoiceDate: "Fecha de Factura",
  areasForImprovement: "Áreas de Mejora",
  create_org: "Crear Organización",
  edit: "Editar",
  book_doctor_appointment: "Reservar cita con el doctor",
  template_deleted_successfully: "Plantilla eliminada con éxito",
  downloading_transcriptions_please_wait:
    "Descargando transcripciones, por favor espere",
  salary_head_name: "Nombre del componente salarial",
  payment_successful: "Pago exitoso",
  very_high: "Muy alto",
  security_details:
    "Que las comunicaciones hacia o desde el Sitio web serán seguras y/o no serán interceptadas. Usted reconoce y acepta que su acceso y uso del Sitio web y el Contenido es completamente bajo su propio riesgo y responsabilidad.",
  something_went_wrong_please_try_again:
    "Algo salió mal, por favor intente nuevamente",
  termination_conditions: "Condiciones de Terminación",
  an_error_occurred_during_file_upload_please_try_again_later:
    "Ocurrió un error durante la carga del archivo. Inténtelo de nuevo más tarde.",
  title_is_needed_in_key_responsibility:
    "Se necesita un título en 'Responsabilidad clave'",
  accomodationCost: "Costo de alojamiento",
  forty_five_days: "45 Días",
  please_select_interviewers: "Por favor, Seleccione Entrevistadores",
  user_email: "Correo electrónico del usuario",
  twitter_link: "Enlace de Twitter",
  mark_ticket_resolved: "Marcar Ticket como Resuelto",
  ifsc_code: "Código IFSC",
  test_is_not_available_in_this_center:
    "La prueba no está disponible en este centro",
  please_enter_department_description:
    "Por favor, ingrese la descripción del departamento",
  please_enter_remarks: "Por favor, ingrese observaciones",
  frequency: "Frecuencia",
  click_add_department_button_to_create_new_department:
    "Haga clic en el Botón de Agregar Departamento para crear un nuevo Departamento",
  example_file_names: "Ej: Archivo Uno, Archivo Dos, etc.",
  check_in: "Entrada",
  category: "Categoría",
  record_your_voice_notes: "Graba tus notas de voz",
  recommendation_reason: "Razón de Recomendación",
  audio_devices: "Dispositivos de audio",
  items: "Ítems",
  doctor_consultations: "Consultas médicas",
  file_upload_already_uploaded: "'{{fileName}}' ya cargado",
  temparature_must_be_within_thirty_two_and_forty_one:
    "La temperatura debe estar entre 32.22 y 41.10",
  add_title: "Agregar Título",
  translate: "Traducir",
  create_new_group: "Crear nuevo grupo",
  please_select_correct_response_date:
    "Por favor seleccione la fecha de respuesta correcta",
  new_sheet: "Nueva Hoja",
  search_name: "Buscar nombre",
  full_name: "Nombre Completo",
  marital_status: "Estado civil",
  upload_files: "Subir Archivos",
  agenda: "Orden del día",
  delete_audio_note: "Eliminar Nota de Audio",
  face_liveness_passed: "Prueba de vivacidad facial pasada....",
  new_rights: "Nuevos derechos",
  doctor_switched_patient: "Doctor Cambió al Paciente",
  leave_request: "Solicitud de Permiso",
  click_add_access_permission_button_to_create_new_access_permission:
    "Haga clic en el Botón de Agregar Permiso de Acceso para crear un nuevo Permiso de Acceso",
  travelStartDate: "Fecha de Inicio del Viaje",
  registration: "Registro",
  Uom: "Unidad de Medida",
  expenditureType: "Tipo de Gasto",
  company: "Empresa",
  template: "Plantilla",
  min_notice_days: "Días Mínimos de Aviso",
  committee: "Comité",
  replace_with: "Reemplazar Con",
  policies_added: "Políticas Agregadas",
  maximum_number_of_days_allowed_for_encashment_cannot_more_the_three_digit_for_current_employee:
    "Número Máximo de Días Permitidos para Canje No Puede Ser Más de 3 Dígitos para el Empleado Actual",
  kra: "ACR",
  already_pms_done_count: "Cantidad de PMS ya realizados",
  budgetHead: "Partida Presupuestaria",
  chief_cardio_vascular_thoracic_surgeon:
    "Jefe de cirugía cardiovascular y torácica",
  po_effective_date: "Fecha de Efectividad de PO",
  remove_from_guest_list: "¿Eliminar a {{userName}} de la lista de invitados?",
  activity_deleted: "Actividad eliminada",
  issue_appoinment_success: "Cita emitida con éxito",
  last_payment_date: "Fecha del último pago",
  folder_created_successfully: "Carpeta creada con éxito",
  reassign_reason: "Motivo de Reasignación",
  support: "Soporte",
  template_already_present: "La plantilla ya está presente",
  successfully_added_unit_for_test: "Unidad agregada para el test con éxito",
  swift_code: "Código SWIFT",
  please_save_modification_before_you_proceed:
    "Por favor, guarde la modificacion antes de continuar",
  lmp: "Último período menstrual",
  no_voice_recorded: "No se ha grabado ninguna voz",
  parameter_to_be_monitored: "Parámetro a monitorear",
  claimedOnActualRate: "Reclamado a Tasa Real",
  activate: "Activar",
  action: "Acción",
  rfp_sent_proposal_awaited: "Propuesta de RFP enviada, en espera",
  size: "Tamaño",
  fail_to_delete_holiday: "Error al eliminar el día festivo",
  get_in_touch: "Contacta",
  hike_amount: "Monto del Aumento",
  leave_webinar: "Salir del Webinar",
  pmsCycle: "Ciclo PMS:",
  diet_recommendations: "Recomendaciones dietéticas",
  enter_category_name: "Ingresar Nombre de la Categoría",
  load_subtitles: "Cargar subtítulos",
  do_not_have_an_account: "¿No tienes una cuenta?",
  this_meeting: "Esta reunión",
  start_live_stream: "Iniciar transmisión en vivo",
  procedure: "Procedimiento",
  edit_holiday: "Editar Festivo",
  bereavement_leave: "Permiso por Luto",
  withdraw: "Retirar",
  repeatUntilDate: "Repetir hasta la fecha",
  add_postponed_details_here: "Agregar Detalles de Posposición Aquí",
  please_enter_last_name: "Por favor ingrese el apellido",
  event_description: "Descripción del Evento",
  completion_date: "Fecha de Finalización",
  select_since: "--Seleccionar Desde--",
  possible_investigations: "Posibles estudios",
  select_date_time: "Seleccionar Fecha y Hora de Finalización",
  forwarded: "Reenviado",
  please_enter_valid_receiver_email_id:
    "Por favor, ingrese un correo electrónico válido del receptor",
  please_enter_first_name: "Por favor ingrese el nombre",
  holiday_update_successfully: "Festivo actualizado con éxito",
  update_attendance: "Actualizar Asistencia",
  edit_leave_rules: "Editar Reglas de Permiso",
  eg_98: "ej: 98",
  select_week: "Seleccionar Semana",
  reason_for_delegation: "Motivo de Delegación",
  please_select_a_past_time: "Por favor, seleccione el tiempo pasada",
  max_file_size: "Tamaño máximo de archivo",
  document_upload_unsuccessful: "Subida del documento fallido",
  search_user: "Buscar usuario",
  local_tax: "Impuesto local",
  deactivate_terms_and_conditions_success:
    "Términos y condiciones desactivados con éxito",
  this_brands_generic_name_is_not_present_in_the_table:
    "El nombre genérico de esta marca no está presente en la tabla",
  initial_posting_message:
    "Aunque su lugar inicial de trabajo será en la dirección indicada anteriormente, puede ser transferido a cualquier lugar del país dentro de la Empresa o a cualquiera de sus asociadas, empresas hermanas o subsidiarias, en cualquier lugar en {{country}} o en el extranjero, ya sea existente hoy o que pueda surgir en el futuro, en cualquier momento, a la sola discreción de la Dirección.",
  official_contact_no_cannot_be_less_than_10_character_long:
    "El número de contacto oficial no puede tener menos de 10 caracteres",
  month: "Mes",
  please_provide_item_type: "Por favor, proporcione el tipo de ítem",
  profile_photo: "Foto de perfil",
  consulting_doctor: "Médico consultante",
  add_new_description: "Agregar Nueva Descripción",
  image_height_must_be_between_50px_and_512px:
    "La altura de la imagen debe estar entre 50 px y 512 px.",
  events_between: "Eventos entre",
  advice: "Consejo",
  accounts_no: "Número de cuenta",
  eg_96_6: "ej: 96.6",
  leave_rule_already_exists: "La regla de permiso ya existe",
  leave_balance_upload_already_exists: "El saldo de licencia ya está cargado",
  group_members: "Miembros del grupo",
  provisional_prescription: "Prescripción provisional",
  new_metrics: "Nuevas métricas",
  Enter_full_screen: "Entrar en Pantalla Completa",
  request_slot_reschedule_successful:
    "Solicitud de reprogramación de plaza exitosa",
  already_have_an_account: "¿Ya tienes una cuenta?",
  please_enter_breakout_room_name:
    "Por favor, ingrese el nombre de la sala de grupo",
  unable_to_process_switch_request_as_no_doctors_of_the_same_specialty_are_currently_online_please_try_again_later:
    "No se puede procesar la solicitud de cambio ya que no hay médicos de la misma especialidad en línea en este momento. Inténtelo de nuevo más tarde...",
  last_payment_amount: "Monto del último pago",
  polling: "Encuestando",
  committee_created_successfully: "Comité creado con éxito",
  salary_head_created_successfully: "Encabezado de salario creado con éxito",
  select_to_add_new_head: "Seleccione para agregar un nuevo encabezado",
  availability_marked_successfully: "Disponibilidad marcada con éxito",
  reopen_postpone_task: "Reabrir Tarea Pospuesta",
  access_updated_success: "Acceso actualizado para {{fileName}} con éxito",
  bank_name: "Nombre del banco",
  net_pay: "Pago Neto",
  itemDescription: "Descripción del ítem",
  fail_to_add_external_user: "Error al añadir usuario externo",
  fail_to_withdraw_leave_request: "Error al retirar la solicitud de permiso",
  expected_cost: "Costo Esperado",
  temparature_must_be_within_ninety_to_one_hundred_six:
    "La temperatura debe estar entre 90 y 106",
  disk_name: "Nombre del disco: ",
  available: "disponible",
  cheque: "Cheque",
  expenditureFor: "Gasto para",
  buyer_details: "Detalles del comprador",
  item_name: "Nombre del Artículo ",
  please_provide_kpi_for: "Por favor, proporcione KPI para {{title}}",
  contact_no: "Número de Contacto",
  modify: "Modificar",
  last_60_days: "Últimos 60 Días",
  please_enter_phone_number: "Por favor, ingrese el número de teléfono",
  origin: "Origen",
  critical: "Crítico",
  save_the_climate: "Salvar el Clima",
  gross_per_month: "Bruto por Mes (GPM)",
  no_notes_available: "No hay notas disponibles",
  this_meeting_needs_at_least_two_participants:
    "Esta reunión necesita al menos 2 participantes",
  quantity: "Cantidad",
  important_notice: "Aviso Importante",
  create_another_ticket: "Crear otro billete",
  doctor_info: "Información del Doctor",
  news: "Noticias",
  please_provide_correct_start_date:
    "Por favor proporcione la fecha de inicio correcta",
  travelEndDate: "Fecha de Fin del Viaje",
  quotation_quantity_error:
    "La cantidad de cotización no puede ser menor o igual a 0 en la fila número {{row}}",
  more: "Más",
  please_enter_user_name: "Por favor ingrese el nombre de usuario",
  error_deleting_file_please_try_again:
    "Error al eliminar el archivo. Por favor, intente de nuevo.",
  latest: "El último",
  activity_wise_completed_tasks: "Tareas Completadas por Actividad",
  more_templates: "Más Plantillas",
  photosensitivity_warning:
    "Advertencia de fotosensibilidad: esta verificación parpadea con diferentes colores. Use precaución si es fotosensible. Algunas personas pueden experimentar convulsiones epilépticas cuando se exponen a luces de colores. Use precaución si usted o alguien en su familia tiene una condición epiléptica.",
  currentCTC: "CTC Actual",
  contract_edited_successfully: "Contrato editado con éxito",
  add_new_payee_name: "Agregar Nuevo Nombre de Beneficiario",
  sputum: "Esputo",
  general_meeting: "Reunión General",
  please_enter_address: "Por favor, ingrese la dirección",
  please_enter_country: "Por favor, ingrese el país",
  employee_position: "Posición del empleado",
  shift_timing_deleted_successFully: "Horario del turno eliminado exitosamente",
  highest_qualification_certificate: "Certificado de Máxima Calificación",
  screen_sharing_on: "Compartiendo pantalla",
  dys: "Dis",
  rtgs_code: "Código RTGS",
  failed_to_activated_salary_component:
    "Error al activar el componente salarial",
  operating_expenditure_added_successfully: "Gasto operativo añadido con éxito",
  patient_switched: "Paciente Cambiado",
  leave_request_withdraw_successfull: "Solicitud de permiso retirada con éxito",
  present_image: "Imagen presente",
  mark_unavailability_for: "Marcar No Disponibilidad para {{eventTitle}}",
  response_date: "Fecha de Respuesta",
  compare_quotation: "Comparar Cotización",
  deactivate_item_success: "Elemento desactivado con éxito",
  enter_description: "Ingrese Descripción",
  task_created_successfully: "Tarea creada con éxito",
  required_documents: "Documentos Requeridos",
  book_appointment: "Reservar cita",
  give_a_reasons_for_edit: "Proporcione una razón para editar",
  upgradeUsingCoupon: "Mejorar usando un cupón",
  schedule_interview: "Programar Entrevista",
  you: "Tú",
  please_type_recipient_name: "Por favor, ingrese el nombre del destinatario",
  termsConditionsError:
    "No se encontraron tipos de Términos y Condiciones para esta organización",
  please_select_specialization: "Por favor, seleccione la especialización",
  enteredRoomMessage:
    "Ha introducido un número de sala, por favor use 'Unirse'",
  booked_event: "Evento Reservado",
  billTo: "Facturar a",
  patient_in_queue: "Paciente en fila",
  item_manufacturer: "Fabricante del Ítem",
  indent_date: "Fecha de Pedido",
  preview: "Vista Previa",
  disclaimer_of_warranties: "RENUNCIA DE GARANTÍAS",
  fixed_term_contracts: "Contratos de Término Fijo",
  advance_more_than_expected:
    "El anticipo no puede ser mayor que el costo esperado",
  please_upload_all_the_required_files:
    "Por favor suba todos los archivos requeridos",
  send_all_for_filter: "Enviar Todo para Filtrar",
  please_type_message: "Por favor escriba un mensaje",
  policy_name: "Nombre de política",
  password_changed_successfully_redirecting:
    "Contraseña cambiada con éxito, redirigiendo...",
  task_transfered: "Tarea transferida",
  add_salary_head_group: "Agregar Cabezal/Grupo Salarial",
  statutory_compliances: "Cumplimientos legales",
  postponed_reason: "Razón de Posposición",
  ecg_test_requisition_created_successfully_please_wait_for_test_results:
    "Requisición de prueba de ECG creada con éxito, por favor espera los resultados de la prueba",
  edit_salary_component: "Editar componente salarial",
  probation_days: "Días de Prueba",
  list_of_template: "Lista de Plantillas",
  salary_message:
    "Según la hoja adjunta, con un CTC total de Rs. {{ctc}} ({{ctcInWords}}) solo por año. El salario y otros beneficios/perquisiciones, que se han distribuido bajo los encabezados mencionados, están sujetos a impuestos según corresponda. A su entera discreción, la Dirección puede considerar cambiar los encabezados o redistribuir las remuneraciones totales bajo varios conceptos.",
  organization_name_is_required: "Se requiere el nombre de la organización",
  please_select_organization: "Por favor, seleccione una organización",
  select_refer_type: "Seleccionar tipo de referencia",
  organization_type: "Tipo de Organización",
  partner: "El socio",
  enter_targets: "Ingrese las objetivos",
  youHaveJoined: "Se ha unido a nosotros en",
  nurse: "Enfermero/a",
  hike_percent: "Porcentaje de Aumento",
  do_you_want_to_cancel_this_leave_request:
    "¿Desea cancelar esta solicitud de permiso?",
  pan_id: "ID de PAN",
  do_you_want_to_deactivate_this_salary_component:
    "¿Desea desactivar este componente salarial?",
  upgrade_now: "Actualizar Ahora",
  belongs_to_your_organization: "Pertenece a su organización",
  message: "Mensaje",
  another_meeting: "Otra reunión",
  minimum_leave_balance_needed_after_encashment:
    "Saldo Mínimo de Permiso Necesario Después del Canje",
  terms_and_conditions_updated_successfully:
    "Términos y condiciones actualizados con éxito",
  hikeRecommendation: "Recomendación de Aumento",
  add_designation: "Agregar designación",
  no_referral_notes: "No hay Notas de Referencia",
  both_sistolic_and_diastolic_value_must_be_present:
    "Deben estar presentes tanto el valor sistólico como el diastólico",
  otp_service_name: "Nombre del servicio OTP",
  create_task: "Crear Tarea",
  an_error_occurred_while_deleting_the_category:
    "Se produjo un error al eliminar la categoría",
  the_password_should_be_at_most_20_characters:
    "La contraseña debe tener como máximo 20 caracteres.",
  an_error_occurred_during_file_upload_Please_try_again_later:
    "Se produjo un error durante la carga del archivo. Por favor, inténtelo más tarde",
  annual_ctc_band: "Costo anual de CTC",
  conduct: "Realizar",
  sys: " Síst",
  linked_task: "Tarea Vinculada",
  select_examination: "Seleccionar Examen",
  budget_head: "Encabezado de Presupuesto",
  could_not_submit_proforma: "No se pudo emitir la factura proforma",
  unit_location: "Ubicación de la Unidad",
  webinar_slug: "Slug del Webinar",
  s_and_o_charges: "Cargos de S&O",
  saving_changes_please_wait: "Guardando cambios, por favor espere...",
  cancellingEvent: "Cancelando {{eventType}}, por favor espere...",
  delivery_date: "Fecha de Entrega",
  document_required: "Documento Requerido",
  please_select_contacts: "Por favor seleccione contactos",
  payroll: "Nómina",
  the_password_should_contain_at_least_one_numeric_letter:
    "La contraseña debe contener al menos una letra numérica.",
  play_audio_message: "Reproducir Mensaje de Audio",
  otp_verification_helper_text:
    "Se enviará un OTP al correo electrónico proporcionado para verificación",
  please_register_your_facial_biometrics_to_mark_attendance:
    "Por favor registre sus datos biométricos faciales para marcar asistencia",
  units: "Unidades",
  tue: "Mar",
  please_select_country_code: "Por favor, seleccione el código del país",
  please_ensure_all_information_in_this_document:
    "Por favor asegúrese de que toda la información en este documento esté ingresada con la máxima precisión y cuidado. Una vez enviado, no se permitirán modificaciones.",
  user_menu_mapped_successfully: "Menú del usuario asignado con éxito",
  other_documents: "Otros documentos",
  expense_type: "Tipo de Gasto",
  search_diseases_name: "Buscar nombre de enfermedades",
  could_not_fetch_generic_data: "No se pudieron obtener los datos genéricos",
  waiting_time: "Tiempo de Espera",
  example_eighty: "ej: 80",
  new_meeting: "Nueva Reunión",
  fail_to_update_leave_rule: "Error al actualizar la regla de licencia",
  me: "Yo",
  view_appointment: "Ver cita",
  omni_presence: "Presencia Omni",
  some_files_could_not_be_deleted_please_try_again:
    "Algunos archivos no pudieron ser eliminados. Por favor, intente de nuevo.",
  please_upload_doctor_signature: "Por favor, suba la firma del doctor",
  mr: "Sr.",
  mode_of_interview: "Modo de Entrevista",
  work_week: "Semana laboral",
  file_uploaded_successfully: "Archivo subido con éxito",
  pmsApprovedBy: "Aprobado por PMS:",
  widowed: "Viudo",
  select_unit: "Seleccionar unidad",
  recommendations: "Recomendaciones",
  provide_expected_cost: "Proporcione el costo esperado",
  patient_info: "Información del Paciente",
  referred_patient: "Paciente Referido",
  city: "Ciudad",
  employee_added_successfully: "Empleado agregado exitosamente",
  opens_5_minutes_before_the_start_time_please_join_after:
    "Se abre 5 minutos antes de la hora de inicio, por favor únete después de",
  fail_to_change_website_template:
    "Error al cambiar la plantilla del sitio web",
  file_upload_max_file_upload_error:
    "Puede cargar un máximo de {{maxFiles}} archivos",
  join_the_zoyel_network_and: "Únete a la Red Zoyel y",
  maximum_carry_forward: "Máximo Acumulado",
  zoom_out: "Alejar",
  fetching_facial_biometrics_data_please_wait:
    "Obteniendo datos biométricos faciales, por favor espere...",
  add_units: "Agregar Unidades",
  password_between_8_and_20_characters: "Contraseña de entre 8 y 20 caracteres",
  completed_task: "Tarea Completada",
  please_select_atleast_one_language:
    "Por favor, seleccione al menos un idioma",
  an_error_occurred_while_updating_slots:
    "Ocurrió un error al actualizar las plazas",
  purchase_order: "Orden de Compra",
  privilege_leave: "Permiso de Privilegio (PL)",
  place_transfer: "Lugar / Transferencia:",
  show_hide: "Mostrar/Ocultar",
  lead_time: "Tiempo de entrega",
  due_date_renewal_date: "Fecha de vencimiento/renovación",
  generate_discount_coupon: "Generar Cupón de Descuento",
  delegate: "Delegar",
  drive_fileSizeError:
    "No se puede subir el archivo {{fileName}}, excede el tamaño máximo de archivo de 100 MB.",
  routine: "Rutina",
  role: "Rol",
  prior_leave_notice: "Aviso de Permiso Anticipado",
  disclaimer_message:
    "Por favor, acepte los términos y condiciones desplazándose hasta el final del descargo de responsabilidad",
  end_webinar_for_all: "Finalizar webinar para todos",
  fax: "FAX",
  ex_pin: "Ej: 700001",
  please_enter_valid_email: "Por favor ingrese un correo electrónico válido",
  select_name: "Seleccionar Nombre",
  component: "Componente",
  fail_to_update_unit: "Error al actualizar la unidad",
  calculation: "Cálculo",
  please_enter_state: "Por favor, ingrese el estado",
  transcript_for_event_title: "Transcripción: {{eventTitle}}",
  choose_column: "Elegir Columna",
  file_upload_extension_allowed: "Extensiones permitidas {{extensions}}",
  branded_medicine: "Medicina de Marca",
  do_you_want_to_delete_this_activity: "¿Desea eliminar esta Actividad?",
  add_a_new_guest: "Agregar un nuevo invitado",
  min_employment_days: "Días Mínimos de Empleo",
  unit_tree: "Árbol de Unidades",
  department_delete_success: "Departamento eliminado con éxito",
  no_position_defined_please_add_a_new_position:
    "No se ha definido ninguna posición. Por favor, agregue una nueva posición",
  select_your_progress_of_task: "Seleccione su progreso de la tarea",
  create_a_webinar: "Crear un Webinar",
  validate_cost_center: "Validar Centro de Costos",
  password_must_contain_at_least_one_digit:
    "La contraseña debe contener al menos un dígito",
  data_submitted_successfully: "Datos enviados con éxito",
  man_power: "Mano de Obra",
  add_organization_storage: "Agregar Almacenamiento de la Organización",
  cvts: "CVTS",
  loi_expiry_date: "Fecha de Expiración del LOI",
  please_select_a_status: "Por favor, seleccione un estado",
  salary_head: "Encabezado de Salario",
  emergency_number: "Número de emergencia",
  please_select_doctor_speciality:
    "Por favor seleccione la especialidad del doctor",
  repeat_until: "Repetir Hasta",
  enter_reagent: "Ingrese Reactivo",
  password_contains_digit: "Por favor incluya al menos un número.",
  duties: "Deberes",
  item_type_added_successfully: "Tipo de elemento añadido con éxito",
  login_to_your_account: "Inicia sesión en tu cuenta",
  termsConditions: "Términos y Condiciones",
  please_select_user_type: "Por favor, seleccione el tipo de usuario",
  item_catagory_added_successfully: "Categoría de elemento añadida con éxito",
  if_half_day_is_applicable_for_this_leave_type:
    "Si el medio día es aplicable para este tipo de permiso",
  other_terms_and_conditions: "Otros Términos y Condiciones",
  failed_to_mark_slot_as_available: "Error al marcar la ranura como Disponible",
  pleaseSelectPaymentMethod: "Por favor seleccione el método de pago",
  maximum_encashment_allowed_during_f_f: "Máximo Canje Permitido durante F&F",
  please_enter_contract_name: "Por favor, ingrese el nombre del contrato",
  please_provide_title: "Por favor, proporcione un título",
  deputation: "Diputación",
  generate_mom: "Generar MOM",
  please_enter_salary_head_name:
    "Por favor, ingrese el nombre del encabezado salarial",
  referral_note: "Nota de Referencia",
  re_upload_sign: "Volver a cargar firma",
  drop_files_here: "Suelte los archivos aquí",
  weekend: "Fin de semana",
  api_driven_menus_for_integrating_existing_tools_or_external_mail_messaging_etc_build_on_your_strengths_and_covers_your_gaps:
    "Menús impulsados por API para integrar herramientas existentes o correo externo, mensajería, etc. Aproveche sus fortalezas y cubra sus brechas",
  files: "Archivos",
  taxss: "Impuesto",
  no_changes_made: "No se realizaron cambios",
  submit: "Enviar",
  banking_details: "Detalles Bancarios",
  accepted: "Aceptado",
  userPreferableTime: "Hora Preferida del Usuario",
  my_calendar: "Mi Calendario",
  check_in_time: "Hora de check-in",
  access_updated_successfully:
    "Acceso actualizado para {{fileName}} con éxito.",
  you_have_been_muted: "Se ha silenciado",
  Leave_calculation_can_be_done_either_based_on_total_number_of_leaves:
    "El cálculo de permisos se puede hacer ya sea en base al Número Total de Días de Permiso, permisos acumulados mensualmente o permisos acumulados anualmente (si algún permiso se acumula mensualmente, ingresa el valor en el cuadro de texto Permiso Acumulado Mensualmente y deja los demás cuadros de texto en blanco, es decir, Número Total de Días de Permiso y Permisos Acumulados Anualmente en blanco)",
  custom_code_cannot_be_more_then_ten_character:
    "El código personalizado no puede tener más de 10 caracteres",
  category_updated_successfully: "Categoría actualizada con éxito",
  payee_name_for_cheque: "Nombre del Beneficiario para Cheque",
  verify: "Verificar",
  guardian_name: "Nombre del Tutor",
  compensation_package:
    ".Su paquete de compensación propuesto se adjunta a continuación.",
  prior_leave_request_is_to_be_made_by_the_employee_for_applying_for_this_leave_type:
    "La solicitud de permiso anticipada debe ser realizada por el empleado para solicitar este tipo de permiso. Por ej., los empleados deben solicitar un tipo de permiso particular al menos un día de anticipación; si el tipo de permiso no requiere notificación anticipada, deja el cuadro de texto abajo en blanco. El número mínimo de días para el aviso anticipado de permisos debe establecerse según la política de la organización. Solo se debe ingresar el número (valor numérico) para esta condición.",
  select_section: "Seleccionar sección",
  confirm: "Confirmar",
  please_enter_valid_amounts_for_budget_heads:
    "Por favor, ingrese cantidades válidas para los conceptos del presupuesto.",
  reassing_task: "Reasignar Tarea",
  registered_address: "Dirección Registrada",
  short_man_power: "Mano de Obra Corta",
  record_audio_note: "Grabar Nota de Audio",
  provide_budget_head: "Proporcione la partida presupuestaria",
  please_upload_a_pdf_file: "Por favor, suba un archivo PDF",
  invoice_date: "Fecha de factura",
  discount_precentage_cannot_be_more_then_hundred:
    "El porcentaje de descuento no puede ser mayor que 100",
  recurring_period: "Período Recurrente",
  message_sent_successfully: "Mensaje enviado con éxito",
  minimum_amount_cannot_be_greater_than_maximum_amount:
    "La cantidad mínima no puede ser mayor que la cantidad máxima",
  no_patient_review_data_found:
    "No se encontraron datos de revisión de pacientes",
  end_meeting: "Finalizar Reunión",
  please_select_current_time: "Por favor seleccione la hora actual",
  reason_for_purchasing_directly: "Razón para Comprar Directamente",
  meeting_files: "Archivos de la reunión",
  complete_collaboration_suite_without_subscribing_to_any_other_provider:
    "Suite completa de colaboración sin necesidad de suscribirse a otro proveedor",
  event_updated_successfully: "Evento actualizado con éxito",
  services_description:
    "Nuestra empresa ofrece software y aplicaciones en la nube, contrapartes móviles, en conjunto denominados 'Servicios'. Estos servicios se pueden utilizar tanto para fines personales como empresariales, ya sea individualmente o dentro de la organización que representa. El acceso a los servicios es posible a través de cualquier navegador de Internet compatible con la plataforma. Es su responsabilidad asegurar el acceso a Internet y adquirir el equipo necesario para utilizar los servicios. Con su cuenta de usuario, tiene la capacidad de crear, modificar, publicar y compartir contenido según su preferencia.",
  terms_and_conditions: "Términos y Condiciones",
  dob: "Fecha de Nacimiento",
  invalid_room_number_room_number_can_have_only_digits:
    "Número de sala inválido, el número de sala solo puede tener dígitos",
  code_of_conduct_message:
    "Se espera que siga las Políticas de Recursos Humanos, las Políticas de Seguridad de la Información y todas las demás políticas de la empresa y que cumpla con el Código de Conducta y el Código Disciplinario de la Compañía. También cumplirá con las Normas y Reglamentos / Órdenes Permanentes de la Compañía vigentes en la actualidad y las que se modifiquen de tiempo en tiempo.",
  task_has_been_cancelled: "La tarea ha sido cancelada",
  please_enter_operating_expenditure_item:
    "Por favor, ingrese el ítem de gasto operativo",
  leave_during_pregnancy_and_after_child_birth:
    "Por ej., Permiso durante el Embarazo y Después del Nacimiento del Niño, el número total de permisos antes y después del evento (Embarazo y Después del Nacimiento) debe ser igual al número total de permisos permitidos para todo el evento",
  pms_approval_request_successs: "Solicitud de aprobación de PMS exitosa",
  please_enter_qualification: "Por favor, ingrese la calificación",
  unit_added_successfully: "Unidad añadida con éxito",
  contract: "Contrato",
  add_new_participants_for_all_related_recurring_events:
    "Agregar nuevos participantes para todos los eventos recurrentes relacionados",
  enter_agenda_description: "Ingrese la descripción de la agenda",
  facial_biometrics_data_found:
    "Datos biométricos faciales encontrados, los detalles del paciente se obtuvieron con éxito",
  taskName: "Nombre de la Tarea",
  eg_12: "ej: 12",
  doctor_canceled_patient_updating_queue:
    "Doctor Canceló al Paciente. Actualizando Fila",
  ex_add_new_terms_and_conditions: "Ej: agregar nuevos Términos y Condiciones",
  contract_owner_name: "Nombre del Propietario del Contrato",
  please_select_reporting_head: "Por favor, seleccione el jefe de reportes",
  you_can_upload_a_maximum_of_five_files: "Puede subir un máximo de 5 archivos",
  enter_agenda_here: "Ingrese la agenda aquí",
  this_is_need_for_supportive_documents:
    "Esto es necesario para documentos de apoyo, agrega nombres de archivos separados por una coma, por ej.: Archivo Uno, Archivo Dos, etc.",
  you_have_not_provided_values_for_some_of_the_parameters_please_provide_value_or_delete_the_parameter:
    "No has proporcionado valores para algunos de los parámetros. Por favor proporcione un valor o elimine el parámetro.",
  indent_details: "Detalles del Pedido",
  please_enter_department: "Por favor, ingrese el departamento",
  zoyel_assistant: "Asistente Zoyel",
  name: "Nombre",
  new_guest_added_successfully: "Nuevo invitado agregado exitosamente",
  moderator: "moderador",
  download_prescription: "Descargar Receta",
  validate: "Validar",
  to: "A",
  indentNo: "Nro. de Pedido",
  product: "Producto",
  selectInterviewerTime:
    "Por favor, Seleccione la Hora Preferida del Entrevistador",
  please_change: "Por favor, cambie",
  minimum_salary_cannot_be_more_than_maximum_salary:
    "El salario mínimo no puede ser mayor que el salario máximo",
  event_date: "Fecha del Evento",
  ticket_resolved_remark: "Comentario de Ticket Resuelto",
  add_cost_center: "Agregar Centro de Costo",
  add_new_bank_account: "Agregar Nueva Cuenta Bancaria",
  view_edit_department: "Ver/Editar departamento",
  avtar_by_ai: "Avatar de IA",
  stop_live_stream: "Detener transmisión en vivo",
  account_suspension_and_termination:
    "En ciertas circunstancias, tenemos la autoridad para suspender o deshabilitar temporalmente su cuenta de usuario, ya sea parcial o completamente, por razones como actividad ilegal sospechada, períodos prolongados de inactividad o solicitudes de agencias gubernamentales o de aplicación de la ley. Si se opone a la suspensión, debe comunicar sus preocupaciones dentro de los treinta días posteriores a la recepción de la notificación en el correo electrónico mencionado a continuación. Si no se plantea ninguna objeción, nos reservamos el derecho de terminar una cuenta de usuario suspendida o deshabilitada después de treinta días. Además, nos reservamos el derecho de terminar su cuenta de usuario y denegar el acceso a cualquier servicio si razonablemente creemos que ha violado el acuerdo, o en caso de problemas técnicos inesperados o discontinuación del servicio. También tiene la opción de solicitar la terminación de su cuenta de usuario. La terminación de su cuenta de usuario implica la denegación del acceso a todos los servicios, la eliminación de la información de su cuenta (incluida la dirección de correo electrónico y la contraseña), y la eliminación de todos los datos asociados con su cuenta de usuario.",
  continuous_absent_days: "Días de Ausencia Continua",
  salary_head_deactivated_successfully:
    "Encabezado de salario desactivado con éxito",
  access_control: "Control de Acceso",
  no_external_user_define_yet: "¡No se ha Definido Ningún Usuario Externo aún!",
  emergency_contact_no: "Número de Contacto de Emergencia",
  select_doctor: "Seleccionar doctor",
  fri: "Vie",
  employee_name: "Nombre del Empleado:",
  participant: "participante",
  test_result_submitted_successfully:
    "Resultado de la prueba enviado con éxito",
  fail_to_publish_website: "Error al publicar el sitio web",
  please_select_title: "Por favor, seleccione el título",
  external_report: "Informe Externo",
  profile_sign_details:
    "Formato de archivo permitido PNG, JPG o JPEG y tamaño entre 10 KB y 200 KB. Dimensiones de la imagen, altura de 50 px a 512 px, con fondo transparente o blanco.",
  appoinment_canceled: "Cita cancelada con éxito",
  payment_info_not_available:
    "La información de pago no está disponible. Por favor, inténtelo de nuevo más tarde.",
  add_remove: "Agregar/Eliminar",
  failed_to_update_leave_balance_upload: "Error al subir el saldo de licencia",
  add_external_user: "Agregar Usuario Externo",
  offer_letter_conditional:
    "Esta Carta de Oferta es condicional, sujeta a que lo anterior sea recibido y verificado por las autoridades competentes (incluida la Verificación Policial). Por favor, firme la copia duplicada de esta carta como muestra de su aceptación y devuélvanosla.",
  contact_number: "Número de contacto",
  do_you_want_to_delete: "¿Desea eliminar?",
  add_procedure: "Agregar procedimiento",
  document_name: "Nombre del Documento",
  private_limited_company: "Sociedad Anónima Privada",
  seller_details: "Detalles del vendedor",
  no_refer_patient_data_is_found:
    "No se encontraron datos del Paciente Referido",
  cgst_amount: "Monto CGST",
  website: "Sitio web",
  experienceInMonth: "Experiencia en Meses",
  no_categories_found: "No se encontraron categorías",
  start_time: "Hora de Inicio",
  template_uploaded_successfully: "Plantilla cargada con éxito",
  sole_proprietorship: "Propiedad Individual",
  please_enter_capital_expenditure_item:
    "Por favor, ingrese el ítem de gasto de capital",
  create_slides: "Crear Diapositivas",
  protocol: "Protocolo",
  left_at: ", Salió a las",
  sl: "Nro.",
  time_space_language: "Tiempo, espacio y lenguaje",
  you_cannot_take_more_leave_than_your_leave_balance:
    "No puede tomar más permisos de los que tiene disponibles",
  medicine: "Medicina",
  payee_upi_ids: "UPI ID del Beneficiario",
  absent_percentage: "Porcentaje de Ausencias:",
  months: "Meses",
  message_forwarded_successfully: "Mensaje reenviado con éxito",
  edit_website: "Editar sitio web",
  add_contacts: "Agregar contactos",
  please_select_correct_date: "Por Favor Seleccione la Fecha Correcta",
  privacyPolicy: "Política de Privacidad",
  relationship: "Relación",
  po_number: "Número de PO",
  tasks: "Tareas",
  please_enter_title_at_index:
    "Por favor, introduzca el título en el índice {{index}}",
  file_size_exceeds_the_limit: "El tamaño del archivo excede el límite (10MB)",
  please_enter_hierarchy_name: "Por favor, ingrese el nombre de la jerarquía",
  no_meeting_agendas_present: "No hay agendas de reunión presentes",
  the_password_should_contain_at_least_one_uppercase_letter:
    "La contraseña debe contener al menos una letra mayúscula.",
  sign_out: "Cerrar Sesión",
  casual_leave: "Permiso Casual (CL)",
  unitName: "Nombre de la Unidad",
  recurring_event: "Evento Recurrente",
  file_invalid_extension:
    "El archivo {{fileName}} tiene una extensión no válida",
  exitFullScreen: "Salir de Pantalla Completa",
  no_department_added_yet: "¡Aún no se ha agregado ningún Departamento!",
  upper_arm_circumference: "Circunferencia del brazo superior",
  please_enter_patient_date_of_birth:
    "Por favor ingrese la fecha de nacimiento del paciente",
  prev_page: "Página Anterior",
  facial_biometrics_added_successfully:
    "Biometría facial agregada a la cuenta con éxito",
  please_select_progress: "Por favor seleccione el Progreso",
  password_length: "La contraseña debe tener al menos 8 caracteres.",
  diet_name: "Nombre de la dieta",
  at_least_one_number_character: "Al menos un carácter numérico",
  generic_name: "Nombre genérico",
  fourth_quarter: "4to Trimestre",
  upload_document: "Subir Documento",
  no_committee_added_yet: "¡Aún no se ha agregado ningún Comité!",
  open_setting: "Abrir Configuración",
  please_enter_correct_email:
    "El correo electrónico que ha ingresado no es válido, por favor ingrese un correo electrónico correcto",
  i_agree: "Estoy de acuerdo *",
  showJD: "Mostrar DP para su posición",
  support_invalid_file_type: "Tipo de archivo inválido de '{{fileName}}'",
  salary_component_activated_successfully:
    "Componente salarial activado con éxito",
  no_rating: "No se seleccionó ninguna calificación",
  add_new_ctc_band: "Agregar nueva banda CTC",
  instant_meeting: "Reunión Instantánea",
  special_bod_meeting: "Reunión Especial del Consejo de Administración",
  re_scheduled_the_meeting: "Reprogramó la reunión",
  add_products: "Agregar Productos",
  please_enter_confirm_password:
    "Por favor, ingrese la confirmación de la contraseña",
  reddit: "Reddit",
  drive_max_drive_storage_exceeded:
    "No se puede subir el/los archivo(s), se excederá el almacenamiento máximo del disco de {{totalStorage}} GB.",
  copy_event_details: "Copiar Detalles del Evento",
  please_enter_a_role: "Por favor, ingrese un rol",
  employee_with: "Empleado con",
  you_have_exceeded_the_current_time_please_select_the_next_time_slot:
    "Ha excedido el tiempo actual. Por favor seleccione el siguiente intervalo de tiempo",
  an_error_occurred_while_updating_the_category:
    "Se produjo un error al actualizar la categoría",
  qualification: "Título",
  do_you_want_to_delete_this_holiday: "¿Desea eliminar este Festivo?",
  practice_start_date: "Fecha de Inicio de Práctica",
  add_new_designation: "Agregar nueva designación",
  please_select_end_time: "Por favor, Seleccione Hora de Fin",
  area_colony: "Área, colonia",
  add_charge: "Agregar Cargo",
  welcomeMessage:
    "Estamos felices de darle la bienvenida a nuestra organización y esperamos una asociación larga y gratificante. No dude en contactar con el Departamento de Recursos Humanos siempre que lo necesite; el Departamento estará encantado de ayudarle.",
  custom_code_cannot_be_less_then_five_character:
    "El código personalizado no puede tener menos de 5 caracteres",
  highest_qualification: "Máxima Calificación",
  timeline: "Cronología",
  fail_to_validate_coupon: "Error al validar el cupón",
  configuration: "Configuración",
  couponApplied:
    "El cupón {{couponCode}} con un descuento del {{discountPercentage}}% ha sido aplicado con éxito",
  slot_duration: "Duración del Intervalo",
  dnWbUnitQty: "Cant. de Unidad DN / WB",
  due_renewal_date_frequency: "Frecuencia de Fecha de Vencimiento/Renovación",
  delete_confirmation: "¿Estás seguro de que quieres eliminar {{name}}?",
  leave_balance: "Saldo de Permiso",
  investigation_admin: "Estudio - Admin",
  initiatedBy: "Iniciado por",
  totalExpenses: "Gastos Totales",
  search_department: "Buscar Departamento",
  operating_expenditure: "Gasto Operativo",
  medication: "Medicación",
  nos: "NOS",
  please_select_languages: "Por favor, seleccione los idiomas",
  choose_a_file: "Elija un archivo",
  no_of_holiday: "Número de Festivos",
  save_critical_right: "Guardar Derecho Crítico",
  register: "Registrarse",
  end_date: "Fecha de Fin",
  please_enter_patient_first_name: "Por favor ingrese el nombre del paciente",
  approvedByUser: "Aprobado por el Usuario:",
  id: "ID",
  insert_atleast_one_item: "Inserte al menos un artículo",
  qty: "Cantidad",
  processing_your_recording: "Procesando tu grabación",
  leave_balance_update_already_exists:
    "El saldo de licencia ya ha sido actualizado",
  start_consultation: "Iniciar Consulta",
  reviewerRating: "Calificación del Revisor",
  grand_total: "Total General",
  please_select_unavailability_reason:
    "Por favor seleccione el motivo de la no disponibilidad",
  account_holder_name: "Nombre del Titular de la Cuenta",
  no_slot_selected: "No se ha seleccionado una franja",
  do_you_want_to_delete_this_leave_rule:
    "¿Desea eliminar esta Regla de Permiso?",
  add_salary_component: "Agregar Componente Salarial",
  concern: "Preocupación",
  discount_precentage_cannot_be_less_then_one:
    "El porcentaje de descuento no puede ser menor que 1",
  select_time_zone: "Seleccionar Zona Horaria",
  new_password_and_confirm_password_should_be_the_same:
    "La nueva contraseña y la confirmación deben ser iguales",
  reject: "Rechazar",
  please_provide_user_note: "Por favor proporcione la nota del usuario",
  salaryStructure: "Estructura salarial",
  i_accept_the_terms_and_conditions: "Acepto los términos y condiciones",
  unit_deactivated_successfully: "Unidad desactivada con éxito",
  please_select_below_issues_you_faces_during_consultation:
    "Por favor, seleccione a continuación los problemas que enfrentó durante la consulta",
  concern_remark: "Comentario de Preocupación",
  otp_verified_successfully: "OTP verificado con éxito",
  unavailable: "no disponible",
  desc_spec: "Descripción / Especificación obligatoria",
  unit_contact_number: "Número de Contacto de la Unidad",
  please_enter_test_name: "Por favor, ingrese el nombre de la prueba",
  please_enter_account_number: "Por favor ingrese el Número de Cuenta",
  delete_experience: "Eliminar Experiencia",
  no_agenda_available: "No hay agenda disponible",
  reference_by_employee: "Referencia por Nombre del Empleado",
  please_wait: "Por favor espera",
  doctor_name: "Nombre del Doctor",
  these_features_are_experimental_it_would_be_best_if_you_reviewed_and_recommended_any_necessary_changes_the_data_has_been_generated_using_ai:
    "Esta es una función de IA experimental que no debe usarse para diagnóstico médico. Sería mejor si revisara y recomendara cualquier cambio necesario. Los datos han sido generados usando IA",
  min_amount: "Monto Mínimo",
  authorized_person_contact_number:
    "Número de contacto de la persona autorizada",
  feb12: "12 de febrero de 2024",
  loading_drive_data: "Cargando datos de la unidad...",
  please_provide_task_description:
    "Por favor proporcione la descripción de la tarea",
  email_address: "Dirección de correo electrónico",
  address: "Dirección",
  receiver_email_id: "Correo Electrónico del Receptor",
  rout: "Vía de Administración",
  personal_contact_no: "Número de contacto personal",
  fileSizeWarning:
    "El archivo '{{fileName}}' debe tener menos de {{maxFileSize}}MB",
  unit_info: "Información de la Unidad",
  clinical_cardiologist: "Cardiólogo clínico",
  delete_face: "Eliminar rostro",
  assistant: "Asistente",
  add_private_notes: "Agregar Notas Privadas",
  eg_12_5: "ej: 12.5",
  cardiologist: "Cardiólogo",
  bug_placeholder:
    "Por favor ingrese una breve descripción del error que está enfrentando",
  view_all: "Ver Todo",
  custom_code: "Código Personalizado",
  please_change_the_title_for_doctor:
    "Por favor, cambie el título a 'Dr.' o 'Prof.' para un Doctor",
  appoinment_rescheduled_successfully: "Cita reprogramada con éxito",
  salary_component_name_is_already_present:
    "El nombre del componente salarial ya existe",
  virtual_background: "Fondo virtual",
  please_fill_corresponding_address_one:
    "Por favor, complete la dirección correspondiente 1",
  fail_to_deactivated_external_user: "Error al desactivar el usuario externo",
  form_data: "Datos del Formulario",
  apply_for_a_leave: "Solicitar un Permiso",
  discountAmount: "Monto del Descuento",
  file_cannot_get_downloaded: "El archivo no se puede descargar",
  desirable_value: "Deseable: < 150",
  failed_to_cancel_the_consultation_please_try_again:
    "Error al cancelar la consulta. Por favor, inténtelo de nuevo.",
  sgst_amount: "Monto SGST",
  task_completed_successfully: "Tarea completada con éxito",
  facebook_link: "Enlace de Facebook",
  sistolic_and_diastolic_pressure_difference_must_be_greater_than_thirty:
    "La diferencia entre la presión sistólica y diastólica debe ser mayor de 30",
  under_process: "En Proceso",
  add_budget: "Agregar Presupuesto",
  could_not_found_diet_list_fetching_it:
    "No se pudo encontrar la lista de dietas. Obteniendo la misma.",
  minimum_twenty_five_gb_is_needed_for_storage_upgrade:
    "Se necesitan al menos 25 GB para la actualización de almacenamiento",
  moderator_grant_rights_success: "Derechos de moderador otorgados con éxito",
  fax_number: "Número de Fax",
  add_new_upi_id: "Agregar Nuevo ID UPI",
  max_length_15: "La longitud máxima es 15",
  intangible: "Intangible",
  drive_maxStorageError:
    "No se puede subir el/los archivo(s), se excederá el almacenamiento máximo de {{storage}} GB",
  please_give_maximum_amount: "Por favor, indique la cantidad máxima",
  budget_head_deactivated_successfully:
    "Encabezado de presupuesto desactivado con éxito",
  all_selected_files_have_been_deleted_successfully:
    "Todos los archivos seleccionados han sido eliminados con éxito",
  ai_avtar_coomiing_soon: "¡Avatar de IA Próximamente!",
  invite_user: "Invitar Usuario",
  iban_number: "Número IBAN",
  maximum_ten_files_can_be_uploaded_at_a_time:
    "Se pueden subir un máximo de 10 archivos a la vez",
  next_page: "Página Siguiente",
  leave_during_pregnancy_and_after_child_birth_total:
    "Por ej., Permiso durante el Embarazo y Después del Nacimiento del Niño, el número total de permisos antes y después del evento (Embarazo y Después del Nacimiento) debe ser igual al número total de permisos permitidos para todo el evento",
  upload_pic: "Subir foto",
  min_8_characters: "Mínimo 8 caracteres",
  disease_name: "Nombre de la Enfermedad",
  add_holiday: "Agregar Festivo",
  please_enter_holiday_name: "Por favor, ingrese el nombre del festivo",
  please_enter_a_reason_for_cancellation:
    "Por favor, ingrese una razón para la cancelación",
  send_invite: "Enviar Invitación",
  change_password: "Cambiar contraseña",
  documents: "Documentos",
  certificates: "Certificados",
  the_webinar_has_ended: "El seminario web ha terminado",
  select_marital_status: "Seleccionar estado civil",
  uploded_files: "Archivos Subidos",
  apps: "Aplicaciones",
  experience_required: "Se requiere Experiencia",
  delete_right: "Eliminar Derecho",
  advanceReceived: "Anticipo Recibido",
  could_not_fetch_data_something_went_wrong:
    "No se pudo obtener los datos. Algo salió mal",
  video: "Video",
  cancel_consultation: "Cancelar Consulta",
  effectivity: "Eficacia",
  no_test_data_provided: "No se proporcionaron datos de prueba",
  back_to_normal_mode: "Volver al Modo Normal",
  please_enter_some_message: "Por favor, ingrese algún mensaje",
  current_event: "Evento Actual",
  download_vendor_quotation: "Descargar Cotización del Proveedor",
  selfEvaluation: "Autoevaluación",
  suggestion_placeholder: "Por favor ingrese aquí su valiosa sugerencia",
  key_responsibilities: "Responsabilidades Clave",
  please_type_your_unavailability_reason:
    "Por favor, escriba el motivo de su no disponibilidad....",
  minimum_leaves: "Permisos Mínimos",
  refer_type: "Tipo de referencia",
  lifestyle_recommendations: "Recomendaciones de estilo de vida",
  no_sign_avalable: "¡No hay firma disponible!",
  please_provide_only_pdf_file: "Por favor, proporcione solo un archivo PDF",
  no_subtitles_present: "No hay subtítulos disponibles",
  please_enter_receiver_name: "Por favor, ingrese el nombre del receptor",
  strengths: "Fortalezas",
  show_leave_details: "Mostrar detalles del permiso",
  please_select_a_date_to_confirm_deactivation_of_this_employee:
    "Por favor, seleccione una fecha para confirmar la desactivación de este empleado",
  coupon_generated_successfully: "Cupón generado con éxito",
  error_deleting_activity_please_try_again:
    "Error al eliminar actividad. Inténtelo de nuevo.",
  contract_added_successfully: "Contrato agregado con éxito",
  deactivate_leave_rule_sucess: "Regla de licencia desactivada con éxito",
  mark_as_working_day: "Marcar como Día Laboral",
  rcpt_amount_after_discount: "Monto de Recibo Después del Descuento",
  please_select_max_rating: "Por favor, seleccione la calificación máxima",
  invite_sent_successfully: "Invitación enviada con éxito",
  enter_title_skill_or_attribute: "Ingrese Título (Habilidad o Atributo)",
  full_address: "Dirección Completa",
  designation: "Designación",
  examination_and_sign: "Examen y signo",
  search_doctor: "Buscar doctor",
  features: "Características",
  password_strength: "Fuerza de la Contraseña",
  termination_clause:
    "Terminación: En caso de que sus servicios sean terminados, se le dará un aviso de {{noticePeriod}} días o se le pagará el salario (salario bruto) correspondiente a {{noticePeriod}} días en lugar de aviso (pago por preaviso). Un aviso similar o salario en lugar de aviso (pago por preaviso) deberá ser proporcionado por usted en caso de que desee dejar los servicios de la Compañía. En caso de conducta indebida o incumplimiento material de este acuerdo, la Compañía puede terminar este Acuerdo con efecto inmediato. En tales casos, no se pagará ninguna compensación al respecto, y no surgirá ni será considerado ningún derecho a incentivos, bonificaciones u otras cantidades similares, que se perderán.",
  add_items: "Agregar Artículos",
  refer: "Referir",
  image_height_details: "La altura de la imagen debe estar entre 50px y 512px",
  doctor_activated_successfully: "Doctor activado con éxito",
  reply: "Responder",
  upload_new_file: "Cargar nuevo archivo",
  add_item_type: "Agregar Tipo de Artículo",
  failed_to_updated_leave_balance_upload:
    "Error al actualizar el saldo de licencia",
  policy_added_successfully: "Política añadida con éxito",
  completion_time: "Hora de Finalización",
  internet_banking: "Banca por Internet",
  start_test: "Iniciar prueba",
  select_payment_mode: "Seleccionar Modo de Pago",
  fail_to_add_item: "Error al añadir el elemento",
  postpone_till: "Posponer Hasta",
  please_enter_ctc: "Por favor, ingrese el CTC",
  click_add_units_button_to_create_new_units:
    "Haga clic en el botón Agregar Unidades para crear nuevas Unidades",
  edit_external_user: "Editar Usuario Externo",
  please_enter_a_valid_percentage:
    "Por favor, ingrese un porcentaje válido (0-100)",
  patient_booked_appointment_details_changes_successfully:
    "Detalles de la cita del paciente cambiados exitosamente",
  tan_id: "ID de TAN",
  analytics: "Analíticas",
  Maximum_number_of_leaves_an_employee_may_apply_at_a_stretch:
    "Número máximo de días de permiso que un empleado puede solicitar de una vez",
  country: "País",
  reason: "Razón",
  pleaseEnterTermsConditionsType:
    "Por favor ingrese el tipo de Términos y Condiciones.",
  upload_report: "Subir Informe",
  terms_message:
    "Por favor, acepte los términos y condiciones desplazándose hasta el final del descargo de responsabilidad",
  review: "Revisión",
  attach_image_for_emailer: "Adjuntar Imagen para el Correo",
  deduction: "Deducción",
  create_new_meeting: "Crear Nueva Reunión",
  create_a_new_event: "Crear un nuevo evento",
  add_or_search_by_mail_id: "Agregar o buscar por correo",
  education: "Educación",
  category_added_successfully: "Categoría añadida con éxito",
  maximum_file_size_allowed: "Tamaño máximo de archivo permitido",
  text: "Texto",
  item_category: "Categoría de Ítem",
  cancel_all_recurring_events: "Cancelar todos los eventos recurrentes",
  new_recurring_event: "Nuevo Evento Recurrente",
  select_type: "Seleccionar Tipo",
  unit_updated_successfully: "Unidad actualizada con éxito",
  recurring_until_empty: "Recurrencia hasta no debe estar vacío",
  restrictiveCovenants: "Pactos restrictivos",
  doctor_switched: "Doctor cambiado",
  evaluation_metrics: "Métricas de evaluación",
  do_you_want_to_edit_this: "¿Deseas editar esto?",
  maximum_file_size: "Tamaño máximo de archivo {{maxFileSize}} MB",
  login: "Iniciar Sesión",
  filtered_by_unread: "Filtrado por no leídos",
  drive_details: "Detalles del Disco",
  blood: "Sangre",
  failed_to_update_otp_service_status:
    "No se pudo actualizar el estado del servicio OTP",
  failed_to_update_employee_bank_details:
    "Error al actualizar los detalles bancarios del empleado",
  please_enter_email: "Por favor, ingrese el correo electrónico",
  audio_note_recorded: "Nota de audio grabada",
  designation_copy_unsuccessful: "Copia de designación fallida",
  risk_factor: "Factor de riesgo",
  candidate_demography: "Demografía del Candidato",
  view_details_of: "Ver detalles de {{title}}",
  set_as_default_shift: "Establecer como Turno Predeterminado",
  enterFullScreen: "Entrar en Pantalla Completa",
  removeFromGuestList: "¿Eliminar a {{userName}} de la lista de invitados?",
  dn_wb_no: "DN / Nro. WB",
  whiteboardOpenedMessage: "{{userName}} ha abierto la pizarra",
  meeting_could_not_be_created: "No se pudo crear la reunión",
  selectInterviewer: "Seleccionar Entrevistador",
  mark_attendance_is_unavailable_as_you_have_denied_location_access_to_this_site_please_allow_access_from_your_browser_settings:
    "El marcado de asistencia no está disponible porque ha denegado el acceso a la ubicación de este sitio. Por favor permita el acceso desde la configuración de su navegador",
  view_booked_appointment: "Ver Cita Reservada",
  company_correspondence:
    "La Compañía se comunicará directamente con usted o a la dirección indicada a continuación:",
  key_already_present: "La clave ya está presente",
  ctc: "CTC",
  download_file: "Descargar archivo",
  participant_with_this_email_already_exists:
    "Ya existe un participante con este correo electrónico",
  polling_show_of_hands: "Votación/Levantamiento de manos",
  profile_signature_uploaded_successfully: "Firma de perfil cargada con éxito",
  targetDeliveryDate: "Fecha de Entrega Objetivo",
  link_task_not_completed_against_this_task:
    "La tarea vinculada no se completó para esta tarea",
  parameter_name: "Nombre del Parámetro",
  veryhigh: "Muy Alto: > 500",
  please_provide_item_name: "Por favor, proporcione el nombre del ítem",
  new_chat: "Nuevo chat",
  liability_statement:
    "En ningún caso el Propietario, sus funcionarios, directores, empleados, agentes, licenciantes y sus respectivos sucesores y cesionarios serán responsables por daños de ningún tipo, incluyendo, sin limitación, cualquier daño directo, especial, indirecto, punitivo, incidental o consecuente, incluyendo, sin limitación, cualquier pérdida o daño de naturaleza, o relacionado con, la pérdida de negocios, información inexacta o cualquier otra pérdida incurrida en conexión con tu uso, mal uso o confianza en el Sitio web o el Contenido, o tu incapacidad para usar el Sitio web, independientemente de la causa y ya sea que surja en contrato (incluyendo incumplimiento fundamental), agravio (incluyendo negligencia) o de otro modo. La limitación anterior se aplicará incluso si el Propietario conocía o debía haber conocido la posibilidad de dichos daños.",
  no_policy_added_yet: "No se ha agregado ninguna política todavía !!",
  please_enter_room_id: "Por favor ingresa el ID de la sala",
  trade_license_no: "Número de Licencia Comercial",
  add_function: "Agregar Función",
  failed_to_send_the_request_for_proposal_please_try_again:
    "Error al enviar la solicitud de propuesta. Por favor, intente nuevamente.",
  transportCost: "Costo de Transporte",
  searchDoctor: "Buscar Doctor",
  sick_leave: "Baja por enfermedad",
  mic: "Micrófono",
  fields_updated_successfully: "Campos actualizados con éxito",
  save_for_this_future_loi: "Guardar para este futuro LOI",
  click_add_terms_and_condition_button_to_create_new_terms_and_condition:
    "Haga clic en el botón Agregar términos y condiciones para crear nuevos términos y condiciones",
  please_select_a_position: "Por favor, seleccione un puesto",
  all_updates_have_been_successfully_applied:
    "Todas las actualizaciones se han aplicado exitosamente.",
  add_services: "Agregar Servicios",
  please_enter_a_valid_patient_email_id:
    "Por favor ingrese un correo electrónico válido del paciente",
  showKRA: "Mostrar ACR para su posición",
  approver_message: "{{approverName}} es el aprobador de la tarea.",
  fail_to_upload_file: "Error al subir el archivo",
  please_enter_address_two: "Por favor, ingrese la dirección 2",
  employee_id: "ID de empleado",
  proceed_to_book_investigation: "Proceder a reservar estudio",
  analytics_description:
    "Administra monitoreando fácilmente lo que está sucediendo y asigna recursos, tiempo y dinero de manera más efectiva.",
  estimated_wait_time: "Tiempo Estimado de Espera",
  diastolic_pressure_cannot_be_empty:
    "La presión diastólica no puede estar vacía",
  refer_to_other_speciality: "Referir a otra especialidad",
  typeInterviewerName: "Por favor, Escriba el Nombre del Entrevistador",
  document_list: "Lista de Documentos",
  marriage_leave: "Permiso de Matrimonio",
  place_of_supply: "Lugar de suministro",
  hide_leave_details: "Ocultar detalles del permiso",
  frequency_timing: "Frecuencia/Horario",
  branch_name: "Nombre de la Sucursal",
  click_add_component_button_to_create_new_salary_component:
    "Haga clic en el botón Agregar componente para crear un nuevo componente salarial",
  select_relationship: "-- Seleccionar Relación --",
  support_tooltip_message:
    "Tamaño máximo de archivo permitido: {{maxSize}}MB, Extensiones permitidas: {{extensions}}",
  please_enter_the_ifsc_code: "Por favor ingrese el código IFSC",
  external_user_deactivated_successfully:
    "Usuario externo desactivado con éxito",
  truck_no: "Nro. de Camión",
  add_kra_data: "Agregar Datos de ACR",
  blood_group: "Grupo Sanguíneo",
  select_time: "Seleccionar Hora",
  about_us: "Sobre Nosotros",
  select_system: "Seleccionar Sistema",
  n_a: "N/A",
  leave_rule_added_successfully: "Regla de permiso añadida con éxito",
  max_amount: "Monto Máximo",
  filter_patient: "Filtrar Paciente",
  methodMessage: "Método: {{method}}",
  life_style_name: "Nombre del estilo de vida",
  confidentiality_details:
    "Mantendrá todos los secretos comerciales del empleador de manera confidencial en todo momento, durante y después del término de su empleo. Los secretos comerciales del empleador incluyen cualquier información relacionada con los clientes, proveedores, finanzas, estudio, desarrollo, procesos de fabricación, u otra información técnica o comercial del empleador.",
  pleaseEnterHeading:
    "Por favor ingrese el encabezado de los Términos y Condiciones.",
  failed_to_convert_file: "Error al convertir el archivo.",
  lab_technician: "Técnico de Laboratorio",
  disagree: "No estar de acuerdo",
  marked_as_default_shift: "Marcado como Turno Predeterminado",
  physical: "Presencial",
  share_link: "Compartir enlace",
  eg_80: "ej: 80",
  please_select_monthly_period: "Por favor, seleccione el período mensual",
  please_enter_condition: "Por favor ingrese la condición",
  sistolic_and_diastolic_pressure_difference_must_be_greater_than_30:
    "La diferencia entre la presión sistólica y diastólica debe ser mayor de 30",
  effective_date: "Fecha de Efectividad",
  order_to: "Ordenar a",
  mail_id_exists_already_please_login_sign_in:
    "El ID de correo ya existe, por favor inicie sesión",
  select_spoken_languages: "Seleccionar idiomas hablados",
  productivity_description:
    "Crea tu presencia en la web con unos pocos clics. No necesitas dominios, alojamiento web o infraestructura de TI.",
  expDate: "Fecha de Exp.",
  subscribeTitle: "Suscribirse",
  annual_general_meeting: "Reunión General Anual",
  select_rights: "Seleccionar Derechos",
  could_not_fetch_generic_ranking: "No se pudo obtener el ranking genérico",
  click_add_button_to_create_new_activity:
    "Haga clic en el Botón de Agregar para crear una Nueva Actividad",
  Chat: "Charlar",
  please_give_some_comments: "Por favor, dé algunos comentarios",
  cancellation_reason_cannot_be_empty:
    "El motivo de la cancelación no puede estar vacío",
  please_provide_food_name: "Por favor proporcione el nombre del alimento",
  add_new_skills_or_attributes: "Agregar Nuevas Habilidades o Atributos",
  ce_items_added: "Ítems de Gasto de Capital Agregados",
  healthcare_config: "Configuración de atención médica",
  deactivate_health_care_professional:
    "Eliminar Profesional de Atención Médica",
  past_salary: "Salario Anterior",
  refer_by: "Referido Por",
  consultation_ended: "Consulta Finalizada",
  selectEndTime:
    "Por favor, seleccione la 'Hora de finalización' para {{dayName}}",
  contactNo: "Número de contacto",
  an_error_occurred_while_adding_the_category:
    "Se produjo un error al añadir la categoría",
  paymentAgainstAdvance: "Pago Contra Anticipo",
  profile_image_upload: "Cargar imagen de perfil",
  leave_end_webinar: "Salir/Finalizar Webinar",
  recordGrowth: "Zoyel One preparado para un crecimiento récord en 2024",
  proforma_invoice_issued_successfully: "Factura proforma emitida con éxito",
  view_created_ticket_details: "Ver detalles del ticket creado",
  exp_date: "Fecha de Exp.",
  ex_employe_org_com: "ej: empleado@org.com",
  copyright: "Derechos de Autor",
  stock_qty: "Cantidad de Existencias",
  phone_number: "Número de Teléfono",
  selectPositionEffectiveDate:
    "Por favor, seleccione tanto la Posición como la Fecha de Efectividad para la Posición Adicional en la Posición Adicional {{index}}",
  diagnosis_condition: "Diagnóstico/Condición",
  weight: "Peso",
  rcpt_amount: "Monto de Recibo",
  canceled_event: "Evento Cancelado",
  do_you_want_to_delete_this_terms_and_conditions:
    "¿Desea eliminar estos Términos y Condiciones?",
  detail_the_concern: "Detallar la Preocupación",
  grant: "Otorgar",
  purchase_order_preview: "Vista Previa de la Orden de Compra",
  invalidFileType: "Tipo de archivo inválido de '{{fileName}}'",
  height: "Altura",
  no_patient_document_data_is_present:
    "No hay datos de documentos de paciente presentes",
  face_details_found_please_associate_face_with_the_provided_data:
    "Detalles faciales encontrados, asocie la cara con los datos proporcionados",
  please_provide_item_description:
    "Por favor, proporcione la descripción del ítem",
  doctor_image: "Imagen del Doctor",
  skills_and_attributes: "Habilidades y Atributos",
  please_provide_task_name: "Por favor proporcione el nombre de la tarea",
  my_accounts: "Mis Cuentas",
  enter_skills_or_attributes: "Ingrese Habilidades o Atributos",
  add_expenditure_power: "Agregar Poder de Gasto",
  orgType: "Tipo de organización",
  recording: "Grabación",
  investigations_from_prescriptions: "estudios de prescripciones",
  select_meeting: "Seleccionar Reunión",
  user_marked_unavailable: "Usuario marcado como no disponible",
  address_change_notification:
    "En caso de que haya algún cambio en su dirección, deberá notificarlo a la Compañía por escrito dentro de {{days}} días desde la fecha de dicho cambio.",
  submit_in_caps: "ENVIAR",
  roles_added_successfully: "Roles añadidos con éxito",
  Apps: "Aplicaciones",
  select_hospital: "Seleccionar hospital",
  task_has_been_closed: "La tarea ha sido cerrada",
  failed_to_deactivated_salary_component:
    "Error al desactivar el componente salarial",
  selectedPatient: "(Paciente Seleccionado)",
  manage_by_easily_monitoring_what_is_happening_and_allocate_resources_time_money_more_effectively:
    "Gestione fácilmente monitoreando lo que sucede y asigne recursos, tiempo y dinero de manera más efectiva",
  file_attached: "Archivo adjunto",
  checking_facial_biometrics_uniqueness:
    "Verificando la unicidad de biometría facial...",
  patientName: "Nombre del Paciente",
  view_appointments: "Ver Citas",
  contact_info: "Información de Contacto",
  grnDetails: "Detalles de GRN",
  drive_file_already_present:
    "{{fileName}}.{{fileExt}} ya está presente. Por favor, cree un archivo",
  please_enter_the_reason_for_rescheduling_the_meeting:
    "Por favor, ingrese el motivo de la reprogramación de la reunión",
  video_camera: "Cámara de video",
  costCenter: "Centro de Costos",
  menus: "Menús",
  file_size_cannot_exceed_five_mb:
    "El tamaño del archivo no puede exceder los 5 MB",
  fail_to_sign_up: "Error al registrarse",
  no_data_found: "No se encontraron datos",
  send_feedback: "Enviar comentario",
  dismiss_as_admin: "Destituir como administrador",
  submitting_details_please_wait: "Enviando detalles, por favor espere...",
  an_error_occurred_while_updating_the_data:
    "Ocurrió un error al actualizar los datos.",
  deputation_message:
    "Puede ser enviado en comisión de servicio a cualquier otra organización en cualquier parte del país que esté bajo la misma gestión o bajo diferentes gestiones (Existente o Nueva). La negativa a aceptar dicha comisión podría resultar en medidas disciplinarias en su contra.",
  document_uploaded_successfully: "Documento subido con éxito",
  reviewer_rating: "Calificación del Revisor",
  please_enter_designation_name: "Por favor, ingrese el nombre del cargo",
  terms_and_conditions_created_successfully:
    "Términos y condiciones creados con éxito",
  you_cannot_record_for_more_than_15_secs:
    "No puedes grabar por más de 15 segundos",
  add_internal_user: "Agregar Usuario Interno",
  select_organization: "Seleccionar Organización",
  enter_sign: "Ingrese signo",
  facial_biometrics_data_found_patient_details_fetched_successfully:
    "Datos biométricos faciales encontrados, los detalles del paciente se obtuvieron con éxito",
  search_investigations: "Buscar estudios",
  no_coupon_added_yet: "¡Aún no se ha agregado ningún Cupón!",
  expenditure: "Gasto",
  present: "Presente",
  make_convener: "Hacer Moderador",
  temperature_range_error_in_fahrenheit:
    "La temperatura debe estar entre 90 y 106 grados Fahrenheit",
  view_edit_departments: "Ver/Editar departamentos",
  permission_for_dashboard_modules:
    "Permiso para los módulos del tablero no encontrado, por favor contacta a tu Administrador",
  traveling: "Viajes",
  bill_to: "Facturar a",
  in: "en",
  official_phone_no: "Teléfono Oficial",
  loi_candidate_demography: "Demografía del Candidato LOI",
  medical_history: "Historial médico",
  list_of_products: "Lista de Productos",
  on_clicking_continue_this_data_will_be_deleted_and_the_new_data_with_facial_biometrics_will_be_created:
    "Al hacer clic en continuar, estos datos serán eliminados y se crearán nuevos datos con biometría facial",
  please_select_quarterly_period: "Por favor, seleccione el período trimestral",
  single: "Soltero",
  non_government_organization: "Organización No Gubernamental (ONG)",
  add_roles: "Agregar Roles",
  room_name: "Nombre de la Sala",
  no_patient_found: "No Se Encontró Paciente",
  add_vendor_t_and_c: "Agregar Términos del Proveedor",
  file_size_error: "Sube un archivo de menos de {{maxFileSize}}MB",
  please_get_vitals: "Por favor, obtenga signos vitales",
  test_group: "Grupo de Pruebas",
  feb6: "6 de febrero de 2024",
  restrictive_covenants:
    "Cláusulas Restrictivas: Correspondencia y dirección para comunicación:",
  no_departments_available: "No hay departamentos disponibles",
  patient_documents: "Documentos del paciente",
  branch_office: "Oficina Sucursal",
  reschedule_appointment: "Reprogramar Cita",
  phermacy_copy_of_rx: "Copia de farmacia de Rx",
  profile_image: "Imagen de perfil",
  leave_rule_update_successfully: "Regla de licencia actualizada con éxito",
  organization_type_is_required: "Se requiere el tipo de organización",
  employee_pay_slip: "Recibo de Pago del Empleado",
  holiday_date: "Fecha del Festivo",
  fail_to_add_leave_rule: "Error al añadir la regla de permiso",
  file_upload_size_exceeded:
    "'{{fileName}}' el archivo debe ser menor de {{maxSize}}MB",
  maximum_encashment_allowed_during_fandf_cannot_be_more_than_three_digit:
    "El máximo de canje permitido durante el F&F no puede ser más de 3 dígitos",
  grn_info: "Información de GRN",
  remove_for_all_recurring_events:
    "Eliminar para todos los eventos recurrentes",
  access_permission: "Permiso de Acceso",
  edit_unit: "Editar Unidad",
  no_of_slots: "No. de ranuras",
  specifics_max_characters: "Especificaciones (máximo 2000 caracteres)",
  password_and_confirm_password_does_not_match:
    "La contraseña y la confirmación de la contraseña no coinciden",
  registered_office_address: "Dirección de la oficina registrada",
  roles_added: "Roles Agregados",
  clinical_psychologist: "Psicólogo clínico",
  seven_days: "7 Días",
  edit_medication: "Editar medicación",
  edit_committee: "Editar Comité",
  please_provide_investigation_name:
    "Por favor proporcione el nombre de la estudio",
  add_user: "Agregar usuario",
  exclude_me: "Exclúyeme",
  shift_marked_as_default_successfully:
    "Turno marcado como predeterminado exitosamente",
  face_details_not_found_please_associate_face_with_the_provided_data:
    "Detalles faciales no encontrados, asocie la cara con los datos proporcionados",
  please_select_department_name:
    "Por favor, seleccione el nombre del departamento",
  dear: "Estimado/a",
  minimum_number_of_leaves_an_employee_may_apply_for_a_particular_leave_type:
    "Número mínimo de días de permiso que un empleado puede solicitar para un tipo de permiso particular",
  audio_message: "Mensaje de Audio",
  tax_no: "Número de impuestos",
  template_added_successfully: "Plantilla añadida con éxito",
  current: "Actual",
  preferable_interview_time: "Hora Preferible para la Entrevista",
  enter_name: "Ingresa un nombre",
  organization_email_id: "Correo Electrónico de la Organización",
  the_provided_facial_biometrics_are_associated_with_this_account:
    "Los datos biométricos faciales proporcionados están asociados con esta cuenta",
  noAvailableDoctorsError:
    "No se pudo procesar la solicitud de cambio ya que no hay doctores de la misma especialidad disponibles, intente nuevamente más tarde...",
  error_adding_activity_please_try_again:
    "Error al agregar actividad. Inténtelo de nuevo.",
  emergency_phone_no: "Teléfono de Emergencia",
  attendance: "Asistencia",
  consulting_start_time: "Hora de inicio de consulta",
  time_ranges_overlap:
    "Los rangos de tiempo se superponen para {{dayName}} en la semana {{week}}",
  add_item: "Agregar Ítem",
  create_your_web_presence_with_a_few_clicks_no_need_for_domains_webhosting_or_it_infra:
    "Cree su presencia web con unos pocos clics. No necesita dominios, alojamiento web o infraestructura de TI",
  ok: "Aceptar",
  not_relevant_to_me: "No es relevante para mí",
  password_should_contain: "La Contraseña debe contener",
  low: "Baja",
  payable_days: "Días Pagables",
  click_add_book_consultation_button_to_create_new_patient_queue:
    "Haga clic en el botón de reservar consulta para crear una nueva line de pacientes",
  please_provide_name: "Por favor, proporcione un nombre",
  change_avtar: "Cambiar Avatar",
  numbers_of_effected_employees: "Cantidad de empleados afectados",
  select_recurring_period: "Seleccione el período de recurrencia",
  submitFeedback: "Enviar Comentarios",
  day: "Día",
  surgery_date: "Fecha de cirugía",
  choose_your_own_avtar: "Elige Tu Propio Avatar",
  numbers_of_employees_who_joined_30_days_before_applicable_join_date:
    "Cantidad de empleados que ingresaron 30 días antes de la fecha aplicable",
  past_illness: "Enfermedad pasada",
  guests: "Invitados",
  open_folder: "Abrir Carpeta",
  invoice_no: "Número de factura",
  no_appointments_found: "No se encontraron citas",
  component_category: "Categoría del Componente",
  please_enter_password: "Por favor, ingrese la contraseña",
  fail_to_add_budget: "Error al añadir el presupuesto",
  select_issue: "Seleccionar Problema",
  remove_access: "Eliminar Acceso",
  granted: "Otorgado",
  challan_no: "No. Challan",
  shift: "Turno",
  goods: "Bienes",
  click_to_stop: "Haz clic para detener",
  shortlist: "Preseleccionar",
  sign_in: "Iniciar Sesión",
  manage_your_files: "Gestionar Tus Archivos",
  on_payroll: "En Nómina",
  please_type_your_reason: "Por Favor Escribe Tu Razón",
  add_new_targets: "Agregar Nuevas Metas",
  advise_higher_facility: "Aconsejar instalación de mayor nivel",
  shift_list: "Lista de Turnos",
  File_fileDisplayName: "Archivo {{fileDisplayName}}",
  da_ta: "DA/TA",
  duties_message:
    "En su debido momento se le proporcionará un cronograma de sus amplias funciones y responsabilidades. Sin embargo, la empresa se reserva el derecho de asignarle otras funciones y responsabilidades que se consideren aconsejables en interés de la empresa. En su capacidad como {{position}}, informará al {{reportingHead}} o a cualquier otro funcionario designado por él o ella.",
  fill_the_form: "Rellenar el Formulario",
  please_select_new_head: "Por favor seleccione Nueva Cabeza",
  estimated_rate: "Tasa Estimada ",
  transport_co: "Compañía de Transporte",
  emp_salary_component_success:
    "Componente salarial del empleado añadido con éxito",
  add_agenda: "Agregar agenda",
  click_add_contract_button_to_create_new_contract:
    "Haga clic en el Botón Agregar Contrato para crear un nuevo Contrato",
  Profile: "Perfil",
  website_template_change_successfully:
    "Plantilla del sitio web cambiada con éxito",
  approved_by: "Aprobado por",
  medical_report:
    "Su nombramiento está sujeto a la presentación de un informe médico de un médico registrado que certifique su aptitud para el trabajo.",
  could_not_add_template: "No se pudo agregar la plantilla.",
  the_password_should_contain_atleast_one_special_character:
    "La contraseña debe contener al menos un carácter especial.",
  add: "Agregar",
  referenceNumber: "Número de Referencia",
  cannotEditEvent:
    "No puede editar el {{eventType}} dentro de los 5 minutos o después de la hora de inicio del {{eventType}}",
  pin: "Código postal",
  virtual_presence: "Presencia Virtual",
  title_already_present: "El título ya está presente",
  verified: "Verificado",
  budget_added_successfully: "Presupuesto añadido con éxito",
  more_slots: "Más Horarios",
  reschedule_date: "Reprogramar fecha",
  microphones: "Micrófonos",
  disable: "Deshabilitar",
  discount_precentage: "Porcentaje de Descuento",
  rating: "Calificación",
  no_employee_payroll_added_yet:
    "¡No se ha Agregado Ninguna Nómina de Empleados aún!",
  consultation_cannot_be_cancelled: "La consulta no puede ser cancelada",
  select_option_for: "Por favor, seleccione una opción para {{metricName}}",
  Consultation_canceled_successfully: "Consulta cancelada con éxito",
  job_description: "Descripción del Puesto",
  copy_positions: "Copiar posiciones",
  task_management: "Gestión de Tareas",
  select_salary_component: "Seleccionar Componente Salarial",
  selectDoctorError: "Por favor seleccione un doctor.",
  please_check_mark_as_unavailable_before_proceeding:
    "Por favor, revise Marcar como No Disponible antes de continuar",
  same_as_permanent_address: "Igual que la Dirección Permanente",
  please_enter_a_valid_file_name_special_characters_are_not_allowed:
    "Por favor, ingrese un nombre de archivo válido, no se permiten caracteres especiales",
  assigned_by: "Asignado por",
  enter_uom: "Ingresar UOM",
  reschedule_event: "Reprogramar Evento",
  group_created_successfully: "Grupo creado con éxito",
  update_name: "Actualizar nombre",
  from_first_day_of_week_before_removing_it_from_working_day:
    "del Primer Día de la Semana antes de eliminarlo del día laboral",
  dueDate: "Fecha de Vencimiento",
  click_add_external_user_button_to_create_new_external_user:
    "Haga clic en el Botón Agregar Usuario Externo para crear un nuevo Usuario Externo",
  please_select_salary_head: "Por favor seleccione el concepto salarial",
  license_issuing_authority: "Escuela de egreso",
  re_sync_needed: "(se necesita re-sincronización)",
  limitation_of_liability: "LIMITACIÓN DE RESPONSABILIDAD",
  add_people: "Agregar Personas",
  you_cannot_select_more_than_three_languages:
    "No puedes seleccionar más de 3 idiomas",
  fileSizeError: "El archivo '{{fileName}}' excede el límite de tamaño (5MB)",
  please_give_minimum_amount: "Por favor, indique la cantidad mínima",
  reschedule_reason: "Motivo de reprogramación",
  an_error_occurred_while_deactivating_the_account:
    "Ocurrió un error al desactivar la cuenta",
  leave_request_Cancel_successfull: "Solicitud de permiso cancelada con éxito",
  salary_in_lieu_of_notice: "Salario en Lugar de Aviso (en salario de meses)",
  client_meeting: "Reunión con Cliente",
  upload_document_if_any: "Subir Documento si lo hay",
  template_added: "Plantilla Agregada",
  event: "Evento",
  create_your_organization_add_people_assign_departments_functions_and_go_live:
    "Cree su organización, agregue personas, asigne departamentos y funciones, y comience",
  salary_head_updated_successfully:
    "Encabezado de salario actualizado con éxito",
  an_error_occurred_while_deactivated_salary_component:
    "Se produjo un error al desactivar el componente salarial",
  cannotRemovePatient:
    "No puede eliminar este paciente ya que es el paciente seleccionado actualmente",
  meetings_phygital: "Reuniones - Figital",
  past_illnesses: "Enfermedades Pasadas",
  add_statutory_and_insurance: "Agregar Estatutario y Seguro",
  please_select_at_least_one_employee_status:
    "Por favor, seleccione al menos un estado del empleado",
  easily_create_tasks_and_monitor_them_remove_management_overheads:
    "Cree tareas fácilmente y monitoree su progreso. Elimine la sobrecarga de gestión",
  view_meeting_summary: "Ver resumen de la reunión",
  update_your_photo_and_personal_details:
    "Actualiza Tu Foto y Detalles Personales",
  please_select_rights: "Por favor, seleccione los derechos",
  you_have_been_unmuted: "Se te ha activado el sonido",
  please_select_expense_type: "Por favor, seleccione el tipo de gasto",
  price: "Precio",
  please_check_the_email_id: "Por favor, verifique el correo electrónico",
  add_component_category: "Agregar Categoría del Componente",
  please_select_percentage_of_work:
    "Por favor seleccione el porcentaje de trabajo",
  success_please_continue_to_book_consultation:
    "Éxito, por favor continúe para reservar consulta",
  search_activity: "Buscar Actividad",
  sgst: "SGST",
  make_admin_successfully: "Administrador asignado con éxito",
  add_banking_details: "Agregar Detalles Bancarios",
  enter_doctor_name: "Ingrese el nombre del doctor",
  is_advance_needed: "¿Se Necesita Adelanto?",
  update_cost_center: "Actualizar Centro de Costos",
  event_end_time: "Hora de Finalización del Evento",
  special_character: "Caracter especial",
  delayed_task: "Tarea Retrasada",
  ctc_after_hike: "CTC después del Aumento:",
  did_not_found_terms_and_conditions_types_for_this_organization:
    "No se encontraron tipos de términos y condiciones para esta organización",
  spoken_language_updated_successfully:
    "Idioma hablado actualizado correctamente",
  duplicate_capital_expenditure_item_please_add_a_new_item:
    "Ítem de gasto de capital duplicado. Por favor, añada un nuevo ítem",
  reporting_manager: "Gerente informante",
  event_created_successfully: "Evento creado exitosamente",
  last_synced: "Última sincronización:",
  t_and_c_type: "TIPO DE T&C",
  patient_queue: "Fila de pacientes",
  pms_period_from: "Período de PMS desde",
  experience_in_month: "Experiencia en Meses",
  speakers: "Altavoces",
  at_least_one_special_character: "Al menos un carácter especial",
  gpm_ex: "GPM Ej: 20000",
  meeting_convened_time: "Hora de convocatoria de la reunión",
  years: "Años",
  board_of_director_meeting: "Reunión del Consejo de Administración",
  you_cannot_record_audio_for_more_than_120_sec:
    "No puedes grabar audio por más de 120 segundos",
  invalid_file_type_warning: "Tipo de archivo inválido de '{{fileName}}'",
  weekend_absent: "Fin de Semana (Ausente)",
  fail_to_add_emply_salary_component:
    "Error al añadir el componente salarial del empleado",
  title: "Título",
  timing: "Tiempo",
  department_update_error: "Error al actualizar el departamento",
  hold_board_agm_meetings_issue_agenda_take_polls_record_them_keep_track_of_compliances:
    "Realice reuniones de Junta/AGM, emita agenda, tome votaciones, regístrelas. Mantenga un seguimiento de los cumplimientos",
  first_name: "Nombre",
  choose_martial_status: "Elige Estado Civil",
  pleaseWait: "Por favor espere...",
  mark_as_available: "Marcar como Disponible",
  select_all_for_unavailability: "Seleccionar Todo Para No Disponibilidad",
  please_select_t_and_c_for: "Por favor, seleccione T&C para",
  fail_to_submit_prescription: "Error al enviar la receta",
  sign_up: "Registrarse",
  day_wise_completed_task: "Tarea Completada por Día",
  booking_date_time: "Fecha y hora de reserva",
  add_manufacturer: "Agregar Fabricante",
  socials: "Redes Sociales",
  please_select_leave_notice_period:
    "Por favor, seleccione el período de notificación de permiso",
  invalid_employee_id_it_should_start_with_uppercase_letters_and_end_with_numbers:
    "ID de empleado no válido. Debe comenzar con letras mayúsculas y terminar con números.",
  latitude: "Latitud",
  enter_percentage: "Ingresar Porcentaje (%)",
  compliances: "Cumplimientos",
  please_select_activity: "Por favor seleccione actividad",
  please_enter_a_valid_email: "Por favor ingrese un correo electrónico válido",
  other_observation_field_cannot_be_empty:
    "El campo de otras observaciones no puede estar vacío",
  specialization: "Especialización",
  po_no: "No. PO",
  is_tangible: "Es Tangible",
  probation_confirmation: "Período de prueba / Confirmación",
  linkedin_link: "Enlace de LinkedIn",
  processing_please_wait: "Procesando, por favor espere...",
  syncing_please_wait: "Sincronizando, por favor espere...",
  agreement_modification:
    "Nos reservamos el derecho de modificar este acuerdo, según sea necesario. Es su responsabilidad mantenerse actualizado sobre los cambios en el acuerdo. Si tiene alguna pregunta o inquietud sobre los términos y condiciones establecidos en este acuerdo, no dude en ponerse en contacto con nosotros. Puede contactarnos a info@zoyel.one. Estamos disponibles para abordar cualquier consulta o aclaración que pueda necesitar.",
  fifteen_days: "15 Días",
  run_differential_diagnosis: "Realizar diagnóstico diferencial",
  estimated_value: "Valor Estimado",
  view_report: "Ver informe",
  N_A: "N/A",
  please_enter_terms_and_condition: "Por favor, ingrese términos y condiciones",
  current_organization_required:
    "Por favor, proporcione el nombre de la organización actual",
  please_enter_address_one: "Por favor, ingrese la dirección 1",
  advance_needed: "Anticipo Necesario",
  you_cannot_select_yourself: "No puede seleccionarse a sí mismo",
  activity: "Actividad",
  select_evaluation_metrics: "Por favor, seleccione métricas de evaluación.",
  board_Member: "Miembro de la Junta",
  matched_face: "Rostro coincidente",
  add_Items: "Agregar Elementos",
  watch_out_for: "Atención a",
  organization_common_name: "Nombre común de la organización",
  country_code: "Código de País",
  remark: "Observación",
  webinar: "Webinar",
  reference_details: "Detalles de referencia",
  search_name_to_add_contact: "Buscar nombre para agregar contacto",
  associated_face: "rostro asociado",
  file_name: "Nombre del Archivo",
  upi: "UPI",
  please_enter_valid_emailid: "Por favor, ingrese un correo electrónico válido",
  downloading_files_please_wait_and_do_not_close_the_page:
    "Descargando archivos, por favor espere y no cierre la página...",
  appointment_letter_note:
    "Se le emitirá una Carta de Nombramiento formal al unirse a la organización, sujeto al cumplimiento de la titulación académica, la experiencia laboral y otros datos proporcionados en su currículum que nos envió.",
  no_patient_queue: "No hay fila de pacientes",
  advance_payment_in_percentage: "Pago Anticipado (en %)",
  search_leave_rules: "Buscar Reglas de Permiso",
  please_select_t_and_c_type: "Por favor, seleccione el tipo de T&C",
  sub_total: "Subtotal",
  item_already_selected_from_another_vendor:
    "Artículo ya seleccionado de otro proveedor",
  meeting_details: "Detalles de la reunión",
  enter_room_number: "Introducir número de sala *",
  conduct_investigation: "Realizar estudio",
  unit_queue: "Fila de unidad",
  bic_code: "Código BIC",
  select_bill_to: "Seleccione a quién facturar",
  an_error_occurred_while_updating_employee_bank_details:
    "Ocurrió un error al actualizar los detalles bancarios del empleado",
  house_no_building_name: "Número de Casa, Nombre del Edificio",
  accuracy:
    "El Sitio web o el Contenido será preciso, completo, actual, confiable, oportuno o adecuado para cualquier propósito particular.",
  highest_qualification_institute: "Instituto de Máxima Calificación",
  please_provide_notice_period:
    "Por favor, proporcione el período de notificación",
  submit_ticket: "Enviar ticket",
  resend_otp: "Reenviar OTP",
  other_leave_type_that_may_be_combined_with_this_leave_type_as_per_the_organizational_leave_policy_for_eg_medical_sick_leave_may_be_combined_with_leave_x_leave_Y:
    "Otro tipo de permiso que puede combinarse con este tipo de permiso según la política de permisos de la organización (por ej., Permiso Médico/Enfermedad puede combinarse con permiso X y permiso Y.)",
  active_file: "Archivo Activo",
  exit_full_screen: "Salir de Pantalla Completa",
  appoinment_booked_successfully: "Cita reservada con éxito",
  department: "Departamento",
  active_employee: "Empleado Activo",
  phone: "Teléfono",
  reporting_to: "Reportando a",
  please_provide_item_manufacturer:
    "Por favor, proporcione el fabricante del ítem",
  choose_activity: "Elegir Actividad",
  new_folder: "Nueva Carpeta",
  gst_total: "Total GST",
  expectedCtc: "CTC esperado",
  separated: "Union Libre",
  sun: "Dom",
  general_surgeon: "Cirujano general",
  component_name: "Nombre del Componente",
  the_rpf_has_been_successfully_sent_to_the_vendors:
    "El RFP ha sido enviado con éxito a los proveedores.",
  with_task_linked_meetings_and_result_based_task_stop_the_drift_and_save_crucial_time:
    "Con reuniones vinculadas a tareas y tareas basadas en resultados, detenga la deriva y ahorre tiempo crucial",
  new_password_and_confirm_password_should_match:
    "La nueva contraseña y la confirmación deben coincidir.",
  please_enter_transfer_reason:
    "Por favor ingrese el motivo de la transferencia",
  organization_contact_no_is_required:
    "Se requiere el número de contacto de la organización",
  please_enter_cancellation_reason:
    "Por favor, ingrese la razón de cancelación",
  available_leave_balance: "Saldo de permisos disponibles",
  asked_for_test: "Solicitó prueba",
  please_select_work: "Por favor, seleccione trabajo",
  add_template: "Agregar Plantilla",
  patient_audio_message: "Mensaje de Audio del Paciente",
  quotationQtyError:
    "La cantidad de cotización no puede ser menor o igual a 0 en la fila no.",
  an_unexpected_error_occurred_please_try_again_later:
    "Ocurrió un error inesperado. Por favor, inténtelo más tarde.",
  create_link_task: "Crear Tarea Enlazada",
  update: "Actualizar",
  dosage: "Dosis",
  document_type: "Tipo de documento",
  brokenTornLeakingContainers: "Contenedores Rotos, Rasgados o con Fugas",
  job_title: "Título del Trabajo",
  edit_leave_balance: "Editar Saldo de Permiso",
  referred_by: "Referido por",
  eg_120: "ej:120",
  invalid_file_type: "Tipo de archivo inválido",
  select_and_add_operating_expenditure: "Seleccionar y Agregar Gasto Operativo",
  capital_expenditure_head: "Encargado de Gasto de Capital",
  please_select_currency: "Por favor, seleccione la moneda",
  high: "Alta",
  please_enter_category_name: "Por favor, ingrese el nombre de la categoría",
  face_liveness_test_failed_please_try_again:
    "La prueba de vida facial falló, por favor intente nuevamente...",
  item_replace_success: "Elemento reemplazado con éxito",
  please_select_terms_and_conditions:
    "Por favor seleccione los términos y condiciones",
  deactivate_employee_success: "Empleado desactivado con éxito",
  do_you_want_to_delete_this_department: "¿Desea eliminar este Departamento?",
  vitals: "Signos Vitales",
  Comp_off: "Compensación",
  fail_to_delete_Notes: "Error al eliminar notas",
  self_rating: "Autoevaluación",
  organization_detail: "Detalles de la organización",
  works_detail_on_weekend_holiday_successfull:
    "Detalles de trabajo en fin de semana/feriado exitosos",
  operatingExpenditure: "Gasto Operativo",
  please_provide_facial_biometrics:
    "Por favor proporcione datos biométricos faciales",
  failed_to_fetch_mapped_menus: "Error al obtener menús asignados",
  new_password: "Nueva Contraseña",
  postpone_by: "Pospuesto Por",
  organization_description:
    "Crea y define tu organización y su estructura, añade personas, asigna departamentos, funciones y ponla en marcha.",
  add_new: "Agregar Nuevo",
  add_contracts: "Agregar Contratos",
  confirm_account_no: "Confirmar Número de Cuenta",
  select_enter_leave_type: "Seleccionar/Ingresar Tipo de Permiso",
  assign_user_to_selected_task: "Asignar Usuario a la Tarea Seleccionada",
  view_template: "Ver Plantilla",
  available_templates: "Plantillas Disponibles",
  doctor_switched_patient_updating_queue:
    "Doctor Cambió Paciente(s). Actualizando Fila",
  please_enter_some_symptoms: "Por favor, ingrese algunos síntomas",
  postponed_date: "Fecha de Posposición",
  drive_fileExtensionError:
    "No se puede subir el archivo {{fileName}}, {{extension}} no es compatible. Por favor, verifique las extensiones compatibles en el signo de interrogación (?) a la derecha.",
  sign_in_now: "¡Inicia Sesión Ahora!",
  please_view_all_t_and_c: "Por favor, vea todos los Términos y Condiciones",
  service_accepting_position: "Cargo que Acepta el Servicio",
  attrition: "Desgaste",
  physical_meeting: "Reunión Física",
  doctor: "Doctor",
  booking_window_in_days: "Ventana de Reserva en Días",
  meetingAt: "Reunión en {{date}}",
  sistolic_pressure_cannot_be_empty:
    "La presión sistólica no puede estar vacía",
  add_terms_and_condition: "Agregar Términos y Condiciones",
  convener: "Moderador",
  effective_date_of_pms: "Fecha Efectiva de PMS:",
  loading: "Cargando",
  year_of_experience: "Años de Experiencia",
  delete_event_title: "Eliminar evento {{eventTitle}}",
  link_task_created: "Tarea vinculada creada",
  check_out: "Salida",
  patient_image: "Imagen del paciente",
  select_users: "Seleccionar usuarios",
  file_restrictions:
    "Tamaño Máximo de Archivo Permitido: {{maxFileSize}}MB, Extensiones Permitidas: {{validFileTypes}}",
  my_task: "Mi Tarea",
  please_select_unit: "Por favor, seleccione la unidad",
  reference_detail_required:
    "Por favor, proporcione los detalles de la referencia",
  failed_to_create_group: "No se pudo crear el grupo",
  please_enter_patient_email_id:
    "Por favor ingrese el correo electrónico del paciente",
  drop_files_to_upload_them: "Suelta Archivos para subirlos",
  kill_wastage: "Eliminar Desperdicios",
  ticket_created_successfully: "Ticket creado con éxito",
  please_enter_policy_name: "Por favor, ingrese el nombre de la política",
  custom_date: "Fecha Personalizada",
  indemnity: "INDEMNIZACIÓN",
  account_no: "N.º de Cuenta",
  t_and_c_for: "Términos y condiciones para",
  slots_date_from: "Fecha de los espacios desde",
  pleaseSign:
    "Por favor, firme y devuelva al abajo firmante la copia duplicada de esta carta, indicando su aceptación y confirmando que los términos han sido correctamente reproducidos y comprendidos.",
  search_or_find_task: "Buscar o encontrar tarea",
  please_provide_risk_factor: "Por favor proporcione el factor de riesgo",
  collection_time_cannot_be_empty:
    "La hora de recolección no puede estar vacía",
  cannotSaveChanges:
    "No puede guardar cambios en el {{eventType}} dentro de los 5 minutos o después de la hora de inicio del {{eventType}}",
  create_breakout_room: "Crear Sala de Grupo",
  sync_with_google_calendar: "Sincronizar con Google Calendar",
  please_select_amount: "Por favor, seleccione la cantidad",
  min_wage: "Salario mínimo",
  on_leave: "De Licencia",
  app_coming_soon: "APP PRÓXIMAMENTE",
  sign: "Firmar",
  private_notes: "Notas privadas",
  create: "Crear",
  profit_center: "Centro de Beneficios",
  procedure_surgery: "Procedimiento/Cirugía",
  list_of_contracts: "Lista de Contratos",
  calendar_settings: "Configuración del Calendario",
  join_leave: "Unirse / Abandonar",
  meeting_scheduled_time: "Hora programada de la reunión",
  medical_license_number: "Número de Cédula Profesional",
  tax: "Impuesto",
  item_disable_success: "Elemento desactivado con éxito",
  select_all: "Seleccionar Todo",
  issue_name: "Nombre del problema",
  are_you_sure_you_want_to_delete_the_following_files:
    "¿Estás seguro de que quieres eliminar los siguientes archivos?",
  mrs: "Sra.",
  selectPosition: "Seleccionar Posición",
  current_ctc: "CTC Actual:",
  start_ecg_test: "Iniciar prueba de ECG",
  other: "Otro",
  please_save_current_position: "Por favor, guarde la posición actual",
  recurring_event_details: "Detalles del Evento Recurrente",
  please_enter_shift_name: "Por favor, ingrese el nombre del turno",
  start_date: "Fecha de Inicio",
  end_the_webinar_for_all_users_the_webinar_will_be_closed_and_you_will_not_be_able_to_start_it_again:
    "Finalizar el webinar para todos los usuarios, el webinar se cerrará y no podrá volver a iniciarlo",
  select_slot_duration: "Seleccionar duración del intervalo",
  time_space_language_description:
    "Con soporte multilingüe y de zonas horarias, permite que el trabajo fluya con equipos, clientes y proveedores en todo el mundo.",
  next: "Siguiente",
  enter_user_current_ctc: "Ingrese el CTC actual del usuario",
  click_add_storage_button_to_create_new_storage:
    "Haga clic en el botón Agregar almacenamiento para crear un nuevo almacenamiento",
  selected: "Seleccionado",
  insurance: "Seguro",
  upgraded: "Actualizado",
  govt_ids: "Identificaciones Gubernamentales",
  partnership_firm: "Firma de Sociedad",
  reported_date: "Fecha Reportada",
  appoinment_booking_unsuccessful: "Reserva de cita fallida",
  online_portal: "Portal en Línea",
  all: "Todo",
  add_category: "Agregar Categoría",
  images_of_the_issue: "Imágenes del problema",
  sheets: "Hojas de cálculo",
  recorded_audio_note: "Nota de audio grabada",
  select_languages: "Seleccionar idiomas",
  invoiceReceiptNo: "Nro. de Factura / Recibo",
  minimum_number_of_days_an_employee_need_to_be_working_in_the_organization_in_order_to_apply_this_leave_type:
    "Número mínimo de días que un empleado necesita estar trabajando en la organización para solicitar este tipo de permiso",
  verified_items: "Elementos Verificados",
  teamHR: "Equipo de RRHH",
  date_time: "Fecha y Hora",
  encashable: "Canjeable",
  select_route: "--Seleccionar Vía de Administración--",
  breakout_room_created: "Sala de grupo {{breakoutRoomName}} creada",
  effective_from: "Válido desde",
  please_enter_here_your_valuable_suggestion:
    "Por favor ingrese aquí su valiosa sugerencia",
  corresponding_office_address: "Dirección de la oficina correspondiente",
  used: "Usado",
  loading_differential_diagnosis_please_wait:
    "Cargando diagnóstico diferencial, por favor espere...",
  failure_note:
    "en cuyo caso, esta Carta de Intención quedará retirada, a menos que se acuerde una nueva fecha de manera mutua y por escrito.",
  editor: "Editor",
  software: "Software",
  enter_notes: "Ingrese notas",
  select_template: "Seleccionar plantilla",
  indent_no: "No. de Indent",
  description2:
    "Zoyel One es una poderosa plataforma de gestión de tareas y colaboración diseñada para optimizar las operaciones y mejorar la productividad del equipo. Así es como el uso de Zoyel One puede ahorrar tiempo y hacer que tus equipos sean más efectivos:",
  the_provided_facial_biometrics_will_be_registered_with_the_account_details_shown_below:
    "Las biometrías faciales proporcionadas se registrarán con los detalles de la cuenta que se muestran a continuación",
  leave_balance_expire_days: "Días de Caducidad del Saldo de Permiso",
  switch_doctor: "Cambiar Doctor",
  married: "Casado",
  please_enter_event_title: "Por favor ingrese el título del evento",
  consultation_list: "Lista de Consultas",
  analysis: "Análisis",
  change_logo: "Cambiar Logo",
  please_enter_otp: "Por favor, ingrese OTP",
  select_days: "Seleccionar días",
  select_unit_type: "Seleccionar tipo de unidad *",
  created_by: "Creado por",
  review_and_referred: "Revisado y derivado",
  work: "Trabajo",
  add_leave_rules: "Agregar Reglas de Permiso",
  form: "Forma",
  conversation: "Conversación",
  switch_account: "Cambiar Cuenta",
  result_fetched: "Resultado obtenido",
  correspondence_address: "Dirección de Correspondencia",
  first_day_of_the_week: "Primer día de la semana",
  add_guests: "Agregar Invitados",
  otp_sent_to_mail_id_successfully:
    "OTP enviado con éxito al correo electrónico",
  to_date: "Hasta la Fecha",
  our_pre_biuld_avtar: "Preconstruido",
  leave: "Licencia",
  coupon_code_deactivated_successfully: "Código de cupón desactivado con éxito",
  add_committee: "Agregar Comité",
  otp_sent_to_email_id_successfully:
    "OTP enviado al correo electrónico con éxito",
  type_meeting_title: "Escriba el título de la reunión",
  description1:
    "Zoyel ocupó el centro del escenario en SecCon 2024, la conferencia principal para profesionales de la seguridad y la tecnología. Este año, Zoyel mostró sus últimos avances en ciberseguridad y soluciones empresariales, atrayendo la atención significativa de líderes y expertos de la industria.",
  new_word: "Nuevo Documento de Word",
  starting_instant_meeting_please_wait:
    "Iniciando reunión instantánea, por favor espera...",
  fail_to_add_Notes: "Error al añadir notas",
  comorbidity: "Comorbilidad",
  sign_off: "Cerrar",
  category_deleted_successfully: "Categoría eliminada con éxito",
  landing_tab_modified_successfully:
    "Pestaña de aterrizaje modificada con éxito",
  facial_biometrics_added_to_account_successfully:
    "Biometría facial agregada a la cuenta con éxito",
  reference_type: "Tipo de Referencia",
  urine: "Orina",
  email_address_already_exists: "La dirección de correo electrónico ya existe",
  driver: "Conductor",
  external_user_updated_success: "Usuario externo actualizado con éxito",
  fail_to_event_updated: "Error al actualizar el evento",
  no_of_user: "Número de Usuarios",
  salary_head_configuration: "Configuración del Concepto Salarial",
  please_enter_medical_license_number:
    "Por favor, ingrese el número de Cédula Profesional",
  search_contract: "Buscar Contrato",
  pleaseUploadFile: "Por favor cargue el archivo de cotización",
  leave_balance_update_successfully: "Saldo de licencia actualizado con éxito",
  do_you_want_to_go_to_go_to_consultation: "¿Desea ir a la consulta?",
  Please_fill_blogSlug: "Por favor complete el Slug del blog",
  webinar_created_successfully: "Webinar creado exitosamente",
  no_attendance_data_found: "No se Encontraron Datos de Asistencia",
  noItemAvailable: "No hay artículos disponibles para agregar",
  consultationMessage:
    "{{patientName}} ha programado una consulta con {{doctorName}} el {{date}} a las {{fromTime}} - {{toTime}}. Por favor, asegúrese de llegar al centro al menos 15 minutos antes de la hora de su cita para evitar retrasos y permitir un proceso de consulta sin problemas.",
  specification_details: "Detalles de la Especificación",
  create_a_new_task: "Crear una nueva tarea",
  no_capital_expenditure_added_yet:
    "¡Aún no se ha agregado ningún Gasto de Capital!",
  copy: "Copiar",
  do_you_want_to_withdraw_this_leave_request:
    "¿Desea retirar esta solicitud de permiso?",
  desirable: "Deseable",
  password_changed_successfully_please_login:
    "Contraseña Cambiada Exitosamente, Por Favor Inicie Sesión...",
  since: "Desde",
  eg_34_5: "ej: 34.5",
  reset_password: "Restablecer contraseña",
  please_enter_responsible_person: "Por favor, ingrese la persona responsable",
  Prof: "Prof.",
  insurance_provider_name: "Nombre del Proveedor de Seguro",
  additional_position: "Posición Adicional",
  possible_conditions: "Posibles condiciones",
  approvedBy: "Aprobado por",
  re_assign: "Reasignar",
  relevant_family_history: "Historia familiar relevante",
  sending_Call_invite_please_wait:
    "Enviando invitación a la llamada, por favor espere...",
  defult_uom: "Unidad de Medida Predeterminada",
  select_salary_group: "Seleccionar Grupo Salarial",
  please_enter_reason: "Por favor ingrese el motivo",
  salary_component: "Componente Salarial",
  no_contract_added_yet: "¡No se ha Agregado Ningún Contrato aún!",
  select_ship_to: "Seleccione a quién enviar",
  assign_user: "Asignar Usuario",
  allow_multi_drag: "Permitir Arrastre Múltiple",
  no_leave_history_found: "No se encontró historial de permisos",
  designation_edited_successfully: "Designación editada con éxito",
  coupon_validated_successfully: "Cupón validado con éxito",
  manage_tasks: "Gestionar Tareas",
  committee_roles: "Roles del Comité",
  file_upload_maximum_file_size:
    "Tamaño máximo de archivo permitido {{size}}MB",
  task_concern_submitted: "Preocupación sobre la tarea enviada!",
  cancel_appoinment: "Cancelar cita",
  please_provide_atleast_one_user: "Por favor proporcione al menos un usuario",
  please_enter_item_manufacturer: "Por favor, ingrese el fabricante del ítem",
  designation_name: "Nombre de la Designación",
  tan: "TAN",
  payment: "Pago",
  submitting: "Enviando...",
  patient_registered_successfully_please_continue_to_book_consultation:
    "Paciente registrado con éxito, por favor continúe para reservar consulta",
  consultant: "Consultor",
  google: "Google",
  doctor_writing_prescription: "Doctor Escribiendo Receta",
  external_user_added_successfully: "Usuario externo agregado exitosamente",
  physical_examination: "Examen Físico",
  new: "Nuevo",
  phone_no: "Número de Teléfono",
  please_enter_patient_gender: "Por favor ingrese el género del paciente",
  send_to_pool: "Enviar al Grupo",
  participants: "Participantes",
  add_private_note: "Agregar nota privada",
  share_screen: "Compartir Pantalla",
  click_add_template_button_to_create_new_template:
    "Haga clic en el Botón de Agregar Plantilla para crear una Nueva Plantilla",
  add_coupon: "Agregar Cupón",
  add_concern_here: "Agregar Preocupación Aquí",
  processize: "PROCESAR",
  please_select_unit_from_where_position_is_To_be_copied:
    "Por favor, seleccione la unidad de donde se copiará la posición",
  gst_tax_invoice: "FACTURA GST/IMPUESTO",
  onboard_checklist: "Lista de verificación de incorporación",
  committee_name: "Nombre del Comité",
  traveling_message:
    "En el curso de su empleo, se le podrá requerir que realice viajes, ya sea dentro de {{countryName}} o a otros lugares, en relación con el negocio de la Compañía, según las instrucciones emitidas por la Dirección de vez en cuando, para lo cual se le reembolsarán los gastos de acuerdo con las reglas. La negativa a realizar dichos viajes podría resultar en medidas disciplinarias en su contra.",
  proforma_invoice: "FACTURA PROFORMA",
  accept_or_reject: "Aceptar o Rechazar",
  differential_diagnosis: "Diagnóstico Diferencial",
  generic_medicines: "Medicinas Genéricas",
  advance_amount_more:
    "El monto anticipado no puede ser mayor que el monto de reembolso",
  create_pms_request: "Crear Solicitud de PMS",
  password_changed_successfully: "Contraseña cambiada con éxito.",
  organization_email_is_not_a_valid_email:
    "El correo electrónico de la organización no es válido",
  Admin: "Administrador",
  joiningRoom: "Uniéndose a la sala de grupo {{roomName}}",
  download_pdf: "Descargar PDF",
  joined_at: "Se unió a las",
  link_units: "Vincular Unidades",
  cycleStartDate: "Fecha de Inicio del Ciclo:",
  enter_summary: "Introduzca el resumen",
  shift_name: "Nombre del Turno",
  collaboration: "Colaboración",
  schedule_meeting: "Programar Reunión",
  last_consulted_date: "Fecha de Última Consulta",
  please_enter_webinar_description:
    "Por favor ingrese la descripción del Webinar",
  only: "Sólo",
  account_number: "Número de cuenta",
  create_an_event: "Crear un Evento",
  consolidated: "Consolidado",
  added_templates: "Plantillas Añadidas",
  sat: "Sáb",
  copy_link: "Copiar enlace",
  drug_safety_check: "VERIFICACIÓN DE SEGURIDAD DE MEDICAMENTOS",
  title_conflict_title_already_exist_consider_changing:
    "Conflicto de Título! El Título ya existe, considere cambiarlo",
  termination: "Terminación",
  doctor_queue: "Fila de doctores",
  bank_and_payment_details: "Detalles bancarios y de pago",
  common_name: "Nombre Común",
  please_enter_budget_name: "Por favor, ingrese el nombre del presupuesto",
  investigations: "estudios",
  please_select_primary_position: "Por favor seleccione posición principal",
  joining_call_please_wait: "Uniéndose a la llamada, por favor espere...",
  attach_resume: "Adjuntar Currículum",
  meetings: "Reuniones",
  modify_event: "Modificar Evento",
  make_group_admin: "Hacer administrador del grupo",
  lets_get_you: "Vamos a ponerte",
  no_employee_define_yet: "¡Aún no se ha definido ningún empleado!",
  download_selected_files: "Descargar Archivos Seleccionados",
  confidentiality_clause:
    "(La cláusula de confidencialidad se detalla más en el Acuerdo de No Divulgación)",
  enterCouponCode: "Ingresar código de cupón",
  drive_fileAlreadyPresent: "{{fileName}} ya está presente",
  vendor_menu: "Menú del Proveedor",
  please_provide_concern_remark:
    "Por favor proporcione un comentario sobre la preocupación",
  please_provide_salary_informations:
    "Por favor, proporcione la información salarial",
  event_repeat_until_date: "Repetir evento hasta la fecha",
  band_label: "Banda/Etiqueta",
  beneficiary_name: "Nombre del beneficiario",
  dr: "Dr.",
  select_files_to_upload: "Seleccione archivos para subir",
  quantity_dose: "Cantidad/Dosis",
  please_enter_item_uom: "Por favor, ingrese la unidad de medida del ítem",
  discount: "Descuento",
  per_annum: "PA (por año)",
  subscribed_successfully: "Suscripción exitosa",
  fail_to_update_language: "Error al actualizar el idioma",
  no_of_registraton: "N.º de registro",
  enter_full_screen: "Entrar en Pantalla Completa",
  upload_file: "Subir Archivo",
  add_activity_template: "Agregar Plantilla de Actividad",
  meeting_description: "Descripción de la reunión",
  please_change_the_convenor_chairperson_first:
    "Por favor, cambie al convocador/presidente primero",
  please_change_first_day_of_week:
    "Por favor cambie {{day}} del primer día de la semana antes de eliminarlo del día laboral",
  patient_left: "Paciente Salió",
  please_enter_the_bank_name: "Por favor ingrese el nombre del banco",
  fail_to_create_link_task: "Error al crear vínculo de tarea",
  at_least_one_lowercase_letter: "Al menos una letra minúscula",
  list_of_employees: "Lista de Empleados",
  viewDetails: "Ver Detalles",
  please_select_surgery_date: "Por favor, seleccione la fecha de cirugía",
  transfer: "Transferir",
  fitness_certificate: "Certificado de Aptitud",
  per_month: "PM (por mes)",
  kpi: "KPI",
  clear_filter: "Borrar filtro",
  click_add_committee_button_to_create_new_committee:
    "Haga clic en el Botón de Agregar Comité para crear un nuevo Comité",
  search_budget: "Buscar Presupuesto",
  click_add_leave_rule_button_to_create_leave_rule:
    "Haga clic en el Botón Agregar Regla de Permiso para crear una Regla de Permiso",
  save_changes: "Guardar cambios",
  make_admin: "Hacer administrador",
  no_templates_found: "No se Encontraron Plantillas",
  please_enter_custom_code: "Por favor, ingrese un código personalizado",
  please_enter_valid_password: "Por favor, ingrese una contraseña válida",
  join_zoyel_network_detail:
    "En el entorno empresarial competitivo y de ritmo acelerado de hoy, la eficiencia y la innovación son cruciales para el éxito. La Red Zoyel ofrece una solución integral diseñada para ayudar a tu negocio a prosperar. Al unirte a la Red Zoyel, puedes optimizar tus operaciones, mejorar la productividad y desbloquear nuevas oportunidades de crecimiento.",
  make_chairperson: "Hacer Presidente",
  task: "Tarea",
  enter_license_issuing_authority: "Ingresar Autoridad Emisora de Licencia",
  capitalExpenditure: "Gasto de Capital",
  reschedule: "Reprogramar",
  quarterly: "Trimestral",
  pleaseEnterSlNo: "Por favor ingrese SlNo.",
  expenditure_approval_request: "Solicitud de Aprobación de Gastos",
  approval_remark: "Comentario de Aprobación",
  please_provide_uom: "Por favor, proporcione la unidad de medida",
  hr_accounts_finance_business_sales_marketing_customizable_workflows:
    "Recursos Humanos, Cuentas, Finanzas, Negocios, Ventas y Marketing, flujos de trabajo personalizables",
  reviewer_name: "Nombre del Revisor:",
  please_provide_procedure_name:
    "Por favor proporcione el nombre del procedimiento",
  ensure_data_security_with_best_of_class_network_security_encryption_and_data_safety:
    "Garantice la seguridad de los datos con la mejor seguridad de red, encriptación y seguridad de datos",
  employee_type: "Tipo de empleado",
  activity_template: "Plantilla de Actividad",
  you_select_all_items_from_a_vendor:
    "Selecciona todos los artículos de un proveedor",
  hsn: "HSN",
  feedback_submitted_successfully: "Comentarios enviados con éxito",
  time_slot: "Intervalo de tiempo",
  igst: "IGST",
  minimum_leave_balance_needed_after_encashment_cannot_more_the_three_digit_for_current_employee:
    "Saldo Mínimo de Permiso Necesario Después del Canje No Puede Ser Más de 3 Dígitos para el Empleado Actual",
  max_file_size_allowed: "Tamaño máximo de archivo permitido {{size}}MB",
  address_in_cap: "DIRECCIÓN",
  enter_method: "Ingrese Método",
  slot_marked_as_unavailable_successfully:
    "Plaza marcada como No Disponible con éxito",
  please_enter_your_email: "Por favor, ingrese su correo electrónico",
  enter_diseases: "Ingrese enfermedades",
  organization_storage:
    "Almacenamiento de la organización - {{totalUsedStorage}} GB de {{totalStorage}} GB utilizados",
  edit_critical_rights: "Editar Derechos Críticos",
  noPatientError: "No se encontró ningún paciente. Intente nuevamente",
  task_has_been_completed: "La tarea ha sido completada",
  description: "Descripción",
  code_of_conduct:
    "Código de Conducta / Órdenes Permanentes / Normas o Reglamentos",
  reason_for_transfer: "Razón Para la Transferencia",
  please_fill_the_corresponding_country:
    "Por favor, complete el país correspondiente",
  white_board: "Pizarra",
  select_state: "Seleccionar Estado",
  search_or_find_by_name: "Buscar o encontrar por nombre",
  please_provide_item_category:
    "Por favor, proporcione la categoría del artículo",
  bio_ref_interval: "Intervalo de Referencia Biológica",
  shift_timing_added_successFully: "Horario del turno agregado exitosamente",
  please_select_relationship: "Por favor seleccione relación",
  details_of_file_to_upload_in_sign:
    "Archivo permitido en formato PNG, JPG o JPEG y rango de tamaño de 2 KB a 200 KB. Dimensión de la imagen, altura de 50 px a 512 px, con fondo transparente o blanco",
  approved_successfully: "Aprobado con éxito",
  igst_amount: "Monto IGST",
  please_enter_heading: "Por favor ingrese el encabezado",
  already_have_an_account_sign_in: "¿Ya tienes una cuenta? Iniciar sesión",
  directPurchaseReason: "Razón de Compra Directa",
  change_group_icon: "Cambiar ícono del grupo",
  sub_letter_of_intent: "Asunto: Carta de Intención",
  paymentAgainstReimbursement: "Pago Contra Reembolso",
  address_line_one: "Línea de Dirección 1",
  holiday_emailer_preview: "Vista Previa del Correo del Festivo",
  one_person_company: "Empresa Unipersonal",
  no_doctor_notes: "No hay Notas del Doctor",
  kill_wastage_description:
    "Con reuniones vinculadas a tareas y Tareas basadas en Resultados, detén la deriva y ahorra tiempo crucial.",
  duplicate_operating_expenditure_item_please_add_a_new_item:
    "Ítem de gasto operativo duplicado. Por favor, añada un nuevo ítem",
  reference_range: "Rango de Referencia",
  payment_mode: "Modo de pago",
  mark_slots_for_unavailability: "Marcar plazas como No Disponibles",
  otherTermsAndConditions: "Otros términos y condiciones",
  response_copied: "Respuesta copiada",
  meeting_invite_to_external_User_successfull:
    "Invitación a reunión enviada a usuario externo con éxito",
  submit_otp: "Enviar OTP",
  most: "Más",
  no_differential_diagnosis_can_be_recommended_against_the_provided_symptoms_and_patient_data:
    "No se puede recomendar ningún diagnóstico diferencial con los síntomas proporcionados y los datos del paciente",
  current_structure: "Estructura Actual",
  recurring_period_empty: "El período de recurrencia no puede estar vacío",
  sign_return_notice:
    "Por favor, firme y devuelva al suscrito la copia duplicada de esta carta, indicando su aceptación y confirmando que los términos se han reproducido y entendido correctamente.",
  deliver: "ENTREGAR",
  do_you_want_to_delete_this_policy: "¿Desea eliminar esta política?",
  current_address: "Dirección Actual",
  substance_abuse: "Abuso de sustancias",
  thu: "Jue",
  the_provided_email_id_is_currently_linked_with_the_patient_details_shown_below:
    "El correo electrónico proporcionado está actualmente vinculado con los detalles del paciente mostrados a continuación",
  view_form: "Ver Formulario",
  organization_details_updated_successfully:
    "Detalles de la organización actualizados con éxito",
  scheduleDate: "Fecha Programada",
  email: "Correo Electrónico",
  periodicity: "Periodicidad",
  calling_code: "Código de llamada",
  employee_payroll: "Nómina de Empleados",
  default_currency: "Moneda predeterminada",
  working_days: "Días Laborales",
  patient_back_to_center: "Paciente Regresa al Centro",
  active_files: "Archivos Activos",
  esi_no: "Número de ESI",
  selected_message_count: "{{count}} seleccionado(s)",
  done: "Hecho",
  trade_license: "Licencia comercial",
  please_enter_a_task_name: "Por favor ingrese un nombre de tarea",
  fill_form_properly: "Por favor complete el formulario correctamente",
  ewt: "TME",
  click_add_budget_button_to_create_new_budget:
    "Haga clic en el Botón Agregar Presupuesto para crear un nuevo Presupuesto",
  others_options: "Otras opciones",
  extraordinary_general_meeting: "Reunión General Extraordinaria",
  meeting_chat: "Chat de la Reunión",
  start_instant_meeting: "Iniciar reunión instantánea",
  transcript: "Transcripción",
  meeting_start_time: "Hora de Inicio de la Reunión",
  create_group: "Crear grupo",
  cost_to_company: "Costo para la Empresa (CTC)",
  rfp_preview: "Vista Previa de RFP",
  new_guest: "Nuevo Invitado",
  feedback_submission_unsuccessful: "Envío de comentarios no exitoso",
  search_generic_medicine: "Buscar Medicina Genérica",
  please_add_jd_data: "Por favor, agregue datos de DP",
  join_with_us: "Nos gustaría que se uniera a nosotros en o antes de",
  category_name: "Nombre de la Categoría",
  please_select_percent: "Por favor, seleccione el porcentaje",
  respiration_rate: "Frecuencia respiratoria",
  location: "en nuestra",
  delegate_task: "Delegar Tarea",
  gender: "Género al nacer",
  select_user_type: "Seleccionar Tipo de Usuario",
  other_observations: "Otras observaciones",
  select_the_days: "Seleccione los días",
  fail_to_update_fields: "Error al actualizar los campos",
  please_enter_a_reason_for_reschedule:
    "Por favor, ingrese una razón para la reprogramación",
  meeting_has_ended_and_no_one_is_in_the_meeting:
    "La reunión ha terminado y no hay nadie en la reunión",
  review_meeting: "Reunión de Revisión",
  contacts: "Contactos",
  patient_visit_data_success: "Datos de visita del paciente añadidos con éxito",
  documents_uploaded: "Documentos Subidos",
  please_enter_contract_type: "Por favor, ingrese el tipo de contrato",
  appointment_booking: "Reserva de cita",
  fail_to_create_meeting: "Error al crear la reunión",
  recommend_for_promotion: "Recomendar para Promoción",
  you_have_been_removed_from_this_group: "Has sido eliminado de este grupo",
  quotation_rate_error:
    "La tarifa de cotización no puede ser menor o igual a 0 en la fila número {{row}}",
  open_main_chat_window: "Abrir Ventana de Chat Principal",
  last_increment_date: "Fecha del último incremento",
  noAvailableOnlineDoctorsError:
    "No se pudo procesar la solicitud de cambio ya que no hay doctores de la misma especialidad en línea actualmente, intente nuevamente más tarde...",
  added_by_vendor: "Agregado por el Proveedor",
  linked_sites:
    "Cualquier acceso, uso, confianza en o incapacidad para usar cualquier material, contenido, bienes o servicios ubicados en, o puestos a disposición en, cualquier sitio web vinculado al o desde el Sitio web, independientemente de la causa y ya sea que surja en contrato (incluyendo incumplimiento fundamental), agravio (incluyendo negligencia) o de otro modo. La limitación anterior se aplicará incluso si el propietario conocía o debía haber conocido la posibilidad de dichos daños.",
  position: "Puesto",
  yes: "Sí",
  interviewFeedback: "Comentarios de la Entrevista",
  you_cannot_add_more_then_3_languages: "No puede agregar más de 3 idiomas",
  assign_observer: "Asignar Observador",
  drive_file_extension_not_supported:
    "No se puede subir el archivo {{fileName}}, {{extension}} no es compatible. Por favor, verifique las extensiones compatibles en la parte inferior del disco.",
  hardware: "Hardware",
  select_translate_language: "Seleccionar idioma de traducción",
  sign_up_successful: "Registro exitoso",
  company_identification_no: "Número de Identificación de la Empresa",
  advance: "Anticipo",
  personal_email_id: "Correo Electrónico Personal",
  proceed_to_book_appointment: "Proceda a reservar cita",
  age: "Edad",
  profile: "Perfil",
  recurringPeriod: "Período Recurrente",
  one_hundred_eighty_days: "180 Días - (6 Meses)",
  weaknesses: "Debilidades",
  webinar_description: "Descripción del Webinar",
  fileUploadMessage:
    "{{userName}} ha subido un nuevo archivo, por favor verifique...",
  please_enter_salary_type: "Por favor, ingrese el tipo de salario",
  one_number: "Un Número",
  notify_to: "Notificar a",
  retry_ecg_test: "Reintentar prueba de ECG",
  condition: "Condición",
  please_select_atleast_two_participants_for_viewing_convenient_time:
    "Por favor seleccione al menos dos participantes para ver el tiempo conveniente",
  proposedExpenditure: "Gasto Propuesto",
  leaves_before_event: "Permisos Antes del Evento",
  address_line_two: "Línea de Dirección 2",
  create_convenient_meeting: "Crear Reunión Conveniente",
  rcptQty: "Cant. de Recibo",
  search_test_name: "Buscar Nombre de Prueba",
  anaesthesia: "Anestesia",
  recapture: "Volver a capturar",
  registered_patients: "Pacientes registrados",
  click_to_view: "Haz clic para ver",
  feedback_success: "Comentario exitoso",
  oops_doctor_not_provide_any_data:
    "¡Vaya! El doctor no proporcionó ningún dato",
  bmi: "IMC",
  please_select_department: "Por favor, seleccione Departamento",
  add_test_data: "Agregar datos del test",
  maintains_own_balance_Sheet: "Mantiene su Propio Balance",
  useable_time_cannot_be_less_then_one:
    "El tiempo de uso no puede ser menor que 1",
  sample_type: "Tipo de Muestra",
  do_you_want_to_deactivate_this_employee: "¿Desea desactivar a este empleado?",
  position_offer:
    "Con referencia a su solicitud y la posterior conversación que mantuvo con nosotros, nos complace ofrecerle el puesto de",
  new_event: "Nuevo Evento",
  webinar_public_chat: "Chat público del seminario web",
  confirm_booking: "Confirmar reserva",
  recommended_templates: "Plantillas Recomendadas",
  please_enter_user_email:
    "Por favor ingrese el correo electrónico del usuario",
  please_provide_sign_name: "Por favor proporcione el nombre del signo",
  add_shift: "Agregar Turno",
  opened_files: "Archivos Abiertos",
  assigned_to: "Asignado a",
  could_not_dismiss_admin_user: "No se pudo destituir al usuario administrador",
  meeting_list: "Lista de Reuniones",
  delete: "Eliminar",
  please_provide_a_rating_for_your_overall_experience_with_the_video_consultation:
    "Por favor, proporcione una calificación para su experiencia general con la consulta por video",
  no_participant_is_present_in_breakout_room:
    "No hay participantes en la sala de grupo {{roomName}}",
  highest_qualification_year: "Año de Máxima Calificación",
  calculation_basis: "Base de Cálculo",
  provisional: "Provisional",
  uploading_file_please_wait_and_do_not_close_the_page:
    "Subiendo archivo, por favor espere y no cierre la página...",
  please_rotate_your_device: "Por favor, rote su dispositivo",
  select_country: "Seleccionar país *",
  enter_expenditure_items: "Ingresar Ítems de Gasto",
  concern_raised_successfully: "Preocupación planteada con éxito",
  enter_meeting: "Ingresar Reunión",
  please_enter_event_location: "Por favor ingrese la ubicación del evento",
  select_unit_from_where_to_copy: "Seleccionar unidad desde donde copiar",
  meeting_room: "Sala de Reunión",
  please_enter_a_valid_phone_number:
    "Por favor, ingrese un número de teléfono válido",
  please_add_note: "Por favor añade una nota...",
  cost_center: "Centro de Costos",
  enter_zoyel_mode: "Ingresar al Modo Zoyel",
  please_select_location: "Por favor, seleccione la ubicación",
  compliances_description:
    "Realiza reuniones de Junta / AGM, emite Agenda, realiza encuestas, regístralas. Mantén un seguimiento de los cumplimientos.",
  formal_appointment_note:
    "Se le emitirá una Carta de Nombramiento formal al unirse a la organización, sujeto al cumplimiento de la titulación académica, la experiencia laboral y otros datos proporcionados en su currículum que nos envió.",
  gst_number: "Número de GST",
  demo: "Demostración",
  organization_details: "Detalles de la Organización",
  search_items: "Buscar Artículos",
  notes: "Notas",
  heart_rate: "Frecuencia cardíaca",
  matched_image: "Imagen coincidente",
  invite_send_succesfully: "Invitación enviada con éxito",
  unit_list: "Lista de unidades",
  description4:
    "Zoyel One está expandiendo rápidamente su presencia en nuevos y existentes mercados. La versatilidad y adaptabilidad de la plataforma la convierten en una opción atractiva para empresas de todos los tamaños, desde nuevas empresas hasta grandes empresas, en diversas industrias.",
  set_slots_for_book_appointment: "Establecer horarios para reservar cita",
  add_new_title: "Añadir nuevo título",
  gst_no: "Número de GST",
  meeting_transcription: "Transcripción de la reunión",
  please_select_rating: "Por favor, seleccione una calificación",
  home: "Inicio",
  duties_title: "Deberes",
  item_description: "Descripción del Artículo",
  appointmentAs: "Nombramiento como",
  notice_period: "Período de Aviso (en días)",
  share_file: "Compartir Archivo",
  cancel_facial_biometrics_registration:
    "¿Cancelar el proceso de registro de biometría facial?",
  fail_to_change_password: "Error al cambiar la contraseña",
  upload: "Subir",
  routine_work: "Trabajo Rutina",
  accept_reject: "Aceptar/Rechazar",
  scan_face: "Escanear rostro",
  timezone: "Zona Horaria",
  refer_patient: "Referir Paciente",
  type: "Tipo",
  please_enter_webinar_title: "Por favor ingrese el título del Webinar",
  on_the_digital_highway: "en la autopista digital",
  head_circumference: "Circunferencia de la cabeza",
  too_short: "Demasiado Corto",
  vendor_partner_added_successfully: "Proveedor/Asociado añadido con éxito",
  designations: "Designaciones",
  confirm_prescription: "Confirmar prescripción",
  authorized_person_contact_no: "Número de Contacto de la Persona Autorizada",
  review_patients: "Revisar Pacientes",
  search_holiday: "Buscar Festivo",
  no_unit_is_defined_please_add_a_new_unit:
    "No se ha definido ninguna unidad. Por favor, agregue una nueva unidad",
  cancel_event: "Cancelar Evento",
  use_of_services_risk:
    "El uso de los servicios es completamente bajo su propio riesgo, ya que se proporcionan 'tal cual' y 'según disponibilidad'. Zoyel One rechaza explícitamente todas las garantías de cualquier tipo, ya sean expresas o implícitas, incluyendo, pero no limitándose a, las garantías implícitas de comerciabilidad y adecuación para un propósito particular. Zoyel One no garantiza que los servicios sean ininterrumpidos, oportunos, seguros ni libres de errores. Usted es el único responsable de cualquier daño que pueda ocurrir a su sistema informático, teléfono móvil, dispositivo inalámbrico o datos como resultado del uso de los servicios o la descarga de cualquier material obtenido a través de ellos. Cualquier información o consejo, ya sea escrito o oral, obtenido de Zoyel One, sus empleados o representantes no crea ninguna garantía no expresamente indicada en el acuerdo. Zoyel One no será responsable por ninguna pérdida o daño consecuente, incidental, indirecto, especial, punitivo o de otro tipo, incluyendo pérdida de ganancias comerciales, interrupción del negocio, fallos informáticos, pérdida de información comercial o cualquier otra pérdida, como resultado o debido a su uso o incapacidad de usar el servicio, incluso si Zoyel One ha sido advertido de la posibilidad de tal daño.",
  employment_type: "Tipo de Empleo",
  please_select_dosage: "Por favor seleccione la dosis",
  unable_to_attend: "Incapaz de asistir",
  comments: "Comentarios",
  please_type_observer_name: "Por favor, escriba el nombre del observador",
  joiningMainroom: "Uniéndose a la sala principal {{roomName}}",
  view_task_history: "Ver Historial de Tareas",
  sos: "SOS",
  drag_and_drop_files_here: "Arrastre y suelte archivos aquí",
  consultation_end_time: "Hora de Fin de la Consulta",
  please_select_frequency: "Por favor seleccione la frecuencia",
  consultationWithClinic: "Consulta con {{clinicName}}",
  seventy_five_days: "75 Días",
  password_contains_small: "Por favor incluya al menos una letra minúscula.",
  switchFailureMessage: "No se pudo cambiar al paciente",
  one_upper_case_letter: "Una letra mayúscula",
  save: "Guardar",
  actions: "Acciones",
  with_effect_from: "Con Efecto Desde",
  joining_documents_note:
    "En la fecha de su incorporación, deberá proporcionar copias firmadas y escaneadas de los siguientes documentos:",
  deleting_face_please_wait: "Eliminando rostro, por favor espere...",
  status: "Estado",
  subject: "Asunto",
  whiteboard: "Pizarra",
  disclaimer_of_warranties_text_details:
    "El Sitio web y el Contenido se proporcionan 'TAL CUAL'. Aunque el Propietario se esfuerza por proporcionar información que sea correcta, precisa, actual y oportuna, el Propietario no hace representaciones, garantías o convenios, expresos o implícitos, sobre el Sitio web y el Contenido, incluyendo, sin limitación, ninguna representación, garantía o convenio que",
  first_day_of_week: "Primer Día de la Semana",
  terms: "Términos",
  select_health_care_profession: "Seleccionar Profesión de Atención Médica",
  department_description: "Descripción del Departamento",
  experiences: "Experiencias",
  password_cannot_contain_spaces: "La contraseña no puede contener espacios",
  delete_selected_files: "Eliminar Archivos Seleccionados",
  pmsFinalReviewedBy: "Última Revisión de PMS por:",
  service_accepting_person: "Persona que Acepta el Servicio",
  facebook: "Facebook",
  please_provide_a_timezone: "Por favor, proporcione una zona horaria",
  support_file_size_warning:
    "'{{fileName}}' debe ser menor de {{maxFileSize}}MB",
  joining_date: "Fecha de Incorporación",
  date_of_joining: "Fecha de ingreso",
  advise_immediate_hospitalization: "Aconsejar hospitalización inmediata",
  salary_structure: "Estructura salarial",
  quotation: "Cotización",
  user_full_name: "Nombre completo del usuario",
  selected_files: "Archivos seleccionados {{count}}",
  banking_method: "Método Bancario",
  budget: "Presupuesto",
  the_password_should_contain_at_least_one_lowercase_letter:
    "La contraseña debe contener al menos una letra minúscula.",
  max_size_per_file: "Tamaño máximo por archivo",
  paymentMethod: "Método de Pago",
  registration_description:
    "Para utilizar los servicios, es necesario registrarse con una cuenta de usuario y proporcionar toda la información necesaria. Si los servicios se utilizan para fines internos corporativos por una organización, se recomienda que todos los usuarios creen cuentas de usuario utilizando los detalles de contacto corporativos, preferentemente la dirección de correo electrónico corporativa. Al registrarse, usted acepta proporcionar información verdadera, exacta, actual y completa sobre usted y su organización durante el proceso de registro y asegurarse de que permanezca veraz, exacta, actual y completa actualizándola de inmediato. Si proporciona información que resulta ser falsa, inexacta, desactualizada o incompleta, o si ZOYEL ONE tiene motivos razonables para sospechar de inexactitudes, ZOYEL ONE se reserva el derecho de terminar su cuenta de usuario y negar el acceso presente o futuro a sus servicios. Además de todos los demás términos y condiciones de este acuerdo, no deberá transferir los servicios ni ponerlos a disposición de terceros, ni proporcionar ningún servicio basado en los servicios sin autorización previa por escrito.",
  activity_added_successfully: "Actividad agregada con éxito",
  types: "Tipos",
  eg_182: "ej: 182",
  evaluation: "Evaluación",
  process: "Proceso",
  join_date: "Nos gustaría que se uniera a nosotros en o antes de",
  please_enter_department_name: "Por favor, ingrese el nombre del departamento",
  connecting_to_the_doctor: "Conectando con el Doctor",
  operation:
    "Que la operación del Sitio web no será interrumpida ni tendrá errores.",
  document: "Documento",
  employee_status: "Estado del Empleado",
  please_select_at_least_one_employment_type:
    "Por favor, seleccione al menos un tipo de empleo",
  delete_certificate: "Eliminar certificado",
  self_evaluation: "Autoevaluación",
  diet_recommendation_cannot_be_empty:
    "La recomendación dietética no puede estar vacía",
  add_new_education: "Agregar Nueva Educación",
  paternity_leave: "Permiso de Paternidad",
  please_enter_subTask_details: "Por Favor Ingresa Detalles de la Subtarea",
  click_to_view_recording: "Haz clic para ver la grabación",
  error_in_connecting_to_the_doctor: "Error al Conectar con el Doctor",
  add_access_permission: "Agregar Permiso de Acceso",
  maternity_leave: "Permiso de Maternidad (ML)",
  local_tax_applicable: "Impuesto local aplicable",
  view_leave_rules_details: "Ver Detalles de las Reglas de Permiso",
  select_menu: "--Seleccionar Menú--",
  select_max_rating: "Seleccionar calificación máxima",
  loi_issue_success: "Emisión de la Carta de Intención Exitosa",
  select_cost_center: "Por favor seleccione el centro de costos",
  employee_info: "Información del Empleado",
  organization_unit_type_is_required:
    "Se requiere el tipo de unidad de la organización",
  click_add_holiday_button_to_create_new_holiday:
    "Haga clic en el Botón Agregar Festivo para crear un nuevo Festivo",
  time: "Hora",
  remarks: "Observaciones",
  task_management_description:
    "Crea tareas fácilmente y monitóralas. Elimina la carga de gestión.",
  write_about_your_product_here: "Escriba sobre su producto aquí...",
  more_features: "Más Características",
  gps_location: "Ubicación GPS de la Unidad",
  no_files_uploaded: "No se han Subido Archivos",
  remove_notification: "Eliminar Notificación",
  video_switched_off: "Video apagado",
  sl_no: "N.º",
  quotation_value: "Valor de Cotización",
  outsource_report: "Subcontratar informe",
  no_organization_details_not_define_yet:
    "No se han definido detalles de la organización todavía !!",
  please_provide_category: "Por favor, proporcione la categoría",
  slot_already_booked_select_another_slot:
    "Franja ya reservada. Seleccione otra franja",
  load_more: "Cargar más",
  security: "Seguridad",
  policy: "Política",
  facial_biometrics_data_not_found:
    "Datos biométricos faciales no encontrados, por favor continúe para registrar un nuevo paciente",
  select_position: "Seleccionar Posición",
  no_holiday_define_yet: "¡No se ha Definido Ningún Festivo aún!",
  unit_wise: "Por Unidad",
  history_and_risk_factor: "Historia y Factor de Riesgo",
  uom: "UOM",
  add_departmment: "Agregar departamento",
  sistolic_pressure_must_be_greater_than_diastolic:
    "La presión sistólica debe ser mayor que la presión diastólica",
  createdBy: "Creado por",
  provide_reimbursement_data: "Proporcione al menos un dato de reembolso",
  slot_duration_in_minutes: "Duración del Horario en minutos",
  collection_date_time: "Fecha y Hora de Recolección",
  select_participants: "Seleccionar participantes",
  meetings_description:
    "Reuniones sincrónicas y asincrónicas - programadas e instantáneas con salas privadas y seminarios web.",
  please_enter_contact_number: "Por favor, ingrese número de contacto",
  epf_no: "Número de EPF",
  confirm_password: "Confirmar contraseña",
  doctor_cancelled: "Doctor Canceló",
  register_patient: "Registrar Paciente",
  check: "Verificar",
  add_previous_procedure: "Agregar procedimiento anterior",
  holiday_added_successfully: "Festivo añadido con éxito",
  convenor_chairperson_needs_to_be_changed_individually_for_each_recurring_event:
    "El convocador/presidente debe cambiarse individualmente para cada evento recurrente",
  please_type_to_search: "Por favor, escriba para buscar",
  start_convenient_meeting: "Iniciar Reunión Conveniente",
  acceptance_text:
    "Para aceptar el acuerdo, debe ser mayor de edad para firmar un acuerdo vinculante. Si no está de acuerdo con los términos generales, evite utilizar nuestros servicios. La aceptación del acuerdo se puede demostrar marcando una casilla, haciendo clic en un botón que indique aceptación.",
  uploaded_file_data: "Datos del Archivo Subido",
  conditions: "Condiciones",
  no_recordings_present: "No hay grabaciones presentes",
  rejected_by: "Rechazado Por",
  no_of_use_times: "Número de Usos (1-1000)",
  failed_to_add_some_guests: "No se pudo agregar a algunos invitados",
  please_properly_fill_the_corresponding_address:
    "Por favor, complete adecuadamente la dirección correspondiente",
  row_auto_height: "Altura Automática de la Fila",
  patient_registrations: "Registros de pacientes",
  show_of_hand: "Levantar la mano",
  orglogo: "orgLogo",
  organization_tree: "Árbol de la Organización",
  contract_if_any: "Contrato (si lo hay)",
  please_enter_city: "Por favor, ingrese la ciudad",
  from_organization: "De la Organización",
  external_user: "Usuario Externo",
  please_enter_your_comment: "Por favor ingresa tu comentario",
  possible_diagnosis: "Posible diagnóstico",
  fail_to_updated_logo: "Error al actualizar el logo",
  holiday_update_already_exists: "El día festivo ya existe",
  please_enter_license_issuing_authority:
    "Por favor, ingrese la autoridad emisora de la licencia",
  po_preview: "Vista Previa de PO",
  loss_of_pay: "Pérdida de Pago (LOP)",
  switchConsultation: "Cambiar Consulta",
  hours: "Horas",
  select_doctor_available_units:
    "Seleccionar Unidades Disponibles para el Doctor",
  permanent_address: "Dirección Permanente",
  update_progress_of_the_task: "Actualizar el progreso de la tarea",
  delete_unit: "Eliminar Unidad",
  please_provide_absent_days: "Por favor, proporcione los días de ausencia",
  failed_to_delete_category: "No se pudo eliminar la categoría",
  add_more_storage: "Agregar Más Almacenamiento",
  meeting: "Reunión",
  file_size_warning: "'{{fileName}}' debe ser menor que {{maxFileSize}}MB",
  primary_position: "Posición Principal",
  appointment: "Cita",
  search_history_and_risk_factor: "Buscar historia y factor de riesgo",
  currentCtc: "CTC actual",
  goodCondition: "Buena Condición",
  new_structure: "Nueva Estructura",
  new_slides: "Nuevas Diapositivas",
  quotation_qty: "Cantidad de Cotización",
  tax_number: "Número de impuesto",
  confidentiality: "Confidencialidad",
  otp_service: "Servicio OTP",
  define_salary_breakup: "Definir Desglose del Salario",
  attendance_data_is_empty_unable_to_export:
    "Los datos de asistencia están vacíos, no se puede exportar",
  pms_cycle_from: "Ciclo de PMS desde",
  event_start_time: "Hora de Inicio del Evento",
  happy_holidays: "Felices Fiestas",
  rating_out_of: "Calificación de",
  employee_salary_structure: "Estructura Salarial del Empleado",
  contacts_on: "Contactos en",
  withReferenceToYourApplication:
    "Con referencia a su solicitud y la posterior entrevista con nosotros, nos complace nombrarlo como",
  others: "Otros",
  sort_code: "Código de Clasificación",
  position_deactivated: "Posición desactivada",
  connect_to_doctor: "Conectar con el Doctor",
  third_quarter: "3er Trimestre",
  preferable_time: "Hora Preferible para la Entrevista",
  choose_department: "Elegir Departamento",
  screen_sharing_off: "Compartir pantalla desactivado",
  user_name: "Nombre de usuario",
  indemnity_statement:
    "Aceptas indemnizar, defender y mantener indemne al Propietario y sus funcionarios, directores, empleados, agentes, licenciantes y sus respectivos sucesores y cesionarios, de y contra cualquier y todas las reclamaciones, demandas, responsabilidades, costos o gastos que resulten directa o indirectamente de",
  half_day: "Medio Día",
  invoice_number: "Número de Factura",
  consulting_end_time: "Hora de fin de consulta",
  location_required_for: "UBICACIÓN REQUERIDA PARA",
  minimum_employment_tenure_needed: "Antigüedad Mínima Necesaria",
  facial_biometrics_not_found_please_register_patient_first:
    "Datos biométricos faciales no encontrados, por favor registre al paciente primero",
  digitize: "DIGITALIZAR",
  an_error_occurred_please_try_again:
    "Ocurrió un error. Por favor, intente de nuevo.",
  add_new_component_category: "Agregar Nueva Categoría de Componente",
  rejected: "Rechazado",
  mark_attendance: "Marcar Asistencia",
  please_ask_a_question: "¡Por favor, haz una pregunta!",
  aboutTitle: "Acerca de Zoyel",
  stop_unnecessary_use_of_energy_paper_travel_to_protect_our_world_without_it_our_businesses_do_not_exist:
    "Detenga el uso innecesario de energía, papel y viajes para proteger nuestro mundo. Sin él, nuestros negocios no existirían",
  total_ctc: "CTC Total",
  please_enter_valid_email_id:
    "Por favor, ingrese un correo electrónico válido",
  cancellation_applied_to_all_occurrences_of_the_event:
    "Cancelación aplicada a todas las ocurrencias del evento",
  maximum_number_of_days_allowed_for_encashment_during_f_f:
    "Número máximo de días permitidos para canje durante F&F. El máximo canje permitido durante F&F no puede ser mayor a 3 dígitos",
  emailId: "Correo electrónico",
  ledger_head: "Encabezado del Libro Mayor ",
  manage_meetings: "Gestionar Reuniones",
  please_enter_valid_phone_number_for_country_code:
    "Por favor, ingrese un número de teléfono válido para el código de país",
  security_description:
    "Asegura la seguridad de los datos con la mejor seguridad de red, cifrado y seguridad de datos.",
  categories: "Categorías",
  assign_to_vendor: "Asignar al Proveedor",
  codeOfConduct:
    "Código de conducta / Órdenes permanentes / Reglas o reglamentos",
  please_provide_drug_name: "Por favor proporcione el nombre del medicamento",
  click_add_policy_button_to_create_new_policy:
    "Haga clic en el botón Agregar política para crear una nueva política",
  you_cannot_remove_your_unit: "No puedes eliminar tu unidad",
  event_title: "Título del Evento",
  variance_speciation: "Especificación de Varianza",
  advance_amount_cannot_be_more_than_total_amount:
    "El Monto del Adelanto No Puede Ser Mayor que el Monto Total",
  delivery_terms: "Términos de Entrega",
  organization: "Organización",
  mark_as_unavailable: "Marcar como No Disponible",
  jan21: "21 de enero de 2024",
  select_parameter: "Seleccionar Parámetro",
  continue: "Continuar",
  please_enter_doctor_note_or_add_audio_note:
    "Por favor ingresa una nota del doctor o añade una nota de audio",
  method: "Método",
  appointment_consultation: "Consulta de Cita",
  we_dont_support_landscape_mode_yet_please_go_back_to_portrait_mode_for_the_best_experience:
    "Todavía no soportamos el modo horizontal. Por favor, vuelva al modo vertical para la mejor experiencia",
  minimize: "Minimizar",
  department_created_successfully: "Departamento creado con éxito",
  linked_task_name: "Nombre de Tarea Enlazada",
  cancellation_reason: "Razón de la Cancelación",
  please_send_correspondence_regarding_this_purchase_order_to:
    "Por favor, envíe correspondencia relacionada con esta orden de compra a",
  search_by_dr_speciality: "Buscar por Especialidad del Dr",
  an_error_occurred_while_making_the_slot_unavailable:
    "Ocurrió un error al marcar la plaza como No Disponible",
  the_category_name_already_exists: "El nombre de la categoría ya existe",
  proceed: "CONTINUAR",
  coupon: "Cupón",
  approveRequest: "Aprobar Solicitud",
  group_info: "Información del grupo",
  password_contains_capital: "Por favor incluya al menos una letra mayúscula.",
  collaboration_description:
    "Suite completa de colaboración sin suscribirte a ningún otro proveedor.",
  your_ticket_has_been_created_please_find_the_ticket_id_below:
    "Tu ticket ha sido creado, por favor encuentra el ID del ticket a continuación",
  male: "Hombre",
  you_cannot_add_more_then_three_languages:
    "No puedes agregar más de 3 idiomas",
  contract_type: "Tipo de Contrato",
  do_you_want_to_mark_this_shift_as_the_default_shift:
    "¿Deseas marcar este turno como el turno predeterminado?",
  the_password_should_be_atleast_8_characters:
    "La contraseña debe tener al menos 8 caracteres.",
  failed_to_add_holiday: "Error al añadir el festivo",
  send: "Enviar",
  first_quarter: "1er Trimestre",
  save_for_this_future_appointment_letter:
    "Guardar para esta futura Carta de Designación",
  warrantee_guarantee_sla: "Garantía/ Garantía/ SLA",
  update_mom: "Actualizar MOM",
  do_you_want_to_continue_with_given_parameters:
    "¿Quieres continuar con los parámetros dados?",
  are_you_sure_to_add_this_as_leave_name:
    "¿Estás seguro de agregar esto como nombre de permiso?",
  not_yet_generated: "No Generado Aún",
  absentPercentage: "Porcentaje de Ausencias",
  spouse_Name: "Nombre del Cónyuge",
  few: "Pocos",
  invoice_submitted_successfully: "Factura enviada con éxito",
  ex_kolkata: "Ej: Kolkata",
  longitude: "Longitud",
  enter_rating_more_than_zero:
    "Por favor, ingrese una calificación mayor a 0 para {{metricName}}",
  sixty_days: "60 Días - (2 Meses)",
  update_attendance_successfully: "Asistencia actualizada con éxito",
  how_may_i_help_you: "¿Cómo puedo ayudarte?",
  holiday_name: "Nombre del Festivo",
  copy_room_number: "Copiar número de sala",
  employeeName: "Nombre del Empleado",
  your_business: "tu negocio",
  with_rating: "Calificación: {{rating}} / 5",
  itemName: "Nombre del Ítem",
  please_refer_to_the_po_no_in_all_your_correspondence:
    "Por favor, refiera el Nro. de PO en toda su correspondencia",
  dont_have_an_account_sign_up: "¿No tiene una cuenta? Regístrese",
  please_provide_family_disease_name:
    "Por favor proporcione el nombre de la enfermedad familiar",
  regards: "Saludos",
  company_identification_number: "Número de identificación de la empresa",
  experience: "Experiencia",
  carry_forward: "Acumulación",
  totalAmount: "Monto Total",
  consultation_in_progress: "Consulta en Progreso",
  borderline_high: "Límite alto",
  please_select_a_file_to_upload: "Por favor, seleccione un archivo para subir",
  invoice_amount: "Monto de la Factura",
  add_uom: "Agregar Unidad de Medida",
  selected_time_already_passed: "El tiempo seleccionado ya ha pasado",
  acute_care: "Cuidados agudos",
  pms_period_to: "Período de PMS hasta",
  please_enter_desscription_index:
    "Por favor, introduzca la descripción en el índice {{index}}",
  website_is_not_available: "El sitio web no está disponible",
  select_gender: "Seleccionar Género",
  please_enter_title_index:
    "Por favor, introduzca el título en 'Responsabilidad clave' en el índice {{index}}",
  add_other_charges: "Agregar Otros Cargos",
  start_conversation: "Iniciar Conversación",
  deleted_private_notes: "Notas privadas eliminadas",
  error_in_adding_unit_for_test: "Error al agregar unidad para el test",
  invalid_file_format_allowed_formats_png_jpg_jpeg:
    "Formato de archivo no válido. Formatos permitidos: PNG, JPG, JPEG",
  owner: "Propietario",
  room_number: "Número de Sala *",
  ninety_days: "90 Días - (3 Meses)",
  add_critical_rights: "Agregar Derechos Críticos",
  fileTooSmall: "El archivo '{{fileName}}' es demasiado pequeño.",
  google_calendar_data_synced_successfully:
    "Datos de Google Calendar sincronizados con éxito",
  civil_status: "Estado civil",
  in_word: "En palabras",
  capital_expenditure_added_successfully: "Gasto de capital añadido con éxito",
  dateOfJoining: "Fecha de incorporación:",
  notify_lead_time: "Tiempo de Anticipación para Notificar",
  please_provide_description: "Por favor, proporcione una descripción",
  leaves_after_event: "Permisos Después del Evento",
  whatsapp: "Whatsapp",
  cardiologist_cvs_cardiac_surgeon: "Cardiólogo/CVS/Cirujano cardíaco",
  mailing_address: "Dirección Postal",
  please_enter_the_purpose: "Por favor ingrese el propósito",
  generate_report: "Generar Informe",
  inbox: "Bandeja de Entrada",
  details: "Detalles",
  purpose_empty: "El propósito no puede estar vacío",
  pending_task: "Tarea Pendiente",
  important: "Importante",
  goals: "OBJETIVOS",
  leave_rules: "Reglas de Permiso",
  click_add_employee_button_to_create_new_employee:
    "Haga clic en el botón Agregar Empleado para crear un nuevo empleado",
  file_deleted_successfully: "Archivo eliminado con éxito",
  cgst_percentage: "CGST %",
  enter_reason: "Ingresar Motivo",
  create_approval_request: "Crear solicitud de aprobación",
  accommodationCost: "Costo de Alojamiento",
  streamline: "optimiza",
  refer_to_medico_legal: "Referir a médico legal",
  importantLinksTitle: "Enlaces Importantes",
  salary_component_deactivated_successfully:
    "Componente salarial desactivado con éxito",
  quotationRateError:
    "La tarifa de cotización no puede ser menor o igual a 0 en la fila no.",
  select_recurring_document: "Seleccione el documento de recurrencia",
  personal_info_description:
    "Su información personal proporcionada a ZOYEL ONE a través del servicio está regulada por los términos del acuerdo. Al elegir utilizar el servicio, usted indica su aceptación de estos términos. Es su responsabilidad mantener la confidencialidad de su nombre de usuario, contraseña y otra información sensible. Usted es responsable de todas las actividades que ocurran en su cuenta de usuario y acepta notificar de inmediato cualquier uso no autorizado a través de correo electrónico o teléfono. No somos responsables por cualquier pérdida o daño a usted o a terceros como resultado del acceso no autorizado o uso de su cuenta de usuario, o cualquier otra circunstancia.",
  you_have_to_add_meeting_agenda: "Debe agregar la agenda de la reunión",
  search_committee: "Buscar Comité",
  tenure: "Antigüedad",
  hybrid: "Híbrido",
  statutory_and_insurance: "Estatutario y Seguro",
  upload_logo: "Subir logo",
  no_endorsements_text_details:
    "A menos que se indique específicamente, el Propietario no recomienda ni respalda ninguna marca específica de productos, servicios, procedimientos u otra información que aparezca o que pueda ser anunciada en el Sitio web.",
  reference_detail: "Detalle de la Referencia",
  please_enter_unit_name: "Por favor, ingrese el nombre de la unidad",
  storage: "Almacenamiento",
  sgst_percentage: "SGST %",
  activity_name: "Nombre de la Actividad",
  website_name: "Nombre del sitio web",
  taxAmount: "Monto del Impuesto",
  create_word_document: "Crear Documento de Word",
  last_30_days: "Últimos 30 Días",
  following_changes_made_will_not_be_saved_discard_changes:
    "Los siguientes cambios realizados no se guardarán, ¿descartar los cambios?",
  content_use:
    "Tu uso, confianza, publicación, comunicación, distribución, carga o descarga de cualquier cosa (incluido el Contenido) en o desde el Sitio web.",
  expectedCost: "Costo Esperado",
  detail: "Detalle",
  no_template_define_yet: "¡Aún no se ha definido ninguna Plantilla!",
  oops: "¡Ups!",
  omni_presence_description:
    "Crea tu presencia en la web con unos pocos clics. No necesitas dominios, alojamiento web o infraestructura de TI.",
  please_select_a_reason_for_unavailability:
    "Por favor seleccione un motivo de no disponibilidad",
  borderlinehigh: "Límite Alto: 150-199",
  slotAlreadyBooked:
    "La plaza {{fromTime}} - {{toTime}} ya está reservada, por favor reprogramarla",
  select_month: "Seleccionar Mes",
  patient_left_the_center: "El paciente dejó el centro",
  click_to_start: "Haz clic para empezar",
  add_routine_work: "Agregar Trabajo de Rutina",
  todays_meetings: "Reuniones de Hoy",
  units_tree: "Árbol de Unidades",
  unread: "No leído",
  mon: "Lun",
  please_enter_position: "Por favor, ingrese la posición",
  please_enter_pin: "Por favor, ingrese el PIN",
  additional_document_name: "Nombre del documento adicional",
  file_details: "Detalles del Archivo",
  please_check_reschedule_before_proceeding:
    "Por favor, revise Reprogramar antes de continuar",
  tax_id: "ID Fiscal",
  holiday: "Vacaciones",
  select_spoken_language: "Seleccionar idioma hablado",
  week: "Semana",
  list_of_people: "Lista de Personas",
  service: "Servicio",
  estimatedCost: "Costo estimado",
  approval_request: "Solicitud de aprobación",
  manufacturer_name: "Nombre del Fabricante",
  description3:
    "Zoyel One está revolucionando la forma en que las empresas operan al ofrecer una solución integral, todo en uno, que se adapta a cada aspecto de la gestión empresarial moderna. Aquí te explicamos por qué Zoyel One se destaca como la elección definitiva para las empresas que buscan eficiencia, crecimiento e innovación:",
  meeting_agendas: "Agendas de la reunión",
  organization_contact_no: "Número de Contacto de la Organización",
  contact_no_cannot_be_less_than_10_character_long:
    "El número de contacto no puede tener menos de 10 caracteres",
  ship_to: "Enviar a",
  payment_against_sip: "Pago Contra SIP",
  notAvailable: "No disponible",
  new_storage_added: "Nuevo Almacenamiento Agregado",
  committee_deactivated_successfully: "Comité desactivado con éxito",
  makePayment: "Realizar pago de ${{amount}}",
  an_error_occurred_while_rescheduling_the_slot:
    "Ocurrió un error al reprogramar la plaza",
  website_access:
    "Tu acceso, uso, mal uso, confianza o incapacidad para acceder o usar el Sitio web, el Contenido o cualquier sitio web al que el Sitio web está o puede estar vinculado de vez en cuando o",
  expirable: "Caducable",
  please_fill_the_form_correctly:
    "Por Favor Complete el Formulario Correctamente",
  forward: "Reenviar",
  edit_department: "Editar Departamento",
  select: "Seleccionar",
  deleting_files_please_wait_and_do_not_close_the_page:
    "Eliminando archivos, por favor espere y no cierre la página...",
  performance_management_system: "Sistema de gestión del rendimiento (PMS)",
  grouping: "Agrupación",
  medicalExamination: "Examen médico y documentación",
  updatingUnavailability:
    "Actualizando la no disponibilidad para {{eventType}}, por favor espere...",
  search_service: "Buscar servicio",
  please_enter_task_description: "Por Favor Ingresa la Descripción de la Tarea",
  select_category: "Seleccionar Categoría",
  adding_facial_biometrics_data_to_account_please_wait:
    "Agregando datos de biometría facial a la cuenta, por favor espere...",
  add_watch_out_for: "Añadir advertencia para",
  days: "Días",
  designation_edit_unsuccessful: "Edición de designación fallida",
  validate_otp: "Validar OTP",
  add_accounts_and_invoices: "Agregar Cuentas y Facturas",
  user: "Usuario",
  username_gose_here: "nombre de usuario aquí",
  travel: "Viaje",
  please_select_a_doctor_before_submitting:
    "Por favor seleccione un médico antes de enviar",
  fail_to_verify_otp: "Error al verificar OTP",
  indent_qty: "Cantidad de Pedido ",
  patient_waiting_in_video: "Paciente Esperando en Video",
  disclaimer:
    "El Propietario también renuncia expresamente a cualquier y toda responsabilidad por los actos, omisiones y conductas de cualquier usuario de terceros del Sitio web, o de cualquier anunciante o patrocinador del Sitio web ('tercero'). En ninguna circunstancia el Propietario, sus funcionarios, directores, empleados, agentes, licenciantes y sus respectivos sucesores y cesionarios, serán responsables por cualquier lesión, pérdida, daño (incluidos daños directos, especiales, indirectos, punitivos, incidentales o consecuentes), o gasto que surja de cualquier manera de",
  video_devices: "Dispositivos de video",
  edit_designation: "Editar designación",
  meeting_canceled_successfully: "Reunión cancelada con éxito",
  operating_expenditure_head: "Encargado de Gasto Operativo",
  please_enter_brief_description_of_bug_that_you_facing:
    "Por favor ingrese una breve descripción del error que está enfrentando",
  reschedule_date_on_before_after: "Reprogramar Fecha en/Antes/Después",
  purpose_of_leave: "Propósito del permiso",
  enter_purpose_of_leave: "Ingrese el propósito del permiso",
  meeting_summary: "Resumen de la reunión",
  email_id_is_linked_with_another_account:
    "El correo electrónico está vinculado a otra cuenta",
  switchSuccessMessage: "Cambio procesado con éxito",
  raise_hand: "Levantar la mano",
  the_voice_recording_limit_is_restricted_to_2_minutes:
    "El límite de grabación de voz está restringido a 2 minutos.",
  tooltip: "Tooltip",
  leave_type: "Tipo de permiso",
  confirm_website_change:
    "¿Estás seguro? ¡Todos los datos existentes del sitio web serán eliminados!",
  add_link_task: "Agregar Tarea Vinculada",
  sending_positive_vibes_for_the_weekend_to_you_enjoy_your_well_deserved_break:
    "Enviando buenas vibras para el fin de semana, disfruta de tu merecido descanso.",
  playing_audio_note: "Reproduciendo nota de audio",
  emergency_patient: "Paciente de Emergencia",
  add_group_icon: "Agregar ícono del grupo",
  hsncd: "HSNCD",
  savingChanges:
    "Guardando los siguientes cambios, {{textToDisplay}} por favor espere...",
  title_wise_events: "Eventos por Título",
  forward_chat: "Reenviar chat",
  date_of_birth: "Fecha de nacimiento",
  file_added: "Archivo Agregado",
  item_updated_successfully: "Elemento actualizado con éxito",
  please_unmute_to_speak: "Por favor, active el sonido para hablar",
  postpone: "Posponer",
  oe_items_added: "Ítems de Gasto Operativo Agregados",
  please_enter_job_summary: "Por favor, introduzca el resumen del trabajo",
  send_for_shortlist: "Enviar para Preselección",
  mrp: "Precio MRP",
  test_observation: "Observación de prueba",
  meeting_end_time: "Hora de Fin de la Reunión",
  template_added_to_task: "Plantilla añadida a la tarea",
  contract_owner: "Propietario del Contrato",
  please_select_route: "Por favor selecciona la Vía de Administración",
  enter_user_details: "Ingrese los detalles del usuario",
  gmail: "Gmail",
  add_budget_successfully: "Presupuesto añadido con éxito",
  please_give_closing_remarks: "Por favor, dar comentarios de cierre",
  select_vendors: "Seleccionar Proveedores",
  please_upload_a_medical_license_attachment:
    "Por favor, suba un archivo adjunto de la Cédula Profesional",
  terms_and_condition: "Términos y Condiciones",
  advisory_board_consultant: "Consultor del Consejo Asesor",
  please_add_at_least_one_role: "Por favor, añada al menos un rol",
  edit_expenditure_power: "Editar Poder de Gasto",
  fetching_facial_biometrics_data:
    "Obteniendo datos biométricos faciales, por favor espere...",
  p_o_no: "Nro. de P.O.",
  nature_of_appointment: "NATURALEZA DEL NOMBRAMIENTO",
  failed_to_update_category: "No se pudo actualizar la categoría",
  please_select_severity: "Por favor seleccione la severidad",
  appointment_settings: "Configuración de Citas",
  search: "Buscar",
  assignd_to: "Asignado a",
  from_word_processor_to_spreadsheet_presenter_all_productivity_tools_built_in_with_drive_and_business_templates:
    "Desde procesadores de texto hasta hojas de cálculo y presentadores: todas las herramientas de productividad integradas con Drive y plantillas empresariales",
  provideRemarkError: "Por favor proporcione alguna observación.",
  same_as_registered_address: "Igual que la dirección registrada",
  doctor_available_in_units: "Doctor Disponible en Unidades",
  convenor: "Convocante",
  statutory_and_insurance_payments_head_added_successfully:
    "Encabezado de Pagos Estatutarios y de Seguros añadido con éxito",
  designation_copied_successFully: "Designación copiada con éxito",
  vendor_meeting: "Reunión con Proveedor",
  remove_in_caps: "ELIMINAR",
  change_mode: "Cambiar Modo",
  department_update_success: "Departamento actualizado con éxito",
  disMessage:
    "*Al presionar continuar, los datos del paciente anterior reemplazarán los datos del paciente asociados con el correo electrónico {{emailId}} con el que se reservó esta cita, tenga en cuenta que esto afectará a todas las demás citas reservadas con {{emailId}}.",
  no_doctor_is_online: "Ningún doctor está en línea",
  candidate_name: "Nombre del Candidato",
  auth_per_contact_no: "Número de contacto de la persona autorizada",
  second_quarter: "2do Trimestre",
  Call_invite_sent: "Invitación a la llamada enviada",
  start_time_less_than_end_time:
    "La 'Hora de inicio' debe ser menor que la 'Hora de fin' para {{dayName}}",
  please_enter_patient_last_name: "Por favor ingrese el apellido del paciente",
  please_check_mark_as_available_before_proceeding:
    "Por favor, revise Marcar como Disponible antes de continuar",
  patient_is_allergic_to_the_following_medication:
    "El paciente es alérgico a la siguiente medicación",
  please_enter_valid_longitude_range:
    "Por favor, ingrese un rango de longitud válido entre -180 y 180",
  please_upload_you_organization_logo:
    "Por favor, cargue el logo de su organización",
  blood_groud: "Grupo Sanguíneo",
  word_processor: "Procesador de texto",
  file_upload_invalid_file_type: "Tipo de archivo no válido de '{{fileName}}'",
  employee_id_already_present: "ID de empleado ya presente",
  interviewerPreferedTime: "Hora Preferida del Entrevistador",
  absenteeism: "Absentismo",
  miss: "Srta.",
  collaborate: "COLABORAR",
  consultation_cancelled_successfully: "Consulta cancelada con éxito",
  invalid_credentials: "¡Credenciales inválidas!",
  edit_units: "Editar Unidades",
  moderator_rights_granted:
    "Derechos de moderador otorgados a {{displayName}} con éxito",
  test_result: "Resultado de la Prueba",
  viewResume: "Ver currículum",
  leave_message:
    "Tendrá derecho a las licencias según las Reglas de Licencia de la empresa. Para obtener información sobre el número de días de licencia, consulte la Política de Licencia o Recursos Humanos. Sin embargo, la concesión de licencias (excepto en caso de emergencia médica) dependerá de las necesidades del trabajo y estará a discreción de la gerencia. Para solicitar una licencia, deberá aplicar en el formulario prescrito a la autoridad correspondiente a través del sistema y buscar la aprobación al menos {{priorLeaveNoticeDays}} días antes de dicha licencia. Del mismo modo, para la extensión de la licencia, deberá realizar una solicitud a través del sistema antes de que expire la licencia originalmente aprobada, la cual deberá llegar a la empresa al menos {{priorLeaveNoticeDays}} días antes de la expiración de la licencia. Al realizar dicha solicitud, indicará su dirección durante el período de licencia. La mera presentación de la solicitud no significa que la licencia haya sido aprobada, y a menos que se apruebe o extienda por escrito, no se le considerará en licencia.",
  no_leave_rule_define_yet: "¡No se ha Definido Ninguna Regla de Permiso aún!",
  statutory_insurance_payments: "Pagos Legales y de Seguro",
  no_of_use: "Número de Usos",
  remove_unnecessary_work_and_useless_meetings_with_our_effectivity_tools:
    "Elimine el trabajo innecesario y las reuniones inútiles con nuestras herramientas de eficacia",
  special_resolution_will_be_proposed: "Se propondrán resoluciones especiales",
  differential_diagnosis_loaded_successfully:
    "Diagnóstico diferencial cargado con éxito",
  percentage_of_basic: "Porcentaje del Básico",
  please_provide_a_task_description_or_task_description_audio:
    "Por favor proporcione una descripción de tarea o descripción de tarea en audio",
  non_verified_items: "Elementos No Verificados",
  join: "Unirse",
  consultation_with: "Consulta con",
  consultation_start_time: "Hora de Inicio de la Consulta",
  this_month: "Este Mes",
  official_email_id: "Correo Electrónico Oficial",
  changes_made: "Cambios Realizados",
  attendance_report: "Informe de Asistencia",
  productivity: "Productividad",
  search_policy: "Buscar política",
  number_of_leaves: "Número de Días de Permiso",
  event_location: "Ubicación del Evento",
  select_speciality: "Seleccionar especialidad",
  translate_all: "Traducir todo",
  signature: "Firma",
  instagram_link: "Enlace de Instagram",
  view_details: "Ver Detalles",
  password_contains_special_char:
    "Por favor incluya al menos un carácter especial.",
  search_or_find: "Buscar o encontrar",
  proper_service_notice:
    "Cualquier carta enviada por la Compañía mediante Correo Certificado A.D./Correo Rápido/A través de Mensajero a la dirección mencionada se considerará como una notificación adecuada. Cualquier comunicación enviada por correo electrónico o fax a la dirección de correo electrónico o número de fax anterior se considerará como una notificación adecuada.",
  none: "Ninguno",
  responsible_person: "Persona Responsable",
  day_wise_events: "Eventos por Día",
  enter_purpose: "Ingrese el propósito",
  pad: "Bloc",
  meeting_name: "Nombre de la reunión",
  from_issued_loi: "De LOI Emitido",
  doctor_canceled_meeting: "Doctor canceló la reunión",
  facial_biometrics_data_added_to_account_successfully:
    "Datos de biometría facial agregados a la cuenta exitosamente",
  facial_biometric_uniqueness_confirmed:
    "Unicidad de biometría facial confirmada, registrando rostro ahora...",
  percentage_of_ctc: "Porcentaje del CTC",
  search_patient_name: "Buscar Nombre de Paciente",
  thank_you: "Gracias",
  budget_for: "Presupuesto Para",
  selected_templates: "Plantillas Seleccionadas",
  list_of_services: "Lista de Servicios",
  add_ctc: "Agregar banda CTC",
  view_activity_template: "Ver Plantilla de Actividades",
  successfully_logged_in_for_consultation:
    "Inicio de Sesión Exitoso para la Consulta",
  weekend_present: "Fin de Semana (Presente)",
  task_progress_report_submitted_successfully:
    "Informe de progreso de la tarea enviado con éxito",
  please_give_a_concern: "Por Favor Expresa una Preocupación",
  task_information: "Información de la Tarea",
  doctor_switched_successfully: "Cambio de médico realizado con éxito",
  file_upload_notification:
    "{{userName}} ha subido un nuevo archivo, por favor revisa...",
  country_not_found: "País no encontrado",
  meeting_opens_before:
    "Esta reunión se abre 5 minutos antes de la hora de inicio, por favor únete después de {{dateAndTime}}",
  change: "CAMBIAR",
  probation_message:
    "Estará en período de prueba durante {{probationDay}} días después de comenzar a trabajar. Después de que expire el período de prueba, la dirección podrá confirmar sus servicios o extender el período de prueba. Sin embargo, la Dirección se reserva el derecho de terminar sus servicios sin asignar ningún motivo durante el período de prueba o el período de prueba extendido, dándole un aviso de 24 horas. Si decide abandonar los servicios de la empresa durante el período de prueba, deberá dar un aviso de {{noticePeriod}} días o el salario bruto de {{noticePeriod}} días en lugar del mismo.",
  is_needed_while_onboarding: "Es necesario durante la incorporación",
  queue: "Fila",
  claimedOnActualBasis: "Reclamado en Base Real",
  previously_uploaded_files: "Archivos subidos anteriormente",
  please_select_lead_notify_lead_time:
    "Por favor, seleccione el tiempo de notificación del líder",
  hsn_code: "Código HSN",
  time_zone: "Zona horaria",
  for: "Para",
  safety: "Seguridad",
  fail_to_update_access_file: "Error al actualizar el acceso al archivo",
  currency: "Moneda",
  send_otp: "Enviar OTP",
  leave_meeting: "¡Dejar la reunión!",
  list_of_meetings: "Lista de Reuniones",
  enter_your_message: "Ingrese su mensaje",
  acceptCandidate: "Aceptar Candidato",
  trade_lic_no: "Número de licencia comercial",
  subtitles_loaded_successfully: "Subtítulos cargados con éxito",
  added_private_notes: "Notas privadas añadidas",
  vendor: "Proveedor",
  all_guests_added_successfully:
    "Todos los invitados fueron agregados con éxito",
  probation_confirmation_period: "Periodo de Prueba/Confirmación",
  past_medication_file_pdf: "Archivo de medicación anterior.pdf",
  at_least_one_uppercase_letter: "Al menos una letra mayúscula",
  investigation_unit: "Estudio - Unidad",
  activity_added: "Actividad Agregada",
  start_meeting: "Iniciar Reunión",
  no_storage_define_yet: "No se ha definido ningún almacenamiento todavía !!",
  record_updated_successfully: "Registro actualizado con éxito",
  please_select_at_least_one_gender: "Por favor, seleccione al menos un género",
  reason_over_disagreement: "Motivo de Desacuerdo",
  item_disable_error: "Error al desactivar el elemento",
  slots: "Espacios",
  edit_employee: "Editar Empleado",
  enter_qualification: "Ingresar Calificación",
  payment_intent_failed: "Error al crear la intención de pago",
  chairperson: "Presidente",
  compare: "Comparar",
  suggested_templates: "Plantillas Sugeridas",
  contactTitle: "Contacto",
  go_for_test: "Ir a la prueba",
  item_added_successfully: "Elemento añadido con éxito",
  holiday_already_exists: "El festivo ya existe",
  drive: "Unidad de almacenamiento",
  participantUnavailable:
    "{{userName}} no está disponible para la reunión, no se pueden transferir los derechos del convocador",
  please_provide_your_facial_biometrics_to_mark_attendance:
    "Por favor proporcione sus datos biométricos faciales para marcar la asistencia",
  select_files: "Seleccionar archivos",
  approval_request_will_be_sent_to: "La Solicitud de Aprobación Será Enviada a",
  fileTooLarge:
    "El archivo '{{fileName}}' debe tener menos de {{maxFileSize}} MB",
  holiday_description_for_email: "Descripción del Festivo para el Correo",
  the_selected_time_has_already_passed: "El tiempo seleccionado ya ha pasado",
  previous: "Anterior",
  new_password_should_not_match_the_old_password:
    "La nueva contraseña no debe coincidir con la contraseña anterior",
  document_viewer: "Visor de Documentos",
  failed_to_update_holiday: "Error al actualizar el día festivo",
  prev_purc_rate: "Tasa de Compra Anterior",
  complete_workflows_description:
    "RRHH, Contabilidad, Finanzas, Negocios, Ventas y Marketing, flujos de trabajo personalizables.",
  right_deactivated_successfully: "Derecho desactivado con éxito",
  create_a_ticket: "Crear un ticket",
  select_contract: "Seleccionar Contrato",
  today: "Hoy",
  if_any_leave_type_has_a_specific_expiry_time_line_organizations_may_add_the_same_in_the_Leave_expiry_field:
    "Si algún tipo de permiso tiene una línea de caducidad específica, las organizaciones pueden agregarlo en el campo de Caducidad del Permiso",
  please_upload_excel_file: "Por favor suba un archivo Excel.",
  an_error_occurred_while_activating_the_account:
    "Ocurrió un error al activar la cuenta",
  add_variance: "Agregar Varianza",
  food_name: "Nombre del alimento",
  provide_advance: "Proporcione el anticipo",
  close_template: "Cerrar Plantilla",
  from: "De",
  event_times: "Tiempos del Evento",
  please_upload_less_than: "Por favor suba un archivo de menos de {{size}} MB.",
  added_participants: "Participantes añadidos",
  Please_upload_at_least_one_file: "Por favor, suba al menos un archivo",
  email_verified_helper_text:
    "Correo electrónico verificado con OTP, cambiarlo requerirá re-verificación",
  no_operating_expenditure_added_yet:
    "¡Aún no se ha agregado ningún Gasto Operativo!",
  unauthorized_copies_details:
    "No realizará copias no autorizadas de ninguno de los secretos comerciales o información del empleador sin el consentimiento del mismo, ni retirará ninguno de los secretos comerciales o información de las instalaciones del empleador.",
  onlineDoctorNotice:
    "Solo puede cambiar a doctores en línea de la misma especialidad",
  fail_to_update_salary_head: "Error al actualizar el encabezado de salario",
  from_date: "Desde la Fecha",
  add_rejection_reason_here: "Agregar Razón de Rechazo Aquí",
  reason_for_selection: "Razón de Selección",
  no_activity_added_yet: "¡Aún no se ha agregado ninguna Actividad!",
  please_enter_event_description: "Por favor ingrese la descripción del evento",
  please_fill_the_corresponding_state:
    "Por favor, complete el estado correspondiente",
  meeting_notes: "Notas de la reunión",
  food_allergy: "Alergia alimentaria",
  past_diseases: "Enfermedades Pasadas",
  appointment_booked: "Cita reservada",
  password_policy: "Política de contraseña",
  brand: "Marca",
  grid_view: "Vista de cuadrícula",
  patient: "Paciente",
  select_a_convenient_time: "Seleccione un tiempo conveniente",
  adviser: "Asesor",
  totalDamagedMissing: "Total Dañado y Faltante",
  org_business_types: "Tipos de negocio de la organización",
  file_size_must_be_between_10KB_and_200KB:
    "El tamaño del archivo debe estar entre 10 KB y 200 KB.",
  middle_name: "Primer apellido",
  drive_file_upload_exceeds_max_size:
    "No se puede subir el archivo {{fileName}}, excede el tamaño máximo de archivo de {{maxSize}} MB.",
  cancel: "Cancelar",
  total_value: "Valor Total",
  cancel_facial_biometrics_registration_process:
    "¿Cancelar el proceso de registro de biometría facial?",
  please_select_vendor: "Por favor seleccione el proveedor(es)",
  slot_availability_unavailability_is_updated:
    "La disponibilidad/no disponibilidad de la ranura ha sido actualizada",
  approved_on: "Aprobado el",
  support_dashboard: "Panel de soporte",
  expected_ctc_required: "Se requiere CTC esperado",
  verification:
    "La continuación en su servicio también estará sujeta a la verificación satisfactoria de sus credenciales/testimonios, etc. mencionados en su carta de oferta.",
  selectStartTime: "Por favor, seleccione la 'Hora de inicio' para {{dayName}}",
  twitter: "Twitter",
  failed_to_mark_slot_as_unavailable:
    "Error al marcar la ranura como indisponible",
  biometric_associated_with_another_account:
    "Estas biometrías están asociadas con otra cuenta, continúe con esa cuenta.",
  maximize: "Maximizar",
  search_name_or_email: "Buscar nombre o correo electrónico",
  add_tax_details: "Agregar detalles de impuestos",
  fail_to_sent_otp: "Error al enviar OTP",
  maximum_number_of_days_allowed_for_encashment:
    "Número máximo de Días permitidos para cobro",
  please_enter_your_name: "Por favor, ingrese su nombre",
  search_food: "Buscar comida",
  doctor_deactivated_successfully: "Doctor desactivado con éxito",
  official_phone_number: "Número de teléfono oficial",
  please_select_patient: "Por favor seleccione paciente",
  transcription_details: "Detalles de la transcripción",
  symptoms_cannot_be_empty: "Los síntomas no pueden estar vacíos",
  organization_email_is_required:
    "Se requiere el correo electrónico de la organización",
  ctc_ex: "CTC Ej: 300000",
  positions: "Posiciones:",
  duration: "Duración",
  chats: "Charlas",
  job_summary: "Resumen del Trabajo",
  p_o_date: "Fecha de P.O.",
  an_error_occurred_while_updating_the_otp_service_status:
    "Se produjo un error al actualizar el estado del servicio OTP",
  contract_name: "Nombre del Contrato",
  please_select_option: "Por favor, seleccione una opción para {{metrics}}",
  get_vitals_from_device: "Obtener signos vitales del dispositivo",
  date_of_incorporation: "Fecha de Incorporación",
  religion: "Religión",
  organization_position: "Posición de la organización",
  please_enter_document_name: "Por favor, ingrese el nombre del documento",
  please_enter_no_of_use: "Por favor, ingrese el número de usos",
  one_lower_case_letter: "Una letra minúscula",
  biochemistry_test_requisition_created_successfully_please_wait_for_test_results:
    "Requisición de prueba de bioquímica creada con éxito, por favor espera los resultados de la prueba",
  dashboard: "Panel de Control",
  spo2: "SPO2",
  absenteeism_message:
    "La ausencia por un período continuo de {{absentDays}} ({{absentDaysWords}}) días sin la aprobación previa de su superior, (incluyendo la prolongación de licencia / capacitación) o la notificación escrita en caso de circunstancias imprevistas como una emergencia médica será, por sí misma, prueba de su abandono voluntario de los servicios y resultará en la pérdida de su derecho a los mismos, finalizando automáticamente su relación laboral. Además, se asumirá que ha dejado el servicio por su propia voluntad sin haber cumplido con el período de preaviso, por lo que estará obligado a pagar el salario correspondiente al preaviso según la cláusula de terminación mencionada a continuación.",
  already_uploaded_document: "Documento ya cargado",
  admin_successfully_updated: "Administrador actualizado con éxito",
  sign_up_now: "¡Regístrate Ahora!",
  add_department: "Agregar Departamento",
  notify_to_positions: "Notificar a las posiciones",
  please_select_current_salary: "Por favor, seleccione el salario actual",
  please_select_medicine_name: "Por favor seleccione el nombre del medicamento",
  medical_examination: "Examen médico y documentación:",
  file_share: "Compartir archivo",
  rights: "Derechos",
  greeting: "¡Saludos!",
  health_care_profession: "Profesión de Atención Médica",
  quotation_rate: "Tasa de Cotización",
  last_employee_ids: "Últimos identificadores de empleados",
  feedback: "Comentarios",
  user_already_selected: "{{userName}} ya está seleccionado",
  enter_fees: "Ingresar Tarifas",
  purpose: "Propósito",
  please_provide_location_access_to_mark_attendance:
    "Por favor proporcione acceso a la ubicación para marcar asistencia",
  review_patient: "Revisar paciente",
  employement_demography: "Demografía del Empleo",
  the_webinar_has_been_closed_by_the_convenor:
    "El seminario web ha sido cerrado por el convocador",
  notice_period_required: "Por favor, proporcione el período de aviso",
  booked: "Reservado",
  participantUnavailableChairperson:
    "{{userName}} no está disponible para la reunión, no se pueden transferir los derechos del presidente",
  free_trial_description:
    "Si se registra para una prueba gratuita de nuestros servicios, tendrá acceso a un conjunto de servicios aplicables sin cargo durante el período de prueba, a menos que se termine antes por usted o ZOYEL ONE. El período de prueba termina cuando (i) finaliza el período de prueba gratuito, (ii) comienza el período de suscripción paga de los servicios, o (iii) ZOYEL ONE termina la prueba a su discreción. Es importante tener en cuenta que cualquier dato ingresado en los servicios y cualquier personalización realizada durante la prueba se perderá permanentemente a menos que (i) compre el plan de suscripción paga correspondiente, (ii) adquiera las actualizaciones de servicios aplicables, o (iii) exporte los datos antes de que termine el período de prueba. Tenga en cuenta que durante la prueba gratuita, los servicios se proporcionan 'tal cual'; sin garantía, acuerdo, soporte ni responsabilidad, en la medida permitida por la ley.",
  add_to_queue: "Agregar a la line",
  at_our: "en nuestro",
  create_sheets: "Crear Hojas",
  add_parameter: "Agregar Parámetro",
  gst: "GST",
  select_user_to_add_group:
    "Seleccione un usuario para agregar al grupo (Mínimo {{minUsers}})",
  please_wait_the_file_is_being_downloaded:
    "Por Favor Espere. El archivo se está descargando",
  loi_withdrawal_note:
    "en cuyo caso, esta Carta de Intención quedará retirada, a menos que se acuerde una nueva fecha de manera mutua y por escrito.",
  missingShortLandedUnits: "Unidades Faltantes o Cortas",
  right_added_successfully: "Derecho añadido con éxito",
  attachFile: "Adjuntar archivo",
  no_salary_component_added_yet:
    "No se ha agregado ningún componente salarial todavía !!",
  template_message_for_non_admin:
    "Puedes seleccionar una plantilla que se adapte mejor a tu estilo de comunicación, esto se puede modificar más tarde a través del panel de administración",
  transcendence: "Trascendencia",
  enter_year_of_experience: "Ingresar Años de Experiencia",
  please_select_type: "Por favor seleccione el tipo",
  severity: "Severidad",
  language_updated_successfully: "Idioma actualizado con éxito",
  uploaded_files: "Archivos Subidos",
  please_enter_target_value: "Por favor, introduzca el objetivo",
  t_and_c: "Términos y Condiciones",
  please_enter_comment: "Por favor, ingrese el Comentario",
  search_component: "Buscar componente",
  reviewerEvaluation: "Evaluación del Revisor",
  service_faq: "Preguntas Frecuentes del Servicio",
  no_terms_and_condition_define_yet:
    "No se han definido términos y condiciones todavía !!",
  please_leave_your_valuable_feedback:
    "Por favor, deje sus valiosos comentarios",
  function_department: "Función/Departamento",
  doctor_name_optional: "Nombre del doctor (Opcional)",
  type_your_message_here: "Escriba su mensaje aquí!",
  variance: "Varianza",
  item_type: "Tipo de Ítem",
  vendor_name: "Nombre del Proveedor",
  emailPlaceholder: "Correo Electrónico",
  creating_support_ticket_please_do_not_close_the_page:
    "Creando ticket de soporte, por favor no cierre la página...",
  select_timezone: "Seleccionar zona horaria",
  damagedUnits: "Unidades Dañadas (mojadas, aplastadas, etc.)",
  taskEffectiveness: "Cómo las Tareas ahorran tiempo y hacen equipos efectivos",
  maxFileSizeError: "Tamaño máximo de archivo permitido {{size}}MB",
  please_leave_your_valuable_feedback_regarding_your_overall_experience_with_the_video_consultation:
    "Por favor, deje sus valiosos comentarios sobre su experiencia general con la consulta por video",
  fees: "Honorarios",
  please_enter_or_fetch_current_longitude:
    "Por favor, ingrese o obtenga la longitud actual",
  changing_website_template_please_wait_and_do_not_close_the_page:
    "Cambiando plantilla del sitio web, por favor espere y no cierre la página...",
  maximum_leaves: "Máximo de Días de Permiso",
  type_here: "Escriba aquí....",
  qualifications: "Calificaciones",
  select_button: "Seleccionar",
  view: "Ver",
  enable: "Habilitar",
  pay_slip: "Recibo de Pago",
  expires_on: "Expira el",
  files_download_successfully: "Archivos se han descargado con éxito",
  address_concern: "Atender Preocupación",
  eg_35: "ej: 35",
  reassign: "Reasignar",
  please_add_designation: "Por favor, añada Designación",
  file_not_allowed_please_upload_a_valid_file:
    "Archivo no permitido. Por favor suba un archivo válido.",
  templates: "Plantillas",
  select_orgbusiness_types_placeholder: "Tipo de negocio de la organización",
  view_budget: "Ver presupuesto",
  gridlines: "Líneas de la Cuadrícula",
  please_enter_component_name: "Por favor, ingrese el nombre del componente",
  please_define_designation_name:
    "Por favor, defina el nombre de la designación",
  salary_cannot_be_negative: "El salario no puede ser negativo",
  search_employee: "Buscar Empleado",
  add_terms_and_conditions: "Agregar Términos y Condiciones",
  organization_created_successfully: "Organización creada con éxito",
  virus_free: "Que el Sitio web estará libre de virus o componentes dañinos.",
  remove: "Eliminar",
  jan28: "28 de enero de 2024",
  changed_the_convenor: "Cambiado el convocador",
  event_days: "Días del evento",
  critical_rights: "Derechos críticos",
  reporting_head: "Jefe a quien reporta",
  please_enter_a_valid_file_name:
    "Por favor, ingrese un nombre de archivo válido",
  no: "No",
  manufacturer: "el/la fabricante",
  targets: "Metas",
  i_have_checked_statutory_compliance: "He verificado el Cumplimiento Legal",
  please_select_start_time: "Por favor, Seleccione Hora de Inicio",
  recommendForPromotion: "Recomendar para Promoción",
  job_kra: "Áreas Clave de Resultados",
  add_policy: "Agregar Política",
  complete_workflows: "Flujos de Trabajo Completos",
  timeline_views: "Vistas cronológicas",
  please_enter_area_colony: "Por favor, ingrese el área o colonia",
  current_password: "Contraseña Actual",
  word: "Palabra",
  divorced: "Divorciado",
  noDoctorOnlineError: "No hay doctores en línea",
  client: "Cliente",
  please_select_address_change_notice_period:
    "Por favor, seleccione el período de notificación del cambio de dirección",
  minimum_leave_balance_needed_after_encashment_cannot_more_the_three_digit:
    "El saldo mínimo de permiso necesario después del cobro no puede ser más de 3 dígitos",
  terms_and_condition_list: "Lista de Términos y Condiciones",
  test_name: "Nombre de la Prueba",
  delete_terms_and_conditions: "Eliminar Términos y Condiciones",
  password: "Contraseña",
  recurringDocument: "Documento Recurrente",
  no_preview_available: "No hay vista previa disponible",
  default_uom: "UOM Predeterminado",
  storage_upgraded_successfully:
    "¡Almacenamiento de {{userName}} actualizado con éxito!",
  direct: "Directo",
  female: "Mujer",
  items_list: "Lista de Artículos",
  scheduler: "Calendario",
  otherExpenses: "Otros Gastos",
  wed: "Mié",
  cgst: "CGST",
  do_you_want_to_delete_this_external_user:
    "¿Desea eliminar este Usuario Externo?",
  allow_half_day: "Permitir Medio Día",
  roundoff: "Redondeo",
  notes_added_successfully: "Notas añadidas con éxito",
  accounts: "Cuentas",
  please_enter_the_descriptions: "Por favor, introduzca las descripciones",
  reimbursement: "Reembolso",
  temperature_range_error_in_celsius:
    "La temperatura debe estar entre 32.22 y 41.10 grados Celsius",
  configuration_head: "Cabezal de configuración\n",
  meeting_created_successfully: "Reunión creada con éxito",
  allowed_file_types: "Tipos de archivo permitidos",
  next_review: "Próxima revisión",
  audio_note: "Nota de audio",
  last_7_days: "Últimos 7 Días",
  regular: "Regular",
  please_give_a_comment: "Por Favor escriba un Comentario ",
  subtotal: "Subtotal",
  recurringExpenditure: "Gasto Recurriente",
  maximum_three_languages_can_be_selected:
    "Se pueden seleccionar un máximo de 3 idiomas",
  verify_item_sucess: "verificar artículo con éxito",
  do_you_want_to_continue: "¿Desea continuar?",
  joint_venture_company: "Empresa Conjunta",
  face_deleted_successfully: "Rostro eliminado con éxito",
  please_give_patient_complaints:
    "Por favor, ingrese las Síntomas del paciente",
  please_select_symptom: "Por favor seleccione el síntoma",
  you_cannot_change_password_for_this_email:
    "No Puedes Cambiar la Contraseña Para Este correo",
  please_select_file_less_than:
    "Por favor seleccione un archivo de menos de {{maxFileSize}}MB",
  old_password: "Contraseña anterior",
  policy_deleted_successfully: "Política eliminada con éxito",
  confirm_facial_biometrics_deletion:
    "¿Confirmar la eliminación de biometría facial?",
  the_file_cannot_be_downloaded: "El archivo no se puede descargar",
  extensions_allowed: "Extensiones permitidas",
  default_landing_tab: "Pestaña de inicio predeterminada",
  select_parameters: "Seleccionar parámetros",
  drug_safety: "Advertencias de medicamentos",
  or: "O",
  pms: "PMS",
  last_consulted_with: "Última Consulta con",
  add_cancelation_reason_here: "Agregar Razón de Cancelación Aquí",
  data_updated_successfully: "Datos actualizados con éxito",
  information: "Información",
  enter_kpi: "Ingrese KPI",
  pan: "PAN",
  discountPercent: "Descuento %",
  add_quotation: "Agregar Cotización",
  select_reporting_head: "Seleccionar jefe de informes",
  services: "Servicios",
  book_consultation: "Reservar consulta",
  prognosis: "Pronóstico",
  guarantee_warantee_sla: "Garantía/Certificación/SLA",
  how_it_works: "Cómo Funciona",
  consulted_on: "Consultado el",
  please_enter_your_email_id: "Por favor, ingrese su correo electrónico",
  fileAttached: "{{fileName}} adjunto",
  please_enter_discount_precentage:
    "Por favor, ingrese el porcentaje de descuento",
  select_date: "Seleccionar Fecha",
  updated_amount: "Monto Actualizado",
  history_risk_factor: "Historia y factor de riesgo",
  please_enter_item_type: "Por favor, ingrese el tipo de artículo",
  your_join_meeting_request_was_rejected_by_the_moderator:
    "Su solicitud de unirse a la reunión fue rechazada por el moderador",
  newPosition: "Nueva Posición",
  tangible: "Tangible",
  enter_amount: "Ingresar Monto",
  type_to_search: "Escriba para buscar",
  salaryHead: "Cabezal Salarial",
  leave_request_successfull: "Solicitud de permiso exitosa",
  patient_name: "Nombre del Paciente",
  please_enter_gross_per_month: "Por favor, ingrese el bruto por mes",
  click_add_coupon_button_to_create_new_coupon:
    "Haga clic en el Botón de Agregar Cupón para crear un nuevo Cupón",
  the_results_will_appear_automatically_once_the_test_is_done_it_will_take_8_to_15_mins_complete:
    "Los resultados aparecerán automáticamente una vez realizada la prueba, que tardará entre 8 y 15 minutos en completarse.",
  joining_meeting_please_wait: "Uniéndose a la reunión, por favor espere",
  employee_bank_details_updated_successfully:
    "Detalles bancarios del empleado actualizados con éxito",
  download: "Descargar",
  agreement_text:
    "Este acuerdo regula el uso de Zoyel One Suite, un software de productividad y colaboración empresarial en línea, proporcionado por Zoyel One. Establece los términos y condiciones entre usted (referido como 'Usted' o 'Su') y Zoyel One (referido como 'Zoyel One').",
  list_of_accounts_and_invoices: "Lista de Cuentas y Facturas",
  google_search: "Búsqueda en Google",
  activateAccount: "Activar cuenta",
  thirty_days: "30 Días",
  select_sub_system: "Seleccionar Sub Sistema",
  skills: "Habilidades",
  replacement: "Reemplazo",
  bank_account: "Cuenta Bancaria",
  heading: "Encabezado",
  additional_documents_for_meeting: "Documentos adicionales para la reunión",
  validate_item_ledger: "Validar Libro Mayor de Ítems",
  charge_description: "Descripción del Cargo",
  value_not_provided: "Valor no proporcionado",
  add_new_experience: "Agregar Nueva Experiencia",
  delete_education: "Eliminar Educación",
  pay_amount: "Pagar ${{amount}}",
  remove_test: "Eliminar prueba",
  error_downloading_file: "Error al descargar el archivo: {{name}}",
  expense_approval_request_successful:
    "Solicitud de aprobación de gastos exitosa",
  update_password: "Actualizar contraseña",
  search_by_dr: "Buscar por Dr",
  doctor_note: "Nota del Doctor",
  patient_complaints: "Síntomas del paciente",
  with_ahlans_asynchronous_and_synchronous_collaboration_suite_allow_work_to_happen_with_and_against_time_zones_from_anywhere:
    "Con la suite de colaboración sincrónica y asincrónica de Ahlan, permita que el trabajo se realice con y contra zonas horarias desde cualquier lugar",
  metrics: "Métricas",
  task_review_meeting: "Reunión de Revisión de Tareas",
  second_name: "Primer apellido",
  line_total: "Total de Línea",
  files_uploaded_successfully: "Archivo(s) subido(s) con éxito",
  failed_to_record_your_concern_please_try_again_later:
    "Error al registrar su preocupación. Por favor, intente nuevamente más tarde.",
  changed_the_chairperson: "Cambiado el presidente",
  do_you_want_to_delete_this_committee: "¿Desea eliminar este Comité?",
  please_select_position: "Por favor, seleccione la posición",
  approvalRequestWillBeSentTo: "La solicitud de aprobación se enviará a",
  applicable_for_employees_who_joined_before:
    "Aplicable a los empleados que se incorporaron antes de",
  select_and_add_capital_expenditure: "Seleccionar y Agregar Gasto de Capital",
  full_screen: "Pantalla Completa",
  holiday_deleted_successfully: "Día festivo eliminado con éxito",
  enter_remarks_here: "Ingrese observaciones aquí",
  please_enter_purpose: "Por favor, ingrese el propósito",
  upi_id: "ID UPI",
  guardian_relationship: "Relación con el Tutor",
  active: "Activo",
  employee: "Empleado",
  special_resolution: "Resolución especial",
  add_designation_hierarchy: "Agregar Designación/Jerarquía",
  please_enter_your_password: "Por favor, ingrese su contraseña",
  files_added: "Archivos Agregados",
  settings: "Configuraciones",
  salary_head_already_exists: "El concepto salarial ya existe",
  appoinment_could_not_be_canceled: "No se pudo cancelar la cita",
  please_select_gender: "Por favor seleccione el género",
  please_provide_proper_skills_and_attribute:
    "Por favor, proporcione habilidades y atributos adecuados",
  no_consultation_data_is_found: "No se encontraron datos de consulta",
  please_confirm_password: "Por favor confirme su contraseña.",
  no_investication_admin_data_found:
    "No se encontraron datos administrativos de estudio",
  unit: "Unidad",
  meeting_with_provided_room_number_does_not_exists_please_check_once:
    "La reunión con el número de sala proporcionado no existe, por favor revisa",
  budget_amount: "Monto del Presupuesto",
  content_ownership_description:
    "Reconocemos y respetamos sus derechos de propiedad sobre el contenido creado o almacenado por usted. Usted conserva la propiedad de dicho contenido. A menos que se autorice específicamente, su uso de los servicios no otorga a ZOYEL ONE una licencia para utilizar, reproducir, adaptar, modificar, publicar ni distribuir el contenido que usted crea o almacena en su cuenta de usuario para fines comerciales, de marketing o similares. Sin embargo, usted otorga a ZOYEL ONE permiso para acceder, copiar, distribuir, almacenar, transmitir, reformatear, mostrar públicamente y ejecutar públicamente el contenido en su cuenta de usuario, exclusivamente con el fin de proporcionar los servicios a usted.",
  submit_your_feedback: "Enviar sus comentarios",
  close: "Cerrar",
  meeting_agenda: "Agenda de la reunión",
  search_coupon: "Buscar Cupón",
  do_you_want_to_activate_this_salary_component:
    "¿Desea activar este componente salarial?",
  priority: "Prioridad",
  investigation: "Estudios",
  p_s: "P/S",
  search_by_patient_id: "Buscar por ID de paciente",
  atleast_one_language_is_mandatory: "Al menos un idioma es obligatorio",
  select_location: "Seleccionar Ubicación",
  department_name: "Nombre del Departamento",
  could_not_generate_mom: "No se pudo generar el MOM",
  add_new_investigation: "Agregar nueva estudio",
  reconnect_doctor: "Reconectar Doctor",
  passwords_mismatch: "Las contraseñas no coinciden",
  please_enter_target:
    "Por favor, introduzca el objetivo para {{label}} {{title}} en el índice {{index}}",
  subscribeText:
    "Suscríbete a nuestro Boletín para recibir las últimas noticias",
  assignee_of_task: "Asignado a Tarea",
  breach_terms:
    "Tu incumplimiento de cualquiera de los términos y condiciones de este acuerdo.",
  look_forward_to_working_with_you: "Esperamos trabajar con usted.",
  previous_procedure: "Procedimiento Anterior",
  failed_to_reschedule_slot: "Error al reprogramar la plaza",
  select_activity: "Seleccionar Actividad",
  file_created_successfully: "Archivo creado con éxito",
  reviewer_evaluation: "Evaluación del Revisor",
  errorMessageSomethingWrong: "Ocurrió un Error.",
  menu: "Menú",
  meeting_transcript: "Transcripción de la reunión",
  patient_profile: "perfil del paciente",
  prescription_submitted_successfully: "Receta enviada con éxito",
  end_meeting_for_all: "¡Terminar la reunión para todos!",
  patient_data_visit_sucess: "Datos del paciente enviados con éxito",
  addMorePatient: "¿Agregar más paciente?",
  opened: "Abierto",
  no_more_subtitles_present: "No hay más subtítulos disponibles",
  languages: "Idiomas",
  genericMedicine: "Medicina Genérica",
  proceed_to_book_consultation: "Proceder a reservar consulta",
  please_fill_the_corresponding_city:
    "Por favor, complete la ciudad correspondiente",
  update_component_category: "Actualizar Categoría del Componente",
  email_id: "Correo electrónico",
  temperature: "Temperatura",
  invalid_file_format:
    "Formato de archivo no válido. Formatos permitidos: PNG, JPG, JPEG.",
  cannotMarkUnavailable:
    "No puede marcarse como no disponible para el {{eventType}} dentro de los 5 minutos o después de la hora de inicio de {{eventType}}",
  treatment_plan: "Plan de tratamiento",
  no_units_define_yet: "No se han definido unidades todavía !!",
  please_enter_the_reason_for_edit: "Por favor ingrese el motivo de la edición",
  shipTo: "Enviar a",
  family_disease_history: "Historia de enfermedades familiares",
  selectInterviewers: "Por favor, Seleccione Entrevistadores",
  fail_to_update_external_user: "Error al actualizar el usuario externo",
  template_message_for_admin:
    "Selecciona para cambiar la plantilla de tu sitio web, una vez cambiada, todos los datos del sitio web se perderán",
  create_magic: "Crear magia",
  description_specification_mandatory: "Descripción/Especificación obligatoria",
  selectDuration: "Por favor, seleccione la 'Duración' para {{dayName}}",
  no_files_folder_found: "No se encontraron archivos / carpeta",
  please_select_probation_time: "Por favor, seleccione el tiempo de prueba",
  welcome_message:
    "Nos complace darle la bienvenida a nuestra Organización y esperamos una relación larga y gratificante. No dude en comunicarse con el Departamento de Recursos Humanos siempre que sea necesario, y el Departamento estará encantado de asistirle.",
  file_size_must_be_between_ten_kb_and_two_hundred_kb:
    "El tamaño del archivo debe estar entre 10KB y 200KB",
  leave_call: "Salir de la llamada",
  please_select_or_enter_a_leave_type:
    "Por favor, seleccione o ingrese un tipo de permiso",
  register_face: "Registrar rostro",
  value: "Valor",
  unit_name: "Nombre de la Unidad:",
  please_wait_file_is_getting_get_downloaded:
    "Por favor espere. El archivo se está descargando",
  aboutText:
    "Zoyel One integra una amplia gama de funcionalidades en una sola plataforma, eliminando la necesidad de múltiples herramientas dispares. Este enfoque unificado simplifica las operaciones, reduce costos y mejora la productividad al proporcionar todo lo que una empresa necesita en un solo lugar.",
  search_doctor_or_speciality: "Buscar Doctor o especialidad",
  disc: "Descuento",
  add_candidate: "Agregar Candidato",
  holiday_working_day: "Día Festivo / Día Laboral",
  Fail_to_make_leave_request: "Error al realizar la solicitud de permiso",
  review_date: "Fecha de Revisión",
  add_new_certificate: "Agregar Nuevo Certificado",
  please_enter_committee_name: "Por favor, ingrese el nombre del comité",
  terms_and_conditions_were_successfully_deleted:
    "Los términos y condiciones fueron eliminados con éxito.",
  unit_price: "Precio Unitario",
  official_contact_no: "Número de contacto oficial",
  please_select_participants: "Por favor, seleccione participantes",
  transcription: "Transcripción",
  please_enter_subactivity_work_name:
    "Por favor, ingrese el nombre de la subctividad o trabajo",
  please_enter_other_terms_and_conditions:
    "Por favor, ingrese otros términos y condiciones",
  please_enter_percentage: "Por favor, ingrese el porcentaje",
  this_meeting_opens_5_minutes_before_the_start_time_please_join_after:
    "Esta reunión se abre 5 minutos antes de la hora de inicio, por favor únete después",
  fail_to_create_folder: "Error al crear la carpeta",
  add_other_document: "Agregar otro documento",
  please_enter_meeting_topic: "Por favor, ingrese el tema de la reunión",
  edit_designation_hierarchy: "Editar Designación/Jerarquía",
  record_already_exist: "El registro ya existe",
  mealCost: "Costo de Comida",
  technician: "Técnico",
  create_new_task: "Crear Nueva Tarea",
  failed_to_switch_doctor_please_try_again:
    "No se pudo cambiar de médico. Inténtelo de nuevo",
  deactive: "Inactivo",
  appointmentMessage:
    "{{fullName}} ha programado una consulta con {{doctorName}} el {{date}} de {{fromTime}} a {{toTime}}. Por favor asegúrese de llegar al centro al menos 15 minutos antes de su hora de cita para evitar retrasos y permitir un proceso de consulta sin problemas.",
  record_patient_complaints: "Registrar Síntomas del paciente",
  bank_branch: "Sucursal Bancaria",
  admin_account_description:
    "Al registrarse para una cuenta de su organización, se creará una cuenta de administrador que tendrá el derecho de configurar los servicios según sus requisitos.",
  please_enter_email_id: "Por favor ingrese correo electrónico",
  please_provide_disease_name:
    "Por favor proporcione el nombre de la enfermedad",
  cycleEndDate: "hasta",
  select_parent_unit: "Seleccionar Unidad Principal (si la hay)",
  one_time_event: "Evento Único",
  metric_name: "Nombre de la métrica",
  send_otp_again: "Enviar OTP nuevamente",
  prior_leave_request: "Solicitud de Permiso Anticipada",
  create_organization: "Crear organización",
  current_organization: "Nombre de la Organización Actual",
  insert_emoji: "Insertar emoji",
  search_medicine_brand_generic: "Buscar medicamento Marca / Genérico\n\n",
  please_enter_receiver_email_id:
    "Por favor, ingrese el correo electrónico del receptor",
  plese_enter_concern: "Por favor, ingrese la Preocupación",
  appoinment_could_not_be_rescheduled: "No se pudo reprogramar la cita",
  responsible_person_name: "Nombre de la Persona Responsable",
  synchronous_and_asynchronous_meetings_scheduled_and_instant_with_private_rooms_and_webinars:
    "Reuniones sincrónicas y asincrónicas, programadas y instantáneas, con salas privadas y seminarios web",
  assign_recipients: "Asignar destinatarios",
  link_task: "Vincular tarea",
  click_here_to_upload_sign: "Haga clic aquí para cargar la firma",
  no_access_permission_added_yet:
    "¡Aún no se ha agregado ningún Permiso de Acceso!",
  please_enter_house_no_building_name:
    "Por favor, ingrese el número de casa o el nombre del edificio",
  leave_rule_update_already_exists: "La regla de licencia ya existe",
  you_have_entered_a_room_number_please_use_join:
    "Ha introducido un número de sala, por favor use 'Unirse'",
  enter_medical_license_number: "Ingresar Número de Cédula Profesional",
  item_already_present: "El elemento ya está presente",
  slots_unavailable: "Espacios No Disponibles",
  cannotCancelEvent:
    "No puede cancelar el {{eventType}} dentro de los 5 minutos o después de la hora de inicio de {{eventType}}",
  please_select_completion_time: "Por favor seleccione tiempo de finalización",
  new_and_confirm_password_should_match:
    "La nueva contraseña y la confirmación deben coincidir",
  emptyAndLightUnits: "Unidades Vacías y Ligeras",
  please_provide_lifestyle: "Por favor proporcione el estilo de vida",
  igst_percentage: "IGST %",
  please_give_some_unavailability_reason:
    "Por favor, proporcione una razón para la no disponibilidad...",
  please_select_users: "Por Favor Selecciona Usuarios",
  admin_rights_granted: "Derechos de administrador otorgados",
  search_patient: "Buscar paciente",
  treasurer: "Tesorero",
  list_of_Items: "Lista de Elementos",
  acceptance: "ACEPTACIÓN",
  please_wait_redirecting_to_your_organization_home_page:
    "Por favor espere, redirigiendo a la página de inicio de su organización",
  count: "Conteo",
  confirmation: "Confirmación",
  please_provide_illness_name:
    "Por favor proporcione el nombre de la enfermedad",
  enter_policy_name: "Ingresar Nombre de la Política",
  land_mark: "Punto de Referencia",
  end_time: "Hora de Fin",
  approve: "Aprobar",
  assign_recipient: "Asignar Destinatario",
  annually: "Anualmente",
  file_viewer: "Visor de archivos",
  send_for_filter: "Enviar para Filtrar",
  audio: "Audio",
  employee_demography: "Demografía del Empleado",
  please_fill_employment_id: "Por favor, introduzca el ID de empleo",
  employee_data_updated_successfully:
    "Datos del empleado actualizados con éxito",
  please_select_gross_or_basic: "Por favor, seleccione bruto o básico",
  select_orders: "Seleccionar Órdenes",
  maximum_amount_should_be_greater_than_minimum_amount:
    "La cantidad máxima debe ser mayor que la cantidad mínima",
  vendor_approval_has_been_submitted:
    "La aprobación del proveedor ha sido enviada.",
  attend: "Asistir",
  housing_no_building_name: "Número de vivienda, nombre del edificio",
  date: "Fecha",
  please_enter_private_notes: "Por favor, ingrese notas privadas",
  address_change_notice: "Aviso de Cambio de Dirección (en días)",
  combination_with_other_leaves: "Combinación con Otros Permisos",
  capital_expenditure: "Gasto de Capital",
  department_delete_error: "Error al eliminar el departamento",
  edit_organization: "Editar Organización",
  failed_to_log_in_for_consultation_please_try_again:
    "Error al Iniciar Sesión para la Consulta. Por Favor, Inténtelo de Nuevo.",
  no_specific_date: "Sin Fecha Específica",
  special_resolution_name: "Nombre de la resolución especial",
  employement_status: "Estado del Empleo",
  admin: "Administrador",
  provide_manpower_data: "Proporcione al menos un dato de personal",
  please_select_doctor: "Por favor seleccione doctor",
  mark_as: "Marcar Como",
  please_enter_fees: "Por favor, ingrese las tarifas",
  authorized_person: "Persona Autorizada",
  account_number_and_confirm_account_number_should_be_the_same:
    "El Número de Cuenta y Confirmar Número de Cuenta deben ser iguales",
  passwords_do_not_match: "Las contraseñas no coinciden.",
  select_department: "Seleccionar departamento",
  reset_form: "Restablecer Formulario",
  list_of_function: "Lista de Funciones",
  something_went_wrong: "Algo salió mal",
  no_booked_doctor_slots_found:
    "No se encontraron horarios de doctor reservados",
  examination_name: "Nombre del Examen",
  expected_ctc: "CTC Esperado",
  upload_form_pc: "Subir",
  api_name: "Nombre de la API",
  rejection_reason: "Motivo de Rechazo",
  language: "Idioma",
  corresponding_address: "Dirección Correspondiente",
  deleted_participants: "Participantes eliminados",
  total: "Total",
  discount_percent: "Descuento (1-100 %)",
  acceptance_and_indemnity:
    "Al aceptar estos términos, usted reconoce y acepta indemnizar completamente y proteger a ZOYEL ONE, incluyendo sus funcionarios, directores, empleados, proveedores y afiliados, de cualquier pérdida, daño, multa, gasto (incluidos honorarios y costos legales) y responsabilidad. Esta indemnización se aplica a reclamaciones derivadas de o relacionadas con su uso de los servicios, ya sea por violación de los derechos de otra parte, cualquier ley, cualquier disposición del acuerdo o cualquier otra reclamación, a menos que dicho uso sea expresamente autorizado por ZOYEL ONE.",
  contact: "Contacto",
  search_man_power: "Buscar Mano de Obra",
  error_correction:
    "Que los defectos o errores en el Sitio web o el Contenido, ya sean errores humanos o informáticos, serán corregidos.",
  chat: "Chat",
  consultations: "Consultas",
  last_modified: "Última modificación",
  view_attachment: "Ver Adjunto",
  contract_wise_pending_task: "Tarea Pendiente por Contrato",
  creating_org_message: "Creando organización, por favor espere...",
  updated_roster: "Lista actualizada",
  enter_file_name: "Ingresa el Nombre del Archivo",
  serial_number: "Número de Serie",
  maximum_number_of_days_allowed_for_encashment_cannot_more_the_three_digit:
    "El número máximo de días permitidos para el canje no puede ser más de 3 dígitos",
  search_by_brand: "Buscar por marca",
  generic: "Genérico",
  please_select_date_of_birth: "Por favor seleccione la fecha de nacimiento",
  selectEffectiveDate:
    "Por favor, seleccione la Fecha de Efectividad para la Posición Adicional en la Posición Adicional {{index}}",
  unauthorized_copies: "Copias no autorizadas",
  annual_ctc: "CTC Anual",
  enter_title: "Ingrese Título",
  please_select_category: "Por favor, seleccione una categoría",
  add_contract: "Agregar Contrato",
  applicable_for_leaves_that_can_be_carried_forward_to_the_next_calender_year:
    "Aplicable para permisos que pueden ser acumulados para el próximo año calendario",
  selfRating: "Autoevaluación",
  moderator_rights: "Derechos de moderador",
  budget_name: "Nombre del Presupuesto",
  t_and_c_heading: "Encabezado de términos y condiciones",
  receiver_name: "Nombre del Receptor",
  organization_info: "Información de la Organización",
  type_of_position: "TIPO DE POSICIÓN",
  organization_name: "Nombre de la organización",
  add_skills: "Agregar Habilidades",
  pleaseEnterBody: "Por favor ingrese el cuerpo de los Términos y Condiciones.",
  send_message: "Enviar Mensaje",
  select_and_drag_to_set_you_timing:
    "Seleccionar y arrastrar para establecer el horario",
  approved_rejected: "Aprobado/Rechazado",
  notifications: "Notificaciones",
  accept: "Aceptar",
  rate: "Tarifa",
  prescription_time: "Hora de la Prescripción",
  please_select_notify_to_position:
    "Por favor, seleccione notificar a la posición",
  please_enter_contract_owner: "Por favor, ingrese el propietario del contrato",
  prescription: "Receta",
  breakout_rooms: "Salas de grupos",
  please_provide_a_subdomain_name:
    "Por favor, proporcione un nombre de subdominio",
  invalidFileExtension: "Extensión de archivo no válida",
  enter_number_of_months: "Ingresar número de meses",
  group_name: "Nombre del grupo",
  mark_as_completed: "Marcar como completado",
  with_multi_language_timezone_support_let_work_go_with_teams_clients_and_vendors_across_the_world:
    "Con soporte multilingüe y de zonas horarias, deje que el trabajo fluya con equipos, clientes y proveedores de todo el mundo",
  downloading_file_please_wait: "Descargando archivo, por favor espera",
  default_shift: "Turno Predeterminado",
  click_pencil_button_to_edit_and_save:
    "Haga clic en el botón del lápiz para editar y guardar",
  last_name: "Segundo apellido",
  no_budget_define_yet: "¡No se ha Definido Ningún Presupuesto aún!",
  blood_pressure: "Presión arterial",
  no_endorsements: "SIN RESPALDOS",
  edit_access_permission: "Editar Permisos de Acceso",
  no_meeting_notes_present: "No hay notas de la reunión presentes",
  open_file: "Abrir archivo",
  absent: "Ausente",
  third_party_actions: "Los actos, omisiones o conductas de cualquier tercero.",
  slides: "Presentaciones",
  your_progress_of_task_cant_not_decrease:
    "Tu Progreso en la Tarea No Puede Disminuir",
  insurance_provider_no: "Número del Proveedor de Seguro",
  view_resume: "Ver Currículum",
  quick_actions: "Acciones Rápidas",
  acceptance_text_details:
    "Es importante que leas todos los términos y condiciones siguientes cuidadosamente. Este Acuerdo de Términos de Uso ('Acuerdo') es un acuerdo legal entre tú y el propietario y operador de Zoya Technologies LLC ('Propietario') de este sitio web (el 'Sitio web'). Establece los términos y condiciones bajo los cuales puedes acceder y usar el Sitio web y todos los materiales escritos y otros materiales mostrados o puestos a disposición a través del Sitio web, incluidos, sin limitación, artículos, texto, fotografías, imágenes, ilustraciones, clips de audio, clips de video, software y código informático (el 'Contenido'). Al acceder y usar el Sitio web, indicas tu aceptación de estar sujeto a los términos y condiciones de este Acuerdo. Si no aceptas estos términos y condiciones, no debes acceder ni usar el Sitio web. El Propietario puede revisar este Acuerdo en cualquier momento actualizando esta publicación. El uso del Sitio web después de que se publiquen dichos cambios significará tu aceptación de estos términos revisados. Debes visitar esta página periódicamente para revisar este Acuerdo.",
  medium: "Media",
  select_org_type_placeholder: "Seleccionar tipo de organización",
  ex_relieving_certificate_from_the_previous_employer:
    "Ej: Certificado de Liberación del Empleador Anterior",
  variance_specification: "Especificación de Varianza",
  personal_phone_no: "Teléfono Personal",
  please_enter_or_fetch_current_latitude:
    "Por favor, ingrese o obtenga la latitud actual",
  viewer: "Visor",
  already_exists_replace_file_all_data_will_be_lost:
    "ya existe! Reemplace el archivo, se perderán todos los datos",
  leave_accrued_monthly: "Permiso Acumulado Mensualmente",
  complaints: "Síntomas",
  jd: "DP",
  quotation_submitted: "Cotización enviada",
  prebuild_avatar: "Avatar preconstruido",
  fail_to_update_organization_details:
    "Error al actualizar los detalles de la organización",
  notes_deleted_successfully: "Notas eliminadas con éxito",
  diagnosis: "Diagnóstico",
  max_files_that_can_be_uploaded: "Máximo de archivos que se pueden subir",
  fetch_gps_longitude_and_latitude: "Obtener Longitud y Latitud GPS",
  created_by_assigned_by: "Creado por/Asignado por",
  add_test_result: "Agregar Resultado de Prueba",
  remuneration_package: "Paquete de Remuneración",
  auth_person: "Nombre de la persona autorizada",
  please_select_a_user: "Por Favor Selecciona un Usuario",
  leave_accrued_yearly: "Permiso Acumulado Anualmente",
  fail_to_cancel_leave_request: "Error al cancelar la solicitud de permiso",
  forgot_password: "Contraseña olvidada",
  drop_here_to_upload_files: "Deje aquí para cargar archivos",
  please_select_investigation: "Por favor, seleccione estudio",
  fileAlreadyUploaded: "'{{fileName}}' ya ha sido subido",
  transactionType: "Tipo de Transacción",
  right_updated_successfully: "Derecho actualizado con éxito",
  contact_details_optional: "Detalles de Contacto (Opcional)",
  account_details: "Detalles de la Cuenta",
  your_facial_biometrics_are_already_registered_you_can_delete_and_register_again:
    "Sus biometrías faciales ya están registradas, puede eliminarlas y registrarse nuevamente",
  and: "Y",
  execute_on_last_date_of_month: "Ejecutar en la Última Fecha del Mes",
  please_select_duration: "Por favor seleccione la duración",
  zoom_in: "Acercar",
  last_updated: "Última actualización",
  meeting_date: "Fecha de la reunión",
  leave_balance_upload_successfully: "Saldo de licencia subido con éxito",
  trailer_no: "Nro. de Remolque",
  balance_sheet_maintain_by_own: "Mantenimiento del balance por cuenta propia",
  public_limited_company: "Sociedad Anónima Pública",
  organization_address_is_required:
    "Se requiere la dirección de la organización",
  service_name: "Nombre del Servicio",
  update_comment_here: "Actualizar comentario aquí",
  add_past_illness: "Agregar enfermedad pasada",
  assign_observers: "Asignar Observadores",
  amount: "Monto",
  symptoms: "Síntomas",
  please_enter_valid_latitude_range:
    "Por favor, ingrese un rango de latitud válido entre -90 y 90",
  attach_license_document: "Adjuntar Documento de Licencia",
  end_time_should_be_after_start_time:
    "La hora de finalización debe ser después de la hora de inicio",
  batchNo: "Nro. de Lote",
  Webinar_title: "Título del Webinar",
  logo_updated_successfully: "Logo actualizado con éxito",
  refered_by: "Referido por",
  reagent: "Reactivo",
  replace: "Reemplazar",
  team_hr: "Equipo de RRHH",
  dermatologist_hair_transplant_surgeon:
    "Dermatólogo y cirujano de trasplante capilar",
  prescription_type: "Tipo de prescripción",
  postponed: "Pospuesto",
  select_priority: "Seleccionar Prioridad",
  hierarchy_name: "Nombre de la Jerarquía",
  please_select_user: "Por favor seleccione usuario",
  cin: "Número de identificación de la empresa",
  expected_effective_date: "Fecha Efectiva Esperada",
  attach_file: "Adjuntar archivo",
  select_user: "Seleccionar Usuario",
  yearly: "Anual",
  there_was_an_issue_submitting_the_vendor_approval_Please_try_again:
    "Hubo un problema al enviar la aprobación del proveedor. Por favor, intente nuevamente.",
  flat_amount: "Monto Fijo",
  allInOne: "Zoyel One: La Solución Empresarial Todo en Uno",
  slot_marked_as_available_successfully:
    "Plaza marcada como Disponible con éxito",
  note: "Nota",
  create_and_open_file: "Crear y Abrir Archivo",
  please_enter_a_valid_folder_name:
    "Por favor, ingrese un nombre de carpeta válido",
  both: "Ambos",
  admin_dashboard: "Panel de Administración",
  an_error_occurred_while_activated_salary_component:
    "Se produjo un error al activar el componente salarial",
  add_employee: "Agregar Empleado",
  patient_leave_center: "Paciente Sale del Centro",
  featuredIn: "Zoyel presentado en SecCon 2024",
  do_you_want_to_delete_this_unit: "¿Desea eliminar esta Unidad?",
  week_off: "Semana libre",
  no_unread_chat_available: "No hay chats no leídos disponibles",
  max_wage: "Salario máximo",
  enter_user_ctc_after_hike: "Introduzca el usuario ctc después de la caminata",
  advance_amount_cannot_be_zero_or_empty:
    "El monto adelantado no puede ser cero o estar vacío",
  vendor_quotation_added_successfully:
    "Cotización del proveedor añadida con éxito.",
  Please_save_the_additional_charges:
    "Por favor, guarde los cargos adicionales",
  action_required_from_your_end: "Se requiere acción de su parte",
  action_required_from_user_end: "Se requiere acción del usuario",
  last_updated_by: "Última actualización por",
  contact_details: "Detalles de contacto",
  join_call: "Unirse a la llamada",
  authenticate_email:
    "No está autenticado. Para autenticar, vaya a la pestaña 'Correo electrónico'. Se abrirá una nueva ventana para autenticarte.",
  switch_patient: "Cambiar Paciente",
  method_enzymatic: "Método: ENZimático",
  borderline: "Límite",
  all_patient_switched: "Todos los pacientes cambiaron",
  patient_switch_info:
    "{{switchedPatients}} paciente cambiado, {{notSwitchedPatients}} paciente no pudo ser cambiado",
  requested_by: "Solicitado por",
  reject_request: "Rechazar solicitud",
  postpone_approval_request: "Aplazar solicitud de aprobación",
  postpone_reason: "Razón de aplazamiento",
  approve_task: "Aprobar tarea",
  please_open_related_form: "Por favor, abra el formulario relacionado",
  please_provide_rejection_reason:
    "Por favor, proporcione la razón del rechazo",
  task_rejected_successfully: "Tarea rechazada con éxito.",
  failed_to_reject_the_task_please_try_again:
    "Error al rechazar la tarea. Por favor, intente de nuevo.",
  please_provide_postpone_reason:
    "Por favor, proporcione la razón del aplazamiento",
  task_postponed: "Tarea pospuesta.",
  failed_to_postpone_the_task_please_try_again:
    "Error al aplazar la tarea. Por favor, intente de nuevo.",
  please_provide_approval_remark:
    "Por favor, proporcione un comentario de aprobación",
  task_approved: "Tarea aprobada.",
  failed_to_approve_the_task_please_try_again:
    "Error al aprobar la tarea. Por favor, intente de nuevo.",
  you_are_already_in_the_selected_organization:
    "¡Ya estás en la organización seleccionada!",
  online: "En línea",
  offline: "Desconectado",
  logout: "Cerrar sesión",
  create_account: "Crear cuenta",
  switch_to_org: "Cambiar a {{orgName}}",
  do_you_want_to_logout: "¿Quieres cerrar sesión?",
  support_message:
    "Hola, soy Zoey, tu asistente de soporte. Puedes chatear conmigo o crear un ticket",
  open_settings: "Abrir configuración",
  guest_nav: " (Invitado)",
  daily_consultation_trend: "Tendencia de consultas diarias",
  till_date_consultation: "Consultas hasta la fecha",
  consultation_sharing: "Compartir consultas",
  daily_average: "Promedio diario",
  top_3_diseases: "Las 3 principales enfermedades",
  center: "Centro",
  repeat: "Repetir",
  repeat_patient_consult_card: "Paciente recurrente",
  consultation: "Consulta",
  daily: "Diario",
  visit: "Visita",
  left: "Salió",
  clinic: "Clínica",
  disease: "Enfermedad",
  last_30_days_count: "Conteo de los últimos 30 días",
  last_occurrence: "Última ocurrencia",
  total_consultations: "Total de consultas",
  doctors_consulted: "Doctores consultados",
  doctor_statistics: "Estadísticas del doctor",
  consulted_doctors: "Doctores consultados",
  online_doctors: "Doctores en línea",
  doctors: "Doctores",
  feedback_bubble_chart: "Gráfico de burbujas de retroalimentación",
  nurse_patient: "Enfermera/Paciente",
  trends: "Tendencias",
  please_provide_reassign_reason:
    "Por favor, proporcione la razón de la reasignación",
  please_select_reassign_user_list:
    "Por favor, seleccione la lista de usuarios para reasignar",
  task_reassigned: "Tarea reasignada.",
  failed_to_reassign_the_task_please_try_again:
    "Error al reasignar la tarea. Por favor, intente de nuevo.",
  please_provide_your_concern_response:
    "Por favor, proporcione su respuesta a la preocupación",
  the_task_concern_has_been_successfully_addressed:
    "La preocupación de la tarea se ha resuelto con éxito.",
  failed_to_address_the_task_concern_please_try_again:
    "Error al resolver la preocupación de la tarea. Por favor, intente de nuevo.",
  forward_task: "Reenviar tarea",
  please_select_atleast_one_user: "Por favor, seleccione al menos un usuario",
  the_approved_task_has_been_successfully_forwarded:
    "La tarea aprobada se ha reenviado con éxito.",
  failed_to_forward_the_approved_task_please_try_again:
    "Error al reenviar la tarea aprobada. Por favor, intente de nuevo.",
  please_provide_disagreement_reason:
    "Por favor, proporcione la razón de su desacuerdo",
  please_select_work_progress: "Por favor, seleccione el progreso del trabajo",
  your_disagreement_has_been_successfully_submitted:
    "Su desacuerdo se ha enviado con éxito.",
  failed_to_submit_your_disagreement_please_try_again:
    "Error al enviar su desacuerdo. Por favor, intente de nuevo.",
  sign_off_confirmation: "Confirmación de cierre",
  create_next_task: "Crear siguiente tarea",
  there_is_no_next_task: "No hay una siguiente tarea",
  suggested_activity: "Actividad sugerida",
  please_provide_a_sign_off_comment:
    "Por favor, proporcione un comentario de cierre",
  please_select_a_activity: "Por favor, seleccione una actividad",
  please_select_a_completion_time:
    "Por favor, seleccione una hora de finalización",
  please_provide_a_task_name: "Por favor, proporcione un nombre para la tarea",
  please_provide_a_task_description:
    "Por favor, proporcione una descripción de la tarea",
  task_sign_off_completed_successfully:
    "Cierre de la tarea completado con éxito",
  task_sign_off_was_unsuccessful_please_check_and_try_again:
    "El cierre de la tarea no fue exitoso. Por favor, verifique y vuelva a intentarlo.",
  resumes_on_postpone: "Se reanuda el {{postponedTill}}",
  re_open: "Reabrir",
  failed_to_validate_otp: "Error al validar el OTP",
  otp_validated_successfully: "OTP validado con éxito",
  otp_value_or_request_id_is_missing:
    "Falta el valor de OTP o el ID de solicitud",
  an_error_occurred_while_validating_otp: "Ocurrió un error al validar el OTP",
  otp_resend_successfully: "OTP reenviado con éxito",
  failed_to_resend_otp: "Error al reenviar el OTP",
  please_enter_an_otp_that_has_been_sent_to_your_email:
    "Por favor, introduzca un OTP que ha sido enviado a su correo electrónico",
  enter_Otp: "Introducir OTP",
  timeLeft: "Tiempo restante: {{timeLeft}}s",
  designation_created_successfully: "Designación creada con éxito",
  failed_to_create_designation: "Error al crear la designación",
  new_uom_added_successfully: "Nueva UOM añadida con éxito.",
  manufacturer_added_successfully: "Fabricante añadido con éxito",
  task_has_been_canceled: "La tarea ha sido cancelada.",
  failed_to_cancel_the_task_please_try_again:
    "Error al cancelar la tarea. Por favor, intente de nuevo",
  completed: "Completado",
  attrition_rate: "Tasa de deserción",
  selected_files_upload: "Archivos seleccionados",
  default_positions_loaded_successfully:
    "Posiciones predeterminadas cargadas exitosamente",
  please_provide_minimum_salary: "Por favor proporcione salario mínimo",
  please_provide_maximum_salary: "Por favor proporcione salario máximo",
  no_unit_selected_please_select_a_unit:
    "Ninguna unidad seleccionada. Por favor seleccione una unidad",
  position_couldnt_be_saved: "No se pudo guardar la posición",
  position_saved: "Posición guardada con éxito",
  ctc_band_added: "Banda CTC agregada",
  dose_dependent_on_body_weight: "¿Dosis dependiente del peso corporal?",
  select_compound: "Seleccionar compuesto",
  dose: "Dosis",
  dose_unit: "Unidad de dosis",
  maximum_dose_limit: "Límite máximo de dosis",
  dose_limit: "Límite de dosis",
  add_compound: "Agregar compuesto",
  then: "Entonces",
  add_new_stp: "Agregar nuevo STP",
  disease_with_icd11: "Enfermedad con ICD11",
  common: "Común",
  new_regime: "Nuevo régimen",
  regime: "Régimen",
  age_group: "Grupo de edad",
  age_group_list: "Lista de grupos de edad",
  parameter_to_monitor: "Parámetro a monitorear",
  search_lifestyle: "Buscar estilo de vida",
  please_add_regime_name: "Por favor, agregue el nombre del régimen",
  please_provide_parameter_name:
    "Por favor, proporcione el nombre del parámetro",
  please_select_disease: "Por favor, seleccione la enfermedad",
  please_select_compounds: "Por favor, seleccione los compuestos",
  list_of_age_group: "Lista de grupos de edad",
  add_new_age_group: "Agregar nuevo grupo de edad",
  new_born: "Recién nacido",
  up_to_3_months_old: "Hasta 3 meses de edad",
  infant: "Infante",
  three_12_months_old: "3 - 12 meses de edad",
  edit_stp: "Editar STP",
  could_not_find_investgation_of_test_id:
    "No se pudo encontrar la estudio con el id de prueba",
  vitals_unit: "Unidad de signos vitales",
  standard_treatment_protocol: "Protocolo de tratamiento estándar",
  unit_doctor_mapping: "Mapeo de unidad, doctor",
  unit_investigation_mapping: "Mapeo de unidad de estudio",
  monitoring_parameters: "Parámetros de monitoreo",
  lifestyle: "Estilo de vida",
  regime_name: "Nombre del régimen",
  compound_items: "Elementos del compuesto",
  Stp_deleted: "STP eliminado con éxito",
  edit_item: "Editar elemento",
  contract_description: "Descripción del contrato",
  contract_brief: "Resumen del contrato",
  contract_number: "Número de contrato",
  contract_date: "Fecha del contrato",
  contract_value: "Valor del contrato",
  contact_person: "Persona de contacto",
  counter_party: "Contraparte",
  please_enter_contract_number: "Por favor, introduzca el número de contrato",
  please_enter_contract_date: "Por favor, introduzca la fecha del contrato",
  please_enter_contract_value: "Por favor, introduzca el valor del contrato",
  please_select_contract_person: "Por favor, seleccione la persona de contacto",
  please_select_responsible_person_name:
    "Por favor, seleccione el nombre de la persona responsable",
  contract_deleted: "Contrato eliminado con éxito",
  fail_to_delete_contract: "Error al eliminar el contrato",
  from_issued_Poforma: "De la Proforma emitida",
  pending_invoices: "Facturas pendientes",
  seller: "Vendedor",
  tax_gstin_id: "ID de impuestos/GSTIN",
  enter_seller_tax_gstin_id: "Introduzca el ID de impuestos/GSTIN del vendedor",
  please_type_email_id: "Por favor, escriba el correo electrónico",
  seller_email_id: "Correo electrónico del vendedor",
  enter_buyer_tax: "Introduzca el impuesto del comprador",
  enter_email: "Introducir correo electrónico",
  enter_place_of_supply: "Introduzca el lugar de suministro",
  challan_number: "Número de challan",
  enter_challan_number: "Introduzca el número de challan",
  items_details: "Detalles de los artículos",
  tax_percentage: "Impuesto (%)",
  round_off: "Redondear",
  notes_special_instruction: "Notas / Instrucción especial",
  please_select_item: "Por favor, seleccione el artículo",
  please_provide_quantity: "Por favor, proporcione la cantidad",
  please_provide_rate: "Por favor, proporcione la tarifa",
  some_item_do_not_match_selected_item_type:
    "Algunos artículos no coinciden con el tipo de artículo seleccionado",
  reference_no: "Número de referencia",
  enter_reference_number: "Ingrese el número de referencia",
  discount_type: "Tipo de descuento",
  discount_on_item_level: "Descuento a nivel de artículo",
  discount_on_total: "Descuento sobre el total",
  delivery_address: "Dirección de entrega",
  enter_address: "Introduzca la dirección",
  for_information_only: "Sólo para información",
  privacy_incaps: "PRIVACIDAD",
  policy_incaps: "POLÍTICA",
  privacy_policy:
    "Política de privacidad de la empresa y todas sus subsidiarias y activos, incluidos los de diferentes países. La empresa ofrece servicios de software basados en la nube a sus clientes en un modelo de servicio por suscripción. Necesita acceso a los datos para permitir la prestación de servicios y también para mejorar sus servicios. Cada transacción implicará la transferencia, en el caso de una persona que acceda a los servicios, de sus datos personales para permitir la entrega del servicio, encargándose de transferir los pagos o reembolsos y, en cumplimiento de la ley, sus credenciales y datos personales al destinatario del servicio para permitir la entrega de servicios y también para el procesamiento de pagos, mejor atención al cliente, etc. La empresa también utiliza algoritmos automatizados para mejorar la prestación de servicios a través de zoyel.one y sus otras subsidiarias. El software y los datos utilizados solo se utilizan para intentar una mejor prestación de servicios.",
  privacy_policy_intro:
    "Esta Política de Privacidad describe nuestras políticas y procedimientos sobre la recopilación, uso, almacenamiento, procesamiento, transferencia y divulgación de sus datos personales cuando utiliza el servicio y le informa sobre sus derechos de privacidad y cómo la ley lo protege.",
  privacy_policy_usage:
    "Usamos sus datos personales para proporcionar y mejorar el servicio. Al utilizar el servicio, usted acepta la recopilación y el uso de la información de acuerdo con esta Política de Privacidad.",
  interpretation_definitions: "INTERPRETACIÓN Y DEFINICIONES",
  interpretation: "INTERPRETACIÓN",
  definition_conditions:
    "Las palabras cuya primera letra está en mayúscula tienen los significados definidos bajo las siguientes condiciones. Las siguientes definiciones tendrán el mismo significado, sin importar si aparecen en singular o en plural.",
  definitions: "DEFINICIONES",
  privacy_policy_purpose: "Para los fines de esta Política de Privacidad:",
  account_definition:
    "<strong>Cuenta</strong> significa una cuenta única creada para usted para acceder a nuestro Servicio o partes de nuestro Servicio.",
  affiliate_definition:
    '<strong>Afiliado</strong> significa una entidad que controla, es controlada por o está bajo control común con una parte, donde "control" significa la propiedad del 50% o más de las acciones, intereses de capital u otros valores con derecho a voto para la elección de directores u otra autoridad gestora.',
  application_defination_part_one:
    "<strong>Aplicación</strong> significa el programa de software / aplicación / aplicación web proporcionado por la empresa que has descargado en cualquier dispositivo electrónico, denominado Zoyel.one y en la URL que comienza con",
  application_defination_part_two:
    'Compañía Compañía (denominada "la Compañía", "Nosotros", "Nos" o "Nuestro" en este Acuerdo) se refiere a ZoyelTechnologies LLC 112-028, Bena Complex C, Dubai UAE',
  cookies_definition:
    "<strong>Cookies</strong> son pequeños archivos que se colocan en su computadora, dispositivo móvil o cualquier otro dispositivo por un sitio web, que contienen los detalles de su historial de navegación en ese sitio web, entre otros usos.",
  device_definition:
    "<strong>Dispositivo</strong> significa cualquier dispositivo que pueda acceder al Servicio, como una computadora, un teléfono móvil o una tableta digital.",
  persona_data_definition:
    "<strong>Datos personales</strong> significa cualquier información que se relacione con una persona física que, ya sea directa o indirectamente, sea capaz de identificar a dicha persona y, para los fines de esta Política de Privacidad, se refiere a los Datos Personales identificados en este documento bajo el título - Tipo de Datos Recogidos.",
  service_definition:
    "<strong>Servicio</strong> se refiere a la Aplicación o al Sitio Web o a ambos.",
  service_provider_definition:
    "<strong>Proveedor de Servicios</strong> significa cualquier persona natural o jurídica que procesa los datos en nombre de la Compañía. Se refiere a empresas o individuos externos empleados por la Compañía para facilitar el Servicio, proporcionar el Servicio en nombre de la Compañía, realizar servicios relacionados con el Servicio o ayudar a la Compañía a analizar cómo se utiliza el Servicio.",
  third_party_social_media_service_definition:
    "<strong>Servicio de Redes Sociales de Terceros</strong> se refiere a cualquier sitio web o sitio web de red social a través del cual un Usuario puede iniciar sesión o crear una cuenta para usar el Servicio.",
  usage_data_definition:
    "<strong>Datos de Uso</strong> se refiere a los datos recopilados automáticamente, ya sea generados por el uso del Servicio o de la infraestructura del Servicio en sí (por ejemplo, la duración de una visita a la página).",
  website_definition:
    "<strong>Sitio web</strong> se refiere a zoyel.one, accesible desde ",
  you_definition:
    "<strong>Usted</strong> significa la persona que accede o utiliza el Servicio, o la empresa u otra entidad legal en nombre de la cual dicha persona está accediendo o utilizando el Servicio, según corresponda.",
  privacy_policy_record:
    "Esta Política de Privacidad es un registro electrónico bajo las leyes aplicables y las reglas establecidas en ellas. Esta Política de Privacidad no requiere ninguna firma física, electrónica o digital por parte de la Compañía.",
  collecting_personal_data: "RECOLECCIÓN Y USO DE SUS DATOS PERSONALES",
  type_of_data_collected: "TIPOS DE DATOS RECOPILADOS",
  personal_data: "DATOS PERSONALES",
  using_service_info:
    "Al usar nuestro servicio, podemos pedirle que nos proporcione cierta información personal identificable que se pueda usar para contactarlo o identificarlo, incluyendo, pero no limitado a:",
  first_name_and_last_name: "Nombre y apellido",
  device: "Dispositivo",
  address_details_state:
    "Dirección, Estado, Provincia, Código postal, Ciudad, País",
  bank_account_info:
    "Información de la cuenta bancaria para pagar productos y/o servicios dentro del Servicio",
  usage_data: "Datos de uso",
  details_for_services:
    "Cualquier detalle relacionado con lo anterior, tal como se proporciona a la Compañía para aprovechar los Servicios",
  public_info_note:
    'Por la presente se aclara que cualquier información que esté libremente disponible o sea accesible en el dominio público o proporcionada bajo las leyes aplicables en ese momento no se considerará como "información de identificación personal"',
  public_info_exclusion:
    'Por la presente se aclara que cualquier información que esté libremente disponible o sea accesible en el dominio público o proporcionada bajo las leyes aplicables en ese momento no se considerará como "información de identificación personal".',
  personal_info_request:
    "Mientras utiliza nuestro servicio, es posible que le pidamos que nos proporcione cierta información de identificación personal que pueda usarse para contactarlo o identificarlo, incluyendo pero no limitado a:",
  id_document:
    "Pasaporte o tarjeta de identificación nacional u otra forma de identificación",
  bank_card_statement: "extracto de tarjeta bancaria",
  other_information_linking_you_to_an_address:
    "Otra información que lo vincula a una dirección",
  data_provision_option:
    "Siempre tiene la opción de no proporcionar los Datos Personales o la información que buscamos recopilar de usted eligiendo no usar un servicio(s) o característica en particular que le proporcionamos, que requiere que proporcione dicha información. Sin embargo, cualquier negativa a proporcionar ciertos Datos Personales o información podría afectar la prestación de servicios y restringir su acceso a ellos.",
  usage_data_collected:
    "Los datos de uso se recopilan automáticamente al utilizar el servicio.",
  usage_data_details:
    "Los datos de uso pueden incluir información como la dirección del Protocolo de Internet de su dispositivo (por ejemplo, dirección IP), tipo de navegador, versión del navegador, las páginas de nuestro Servicio que visita, la hora y la fecha de su visita, el tiempo pasado en esas páginas, identificadores únicos de dispositivo y otros datos de diagnóstico.",
  mobile_access_info:
    "Cuando acceda al Servicio desde o a través de un dispositivo móvil, es posible que recopilemos cierta información automáticamente, incluido, entre otros, el tipo de dispositivo móvil que utiliza.",
  browser_access_info:
    "También podemos recopilar información que su navegador envía cada vez que visita nuestro Servicio o cuando accede al Servicio mediante o a través de un dispositivo móvil.",
  information_collected: "INFORMACIÓN RECOGIDA AL USAR LA APLICACIÓN",
  collect_data_permission:
    "Mientras usa nuestra aplicación, para proporcionar las funciones de nuestra aplicación, podemos recopilar, con su permiso previo:",
  information_regarding_your_location: "Información sobre su ubicación",
  camera_and_photos:
    "Imágenes, videos y otra información de la cámara y la biblioteca de fotos de su dispositivo o del dispositivo que accede",
  use_of_information:
    "Usamos esta información para proporcionar las características de nuestro servicio y para mejorar y personalizar nuestro servicio. La información puede ser cargada en los servidores de la empresa y/o en el servidor de un proveedor de servicios, o puede ser simplemente almacenada en su dispositivo.",
  access_to_information:
    "Puede habilitar o deshabilitar el acceso a esta información en cualquier momento, a través de la configuración de su dispositivo.",
  tracking_technologies_and_cookies: "TECNOLOGÍAS DE SEGUIMIENTO Y COOKIES",
  cookies_and_tracking_technologies:
    "Usamos Cookies y tecnologías de seguimiento similares para rastrear la actividad en Nuestro Servicio y almacenar cierta información. Las tecnologías de seguimiento utilizadas son balizas, etiquetas y scripts para recopilar y rastrear información y mejorar y analizar Nuestro Servicio. Las tecnologías que utilizamos pueden incluir:",
  cookies_or_browser_cookies:
    "<strong>Cookies o Cookies de Navegador:</strong> Una cookie es un archivo pequeño colocado en su dispositivo. Puede indicar a su navegador que rechace todas las cookies o que indique cuándo se envía una cookie. Sin embargo, si no acepta las cookies, es posible que no pueda utilizar algunas partes de nuestro Servicio. A menos que haya ajustado la configuración de su navegador para que rechace las cookies, nuestro Servicio puede usar cookies.",
  flash_cookies:
    '<strong>Cookies Flash:</strong> Ciertas características de nuestro Servicio pueden utilizar objetos almacenados localmente (o Cookies Flash) para recopilar y almacenar información sobre sus preferencias o su actividad en nuestro Servicio. Las Cookies Flash no se gestionan mediante la misma configuración del navegador que se utiliza para las Cookies del Navegador. Para obtener más información sobre cómo puede eliminar las Cookies Flash, lea "¿Dónde puedo cambiar la configuración para desactivar o eliminar los objetos compartidos locales?" disponible aquí.',
  web_beacons:
    "<strong>Balizas web:</strong> Ciertas secciones de nuestro Servicio y nuestros correos electrónicos pueden contener pequeños archivos electrónicos conocidos como balizas web (también llamados gifs claros, etiquetas de píxeles y gifs de un solo píxel) que permiten a la Compañía, por ejemplo, contar a los usuarios que han visitado esas páginas o abierto un correo electrónico y para otras estadísticas relacionadas con el sitio web (por ejemplo, grabar la popularidad de una sección determinada y verificar la integridad del sistema y del servidor).",
  cookies_explanation:
    '<strong>Las cookies</strong> pueden ser cookies "persistentes" o "de sesión". Las cookies persistentes permanecen en su computadora personal o dispositivo móvil cuando está fuera de línea, mientras que las cookies de sesión se eliminan tan pronto como cierre su navegador web. Obtenga más información sobre las cookies: ¿Qué son las cookies?.',
  cookies_usage:
    "Usamos tanto cookies de sesión como cookies persistentes para los fines establecidos a continuación:",
  necessary_essential_cookies: "Cookies necesarias/esenciales",
  type_session_cookies: "Tipo: Cookies de sesión",
  administered_by_us: "Administrado por: Nosotros",
  cookies_purpose:
    "Propósito: Estas cookies son esenciales para proporcionarte los servicios disponibles a través del sitio web y permitirte usar algunas de sus funciones. Ayudan a autenticar a los usuarios y prevenir el uso fraudulento de las cuentas de usuario. Sin estas cookies, los servicios que has solicitado no pueden ser proporcionados, y solo usamos estas cookies para brindarte esos servicios.",
  cookies_policy_notice_acceptance_cookies:
    "Política de Cookies / Aviso de Aceptación de Cookies",
  type_persistent_cookies: "Tipo: Cookies persistentes",
  cookies_acceptance_purpose:
    "Propósito: Estas cookies identifican si los usuarios han aceptado el uso de cookies en el sitio web.",
  functionality_cookies: "Cookies de funcionalidad",
  cookies_remember_choices_purpose:
    "Propósito: Estas cookies nos permiten recordar las elecciones que haces cuando usas el sitio web, como recordar tus detalles de inicio de sesión o la preferencia de idioma. El propósito de estas cookies es ofrecerte una experiencia más personalizada y evitar que tengas que volver a ingresar tus preferencias cada vez que uses el sitio web.",
  use_of_your_personal_data: "USO DE SUS DATOS PERSONALES",
  company_use_personal_data_purpose:
    "La Empresa puede usar los Datos Personales para los siguientes propósitos:",
  to_provide_maintain_service:
    "<strong>Para proporcionar y mantener nuestro Servicio:</strong> incluyendo el monitoreo del uso de nuestro Servicio.",
  to_manage_account:
    "<strong>Para gestionar su cuenta:</strong> para gestionar su registro como usuario del servicio. Los datos personales que proporcione pueden darle acceso a diferentes funcionalidades del servicio que están disponibles para usted como usuario registrado.",
  for_performance_contract:
    "<strong>Para el cumplimiento de un contrato:</strong> el desarrollo, cumplimiento y ejecución del contrato de compra de los productos, artículos o servicios que ha comprado o de cualquier otro contrato con Nosotros a través del Servicio.",
  to_contact_you:
    "<strong>Para contactarle:</strong> Para contactar con Usted por correo electrónico, llamadas telefónicas, SMS u otras formas equivalentes de comunicación electrónica, como las notificaciones push de una aplicación móvil relacionadas con actualizaciones o comunicaciones informativas sobre las funcionalidades, productos o servicios contratados, incluidas las actualizaciones de seguridad, cuando sea necesario o razonable para su implementación.",
  to_provide_you:
    "<strong>Para proporcionarle:</strong> noticias, ofertas especiales e información general sobre otros bienes, servicios y eventos que ofrecemos y que son similares a los que ya ha adquirido o preguntado, a menos que haya optado por no recibir dicha información.",
  to_manage_your_requests:
    "<strong>Para gestionar sus solicitudes:</strong> Para atender y gestionar sus solicitudes hacia Nosotros.",
  for_business_transfers:
    "<strong>Para transferencias comerciales:</strong> Podemos usar su información para evaluar o llevar a cabo una fusión, desinversión, reestructuración, reorganización, disolución u otra venta o transferencia de algunos o todos nuestros activos, ya sea como una entidad en funcionamiento o como parte de un procedimiento de quiebra, liquidación o procedimiento similar, en el que los Datos Personales que poseemos sobre nuestros usuarios del Servicio sean parte de los activos transferidos.",
  for_research_and_development:
    "<strong>Para estudio y Desarrollo:</strong> Podemos usar su información para el desarrollo de mejores servicios a través de estudios de cohorte, IA, aprendizaje automático, análisis para proporcionar una mejora continua de algoritmos, para garantizar una mejor entrega.",
  for_other_purposes:
    "<strong>Para otros fines:</strong> Podemos usar su información para otros fines, como análisis de datos, auditoría, identificación de tendencias de uso, determinación de la efectividad de nuestras campañas promocionales y para evaluar y mejorar nuestro Servicio, productos, servicios, marketing y su experiencia.",
  may_share_personal_data:
    "Podemos compartir Su Datos Personales en las siguientes situaciones:",
  with_service_providers:
    "<strong>Con Proveedores de Servicios / Receptores de Servicios:</strong> Podemos compartir Sus Datos Personales con Proveedores de Servicios / Receptores de Servicios para monitorear y analizar el uso de nuestro / Su Servicio, para el procesamiento de pagos, para contactarlo y para permitirle proporcionar / recibir servicios. Todas las agencias o personas involucradas en la coordinación del cuidado pueden tener acceso a los datos.",
  with_government_authorities:
    "<strong>Con Autoridades Gubernamentales:</strong> donde sea requerido por la ley para cumplir con el país de origen o jurisdicción para cumplir con",
  for_business_transfers_private:
    "<strong>Para transferencias comerciales:</strong> Podemos compartir o transferir Sus Datos Personales en relación con, o durante las negociaciones de, cualquier fusión, venta de activos de la Compañía, financiamiento o adquisición de toda o una parte de nuestro negocio a otra empresa.",
  with_affiliates:
    "<strong>Con Afiliados:</strong> Podemos compartir Su información con Nuestros Afiliados, en cuyo caso les pediremos a esos Afiliados que respeten esta Política de Privacidad. Los Afiliados pueden incluir nuestra empresa matriz y otras subsidiarias, socios de joint venture u otras compañías que controlamos o que están bajo control común con Nosotros.",
  with_business_partners:
    "<strong>Con socios comerciales:</strong> Podemos compartir Su información con Nuestros socios comerciales para ofrecerle ciertos productos, servicios o promociones.",
  with_other_users:
    "<strong>Con otros usuarios:</strong> cuando comparta Datos Personales o interactúe de alguna otra forma en las áreas públicas con otros usuarios, dicha información puede ser vista por todos los usuarios y puede ser distribuida públicamente fuera. Si interactúa con otros usuarios o se registra a través de un Servicio de Red Social de Terceros, sus contactos en el Servicio de Red Social de Terceros pueden ver su nombre, perfil, fotos y descripción de su actividad. De manera similar, otros usuarios podrán ver descripciones de su actividad, comunicarse con usted y ver su perfil.",
  with_your_consent:
    "<strong>Con su consentimiento:</strong> Podemos divulgar sus Datos Personales para cualquier otro propósito con su consentimiento.",
  retention_of_your_personal_data: "CONSERVACIÓN DE TUS DATOS PERSONALES",
  data_retention:
    "La Empresa retendrá sus Datos Personales solo por el tiempo que sea necesario para los fines establecidos en esta Política de Privacidad o para cumplir con los requisitos de la ley en la jurisdicción correspondiente. Retendremos y utilizaremos sus Datos Personales en la medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si se nos requiere retener sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestros acuerdos y políticas legales.",
  usage_data_retention:
    "La Empresa también retendrá los Datos de Uso para análisis internos con fines. Los Datos de Uso generalmente se retienen por un período de tiempo más corto, excepto cuando estos datos se utilizan para fortalecer la seguridad o mejorar la funcionalidad de Nuestro Servicio, o cuando estamos legalmente obligados a retener estos datos durante períodos de tiempo más largos.",
  transfer_of_your_personal_data: "TRANSFERENCIA DE SUS DATOS PERSONALES",
  data_transfer:
    "Su información, incluidos los Datos Personales, se procesa en las oficinas operativas de la Empresa y en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. Esto significa que esta información puede ser transferida a - y mantenida en - computadoras ubicadas fuera de su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos puedan diferir de las de su jurisdicción.",
  consent_transfer:
    "Su consentimiento a esta Política de Privacidad seguido de la presentación de dicha información representa su acuerdo con esa transferencia.",
  data_security:
    "La Compañía tomará todas las medidas razonablemente necesarias para garantizar que Sus datos sean tratados de manera segura y de acuerdo con esta Política de Privacidad, y no se realizará ninguna transferencia de Sus Datos Personales a una organización o país, a menos que existan controles adecuados, incluida la seguridad de Sus datos y otros Datos Personales.",
  disclosure_of_your_personal_data: "DIVULGACIÓN DE SUS DATOS PERSONALES",
  business_transactions: "Transacciones comerciales",
  merger_notice:
    "Si la Compañía está involucrada en una fusión, adquisición o venta de activos, Sus Datos Personales pueden ser transferidos. Le informaremos antes de que Sus Datos Personales sean transferidos y queden sujetos a una Política de Privacidad diferente.",
  law_enforcement: "Aplicación de la ley",
  data_disclosure:
    "Bajo ciertas circunstancias, la Compañía puede estar obligada a divulgar Sus Datos Personales si así lo exige la ley o en respuesta a solicitudes válidas de las autoridades públicas (por ejemplo, un tribunal o una agencia gubernamental).",
  other_legal_requirements: "Otros requisitos legales",
  data_disclosure_good_faith:
    "La Compañía puede divulgar Sus Datos Personales con la creencia de buena fe de que dicha acción es necesaria para:",
  comply_with_a_legal_obligation: "Cumplir con una obligación legal",
  protect_and_defend_the_rights_or_property_of_the_company:
    "Proteger y defender los derechos o bienes de la Empresa",
  prevent_investigate_wrongdoing:
    "Prevenir o investigar posibles irregularidades en relación con el Servicio",
  protect_safety:
    "Proteger la seguridad personal de los usuarios del Servicio o del público",
  protect_against_legal_liability: "Proteger contra responsabilidad legal",
  security_measures:
    "La Compañía tiene medidas de seguridad razonables y salvaguardas para proteger Su privacidad y Datos Personales contra pérdida, mal uso, acceso no autorizado, divulgación, destrucción y alteración, en cumplimiento con las leyes aplicables. La seguridad de Sus Datos Personales es importante para Nosotros, pero recuerde que ningún método de transmisión por Internet ni método de almacenamiento electrónico es 100% seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger Sus Datos Personales, no podemos garantizar su seguridad absoluta. Se aclara además que Usted tiene y, mientras acceda a los Servicios (directa o indirectamente), la obligación de asegurarse de que tomará en todo momento salvaguardas físicas, gerenciales y técnicas adecuadas, en Su extremo, para preservar la integridad y seguridad de Sus datos, lo que incluirá, pero no se limitará a, Sus Datos Personales.",
  detailed_information_on_the_processing_of_your_personal_data:
    "INFORMACIÓN DETALLADA SOBRE EL TRATAMIENTO DE SUS DATOS PERSONALES",
  service_providers_access:
    "Los Proveedores de Servicios tienen acceso a Sus Datos Personales solo para realizar sus tareas en Nuestro nombre y están obligados a no divulgar ni usarlo para ningún otro propósito.",
  payments: "PAGOS",
  payment_processing:
    "Podemos ofrecer productos y/o servicios de pago dentro del Servicio. En ese caso, podemos usar servicios de terceros para el procesamiento de pagos (por ejemplo, procesadores de pagos).",
  payment_card_details:
    "No almacenaremos ni recopilaremos los detalles de su tarjeta de pago. Esa información es proporcionada directamente por Usted a nuestros procesadores de pagos de terceros, cuyo uso de sus datos personales está regido por su Política de Privacidad. Estos procesadores de pagos cumplen con los estándares establecidos por PCI-DSS, gestionados por el Consejo de Normas de Seguridad PCI, que es un esfuerzo conjunto de marcas como Visa, Mastercard, American Express y Discover. Los requisitos de PCI-DSS ayudan a garantizar el manejo seguro de la información de pago.",
  stripe_details:
    "<strong> Stripe </strong> Su Política de Privacidad puede ser consultada",
  here: "aquí",
  payment_info_private_policy:
    "Cuando utilice nuestro servicio para pagar un producto y/o servicio mediante transferencia bancaria, podemos pedirle que proporcione información para facilitar esta transacción y verificar su identidad.",
  payment_security_private_policy:
    "Cuando se transmite información de pago para acceder a los servicios, estará protegida por tecnología de cifrado. Al realizar pagos por cualquier servicio en nuestra plataforma, usted da su consentimiento expreso para compartir su información con proveedores de servicios de terceros, incluidos los pasarelas de pago, para procesar pagos y gestionar su información relacionada con los pagos. Por lo tanto, la Compañía no puede garantizar que las transmisiones de su información de pago o datos personales siempre sean seguras o que terceros no autorizados nunca puedan derrotar las medidas de seguridad tomadas por la Compañía o sus proveedores de servicios de terceros. La Compañía no asume ninguna responsabilidad por la divulgación de su información debido a errores en la transmisión, acceso no autorizado de terceros u otras causas fuera de su control. Usted juega un papel importante en mantener sus datos personales seguros. No debe compartir sus datos personales ni otra información de seguridad para su cuenta con nadie. La Compañía ha tomado medidas razonables para proteger sus derechos de privacidad con respecto a su acceso a nuestros servicios. Sin embargo, no seremos responsables de divulgaciones no autorizadas o ilegales de sus datos personales realizadas por terceros que no están sujetos a nuestro control.",
  other_service_partners: "OTROS SOCIOS DE SERVICIO",
  link_to_other_websites: "ENLACES A OTROS SITIOS WEB",
  third_party_links:
    "Nuestro Servicio puede contener enlaces a otros sitios web que no son operados por Nosotros. Si hace clic en un enlace de un tercero, será dirigido al sitio de ese tercero. Le recomendamos encarecidamente que revise la Política de Privacidad de cada sitio que visite.",
  no_control_over_third_party:
    "No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de cualquier sitio o servicio de terceros.",
  your_consent_and_changes_to_this_privacy_policy:
    "SU CONSENTIMIENTO Y CAMBIOS A ESTA POLÍTICA DE PRIVACIDAD",
  your_acknowledgement:
    "<strong>Su reconocimiento:</strong> Toda la información divulgada por usted se considerará divulgada voluntariamente y sin coacción. Ninguna responsabilidad relacionada con la autenticidad/genuinidad/falsedad/fraude/negligencia de la información divulgada por usted recaerá en la Compañía, ni la Compañía será responsable de verificar la información obtenida de usted de ninguna manera.",
  changes_to_privacy_policy:
    "<strong>Cambios en nuestra Política de Privacidad:</strong> Podemos actualizar nuestra Política de Privacidad de vez en cuando. Le notificaremos de cualquier cambio publicando la nueva Política de Privacidad en esta página.",
  privacy_policy_update_notice:
    "Le informaremos por correo electrónico y/o mediante un aviso destacado en nuestro Servicio, antes de que el cambio entre en vigor y actualizaremos la fecha de 'Última actualización' en la parte superior de esta Política de Privacidad.",
  review_privacy_policy_advice:
    "Se le recomienda revisar esta Política de Privacidad periódicamente para detectar cualquier cambio. Los cambios en esta Política de Privacidad son efectivos cuando se publican en esta página. Su aceptación de la Política de Privacidad enmendada, incluso mediante su acceso continuo a los Servicios, significará su consentimiento a dichos cambios y su acuerdo de estar legalmente obligado por los mismos.",
  contact_us: "CONTACT US",
  data_protection_rights_info:
    "Nuestra Empresa desea asegurarse de que esté completamente al tanto de todos sus derechos de protección de datos. Cada usuario tiene derecho a lo siguiente:",
  right_to_access_info:
    "El derecho de acceso: tiene derecho a solicitar a Nuestra Empresa copias separadas de sus datos personales. Es posible que le cobremos una pequeña tarifa por este servicio.",
  right_to_rectification_info:
    "El derecho a la rectificación: tiene derecho a solicitar que Nuestra Empresa corrija cualquier información que crea que es inexacta. También tiene derecho a solicitar que Nuestra Empresa complete la información que crea que está incompleta.",
  right_to_erasure_info:
    "El derecho a la supresión: tiene derecho a solicitar que Nuestra Empresa borre sus datos personales, bajo ciertas condiciones.",
  right_to_restrict_processing:
    "El derecho a restringir el procesamiento: tiene derecho a solicitar que Nuestra Empresa restrinja el procesamiento de sus datos personales, bajo ciertas condiciones.",
  right_to_object_to_processing:
    "El derecho a oponerse al procesamiento: tiene derecho a oponerse al procesamiento de sus datos personales por parte de Nuestra Empresa, bajo ciertas condiciones.",
  right_to_data_portability:
    "El derecho a la portabilidad de los datos: tiene derecho a solicitar que Nuestra Empresa transfiera los datos que hemos recopilado a otra organización, o directamente a usted, bajo ciertas condiciones.",
  right_to_withdraw_consent:
    "El derecho a retirar el consentimiento: puede elegir retirar su consentimiento por escrito sujeto a esta Política de privacidad en cualquier momento. En caso de que no proporcione su consentimiento o lo retire más tarde, le solicitamos que no acceda ni utilice los Servicios y también nos reservamos el derecho de no proporcionarle ningún Servicio. En tal caso, la Empresa puede eliminar su información (personal o de otro tipo) o desidentificarla para que sea anónima y no atribuible a usted.",
  response_time_private_policy:
    "Si realiza una solicitud, tenemos un mes para responderle. Si desea ejercer alguno de estos derechos, por favor contáctenos a nuestro correo electrónico: noreply@zoyel.one",
  contact_grievance_private_policy:
    "Si tiene alguna (i) pregunta sobre esta Política de Privacidad y/o (ii) alguna Síntoma con respecto a los Servicios, puede contactarnos:",
  by_email: "Por correo electrónico",
  contact_by_mail_private_policy:
    "Por correo: A <strong>atención al cliente</strong>, Zoya Technologies LLC 112-028, Bena Complex C, Dubai UAE, Si no hay respuesta o resolución, puede buscar arbitraje bajo las leyes aplicables.",
  usage_data_caps: "DATOS DE USO",
  security_of_your_personal_data: "SEGURIDAD DE SUS DATOS PERSONALES",
  copy_right_in_caps: "DERECHOS DE AUTOR",
  content_protection:
    "El Contenido está protegido por la ley de derechos de autor y es propiedad del Propietario y sus licenciantes, o de la parte acreditada como proveedor del Contenido. Salvo lo concedido en la licencia limitada aquí, cualquier uso del Contenido, incluida la modificación, transmisión, presentación, distribución, re-publicación u otra explotación del sitio web o de su Contenido, ya sea en su totalidad o en parte, está prohibido sin el consentimiento previo por escrito del Propietario.",
  limited_license: "LICENCIA LIMITADA",
  limited_licence_term_condition:
    "Sujeto a los términos y condiciones de este Acuerdo, se le concede una licencia limitada, no transferible y no exclusiva para acceder, ver y utilizar el sitio web y el Contenido para su uso personal y no comercial. Se le concede el derecho de descargar, almacenar y/o imprimir copias individuales de elementos que componen el contenido para su uso personal y no comercial, siempre que mantenga todos los avisos de derechos de autor y otros avisos contenidos en dicho contenido. No puede copiar y/o volver a publicar elementos que componen el Contenido en línea. También debe cumplir con cualquier requisito adicional que regule el uso de cualquier Contenido específico que pueda establecerse en el sitio web. En caso de conflicto entre los términos de una licencia que regule un contenido específico y este Acuerdo, prevalecerán los términos de la licencia específica.",
  mise_en_relation: "MISE EN RELACIÓN",
  third_party_links_term_and_condition:
    "El sitio web puede contener enlaces a sitios web de terceros. Estos enlaces se proporcionan únicamente como una conveniencia para usted y no como una aprobación por parte del propietario de ningún sitio web de terceros o de su contenido. A menos que se indique expresamente, el Propietario no opera ningún sitio web de terceros vinculado al sitio web y no es responsable del contenido de ningún sitio web de terceros, ni hace ninguna representación, garantía o compromiso de ningún tipo con respecto a ningún sitio web de terceros, incluidos, entre otros.",
  third_party_content_disclaimer:
    "Cualquier representación, garantía o compromiso con respecto a la legalidad, exactitud, fiabilidad, integridad, puntualidad o idoneidad de cualquier contenido en dichos sitios web de terceros,",
  third_party_merchantability_disclaimer:
    "Cualquier representación, garantía o compromiso con respecto a la comerciabilidad y/o idoneidad para un propósito particular de cualquier sitio web de terceros o material, contenido, software, bienes o servicios ubicados en o disponibles a través de dichos sitios web de terceros, o",
  third_party_website_disclaimer:
    "Cualquier representación, garantía o compromiso de que el funcionamiento de dichos sitios web de terceros será ininterrumpido o sin errores, que los defectos o errores en dichos sitios web de terceros se corregirán, o que dichos sitios web de terceros estarán libres de virus u otros componentes dañinos. Aunque el Propietario fomenta los enlaces al sitio web, no desea estar vinculado desde o hacia ningún sitio web de terceros que contenga, publique o transmita cualquier información ilegal o indecente de cualquier tipo, incluyendo, sin limitación",
  criminal_offense_content:
    "Cualquier contenido que constituya o fomente una conducta que constituya un delito penal, dé lugar a responsabilidad civil o viole cualquier ley o regulación local, estatal, provincial, nacional o internacional que pueda ser dañina o perjudicial para las actividades, operaciones, credibilidad o integridad del Propietario, o",
  owner_right_to_modify:
    "El Propietario se reserva el derecho en cualquier momento, y de vez en cuando, de modificar o suspender, temporal o permanentemente, el Sitio web (o cualquier parte del mismo) con o sin previo aviso. El Propietario no tendrá ninguna responsabilidad ante usted o cualquier tercero por cualquier modificación, suspensión o interrupción del Sitio web o cualquier parte del mismo.",
  user_content: "CONTENIDO DEL USUARIO",
  your_content_definition:
    'En estos términos y condiciones de uso, "su contenido" se refiere a material (incluidos, entre otros, texto, imágenes, material de audio, material de video y material audiovisual) que usted envíe a nuestro sitio web, para cualquier propósito.',
  content_license_term_and_condition:
    "Usted nos otorga una licencia mundial, irrevocable, no exclusiva y libre de regalías para usar, reproducir, adaptar, publicar, traducir y distribuir su contenido en cualquier medio existente o futuro. También nos otorga el derecho a sublicenciar estos derechos y el derecho a entablar acciones legales por infracción de estos derechos. Usted garantiza y declara que su contenido cumplirá con estos términos y condiciones. Su contenido no debe ser ilegal ni ilícito, no debe infringir los derechos legales de terceros y no debe ser capaz de dar lugar a acciones legales, ya sea contra usted, nosotros o un tercero (en cada caso bajo cualquier ley aplicable).",
  content_publication_restrictions:
    "Su contenido (y su publicación en nuestro sitio web) no debe:",
  be_libellous_or_maliciously_false: "Ser difamatorio o maliciosamente falso.",
  be_obscene_or_indecent: "Ser obsceno o indecente.",
  infringe_intellectual_property:
    "Infringir cualquier derecho de autor, derecho moral, derecho de base de datos, derecho de marca registrada, derecho de diseño, derecho de usurpación o cualquier otro derecho de propiedad intelectual.",
  infringe_rights:
    "Infringir cualquier derecho de confidencialidad, derecho de privacidad o derecho bajo la legislación de protección de datos.",
  negligent_advice:
    "Constituir asesoramiento negligente o contener cualquier declaración negligente.",
  constitute_an_incitement_to_commit_a_crime:
    "Constituya una incitación a delinquir.",
  be_in_contempt_of_any_court_or_in_breach_of_any_court_order:
    "Estar en desacato a cualquier tribunal o en incumplimiento de cualquier orden judicial.",
  racial_or_religious_hatred:
    "Violar la legislación sobre odio racial o religioso o discriminación.",
  blasphemous: "Ser blasfemo.",
  breach_of_official_secrets: "Violar la legislación sobre secretos oficiales.",
  breach_of_contractual_obligation:
    "Violar cualquier obligación contractual con cualquier persona.",
  depict_violence:
    "Representar violencia de una manera explícita, gráfica o gratuita.",
  pornographic: "Ser pornográfico o explícitamente sexual.",
  false_misleading: "Ser falso, inexacto o engañoso.",
  cause_damage:
    "Consistir o contener cualquier instrucción, consejo u otra información que pueda ser seguida y que, si se actúa sobre ella, pueda causar enfermedad, lesión o muerte, o cualquier otra pérdida o daño.",
  spam: "Constituir spam",
  offensive:
    "Ser ofensivo, engañoso, amenazante, abusivo, acosador o intimidante, odioso, discriminatorio o inflamatorio.",
  cause_inconvenience:
    "Causar molestias, inconvenientes o ansiedad innecesaria a cualquier persona.",
  appropriate_terms_and_conditions:
    "Tu contenido debe ser apropiado, civilizado, de buen gusto y conforme con los estándares generalmente aceptados de etiqueta y comportamiento en internet. No debes utilizar nuestro sitio web para vincular a ningún sitio web o página web que consista en o contenga material que, si se publicara en nuestro sitio web, infringiría las disposiciones de estos términos y condiciones.",
  no_legal_issues:
    "No debes enviar ningún contenido de usuario al sitio web que esté o haya estado alguna vez sujeto a procedimientos legales amenazados o reales o cualquier Síntoma similar.",
  edit_or_remove_content:
    "Nos reservamos el derecho de editar o eliminar cualquier material enviado a nuestro sitio web, almacenado en nuestros servidores, o alojado o publicado en nuestro sitio web.",
  no_monitoring_undertaking:
    "No obstante nuestros derechos bajo estos términos y condiciones en relación con el contenido del usuario, no nos comprometemos a monitorear la presentación de dicho contenido en, o la publicación de dicho contenido en, nuestro sitio web.",
  notify_breaches:
    "Si se da cuenta de cualquier contenido en el sitio web que infrinja estos términos y condiciones, por favor notifíquenos de inmediato por correo electrónico.",
  security_in_caps: "SEGURIDAD",
  internet_not_secure:
    "Cualquier información enviada o recibida a través de Internet generalmente no es segura. El Propietario no puede garantizar la seguridad o confidencialidad de ninguna comunicación hacia o desde el sitio web.",
  modification_of_website_title: "MODIFICACIÓN DEL SITIO WEB",
  modification_of_website_content:
    "El Propietario se reserva el derecho en cualquier momento, y de vez en cuando, de modificar o discontinuar, temporal o permanentemente, el sitio web (o cualquier parte de él) con o sin previo aviso. El Propietario no tendrá ninguna responsabilidad ante usted ni ante ningún tercero por cualquier modificación, suspensión o discontinuación del sitio web o de cualquier parte del mismo.",
  useProhibited_where_contrary_to_law_title:
    "USO PROHIBIDO DONDE CONTRAVENGA LA LEY",
  useProhibited_where_contrary_to_law_content:
    "El uso de este sitio web no está autorizado en ninguna jurisdicción donde el sitio web o cualquier contenido pueda violar cualquier ley o regulación. Usted acepta no acceder ni usar el sitio web en tales jurisdicciones. Usted acepta que es responsable del cumplimiento de todas las leyes y regulaciones aplicables. Cualquier contravención de esta disposición (o de cualquier disposición de este acuerdo) es totalmente bajo su propio riesgo.",
  governing_law_and_jurisdiction_title: "LEY APLICABLE Y JURISDICCIÓN",
  governing_law_and_jurisdiction_content:
    "Usted acepta que todos los asuntos relacionados con su acceso o uso del sitio web y su contenido estarán regidos por las leyes aplicables sin tener en cuenta los principios de conflicto de leyes. Usted acepta y se somete por la presente a la jurisdicción exclusiva y preferencial de los tribunales de la provincia aplicable con respecto a todos los asuntos relacionados con su acceso y uso del sitio web y el contenido, así como cualquier disputa que pueda surgir de ello.",
  waiver_title: "RENUNCIA",
  waiver_content:
    "Cualquier consentimiento por parte del Propietario para, o renuncia a, una violación de este Acuerdo que usted haya cometido, ya sea expresado o implícito, no constituirá un consentimiento a, o renuncia de, cualquier otra violación diferente o posterior.",
  severability_title: "DIVISIBILIDAD",
  severability_content:
    "La invalidez o inaplicabilidad de cualquier disposición de este Acuerdo o de cualquier pacto contenido en el mismo no afectará la validez o aplicabilidad de ninguna otra disposición o pacto contenido en el mismo, y dicha disposición o pacto inválido se considerará separable del resto de este Acuerdo.",
  notice_title: "AVISO",
  notice_content:
    "Las preguntas o comentarios sobre el sitio web deben enviarse por correo electrónico a noreply@zoyel.one",
  termination_title: "TERMINACIÓN",
  termination_content:
    "El propietario puede, a su sola discreción, cancelar o terminar su derecho a usar el sitio web, o cualquier parte del sitio web, en cualquier momento y sin previo aviso. En caso de terminación, ya no está autorizado para acceder al sitio web, o a la parte del sitio web afectada por dicha cancelación o terminación. Las restricciones impuestas sobre usted con respecto al material descargado del sitio web y los descargos de responsabilidad y limitaciones de responsabilidad establecidas en este Acuerdo, sobrevivirán a la terminación de este Acuerdo. El propietario no será responsable ante ninguna parte por dicha terminación.",
  website_availability_title: "DISPONIBILIDAD DEL SITIO WEB",
  website_availability_content1:
    "De vez en cuando, el sitio web o las características del sitio web estarán no disponibles. Tal indisponibilidad puede ser el resultado de defectos en el software del sitio web, procedimientos de mantenimiento programados o de emergencia, o fallas de proveedores de servicios de terceros.",
  website_availability_content2:
    "No nos comprometemos a garantizar que el sitio web esté disponible en un momento determinado.",
  website_availability_content3:
    "Además, no nos comprometemos a garantizar que el sitio web continuará siendo publicado en el futuro.",
  entire_agreement_title: "ACUERDO COMPLETO",
  entire_agreement_content:
    "Este es el Acuerdo completo entre usted y el propietario relacionado con su acceso y uso del sitio web.",
  cc_email_id: "Correo electrónico CC",
  enter_cc_email_id: "Ingrese correo electrónico CC",
  calculate: "Calcular",
  please_provide_ctc_value: "Por favor proporcione el valor CTC",
  monthly_amount: "Monto Mensual",
  annual_amount: "Monto Anual",
  grn_details: "Detalles del GRN",
  search_po_id: "Buscar ID de Pedido",
  grn_date: "Fecha del GRN",
  enter_invoice_amount: "Ingrese el Monto de la Factura",
  dw_wb_number: "Número DW/WB",
  enter_dw_wb_number: "Ingrese el Número DW/WB",
  transport_company: "Empresa de Transporte",
  enter_transport_co_name: "Ingrese el Nombre de la Empresa de Transporte",
  enter_origin: "Ingrese el Origen",
  enter_driver: "Ingrese el Conductor",
  lr_no: "No. LR",
  enter_lr_no: "Ingrese el No. LR",
  vehicle_no: "No. de Vehículo",
  enter_vehicle_no: "Ingrese el No. de Vehículo",
  qty_po: "CANTIDAD (Pedido)",
  qty_dn_wb: "CANTIDAD (DN/WB)",
  qty_received: "CANTIDAD (RECIBIDA)",
  qty_excess_attachment_as_a_pic: "CANTIDAD (Exceso) (Adjunto como imagen)",
  qty_short_attachment_as_a_pic: "CANTIDAD (Faltante) (Adjunto como imagen)",
  rejected_damaged_attachment_as_a_pic:
    "RECHAZADO/DAÑADO (Adjunto como imagen)",
  returned: "Devuelto",
  advance_amount: "Monto del Anticipo",
  enter_due_amount: "Ingrese el Monto Adeudado",
  amount_due: "Monto Adeudado",
  enter_advance_amount: "Ingrese el Monto del Anticipo",
  select_or_type_employment_type: "Seleccione o escriba el tipo de empleo",
  please_enter_payee_name: "Por favor, ingrese el nombre del beneficiario",
  payee_name_already_added: "¡Nombre del beneficiario ya agregado!",
  unit_id_is_not_provided: "No se ha proporcionado el ID de la unidad.",
  cheque_details_added_successfully: "Detalles del cheque añadidos con éxito",
  please_enter_branch_name: "Por favor, ingrese el nombre de la sucursal",
  please_enter_account_holder_name:
    "Por favor, ingrese el nombre del titular de la cuenta",
  please_enter_swift_code: "Por favor, ingrese el código SWIFT",
  please_enter_sort_code: "Por favor, ingrese el código de clasificación",
  bank_account_already_added: "¡Cuenta bancaria ya agregada!",
  bank_details_added_successfully: "Detalles bancarios añadidos con éxito",
  please_enter_upi_id: "Por favor, ingrese el ID de UPI",
  upi_id_already_added: "¡ID de UPI ya agregado!",
  upi_id_added_successfully: "ID de UPI añadido con éxito",
  select_atleast_one_code: "Seleccione al menos un código",
  please_select_payment_method: "Por favor, seleccione el método de pago",
  please_select_seller: "Por favor, seleccione el vendedor",
  please_add_seller_tax_gst_id:
    "Por favor, agregue el ID de impuestos/GST del vendedor",
  invalid_seller_email_id: "Correo Electrónico del vendedor no válido",
  invalid_buyer_email_id: "Correo Electrónico del comprador no válido",
  please_select_buyer: "Por favor, seleccione el comprador",
  please_add_buyer_tax_gst_id:
    "Por favor, agregue el ID de impuestos/GST del comprador",
  please_add_items: "Por favor, agregue artículos",
  please_add_place_of_supply: "Por favor, agregue el lugar de suministro",
  please_select_banking_data: "Por favor, seleccione los datos bancarios",
  please_select_employee: "Por favor, seleccione al empleado",
  issue_release_letter_successfully: "Carta de liberación emitida con éxito",
  select_employee: "Seleccionar empleado",
  last_working_day: "Último día de trabajo",
  date_of_resignation: "Fecha de renuncia",
  document_preview: "Vista previa del documento",
  employ_id: "ID del Empleado",
  employment_confirmation:
    "Esta carta es para confirmar que su empleo con {{orgName}} concluirá el {{lastWorkingDate}}, según su renuncia presentada el {{resignationDate}}. Agradecemos sus contribuciones a la empresa durante su tiempo con nosotros.",
  best_wishes: "Le deseamos todo lo mejor en sus futuros proyectos.",
  sincerely: "Atentamente",
  issue_service_certificate_successfully:
    "Emitir certificado de servicio con éxito",
  positive_qualities: "Cualidades Positivas",
  enter_positive_qualities: "Ingrese cualidades positivas",
  legal_clause_text: "Cláusula Legal",
  enter_legal_clause: "Ingrese cláusula legal",
  designation_details: "Detalles de la Designación",
  responsibilities: "Responsabilidades",
  service_certificate_experience_letter:
    "Certificado de Servicio / Carta de Experiencia",
  he: "él",
  she: "ella",
  they: "ellos/ellas",
  contributions_and_qualities:
    "{{name}} demostró consistentemente {{qualities}} y realizó contribuciones significativas a {{orgName}}.",
  legal_clause: "{{clause}}",
  future_wishes:
    "Le deseamos a {{name}} todo lo mejor en {{genderPossessive}} futuros proyectos.",
  error_loading_image: "Error al cargar la imagen",
  employment_certification:
    "Esto es para certificar que {{name}} estuvo empleado en {{orgName}} desde {{startDate}} hasta {{endDate}}. Durante este período, {{genderPronoun}} desempeñó varias funciones, incluidas:",
  doctor_to_unit: "Doctor a Unidad",
  unit_to_doctor: "Unidad a Doctor",
  please_select_a_doctor: "Por favor, seleccione un doctor",
  doctor_units_submitted_successfully:
    "¡Unidades de doctor enviadas con éxito!",
  please_select_a_unit: "Por favor, seleccione una unidad",
  unit_doctors_submitted_successfully:
    "¡Doctores de unidad enviados con éxito!",
  test_to_unit: "Prueba a Unidad",
  select_test: "Seleccionar Prueba",
  unit_to_test: "Unidad a Prueba",
  please_select_a_test: "Por favor, seleccione una prueba",
  unit_tests_submitted_successfully: "¡Pruebas de unidad enviadas con éxito!",
  test_units_submitted_successfully: "¡Unidades de prueba enviadas con éxito!",
  vital_updated_successfully: "Vital actualizado con éxito",
  vital_unit: "Unidad Vital",
  vital_code: "Código Vital",
  organization_vital_name: "Nombre Vital de la Organización",
  vital_units: "Unidades Vitales",
  save_vital: "Guardar Vital",
  edit_vital: "Editar Vital",
  personal: "PERSONAL",
  free: "GRATIS",
  personal_version:
    "La versión personal de Zoyel te da acceso a la suite de correo, almacenamiento y más: todo lo que necesitas para gestionar tu negocio diario.",
  professional: "Profesional",
  per_user_month: "POR USUARIO/MES",
  upgrade: "ACTUALIZAR",
  more_space:
    "Más espacio, más usuarios, más características. ¡Actualiza a Profesional para aprovechar al máximo tu cuenta de Zoyel!",
  enterprise: "Empresa",
  coming_soon: "Próximamente",
  welcome_to_zoyel: "Bienvenido a Zoyel: ",
  where_ideas_thrive: "Donde las ideas prosperan",
  intro_text:
    "Estamos encantados de tenerte a bordo y emocionados de mejorar tu experiencia con Zoyel.",
  org_website:
    "A continuación, encontrarás los detalles del sitio web de tu organización:",
  website_link: "Enlace del sitio web:",
  login_id: "ID de inicio de sesión:",
  start_by_creating_team: "Comienza creando un equipo",
  collaborating_using_tasks:
    "Colaborando de manera más eficiente usando tareas",
  templates_for_uniformity: "Plantillas para uniformidad y facilidad",
  unique_website: "Un sitio web tan único como tú",
  or_in_caps: "O",
  dismiss: "Descartar",
  click_to_copy: "Haz clic para copiar",
  welcome_message_transcends_traditional_communication:
    "Bienvenido a nuestro Zoyel, donde la colaboración trasciende las barreras de la comunicación tradicional. ¡Di adiós a los buzones de entrada desordenados y hola a la eficiencia optimizada! Con las tareas como pieza central de nuestra plataforma, hemos redefinido la forma en que los equipos trabajan juntos, haciendo que la colaboración sea sin esfuerzo y la comunicación fluida. ¡Empecemos este viaje juntos!",
  supercharge_message:
    "¿Listo para potenciar la productividad de tu equipo? ¡Comencemos! Crea tu equipo añadiendo miembros a Zoyel sin problemas. La colaboración te espera mientras desbloqueas el poder del genio colectivo. Juntos, transformaremos ideas en acción y conquistaremos desafíos con una eficiencia inigualable. ¡Construyamos algo increíble, juntos!",
  goodbye_email_chains:
    "¡Di adiós a las interminables cadenas de correos electrónicos y hola a la eficiencia optimizada! Con nuestro sistema intuitivo basado en tareas, la comunicación se vuelve ultrarrápida. Asigna tareas, establece plazos y realiza un seguimiento del progreso, todo dentro de una plataforma. Experimenta el poder de la acción directa y observa cómo aumenta la productividad al reemplazar los correos electrónicos por una colaboración fluida.",
  elevate_workflow:
    "¡Eleva tu flujo de trabajo con nuestra amplia biblioteca de plantillas personalizables! Desde planes de proyecto hasta agendas de reuniones, sube y comparte plantillas con tu equipo sin esfuerzo. Optimiza procesos, mantiene la consistencia y acelera la productividad con formatos probados y adaptados a tus necesidades. Simplifica la colaboración y logra la grandeza juntos.",
  transform_ideas:
    "¡Transforma tus ideas en sitios web impresionantes con solo un clic! Nuestra innovadora función de creación de sitios web con un solo clic ofrece una selección curada de plantillas excepcionales. Elige entre una variedad de diseños elegantes adaptados a tu visión. Lanza tu presencia en línea sin esfuerzo y cautiva a tu audiencia en minutos. Eleva tu marca con facilidad y simplicidad.",
  concern_raised_by: "Preocupación planteada por",
  seen: "Visto",
  items_name: "Nombre de los artículos",
  requested: "Solicitado",
  changes: "Cambios",
  requested_time: "Hora solicitada",
  approved_time: "Hora aprobada",
  approval_remarks: "Comentarios de aprobación",
  add_reopen_remark_here: "Agregar comentario para reabrir aquí",
  provide_reopen_remark: "Proporcionar comentario para reabrir",
  please_provide_reopen_remark:
    "Por favor, proporcione un comentario para reabrir",
  the_postponed_task_reopened: "¡La tarea pospuesta ha sido reabierta!",
  resume_request: "Reanudar solicitud",
  provide_remark: "Proporcionar comentario",
  please_provide_resume_reason:
    "Por favor, proporcione el motivo de reanudación",
  request_successfully_resumed: "¡Solicitud reanudada con éxito!",
  software_high_priority: "Alta prioridad de software",
  software_medium_priority: "Prioridad media de software",
  software_low_priority: "Baja prioridad de software",
  hardware_high_priority: "Alta prioridad de hardware",
  hardware_medium_priority: "Prioridad media de hardware",
  hardware_low_priority: "Baja prioridad de hardware",
  ticket_resolution_time_distribution:
    "Distribución del tiempo de resolución de tickets",
  open: "Abierto",
  closed: "Cerrado",
  support_ticket: "Ticket de soporte",
  tickets: "Tickets",
  response_time: "Tiempo de Respuesta",
  Uptime_Downtime: "Tiempo Activo/Inactivo",
  Status_Page: "Página de Estado",
  Today: "Hoy",
  Yesterday: "Ayer",
  Last_7_Days: "Últimos 7 Días",
  This_Week: "Esta Semana",
  Last_Week: "La Semana Pasada",
  This_Month: "Este Mes",
  Last_Month: "El Mes Pasado",
  Last_30_Days: "Últimos 30 Días",
  All_Time: "Todo el Tiempo",
  Avg_Response_Time: "Tiempo Promedio de Respuesta",
  Seconds: "Segundos",
  Online: "En Línea",
  Offline: "Fuera de Línea",
  Response_Time: "Tiempo de Respuesta",
  Date_Time: "Fecha y Hora",
  Uptime: "Tiempo Activo",
  add_new_items: "Agregar nuevos elementos",
  following_vitals_are_missing_do_you_want_to_continue:
    "Faltan los siguientes signos vitales. ¿Quieres continuar?",
  please_select_a_future_time: "Por favor, selecciona una hora futura",
  convenient_time_selected: "Hora conveniente seleccionada",
  convenient_date_time: "Fecha y hora convenientes",
  you_have_selected_meeting_that_overlaps_with_other_meeting_do_you_want_to_proceed:
    "Has seleccionado una reunión que se superpone con otra. ¿Quieres proceder?",
  open_form: "Abrir formulario",
  close_form: "Cerrar formulario",
  Afrikaans: "Afrikáans",
  Arabic_Algeria: "Árabe (Argelia)",
  Arabic_Bahrain: "Árabe (Bahrein)",
  Arabic_Egypt: "Árabe (Egipto)",
  Arabic_Iraq: "Árabe (Irak)",
  Arabic_Israel: "Árabe (Israel)",
  Arabic_Jordan: "Árabe (Jordania)",
  Arabic_Kuwait: "Árabe (Kuwait)",
  Arabic_Lebanon: "Árabe (Líbano)",
  Arabic_Morocco: "Árabe (Marruecos)",
  Arabic_Oman: "Árabe (Omán)",
  Arabic_Palestinian_Territories: "Árabe (Territorios Palestinos)",
  Arabic_Qatar: "Árabe (Catar)",
  Arabic_Saudi_Arabia: "Árabe (Arabia Saudita)",
  Arabic_Tunisia: "Árabe (Túnez)",
  Arabic_United_Arab_Emirates: "Árabe (Emiratos Árabes Unidos)",
  Basque: "Vasco",
  Bulgarian: "Búlgaro",
  Catalan: "Catalán",
  Chinese_Simplified: "Chino (Simplificado)",
  Chinese_Traditional: "Chino (Tradicional)",
  Croatian: "Croata",
  Czech: "Checo",
  Danish: "Danés",
  Dutch: "Holandés",
  English_Australia: "Inglés (Australia)",
  English_Canada: "Inglés (Canadá)",
  English_India: "Inglés (India)",
  English_Ireland: "Inglés (Irlanda)",
  English_New_Zealand: "Inglés (Nueva Zelanda)",
  English_Philippines: "Inglés (Filipinas)",
  English_South_Africa: "Inglés (Sudáfrica)",
  English_United_Kingdom: "Inglés (Reino Unido)",
  English_United_States: "Inglés (Estados Unidos)",
  Filipino: "Filipino",
  Finnish: "Finés",
  French_Canada: "Francés (Canadá)",
  French_France: "Francés (Francia)",
  Galician: "Gallego",
  German: "Alemán",
  Greek: "Griego",
  Hebrew: "Hebreo",
  Hindi: "Hindi",
  Hungarian: "Húngaro",
  Icelandic: "Islandés",
  Indonesian: "Indonesio",
  Italian: "Italiano",
  Japanese: "Japonés",
  Korean: "Coreano",
  Lithuanian: "Lituano",
  Malay: "Malayo",
  Norwegian_Bokmal: "Noruego (Bokmål)",
  Persian: "Persa",
  Polish: "Polaco",
  Portuguese_Brazil: "Portugués (Brasil)",
  Portuguese_Portugal: "Portugués (Portugal)",
  Romanian: "Rumano",
  Russian: "Ruso",
  Serbian: "Serbio",
  Slovak: "Eslovaco",
  Slovenian: "Esloveno",
  Spanish_Argentina: "Español (Argentina)",
  Spanish_Bolivia: "Español (Bolivia)",
  Spanish_Chile: "Español (Chile)",
  Spanish_Colombia: "Español (Colombia)",
  Spanish_Costa_Rica: "Español (Costa Rica)",
  Spanish_Dominican_Republic: "Español (República Dominicana)",
  Spanish_Ecuador: "Español (Ecuador)",
  Spanish_El_Salvador: "Español (El Salvador)",
  Spanish_Guatemala: "Español (Guatemala)",
  Spanish_Honduras: "Español (Honduras)",
  Spanish_Mexico: "Español (México)",
  Spanish_Nicaragua: "Español (Nicaragua)",
  Spanish_Panama: "Español (Panamá)",
  Spanish_Paraguay: "Español (Paraguay)",
  Spanish_Peru: "Español (Perú)",
  Spanish_Puerto_Rico: "Español (Puerto Rico)",
  Spanish_Spain: "Español (España)",
  Spanish_United_States: "Español (Estados Unidos)",
  Spanish_Uruguay: "Español (Uruguay)",
  Spanish_Venezuela: "Español (Venezuela)",
  Swedish: "Sueco",
  Thai: "Tailandés",
  Turkish: "Turco",
  Ukrainian: "Ucraniano",
  Vietnamese: "Vietnamita",
  Zulu: "Zulú",
  change_request: "Solicitud de cambio",
  First_Response_Timeline: "Cronograma de primera respuesta",
  Resolution_Timeline: "Cronograma de resolución",
  additional_error_position_type:
    "Tipo de posición '{{positionType}} {{index}}' no pertenece a esta unidad. Por favor, elimínelo.",
  additional_error_type_position:
    "Tipo de posición '{{positionType}}' no pertenece a esta unidad. Por favor, elimínelo.",
  no_contact_available: "No hay contacto disponible",
  organization_business_type: "Tipo de negocio de la organización",
  please_type_responsible_person_name:
    "Por favor, escriba el nombre de la persona responsable",
  please_enter_date_of_birth: "Por favor, introduzca la fecha de nacimiento",
  do_you_want_to_delete_this_shift: "¿Desea eliminar este turno?",
  signature_upload_file_description:
    "Archivos permitidos en formato PNG, JPG o JPEG con un tamaño de 10 KB a 200 KB. Dimensiones de la imagen: altura de 50 px a 512 px, con fondo transparente o blanco.",
  payroll_ctc_does_not_match_message:
    "El salario total calculado no coincide con el CTC ingresado. Por favor, verifique sus ajustes.",
  select_salary_head: "seleccionar concepto salarial",
  reschedule_message_patient:
    "{{doctorName}} ha reprogramado la cita para {{rescheduleDate}} debido a {{rescheduleReqRmks}}.",
  method_label: "Método: {{method}}",
  remove_parameter: "Eliminar parámetro",
  test_submitted: "Prueba enviada con éxito",
  some_of_the_documents_could_not_be_uploaded:
    "Algunos de los documentos no se pudieron cargar.",
  appointment_message_Booking:
    "{{patientName}} ha programado una consulta con {{doctorName}} el {{date}} de {{fromTime}} a {{toTime}}. Por favor, asegúrese de llegar al centro al menos 15 minutos antes de la hora de su cita para evitar retrasos y permitir un proceso de consulta fluido.",
  please_wait_while_we_fetch_the_patient_data:
    "Por favor, espere mientras recuperamos los datos del paciente",
  please_enter_a_patient_id:
    "Por favor, introduzca una identificación del paciente",
  patient_details_fetched_successfully:
    "Detalles del paciente obtenidos con éxito",
  patient_details_not_found_with_the_provided_patient_id_please_check_again:
    "No se encontraron detalles del paciente con la identificación proporcionada, por favor, verifique nuevamente.",
  search: "Buscar",
  clear_patient_id: "Limpiar identificación del paciente",
  slots_available: "{{bookedCount}} espacios disponibles",
  slots_booked: "{{bookedCount}} reservados",
  expense_form_preview: "Vista previa del formulario de gastos",
  please_make_sure_you_change_budget_head:
    "Por favor, asegúrese de cambiar el responsable del presupuesto",
  remove_this_metrics: "Eliminar esta métrica",
  please_add_metrics: "Por favor, agregue métricas",
  please_select_employees_status:
    "Por favor, seleccione el estado de los empleados",
  please_select_at_list_one_user_for_pms:
    "Por favor, seleccione al menos un usuario para PMS",
  pms_approval: "Aprobación de PMS",
  applicable_for_employees_who_joined_before:
    "Aplicable para empleados que se unieron antes",
  self_evolution_last_date: "Última fecha de autoevaluación",
  manager_review_last_date: "Última fecha de revisión del gerente",
  numbers_of_effected_employees: "Número de empleados afectados",
  excluded_employees: "Empleados excluidos",
  percent: "Porcentaje",
  enter_percent: "Introduzca el porcentaje",
  current_total_ctc: "El CTC total actual {{totalCurrentCtc}} aumenta en",
  pms_metrics: "Métricas de PMS",
  new_ctc_message: "El nuevo CTC será {{newCtc}}",
  enter_metric_name: "Ingrese el nombre de la métrica",
  save_and_close: "Guardar y cerrar",
  cost_center_updated_successfully:
    "El centro de costos se actualizó correctamente",
  cost_center_could_not_be_updated: "El centro de costos no se pudo actualizar",
  grn: "GRN",
  srn: "SRN",
  select: "Seleccionar",
  small_items: "Artículos pequeños",
  emergency: "Emergencia",
  rate_contract: "Contrato de tarifa",
  other: "Otro",
  invoice: "Factura",
  please_provide_invoice_number: "Por favor, proporcione el número de factura",
  please_provide_tax_amount: "Por favor, proporcione el monto del impuesto",
  please_provide_total_amount: "Por favor, proporcione el monto total",
  no_data_found_in_selected_date_range:
    "No se encontraron datos en el rango de fechas seleccionado",
  support_tickets: "Tickets de soporte",
  search_by: "Buscar por",
  ticket_id: "ID del ticket",
  issue_category: "Categoría del problema",
  issue_description: "Descripción del problema",
  ticket_creation_time: "Hora de creación del ticket",
  pending_action: "Acción pendiente",
  ticket_status: "Estado del ticket",
  action_pending_from_support_end: "Acción pendiente por parte del soporte",
  action_pending_from_your_end: "El soporte ha respondido, por favor revisa",
  please_enter_some_comments: "Por favor, ingrese algunos comentarios",
  ticket_details: "Detalles del ticket",
  send_message_disabled_as_task_is_closed:
    "El envío de mensajes está deshabilitado porque la ticket está cerrada",
  created_date_and_time: "Fecha y hora de creación",
  uploaded_images: "Imágenes cargadas",
  messages: "Mensajes",
  by_ticket_id: "por ID de ticket",
  by_issue_category: "por categoría de problema",
  by_issue_name: "por nombre de problema",
  by_issue_description: "por descripción del problema",
  by_priority: "por prioridad",
  type_to_search: "Escriba para buscar",
  attach_screenshot: "Adjuntar captura de pantalla",

  Please_provide_reference_no: "Por favor, proporcione el número de referencia",
  please_select_reference_type: "Por favor, seleccione el tipo de referencia",
  please_provide_employee_resume:
    "Por favor, proporcione el currículum del empleado",
  please_select_interview_mode: "Por favor, seleccione el modo de entrevista",
  amount_cannot_be_zero: "El monto no puede ser cero",
  could_not_raise_concern: "No se pudo plantear preocupación por {{taskName}}",
  postpone_tasks: "Posponer {{count}} tareas",
  no_update_found: "No se encontraron actualizaciones",
  pospone_approval_request: "Posponer solicitud de aprobación",
  pospone_task: "Posponer tarea",
  forward_task_after_approval: "¿Enviar tarea después de la aprobación?",
  indent_by: "Sangría por",
  quotation_status: "Estado de la cotización",
  respond_by: "Responder antes de",
  pending: "Pendiente",
  rfp_submitted: "RFP enviada",
  items_dtl: "Detalles de los artículos",
  prescription_generated: "Receta generada",
  search_patient_by_name: "Buscar paciente por nombre",
  patient_id: "id del paciente",
  search_by_massalud_patient_id: "Búsqueda por ID de paciente de Massalud",
  please_enter_email_id_or_patient_id:
    "Por favor ingrese una correo electrónico o una id de paciente",
  biometric_associated_with_another_account_patient:
    "No se puede procesar porque los datos biométricos faciales están asociados con otra cuenta de paciente",
  please_give_patient_image: "Proporcione la imagen de la cara del paciente.",
  patient_data_not_found:
    "No se encontraron datos del paciente. Proporcione una identificación existente o una identificación de correo electrónico válida.",
  adverse_reactions: "Reacciones Adversas",
  drug_interactions: "Interacciones Medicamentosas",
  indications_and_usage: "Indicaciones y Uso",
  warnings_and_cautions: "Advertencias y Precauciones",
  overdosage: "Sobredosis",
  product_type: "Tipo de Producto",
  stop_use: "Suspender Uso",
  do_not_use: "No Usar",
  when_using: "Al Usar",
  warnings: "Advertencias",
  precautions: "Precauciones",
  information_for_patients: "Información para Pacientes",
  pregnancy: "Embarazo",
  pediatric_use: "Uso Pediátrico",
  geriatric_use: "Uso Geriátrico",
  contraindications: "contraindicaciones",
  success_please_continue_to_book_appointment:
    "Éxito, por favor continúa reservando cita.",
  joining_event_please_wait: "Uniéndose a {{eventTitle}}, por favor espera",
  opening_whiteboard_please_wait: "Abriendo la pizarra, por favor espera",
  disabled_from_organization: "Has sido deshabilitado de tu organización",
  otp_sent_successfully:
    "El OTP se ha enviado correctamente, por favor revisa tu carpeta de spam si no lo has recibido",
  no_accounts_found: "No se encontraron cuentas para este correo electrónico",
  the_meeting_link_is_invalid: "El enlace de la reunión no es válido.",
  sending_otp_please_wait: "Enviando OTP, espere....",
  ticket_closed_successfully: "Ticket cerrado con éxito",
  please_select_an_organization_to_login:
    "Por favor, selecciona una organización para iniciar sesión",
  login_successful_redirecting_to_organization_website:
    "Inicio de sesión exitoso, redirigiendo al sitio web de la organización",
  success_please_continue_to_book_investication:
    "Éxito, continúe reservando la estudio.",

  stat: "Estadística",
  before_breakfast: "Antes del desayuno",
  after_breakfast: "Después del desayuno",
  before_lunch: "Antes del almuerzo",
  after_lunch: "Después del almuerzo",
  before_evening: "Antes de la tarde",
  after_evening: "Después de la tarde",
  before_dinner: "Antes de la cena",
  after_dinner: "Después de la cena",
  after_food: "Después de comer",
  empty_stomach: "En ayunas",
  absent_count: "Cuenta de ausentes",
  present_count: "Cuenta de presentes",
  work_from_home: "Trabajo desde casa",
  work_at_office: "Trabajo en la oficina",
  no_data: "Sin datos",
  actual_amount: "Cantidad real",
  capexAmount: "Cantidad CAPEX",
  opexAmount: "Cantidad OPEX",
  contracts: "Contratos",
  count: "Cuenta",
  holiday_count: "Cuenta de vacaciones",
  value: "Valor",
  stock: "Stock",
  yrs: "Años",
  no_medicine: "Sin medicina",
  overmedication: "Sobre medicación",
  test: "Prueba",
  refer: "Referir",
  no_of_slots: "Número de espacios",
  booked_slots: "Espacios reservados",
  test_count: "Cuenta de pruebas",
  patient: "Paciente",
  are_not_provided: " no se proporcionan",
  refer_patient_to_doctor: "Recomienda al paciente al doctor",
  notes: "Notas:",
  join_message:
    "Por favor, únase como invitado o ingrese sus credenciales para unirse a la reunión con su cuenta de {{orgName}} en zoyel one.",
  join_meeting: "Unirse a la reunión",
  guest_user: "Usuario invitado",
  existing_user: "Usuario existente",
  join_as_guest: "Únete como invitado",
  search_allergic_compounds: "buscar compuestos alérgicos",
  scan_rdk_kit: "Kit de escaneo RDK",
  add_to_medicine_list: "Añadir a la lista de medicamentos",
  current_medicine: "Medicina actual",
  wait_for_test_result: "Espere el resultado de la prueba",
  update_timeline: "Actualizar cronograma",
  edit_timeline: "Editar línea de tiempo",
  selected_count_files: "Seleccionados {{count}} archivos",
  mobile_device_info:
    "La ID única de su dispositivo móvil, la dirección IP de su dispositivo móvil, el sistema operativo móvil, el tipo de navegador de Internet móvil que usa, identificadores únicos del dispositivo y otros datos de diagnóstico.",
  service_partners_access:
    "Otros socios de servicio que pueden tener acceso a parte o la totalidad de sus datos para habilitar servicios en ciertas circunstancias, lo que puede incluir lo siguiente. Por favor, revise sus políticas de privacidad.",
  please_select_item_or_add_new_item:
    "Por favor seleccione un artículo o agregue uno nuevo",
  Please_enter_description: "Por favor ingrese la descripción",
  please_select_budget_head:
    "Por favor seleccione el encabezado presupuestario",
  please_enter_indent_quantity: "Por favor ingrese la cantidad de sangría",
  estimated_rate_cannot_be_less_then_or_equal_0:
    "La tasa estimada no puede ser menor o igual a 0",
  estimated_value_cannot_be_less_then_or_equal_0:
    "El valor estimado no puede ser menor o igual a 0",
  please_select_purpose_on_row: "Por favor seleccione el propósito en la fila",
  please_select_the_ledger: "Por favor seleccione el libro mayor",
  first_surname: "Primer apellido",
  second_surname: "Segundo apellido",
  Sunday: "Domingo",
  Monday: "Lunes",
  Tuesday: "Martes",
  Wednesday: "Miércoles",
  Thursday: "Jueves",
  Friday: "Viernes",
  Saturday: "Sábado",
  January: "Enero",
  February: "Febrero",
  March: "Marzo",
  April: "Abril",
  May: "Mayo",
  June: "Junio",
  July: "Julio",
  August: "Agosto",
  September: "Septiembre",
  October: "Octubre",
  November: "Noviembre",
  December: "Diciembre",
  search_disease: "Buscar enfermedad",
  search_diet: "buscar dieta",
  first_week: "1ra semana",
  second_week: "2da semana",
  third_week: "3ra semana",
  fourth_week: "4ta Semana",
  fifth_Week: "5ta Semana",
  task_updated_successfully: "Tarea actualizada exitosamente",
  task_could_not_be_updated: "La tarea no se pudo actualizard",
  task_of: "tarea de",
  analytic_details: "Detalles analíticos",
  queue_history: "Historial de colas",
  doctor_wise_consultation: "Consulta con el médico",
  dr_name: "Nombre del Dr.",
  no_of_consultation: "Nº de consulta",
  no_of_center: "Nº de centro",
  avg_waiting_time: "Tiempo de espera promedio",
  avg_consultation_time: "Tiempo promedio de consulta",
  current_status: "Estado actual",
  nombre_unidad: "Nombre de la unidad",
  doctor_assigned_successfully: "Médico asignado exitosamente",
  are_you_sure: "¿Estás seguro?",
  no_person_selected: "Ninguna persona seleccionada",
  connect_again: "conectarse de nuevo",
  overall_rating: "Calificación general",
  successfully_accepted_policies: "Políticas aceptadas con éxito",
  please_view_all_policy_documents:
    "Por favor vea todos los documentos de la política",
  employee_tenure: "Tenencia de los empleados",
  disclaimer_heading: "Descargo de responsabilidad",
  application_disclaimer: "Descargo de responsabilidad",
  edit_priority: "Editar prioridad",
  edit_description: "Editar descripción",
  save_description: "Guardar descripción",
  edit_contract: "Editar contrato",
  save_contract: "Guardar contrato",
  contract_updated: "Contrato actualizado",
  parameter: "Parámetro",
  edit_prameter: "Editar parámetro",
  lower_limit: "Límite inferior",
  parameter_gender: "Género",
  choose_doctor: "Elegir doctor",
  see_more: "Ver más",
  date_updated_successfully: "Fecha actualizada exitosamente",
  search_parameter: "Parámetro de búsqueda",
  ticket: "Boleto",
  choose_file: "Elegir archivo",
  no_file_chosen: "Ningún archivo elegido",
  images_of_the_issue: "Imágenes del problema",
  Mon: "Lun",
  Tue: "Mar",
  Wed: "Mié",
  Thu: "Jue",
  Fri: "Vie",
  Sat: "Sáb",
  Sun: "Dom",
  Jan: "Ene",
  Feb: "Feb",
  Mar: "Mar",
  Apr: "Abr",
  May: "May",
  Jun: "Jun",
  Jul: "Jul",
  Aug: "Ago",
  Sep: "Sep",
  Oct: "Oct",
  Nov: "Nov",
  Dec: "Dic",
  next: "Siguiente",
  previous: "Anterior",
  page: "Página",
  rows: "Filas",
  of: "de",

  edit_test: "Editar Prueba",
  parameter_references: "Referencias de Parámetro",
  please_select_parameter: "Por favor seleccione un parámetro",
  please_add_parameter: "Por favor agregue un parámetro",
  test_parameter_saved: "Parámetro de Prueba Guardado",
  test_parameter_couldnt_be_saved: "El Parámetro de Prueba No Se Pudo Guardar",

  parameter_value_updated_successfully:
    "Valor del parámetro actualizado con éxito",
  parameter_could_not_be_updated: "No se pudo actualizar el parámetro",
  parameter_already_exists: "Parámetro ya existe",
  reference_ranges: "Rangos de referencia",
  add_parameter_reference: "Agregar referencia de parámetro",
  save_parameter_reference: "Guardar referencia de parámetro",
  parameter_type: "Tipo de parámetro",
  parameter_unit: "Unidad de parámetro",

  edit_test_parameters: "Editar parámetros de prueba",
  add_test_to_unit: "Agregar prueba a unidad",
  delete_parameter: "Eliminar parámetro",
  is_pregnant: "¿Está embarazada?",
  daily: "Diario",
  weekly: "Semanal",
  recurring_task_details: "Detalles de tarea recurrente",
  recurring_task_created: "Tarea recurrente creada",
  task_already_exists: "La tarea ya existe",
  cancel_task: "Cancelar tarea",
  approved: "Aprobado",
  add_new_department: "Agregar nuevo departamento",
  reassign_task: "Reasignar tarea",
  meeting_recordings: "Grabaciones de reuniones",
  person_name: "Nombre de la Persona",
  grant_rights_message: "¿Conceder derechos de moderador a {{name}}?",
  information: "Información",
  get_default_positions: "Obtener posiciones predeterminadas",
  privacy_incaps_zh: "PRIVACIDAD",
  policy_incaps_zh: "POLÍTICA",
  privacy_policy_header_zh:
    "<strong>ZOYA TECHNOLOGIES LLC</strong>. AVISO DE PRÁCTICAS DE PRIVACIDAD",
  privacy_policy_header_text_zh_p1:
    "ESTE AVISO DE PRÁCTICAS DE PRIVACIDAD DESCRIBE CÓMO SE PUEDE USAR Y DIVULGAR LA INFORMACIÓN MÉDICA SOBRE USTED Y CÓMO PUEDE ACCEDER A ESTA INFORMACIÓN.",
  privacy_policy_header_text_zh_p2: "Por favor, revíselo cuidadosamente.",
  privacy_policy_header_last_updated_zh:
    "Última actualización el 5 de diciembre de 2024",
  privacy_policy_header_overview_zh: "Resumen",
  privacy_policy_zh:
    "Su acceso y uso de los servicios de <strong>ZOYA TECHNOLOGIES LLC</strong>, que incluyen este sitio web <0>https://zoyel.health</0> y la información, comunidad, productos y servicios (colectivamente, los “Servicios de <strong>ZOYA TECHNOLOGIES LLC</strong>”) que le proporcionamos están sujetos a los Términos de Uso de los Servicios de <strong>ZOYA TECHNOLOGIES LLC</strong> y a esta Política de Privacidad.",
  privacy_policy_intro_zh:
    "<strong>ZOYA TECHNOLOGIES LLC</strong> ha creado esta Política de Privacidad para explicar qué información recopilamos de usted cuando visita un Servicio de <strong>ZOYA TECHNOLOGIES LLC</strong>, cómo podemos usar esta información, los enfoques de seguridad que utilizamos para proteger su información y cómo puede acceder y solicitar modificaciones de cierta información que podamos almacenar sobre usted.",
  privacy_policy_usage_zh:
    "Tenga en cuenta que esta Política de Privacidad se aplica únicamente a la información recopilada a través de los Servicios de <strong>ZOYA TECHNOLOGIES LLC</strong>. No se aplica a la información que divulgue a <strong>ZOYA TECHNOLOGIES LLC</strong> por otros medios. Esta Política de Privacidad se incorpora y forma parte de los Términos de Uso de los Servicios de <strong>ZOYA TECHNOLOGIES LLC</strong>.",
  pp_acceptance_header_zh:
    "Su aceptación de esta Política de Privacidad y los cambios en ella",
  pp_acceptance_header_zh_p1:
    "Al acceder, ver o usar de otra manera cualquier Servicio de <strong>ZOYA TECHNOLOGIES LLC</strong>, usted consiente en la recopilación y uso de su información por parte de <strong>ZOYA TECHNOLOGIES LLC</strong> de acuerdo con esta Política de Privacidad. Si no está de acuerdo con esta Política de Privacidad, no puede usar ningún Servicio de <strong>ZOYA TECHNOLOGIES LLC</strong>.",
  pp_acceptance_header_zh_p2:
    "<strong>ZOYA TECHNOLOGIES LLC</strong> se reserva el derecho de cambiar, modificar, agregar o eliminar partes de esta Política de Privacidad en cualquier momento, sin previo aviso. Los cambios entran en vigor en la fecha que aparece en la Política de Privacidad revisada.",
  pp_acceptance_header_zh_p3:
    "Si utiliza el Servicio de <strong>ZOYA TECHNOLOGIES LLC</strong> después de un cambio en esta Política de Privacidad, se entenderá que su uso indica que acepta y está de acuerdo en estar sujeto a los cambios. Por lo tanto, le instamos a que revise esta Política de Privacidad con frecuencia para conocer los cambios.",
  pp_information_collect_zh: "¿Qué información recopilamos?",
  pp_information_collect_zh_p1:
    "Recopilamos información de identificación personal (“Información Personal”) y otra información no identificable individualmente cuando se registra en los Servicios de <strong>ZOYA TECHNOLOGIES LLC</strong>, responde a comunicaciones como correos electrónicos o usa de cualquier manera los Servicios de <strong>ZOYA TECHNOLOGIES LLC</strong>.",
  pp_information_collect_zh_p2:
    "Para los fines de estos servicios, tenga en cuenta que la Información Personal incluye información que proporciona sobre usted mismo u otros miembros de su familia en cuyo nombre está utilizando los Servicios de <strong>ZOYA TECHNOLOGIES LLC</strong>. “Usted” y “su” incluyen a usted y a estos miembros de la familia.",
  pp_information_collect_zh_p3:
    "Para participar en ciertas funciones de los Servicios de <strong>ZOYA TECHNOLOGIES LLC</strong>, será necesario que proporcione dicha Información Personal. Por ejemplo, al registrarse como usuario en cualquier Servicio de <strong>ZOYA TECHNOLOGIES LLC</strong>, podemos pedirle su nombre, dirección de correo electrónico, dirección postal, número de teléfono, información de tarjeta de crédito (si se agregan servicios adicionales que requieran tarifas) <strong>u otra información, como fecha de nacimiento, sexo al nacer, etnia, código postal, historial médico personal relevante e historial familiar, que nos permita proporcionar los Servicios.</strong> Sin embargo, puede visitar partes de los Servicios de <strong>ZOYA TECHNOLOGIES LLC</strong> sin proporcionar ninguna Información Personal.",
  pp_information_collect_zh_p4:
    "Podemos recopilar y usar identificadores de dispositivos móviles, direcciones IP e identificadores de sesión para analizar tendencias, administrar los Servicios de <strong>ZOYA TECHNOLOGIES LLC</strong>, rastrear actividades de los usuarios, inferir intereses de los usuarios y, de otro modo, inducir, deducir y recopilar información sobre usuarios individuales y segmentos de mercado.",
  pp_information_collect_zh_p5:
    "También podemos recopilar y almacenar cierta otra información no identificable personalmente. Esta información se recopila de forma pasiva utilizando diversas tecnologías y, en la actualidad, no se puede utilizar para identificarlo específicamente.",
  pp_use_information_zh: "¿Cómo utilizamos su información?",
  pp_use_information_zh_p1:
    "Podemos usar la Información Personal y otros datos que recopilamos de usted cuando se registra, responde a una encuesta o comunicación de marketing, accede o ve los Servicios de <strong>ZOYA TECHNOLOGIES LLC</strong>, o utiliza ciertas otras funciones de los Servicios de <strong>ZOYA TECHNOLOGIES LLC</strong> de las siguientes maneras:",
  pp_use_information_zh_p2_1:
    "Para proporcionar cualquier servicio o producto comercial legítimo.",
  pp_use_information_zh_p2_2:
    "Para personalizar su Servicio de <strong>ZOYA TECHNOLOGIES LLC</strong> y permitirnos ofrecer el tipo de contenido y productos que sean de su mayor interés.",
  pp_use_information_zh_p2_3:
    "Para permitirnos atenderlo mejor al responder a sus solicitudes de servicio al cliente.",
  pp_use_information_zh_p2_4: "Para validar su identidad.",
  pp_use_information_zh_p2_5:
    "Para administrar una promoción u otra función del Servicio de <strong>ZOYA TECHNOLOGIES LLC</strong>.",
  pp_use_information_zh_p2_6:
    "Para resolver problemas con los Servicios de <strong>ZOYA TECHNOLOGIES LLC</strong>, o cualquier servicio, según lo solicitado.",
  pp_use_information_zh_p2_7:
    "Para hacer cumplir los <strong>Términos de Uso de ZOYA TECHNOLOGIES LLC</strong> y para detectar y proteger contra errores, fraudes y otras actividades no autorizadas o ilegales.",
  pp_use_information_zh_p2_8:
    "Para intentar contactarlo con respecto a problemas de seguridad de productos o retiro del mercado.",
  pp_use_information_zh_p3:
    "También podemos desidentificar y agregar su Información Personal para fines como estudios.",
  pp_use_information_zh_p4:
    "“Datos agregados” es información que se ha combinado con la de otros usuarios y se ha analizado o evaluado como un todo, de modo que no se puede identificar razonablemente a ningún individuo específico.",
  pp_use_information_zh_p5:
    "“Información desidentificada” es información a la que se le han eliminado sus identificadores, como su nombre, información de contacto y otros datos identificativos, de modo que no se le pueda identificar razonablemente como un individuo.",
  pp_use_information_zh_p6:
    "Usted entiende y acepta que si utiliza cualquier Servicio de <strong>ZOYA TECHNOLOGIES LLC</strong> desde un país fuera de los Estados Unidos y proporciona Información Personal al Servicio de <strong>ZOYA TECHNOLOGIES LLC</strong>, estará autorizando y consintiendo la transferencia de Información Personal sobre usted a los Estados Unidos.",
  pp_use_information_zh_p7:
    "Usted entiende que las leyes de privacidad de los Estados Unidos pueden ser diferentes y no tan completas ni protectoras como las de su país, y acepta que la transferencia de su Información Personal a los Estados Unidos se realiza con su consentimiento. La Información Personal recopilada en el Servicio de <strong>ZOYA TECHNOLOGIES LLC</strong> puede almacenarse y procesarse en los Estados Unidos o en el extranjero.",
  pp_communicate_with_you_zh: "¿Cómo nos comunicamos con usted?",
  pp_communicate_with_you_zh_p1:
    "Cuando envía un correo electrónico u otras comunicaciones a <strong>ZOYA TECHNOLOGIES LLC</strong>, podemos conservar esas comunicaciones para procesar sus consultas, responder a sus solicitudes y mejorar nuestros Servicios y productos relacionados. Cuando envía y recibe mensajes, podemos recopilar y mantener información asociada con esos mensajes.",
  pp_communicate_with_you_zh_p2:
    "También le enviaremos anuncios estrictamente relacionados con el servicio en algunas ocasiones cuando sea necesario hacerlo. Por ejemplo, si nuestros Servicios están temporalmente suspendidos por mantenimiento, podríamos enviarle un correo electrónico. Generalmente, no puede optar por no recibir estas comunicaciones, que no son de carácter promocional. Si no desea recibirlas, tiene la opción de desactivar su cuenta.",
  pp_communicate_with_you_zh_p3:
    "Basándonos en la Información Personal que nos proporcione, podemos enviarle un correo electrónico de bienvenida para verificar su nombre de usuario y contraseña. Nos comunicaremos con usted en respuesta a sus consultas, para proporcionar los servicios que solicite y para gestionar su cuenta. Nos comunicaremos con usted por correo electrónico o por teléfono, de acuerdo con su preferencia. Puede contactarnos en <strong>info@zoyel.one</strong>",
  pp_communicate_with_you_zh_p4:
    "Reconoce que al proporcionarnos su número de teléfono, número de celular y correos electrónicos, podemos usar esa información para enviarle información que usted o su proveedor soliciten sobre productos y servicios que ofrecemos nosotros o uno de nuestros socios. Si lo hacemos, por respeto a su privacidad, proporcionaremos una opción para no recibir este tipo de comunicaciones. Por favor, revise los <strong>Términos de Uso</strong> para obtener más información sobre las formas en que podemos comunicarnos con usted.",
  pp_information_we_disclose: "Información que divulgamos a terceros",
  pp_information_we_disclose_p1:
    "Podemos divulgar su Información Personal y otros datos a terceros, de las siguientes maneras:",
  pp_information_we_disclose_p2_1:
    "A nuestras subsidiarias o afiliados, socios estratégicos y a terceros que contratamos para proporcionar servicios en nuestro nombre, como alojamiento web, procesamiento de pedidos, entrega, etc. <strong>Nuestros acuerdos con estos terceros requieren que protejan estos datos y prohíben que utilicen su Información Personal para cualquier otro propósito.</strong>",
  pp_information_we_disclose_p2_2:
    "En caso de venta o transferencia de <strong>ZOYA TECHNOLOGIES LLC</strong>, de una o más de nuestras unidades de negocio o de algunos o todos nuestros activos, o en el contexto de alguna otra transacción de adquisición de negocios.",
  pp_information_we_disclose_p2_3:
    "En respuesta a una citación o orden judicial, o una solicitud de una agencia gubernamental o de aplicación de la ley; para establecer o ejercer nuestros derechos legales, o para defendernos de reclamos; o para proteger la seguridad o protección del público o de los usuarios de los Servicios de <strong>ZOYA TECHNOLOGIES LLC</strong>.",
  pp_information_we_disclose_p3:
    "Proporcionamos un aviso adicional y solicitamos su consentimiento si deseamos compartir su información con nuestras entidades de propiedad común de una manera materialmente diferente a la discutida en esta Política de Privacidad.",
  pp_information_we_disclose_p4_1:
    "<strong>ZOYA TECHNOLOGIES LLC</strong> no almacenará datos de pacientes en nombre de sus clientes. Los pacientes deben solicitar sus registros directamente al proveedor de atención médica o Entidad Cubierta (el cliente). Los clientes, como Entidades Cubiertas bajo HIPAA, son responsables de incorporar estas prácticas en sus propias políticas de privacidad e informar a los pacientes sobre cómo se manejan sus datos, incluyendo su uso de los servicios de <strong>ZOYA TECHNOLOGIES LLC</strong>.",
  pp_information_we_disclose_p4_2:
    "<strong>ZOYA TECHNOLOGIES LLC</strong> almacenará información específica del cliente en sus servidores, pero no manejará directamente datos de pacientes a menos que se especifique en un Acuerdo de Asociación Comercial (BAA) separado con el cliente.",
  pp_information_we_disclose_p4_3:
    "<strong>ZOYA TECHNOLOGIES LLC</strong> almacenará únicamente información específica del cliente en sus servidores, y todos los servicios relacionados con pacientes serán proporcionados por los clientes. Las políticas de privacidad de los clientes deben adaptarse para reflejar el contexto en el que <strong>ZOYA TECHNOLOGIES LLC</strong> almacena información del cliente, pero no datos de pacientes.",
  pp_information_we_disclose_p4_4:
    "Aunque <strong>ZOYA TECHNOLOGIES LLC</strong> no utiliza cookies para rastrear usuarios, pero para proteger los datos de los clientes y la información relacionada con pacientes, <strong>ZOYA TECHNOLOGIES LLC</strong> implementará controles de seguridad apropiados y mecanismos de protección. Sin embargo, <strong>ZOYA TECHNOLOGIES LLC</strong> no tendrá acceso directo a la información de los pacientes a menos que sea especificado por el cliente a través de un acuerdo formal (Acuerdo de Asociación Comercial). <strong>ZOYA TECHNOLOGIES LLC</strong> no se comunicará directamente con los pacientes de los clientes, y cualquier comunicación entre los clientes y <strong>ZOYA TECHNOLOGIES LLC</strong> se facilitará a través de puntos de contacto designados especificados por el cliente.",
  pp_information_we_disclose_p4_5:
    "Este servicio es una oferta basada en SaaS, donde los clientes brindan servicios a sus clientes (pacientes) y <strong>ZOYA TECHNOLOGIES LLC</strong> solo almacena los datos del cliente que son permitidos por los clientes. Cualquier dato de pacientes será responsabilidad de los clientes (Entidades Cubiertas), quienes deben garantizar el cumplimiento de HIPAA en su manejo de esos datos.",
  pp_information_we_disclose_p4_6:
    "Se aclara que <strong>ZOYA TECHNOLOGIES LLC</strong> no consume ni accede a ningún dato personal identificable clasificado como PHI de los pacientes o clientes de los clientes.",
  pp_information_we_disclose_p4_7:
    "Solo se recopilan datos anonimizados con fines de mejora del servicio y números de transacción para fines contables y de facturación de los clientes de nuestros clientes.",
  pp_information_we_disclose_p4_8: "Solicitar comunicación confidencial",
  pp_information_we_disclose_p4_9:
    "Puede pedirnos que lo contactemos de una manera específica (por ejemplo, teléfono de casa o de oficina) o que enviemos correos a una dirección diferente.",
  pp_information_we_disclose_p4_10:
    "Diremos 'sí' a todas las solicitudes razonables.",
  pp_information_we_disclose_p4_11:
    "Obtenga una copia de este aviso de privacidad",
  pp_information_we_disclose_p4_12:
    "Puede solicitar una copia en papel de este aviso en cualquier momento, incluso si ha aceptado recibir el aviso electrónicamente. Le proporcionaremos una copia en papel de forma rápida, lo que requerirá que pague un cargo por servicio.",
  pp_information_we_disclose_p4_13:
    "Presentar una Síntoma si cree que se han violado sus derechos de privacidad",
  pp_information_we_disclose_p4_14:
    "Puede presentar una Síntoma si siente que hemos violado sus derechos contactándonos usando la información en <strong>la página 1.</strong>",
  pp_information_we_disclose_p4_15:
    "Puede presentar una Síntoma ante la Oficina de Derechos Civiles del Departamento de Salud y Servicios Humanos de los Estados Unidos enviando una carta a 200 Independence Avenue, S.W., Washington, D.C. 20201, llamando al 1-877-696-6775 o visitando <strong>www.hhs.gov/ocr/privacy/hipaa/complaints/.</strong>",
  pp_information_we_disclose_p4_16:
    "No tomaremos represalias contra usted por presentar una Síntoma.",
  pp_exercise_your_rights:
    "Puede ejercer sus derechos enviándonos un correo electrónico a <strong>info@zoyel.one</strong>",
  pp_opt_out_choices: "Opciones para darse de baja",
  pp_opt_out_choices_p1:
    "Para 'darse de baja' de cualquiera de los siguientes, envíe un correo electrónico a <strong>info@zoyel.one</strong>",
  pp_opt_out_choices_p2_1:
    "cualquier consentimiento previamente otorgado a nosotros,",
  pp_opt_out_choices_p2_2: "recibir comunicaciones de nosotros, o",
  pp_opt_out_choices_p2_3:
    "que la Información Personal sea divulgada a terceros,",
  pp_opt_out_choices_p3:
    "No obstante este derecho a solicitar dejar de recibir comunicaciones de nosotros y a que no compartamos Información Personal con terceros, conservamos el derecho de recopilar, mantener y procesar información proporcionada por usted y recopilada sobre usted en y a través de los Servicios, y divulgar dicha información para cumplir con nuestras obligaciones comerciales y legales razonables.",
  pp_safeguarding_personal_info_zh: "Protección de su Información Personal",
  pp_safeguarding_personal_info_zh_p1:
    "<strong>ZOYA TECHNOLOGIES LLC</strong> sigue estándares de seguridad aceptados en la industria para salvaguardar y ayudar a prevenir el acceso no autorizado y mantener la seguridad de los datos de la Información Personal. Sin embargo, ningún método comercial de transferencia de información a través de Internet o almacenamiento de datos electrónicos es conocido como 100% seguro. Como resultado, no podemos garantizar la seguridad absoluta de ninguna Información Personal enviada o recopilada durante su uso de cualquier servicio de <strong>ZOYA TECHNOLOGIES LLC</strong>. En consecuencia, usted entiende y acepta que transmite todos los datos, incluida la Información Personal, a nosotros bajo su propio riesgo.",
  pp_privacy_safeguarding_links_zh: "Privacidad y Enlaces de Terceros",
  pp_privacy_safeguarding_links_zh_p1:
    "Esta Política de Privacidad se aplica únicamente a la información recopilada por el Servicio de <strong>ZOYA TECHNOLOGIES LLC</strong> a través de nuestro sitio web ubicado en <0>https://zoyel.health</0> y las aplicaciones móviles que ponemos a disposición. En un intento de proporcionar un mayor valor, podemos incluir enlaces de terceros en los Servicios de <strong>ZOYA TECHNOLOGIES LLC</strong>. Esta Política de Privacidad no se aplica a los sitios y servicios de terceros que son accesibles a través de estos enlaces, y sugerimos que contacte al operador del servicio de terceros para obtener detalles sobre sus políticas de privacidad. No tenemos responsabilidad ni obligación por el contenido y las actividades de estos sitios o servicios vinculados. No obstante, buscamos proteger la integridad de los Servicios de <strong>ZOYA TECHNOLOGIES LLC</strong> y agradecemos cualquier comentario sobre estos sitios vinculados (incluido si un enlace específico no funciona).",
  pp_exclusions_zh: "Exclusiones",
  pp_exclusions_zh_p1:
    "Esta Política de Privacidad no se aplicará a ninguna información no solicitada que nos proporcione a través de los Servicios de <strong>ZOYA TECHNOLOGIES LLC</strong> o por cualquier otro medio. Esto incluye, pero no se limita a, ideas para nuevos productos o modificaciones a productos existentes, y otras presentaciones no solicitadas (colectivamente, 'Información No Solicitada'). Toda la Información No Solicitada se considerará no confidencial y seremos libres de reproducir, usar, divulgar y distribuir dicha Información No Solicitada a otros sin limitación ni atribución.",
  pp_children_privacy_zh: "Privacidad de los Niños",
  pp_children_privacy_zh_p1:
    "El Servicio de <strong>ZOYA TECHNOLOGIES LLC</strong> está destinado solo para uso por adultos, ya sea para ellos mismos o en nombre de sus hijos menores. No recopilamos información directamente de niños menores de 13 años a sabiendas. Si tiene motivos para creer que un niño menor de 13 años nos ha proporcionado Información Personal a través del Servicio de <strong>ZOYA TECHNOLOGIES LLC</strong>, contáctenos, y nos esforzaremos por eliminar esa información de nuestras bases de datos.",
  pp_retention_personal_info: "Retención de su Información Personal",
  pp_retention_personal_info_p1:
    "Almacenaremos la Información Personal que usted proporcione mientras sea necesario de acuerdo con nuestra Política de Retención:",
  pp_retention_personal_info_p2_1:
    "para llevar a cabo el(los) propósito(s) para los que la recopilamos, o",
  pp_retention_personal_info_p2_2:
    "para cumplir con las leyes, contratos u otras normas o regulaciones aplicables.",
  pp_questions_feedback_zh: "Preguntas y Comentarios",
  pp_questions_feedback_zh_p1:
    "Agradecemos sus preguntas, comentarios y preocupaciones sobre los Servicios de <strong>ZOYA TECHNOLOGIES LLC</strong>. Envíenos cualquier comentario relacionado con los Servicios de <strong>ZOYA TECHNOLOGIES LLC</strong> a <strong>info@zoyel.one</strong>",
  pp_questions_feedback_zh_p2:
    "La información contenida en esta Política de Privacidad está sujeta a cambios sin previo aviso.",
  underweight: "Bajo peso",
  normal: "Normal",
  pre_obesity: "Sobrepeso",
  obese_i: "Obeso I",
  obese_ii: "Obeso II",
  obese_iii: "Obeso III",
  signup_disclaimer_1: `Este acuerdo rige el uso de Zoyel One Suite, un software de colaboración y productividad empresarial en línea proporcionado por Zoyel One. Establece los términos y condiciones entre usted (denominado "Usted" o "Su") y Zoyel One (denominado "Zoyel One") o cualquier aplicación bajo el nombre de Zoyel One o propiedad de Zoya Technologies LLC.`,
  signup_disclaimer_2: `Para aceptar el Acuerdo, debe ser mayor de edad para celebrar un acuerdo vinculante. Si no está de acuerdo con los Términos Generales, absténgase de utilizar nuestros Servicios. La aceptación del Acuerdo se puede demostrar marcando una casilla de verificación y haciendo clic en un botón que indica aceptación.`,
  signup_disclaimer_3: `Nuestra empresa ofrece software y aplicaciones en la nube, equivalentes móviles, denominados colectivamente "Servicios". Estos Servicios se pueden utilizar tanto para fines personales como comerciales, ya sea individualmente o dentro de la organización que representa. El acceso a los Servicios es posible a través de cualquier navegador de Internet soportado por la plataforma. Es su responsabilidad asegurar el acceso a Internet y adquirir el equipo necesario para utilizar los Servicios. Con su cuenta de usuario, tiene la capacidad de crear, modificar, publicar y compartir contenido según sus preferencias.`,
  signup_disclaimer_4: `Si se registra para una prueba gratuita de nuestros Servicios, tendrá acceso a un conjunto de Servicios aplicables sin cargo durante el período de prueba, a menos que usted o ZOYEL ONE lo cancelen antes. El período de prueba finaliza cuando (i) concluye el período de prueba gratuito, (ii) comienza el período de suscripción paga para los Servicios o (iii) ZOYEL ONE finaliza la prueba a su discreción. Es importante tener en cuenta que todos los datos ingresados ​​en los Servicios y cualquier personalización realizada durante la prueba se perderán permanentemente a menos que usted (i) compre el plan de suscripción paga correspondiente, (ii) adquiera las actualizaciones de Servicio correspondientes o (iii) exporte los datos. antes de que finalice el período de prueba. Tenga en cuenta que durante la prueba gratuita, los Servicios se proporcionan "tal cual" sin ninguna garantía, convenio, soporte o responsabilidad, en la medida permitida por la ley.`,
  signup_disclaimer_5: `Para utilizar los Servicios, es necesario registrar una cuenta de usuario y proporcionar toda la información necesaria. Si una organización utiliza los Servicios para fines corporativos internos, es recomendable que todos los usuarios creen cuentas de usuario utilizando los datos de contacto corporativos, preferiblemente la dirección de correo electrónico corporativa. Al registrarse, acepta proporcionar información verdadera, precisa, actual y completa sobre usted y su organización durante el proceso de registro y garantizar que siga siendo veraz, precisa, actual y completa actualizándola de inmediato. Si proporciona información que se considera falsa, inexacta, desactualizada o incompleta, o si ZOYEL ONE tiene motivos razonables para sospechar inexactitudes, ZOYEL ONE se reserva el derecho de cancelar su cuenta de usuario y denegar el acceso presente o futuro a sus Servicios.
Además de todos los demás términos y condiciones de este Acuerdo, usted no transferirá los Servicios ni los pondrá a disposición de ningún tercero, ni proporcionará ningún servicio basado en los Servicios sin permiso previo por escrito.`,
  signup_disclaimer_6: `Al registrarse para obtener una cuenta para su organización, se creará una cuenta de administrador que tendrá derecho a configurar los Servicios de acuerdo con sus requisitos.`,
  signup_disclaimer_7: `Su información personal proporcionada a ZOYEL ONE a través del Servicio se rige por los términos del acuerdo. Al elegir utilizar el Servicio, usted indica su aceptación de estos términos. Es su responsabilidad mantener la confidencialidad de su nombre de usuario, contraseña y otra información confidencial. Usted es responsable de todas las actividades que ocurren en su cuenta de usuario y acepta notificarnos de inmediato sobre cualquier uso no autorizado por correo electrónico o por teléfono. No somos responsables de ninguna pérdida o daño a usted o a un tercero que resulte del acceso o uso no autorizado de su cuenta de usuario, o cualquier otra circunstancia.`,
  signup_disclaimer_8: `Reconocemos y respetamos sus derechos de propiedad sobre el contenido creado o almacenado por usted. Usted conserva la propiedad de dicho contenido. A menos que usted lo autorice específicamente, su uso de los Servicios no otorga a ZOYEL ONE una licencia para utilizar, reproducir, adaptar, modificar, publicar o distribuir el contenido que crea o almacena en su cuenta de usuario para fines comerciales, de marketing o similares. . Sin embargo, usted otorga permiso a ZOYEL ONE para acceder, copiar, distribuir, almacenar, transmitir, reformatear, mostrar públicamente y ejecutar públicamente el contenido de su cuenta de usuario, únicamente con el fin de brindarle los Servicios.`,
  signup_disclaimer_9: `El uso de los servicios es enteramente bajo su propio riesgo, ya que se proporcionan "tal cual" y "según disponibilidad". Zoyel One renuncia explícitamente a todas las garantías de cualquier tipo, ya sean expresas o implícitas, incluidas, entre otras, las garantías implícitas de comerciabilidad e idoneidad para un propósito particular. Zoyel One no garantiza que los servicios sean ininterrumpidos, oportunos, seguros o libres de errores. Usted es el único responsable de cualquier daño que pueda ocurrir a su sistema informático, teléfono móvil, dispositivo inalámbrico o datos como resultado del uso de los servicios o la descarga de cualquier material obtenido a través de ellos. Cualquier información o consejo, ya sea escrito u oral, obtenido de Zoyel One, sus empleados o representantes no crea ninguna garantía que no esté expresamente establecida en el acuerdo. Zoyel One no será responsable de ninguna pérdida o daño consecuente, incidental, indirecto, especial, punitivo o de otro tipo, incluida la pérdida de ganancias comerciales, la interrupción del negocio, fallas informáticas, pérdida de información comercial u otras pérdidas, resultantes o causadas por su uso o imposibilidad de utilizar el servicio, incluso si Zoyel One ha sido advertido de la posibilidad de dicho daño.`,
  signup_disclaimer_10: `Al aceptar estos términos, usted reconoce y acepta indemnizar y proteger completamente a ZOYEL ONE, incluidos sus funcionarios, directores, empleados, proveedores y afiliados, de todas y cada una de las pérdidas, daños, multas, gastos (incluidos honorarios y costos de abogados) y pasivo. Esta indemnización se aplica a reclamos que surjan de o estén relacionados con su uso de los Servicios, ya sea en violación de los derechos de otra parte, cualquier ley, cualquier disposición del Acuerdo o cualquier otro reclamo, a menos que dicho uso esté expresamente autorizado por ZOYEL ONE.`,
  signup_disclaimer_11: `Al aceptar estos términos, usted reconoce y acepta indemnizar y proteger completamente a ZOYEL ONE, incluidos sus funcionarios, directores, empleados, proveedores y afiliados, de todas y cada una de las pérdidas, daños, multas, gastos (incluidos honorarios y costos de abogados) y pasivo. Esta indemnización se aplica a reclamos que surjan de o estén relacionados con su uso de los Servicios, ya sea en violación de los derechos de otra parte, cualquier ley, cualquier disposición del Acuerdo o cualquier otro reclamo, a menos que dicho uso esté expresamente autorizado por ZOYEL ONE.`,
  signup_disclaimer_12: `Nos reservamos el derecho de modificar este Acuerdo cuando sea necesario. Es su responsabilidad mantenerse actualizado sobre los cambios en el acuerdo.`,
  signup_disclaimer_13: `Si tiene alguna pregunta o inquietud sobre los términos y condiciones descritos en este Acuerdo, no dude en comunicarse con nosotros. Puede contactarnos en info@zoyel.one. Estamos disponibles para atender cualquier consulta o aclaración que necesites.`,
  signup_disclaimer_14: `Al reconocer y aceptar todos los términos y condiciones descrito en las políticas anteriores, entiendo que cualquier La violación puede someterme a sanciones según lo determine el las políticas de la organización y las leyes aplicables.`,
  submit_and_continue: `Enviar y continuar`,
  suggested_tasks: "Tareas Sugeridas",
  task_progress_updated: "Progreso de la tarea actualizado",
  doctor_has_switched_your_consultation: "El doctor ha cambiado su consulta.",

  minutes_of_the_meeting: "Minutas de la reunión",
  // generate_mom: "Generar MOM",
  minutes_of_the_meeting_is_not_available:
    "Las minutas de la reunión no están disponibles",
  view_mom: "Ver MOM",
  write_your_meeting_mom_here: "Escribe tus minutas de la reunión aquí",
  // update_mom: "Actualizar MOM",
  please_type_user_name: "Por favor, escribe el nombre de usuario",
  users_with_mom_access: "Usuarios con acceso a MOM",
  finalize_mom: "Finalizar MOM",
  users_access_updated_successfully: "Acceso de usuarios actualizado con éxito",
  update_user_access: "Actualizar acceso de usuario",
  // could_not_generate_mom: "No se pudo generar el MOM",
  mom_submitted_successfully: "MOM enviado con éxito",
  task_name: "Nombre de la tarea",
  task_description: "Descripción de la tarea",
  recipients: "Destinatarios",
  did_not_join_the_call: "No se unió a la llamada",
  parameter_value_type: "Tipo de valor del parámetro",
  minimum_age: "Edad mínima",
  maximum_age: "Edad máxima",
  pregnant: "Embarazada",
  toggle_to_switch_between_dark_and_light_mode:
    "Alternar para cambiar entre el modo oscuro y claro",
  medicine_with_same_dosage_and_frequency_already_added:
    "Medicamento con el mismo dosificación y frecuencia ya agregado",

  description_updated: "Descripción actualizada",
  description_could_not_be_updated: "No se pudo actualizar la descripción",
  task_name_updated: "Nombre de la tarea actualizado",
  task_name_could_not_be_updated: "No se pudo actualizar el nombre de la tarea",
  contract_could_not_be_updated: "No se pudo actualizar el contrato",
  software_ticket: "Ticket de software",
  event_reminder: "Recordatorio de evento",
  is_inviting_you_to_an_event: "te está invitando a un evento",
  priority_wise: "Por prioridad",
  contract_wise: "Por contrato",
  user_wise: "Por usuario",
  total_task: "Total de tareas",
  completed: "Completado",
  no_of_task: "Número de tareas",
  overdue: "Atrasado",
  ontime: "A tiempo",
  avg_completion_time: "Tiempo promedio de finalización",
  open: "Abierto",
  delay: "Retraso",
  avg_progress: "Progreso promedio",
  no_routine_work_define_yet:
    "¡No se ha definido ningún trabajo rutinario aún!",
  click_add_routine_work_button_to_create_new_routine_work:
    "Haga clic en el botón Agregar Trabajo Rutinario para crear un nuevo Trabajo Rutinario",

  no_routine_work_define_yet:
    "¡No se ha definido ningún trabajo rutinario aún!",
  click_add_routine_work_button_to_create_new_routine_work:
    "Haga clic en el botón Agregar Trabajo Rutinario para crear un nuevo Trabajo Rutinario",

  test_updated: "Prueba actualizada",
  summarize: "Resumir",
  unable_to_process: "No se puede procesar",

  //////////////////////////// react joyride

  select_a_tour: "Selecciona un tour",
  select_tour: "Selecciona tour",
  appointment_book: "Reservar cita",
  doctor_consulation: "Consulta médica",
  how_to_start_consultation: "Cómo comenzar una consulta",
  management_consultation: "Gestión de consultas",
  management_feedBack: "Gestión de retroalimentación",
  management_trend: "Tendencia de gestión",
  management_doctor: "Gestión de doctores",
  management_disease: "Gestión de enfermedades",
  management_unit: "Gestión de unidad",
  management_support_ticket: "Gestión de tickets de soporte",
  response_time_massalud_zoyel_health:
    "Tiempo de respuesta massalud.zoyel.health",
  uptime_downtime_massalud_zoyel_health:
    "Tiempo de actividad/inactividad massalud.zoyel.health",
  book_appointment: "Reservar cita",
  register_patient: "Registrar paciente",
  book_consultation: "Reservar consulta",
  patient_document: "Documento del paciente",
  review_and_refer_patient: "Revisar y referir paciente",
  investigation_unit: "Unidad de investigación",
  admin: "Administrador",
  task: "Tarea",
  profile: "Perfil",
  chat: "Chat",
  create_support_ticket: "Crear ticket de soporte",
  card_employee: "Tarjeta de empleado",
  card_task: "Tarjeta de tarea",
  card_leave_rule: "Tarjeta de regla de licencia",
  card_budget: "Tarjeta de presupuesto",
  card_items: "Tarjeta de ítems",
  card_holiday: "Tarjeta de vacaciones",
  card_external_user: "Tarjeta de usuario externo",
  card_contract: "Tarjeta de contrato",
  card_routine_work: "Tarjeta de trabajo rutinario",
  support_dashboard: "Tablero de soporte",
  meeting_room: "Sala de reuniones",
  back: "Atrás",
  finish: "Terminar",
  next: "Siguiente",
  skip: "Saltar",

  ////////

  this_is_where_you_can_view_all_the_details_of_the_patient:
    "Aquí puedes ver todos los detalles del paciente",
  this_is_where_you_can_view_doctor_info:
    "Aquí puedes ver la información del médico",
  this_is_where_you_can_view_patient_info:
    "Aquí puedes ver la información del paciente",
  this_is_where_if_a_patient_is_new_then_enter_the_patient_name:
    "Si un paciente es nuevo, ingresa el nombre del paciente aquí",
  this_is_where_if_a_patient_is_new_then_enter_the_patient_gender_and_dob:
    "Si un paciente es nuevo, ingresa el género y la fecha de nacimiento del paciente aquí",
  this_is_where_if_a_patient_is_new_then_enter_the_patient_civil_status_and_contact_number:
    "Si un paciente es nuevo, ingresa el estado civil y el número de contacto del paciente aquí",
  this_is_where_you_can_choose_add_to_queue_reschedule_cancel_appointment:
    "Aquí puedes elegir 'Agregar a la cola', 'Reprogramar' o 'Cancelar cita' según sea necesario",
  this_is_where_you_can_add_and_edit_data_for_add_to_queue_field:
    "Aquí puedes agregar y editar todos los datos para el campo 'Agregar a la cola'",
  this_is_where_you_can_add_and_edit_data_for_reschedule_field:
    "Aquí puedes agregar y editar todos los datos para el campo 'Reprogramar'",
  this_is_where_you_can_add_and_edit_data_for_cancel_appointment_field:
    "Aquí puedes agregar y editar todos los datos para el campo 'Cancelar cita'",
  this_is_where_you_can_do_submit_or_cancel_operation:
    "Aquí puedes realizar la operación de enviar o cancelar",
  this_is_where_you_can_do_doctor_set_doctor_roster:
    "Aquí puedes configurar el horario del médico.",
  choose_the_time_zone_for_your_appointments:
    "Elige la zona horaria para tus citas.",
  set_the_booking_window_in_days_for_your_appointments:
    "Establece la ventana de reserva en días para tus citas.",
  select_the_days_of_the_week_when_appointments_can_be_booked:
    "Selecciona los días de la semana en que se pueden reservar citas.",
  set_the_start_and_end_times_for_each_day_and_define_the_slot_duration:
    "Establece los horarios de inicio y fin para cada día, y define la duración de los intervalos.",
  choose_which_weeks_these_time_slots_will_be_available_for:
    "Elige qué semanas estarán disponibles estos intervalos de tiempo.",
  click_here_to_add_or_remove_time_slots:
    "Haz clic aquí para agregar o eliminar intervalos de tiempo.",
  once_you_are_done_click_here_to_save_your_settings:
    "Cuando hayas terminado, haz clic aquí para guardar tus ajustes.",
  click_here_if_you_want_to_cancel_and_exit:
    "Haz clic aquí si quieres cancelar y salir.",
  this_section_allows_you_to_book_a_consultation:
    "Esta sección te permite reservar una consulta.",
  this_is_the_heading_on_the_right_side_there_is_a_close_button_to_close_this_modal:
    "Este es el encabezado. En el lado derecho, hay un botón de cierre para cerrar este modal.",
  search_for_a_patient_by_entering_their_name_or_zoyel_patient_id_you_can_also_add_or_edit_patient_details:
    "Busca un paciente ingresando su nombre o el ID de paciente Zoyel. También puedes agregar o editar los detalles del paciente.",
  filter_patients_by_entering_their_name_zoyel_patient_id_contact_number_or_email_you_can_also_add_or_edit_patient_details:
    "Filtra pacientes ingresando su nombre, ID de paciente Zoyel, número de contacto o correo electrónico. También puedes agregar o editar los detalles del paciente.",
  search_for_a_patient_using_facial_biometrics_you_can_also_add_or_edit_patient_details:
    "Busca un paciente utilizando biometría facial. También puedes agregar o editar los detalles del paciente.",
  search_for_a_doctor_in_this_section: "Busca un médico en esta sección.",
  click_the_sos_button_if_there_is_an_emergency:
    "Haz clic en el botón 'SOS' si hay una emergencia.",
  click_submit_to_start_the_consultation_with_the_doctor:
    "Haz clic en 'Enviar' para comenzar la consulta con el médico.",
  this_section_where_you_can_add_the_investigation_of_the_patient:
    "Esta sección donde puedes agregar la investigación del paciente.",
  you_can_search_the_patient_here_and_view_and_add_the_required_investigation:
    "Puedes buscar al paciente aquí y ver y agregar la investigación requerida.",
  you_can_also_find_by_doing_facial_biometrics:
    "También puedes encontrarlo haciendo biometría facial.",
  click_here_to_add_the_given_data_by_clicking_on_submit:
    "Haz clic aquí para agregar los datos dados haciendo clic en enviar.",
  you_can_see_all_the_details_of_the_doctor_here:
    "Puedes ver todos los detalles del doctor aquí.",
  you_can_see_booking_date_time_here:
    "Puedes ver la fecha y hora de la reserva aquí.",
  you_have_to_give_email_id_or_zoyel_patient_id:
    "Tienes que proporcionar el ID de correo electrónico o el ID de paciente Zoyel.",
  click_on_confirm_button_to_confirm_your_appointment:
    "Haz clic en el botón Confirmar para confirmar tu cita.",
  here_you_can_do_patient_registration_using_patient_facial_biometrics_registration:
    "Aquí puedes hacer el registro de pacientes usando el registro biométrico facial del paciente.",
  this_is_the_header_where_you_can_navigate_back_or_view_the_document_title:
    "Este es el encabezado donde puedes navegar hacia atrás o ver el título del documento.",
  search_for_a_specific_patient_by_id_here:
    "Busca un paciente específico por su ID aquí.",
  on_clicking_this_icon_the_facial_biometrics_will_start_after_that_you_can_add_or_update_your_data:
    "Al hacer clic en este icono, comenzará el registro biométrico facial. Después de eso, puedes agregar o actualizar tus datos.",
  this_is_the_header_section_where_you_can_see_the_title_and_close_button:
    "Esta es la sección de encabezado donde puedes ver el título y el botón de cerrar.",
  this_section_contains_information_about_the_doctor_including_their_photo_name_and_specialization:
    "Esta sección contiene información sobre el médico, incluida su foto, nombre y especialización.",
  here_you_can_see_the_patient_details_like_their_name_age_and_gender:
    "Aquí, puedes ver los detalles del paciente como su nombre, edad y género.",
  this_section_covers_vital_signs_and_medical_history_including_patient_complaints:
    "Esta sección cubre los signos vitales y el historial médico, incluidas las quejas del paciente.",
  this_section_lets_you_upload_files_like_medical_records:
    "Esta sección te permite cargar archivos como los registros médicos.",
  here_you_can_input_details_about_systemic_illnesses_and_substance_abuse:
    "Aquí, puedes ingresar detalles sobre enfermedades sistémicas y abuso de sustancias.",
  click_here_to_submit_the_form: "Haz clic aquí para enviar el formulario.",
  you_can_book_a_doctor_appointment_by_clicking_the_appointment_button_and_view_all_appointments_by_clicking_the_view_appointment_button:
    "Puedes reservar una cita con el doctor haciendo clic en el botón 'Cita' y ver todas las citas haciendo clic en el botón 'Ver Cita'.",
  click_here_to_refresh_the_dashboard_and_fetch_the_latest_data:
    "Haz clic aquí para actualizar el panel de control y obtener los datos más recientes.",
  this_chart_displays_todays_unit_wise_appointments_hover_over_the_segments_to_view_details:
    "Este gráfico muestra las citas de hoy por unidad. Pasa el ratón sobre los segmentos para ver los detalles.",
  this_bar_chart_shows_the_hourly_distribution_of_todays_appointments:
    "Este gráfico de barras muestra la distribución horaria de las citas de hoy.",
  this_shows_the_total_number_of_appointments_for_the_day:
    "Esto muestra el número total de citas para el día.",
  click_here_to_view_all_booked_appointments_and_their_details:
    "Haz clic aquí para ver todas las citas reservadas y sus detalles.",
  click_here_to_book_a_new_appointment_with_a_doctor:
    "Haz clic aquí para reservar una nueva cita con un médico.",
  this_card_shows_the_book_consultation_details:
    "Esta tarjeta muestra los detalles de la consulta reservada.",
  click_here_to_refresh_and_update_the_data:
    "Haz clic aquí para actualizar y obtener los últimos datos.",
  here_you_can_see_the_current_number_of_consultations_in_the_queue:
    "Aquí puedes ver el número actual de consultas en la cola.",
  this_pie_chart_shows_todays_specialization_wise_consultation_stats:
    "Este gráfico circular muestra las estadísticas de consultas por especialización de hoy.",
  this_bar_chart_displays_day_wise_consultation_trends:
    "Este gráfico de barras muestra las tendencias de consultas por día.",
  click_here_to_view_all_consultations_in_the_queue:
    "Haz clic aquí para ver todas las consultas en la cola.",
  click_here_to_add_a_new_consultation:
    "Haz clic aquí para agregar una nueva consulta.",
  this_is_the_section_where_you_can_view_doctor_consultations:
    "Esta es la sección donde puedes ver las consultas de los doctores.",
  this_is_the_section_where_you_can_view_doctor_consultations_data_and_refresh_the_charts:
    "Esta es la sección donde puedes ver los datos de las consultas de los doctores y actualizar los gráficos.",
  this_chart_shows_the_distribution_of_patient_data_across_different_categories:
    "Este gráfico muestra la distribución de los datos de los pacientes en diferentes categorías. Pasa el ratón sobre un segmento para ver los detalles.",
  this_chart_shows_day_wise_consultation_counts:
    "Este gráfico muestra la cantidad de consultas por día. Te ayuda a analizar las tendencias a lo largo del tiempo.",
  click_here_to_view_detailed_consultations_list:
    "Haz clic aquí para ver la lista detallada de consultas.",
  this_section_displays_name_of_investigation_unit:
    "Esta sección muestra el nombre de la unidad de investigación.",
  click_here_to_refresh_data_for_dashboard:
    "Haz clic aquí para actualizar los datos de este panel.",
  this_graph_displays_investigation_trends_over_time:
    "Este gráfico muestra las tendencias de investigación a lo largo del tiempo.",
  click_here_to_add_new_investigation_unit:
    "Haz clic aquí para agregar una nueva unidad de investigación.",
  this_section_displays_card_management_units:
    "Esta sección muestra las unidades de gestión de tarjetas.",
  click_here_to_refresh_unit_data:
    "Haz clic aquí para actualizar los datos de la unidad.",
  this_area_visualizes_unit_data_geographically:
    "Esta área visualiza los datos de la unidad geográficamente sobre consultas y enfermedades. El color indica si la unidad está en línea (verde) o fuera de línea (rojo).",
  this_section_displays_card_management_consultations:
    "Esta sección muestra las consultas de gestión de tarjetas.",
  click_refresh_icon_to_update_data:
    "Haz clic en el ícono de actualización para actualizar los datos.",
  use_dropdown_to_filter_data_by_units:
    "Usa este menú desplegable para filtrar los datos por unidades específicas.",
  toggle_between_daily_or_monthly_views:
    "Alterna entre vistas diarias o mensuales utilizando este filtro.",
  click_to_view_chart_in_full_screen:
    "Haz clic aquí para ver el gráfico en pantalla completa.",
  this_chart_visualizes_consultation_data:
    "Este gráfico visualiza los datos de consulta. Estado del día actual con el número de registros, consultas, pacientes en espera, pacientes repetidos, pacientes cancelados, centros activos y médicos.",
  this_bar_chart_represent_consultation_repeat_patients_and_doctors:
    "Este gráfico de barras representa Consultas, Número de pacientes repetidos, Número de médicos realizando consultas.",
  this_section_displays_disease_management_card:
    "Esta sección muestra una tarjeta sobre la gestión de enfermedades.",
  click_here_to_refresh_data:
    "Haz clic aquí para actualizar los datos según las fechas seleccionadas.",
  select_from_date_to_start_viewing_data:
    "Selecciona la 'Fecha de inicio' para comenzar a ver los datos.",
  select_to_date_to_complete_date_range:
    "Selecciona la 'Fecha de finalización' para completar el rango de fechas.",
  disease_data_visualization_by_age:
    "Aquí es donde los datos de enfermedades se visualizan en un formato de gráfico según la edad.",
  this_section_displays_card_management_doctor:
    "Esta sección muestra la gestión de tarjetas del doctor.",
  click_here_to_refresh_doctor_data:
    "Haz clic aquí para actualizar los datos del doctor.",
  select_from_date_to_filter_data:
    "Selecciona la 'Fecha de inicio' para filtrar los datos.",
  select_to_date_to_filter_data:
    "Selecciona la 'Fecha de finalización' para filtrar los datos.",
  click_here_to_view_chart_full_screen:
    "Haz clic aquí para ver el gráfico en pantalla completa.",
  doctor_statistics_chart:
    "Este gráfico muestra las estadísticas del doctor, consultas del doctor, doctor en línea.",
  doctor_speciality_wise_consultation_chart:
    "Este gráfico muestra la consulta del doctor según la especialidad.",
  this_section_displays_card_management_feedback:
    "Esta sección muestra la gestión de tarjetas de comentarios.",
  click_here_to_refresh_feedback_data:
    "Haz clic aquí para actualizar los datos de comentarios manualmente.",
  use_dropdown_to_filter_feedback:
    "Usa este menú desplegable para filtrar los comentarios por 'Paciente' o 'Doctor'.",
  gauge_chart_overall_feedback:
    "Este gráfico de medidor representa la calificación general de comentarios.",
  analyze_performance_trends:
    "Analiza las tendencias de rendimiento con este gráfico de barras que muestra los conteos de comentarios.",
  this_section_displays_card_management_support_tickets:
    "Esta sección muestra la gestión de tarjetas sobre los tickets de soporte.",
  click_refresh_icon_to_update_data:
    "Haz clic en el ícono de actualización para actualizar los datos.",
  select_ticket_category_to_filter_data:
    "Selecciona la categoría del ticket (por ejemplo, Abierto, Cerrado) para filtrar los datos.",
  chart_shows_ticket_distribution_by_category:
    "Este gráfico muestra la distribución de tickets por categoría.",
  chart_shows_ticket_distribution_open_close:
    "Este gráfico muestra la distribución de tickets según abiertos y cerrados.",
  this_section_displays_management_trends:
    "Esta sección muestra las tendencias de gestión para las unidades o médicos seleccionados durante un período específico.",
  click_icon_to_refresh_trends_data:
    "Haz clic en este ícono para actualizar los datos de tendencias.",
  use_dropdown_to_switch_between_units_or_doctors:
    "Usa este desplegable para alternar entre ver las tendencias de unidades o médicos específicos.",
  select_to_view_trends_by_month_or_day:
    "Selecciona si deseas ver los datos de tendencias por mes o por día.",
  chart_visualizes_trends_based_on_filters:
    "Este gráfico visualiza las tendencias crecientes y decrecientes según los filtros seleccionados.",
  this_section_displays_key_metrics_for_patient_documents:
    "Esta sección muestra métricas clave y visualizaciones para los documentos de los pacientes.",
  click_here_to_refresh_patient_dashboard:
    "Haga clic aquí para actualizar el panel de documentos del paciente.",
  this_chart_shows_distribution_of_patient_data:
    "Este gráfico muestra la distribución de los datos del paciente en diferentes categorías. Pase el cursor sobre un segmento para ver los detalles.",
  this_bar_chart_shows_diagnostic_statistics:
    "Este gráfico de barras proporciona información sobre las estadísticas diagnósticas. El eje X muestra las categorías, y el eje Y representa los conteos.",
  click_here_to_view_detailed_patient_documents:
    "Haga clic aquí para ver los documentos detallados del paciente.",
  this_is_overview_of_patient_registrations:
    "Aquí es donde puede encontrar el resumen de las registraciones de pacientes.",
  click_here_to_refresh_and_get_latest_registrations:
    "Haga clic aquí para actualizar los datos y obtener las últimas registraciones.",
  gender_wise_distribution_of_registered_patients:
    "Aquí puede ver la distribución de pacientes registrados por género.",
  age_group_wise_registration_data_for_patients:
    "Este gráfico muestra los datos de registro de pacientes por grupo de edad.",
  click_here_to_add_new_patient_registration:
    "Haga clic aquí para agregar una nueva registración de paciente.",
  click_here_to_refresh_dashboard_data:
    "Haga clic aquí para actualizar los datos mostrados en el tablero.",
  chart_shows_referral_patient_data:
    "Este gráfico muestra los datos de los pacientes referidos de hoy. Pase el mouse para ver los detalles o use el conteo total en el centro para obtener una visión rápida.",
  chart_shows_review_trends:
    "Este gráfico representa las tendencias de revisión para hoy y los próximos períodos. Analice las tendencias fácilmente aquí.",
  total_referral_patients_today:
    "El número total de pacientes referidos hoy se muestra aquí.",
  click_to_view_detailed_referral_patient_info:
    "Haga clic en este botón para ver información detallada sobre los pacientes referidos.",
  click_to_view_detailed_review_patient_info:
    "Haga clic aquí para ver información detallada sobre los pacientes actualmente en revisión.",
  feedback_page_overview:
    "Esta es la página de retroalimentación. Por favor, proporcione su valiosa opinión para mejorar nuestros servicios.",
  rate_experience:
    "Por favor, califique su experiencia general con la consulta por video.",
  select_issues_encountered:
    "Seleccione los problemas que enfrentó durante la consulta, si los hubo.",
  leave_additional_comments:
    "Puede dejar comentarios o sugerencias adicionales aquí.",
  submit_feedback:
    "Cuando termine, haga clic aquí para enviar su retroalimentación.",
  thank_you_feedback:
    "¡Gracias por su retroalimentación! Apreciamos su opinión.",
  appointment_booking_details:
    "Aquí es donde ves los detalles de la reserva de cita para el(los) turno(s).",
  label_and_arrow_navigation:
    "Aquí puedes ver la etiqueta y también hay una flecha. Al hacer clic en ella, irás a la página anterior.",
  patient_info_tour: "Aquí puedes ver la información del paciente.",
  reschedule_from_here: "Puedes reprogramar desde aquí.",
  mark_as_available_tour: "Puedes marcar como disponible desde aquí.",
  mark_as_unavailable_tour: "Puedes marcar como no disponible desde aquí.",
  mark_unavailable_reason:
    "Puedes marcar el motivo de no disponibilidad desde aquí.",
  mark_available_unavailable:
    "Puedes marcar como disponible/no disponible desde aquí.",
  mark_available_unavailable_reason:
    "Puedes marcar el motivo de disponibilidad/no disponibilidad desde aquí.",
  submit_data: "Puedes enviar los datos desde aquí.",
  cancel_from_here: "Puedes cancelar desde aquí.",
  you_can_select_any_doctor_available_slot:
    "Puede seleccionar cualquier franja horaria disponible del médico y hacer una cita. Si desea verificar otras franjas horarias para el médico, haga clic en el botón 'Más franjas horarias'.",
  you_can_search_for_a_doctor_by_name_or_speciality:
    "Puede buscar un médico por nombre o especialidad.",
  here_you_can_see_the_doctors_available_slots:
    "Aquí puede ver las franjas horarias disponibles del médico.",
  select_a_time_slot_to_book_an_appointment:
    "Seleccione una franja horaria para hacer una cita.",
  click_here_to_view_more_available_slots:
    "Haga clic aquí para ver más franjas horarias disponibles para el médico seleccionado.",
  click_on_the_arrow_icon_to_return_to_the_previous_screen:
    "Haga clic en el icono de la flecha para volver a la pantalla anterior.",
  choose_the_doctor_you_want_to_book_an_appointment_with:
    "Elija el médico con el que desea hacer una cita.",
  pick_a_date_to_see_available_slots_for_the_selected_doctor:
    "Elija una fecha para ver las franjas horarias disponibles para el médico seleccionado.",
  here_are_the_available_slots_and_the_booked_slot_for_the_selected_date:
    "Aquí están las franjas horarias disponibles y la franja horaria reservada para la fecha seleccionada.",
  select_a_time_slot_to_book_an_appointment:
    "Seleccione una franja horaria para hacer una cita.",
  this_is_the_header_section_for_patient_queue_management:
    "Esta es la sección de encabezado para la gestión de la cola de pacientes. Aquí puedes ver y gestionar a los pacientes.",
  use_this_search_bar_to_filter_the_patient_queue_by_patient_name_or_id:
    "Utiliza esta barra de búsqueda para filtrar la cola de pacientes por nombre o ID del paciente. Es una forma rápida de encontrar pacientes específicos.",
  click_this_button_to_add_a_new_consultation_booking:
    "Haz clic en este botón para añadir una nueva reserva de consulta. Esto te permitirá crear una cita para un paciente.",
  here_you_can_see_the_details_of_all_patients_in_the_queue:
    "Aquí puedes ver los detalles de todos los pacientes en la cola, incluyendo su estado, médico y tiempos de espera.",
  this_section_allows_you_to_take_actions_such_as_starting_a_conversation_with_the_patient:
    "Esta sección te permite realizar acciones como iniciar una conversación con el paciente o gestionar su estado (por ejemplo, en progreso, completado).",
  patients_can_leave_the_center_by_clicking_this_button:
    "Los pacientes pueden abandonar el centro haciendo clic en este botón. Esto actualiza su estado en consecuencia.",
  if_a_patient_returns_to_the_center_they_can_click_here_to_resume_their_consultation_process:
    "Si un paciente regresa al centro, puede hacer clic aquí para reanudar su proceso de consulta.",
  click_here_to_download_the_patients_prescription_after_the_consultation:
    "Haz clic aquí para descargar la receta del paciente después de la consulta. Esto permite que el paciente acceda a su receta digitalmente.",
  click_this_button_to_switch_the_doctor_for_the_patients_consultation:
    "Haz clic en este botón para cambiar el médico de la consulta del paciente. Esto es útil si hay un cambio en la disponibilidad del médico o si el paciente necesita un especialista diferente.",
  use_this_option_to_add_a_test_result_for_the_patient:
    "Utiliza esta opción para añadir un resultado de prueba para el paciente. Puedes ingresar los resultados de laboratorio u otros datos relevantes de las pruebas aquí.",
  if_the_consultation_was_disconnected_or_interrupted_you_can_click_this_button_to_reconnect_with_the_doctor:
    "Si la consulta fue desconectada o interrumpida, puedes hacer clic en este botón para reconectar con el médico. Esto asegura que el paciente pueda continuar su consulta sin problemas.",
  this_is_where_you_can_view_all_the_appointments:
    "Aquí puedes ver todas las citas.",
  search_for_a_doctor_by_name_or_specialty_here:
    "Busca un médico por nombre o especialidad aquí.",
  you_can_select_date_to_filter_appointments_by_selected_date:
    "Puedes seleccionar una fecha para filtrar las citas por la fecha seleccionada.",
  here_you_can_see_a_list_of_booked_doctors_slots:
    "Aquí puedes ver una lista de los horarios de los médicos reservados.",
  click_on_a_doctors_card_to_view_booked_slots:
    "Haz clic en la tarjeta de un médico para ver los horarios reservados.",
  select_a_time_slot_for_viewing_details_of_the_booked_slot:
    "Selecciona una franja horaria para ver los detalles del horario reservado.",
  click_here_to_go_back_to_the_previous_page:
    "Haz clic aquí para volver a la página anterior.",
  select_the_start_date_for_the_consultation_range:
    "Selecciona la fecha de inicio para el rango de consultas.",
  pick_an_end_date_to_filter_consultations:
    "Elige una fecha de finalización para filtrar las consultas.",
  click_here_to_search_for_consultations_based_on_the_selected_date_range:
    "Haz clic aquí para buscar consultas basadas en el rango de fechas seleccionado.",
  the_data_is_being_fetched_based_on_your_search_parameters:
    "Los datos se están obteniendo según los parámetros de búsqueda.",
  no_consultations_were_found_for_the_selected_dates:
    "No se encontraron consultas para las fechas seleccionadas.",
  here_you_can_see_the_list_of_patients_and_their_consultation_details:
    "Aquí puedes ver la lista de pacientes y los detalles de sus consultas.",
  view_all_booked_available_unavailable_doctor_slots:
    "Puedes ver todos los slots de doctor reservados, disponibles/no disponibles.",
  go_back_to_previous_page_arrow_button:
    "Puedes volver a la página anterior haciendo clic en este botón de flecha.",
  select_a_date_and_check_day_appointments:
    "Puedes seleccionar una fecha y ver las citas del día.",
  choose_a_day_to_reschedule_appointment_slot:
    "Elige un día para reprogramar un slot de cita.",
  reschedule_whole_day_slot:
    "Puedes reprogramar el slot del día completo haciendo clic en esto.",
  choose_particular_slot_to_reschedule:
    "Elige un slot particular para reprogramar.",
  view_doc_header_navigation:
    "Este es el encabezado donde puedes navegar hacia atrás o ver el título del documento.",
  use_filters_to_search_by_date_range:
    "Usa estos filtros para buscar documentos por rango de fechas.",
  search_for_patient_by_name_or_id:
    "Busca un paciente específico por nombre o ID aquí.",
  list_of_patient_documents:
    "Esta es la lista de documentos de pacientes basada en tu búsqueda.",
  refer_pat_close_tour: "Haz clic aquí para cerrar el recorrido",
  refer_pat_select_date: "Selecciona una fecha para la referencia",
  refer_pat_search_data:
    "Haz clic en el botón de búsqueda para obtener los datos",
  refer_pat_loading_data: "Cargando datos del paciente...",
  refer_pat_no_data: "No se encontraron datos del paciente",
  refer_pat_patient_data: "Aquí están los datos del paciente",
  refer_pat_scroll_table: "Desplázate por la tabla para ver más pacientes",
  refer_pat_row_details: "Cada fila muestra los detalles del paciente",
  review_pat_go_back: "Regresar a la página anterior",
  review_pat_prev_week: "Ir a la semana anterior",
  review_pat_next_week: "Ir a la próxima semana",
  review_pat_select_day: "Selecciona un día específico para ver los datos",
  review_pat_loading_data: "Cargando datos",
  review_pat_no_data: "No hay datos disponibles para este día",
  review_pat_patient_data: "Aquí está la tabla con los datos del paciente",
  review_pat_scroll_data: "Desplázate para ver más datos",
  review_pat_process_data:
    "Procesar los datos del paciente para consultar la cita",
  doc_pat_video_name_info:
    "Aquí puedes ver el nombre, ID, género y edad del paciente.",
  doc_pat_video_blood_group: "Aquí puedes ver el grupo sanguíneo del paciente.",
  doc_pat_video_referring_doctor:
    "Aquí puedes ver al médico remitente o fuente.",
  doc_pat_video_vitals:
    "Aquí puedes ver y actualizar los signos vitales del paciente.",
  doc_pat_video_bmi: "Aquí puedes ver el IMC del paciente.",
  doc_pat_video_systemic_illnesses:
    "Aquí puedes ver y actualizar las enfermedades sistémicas del paciente.",
  doc_pat_video_substance_abuse:
    "Aquí puedes ver y actualizar el historial de abuso de sustancias del paciente.",
  doc_pat_video_systemic_illnesses_data:
    "Aquí puedes acceder a los datos sobre las enfermedades sistémicas del paciente.",
  doc_pat_video_substance_abuse_data:
    "Aquí puedes acceder a los datos sobre el abuso de sustancias del paciente.",
  doc_pat_video_audio_file:
    "Aquí puedes escuchar el archivo de audio del paciente.",
  doc_pat_video_uploaded_files:
    "Aquí puedes ver los archivos subidos por el paciente.",
  doc_pat_video_add_symptoms:
    "Aquí puedes ver y agregar los síntomas del paciente.",
  doc_pat_video_add_drug_allergies:
    "Aquí puedes ver y agregar las alergias a medicamentos del paciente.",
  doc_pat_video_add_food_allergies:
    "Aquí puedes ver y agregar las alergias a alimentos del paciente.",
  doc_pat_video_add_comorbidities:
    "Aquí puedes ver y agregar las comorbilidades del paciente.",
  doc_pat_video_physical_signs:
    "Aquí puedes ver los signos físicos del paciente.",
  doc_pat_video_add_physical_examination:
    "Aquí puedes ver y agregar el examen físico del paciente.",
  doc_pat_video_add_history_risk_factors:
    "Aquí puedes ver y agregar los antecedentes y factores de riesgo del paciente.",
  doc_pat_video_add_family_history:
    "Aquí puedes ver y agregar el historial familiar del paciente.",
  doc_pat_video_add_possible_diagnoses:
    "Aquí puedes ver y agregar los posibles diagnósticos para el paciente.",
  doc_pat_video_add_investigations:
    "Aquí puedes ver y agregar investigaciones relacionadas con el paciente.",
  doc_pat_video_test_results:
    "Aquí puedes acceder a los resultados de las pruebas del paciente.",
  doc_pat_video_input_test_results:
    "Aquí puedes ingresar los resultados de las pruebas del paciente.",
  doc_pat_video_add_final_diagnosis:
    "Aquí puedes ver y agregar el diagnóstico final del paciente.",
  doc_pat_video_add_medications:
    "Aquí puedes ver y agregar los medicamentos recetados por el médico.",
  doc_pat_video_add_doctor_recommendations:
    "Aquí puedes ver y agregar las recomendaciones del médico.",
  doc_pat_video_doctor_suggestions:
    "Aquí puedes encontrar otras sugerencias proporcionadas por el médico, como 'Parámetros a monitorear', 'nota del doctor', 'prestar atención a' y más. Luego haz clic en el botón de receta.",
  doc_pat_video_patient_messages:
    "Aquí puedes ver los mensajes relacionados con el paciente.",
  doc_pat_video_audio_files: "Aquí puedes escuchar archivos de audio.",
  video_prescription_preview_prescription:
    "Esta sección permite al médico previsualizar la receta.",
  video_prescription_prescription_type:
    "Esta sección muestra el tipo de receta.",
  video_prescription_patient_details:
    "Esta sección muestra los detalles del paciente.",
  video_prescription_patient_vitals:
    "Esta sección muestra los signos vitales del paciente.",
  video_prescription_patient_complaints:
    "Esta sección muestra las quejas del paciente.",
  video_prescription_examination_signs:
    "Esta sección muestra la exploración y signos del paciente.",
  video_prescription_comorbidities:
    "Esta sección muestra las comorbilidades del paciente.",
  video_prescription_risk_factors:
    "Esta sección muestra los factores de riesgo del paciente.",
  video_prescription_possible_diagnosis:
    "Esta sección muestra el posible diagnóstico del paciente.",
  video_prescription_investigations:
    "Esta sección muestra las investigaciones del paciente.",
  video_prescription_diagnosed_conditions:
    "Esta sección muestra las condiciones diagnosticadas del paciente.",
  video_prescription_procedures:
    "Esta sección muestra los procedimientos del paciente.",
  video_prescription_patient_advice:
    "Esta sección proporciona consejos para el paciente.",
  video_prescription_monitoring_parameters:
    "Esta sección muestra los parámetros a monitorear para el paciente.",
  video_prescription_diet_recommendations:
    "Esta sección proporciona recomendaciones dietéticas para el paciente.",
  video_prescription_lifestyle_recommendations:
    "Esta sección proporciona recomendaciones de estilo de vida para el paciente.",
  video_prescription_referrer_details:
    "Esta sección muestra quién refirió al paciente.",
  video_prescription_referral_details:
    "Esta sección muestra los detalles de la referencia del paciente.",
  video_prescription_review_schedule:
    "Esta sección muestra el próximo horario de revisión del paciente.",
  video_prescription_doctors_notes:
    "Esta sección muestra las notas del médico para el paciente.",
  video_prescription_listen_audio: "Esta sección permite escuchar un audio.",
  video_prescription_watch_outs:
    "Esta sección resalta aspectos importantes a tener en cuenta.",
  video_prescription_audio_message:
    "Esta sección permite escuchar un mensaje de audio.",
  inbox_tab_instructions:
    "Esta es tu pestaña de Bandeja de Entrada. Por favor haz clic aquí para ir a la Cola de Pacientes.",
  apps_tab_instructions: "Por favor haz clic en Apps.",
  profile_step_instructions:
    "Haz clic en este Avatar y luego selecciona perfil del menú desplegable.",
  create_support_ticket_instructions:
    "Haz clic en este Botón para crear un Ticket de Soporte.",
  task_app_instructions: "Por favor, haz clic en el botón de Tareas aquí.",
  admin_app_instructions:
    "Por favor, haz clic en el botón de Administrador aquí.",
  chat_app_instructions: "Por favor, haz clic en el botón de Chat aquí.",
  scheduler_app_instructions:
    "Por favor, haz clic en el botón de Programador aquí.",
  drive_app_instructions: "Por favor, haz clic en el botón de Unidad aquí.",
  support_ticket_app_instructions:
    "Por favor, haz clic en el botón del Panel de Soporte aquí.",
  meeting_room_app_instructions:
    "Por favor, haz clic en el botón de Sala de Reuniones aquí.",
  home_queue_step_two:
    "Esta es la cola de pacientes. Por favor, haz clic en un paciente en particular para ver los detalles.",
  home_rightside_step_two:
    "Esta sección permite al médico ver los detalles del paciente antes de la consulta.",
  home_rightside_step_three:
    "Haz clic en el botón 'Iniciar consulta' para comenzar una videollamada en vivo con el paciente.",
  home_rightside_step_four:
    "Haz clic en este botón para cambiar a otro paciente.",
  home_rightside_step_five:
    "Haz clic en este botón para cerrar el panel del lado derecho.",
  home_rightside_step_six:
    "Aquí puedes ver los detalles del paciente, como su imagen, ID, edad, género, grupo sanguíneo y más.",
  home_rightside_step_seven: "Aquí puedes ver el tipo de paciente.",
  home_rightside_step_eight: "Aquí puedes ver los signos vitales del paciente.",
  home_rightside_step_nine: "Aquí puedes ver las quejas del paciente.",
  home_rightside_step_ten:
    "Aquí puedes escuchar las grabaciones de audio del paciente.",
  home_rightside_step_eleven:
    "Aquí puedes ver los medicamentos actuales del paciente.",
  home_rightside_step_twelve:
    "Aquí puedes ver las condiciones médicas previas del paciente.",
  home_rightside_step_thirteen:
    "Aquí puedes ver las investigaciones del paciente.",
  home_rightside_step_fourteen:
    "Aquí puedes ver los archivos subidos por el paciente.",
  home_rightside_step_fifteen:
    "Aquí puedes ver información sobre quién refirió al paciente.",
  home_rightside_step_sixteen: "Puedes actualizar los comentarios aquí.",
  support_ticket_create_step_one:
    "Aquí es donde puedes cerrar el panel de soporte.",
  support_ticket_create_step_two:
    "Esta área contiene la conversación o los formularios de comentarios.",
  support_ticket_create_step_three:
    "Aquí puedes interactuar con el bot para obtener soporte.",
  support_ticket_create_step_four:
    "Puedes crear un ticket de soporte después de chatear con el bot.",
  support_ticket_create_step_five:
    "Adjunta capturas de pantalla o archivos relacionados con errores.",
  support_ticket_create_step_six:
    "Envía tus comentarios aquí después de ingresar los detalles.",
  support_ticket_create_step_seven:
    "Escribe tu mensaje aquí para interactuar con el bot.",
  support_ticket_view_step_one: "Aquí puedes ver todos los tickets de soporte.",
  support_ticket_view_step_two:
    "Usa este desplegable para filtrar tickets por diferentes criterios y luego escribe tu consulta para encontrar tickets específicos.",
  support_ticket_view_step_three:
    "Selecciona una fecha de inicio para filtrar los tickets por fecha.",
  support_ticket_view_step_four:
    "Selecciona una fecha de fin para filtrar los tickets por fecha.",
  support_ticket_view_step_five:
    "Haz clic aquí para iniciar la búsqueda y ver los resultados.",
  support_ticket_view_step_six:
    "Aquí está la lista de tickets de soporte según tus criterios de búsqueda.",
  support_ticket_view_step_seven:
    "Haz clic en cualquier ticket para ver más detalles.",
  support_tick_right_step_one: "Este es el identificador único del ticket.",
  support_tick_right_step_two:
    "Esta sección muestra la categoría del problema planteado.",
  support_tick_right_step_three: "Esta sección muestra el nombre del problema.",
  support_tick_right_step_four:
    "Esta sección muestra la descripción del problema.",
  support_tick_right_step_five:
    "Esta sección muestra la prioridad del problema.",
  support_tick_right_step_six:
    "Esta sección muestra la fecha y hora en que se creó el problema.",
  support_tick_right_step_seven:
    "Aquí están los archivos subidos para este ticket.",
  support_tick_right_step_eight:
    "Esta sección muestra los comentarios relacionados con el ticket.",
  this_is_the_profile_section: "Esta es la sección de perfil",
  update_profile_picture_face_register:
    "Aquí puede actualizar su foto de perfil, actualizar su nombre e incluso registrar su cara para la biometría facial",
  set_default_landing_page:
    "Aquí puede establecer la página de inicio predeterminada",
  view_employee_id: "Aquí puede ver la identificación del empleado",
  view_employee_department: "Aquí puede ver el departamento del empleado",
  view_employee_position:
    "Aquí puede ver la posición del empleado en una organización",
  view_employee_date_of_joining:
    "Aquí puede ver la fecha de ingreso del empleado en una organización",
  view_employee_reporting_head:
    "Aquí puede ver el jefe de reporte del empleado en una organización",
  view_employee_email_id:
    "Aquí puede ver el correo electrónico del empleado en una organización",
  view_update_employee_language:
    "Aquí puede ver y actualizar el idioma preferido del empleado",
  view_update_employee_skills:
    "Aquí puede ver y actualizar las habilidades del empleado",
  view_update_employee_dob:
    "Aquí puede ver y actualizar la fecha de nacimiento del empleado",
  view_update_employee_marital_status:
    "Aquí puede ver y actualizar el estado civil del empleado",
  view_update_employee_contact_number:
    "Aquí puede ver y actualizar el número de contacto personal del empleado",
  view_update_employee_official_number:
    "Aquí puede ver y actualizar el número oficial personal del empleado",
  view_update_employee_emergency_number:
    "Aquí puede ver y actualizar el número de emergencia personal del empleado",
  reset_login_password:
    "Aquí puede restablecer la contraseña de inicio de sesión",
  view_update_employee_esign:
    "Aquí puede ver y actualizar la firma electrónica del empleado",
  drive_create_folders_upload_files:
    "Haga clic aquí para crear nuevas carpetas, cargar archivos, crear diapositivas, Nuevo Word o Nueva Hoja",
  drive_use_search_bar:
    "Use la barra de búsqueda para filtrar archivos y carpetas rápidamente.",
  drive_available_storage:
    "Esto muestra su espacio de almacenamiento disponible.",
  drive_total_storage:
    "Esto muestra el espacio de almacenamiento total disponible.",
  drive_more_info_storage:
    "¡Haga clic aquí para obtener más información sobre tipos de archivos y almacenamiento!",
  drive_files_and_folders: "Aquí es donde se muestran sus archivos y carpetas.",
  select_the_event_mode_from_virtual_physical_or_hybrid:
    "Seleccione el modo de evento entre Virtual, Físico o Híbrido.",
  enter_the_location_for_the_event_here:
    "Ingrese la ubicación del evento aquí.",
  search_and_add_participant_by_typing_their_name:
    "Busque y agregue participantes escribiendo su nombre.",
  click_here_to_add_a_new_guest_to_the_meeting:
    "Haga clic aquí para agregar un nuevo invitado a la reunión.",
  here_is_the_list_of_all_the_participants_youve_added:
    "Aquí está la lista de todos los participantes que ha agregado.",
  set_the_start_and_time_for_your_meeting_here:
    "Establezca la hora de inicio y finalización de su reunión aquí.",
  set_the_end_date_and_time_for_your_meeting_here:
    "Establezca la fecha y hora de finalización de su reunión aquí.",
  check_this_option_if_you_want_to_exclude_yourself_from_the_event:
    "Marque esta opción si desea excluirse del evento.",
  provide_a_title_for_your_meeting_here:
    "Proporcione un título para su reunión aquí.",
  click_here_for_information_about_the_allowed_file_types_and_sizes:
    "Haga clic aquí para obtener información sobre los tipos de archivos y tamaños permitidos.",
  add_meeting_agendas_in_this_section:
    "Agregue las agendas de la reunión en esta sección.",
  provide_meeting_agendas_title_for_your_meeting_here:
    "Proporcione el título de las agendas de la reunión aquí.",
  provide_meeting_agendas_description_for_your_meeting_here:
    "Proporcione la descripción de las agendas de la reunión aquí.",
  you_can_upload_meeting_agenda_file:
    "Puede cargar el archivo de la agenda de la reunión.",
  you_can_between_polling_and_show_of_hand:
    "Puede elegir entre votación o levantamiento de manos.",
  you_can_a_new_meeting_agendas: "Puede agregar nuevas agendas de reunión.",
  in_this_section_you_can_give_special_resolution_for_meeting:
    "En esta sección puede dar resolución especial para la reunión.",
  here_you_can_give_special_resolution_name:
    "Aquí puede dar el nombre de la resolución especial.",
  here_you_can_add_special_resolution_file:
    "Aquí puede agregar el archivo de resolución especial.",
  here_you_can_give_polling_or_show_of_hand_for_special_resolution_file:
    "Aquí puede dar votación o levantamiento de manos para el archivo de resolución especial.",
  here_you_can_add_a_new_section_for_special_resolution_for_meeting:
    "Aquí puede agregar una nueva sección para la resolución especial de la reunión.",
  in_this_section_you_can_give_additional_documents_for_meeting:
    "En esta sección puede dar documentos adicionales para la reunión.",
  here_you_can_give_additional_documents_name:
    "Aquí puede dar el nombre de los documentos adicionales.",
  here_you_can_add_additional_documents_file:
    "Aquí puede agregar el archivo de documentos adicionales.",
  here_you_can_add_a_new_section_for_additional_documents_for_meeting:
    "Aquí puede agregar una nueva sección para los documentos adicionales de la reunión.",
  submit_all_the_details_of_the_event_here:
    "Envíe todos los detalles del evento aquí.",
  select_the_type_of_leave_you_want_to_apply_for:
    "Seleccione el tipo de permiso que desea solicitar.",
  this_shows_your_available_leave_balance:
    "Esto muestra su saldo de permisos disponibles.",
  provide_the_purpose_of_leave_here:
    "Proporcione el propósito del permiso aquí.",
  pick_the_start_date_for_your_leave:
    "Seleccione la fecha de inicio de su permiso.",
  pick_the_end_date_for_your_leave:
    "Seleccione la fecha de finalización de su permiso.",
  you_can_see_your_leave_details_here:
    "Puede ver los detalles de su permiso aquí.",
  submit_all_the_details_of_the_event_here:
    "Envíe todos los detalles del evento aquí.",
  set_the_event_title_here:
    "Aquí es donde puedes establecer el título del evento.",
  select_the_event_start_date_and_time_here:
    "Selecciona aquí la fecha y hora de inicio del evento.",
  select_the_event_end_date_and_time_here:
    "Ahora, selecciona aquí la fecha y hora de finalización del evento.",
  add_a_description_for_the_event_here:
    "Agrega una descripción para el evento aquí.",
  provide_the_event_location_here:
    "Proporciona la ubicación del evento en este campo.",
  search_and_add_participants_for_your_event:
    "Busca y agrega participantes para tu evento aquí.",
  click_here_to_add_a_new_guest_to_your_event:
    "Haz clic aquí para agregar un nuevo invitado a tu evento.",
  list_of_all_participants_you_added:
    "Aquí está la lista de todos los participantes que has agregado.",
  submit_all_the_details_here: "Envía todos los detalles aquí.",
  action_header_description:
    "Este es el encabezado donde puedes ver el tipo de acción que estás realizando.",
  close_floating_form: "Haz clic aquí para cerrar el formulario flotante.",
  select_task_type_description:
    "Selecciona el tipo de tarea que te gustaría crear: Evento, Permiso o Seminario web.",
  choose_event_type_dropdown:
    "Elige el tipo de evento desde este menú desplegable.",
  this_is_the_main_header_where_you_can_navigate_or_manage_the_scheduler:
    "Este es el encabezado principal donde puedes navegar o gestionar el programador.",
  use_this_dropdown_to_create_new_events_leave_requests_or_webinars:
    "Usa este menú desplegable para crear nuevos eventos, solicitudes de permiso o seminarios web.",
  use_these_buttons_to_navigate_through_the_scheduler:
    "Usa estos botones para navegar por el programador.",
  click_here_to_jump_back_to_todays_date:
    "Haz clic aquí para volver a la fecha de hoy.",
  select_a_specific_date_to_view_or_schedule_events:
    "Selecciona una fecha específica para ver o programar eventos.",
  these_legends_help_you_identify_calendar_categories_like_holidays_and_leaves:
    "Estas leyendas te ayudan a identificar categorías del calendario como Feriados y Permisos.",
  switch_between_day_week_month_and_agenda_views:
    "Cambia entre las vistas de Día, Semana, Mes y Agenda.",
  configure_your_calendar_preferences_here:
    "Configura tus preferencias de calendario aquí.",
  this_is_where_all_scheduled_events_are_displayed:
    "Aquí se muestran todos los eventos programados.",
  select_the_event_mode_from_virtual_physical_or_hybrid:
    "Seleccione el modo de evento: Virtual, Presencial o Híbrido.",
  enter_the_location_for_the_event_here:
    "Ingrese la ubicación del evento aquí.",
  search_and_add_participant_by_typing_their_name:
    "Busque y agregue participantes escribiendo su nombre.",
  click_here_to_add_a_new_guest_to_the_meeting:
    "Haga clic aquí para agregar un nuevo invitado a la reunión.",
  here_is_the_list_of_all_the_participants_you_have_added:
    "Aquí está la lista de todos los participantes que ha agregado.",
  set_the_start_and_time_for_your_meeting_here:
    "Establezca la hora de inicio para su reunión aquí.",
  set_the_end_date_and_time_for_your_meeting_here:
    "Establezca la fecha y hora de finalización para su reunión aquí.",
  check_this_option_if_you_want_to_exclude_yourself_from_the_event:
    "Marque esta opción si desea excluirse del evento.",
  provide_a_title_for_your_meeting_here:
    "Proporcione un título para su reunión aquí.",
  add_meeting_agendas_in_this_section:
    "Agregue las agendas de la reunión en esta sección.",
  provide_meeting_agendas_test_observation_for_your_meeting_here:
    "Proporcione agendas de reunión Prueba Observación para su reunión aquí.",
  you_can_add_new_meeting_agendas: "Puede agregar nuevas agendas de reuniones.",
  submit_all_the_details_of_the_event_here:
    "Envíe todos los detalles del evento aquí.",
  set_webinar_title_here:
    "Aquí es donde puedes establecer el título del seminario web.",
  select_start_date_and_time_for_webinar:
    "Selecciona la fecha y hora de inicio del seminario web aquí.",
  select_end_date_and_time_for_webinar:
    "Ahora, selecciona la fecha y hora de finalización del seminario web aquí.",
  add_webinar_description_here:
    "Agrega una descripción para el seminario web aquí.",
  provide_webinar_location_here:
    "Proporcione la ubicación del seminario web en este campo.",
  provide_webinar_slug_here:
    "Proporcione el Slug del seminario web en este campo.",
  search_and_add_participants_to_event:
    "Busca y agrega participantes para tu evento aquí.",
  click_to_add_new_guest_to_event:
    "Haz clic aquí para agregar un nuevo invitado a tu evento.",
  list_of_all_participants_added:
    "Aquí está la lista de todos los participantes que has agregado.",
  submit_event_details_here: "Envía todos los detalles del evento aquí.",
  support_admin_features_click_to_view_details:
    "Estas son todas las funciones en Support Admin. Por favor, haz clic en cualquiera para ver los detalles.",
  choose_user_manage_access_permissions:
    "Primero, elige al usuario para quien deseas gestionar los permisos de acceso. Luego puedes ver la lista de menús disponibles. Puedes seleccionar o deseleccionar rápidamente todos los menús activando o desactivando los interruptores.",
  save_changes_after_selection:
    "Una vez que hayas hecho tus cambios, haz clic aquí para guardarlos.",
  discard_changes_and_close_form:
    "Haz clic aquí si deseas descartar los cambios y cerrar el formulario.",
  select_activity_step_one:
    "Selecciona una actividad de la lista para asociarla con tu plantilla.",
  select_activity_step_two:
    "Arrastra y suelta archivos aquí, o haz clic abajo para cargarlos manualmente.",
  select_activity_step_three:
    "Gestiona tus archivos cargados aquí. Puedes ver o eliminar archivos según sea necesario.",
  select_activity_step_four:
    "Una vez que todo esté listo, haz clic aquí para cargar tu plantilla.",
  create_committee_step_one: "Aquí es donde puedes agregar comités.",
  create_committee_step_two: "Ingresa el nombre del comité aquí.",
  create_committee_step_three: "Agrega roles al comité en este campo.",
  create_committee_step_four:
    "Haz clic en este botón para agregar el rol que ingresaste.",
  create_committee_step_five:
    "Finalmente, haz clic aquí para guardar tu comité.",
  create_coupon_step_one: "Ingresa el propósito para crear este cupón aquí.",
  create_coupon_step_two:
    "Especifica el porcentaje de descuento para este cupón.",
  create_coupon_step_three: "Define cuántas veces se puede usar este cupón.",
  create_coupon_step_four:
    "Selecciona una fecha de vencimiento para el cupón. Solo se permiten fechas futuras.",
  create_coupon_step_five:
    "Proporciona el nombre de la persona que recibirá este cupón.",
  create_coupon_step_six:
    "Proporciona la ID de correo electrónico del receptor.",
  create_coupon_step_seven:
    "Ingresa un código personalizado para este cupón. Debe tener entre 5 y 10 caracteres.",
  create_coupon_step_eight:
    "Haz clic aquí para guardar el cupón después de ingresar todos los detalles.",
  create_coupon_step_nine: "Haz clic aquí para cancelar la creación del cupón.",
  create_policy_step_one_qwe: "Puedes crear políticas aquí.",
  create_policy_step_one:
    "Por favor, ingresa el número de serie para la política.",
  create_policy_step_two: "Ingresa un nombre para la política.",
  create_policy_step_three:
    "Elige si esta política es requerida durante la incorporación.",
  create_policy_step_four:
    "Haz clic aquí para subir el archivo de la política.",
  create_policy_step_five: "Finalmente, haz clic aquí para enviar la política.",
  create_salary_comp_step_one:
    "Aquí es donde agregas un nuevo componente salarial.",
  create_salary_comp_step_two:
    "Ingresa el nombre del componente salarial aquí.",
  create_salary_comp_step_three:
    "Selecciona una categoría para tu componente salarial.",
  create_salary_comp_step_four:
    "Haz clic aquí para agregar una nueva categoría si no existe.",
  create_salary_comp_step_five:
    "Elige la periodicidad para el componente salarial.",
  create_salary_comp_step_six: "Selecciona cómo se debe realizar el cálculo.",
  create_salary_comp_step_seven: "Configura los ajustes adicionales aquí.",
  create_salary_comp_step_eight:
    "Haz clic en este botón para guardar tu componente salarial.",
  add_org_storage_one:
    "Aquí es donde puedes gestionar y agregar almacenamiento para tu organización.",
  add_org_storage_two:
    "Aquí puedes ver el almacenamiento actual asignado a tu organización.",
  add_org_storage_three:
    "Esta sección muestra tu nuevo almacenamiento total después de la actualización.",
  add_org_storage_four:
    "Usa este control deslizante para seleccionar la cantidad de almacenamiento adicional que deseas agregar.",
  add_org_storage_five:
    "Revisa los detalles del costo del almacenamiento adicional que has seleccionado.",
  add_org_storage_six:
    "Haz clic en este botón para confirmar y actualizar tu almacenamiento.",
  add_org_storage_seven:
    "Haz clic aquí para cancelar la actualización de almacenamiento y volver a la pantalla anterior.",
  create_tax_statutory_step_one:
    "Aquí es donde puedes agregar el Estatutario de Impuestos.",
  create_tax_statutory_step_two: "Selecciona un nombre de la lista.",
  create_tax_statutory_step_three: "Elige una categoría de la lista.",
  create_tax_statutory_step_four:
    "Selecciona la frecuencia de renovación (Mensual, Trimestral, etc.).",
  create_tax_statutory_step_six: "Selecciona el(los) puesto(s) a notificar.",
  create_tax_statutory_step_seven:
    "Establece el tiempo de anticipación para las notificaciones.",
  create_tax_statutory_step_eight:
    "Haz clic para adjuntar cualquier documento.",
  create_tax_statutory_step_nine: "Haz clic aquí para guardar tus datos.",
  create_term_step_one_poiuy: "Aquí puedes Crear Términos y Condiciones.",
  create_term_step_one: "Selecciona los Términos y Condiciones para.",
  create_term_step_two: "Selecciona el Tipo de Términos y Condiciones.",
  create_term_step_three_two: "Ingresa el Tipo de Términos y Condiciones.",
  create_term_step_three:
    "Ingresa la descripción de los Términos y Condiciones.",
  create_term_step_four:
    "Haz clic en Guardar para crear los Términos y Condiciones.",
  create_term_step_five:
    "Haz clic aquí si deseas descartar cualquier cambio y cerrar el formulario.",
  create_unit_step_one: "Ingrese el nombre de la unidad que desea agregar.",
  create_unit_step_two:
    "Ingrese la latitud manualmente o utilice el botón de GPS.",
  create_unit_step_three:
    "Ingrese la longitud manualmente o utilice el botón de GPS.",
  create_unit_step_four:
    "Haga clic para obtener las coordenadas GPS automáticamente.",
  create_unit_step_five: "Elija la zona horaria para su unidad.",
  create_unit_step_six: "Elija el país para la ubicación de la unidad.",
  create_unit_step_seven: "Elija el estado para la ubicación de la unidad.",
  create_unit_step_eight:
    "Verifique las opciones de centro de costos y centro de beneficios.",
  create_unit_step_nine: "Elija si la unidad mantiene su propio balance.",
  create_unit_step_ten:
    "Seleccione el cumplimiento de normativas para la unidad.",
  create_unit_step_eleven:
    "Seleccione si el impuesto local es aplicable para la unidad.",
  create_unit_step_twelve: "Elija la moneda predeterminada para la unidad.",
  create_unit_step_thirteen: "Seleccione la unidad principal si aplica.",
  create_unit_step_fourteen:
    "Haga clic aquí para guardar los detalles de la unidad.",
  access_control_step_one:
    "Use esta barra de búsqueda para encontrar usuarios rápidamente por nombre.",
  access_control_step_two:
    "Haga clic aquí para agregar nuevos permisos de acceso.",
  access_control_step_three:
    "Esta tabla muestra a los usuarios, sus permisos de acceso y las acciones disponibles.",
  access_control_step_five:
    "Haga clic en este ícono para editar los permisos de acceso de un usuario.",
  activity_temp_step_one:
    "Ingresa un nombre de actividad aquí y haz clic en 'Agregar' para incluirlo en tu lista.",
  activity_temp_step_two:
    "Aquí puedes ver todas las actividades agregadas. Usa el ícono de la papelera para eliminar una actividad.",
  activity_temp_step_three: "Haz clic aquí para crear plantillas de actividad.",
  activity_temp_step_four:
    "Esta tabla lista todas las plantillas de actividad. Haz clic en el ícono del ojo para ver los detalles.",
  activity_temp_step_five: "Haz clic aquí para ver plantillas de actividad.",
  tab_committee_step_one:
    "Usa esta barra de búsqueda para filtrar los comités por nombre.",
  tab_committee_step_two: "Haz clic en este botón para crear un nuevo comité.",
  tab_committee_step_three:
    "Aquí puedes ver todos los comités listados. Haz clic en cualquier comité para editar o eliminar.",
  tab_committee_step_four:
    "Haz clic en estos botones de acción para editar o eliminar un comité.",
  org_coupon_step_one:
    "Utiliza este campo para buscar códigos de cupón según su propósito.",
  org_coupon_step_two: "Haz clic aquí para agregar un nuevo código de cupón.",
  org_coupon_step_three:
    "Aquí están los detalles de tus códigos de cupón, incluyendo propósito, descuento, vencimiento y estado.",
  org_coupon_step_five:
    "Este interruptor te permite activar o desactivar el código de cupón.",

  critical_right_step_one:
    "Este es el encabezado para la gestión de derechos críticos.",
  critical_right_step_two:
    "Aquí puedes gestionar los derechos para cada posición.",
  critical_right_step_three: "Elige la posición para este derecho crítico.",
  critical_right_step_four: "Ingresa las cantidades mínimas para este derecho.",
  critical_right_step_five: "Ingresa las cantidades máximas para este derecho.",
  critical_right_step_six:
    "Selecciona quién debe ser notificado cuando se modifique este derecho.",
  critical_right_step_seven:
    "Haz clic aquí para guardar o agregar nuevos derechos.",

  define_expenditure_step_one:
    "Introduce los elementos para el Gasto de Capital aquí.",
  define_expenditure_step_two:
    "Haz clic en este botón para agregar elementos a la lista de Gasto de Capital.",
  define_expenditure_step_three:
    "Ver los elementos añadidos para el Gasto de Capital aquí.",
  define_expenditure_step_four:
    "Introduce los elementos para el Gasto Operativo aquí.",
  define_expenditure_step_five:
    "Haz clic en este botón para agregar elementos a la lista de Gasto Operativo.",
  define_expenditure_step_six:
    "Ver los elementos añadidos para el Gasto Operativo aquí.",

  view_otp_service_step_one:
    "Utiliza esta barra de búsqueda para encontrar rápidamente los servicios OTP por nombre.",
  view_otp_service_step_two:
    "Aquí puedes ver todos los servicios OTP y gestionar sus estados.",
  view_otp_service_step_three:
    "Activa o desactiva el servicio OTP con este interruptor.",

  view_pms_step_one:
    "Bienvenido a la configuración del Sistema de Gestión del Desempeño",
  view_pms_step_two: "Seleccione la fecha de inicio para el ciclo de PMS.",
  view_pms_step_three:
    "Seleccione la fecha de finalización para el ciclo de PMS.",
  view_pms_step_four:
    "Use este interruptor para habilitar o deshabilitar el sistema de PMS.",
  view_pms_step_five: "Establezca la calificación máxima para el ciclo de PMS.",
  view_pms_step_six: "Agregue métricas de evaluación para el ciclo de PMS.",
  view_pms_step_six_seven: "Complete todos los detalles",

  view_policy_step_one:
    "Use esta barra de búsqueda para encontrar políticas específicas rápidamente.",
  view_policy_step_two:
    "Haga clic en este botón para agregar una nueva política.",
  view_policy_step_three:
    "Esta sección muestra una lista de todas las políticas.",
  view_policy_step_five:
    "Estos botones de acción le permiten descargar sus políticas o eliminar una política de la lista.",

  tab_salary_comp_step_one: "Buscar componentes salariales",
  tab_salary_comp_step_two:
    "Haga clic para agregar un nuevo componente salarial",
  tab_salary_comp_step_three:
    "Explore la tabla de datos de componentes salariales",
  tab_salary_comp_step_four:
    "Haga clic para editar o activar componentes salariales",

  org_storage_step_one:
    "Busque usuarios por su nombre para encontrar rápidamente los detalles de almacenamiento.",
  org_storage_step_two:
    "Esta barra de progreso muestra el porcentaje de almacenamiento utilizado por su organización.",
  org_storage_step_three:
    "Haga clic aquí para agregar nuevo espacio de almacenamiento para su organización.",
  org_storage_step_four:
    "Aquí puede ver el almacenamiento asignado, utilizado y la opción de editar el almacenamiento del usuario.",
  org_storage_step_five:
    "Haga clic en el botón de editar para modificar el almacenamiento asignado a un usuario.",

  tax_stat_step_one:
    "Comience buscando un artículo estatutario específico escribiendo aquí.",
  tax_stat_step_two:
    "Haga clic aquí para agregar un nuevo artículo estatutario.",
  tax_stat_step_three:
    "Aquí está la lista de los artículos estatutarios existentes. Puede ver detalles como nombre, categoría, fechas de pago y más.",
  tax_stat_step_five:
    "Una vez que busque, la tabla se actualizará automáticamente con los resultados coincidentes.",
  tax_stat_step_four:
    "Haga clic en este ícono de papelera para deshabilitar un artículo estatutario.",

  term_cond_step_one:
    "Utilice esta barra de búsqueda para filtrar los términos y condiciones por palabras clave.",
  term_cond_step_two:
    "Haga clic aquí para crear nuevos términos y condiciones.",
  term_cond_step_three:
    "Estos son los encabezados de la tabla que muestran los detalles de los términos y condiciones.",
  term_cond_step_four:
    "Aquí está la lista de todos los términos y condiciones. Puede editarlos o eliminarlos.",
  term_cond_step_five:
    "Haga clic en este botón para editar o eliminar el término o condición seleccionada.",

  tab_unit_step_one:
    "Esta sección muestra todas las unidades de su organización.",
  tab_unit_step_two:
    "Haga clic aquí para ver el árbol jerárquico de las unidades organizacionales.",
  tab_unit_step_three:
    "Use este botón para agregar una nueva unidad a su organización.",
  tab_unit_step_four:
    "Aquí puede ver información detallada sobre cada unidad, incluido su nombre, dirección, zona horaria, detalles de contacto y más.",
  tab_unit_step_five:
    "Haga clic en este botón para eliminar una unidad de la lista.",
  tab_unit_step_six:
    "Haga clic en este botón para editar los detalles de una unidad existente.",

  unit_tree_step_one:
    "Esta es la vista del árbol de unidades donde se enumeran todas las unidades.",
  unit_tree_step_two:
    "Este es el encabezado de la vista del árbol de unidades.",
  unit_tree_step_three:
    "Aquí está la estructura del árbol. Haga clic en las flechas para expandir o colapsar.",

  update_access_ctrl_step_one:
    "Puedes ver el usuario para el que deseas gestionar los permisos de acceso.",
  update_access_ctrl_step_two:
    "Usa estos interruptores para habilitar o deshabilitar el acceso a los menús para el usuario seleccionado.",
  update_access_ctrl_step_three:
    "Una vez que hayas realizado tus cambios, haz clic aquí para guardarlos.",
  update_access_ctrl_step_four:
    "Haz clic aquí si deseas descartar los cambios y cerrar el formulario.",

  edit_org_step_one: "Aquí puedes actualizar los detalles de la organización.",
  edit_org_step_two:
    "Haz clic aquí para cerrar el formulario y descartar cualquier cambio.",
  edit_org_step_three: "Haz clic aquí para cambiar el logo de tu organización.",
  edit_org_step_four: "Actualiza el nombre de la organización aquí.",
  edit_org_step_five: "Actualiza el nombre común de la organización aquí.",
  edit_org_step_seven:
    "Actualiza el número de casa o el nombre del edificio aquí.",
  edit_org_step_eight: "Actualiza el área o colonia aquí.",
  edit_org_step_nine: "Actualiza la ciudad aquí.",
  edit_org_step_ten: "Selecciona el país desde el menú desplegable.",
  edit_org_step_eleven: "Actualiza el código postal aquí.",
  edit_org_step_thirteen:
    "Consulta la dirección de correo electrónico de la organización aquí.",
  edit_org_step_fourteen:
    "Actualiza el número de contacto de la organización aquí.",
  edit_org_step_fifteen:
    "Selecciona el tipo de organización desde el menú desplegable.",
  edit_org_step_sixteen:
    "Selecciona el tipo de negocio de la organización desde el menú desplegable.",
  edit_org_step_seventeen:
    "Selecciona la fecha de incorporación de la organización.",
  edit_org_step_eighteen: "Introduce el nombre de la persona autorizada aquí.",
  edit_org_step_nineteen:
    "Introduce el número de contacto de la persona autorizada aquí.",
  edit_org_step_twenty: "Actualiza el número de licencia comercial aquí.",
  edit_org_step_twenty_one:
    "Actualiza el número de identificación de la empresa aquí.",
  edit_org_step_twenty_two: "Actualiza el número fiscal de la empresa aquí.",
  edit_org_step_twenty_three:
    "Selecciona la zona horaria de la empresa desde el menú desplegable.",
  edit_org_step_twenty_four:
    "Una vez que todos los campos estén completados, haz clic aquí para enviar el formulario.",

  select_activity_step_one: "Puedes ver la actividad seleccionada",
  select_activity_step_two:
    "Puedes ver los archivos cargados aquí. Puedes ver o eliminar archivos según sea necesario.",

  health_care_config_step_one:
    "Seleccione una configuración para gestionar los ajustes de atención médica.",
  health_care_config_step_two:
    "Haga clic aquí para agregar un nuevo Protocolo de Tratamiento Estándar.",
  health_care_config_step_three:
    "Esta tabla muestra información detallada sobre los protocolos.",
  health_care_config_step_four:
    "Use estos botones para editar o eliminar un protocolo.",

  doctor_unit_config_step_one:
    "Seleccione un doctor para asignarlo a sus respectivas unidades. Active o desactive los interruptores para habilitar o deshabilitar al doctor para la unidad particular, luego haga clic en el botón Guardar.",
  doctor_unit_config_step_two:
    "Seleccione una unidad para asignarla a sus respectivos doctores. Active o desactive los interruptores para habilitar o deshabilitar la unidad para el doctor particular, luego haga clic en el botón Guardar.",

  view_invest_config_step_one:
    "Esta es la tabla donde puedes ver todas las configuraciones de prueba.",
  view_invest_config_step_two:
    "Usa esta paginación para navegar entre las páginas de datos.",
  view_invest_config_step_three:
    "Estos botones te permiten editar, gestionar los parámetros de prueba o asignar pruebas a las unidades.",

  view_paramter_config_step_one:
    "Este es el encabezado de la tabla que muestra los detalles del parámetro.",
  view_paramter_config_step_two:
    "Aquí está la lista de parámetros con sus detalles.",
  view_paramter_config_step_three:
    "Haz clic aquí para agregar un nuevo parámetro de salud.",
  view_paramter_config_step_four:
    "Haz clic aquí para editar un parámetro existente.",

  test_unit_config_step_one:
    "Seleccione una prueba para asignarla a sus respectivas unidades. Active o desactive los interruptores para habilitar o deshabilitar la prueba para la unidad respectiva, luego haga clic en el botón Guardar.",
  test_unit_config_step_two:
    "Seleccione una unidad para asignarla a sus respectivas pruebas. Active o desactive los interruptores para habilitar o deshabilitar la unidad para la prueba respectiva, luego haga clic en el botón Guardar.",

  vital_config_step_one:
    "Aquí puede ver y editar las configuraciones de unidades vitales para su organización.",
  vital_config_step_two:
    "Con este botón Editar, puede modificar detalles específicos de vitales y luego Guardar para aplicar los cambios.",

  card_budget_step_one:
    "Este es su panel de presupuesto donde puede ver y gestionar los detalles del presupuesto.",
  card_budget_step_two:
    "Este gráfico circular representa la distribución de CAPEX y OPEX en su presupuesto.",
  card_budget_step_three:
    "Esta es la cantidad total del presupuesto, combinando todos los componentes.",
  card_budget_step_four:
    "Aquí puede ver una comparación detallada de las cantidades presupuestadas y reales para cada partida.",
  card_budget_step_five:
    "Haga clic aquí para ver información detallada del presupuesto.",
  card_budget_step_six: "Haga clic aquí para agregar un nuevo presupuesto.",

  card_contract_step_one:
    "Esta sección muestra la visión general del contrato.",
  card_contract_step_two:
    "Haga clic aquí para actualizar los datos del contrato.",
  card_contract_step_three:
    "Este gráfico circular muestra la distribución del contrato por tipo.",
  card_contract_step_four: "Este es el número total de contratos.",
  card_contract_step_five:
    "Este gráfico de barras representa los datos mensuales del contrato.",
  card_contract_step_six:
    "Haga clic aquí para ver más detalles sobre el contrato.",
  card_contract_step_seven: "Haga clic aquí para agregar un nuevo contrato.",

  card_external_user_step_one:
    "Esta sección muestra los detalles de los usuarios externos.",
  card_external_user_step_two:
    "Haga clic aquí para actualizar los datos de los usuarios externos.",
  card_external_user_step_three:
    "Vea el número total de usuarios externos en su organización.",
  card_external_user_step_four:
    "Este gráfico muestra los usuarios externos categorizados como Proveedor, Cliente, Socio, etc.",
  card_external_user_step_five:
    "Haga clic aquí para ver información detallada sobre los usuarios externos.",
  card_external_user_step_six:
    "Haga clic aquí para agregar un nuevo usuario externo.",

  card_holiday_step_one:
    "Esta sección proporciona una visión general de los días festivos y laborales.",
  card_holiday_step_two:
    "Haga clic en el botón de actualizar para obtener los datos más recientes.",
  card_holiday_step_three:
    "Este gráfico muestra los datos mensuales de días festivos. Pase el cursor sobre las barras para obtener detalles.",
  card_holiday_step_four:
    "Aquí se muestra el número de días festivos para el período seleccionado.",
  card_holiday_step_five:
    "Use estos botones para ver los días festivos según sus permisos.",
  card_holiday_step_six:
    "Use estos botones para agregar días festivos según sus permisos.",

  item_card_step_one:
    "Esta es la tarjeta del panel que muestra información general de los artículos.",
  item_card_step_two:
    "Aquí está el gráfico circular que muestra la distribución de bienes y servicios.",
  item_card_step_three:
    "Aquí está el gráfico de barras que visualiza los valores de los artículos y los niveles de stock.",
  item_card_step_four: "Puedes ver los artículos usando los botones.",
  item_card_step_five: "Puedes agregar nuevos artículos usando los botones.",

  leave_rule_card_step_one:
    "Esta es la sección de Reglas de Ausencias, donde puedes ver y gestionar los datos de las reglas de ausencias.",
  leave_rule_card_step_two:
    "Aquí hay un gráfico circular que muestra la distribución de ausencias por tipo.",
  leave_rule_card_step_three:
    "Este gráfico muestra las tendencias de ausencias a lo largo de los meses.",
  leave_rule_card_step_four:
    "Haz clic aquí para ver las reglas de ausencias detalladas.",
  leave_rule_card_step_five:
    "Haz clic aquí para agregar nuevas reglas de ausencias.",

  emp_card_step_one:
    "Esta sección muestra métricas clave y visualizaciones para los empleados.",
  emp_card_step_two: "Actualiza los datos para obtener las últimas novedades.",
  emp_card_step_three: "Detalles de rotación y antigüedad.",
  emp_card_step_four:
    "Este gráfico muestra la distribución por grupo de edad de los empleados.",
  emp_card_step_five:
    "Aquí puedes ver los datos de empleados por género y las tasas de rotación.",
  emp_card_step_six: "Haz clic para ver todos los empleados o personas.",
  emp_card_step_seven: "Haz clic para agregar un nuevo empleado o persona.",

  routine_work_step_one: "Esta es la sección de título del trabajo rutinario.",
  routine_work_step_two:
    "Este gráfico de rosquilla visualiza la distribución del trabajo rutinario. Pasa el ratón para ver el desglose.",
  routine_work_step_three:
    "Esto muestra el conteo total de trabajo rutinario para el día. Se actualiza dinámicamente para reflejar los cambios en tiempo real.",
  routine_work_step_four:
    "Este gráfico de líneas muestra los datos de las licencias de los empleados a lo largo del tiempo, mostrando tendencias y patrones en la asistencia.",
  routine_work_step_five:
    "Haz clic aquí para ver los detalles completos del trabajo rutinario, incluidas las tareas y sus estados.",
  routine_work_step_six:
    "Haz clic aquí para agregar nuevo trabajo rutinario. Puedes asignar tareas a empleados y establecer plazos.",

  task_card_step_one:
    "Este es el encabezado de la tarjeta de tareas. Aquí, puedes ver la categoría de la tarea",
  task_card_step_two:
    "Haz clic en este ícono para actualizar los datos mostrados en esta tarjeta.",
  task_card_step_three:
    "Usa este selector de fecha para filtrar tareas por su fecha de inicio.",
  task_card_step_four:
    "Usa este desplegable para filtrar tareas por categoría (por ejemplo, Prioridad, Completado).",
  task_card_step_five:
    "Aquí puedes ver los detalles de Tareas Abiertas y Cerradas",
  task_card_step_six:
    "Este gráfico de barras muestra una representación visual de los estados y prioridades de las tareas. Pasa el ratón sobre las barras para ver información detallada.",

  ms_response_time:
    "Esta sección muestra el tiempo de respuesta de la gestión de tarjetas de massalud.zoyel.health.",
  ms_response_time_one:
    "Haga clic aquí para ver la página de estado detallada del sitio web de Massalud.",
  ms_response_time_two:
    "Seleccione un rango de tiempo para ver el rendimiento del sitio web durante ese período.",
  ms_response_time_three:
    "Esta tarjeta muestra el tiempo de respuesta promedio del sitio web para el rango de tiempo seleccionado.",
  ms_response_time_four:
    "Esta tarjeta muestra el tiempo de respuesta promedio del sitio web para el rango de tiempo seleccionado.",
  ms_response_time_five:
    "Este gráfico muestra los tiempos de respuesta a lo largo del tiempo para los nodos seleccionados.",

  ms_uptime_downtime:
    "Esta sección muestra la gestión del tiempo de actividad y el tiempo de inactividad de massalud.zoyel.health.",
  ms_uptime_downtime_one:
    "Esta es la vista general del estado del sitio web massalud.zoyel.health.",
  ms_uptime_downtime_two:
    "Seleccione el rango de fechas deseado para los datos del sitio web.",
  ms_uptime_downtime_three:
    "Esta sección muestra el estado general y los tiempos de respuesta del sitio web.",
  ms_uptime_downtime_four:
    "Vea la representación gráfica de los datos de tiempo de actividad e inactividad.",

  task_tab_step_one: "Esta sección muestra las tareas del usuario.",
  task_tab_step_two:
    "Aquí, puede buscar tareas ingresando el nombre de la tarea.",
  task_tab_step_three: "Aquí puede ver de quién es la tarea que se muestra.",
  task_tab_step_four: "Desde aquí, puede crear una nueva tarea.",
  task_tab_step_five:
    "Este es el contenedor de la lista de tareas donde puede gestionarlas.",
  task_tab_step_seven: "Aquí, puede ver los nombres de las tareas.",
  task_tab_step_eight: "Aquí, puede ver las descripciones de las tareas.",
  task_tab_step_nine: "Aquí, puede ver los tipos de tareas.",
  task_tab_step_ten: "Aquí, puede ver los contratos.",
  task_tab_step_ten_alt: "Aquí, puede ver el progreso de la tarea.",
  task_tab_step_eleven:
    "Cada tarea tiene un estado de prioridad: Rutina, Importante o Crítico.",
  task_tab_step_twelve:
    "Aquí, puede ver la fecha y hora de finalización de la tarea.",
  task_tab_step_thirteen: "Esta columna muestra quién creó o asignó la tarea.",

  create_task_step_one:
    "Aquí es donde puede crear una nueva tarea o solicitud de aprobación.",
  create_task_step_two:
    "Seleccione el tipo de contrato de la lista desplegable.",
  create_task_step_three: "Elija o escriba la actividad para la tarea.",
  create_task_step_four:
    "Seleccione si esto es una Tarea o una Solicitud de Aprobación.",
  create_task_step_five: "Elija una fecha de finalización para la tarea.",
  create_task_step_six: "Seleccione la prioridad de la tarea.",
  create_task_step_seven: "Asigne usuarios que trabajarán en esta tarea.",
  create_task_step_eight: "Usuarios observadores que vigilan esta tarea.",
  create_task_step_nine: "Ingrese el nombre de la tarea para la tarea.",
  create_task_step_ten: "Ingrese la descripción de la tarea para la tarea.",
  create_task_step_eleven: "Adjunte un archivo para la tarea.",
  create_task_step_thirteen:
    "Haga clic aquí para enviar la tarea después de completar todos los detalles.",

  meeting_step_one:
    "Ingrese el número de sala y haga clic en el botón Unirse para iniciar la videoconferencia",
  meeting_step_two:
    "Haga clic en este botón para iniciar la videoconferencia al instante",

  view_chat_step_one:
    "Aquí está tu foto de perfil. Haz clic en ella para ver o actualizar la configuración de tu perfil.",
  view_chat_step_two:
    "Usa esta barra de búsqueda para encontrar contactos o chats específicos rápidamente.",
  view_chat_step_three:
    "Haz clic aquí para filtrar chats por mensajes no leídos.",
  view_chat_step_four: "Usa este botón para crear un nuevo chat grupal.",
  view_chat_step_five:
    "Esta sección muestra todos tus chats recientes. Haz clic en un contacto para ver la conversación.",

  add_budget_step_one: "Aquí es donde puedes añadir un nuevo presupuesto.",
  add_budget_step_two: "Elige si el presupuesto es consolidado o por unidad.",
  add_budget_step_three:
    "Selecciona una unidad para tu presupuesto (si corresponde).",
  add_budget_step_four: "Establece la fecha de inicio para tu presupuesto.",
  add_budget_step_five:
    "Establece las fechas de finalización para tu presupuesto.",
  add_budget_step_six: "Establece el Gasto de Capital para tu presupuesto.",
  add_budget_step_seven: "Establece el Gasto Operativo para tu presupuesto.",
  add_budget_step_eight:
    "Haz clic aquí para finalizar y agregar el presupuesto.",

  add_contracts_step_one:
    "Este es el formulario principal donde puedes completar los detalles del contrato.",
  add_contracts_step_two: "Complete el nombre del contrato aquí.",
  add_contracts_step_three: "Proporcione una descripción del contrato aquí.",
  add_contracts_step_four: "Proporcione el resumen del contrato aquí.",
  add_contracts_step_five: "Proporcione el número del contrato aquí.",
  add_contracts_step_six: "Seleccione la fecha del contrato aquí.",
  add_contracts_step_seven: "Ingrese el valor del contrato.",
  add_contracts_step_eight: "Elija la moneda para el valor del contrato.",
  add_contracts_step_nine:
    "Ingrese el nombre y número de teléfono de la persona de contacto para el valor del contrato.",
  add_contracts_step_ten: "Seleccione la persona responsable del contrato.",
  add_contracts_step_eleven:
    "Haga clic aquí para adjuntar un archivo al contrato.",
  add_contracts_step_twelve:
    "Haga clic aquí para finalizar los términos y condiciones.",
  add_contracts_step_thirteen:
    "Envía todos los datos para generar un nuevo contrato.",

  add_external_user_step_one:
    "Aquí puedes agregar los detalles de un nuevo usuario externo.",
  add_external_user_step_two: "Puedes seleccionar el tipo de usuario aquí.",
  add_external_user_step_three: "Puedes ingresar el nombre del usuario aquí.",
  add_external_user_step_four:
    "Puedes ingresar el correo electrónico oficial del usuario aquí.",
  add_external_user_step_five:
    "Puedes ingresar el número de teléfono del usuario aquí.",
  add_external_user_step_six:
    "Puedes ingresar la dirección completa del usuario aquí.",
  add_external_user_step_seven: "Puedes seleccionar el país del usuario aquí.",
  add_external_user_step_eight: "Puedes ingresar el número de fax aquí.",
  add_external_user_step_nine: "Puedes ingresar el número de GST aquí.",
  add_external_user_step_ten:
    "Envía todos los datos que has ingresado para el nuevo usuario externo.",

  add_holiday_step_one:
    "Esta es la página de agregar feriado donde puedes crear un nuevo feriado.",
  add_holiday_step_two: "Ingresa el nombre del feriado aquí.",
  add_holiday_step_three:
    "Selecciona la fecha del feriado utilizando este selector de fechas. No puedes seleccionar fechas pasadas.",
  add_holiday_step_four:
    "Proporciona una descripción para el feriado. Esta descripción se incluirá en las comunicaciones por correo electrónico.",
  add_holiday_step_five:
    "Adjunta una imagen para el correo del feriado aquí. Haz clic en el botón para seleccionar un archivo.",
  add_holiday_step_six:
    "Haz clic aquí para previsualizar los detalles del feriado y la imagen del correo antes de enviarlo.",

  add_item_step_one: "Aquí, puedes agregar un nuevo artículo.",
  add_item_step_two: "Selecciona el tipo de artículo que deseas agregar.",
  add_item_step_three: "Proporciona un nombre para el artículo.",
  add_item_step_four: "Proporciona una breve descripción del artículo.",
  add_item_step_five:
    "Elige una categoría para tu artículo o agrega una nueva.",
  add_item_step_six:
    "Elige una UOM predeterminada para tu artículo o agrega una nueva.",
  add_item_step_seven:
    "Elige un fabricante para tu artículo o agrega uno nuevo.",
  add_item_step_eight: "Proporciona el código HSN del artículo.",
  add_item_step_nine:
    "Marca la casilla si deseas que el artículo sea caducable.",
  add_item_step_ten:
    "Marca la casilla si deseas que el artículo esté en garantía/servicio/SLA.",
  add_item_step_eleven:
    "Marca el botón de radio si deseas que el artículo sea tangible o intangible.",
  add_item_step_twelve:
    "Una vez que hayas terminado, haz clic aquí para enviar el formulario.",

  add_leave_rule_step_one: "Puedes agregar el tipo de permiso aquí.",
  add_leave_rule_step_two:
    "Comienza seleccionando o ingresando un tipo de permiso aquí.",
  add_leave_rule_step_three: "Elige los géneros aplicables para el permiso.",
  add_leave_rule_step_four:
    "Selecciona los estados de los empleados elegibles para este permiso.",
  add_leave_rule_step_five:
    "Especifica los tipos de salario permitidos para este permiso.",
  add_leave_rule_step_six: "Ingresa el documento requerido para el permiso.",
  add_leave_rule_step_seven: "Llena el límite de número de permisos.",
  add_leave_rule_step_eight:
    "Llena el número de permisos acumulados mensualmente.",
  add_leave_rule_step_nine:
    "Llena el número de permisos acumulados anualmente.",
  add_leave_rule_step_ten: "Llena el número de límites mínimos de permisos.",
  add_leave_rule_step_eleven: "Llena el número de límites máximos de permisos.",
  add_leave_rule_step_twelve:
    "Marca esta casilla si este tipo de permiso permite aplicaciones de medio día.",
  add_leave_rule_step_thirteen:
    "Elige otros tipos de permisos que se puedan combinar con este.",
  add_leave_rule_step_fourteen:
    "Especifica el número mínimo de días que un empleado debe trabajar antes de solicitar este tipo de permiso.",
  add_leave_rule_step_fifteen:
    "Ingresa el número mínimo de días previos requeridos para solicitar este permiso.",
  add_leave_rule_step_sixteen:
    "Establece la línea de tiempo de expiración para los saldos de permisos para este tipo de permiso.",
  add_leave_rule_step_seventeen:
    "Establece el evento de permiso anterior para los saldos de permisos para este tipo de permiso.",
  add_leave_rule_step_eighteen:
    "Establece el evento de permiso posterior para los saldos de permisos para este tipo de permiso.",
  add_leave_rule_step_nineteen:
    "Especifica si el permiso puede ser transferido al siguiente año calendario.",
  add_leave_rule_step_twenty:
    "Ingresa el número máximo de días de permiso que se pueden transferir.",
  add_leave_rule_step_twenty_one:
    "Elige si este tipo de permiso es canjeable o no. Al seleccionar 'Sí', se revelarán más opciones.",
  add_leave_rule_step_twenty_two:
    "Establece el número máximo de días que un empleado puede canjear durante su liquidación final.",
  add_leave_rule_step_twenty_three:
    "Define el número máximo de días que un empleado puede canjear mientras esté empleado.",
  add_leave_rule_step_twenty_four:
    "Especifica el número mínimo de días de permiso que un empleado debe tener después de canjearlo.",
  add_leave_rule_step_twenty_five:
    "Envía la configuración de tu regla de permisos",

  add_employ_step_one: "Aquí es donde agregas un empleado al sistema.",
  add_employ_step_two: "Introduce el nombre del empleado.",
  add_employ_step_three: "Selecciona el género del empleado.",
  add_employ_step_four:
    "Proporciona la dirección de correo electrónico oficial del empleado.",
  add_employ_step_five:
    "Proporciona la dirección de correo electrónico oficial del empleado.",
  add_employ_step_six: "Selecciona la fecha de incorporación del empleado.",
  add_employ_step_seven: "Especifica el tipo de salario para el empleado.",
  add_employ_step_eight: "Introduce un ID único de empleado aquí.",
  add_employ_step_nine: "Selecciona el estado de empleo aquí.",
  add_employ_step_ten: "Selecciona la unidad de empleo aquí.",
  add_employ_step_eleven: "Selecciona la posición primaria aquí.",
  add_employ_step_twelve:
    "Selecciona la fecha de efectividad de la posición primaria.",
  add_employ_step_thirteen:
    "Haz clic aquí para agregar una posición adicional.",
  add_employ_step_fourteen: "Haz clic aquí para agregar un jefe de informes.",
  add_employ_step_fifteen: "Haz clic aquí para agregar un turno.",
  add_employ_step_sixteen: "Selecciona atención médica si es necesario.",
  add_employ_step_seventeen:
    "Haz clic en el botón de envío para agregar los detalles del empleado.",

  add_routine_step_one: "Aquí es donde puedes agregar trabajo rutinario.",
  add_routine_step_two:
    "Elige la actividad del menú desplegable para comenzar a configurar el trabajo rutinario. Y llena los detalles necesarios.",
  add_routine_step_three:
    "Haz clic aquí para previsualizar tus entradas antes de enviarlas.",
  add_routine_step_four:
    "Si deseas cancelar, haz clic aquí para cerrar este formulario sin guardar cambios.",

  view_budget_step_one: "Puedes ver todo el presupuesto aquí.",
  view_budget_step_two: "Haz clic aquí para volver a la pantalla anterior.",
  view_budget_step_three:
    "Usa esta barra de búsqueda para filtrar presupuestos por nombre.",
  view_budget_step_four: "Haz clic aquí para crear un nuevo presupuesto.",
  view_budget_step_five:
    "Aquí tienes una lista de tus presupuestos, incluidos detalles como nombre, unidad, fechas de inicio y fin, y montos.",
  view_budget_step_six:
    "Haz clic en este botón para ver información detallada de un presupuesto específico.",

  view_contracts_step_one: "Aquí puedes ver toda la lista de contratos.",
  view_contracts_step_two: "Haz clic aquí para volver a la página anterior.",
  view_contracts_step_three:
    "Usa la barra de búsqueda para filtrar contratos por nombre.",
  view_contracts_step_four: "Haz clic aquí para agregar un nuevo contrato.",
  view_contracts_step_five:
    "Aquí puedes ver todos tus contratos. La tabla muestra los detalles clave del contrato.",
  view_contracts_step_six:
    "Puedes realizar acciones en cada contrato: descargar, editar o eliminar.",
  on_clicking_admin_button_you_can_view_more_features:
    "Al hacer clic en este botón podrá ver más funciones.",

  view_external_user_step_one: "Aquí puedes ver todos los usuarios externos",
  view_external_user_step_two:
    "Haz clic aquí para volver a la página anterior.",
  view_external_user_step_three:
    "Usa esta barra de búsqueda para encontrar usuarios externos por nombre de usuario.",
  view_external_user_step_four:
    "Haz clic aquí para agregar un nuevo usuario externo.",
  view_external_user_step_five:
    "Aquí puedes ver la lista de todos los usuarios externos",
  view_external_user_step_six:
    "Haz clic en este ícono para copiar la dirección de correo electrónico.",
  view_external_user_step_seven:
    "Haz clic en este ícono para copiar el número de teléfono.",
  view_external_user_step_eight:
    "Usa estos íconos para editar o eliminar un usuario externo.",

  view_holidays_step_one:
    "Aquí puedes ver todos los días festivos de la organización",
  view_holidays_step_two: "Haz clic aquí para volver a la página anterior.",
  view_holidays_step_three:
    "Usa la barra de búsqueda para encontrar rápidamente días festivos específicos.",
  view_holidays_step_four: "Crea nuevos turnos utilizando estos botones.",
  view_holidays_step_five: "Crea días laborales utilizando estos botones.",
  view_holidays_step_six: "Crea nuevos días festivos utilizando estos botones.",
  view_holidays_step_seven: "Puedes ver todos los días festivos en esta tabla.",
  view_holidays_step_eight:
    "Puedes ver, editar o eliminar días festivos desde esta tabla.",

  view_item_step_one: "Esta es la página de la lista de artículos.",
  view_item_step_two: "Puedes usar este botón para volver.",
  view_item_step_three:
    "Usa esta barra de búsqueda para filtrar tus artículos por nombre.",
  view_item_step_four:
    "Haz clic aquí para agregar un nuevo artículo a la lista.",
  view_item_step_five:
    "Aquí puedes ver todos los artículos de la lista, organizados por nombre, descripción, categoría, etc.",
  view_item_step_six:
    "Haz clic en este botón para verificar el artículo si cumple con los criterios necesarios.",
  view_item_step_seven:
    "Usa estos botones para editar o desactivar un artículo según sea necesario.",
  view_item_step_eight:
    "Haz clic aquí para reemplazar un artículo por otro de la lista.",

  view_leave_rule_step_one: "Aquí puedes ver todas las reglas de licencia.",
  view_leave_rule_step_two: "Haz clic aquí para volver a la pantalla anterior.",
  view_leave_rule_step_three:
    "Usa esta barra de búsqueda para filtrar las reglas de licencia por su nombre.",
  view_leave_rule_step_four:
    "Haz clic aquí para ver el saldo de licencias de los empleados.",
  view_leave_rule_step_five:
    "Haz clic aquí para agregar una nueva regla de licencia.",
  view_leave_rule_step_six:
    "Esta tabla enumera todas las reglas de licencia con sus detalles.",
  view_leave_rule_step_seven:
    "Usa estos botones de acción para ver, editar o eliminar las reglas de licencia.",

  view_employ_step_one:
    "Aquí puedes ver todos los empleados de la organización",
  view_employ_step_two: "Haz clic aquí para volver a la pantalla anterior.",
  view_employ_step_three:
    "Usa esta barra de búsqueda para filtrar empleados por nombre o ID.",
  view_employ_step_four:
    "Haz clic en este botón para agregar un nuevo empleado.",
  view_employ_step_five:
    "Esta tabla muestra los detalles de los empleados, como Nombre, ID y Estado.",
  view_employ_step_six:
    "Usa estos íconos para editar o eliminar los detalles de los empleados.",

  view_routine_step_one: "Aquí puedes ver todo el trabajo rutinario aquí",
  view_routine_step_two: "Haz clic aquí para volver a la página anterior.",
  view_routine_step_three: "Busca actividades específicas por nombre.",
  view_routine_step_four:
    "Selecciona la fecha de inicio para filtrar el trabajo rutinario.",
  view_routine_step_five: "Elige la fecha de finalización para tu filtro.",
  view_routine_step_six:
    "Haz clic aquí para agregar un nuevo trabajo rutinario.",
  view_routine_step_seven: "Aquí está la lista de trabajos rutinarios.",
  view_routine_step_eight:
    "Haz clic en el botón de descarga para descargar los archivos relacionados.",

  create_term_step_one: "Actualizó los Términos y Condiciones para.",
  create_term_step_two: "Actualizó el tipo de Términos y Condiciones.",
  create_term_step_three_two:
    "Ingrese el encabezado de los Términos y Condiciones.",
  create_term_step_three:
    "Ingrese la descripción de los Términos y Condiciones.",
  create_term_step_four:
    "Haga clic en Guardar para actualizar los Términos y Condiciones.",
  create_term_step_five:
    "Haga clic aquí si desea descartar cualquier cambio y cerrar el formulario.",
  this_brand_is_already_present_do_you_want_to_continue:
    "Esta marca ya está presente. ¿Desea continuar?",
  medicine_with_this_generic_name_already_present_do_you_want_to_continue:
    "Un medicamento con este nombre genérico ya está presente. ¿Desea continuar?",
  another_brand_with_same_generic_name_is_already_added_do_you_want_to_continue:
    "Otra marca con el mismo nombre genérico ya está agregada. ¿Desea continuar?",
  please_provide_a_rating_for_your_overall_experience_with_the_support_dashboard:
    "Por favor, proporcione una calificación para su experiencia general",
  please_select_below_issues_you_faces_during_using_support_dashboard:
    "Por favor, seleccione los problemas que ha enfrentado",
  are_you_satisfied: "¿Está satisfecho?",
  give_feedback_and_close_ticket: "Dar comentarios y cerrar el ticket",
  submit_feedback_and_close_ticket: "Enviar comentarios y cerrar el ticket",
  feedback_submitted_and_ticket_closed_successfully:
    "Comentarios enviados y ticket cerrado con éxito",
  ticket_closed: "Ticket cerrado con calificación",
  please_state_the_reason_for_reopening_the_ticket:
    "Por favor, indique el motivo para reabrir el ticket.",
  reopen_ticket: "Reabrir ticket",
  i_have_given_rating_of: "He dado una calificación de",

  ////////// visual selection///////

  abnormal_behaviour: "Comportamiento Anormal",
  abnormal_body_movement_fits:
    "Movimientos Corporales Anormales / Convulsiones",
  abnormal_body_sensations: "Sensaciones corporales anormales",
  abnormal_body_temperature_chills_rigors:
    "Temperatura corporal anormal / escalofríos / rigidez",
  abnormal_feelings_thoughts: "Sentimientos/pensamientos anormales",
  abnormal_posture: "Postura anormal",
  abnormal_sensation: "Sensación Anormal",
  abnormal_sensorium: "Sensorio anormal",
  abnormal_walking_gait: "Marcha / Caminar Anormal",
  acidity_heartburn: "Acidez / Ardor de Estómago",
  anorectal_problems: "Problemas Anorrectales",
  blood_pressure_problem: "Problemas de Presión Arterial",
  bowel_problems: "Problemas Intestinales",
  breast_problems: "Problemas de Mama",
  breathing_problems: "Problemas Respiratorios",
  burn: "Quemadura",
  cognitive_problem_loss_of_memory: "Problemas Cognitivos / Pérdida de Memoria",
  cough: "Tos",
  deformities: "Deformidades",
  delayed_developmental_milestones: "Retrasos en Hitos del Desarrollo",
  diagnosis_history: "Diagnóstico / Historia Clínica",
  ear_problems: "Problemas de Oídos",
  eating_drinking_problems: "Problemas para Comer / Beber",
  eye_problems: "Problemas oculares",
  gangrene: "Gangrena",
  genito_urinary_problems: "Problemas Genitourinarios",
  hair_scalp_nail_problems: "Problemas de Cabello / Cuero Cabelludo / Uñas",
  hiccups: "Hipo",
  injury_trauma_fracture_dislocation:
    "Lesión / Trauma / Fractura / Dislocación",
  mouth_dental_problems: "Problemas Bucales / Dentales",
  musculoskeletal_problems: "Problemas Musculoesqueléticos",
  nausea_vomiting: "Náuseas / Vómitos",
  neck_throat_problems: "Problemas de Cuello / Garganta",
  nose_problems: "Problemas Nasales",
  other_non_specific_bleeding_discharge: "Sangrado / Secreción No Específica",
  pain_tenderness: "Dolor / Sensibilidad",
  palpitation_heart_problems: "Palpitaciones / Problemas Cardíacos",
  paralysis_paresis: "Parálisis / Paresia",
  physical_examination: "Examen Físico",
  pregnancy_fertility_problems: "Problemas de Embarazo / Fertilidad",
  pulse_problems: "Problemas de Pulso",
  rash_and_skin_problems: "Erupciones y Problemas en la Piel",
  sexual_problem: "Problemas Sexuales",
  sleep_problems: "Problemas de Sueño",
  sneezing: "Estornudos",
  speech_problem: "Problemas del Habla",
  sweating: "Sudoración",
  swelling_lump: "Hinchazón / Bulto",
  weakness_lethargy_fatigue: "Debilidad / Letargo / Fatiga",
  weight_problems: "Problemas de Peso",

  procedure_name: "Nombre del procedimiento",
  support_ticket_forwarded_successfully:
    "Ticket de soporte reenviado correctamente",
  support_ticket_could_not_be_forwarded:
    "No se pudo reenviar el ticket de soporte",
  sign_medical: "Signo",
  ticket_reopened_successfully: "Ticket reabierto exitosamente",
  times_reopened: "Times reopened",
  ticket_reopened_with_comments: "Ticket reabierto con comentarios",
  ticket_reopened: "Boleto reabierto",
  task_already_completed: "La tarea ya se completó",
  reply_if_not_completed: "Responda si no se ha completado",
  please_give_comment_if_not_completed:
    "Por favor, haga un comentario si no se ha completado",

  // third symtoms

  abnormal_crying: "Llanto anormal",
  aggressive_behavior: "Comportamiento agresivo",
  agitation: "Agitación",
  changing_friends: "Cambio de amistades",
  compulsive_actions: "Acciones compulsivas",
  cross_dressing_practice_of_wearing_clothing_typically_associated_with_the_opposite_gender:
    "Vestirse con ropa del género opuesto (travestismo)",
  crying: "Llanto",
  decline_in_daily_activities: "Declive en actividades diarias",
  difficulty_interacting_with_peers:
    "Dificultad para interactuar con compañeros",
  excessive_talking: "Habla excesiva",
  facial_grimacing: "Muecas faciales",
  grasping_or_picking_at_imaginary_objects:
    "Agarre o recogida de objetos imaginarios",
  hyperactivity: "Hiperactividad",
  impatient: "Impaciencia",
  inability_to_sit_still_or_restlessness_or_fidgeting:
    "Incapacidad para permanecer sentado o inquietud",
  interrupting_others: "Interrumpir a otros",
  irresistible_urge_to_move_legs: "Impulso irresistible de mover las piernas",
  irritability: "Irritabilidad",
  jaw_clenching: "Apretamiento de mandíbula",
  lack_of_coordination: "Falta de coordinación",
  lip_puckering: "Fruncir los labios",
  mania: "Manía",
  nail_biting: "Morderse las uñas",
  odd_behavior: "Comportamiento extraño",
  personality_or_behavior_change:
    "Cambio en la personalidad o el comportamiento",
  polysubstance_dependence: "Dependencia de múltiples sustancias",
  recollection_of_past_events_flashback:
    "Recuerdo de eventos pasados (flashback)",
  repetitive_behavior: "Comportamiento repetitivo",
  ritualistic_actions: "Acciones ritualistas",
  rocking_behavior: "Comportamiento de balanceo",
  selfharm: "Autolesiones",
  selfneglect: "Descuido personal",
  stereotyped_actions: "Acciones estereotipadas",
  suspicious_behavior: "Comportamiento sospechoso",
  tantrum: "Rabieta",
  teeth_grinding: "Rechinamiento de dientes",
  thumb_sucking: "Chupar el dedo",
  unable_to_engage_quietly_in_leisure_activities:
    "Incapacidad para realizar actividades de ocio en silencio",
  weak_cry: "Llanto débil",
  abnormal_hand_movement: "Movimiento anormal de las manos",
  abnormal_jaw_movements: "Movimientos anormales de la mandíbula",
  ankle_muscle_spasms: "Espasmos musculares en el tobillo",
  automatic_movements_automatisms: "Movimientos automáticos (automatismos)",
  difficulty_passing_stools: "Dificultad para evacuar heces",
  drop_attacks_sudden_falls_with_or_without_loss_of_consciousness:
    "Ataques de caída (caídas repentinas con o sin pérdida de conciencia)",
  dysdiadochokinesis_the_inability_to_perform_rapid_alternating_muscle_movements:
    "Disdiadoquinesia (incapacidad para realizar movimientos musculares alternantes rápidos)",
  emotional_distress: "Angustia emocional",
  febrile_seizures: "Convulsiones febriles",
  focal_seizures: "Convulsiones focales",
  general_tremor: "Temblor general",
  hand_flapping_tremor: "Temblor de aleteo de manos",
  head_nodding: "Movimiento de cabeza de asentimiento",
  head_tremors: "Temblor de cabeza",
  intention_tremors: "Temblor de intención",
  involuntary_movement_coordination_synkinesis:
    "Coordinación de movimientos involuntarios (sinquinesia)",
  involuntary_writhing_movements_athetosis:
    "Movimientos involuntarios de contorsión (atetosis)",
  lipsmacking: "Chasquido de labios",
  poor_muscle_coordination: "Mala coordinación muscular",
  rapid_jerky_movements_chorea: "Movimientos rápidos y sacudidos (corea)",
  seizure: "Convulsión",
  stereotyped_movements: "Movimientos estereotipados",
  tics_or_fasciculations: "Tics o fasciculaciones",
  tics_or_muscle_twitches: "Tics o espasmos musculares",
  tonicclonic_seizures: "Convulsiones tónico-clónicas",
  tremor_postural: "Temblor postural",
  tremor_at_rest: "Temblor en reposo",
  tremor_of_the_jaw: "Temblor de la mandíbula",
  unilateral_spasm_hemiballismus: "Espasmo unilateral (hemibalismo)",
  unusual_movements: "Movimientos inusuales",
  abnormal_sensation: "Sensación anormal",
  abnormal_sensation_in_the_anal_perianal_area:
    "Sensación anormal en el área anal/perianal",
  abnormal_sensation_in_the_face: "Sensación anormal en la cara",
  abnormal_sensation_in_the_limbs: "Sensación anormal en las extremidades",
  bilateral_loss_of_sensation: "Pérdida bilateral de la sensación",
  burning_sensation: "Sensación de ardor",
  burning_sensation_in_arms: "Sensación de ardor en los brazos",
  burning_sensation_in_fingers: "Sensación de ardor en los dedos",
  burning_sensation_in_hands: "Sensación de ardor en las manos",
  burning_sensation_in_legs_and_feet:
    "Sensación de ardor en las piernas y pies",
  burning_sensation_in_toes: "Sensación de ardor en los dedos de los pies",
  gangrene: "Gangrena",
  generalized_itching: "Picazón generalizada",
  goosebumps: "Piel de gallina",
  hyperaesthesia: "Hiperestesia",
  intermittent_numbness: "Entumecimiento intermitente",
  itching: "Picazón",
  itching_in_the_throat: "Picazón en la garganta",
  itching_of_the_palate: "Picazón en el paladar",
  loss_of_sensation: "Pérdida de la sensación",
  loss_of_vibration_sense: "Pérdida de la sensación vibratoria",
  numb_feeling: "Sensación de entumecimiento",
  numbness_lower_limb: "Entumecimiento en la extremidad inferior",
  numbness_upper_limb: "Entumecimiento en la extremidad superior",
  numbness_in_cheeks: "Entumecimiento en las mejillas",
  numbness_in_legs_and_feet: "Entumecimiento en las piernas y pies",
  numbness_in_the_arms: "Entumecimiento en los brazos",
  numbness_in_the_face: "Entumecimiento en la cara",
  numbness_in_the_mouth: "Entumecimiento en la boca",
  onesided_numbness: "Entumecimiento unilateral",
  perianal_numbness: "Entumecimiento perianal",
  perianal_or_anal_itching: "Picazón perianal o anal",
  perineal_numbness: "Entumecimiento perineal",
  protrusion_from_the_anus: "Protrusión del ano",
  scalp_itching: "Picazón en el cuero cabelludo",
  shoulder_numbness: "Entumecimiento en el hombro",
  tingling_sensation: "Sensación de hormigueo",
  tingling_sensation_in_arms: "Sensación de hormigueo en los brazos",
  tingling_sensation_in_cheeks: "Sensación de hormigueo en las mejillas",
  tingling_sensation_in_extremities:
    "Sensación de hormigueo en las extremidades",
  tingling_sensation_in_hands: "Sensación de hormigueo en las manos",
  tingling_sensation_in_legs_and_feet:
    "Sensación de hormigueo en las piernas y pies",
  tingling_sensation_in_the_mouth: "Sensación de hormigueo en la boca",
  tingling_sensation_in_toes: "Sensación de hormigueo en los dedos de los pies",
  unilateral_loss_of_sensation: "Pérdida unilateral de la sensación",
  chills_or_rigors: "Escalofríos o rigores",
  cold_hands_or_feet: "Manos o pies fríos",
  cold_sensation_in_fingers: "Sensación de frío en los dedos",
  cold_sensation_in_hands: "Sensación de frío en las manos",
  cold_sensations: "Sensaciones de frío",
  fever: "Fiebre",
  frostbite: "Congelación",
  hot_sensations: "Sensaciones de calor",
  anxiety: "Ansiedad",
  boredom: "Aburrimiento",
  delusions: "Delirios",
  depression: "Depresión",
  emotional_dysfunction: "Disfunción emocional",
  excessive_vanity_and_selfcenteredness: "Vanidad excesiva y egocentrismo",
  fear_of_eating: "Miedo a comer",
  fear_of_flying: "Miedo a volar",
  fear_of_water: "Miedo al agua",
  feeling_of_disgust: "Sensación de asco",
  feeling_of_guilt: "Sensación de culpa",
  feelings_of_suspicion_or_mistrust: "Sensaciones de desconfianza o sospecha",
  feelings_of_unreality: "Sensaciones de irrealidad",
  freezing_due_to_fear: "Parálisis por miedo",
  grandiose_delusions: "Delirios de grandeza",
  hearing_hallucinations: "Alucinaciones auditivas",
  illusions: "Ilusiones",
  irrational_thoughts_or_odd_thinking: "Pensamientos irracionales o extraños",
  lack_of_drive_or_motivation: "Falta de motivación",
  lack_of_interest: "Falta de interés",
  lack_of_pleasure: "Falta de placer",
  mood_swings: "Cambios de humor",
  obsession: "Obsesión",
  panic: "Pánico",
  phobias: "Fobias",
  seasonal_depression: "Depresión estacional",
  social_isolation: "Aislamiento social",
  state_of_increased_alertness: "Estado de alerta aumentada",
  suicidal_thoughts: "Pensamientos suicidas",
  undue_concern_about_body_shape_or_weight:
    "Preocupación excesiva por la forma o el peso corporal",
  visual_hallucinations: "Alucinaciones visuales",
  abnormal_body_posture: "Postura corporal anormal",
  arching_of_the_neck: "Arqueo del cuello",
  asymmetrical_posture: "Postura asimétrica",
  bent_posture_while_walking: "Postura inclinada al caminar",
  head_dropping: "Cabeza caída",
  head_tilting: "Inclinación de la cabeza",
  involuntary_neck_deviation: "Desviación involuntaria del cuello",
  stooped_posture: "Postura encorvada",
  wrist_drop: "Caída de la muñeca",
  numbness_in_fingers: "Entumecimiento en los dedos",
  sensation_of_a_lump_in_the_throat: "Sensación de un bulto en la garganta",
  swelling_of_the_thyroid_gland: "Hinchazón de la glándula tiroides",
  tingling_sensation_in_fingers: "Sensación de hormigueo en los dedos",
  coma: "Coma",
  decreased_consciousness: "Disminución de la conciencia",
  delirium: "Delirio",
  dizziness: "Mareos",
  dizziness_upon_changing_posture: "Mareos al cambiar de postura",
  fainting: "Desmayo",
  lightheadedness: "Sensación de aturdimiento",
  reduced_alertness: "Alerta reducida",
  state_of_mental_confusion: "Estado de confusión mental",
  stupor_nearunconsciousness: "Estupor (casi inconsciencia)",
  abnormal_involuntary_eye_movements:
    "Movimientos oculares involuntarios anormales",
  antalgic_gait: "Marcha antálgica",
  balance_loss: "Pérdida del equilibrio",
  bentover_walking: "Caminar encorvado",
  dragging_of_the_foot: "Arrastrar el pie",
  heeltotoe_walking: "Caminar talón a punta",
  highstepping_walk: "Marcha de pasos altos",
  irregular_walking_pattern: "Patrón de caminar irregular",
  limp: "Cojera",
  shuffling_gait: "Marcha arrastrada",
  slapping_of_the_foot: "Golpe del pie al caminar",
  stifflegged_walk: "Caminar con piernas rígidas",
  tiptoe_walking: "Caminar en puntas de pie",
  unsteady_walk: "Caminar inestable",
  waddling_walk: "Marcha de pato",
  widebased_gait: "Marcha de base amplia",
  acid_reflux: "Reflujo ácido",
  belching_eructation: "Eructos",
  burning_sensation_in_the_chest: "Sensación de ardor en el pecho",
  excessive_burping_or_belching: "Eructos excesivos",
  heartburn_after_meals: "Acidez estomacal después de las comidas",
  stomach_upset_indigestion: "Malestar estomacal/indigestión",
  abnormal_rectal_bleeding: "Sangrado rectal anormal",
  anal_or_perianal_or_rectal_pain: "Dolor anal o perianal o rectal",
  enlarged_pharyngeal_lymph_nodes: "Ganglios linfáticos faríngeos agrandados",
  fracture: "Fractura",
  perianal_fistulas: "Fístulas perianales",
  perineal_pain: "Dolor perineal",
  perineal_scarring: "Cicatrización perineal",
  rectal_pressure: "Presión rectal",
  rectal_swelling_that_feels_hard: "Hinchazón rectal que se siente dura",
  rectal_tumor: "Tumor rectal",
  tenderness_in_the_rectal_or_anal_area:
    "Sensibilidad en el área rectal o anal",
  low_blood_pressure_due_to_shock: "Hipotensión por shock",
  postural_low_blood_pressure: "Hipotensión postural",
  abdominal_gurgling_borborygmi: "Ruidos abdominales (borborigmos)",
  abnormal_bowel_sounds: "Ruidos intestinales anormales",
  aversion_to_food: "Aversion a los alimentos",
  black_and_tarry_stools_melena: "Heces negras y alquitranadas (melena)",
  blood_in_stools: "Sangre en las heces",
  change_in_bowel_habits: "Cambio en los hábitos intestinales",
  chronic_diarrhea: "Diarrea crónica",
  constipation: "Estreñimiento",
  constipation_and_diarrhoea_at_alternate_intervals:
    "Estreñimiento y diarrea alternados",
  decreased_bowel_sounds: "Disminución de los ruidos intestinales",
  dependent_edema: "Edema dependiente",
  diarrhea_with_blood: "Diarrea con sangre",
  diarrhea_with_blood_and_mucus: "Diarrea con sangre y moco",
  difficulty_defecating: "Dificultad para defecar",
  enlarged_spleen: "Esplenomegalia (bazo agrandado)",
  feeling_of_incomplete_bowel_emptying:
    "Sensación de evacuación intestinal incompleta",
  foul_smelling_stool: "Heces malolientes",
  frequent_copious_diarrhea: "Diarrea frecuente y copiosa",
  gas: "Gases",
  increased_frequency_of_bowel_sounds:
    "Aumento en la frecuencia de los ruidos intestinales",
  increased_libido: "Aumento de la libido",
  involuntary_stools: "Heces involuntarias",
  irregular_stools: "Heces irregulares",
  loose_stools_diarrhoea: "Heces sueltas / Diarrea",
  loss_of_bowel_control: "Pérdida de control de las heces",
  night_terrors: "Terror nocturno",
  no_passage_of_stools: "Ausencia de paso de heces",
  painful_bowel_movements: "Heces dolorosas",
  presence_of_mucus_in_stool: "Presencia de moco en las heces",
  redness_of_oral_mucosa: "Enrojecimiento de la mucosa oral",
  stool_leakage: "Filtración de heces",
  watery_diarrhea: "Diarrea acuosa",
  worms_in_stool: "Gusanos en las heces",
  blood_in_semen: "Sangre en el semen",
  breast_pain_or_tenderness: "Dolor o sensibilidad en los senos",
  discharge_from_the_breast: "Secreción de los senos",
  galactorrhea: "Galactorrea",
  green_discharge_from_the_breast: "Secreción verde de los senos",
  inverted_nipple: "Pezón invertido",
  localized_increase_in_temperature_breast:
    "Aumento localizado de temperatura - Seno",
  lump_or_swelling_in_the_breast: "Bulto o hinchazón en el seno",
  nipple_secretion: "Secreción del pezón",
  reduced_milk_production: "Disminución de la producción de leche",
  reduction_in_breast_size: "Reducción en el tamaño del seno",
  choking: "Asfixia",
  difficulty_inhaling_shortness_of_breath:
    "Dificultad para inhalar (dificultad para respirar)",
  gasping: "Jadeo",
  shortness_of_breath_dyspnea: "Dificultad para respirar (disnea)",
  shortness_of_breath_dyspnea_episodic:
    "Dificultad para respirar (disnea) - Episódica",
  shortness_of_breath_dyspnea_on_exertion:
    "Dificultad para respirar (disnea) - al esfuerzo",
  shortness_of_breath_at_night: "Dificultad para respirar por la noche",
  shortness_of_breath_at_rest: "Dificultad para respirar en reposo",
  shortness_of_breath_while_talking: "Dificultad para respirar mientras habla",
  absent_tendon_reflexes: "Reflejos tendinosos ausentes",
  burns: "Quemaduras",
  dry_burn: "Quemadura seca",
  facial_burn: "Quemadura facial",
  careless_errors: "Errores imprudentes",
  cognitive_decline: "Declive cognitivo",
  deficit_in_attention: "Déficit de atención",
  delay_in_cognitive_or_intellectual_development:
    "Retraso en el desarrollo cognitivo o intelectual",
  difficulty_focusing: "Dificultad para concentrarse",
  difficulty_learning: "Dificultad para aprender",
  difficulty_making_decisions: "Dificultad para tomar decisiones",
  difficulty_recognizing_faces: "Dificultad para reconocer caras",
  difficulty_visualizing_and_understanding_space:
    "Dificultad para visualizar y comprender el espacio",
  difficulty_writing: "Dificultad para escribir",
  dysarthria_difficulty_speaking: "Disartria (dificultad para hablar)",
  dysentery: "Disentería",
  dysmetria_inability_to_control_the_distance_speed_and_range_of_motion_necessary_to_perform_smoothly_coordinated_movements:
    "Dismetría (incapacidad para controlar la distancia, velocidad y rango de movimiento necesario para realizar movimientos coordinados suavemente)",
  easily_gets_distracted: "Fácil distracción",
  frequently_losing_things: "Frecuentemente pierde cosas",
  gradual_loss_of_language_fluency: "Pérdida gradual de fluidez en el lenguaje",
  impaired_nonverbal_communication: "Comunicación no verbal deteriorada",
  inability_to_perform_calculations: "Incapacidad para realizar cálculos",
  inability_to_read: "Incapacidad para leer",
  incomplete_tasks_or_assignments: "Tareas o encargos incompletos",
  intellectual_disability_mental_retardation:
    "Discapacidad intelectual - Retraso mental",
  lack_of_attention_to_detail: "Falta de atención al detalle",
  longterm_memory_loss: "Pérdida de memoria a largo plazo",
  loss_of_sense_of_position: "Pérdida del sentido de la posición",
  memory_loss: "Pérdida de memoria",
  neurological_difficulties_difficulty_in_construction_tasks:
    "Dificultades neurológicas - Dificultad en tareas de construcción",
  poor_school_performance: "Rendimiento escolar deficiente",
  poor_work_performance: "Rendimiento laboral deficiente",
  shortterm_memory_loss: "Pérdida de memoria a corto plazo",
  though_disorder_tangentiality_and_derailment:
    "Trastorno del pensamiento - Tangencialidad y desvío",
  cough: "Tos",
  cough_with_blood: "Tos con sangre",
  cough_with_phlegm_or_sputum: "Tos con flema o esputo",
  funnel_chest: "Pecho en embudo",
  whooping_cough: "Tos ferina (pertussis)",
  abnormal_urethral_opening: "Apertura uretral anormal",
  absent_testicle: "Testículo ausente",
  bamboo_spine_spinal_deformity:
    "Columna vertebral en bambú (deformidad espinal)",
  barrel_chest: "Pecho en barril",
  bowlegs: "Rodillas en valgo",
  broad_chest: "Pecho ancho",
  chest_deformity: "Deformidad en el pecho",
  claw_hand: "Mano en garra",
  cleft_lip: "Labio leporino",
  cleft_palate: "Paladar hendido",
  clubfoot: "Pie zambo (clubfoot)",
  concave_chest: "Pecho cóncavo",
  deformity_of_the_spine: "Deformidad de la columna vertebral",
  deviated_nasal_septum: "Tabique nasal desviado",
  enlarged_head: "Cabeza agrandada",
  facial_asymmetry: "Asimetría facial",
  flat_head: "Cabeza plana",
  flatfoot: "Pie plano",
  food_intolerance: "Intolerancia alimentaria",
  foot_drop: "Caída del pie",
  joint_tenderness: "Dolor en las articulaciones",
  knock_knee: "Rodilla en varo (rodillas hacia adentro)",
  lateral_curvature_of_the_spine: "Curvatura lateral de la columna vertebral",
  limb_deformities: "Deformidades de las extremidades",
  lordosis: "Lordosis",
  nail_clubbing: "Clubbing de las uñas",
  pigeon_chest: "Pecho en paloma",
  rapid_blinking: "Parpadeo rápido",
  rounded_face: "Cara redonda",
  short_neck: "Cuello corto",
  small_head: "Cabeza pequeña",
  small_mouth: "Boca pequeña",
  small_testicles: "Testículos pequeños",
  soft_skull_craniotabes: "Craneo blando (craniotabes)",
  soft_testicle: "Testículo blando",
  swelling_of_fingers: "Hinchazón de los dedos",
  webbed_neck: "Cuello con pliegues",
  absent_breast_development_in_girls: "Desarrollo mamario ausente en niñas",
  delayed_crawling_or_walking: "Retraso en gateo o caminar",
  delayed_language_development: "Retraso en el desarrollo del lenguaje",
  delayed_motor_skills_development:
    "Retraso en el desarrollo de habilidades motoras",
  delayed_or_absent_puberty: "Retraso o ausencia de la pubertad",
  developmental_disability: "Discapacidad del desarrollo",
  growth_delay: "Retraso en el crecimiento",
  late_onset_of_puberty: "Inicio tardío de la pubertad",
  late_walking_onset: "Inicio tardío de caminar",
  reduced_uterine_size: "Tamaño uterino reducido",
  small_penis: "Pene pequeño",
  very_early_breast_development_in_girls:
    "Desarrollo mamario muy temprano en niñas",
  heart_attack: "Infarto de miocardio (ataque al corazón)",
  sudden_hearing_loss: "Pérdida repentina de audición",
  bleeding_from_the_ears: "Sangrado por los oídos",
  bloody_discharge_from_the_ear: "Secreción sanguinolenta del oído",
  discharge_from_the_ear: "Secreción del oído",
  ear_fullness_or_pressure: "Sensación de plenitud o presión en el oído",
  ear_pain: "Dolor de oído",
  earwax: "Cerumen (cera en los oídos)",
  hearing_loss_deafness: "Pérdida de audición (sordera)",
  increased_sensitivity_to_ones_own_voice_or_sounds:
    "Aumento de la sensibilidad a la propia voz o sonidos",
  itching_in_the_ear: "Picazón en el oído",
  nasal_polyps: "Pólipos nasales",
  ringing_in_the_ears_tinnitus: "Zumbido en los oídos (tinnitus)",
  sensitivity_to_noise: "Sensibilidad al ruido",
  tenderness_of_the_tragus: "Sensibilidad en el trago (cartílago del oído)",
  tophi_deposit_of_monosodium_urate_crystals:
    "Tophi - Depósito de cristales de urato monosódico",
  abdominal_pain_after_fatty_meal:
    "Dolor abdominal - Después de una comida grasosa",
  anorexia: "Anorexia",
  bad_taste: "Mal sabor en la boca",
  binge_eating: "Comer en exceso (atracón)",
  bitter_taste_in_the_mouth: "Sabor amargo en la boca",
  decreased_appetite: "Pérdida de apetito",
  decreased_thirst: "Disminución de la sed",
  difficulty_chewing: "Dificultad para masticar",
  diminished_taste_sensation: "Sensación disminuida de sabor",
  eating_disorder_binge_eating:
    "Trastorno de la alimentación - Comer en exceso (binge eating)",
  eats_nonfood_items_pica: "Come artículos no alimentarios (pica)",
  enhanced_hunger: "Hambre aumentada",
  explosive_stools: "Heces explosivas",
  feeding_difficulties: "Dificultades para alimentarse",
  feeling_full_after_eating_very_little_food:
    "Sensación de estar lleno después de comer muy poca comida",
  heightened_increased_thirst: "Sed aumentada",
  infrequent_stools: "Heces infrecuentes",
  loss_of_taste_ageusia: "Pérdida de sabor (ageusia)",
  loss_of_taste_for_food: "Pérdida del sabor para los alimentos",
  paraplegia: "Paraplejia",
  swallowing_difficulty: "Dificultad para tragar",
  swallowing_difficulty_for_liquids: "Dificultad para tragar - Líquidos",
  swallowing_difficulty_for_solids: "Dificultad para tragar - Sólidos",
  swallowing_difficulty_painful: "Dificultad para tragar - Dolorosa",
  vesicular_rash: "Erupción vesicular",
  abnormal_eye_movements: "Movimientos oculares anormales",
  abnormal_eye_positioning: "Posicionamiento ocular anormal",
  bleeding_from_the_eye: "Sangrado del ojo",
  bruise_around_the_eye_black_eye: "Moretón alrededor del ojo (ojo morado)",
  burns_on_the_eyelids: "Quemaduras en los párpados",
  cervical_sores: "Úlceras cervicales",
  color_vision_deficiency_color_blindness:
    "Deficiencia en la visión de colores (Daltonismo)",
  conjunctival_swelling: "Hinchazón de la conjuntiva",
  constricted_pupils: "Pupilas contraídas",
  corneal_cloudiness: "Nublamiento de la córnea",
  crusty_eyelid_or_eyelashes: "Párpado o pestañas con costra",
  delay_in_eyelid_movement_lid_lag:
    "Retraso en el movimiento del párpado (lag lag)",
  difficulty_closing_eyelids: "Dificultad para cerrar los párpados",
  difficulty_seeing_at_night_night_blindness:
    "Dificultad para ver de noche (ceguera nocturna)",
  difficulty_walking: "Dificultad para caminar",
  dilated_pupils: "Pupilas dilatadas",
  discharge_from_the_eye: "Secreción ocular",
  double_vision: "Visión doble",
  dryness_of_the_eyes: "Sequedad en los ojos",
  enlarged_ovary: "Ovario agrandado",
  eye_discharge_watery: "Secreción ocular - acuosa",
  eye_pain: "Dolor ocular",
  eye_redness: "Enrojecimiento ocular",
  eye_squint: "Entrecerrar los ojos",
  eyelid_contractions: "Contracciones de los párpados",
  eyelid_swelling_edema: "Hinchazón de los párpados (edema)",
  eyelids_sticking_together_in_the_morning: "Párpados pegajosos por la mañana",
  eyes_appearing_sunken: "Ojos hundidos",
  follicles_on_the_inner_surface_of_the_eyelids:
    "Folículos en la superficie interna de los párpados",
  foreign_body_in_the_conjunctiva: "Cuerpo extraño en la conjuntiva",
  foreign_body_in_the_cornea: "Cuerpo extraño en la córnea",
  fracture_or_trauma_to_the_orbit: "Fractura o trauma en la órbita ocular",
  gradual_central_vision_loss: "Pérdida gradual de visión central",
  gradual_decline_in_vision: "Declive gradual de la visión",
  gradual_peripheral_vision_loss: "Pérdida gradual de visión periférica",
  gray_or_white_arc_that_appears_in_the_eye_above_and_below_the_outer_cornea:
    "Arco gris o blanco que aparece en el ojo por encima y por debajo de la córnea externa",
  inflammation_of_the_iris: "Inflamación del iris",
  itching_of_the_eyes: "Picazón en los ojos",
  left_eye_redness: "Enrojecimiento del ojo izquierdo",
  light_halos: "Halos de luz",
  loss_or_decreased_movement_of_eye_ball:
    "Pérdida o disminución del movimiento del globo ocular",
  nodules_in_the_retina: "Nódulos en la retina",
  pain_in_the_eyelids: "Dolor en los párpados",
  pain_in_the_eyes: "Dolor en los ojos",
  pallor_conjunctiva: "Palidez - Conjuntiva",
  pink_eye_inflammation_of_the_conjunctiva:
    "Ojo rosado - Inflamación de la conjuntiva",
  presence_of_blind_spots: "Presencia de puntos ciegos",
  progressive_loss_of_vision: "Pérdida progresiva de visión",
  prolonged_staring: "Mirada prolongada",
  pus_discharge_from_the_eye: "Secreción de pus del ojo",
  redness_in_both_eyes: "Enrojecimiento en ambos ojos",
  redness_of_the_eyelids: "Enrojecimiento de los párpados",
  reduced_blink_rate: "Reducción de la tasa de parpadeo",
  reduced_visual_acuity: "Agudeza visual reducida",
  reduced_visual_acuity_in_both_eyes: "Agudeza visual reducida en ambos ojos",
  reduced_visual_acuity_in_the_left_eye:
    "Agudeza visual reducida en el ojo izquierdo",
  reduced_visual_acuity_in_the_right_eye:
    "Agudeza visual reducida en el ojo derecho",
  right_eye_redness: "Enrojecimiento del ojo derecho",
  rust_ring_on_the_cornea: "Anillo de óxido en la córnea",
  sensation_of_a_foreign_body_in_the_eye:
    "Sensación de cuerpo extraño en el ojo",
  sensitivity_to_bright_light: "Sensibilidad a la luz brillante",
  sensitivity_to_light: "Sensibilidad a la luz",
  skin_crease_eyelid: "Pliegue en la piel - Párpado",
  sudden_painless_vision_loss: "Pérdida repentina e indolora de visión",
  swelling_around_the_eye_area: "Hinchazón alrededor del área ocular",
  swelling_of_the_eye: "Hinchazón del ojo",
  temporary_loss_of_vision: "Pérdida temporal de visión",
  tenderness_around_the_eye: "Sensibilidad alrededor del ojo",
  thin_nails: "Uñas finas",
  total_blindness: "Ceguera total",
  ulcer_on_the_eyelid_margin: "Úlcera en el margen del párpado",
  unequal_pupil_sizes: "Tamaño desigual de las pupilas",
  vision_loss: "Pérdida de visión",
  visual_floaters: "Flotadores visuales",
  visual_perceptual_abnormalities: "Anomalías perceptuales visuales",
  whitecentered_retinal_hemorrhages: "Hemorragias retinianas con centro blanco",
  word_blindness: "Ceguera de palabras",
  xanthelasma: "Xantelasmas",
  abnormal_rectal_discharge: "Secreción rectal anormal",
  bad_odor: "Mal olor",
  bad_odor_from_fingers: "Mal olor de los dedos",
  abnormal_urine: "Orina anormal",
  abnormal_urine_flow: "Flujo anormal de orina",
  abnormal_vaginal_bleeding: "Sangrado vaginal anormal",
  abnormal_vaginal_bleeding_metrorrhagia:
    "Sangrado vaginal anormal (metrorragia)",
  abnormal_vaginal_bleeding_metrorrhagia_painless:
    "Sangrado vaginal anormal (metrorragia) sin dolor",
  abnormal_vaginal_bleeding_following_sexual_intercourse:
    "Sangrado vaginal después de la relación sexual",
  abnormal_vaginal_secretions: "Secreciones vaginales anormales",
  absence_of_menstruation: "Ausencia de menstruación",
  accumulation_of_white_matter_at_the_tip_of_the_penis:
    "Acumulación de materia blanca en la punta del pene",
  adnexal_mass: "Masa anexial",
  bleeding_between_menstrual_cycles: "Sangrado entre ciclos menstruales",
  bleeding_from_the_cervix: "Sangrado del cuello uterino",
  bloody_discharge_from_the_penis: "Secreción sanguinolenta del pene",
  burning_sensation_or_difficulty_in_urination:
    "Sensación de ardor o dificultad al orinar",
  cervical_muscle_pain: "Dolor muscular cervical",
  decreased_urine_output_anuria:
    "Disminución en la producción de orina (anuria)",
  decreased_urine_output_or_urinary_retention:
    "Disminución en la producción de orina o retención urinaria",
  difficulty_urinating: "Dificultad para orinar",
  discharge_from_penis_containing_pus: "Secreción del pene - Conteniendo pus",
  discharge_from_the_cervix: "Secreción del cuello uterino",
  discharge_from_the_cervix_containing_pus:
    "Secreción del cuello uterino - Conteniendo pus",
  discharge_from_the_penis: "Secreción del pene",
  discharge_from_the_urethra: "Secreción de la uretra",
  dry_vagina_or_vulva: "Vagina o vulva secas",
  dysmenorrhea_painful_menstrual_periods:
    "Dismenorrea - Períodos menstruales dolorosos",
  excessive_menstruation: "Menstruación excesiva",
  foulsmelling_urine: "Orina maloliente",
  hardening_or_thickening_of_the_vulva:
    "Endurecimiento o engrosamiento de la vulva",
  heavy_menses: "Menstruación abundante",
  increased_frequency_or_output_of_urination:
    "Aumento de la frecuencia o cantidad de orina",
  increased_urination_frequency: "Aumento de la frecuencia urinaria",
  increased_urine_output: "Aumento de la producción de orina",
  inflammation_or_infection_of_the_glans_penis:
    "Inflamación o infección del glande del pene",
  infrequent_menstruation: "Menstruación infrecuente",
  irregular_menstruation: "Menstruación irregular",
  leg_pain: "Dolor en las piernas",
  lump_in_the_scrotum: "Bulto en el escroto",
  mass_in_the_scrotum: "Masa en el escroto",
  menstrual_irregularities_missed_period:
    "Irregularidades menstruales - Periodo perdido",
  pain_and_tenderness_in_the_vulva_or_vagina:
    "Dolor y sensibilidad en la vulva o vagina",
  pain_in_the_scrotum: "Dolor en el escroto",
  penile_pain: "Dolor peneano",
  polymenorrhea_frequent_menses: "Polimenorrea (Menstruación frecuente)",
  pus_in_the_urine: "Pus en la orina",
  scrotal_mass: "Masa escrotal",
  scrotal_pain: "Dolor escrotal",
  swelling_of_the_glans_penis: "Hinchazón del glande del pene",
  swelling_of_the_penis: "Hinchazón del pene",
  swelling_or_enlargement_of_the_scrotum:
    "Hinchazón o aumento del tamaño del escroto",
  swelling_or_enlargement_of_the_testis:
    "Hinchazón o aumento del tamaño de los testículos",
  thin_hair: "Cabello fino",
  urethral_irritation_or_itching: "Irritación o picazón en la uretra",
  urinary_incontinence: "Incontinencia urinaria",
  urinary_incontinence_loss_of_bladder_control:
    "Incontinencia urinaria (Pérdida del control de la vejiga)",
  urinary_incontinence_with_straining: "Incontinencia urinaria con esfuerzo",
  urinary_infection: "Infección urinaria",
  urinary_obstruction: "Obstrucción urinaria",
  urinary_retention: "Retención urinaria",
  urine_discoloration: "Descoloración de la orina",
  uterine_bleeding: "Sangrado uterino",
  uterine_contractions: "Contracciones uterinas",
  vaginal_fullness_or_pressure: "Sensación de plenitud o presión vaginal",
  vulval_or_vaginal_itching: "Picazón vulvar o vaginal",
  vulval_or_vaginal_pain_or_tenderness: "Dolor o sensibilidad vulvar o vaginal",
  vulvar_mass: "Masa vulvar",
  weak_urine_stream: "Corriente urinaria débil",
  weak_voice: "Voz débil",
  bleeding_from_the_nails: "Sangrado de las uñas",
  brittle_nails: "Uñas quebradizas",
  discolored_nails: "Uñas descoloridas",
  dry_hair: "Cabello seco",
  excessive_body_hair: "Exceso de vello corporal",
  excessive_hair_growth_on_the_legs:
    "Crecimiento excesivo de vello en las piernas",
  hair_loss: "Pérdida de cabello",
  hair_loss_armpit: "Pérdida de cabello - Axilas",
  hair_loss_foot: "Pérdida de cabello - Pies",
  inflamed_nail_folds: "Pliegues de uñas inflamados",
  lightly_pigmented_hair: "Cabello ligeramente pigmentado",
  male_pattern_baldness: "Calvicie de patrón masculino",
  nail_abnormalities: "Anomalías en las uñas",
  sinus_congestion: "Congestión nasal",
  stroke: "Accidente cerebrovascular",
  frequent_hiccups: "Hipo frecuente",
  abnormal_joint_sounds: "Sonidos articulares anormales",
  bone_cracking_crepitus: "Crujido óseo (Crepitación)",
  finger_dislocation: "Dislocación de dedo",
  fracture_of_the_spine: "Fractura de la columna vertebral",
  gum_injuries: "Lesiones en las encías",
  hip_dislocation: "Dislocación de cadera",
  hip_fracture: "Fractura de cadera",
  inability_to_bear_weight: "Incapacidad para soportar peso",
  injury_to_the_lip: "Lesión en el labio",
  joint_dislocation: "Dislocación articular",
  long_bone_fractures: "Fracturas de huesos largos",
  quadriceps_tendon_rupture: "Ruptura del tendón del cuádriceps",
  rib_fractures: "Fracturas de costillas",
  rupture_of_the_patellar_tendon: "Ruptura del tendón rotuliano",
  shoulder_dislocation: "Dislocación de hombro",
  wrist_fracture: "Fractura de muñeca",
  bad_breath: "Mal aliento",
  bleeding_from_the_mucous_membranes: "Sangrado de las membranas mucosas",
  bleeding_from_the_palate: "Sangrado del paladar",
  bleeding_from_throat: "Sangrado de la garganta",
  coated_tongue_hairy_tongue: "Lengua cubierta (Lengua pilosa)",
  cracked_lips: "Labios agrietados",
  cracked_or_fissured_tongue: "Lengua agrietada o fisurada",
  dental_cavities_decay: "Cavidades dentales (caries)",
  dental_enamel_hypoplasia_or_pits: "Hipoplasia o pits del esmalte dental",
  dental_plaque: "Placa dental",
  difficulty_controlling_bodily_secretions:
    "Dificultad para controlar las secreciones corporales",
  difficulty_opening_the_mouth: "Dificultad para abrir la boca",
  drooling: "Babeo",
  drooping_of_mouth_corners: "Caída de las comisuras de la boca",
  dry_mouth: "Boca seca",
  dry_mouth_in_the_early_morning: "Boca seca por la mañana temprano",
  enlarged_tongue_or_thick_tongue: "Lengua agrandada o gruesa",
  erosion_of_tooth_enamel: "Erosión del esmalte dental",
  excessive_salivation: "Salivación excesiva",
  gum_pain: "Dolor en las encías",
  lip_discoloration: "Descoloración de los labios",
  loose_teeth: "Dientes flojos",
  oral_mass: "Masa oral",
  pain_in_the_mouth: "Dolor en la boca",
  pain_while_speaking: "Dolor al hablar",
  quadriplegia: "Cuadriplejia",
  red_swollen_gums: "Encías rojas e inflamadas",
  redness_of_the_buccal_mucosa: "Enrojecimiento de la mucosa bucal",
  swelling_inside_the_mouth_hard_palate: "Hinchazón en la boca - Paladar duro",
  swelling_of_the_gums: "Hinchazón de las encías",
  swelling_of_the_lips: "Hinchazón de los labios",
  tooth_loss: "Pérdida de dientes",
  toothache: "Dolor de muelas",
  torn_lingual_frenum_tongue_web:
    "Frenillo lingual roto (Frenillo de la lengua)",
  torn_lips: "Labios rotos",
  wear_on_teeth: "Desgaste de los dientes",
  abnormal_position_of_the_hip: "Posición anormal de la cadera",
  arm_muscle_enlargement: "Agrandamiento de los músculos del brazo",
  breast_fullness: "Sensación de plenitud en los senos",
  calf_muscle_enlargement: "Agrandamiento de los músculos de la pantorrilla",
  difficulty_dressing: "Dificultad para vestirse",
  elbow_pain_or_tenderness: "Dolor o sensibilidad en el codo",
  finger_pain: "Dolor en los dedos",
  foot_joint_pain: "Dolor en la articulación del pie",
  foot_spasm: "Espasmo en el pie",
  hand_joint_pain: "Dolor en la articulación de la mano",
  hand_spasm: "Espasmo en la mano",
  hip_abnormal_sounds: "Sonidos anormales de la cadera",
  hip_dysplasia: "Displasia de cadera",
  hypermobile_joints: "Articulaciones hipermóviles",
  instability_of_the_knee: "Inestabilidad de la rodilla",
  involuntary_muscle_contractions: "Contracciones musculares involuntarias",
  jaw_muscle_enlargement: "Agrandamiento de los músculos de la mandíbula",
  joint_contracture_in_hands: "Contractura articular en las manos",
  joint_instability: "Inestabilidad articular",
  joint_pain: "Dolor articular",
  joint_stiffness: "Rigidez articular",
  joint_stiffness_small_joints: "Rigidez articular - Articulaciones pequeñas",
  knee_pain_or_tenderness: "Dolor o sensibilidad en la rodilla",
  knee_stiffness: "Rigidez en la rodilla",
  leg_muscle_enlargement: "Agrandamiento de los músculos de las piernas",
  localized_increase_in_temperature_joints:
    "Aumento localizado de la temperatura - Articulaciones",
  loss_of_voluntary_anal_control: "Pérdida del control anal voluntario",
  loud_pop_in_knee: "Chasquido fuerte en la rodilla",
  muscle_atrophy_in_lower_limbs:
    "Atrofia muscular en las extremidades inferiores",
  muscle_atrophy_or_wasting: "Atrofia muscular o pérdida de masa muscular",
  muscle_contractions_dystonia: "Contracciones musculares (Distonía)",
  muscle_contractions_or_spasm: "Contracciones musculares o espasmo",
  muscle_enlargement_hypertrophy: "Agrandamiento muscular (Hipertrofia)",
  muscle_spasms: "Espasmos musculares",
  muscle_stiffness: "Rigidez muscular",
  neck_muscle_enlargement: "Agrandamiento de los músculos del cuello",
  pain_in_large_joints: "Dolor en las articulaciones grandes",
  pain_in_small_joints: "Dolor en las articulaciones pequeñas",
  poor_muscle_tone: "Pobre tono muscular",
  quadriceps_muscle_wasting: "Atrofia del músculo cuádriceps",
  reduced_or_absent_ankle_reflex: "Reflejo de tobillo reducido o ausente",
  reduced_tendon_reflexes: "Reflejos tendinosos reducidos",
  shoulder_pain: "Dolor en el hombro",
  shoulder_stiffness: "Rigidez en el hombro",
  soft_stools: "Heces blandas",
  stiffness_of_the_finger: "Rigidez en el dedo",
  sudden_muscle_jerks_myoclonus: "Sacudidas musculares repentinas (Mioclonía)",
  swelling_of_joints: "Hinchazón de las articulaciones",
  swelling_of_the_joints: "Hinchazón de las articulaciones",
  temporomandibular_joint_pain: "Dolor en la articulación temporomandibular",
  tendon_rupture: "Ruptura del tendón",
  testicular_atrophy: "Atrofia testicular",
  thigh_muscle_atrophy: "Atrofia del músculo del muslo",
  weakness_in_joints: "Debilidad en las articulaciones",
  feeling_of_sickness_nausea: "Sensación de enfermedad / Náuseas",
  nausea_and_vomiting: "Náuseas y vómitos",
  vomiting_blood: "Vómito con sangre",
  difficulty_producing_sounds: "Dificultad para producir sonidos",
  dryness_of_throat: "Sequedad en la garganta",
  highpitched_voice: "Voz aguda",
  hoarseness: "Ronquera",
  hunched_shoulders: "Hombros encorvados",
  inflammation_of_the_tonsils: "Inflamación de las amígdalas",
  inflammation_of_the_vocal_cords: "Inflamación de las cuerdas vocales",
  muteness: "Mudez",
  neck_muscle_spasms: "Espasmos musculares en el cuello",
  neck_pain: "Dolor de cuello",
  pharyngeal_follicles: "Folículos faríngeos",
  sore_throat_or_throat_pain: "Dolor de garganta o dolor en la garganta",
  stiff_neck: "Cuello rígido",
  swelling_of_the_neck: "Hinchazón del cuello",
  tonsillar_follicles: "Folículos amigdalares",
  voice_tremors: "Temblores en la voz",
  white_discharge_from_the_tonsils: "Secreción blanca de las amígdalas",
  bleeding_from_the_nose: "Sangrado de la nariz",
  blood_discharge_from_the_nose: "Secreción sanguinolenta de la nariz",
  copious_nasal_discharge: "Secreción nasal copiosa",
  diminished_sense_of_smell: "Disminución del sentido del olfato",
  discharge_from_nose_containing_pus: "Secreción nasal - Conteniendo pus",
  inability_to_converge_eyeballs: "Incapacidad para converger los ojos",
  nasal_discharge: "Secreción nasal",
  nasal_sores: "Úlceras nasales",
  pharyngeal_congestion: "Congestión faríngea",
  redness_and_swelling_of_the_nasal_mucosa:
    "Enrojecimiento e hinchazón de la mucosa nasal",
  sinus_discharge: "Secreción sinusal",
  bleeding: "Sangrado",
  bleeding_from_the_varicose_veins: "Sangrado de las venas varicosas",
  abdominal_pain_increases_after_meals:
    "Dolor abdominal - Aumenta después de las comidas",
  abdominal_stomach_pain_or_discomfort:
    "Dolor o molestia abdominal / estomacal",
  abdominal_colic_or_cramps: "Cólico abdominal o calambres",
  abdominal_or_stomach_pain: "Dolor abdominal o estomacal",
  abdominal_pain_relieved_after_food:
    "Dolor abdominal - Aliviado después de comer",
  acute_chest_pain: "Dolor torácico agudo",
  ankle_pain_or_tenderness: "Dolor o sensibilidad en el tobillo",
  arm_pain: "Dolor en el brazo",
  back_pain_or_tenderness: "Dolor o sensibilidad en la espalda",
  bone_pain: "Dolor óseo",
  bone_tenderness: "Sensibilidad ósea",
  calf_pain: "Dolor en la pantorrilla",
  chest_pain: "Dolor en el pecho",
  chest_pain_at_rest: "Dolor en el pecho en reposo",
  chest_pain_during_activity: "Dolor en el pecho durante la actividad",
  chest_pain_radiating_to_the_back:
    "Dolor en el pecho que irradia hacia la espalda",
  chest_pain_radiating_to_the_jaw:
    "Dolor en el pecho que irradia hacia la mandíbula",
  chest_pain_radiating_to_the_shoulder:
    "Dolor en el pecho que irradia hacia el hombro",
  chest_pain_radiating_to_the_stomach:
    "Dolor en el pecho que irradia hacia el estómago",
  chest_pain_radiating_to_the_upper_limb:
    "Dolor en el pecho que irradia hacia las extremidades superiores",
  chest_pain_that_doesnt_improve_with_rest:
    "Dolor en el pecho que no mejora con el reposo",
  chest_pain_when_coughing: "Dolor en el pecho al toser",
  chest_pain_while_breathing: "Dolor en el pecho al respirar",
  coccyx_pain: "Dolor en el coxis",
  cramping_leg_pain: "Calambres dolorosos en las piernas",
  eye_irritation: "Irritación ocular",
  facial_pain: "Dolor facial",
  feeling_of_pressure_in_the_sinuses:
    "Sensación de presión en los senos paranasales",
  general_pain: "Dolor general",
  generalised_pain: "Dolor generalizado",
  generalized_abdominal_stomach_pain:
    "Dolor abdominal / estomacal generalizado",
  generalized_abdominal_stomach_pain_after_food:
    "Dolor abdominal / estomacal generalizado - Después de comer",
  groin_pain: "Dolor en la ingle",
  hand_limb_pain: "Dolor en extremidades de las manos",
  headache: "Dolor de cabeza",
  hip_deformity: "Deformidad de la cadera",
  hip_pain: "Dolor en la cadera",
  hip_pain_or_tenderness: "Dolor o sensibilidad en la cadera",
  insect_bites_or_stings: "Picaduras o mordeduras de insectos",
  jaw_pain: "Dolor en la mandíbula",
  left_lower_quadrant_llq_abdominal_stomach_pain:
    "Dolor abdominal / estomacal en el cuadrante inferior izquierdo (QII)",
  left_lower_quadrant_llq_abdominal_tenderness:
    "Sensibilidad abdominal en el cuadrante inferior izquierdo (QII)",
  left_upper_quadrant_luq_abdominal_stomach_pain:
    "Dolor abdominal / estomacal en el cuadrante superior izquierdo (QSI)",
  left_upper_quadrant_luq_abdominal_tenderness:
    "Sensibilidad abdominal en el cuadrante superior izquierdo (QSI)",
  limb_pain: "Dolor en las extremidades",
  limb_pain_heel: "Dolor en las extremidades - Talón",
  limb_pain_while_at_rest: "Dolor en las extremidades en reposo",
  loss_of_grip_strength: "Pérdida de fuerza de agarre",
  lower_back_pain: "Dolor en la parte baja de la espalda",
  lower_half_abdominal_stomach_pain:
    "Dolor abdominal / estomacal en la mitad inferior",
  muscle_pain: "Dolor muscular",
  muscle_tenderness: "Sensibilidad muscular",
  ovarian_tumor: "Tumor ovárico",
  pain_in_the_extremities: "Dolor en las extremidades",
  pain_in_the_flank: "Dolor en el flanco",
  pain_in_the_inguinal_area: "Dolor en la región inguinal",
  pain_induced_by_alcohol: "Dolor inducido por el alcohol",
  pain_or_discomfort_in_the_jaw_while_chewing:
    "Dolor o molestia en la mandíbula al masticar",
  pain_or_tenderness_at_the_costovertebral_angle:
    "Dolor o sensibilidad en el ángulo costovertebral",
  pain_radiating_to_the_buttocks: "Dolor que irradia hacia los glúteos",
  pain_radiating_to_the_left_shoulder:
    "Dolor que irradia hacia el hombro izquierdo",
  pain_while_chewing: "Dolor al masticar",
  pelvic_pain: "Dolor pélvico",
  pelvic_pain_or_tenderness: "Dolor o sensibilidad pélvica",
  radiating_groin_pain: "Dolor irradiado a la ingle",
  radiating_limb_pain: "Dolor irradiado a las extremidades",
  right_lower_quadrant_rlq_abdominal_stomach_pain:
    "Dolor abdominal / estomacal en el cuadrante inferior derecho (QID)",
  right_lower_quadrant_rlq_abdominal_tenderness:
    "Sensibilidad abdominal en el cuadrante inferior derecho (QID)",
  right_upper_quadrant_ruq_abdominal_stomach_pain:
    "Dolor abdominal / estomacal en el cuadrante superior derecho (QSD)",
  right_upper_quadrant_ruq_abdominal_tenderness:
    "Sensibilidad abdominal en el cuadrante superior derecho (QSD)",
  rigidity_of_abdominal_muscles: "Rigidez de los músculos abdominales",
  somatic_pain: "Dolor somático",
  tenderness: "Sensibilidad",
  tenderness_in_or_around_umbilical_region:
    "Sensibilidad en o alrededor de la región umbilical",
  tenderness_in_the_abdomen: "Sensibilidad en el abdomen",
  tenderness_in_the_flank_region: "Sensibilidad en la región del flanco",
  tenderness_in_the_groin_area: "Sensibilidad en la región inguinal",
  tenderness_in_the_lower_abdomen: "Sensibilidad en la parte baja del abdomen",
  tenderness_in_the_sinuses: "Sensibilidad en los senos paranasales",
  tenderness_in_the_upper_abdomen:
    "Sensibilidad en la parte superior del abdomen",
  tenderness_of_tendons: "Sensibilidad en los tendones",
  tenderness_of_the_calf: "Sensibilidad en la pantorrilla",
  thigh_pain: "Dolor en el muslo",
  trunk_pain: "Dolor en el tronco",
  unilateral_limb_pain: "Dolor unilateral en las extremidades",
  upper_half_abdominal_stomach_pain:
    "Dolor abdominal / estomacal en la mitad superior",
  wrist_pain_or_tenderness: "Dolor o sensibilidad en la muñeca",
  oral_thrush: "Candidiasis oral",
  palpitations: "Palpitaciones",
  arm_paralysis: "Parálisis del brazo",
  bilateral_generalized_muscle_weakness:
    "Debilidad muscular generalizada bilateral",
  bilateral_lower_limb_weakness:
    "Debilidad en las extremidades inferiores bilaterales",
  bilateral_shoulder_weakness: "Debilidad bilateral en los hombros",
  bilateral_upper_limb_weakness:
    "Debilidad en las extremidades superiores bilaterales",
  corneal_inflammation: "Inflamación corneal",
  difficulty_climbing_stairs: "Dificultad para subir escaleras",
  facial_paralysis: "Parálisis facial",
  general_muscle_weakness: "Debilidad muscular generalizada",
  hard_stools: "Heces duras",
  hemiparesis: "Hemiparesia",
  limb_weakness: "Debilidad en las extremidades",
  lower_limb_generalized_muscle_weakness:
    "Debilidad muscular generalizada en extremidades inferiores",
  muscle_weakness_in_legs: "Debilidad muscular en las piernas",
  pale_stools: "Heces pálidas",
  paralysis: "Parálisis",
  paralysis_of_all_limbs: "Parálisis de todas las extremidades",
  paralysis_of_finger: "Parálisis del dedo",
  paralysis_of_the_bicep: "Parálisis del bíceps",
  paralysis_of_the_lower_limbs: "Parálisis de las extremidades inferiores",
  paralysis_of_the_shoulder: "Parálisis del hombro",
  paralysis_of_the_triceps: "Parálisis del tríceps",
  paralysis_of_the_wrist: "Parálisis de la muñeca",
  paralysis_of_upper_limbs: "Parálisis de las extremidades superiores",
  paralysis_on_one_side: "Parálisis en un lado del cuerpo",
  paraparesis: "Paraparesia",
  poor_feeding: "Alimentación deficiente",
  shoulder_weakness: "Debilidad en el hombro",
  unilateral_generalized_muscle_weakness:
    "Debilidad muscular unilateral generalizada",
  unilateral_lower_limb_weakness:
    "Debilidad unilateral en extremidades inferiores",
  unilateral_shoulder_weakness: "Debilidad unilateral en el hombro",
  unilateral_upper_limb_weakness:
    "Debilidad unilateral en extremidades superiores",
  unilateral_weakness_of_limbs: "Debilidad unilateral de las extremidades",
  upper_limb_generalized_muscle_weakness:
    "Debilidad muscular generalizada en extremidades superiores",
  weakness_in_extending_the_arm: "Debilidad para extender el brazo",
  weakness_in_facial_muscles: "Debilidad en los músculos faciales",
  weakness_in_finger_muscles: "Debilidad en los músculos de los dedos",
  weakness_in_hand_muscles: "Debilidad en los músculos de la mano",
  weakness_in_hip_muscles: "Debilidad en los músculos de la cadera",
  weakness_in_the_arms: "Debilidad en los brazos",
  weakness_in_thigh_muscles: "Debilidad en los músculos del muslo",
  weakness_of_lower_limbs: "Debilidad de las extremidades inferiores",
  weakness_of_thenar_muscles_hand: "Debilidad en los músculos tenares (mano)",
  weakness_of_upper_limbs: "Debilidad de las extremidades superiores",
  abdominal_enlargement_or_distension_or_swelling:
    "Agrandamiento, distensión o hinchazón abdominal",
  abdominal_mass: "Masa abdominal",
  abnormal_breathing_sound: "Sonido respiratorio anormal",
  abnormal_fundoscopic_examination: "Examen de fondo de ojo anormal",
  abnormal_otoscopic_test: "Prueba otoscópica anormal",
  abnormal_red_reflex: "Reflejo rojo anormal",
  abnormal_respiratory_sound: "Sonido respiratorio anormal",
  anal_discharge: "Secreción anal",
  dark_circles_below_the_eyes: "Ojeras debajo de los ojos",
  elevated_intraocular_pressure: "Presión intraocular elevada",
  enlarged_liver: "Hígado agrandado",
  enlarged_liver_and_spleen_hepatosplenomegaly:
    "Hígado y bazo agrandados (hepatomegalia y esplenomegalia)",
  grunting: "Gruñidos",
  heart_murmur: "Soplo cardíaco",
  mass_in_the_abdomen: "Masa en el abdomen",
  perineal_mass: "Masa perineal",
  pleural_rub: "Frote pleural",
  puddles_sign: "Signo de charco",
  rectal_mass: "Masa rectal",
  reduced_intraocular_pressure: "Presión intraocular reducida",
  rhonchi: "Roncos",
  skin_tag: "Etiqueta cutánea",
  squeaks: "Chirridos",
  stridor: "Estridor",
  thickening_of_tendons: "Engrosamiento de los tendones",
  thrombosis_blood_clots: "Trombosis (coágulos sanguíneos)",
  tumor_plop: "Sonido tumoral (tumor plop)",
  twin_pregnancy: "Embarazo gemelar",
  uterine_prolapse: "Prolapso uterino",
  decreased_fetal_movement: "Disminución del movimiento fetal",
  fetal_growth_restriction: "Restricción del crecimiento fetal",
  miscarriage: "Aborto espontáneo",
  premature_preterm_labor: "Parto prematuro",
  still_birth_or_miscarriage: "Muerte fetal o aborto espontáneo",
  faint_pulse: "Pulso débil",
  irregular_pulse: "Pulso irregular",
  abnormal_skin_pigmentation: "Pigmentación anormal de la piel",
  abnormal_skin_texture: "Textura anormal de la piel",
  abscess_on_the_skin: "Absceso en la piel",
  acne_on_the_face: "Acné en la cara",
  allergy: "Alergia",
  anal_sores: "Llagas anales",
  ankle_ulcer: "Úlcera en el tobillo",
  blisters: "Ampollas",
  blisters_eye: "Ampollas en el ojo",
  blue_discoloration_around_the_umbilicus:
    "Coloración azul alrededor del ombligo",
  bluishgrey_flank_discoloration: "Coloración azul-gris en el flanco",
  bruise_calf: "Moretón en la pantorrilla",
  bruise_on_the_head: "Moretón en la cabeza",
  bruise_on_the_hip: "Moretón en la cadera",
  bruise_on_the_neck: "Moretón en el cuello",
  bruising: "Moretones",
  bullous_rash: "Erupción bullosa",
  butterfly_rash: "Erupción en forma de mariposa",
  chest_rash: "Erupción en el pecho",
  coarse_skin: "Piel áspera",
  cyanosis_bluish_skin_color: "Cianosis (coloración azulada de la piel)",
  darkening_of_the_skin: "Oscurecimiento de la piel",
  difficulty_opening_jars: "Dificultad para abrir frascos",
  diffused_rash: "Erupción cutánea difusa",
  dry_skin: "Piel seca",
  ecchymosis_in_the_inguinal_area: "Equimosis en la región inguinal",
  eczema_lesions: "Lesiones de eccema",
  facial_rash: "Erupción facial",
  facial_wrinkles: "Arrugas faciales",
  flushing_of_skin: "Enrojecimiento de la piel",
  foot_ulcer: "Úlcera en el pie",
  general_rash: "Erupción generalizada",
  jaundice_yellowing_of_the_skin: "Ictericia (Coloración amarilla de la piel)",
  jaundice_present_at_birth: "Ictericia presente al nacer",
  lesions_and_sores_on_the_buccal_mucosa:
    "Lesiones y llagas en la mucosa bucal",
  lesions_on_the_face: "Lesiones en la cara",
  localized_skin_reddening: "Enrojecimiento localizado de la piel",
  macular_rash: "Erupción macular",
  maculopapular_rash: "Erupción maculopapular",
  moist_skin: "Piel húmeda",
  mole: "Lunar",
  mouth_blisters: "Ampollas en la boca",
  mouth_sores: "Llagas en la boca",
  nodular_rash: "Erupción nodular",
  nodular_rash_on_the_palm: "Erupción nodular en la palma",
  oily_skin: "Piel grasosa",
  oral_lesions: "Lesiones orales",
  palate_sores: "Llagas en el paladar",
  pallor_limbs: "Palidez - Extremidades",
  pallor_paleness: "Palidez",
  pallor_fingers: "Palidez en los dedos",
  papular_rash: "Erupción papular",
  papular_rash_around_the_waist: "Erupción papular alrededor de la cintura",
  papular_rash_between_the_fingers: "Erupción papular entre los dedos",
  papular_rash_in_the_axilla: "Erupción papular en la axila",
  papular_rash_on_the_areola: "Erupción papular en la areola",
  papular_rash_on_the_back: "Erupción papular en la espalda",
  papular_rash_on_the_buttocks: "Erupción papular en los glúteos",
  papular_rash_on_the_face_neck_and_palms_soles:
    "Erupción papular en la cara, cuello, palmas y plantas",
  papular_rash_on_the_hands_fingers_and_feet:
    "Erupción papular en las manos, dedos y pies",
  papular_rash_on_the_knees: "Erupción papular en las rodillas",
  papular_rash_on_the_scrotum: "Erupción papular en el escroto",
  papular_rash_on_the_wrist: "Erupción papular en la muñeca",
  penile_sores: "Llagas en el pene",
  perianal_or_anal_inflammation_redness:
    "Inflamación o enrojecimiento perianal o anal",
  plaque_rash: "Erupción en placas",
  pusfilled_lump: "Bulto lleno de pus",
  pustular_rash: "Erupción pustulosa",
  rash_in_the_diaper_area: "Erupción en el área del pañal",
  rash_on_the_breast: "Erupción en el pecho",
  rash_on_the_buttocks: "Erupción en los glúteos",
  rash_on_the_palms_and_soles: "Erupción en las palmas y plantas",
  reddening_of_the_feet: "Enrojecimiento de los pies",
  reddening_of_the_fingers: "Enrojecimiento de los dedos",
  reddening_of_the_heels: "Enrojecimiento de los talones",
  reddening_of_the_nappy_area: "Enrojecimiento en el área del pañal",
  reddening_of_the_palms: "Enrojecimiento de las palmas",
  reddening_of_the_soles: "Enrojecimiento de las plantas",
  reddening_of_the_toes: "Enrojecimiento de los dedos de los pies",
  redness_of_the_breast: "Enrojecimiento del pecho",
  redness_of_the_face: "Enrojecimiento de la cara",
  redness_of_the_vulva_or_vagina: "Enrojecimiento de la vulva o vagina",
  scalp_flaking: "Descamación del cuero cabelludo",
  scalp_rash: "Erupción en el cuero cabelludo",
  scalp_sores: "Llagas en el cuero cabelludo",
  scaly_skin_hands: "Piel escamosa - Manos",
  scar: "Cicatriz",
  scrotum_redness: "Enrojecimiento del escroto",
  skin_rash_red: "Erupción cutánea - Roja",
  skin_reddening: "Enrojecimiento de la piel",
  skin_scaling: "Descamación de la piel",
  subcutaneous_nodular_rash: "Erupción nodular subcutánea",
  tender_skin: "Piel sensible",
  tendinous_xanthomas: "Xantomas tendinosos",
  thick_and_rough_skin_on_the_palms: "Piel gruesa y áspera en las palmas",
  thick_and_rough_skin_on_the_soles: "Piel gruesa y áspera en las plantas",
  thick_skin: "Piel gruesa",
  thickening_tightening_of_skin_of_fingers_or_toes:
    "Engrosamiento y endurecimiento de la piel en dedos o pies",
  ulcer: "Úlcera",
  ulcer_in_the_rectum: "Úlcera en el recto",
  ulcer_on_the_fingers: "Úlcera en los dedos",
  ulcer_on_the_hands: "Úlcera en las manos",
  ulcer_on_the_legs: "Úlcera en las piernas",
  ulcer_on_the_penis: "Úlcera en el pene",
  ulcer_on_the_upper_thigh: "Úlcera en la parte superior del muslo",
  ulcers_on_the_vulva_or_vagina: "Úlceras en la vulva o vagina",
  ulcers_or_fissures_at_mouth_angles:
    "Úlceras o fisuras en las comisuras de la boca",
  vesicular_rash_around_the_waist: "Erupción vesicular alrededor de la cintura",
  vesicular_rash_between_the_fingers: "Erupción vesicular entre los dedos",
  vesicular_rash_in_the_axilla: "Erupción vesicular en la axila",
  vesicular_rash_in_the_perianal_area: "Erupción vesicular en el área perianal",
  vesicular_rash_on_the_areola: "Erupción vesicular en la areola",
  vesicular_rash_on_the_back: "Erupción vesicular en la espalda",
  vesicular_rash_on_the_buttocks: "Erupción vesicular en los glúteos",
  vesicular_rash_on_the_ear: "Erupción vesicular en la oreja",
  vesicular_rash_on_the_gluteal_region:
    "Erupción vesicular en la región glútea",
  vesicular_rash_on_the_knees: "Erupción vesicular en las rodillas",
  vesicular_rash_on_the_mucous_membranes: "Erupción vesicular en las mucosas",
  vesicular_rash_on_the_nose: "Erupción vesicular en la nariz",
  vesicular_rash_on_the_palms_and_soles:
    "Erupción vesicular en las palmas y plantas",
  vesicular_rash_on_the_scrotum: "Erupción vesicular en el escroto",
  vesicular_rash_on_the_upper_thigh:
    "Erupción vesicular en la parte superior del muslo",
  vesicular_rash_on_the_wrist: "Erupción vesicular en la muñeca",
  vulva_or_vagina_depigmentation: "Despigmentación de la vulva o vagina",
  vulval_or_vaginal_lesion: "Lesión vulvar o vaginal",
  whitish_patches_in_the_buccal_mucosa: "Manchas blancas en la mucosa bucal",
  decreased_libido: "Disminución de la libido",
  decreased_sexual_desire: "Disminución del deseo sexual",
  delayed_orgasm: "Orgasmo retardado",
  hemiplegia: "Hemiplejía",
  impotence: "Impotencia",
  lack_delayed_absence_of_ejaculation:
    "Falta, retraso o ausencia de eyaculación",
  pain_during_ejaculation: "Dolor durante la eyaculación",
  pain_during_intercourse: "Dolor durante el coito",
  premature_ejaculation: "Eyaculación precoz",
  prolonged_penile_erection: "Erección peneana prolongada",
  samesex_attraction: "Atracción por el mismo sexo",
  confusional_arousals: "Despertares confusionales",
  decreased_sleep_need: "Disminución de la necesidad de dormir",
  drowsiness: "Somnolencia",
  excessive_sleep: "Sueño excesivo",
  frequent_yawning: "Bostezos frecuentes",
  insomnia: "Insomnio",
  loss_of_appetite: "Pérdida de apetito",
  nightmare: "Pesadillas",
  short_sleep_duration: "Duración corta del sueño",
  sleep_disturbances: "Trastornos del sueño",
  sleepwalking: "Sonambulismo",
  snoring: "Ronquidos",
  talking_in_sleep: "Hablar durante el sueño",
  waking_unrefreshed: "Despertarse sin sensación de descanso",
  sneezing: "Estornudos",
  change_in_speech_pitch: "Cambio en el tono de voz",
  pressured_speech: "Habla presionada",
  shift_quickly_between_topics_and_get_distracted_by_internal_and_external_stimuli:
    "Cambios rápidos de tema y distracción por estímulos internos o externos",
  slow_speech_or_speech_delay: "Habla lenta o retraso en el habla",
  soft_or_muffled_speech: "Habla suave o amortiguada",
  sparse_speech_content: "Contenido escaso del habla",
  speech_disorder: "Trastorno del habla",
  speech_impairment_with_stuttering: "Dificultad en el habla con tartamudeo",
  substitution_of_words_or_phrases_or_sounds_in_speech:
    "Sustitución de palabras, frases o sonidos en el habla",
  temporary_speech_impairment: "Dificultad temporal en el habla",
  excessive_sweating: "Sudoración excesiva",
  abnormal_bony_growth: "Crecimiento óseo anormal",
  ankle_swelling: "Hinchazón del tobillo",
  blurred_vision: "Visión borrosa",
  bone_swelling: "Hinchazón ósea",
  brittle_hair: "Cabello quebradizo",
  calf_swelling: "Hinchazón de la pantorrilla",
  enlargement_of_the_submandibular_salivary_gland:
    "Agrandamiento de la glándula submandibular",
  facial_lump: "Masa facial",
  facial_swelling: "Hinchazón facial",
  feeling_of_a_mass_in_the_abdomen: "Sensación de masa en el abdomen",
  flank_mass: "Masa en el flanco",
  hemorrhoids_distended_rectal_veins: "Hemorroides (venas rectales dilatadas)",
  induration_hardening: "Induración (endurecimiento)",
  left_upper_quadrant_luq_mass_in_the_abdomen:
    "Masa en el cuadrante superior izquierdo del abdomen",
  lower_mass_in_the_abdomen: "Masa en la parte inferior del abdomen",
  lump_under_the_wrist: "Protuberancia bajo la muñeca",
  mass_in_the_groin_or_suprapubic_area: "Masa en la ingle o área suprapúbica",
  masses_or_tumor_in_the_pharynx: "Masas o tumor en la faringe",
  nasal_congestion: "Congestión nasal",
  neck_swelling: "Hinchazón del cuello",
  pelvic_mass: "Masa pélvica",
  presacral_swelling_or_edema: "Hinchazón o edema presacral",
  right_upper_quadrant_ruq_mass_in_the_abdomen:
    "Masa en el cuadrante superior derecho del abdomen",
  skin_lump_mass_or_growth: "Bulto, masa o crecimiento en la piel",
  subcutaneous_swelling: "Hinchazón subcutánea",
  swelling_edema: "Hinchazón / edema",
  swelling_after_vaccination: "Hinchazón después de la vacunación",
  swelling_around_the_anus: "Hinchazón alrededor del ano",
  swelling_in_the_groin_or_suprapubic_area:
    "Hinchazón en la ingle o área suprapúbica",
  swelling_in_the_lower_abdomen: "Hinchazón en la parte inferior del abdomen",
  swelling_in_the_pharynx: "Hinchazón en la faringe",
  swelling_in_the_upper_abdomen: "Hinchazón en la parte superior del abdomen",
  swelling_inside_the_ear: "Hinchazón dentro del oído",
  swelling_of_muscles: "Hinchazón de los músculos",
  swelling_of_the_feet: "Hinchazón de los pies",
  swelling_of_the_forearm: "Hinchazón del antebrazo",
  swelling_of_the_hands: "Hinchazón de las manos",
  swelling_of_the_hip: "Hinchazón de la cadera",
  swelling_of_the_knee: "Hinchazón de la rodilla",
  swelling_of_the_legs: "Hinchazón de las piernas",
  swelling_of_the_lower_limbs: "Hinchazón de las extremidades inferiores",
  swelling_of_the_lymph_nodes: "Hinchazón de los ganglios linfáticos",
  swelling_of_the_nose: "Hinchazón de la nariz",
  swelling_of_the_parotid_gland: "Hinchazón de la glándula parótida",
  swelling_of_the_salivary_gland: "Hinchazón de las glándulas salivales",
  swelling_of_the_skull: "Hinchazón del cráneo",
  swelling_of_the_upper_limbs: "Hinchazón de las extremidades superiores",
  swollen_or_enlarged_anterior_cervical_lymph_nodes:
    "Ganglios linfáticos cervicales anteriores inflamados o agrandados",
  swollen_or_enlarged_axillary_lymph_nodes:
    "Ganglios linfáticos axilares inflamados o agrandados",
  swollen_or_enlarged_femoral_lymph_nodes:
    "Ganglios linfáticos femorales inflamados o agrandados",
  swollen_or_enlarged_inguinal_lymph_nodes:
    "Ganglios linfáticos inguinales inflamados o agrandados",
  swollen_or_enlarged_preauricular_lymph_nodes:
    "Ganglios linfáticos preauriculares inflamados o agrandados",
  swollen_or_enlarged_tonsils: "Amígdalas inflamadas o agrandadas",
  umbilical_nodules: "Nódulos umbilicales",
  upper_mass_in_the_abdomen: "Masa en la parte superior del abdomen",
  wrist_swelling: "Hinchazón de la muñeca",
  wrist_swelling_with_pain: "Hinchazón de la muñeca con dolor",
  bone_weakness: "Debilidad ósea",
  general_malaise: "Malestar general",
  weakness_or_lethargy_or_fatigue: "Debilidad o letargo o fatiga",
  excessive_weight: "Exceso de peso",
  weight_decrease: "Pérdida de peso",
  weight_increase: "Aumento de peso",
  canine_bite_dog_bite: "Mordedura de perro",
  decreased_muscle_tone: "Disminución del tono muscular",
  gagging: "Arcadas",
  gestation_pregnancy: "Gestación (Embarazo)",
  increased_muscle_tone: "Aumento del tono muscular",
  joint_contracture: "Contractura articular",
  tongue_sores: "Llagas en la lengua",
  vaginal_secretions_vaginal_discharge: "Secreciones vaginales (flujo vaginal)",

  ////level two

  abnormal_cry: "Llanto anormal",
  aggression_irritability: "Agresión / Irritabilidad",
  apathy: "Apatía",
  bizarre_behavior: "Comportamiento extraño",
  compulsive_obsessive_behaviour: "Comportamiento compulsivo / obsesivo",
  hyperactive_impatient_behavior: "Comportamiento hiperactivo / impaciente",
  self_harm_neglect: "Autolesiones / Negligencia",
  bizzare_movement: "Movimiento extraño",
  lack_of_coordination: "Falta de coordinación",
  other_body_movement: "Otro movimiento corporal",
  seizures_fits: "Convulsiones / Crisis",
  tremor_tics: "Temblor / Tics",
  bizarre_sensation: "Sensación extraña",
  bizarre_sensation_anal_perianal_area:
    "Sensación extraña - Área anal / perianal",
  burning_sensation: "Sensación de ardor",
  itching: "Picazón",
  loss_of_sense_of_vibration: "Pérdida de la sensación de vibración",
  numbness_tingling: "Entumecimiento / Hormigueo",
  chills_rigors: "Escalofríos / Temblores",
  fever: "Fiebre",
  frost_bite: "Congelamiento",
  hot_cold_sensation: "Sensación de calor / frío",
  hypothermia: "Hipotermia",
  anxiety_panic: "Ansiedad / Pánico",
  compulsive_obsessive_feelings_or_thoughts:
    "Sentimientos o pensamientos compulsivos / obsesivos",
  delusions_hallucination_illusion: "Delirios / Alucinaciones / Ilusiones",
  depression: "Depresión",
  phobia: "Fobia",
  abnormal_posture: "Postura anormal",
  abnormal_posture_head_and_neck: "Postura anormal - Cabeza y cuello",
  throat_swelling_lump: "Garganta - Hinchazón / Bulto",
  dizziness: "Mareos",
  reduced_consciousness: "Conciencia reducida",
  abnormal_walking: "Marcha anormal",
  pain_on_walking: "Dolor al caminar",
  acidity: "Acidez",
  bleeding: "Sangrado",
  discharge: "Secreción",
  anorectal_pain_tenderness: "Dolor anorrectal / Sensibilidad",
  anorectal_swelling_lump: "Anorrectal - Hinchazón / Bulto",
  low_blood_pressure: "Hipotensión",
  abnormal_bowel_sounds: "Sonidos anormales en el intestino",
  abnormal_stools: "Heces anormales",
  bowel_incontinence: "Incontinencia fecal",
  constipation: "Estreñimiento",
  diarrhoea: "Diarrea",
  abnormal_discharge: "Secreción anormal",
  breast_swelling_lump: "Mama - Hinchazón / Bulto",
  other_breast_problems: "Otros problemas mamarios",
  breast_pain_tenderness: "Dolor / Sensibilidad en las mamas",
  choking_gasping: "Asfixia / Jadeo",
  shortness_of_breath: "Dificultad para respirar",
  burn: "Quemadura",
  communication_impairment: "Alteración en la comunicación",
  impaired_intellect: "Inteligencia deteriorada",
  lack_of_concentration: "Falta de concentración",
  loss_of_memory: "Pérdida de memoria",
  cough_abnormal_sound: "Tos - Sonido anormal",
  cough_with_blood: "Tos - Con sangre",
  cough_with_sputum: "Tos - Con esputo",
  other_cough_related_issues: "Otros problemas relacionados con la tos",
  chest_deformity: "Deformidad en el pecho",
  hair_scalp_nail_deformity: "Deformidad en cabello / cuero cabelludo / uñas",
  hand_deformity: "Deformidad en las manos",
  head_facial_deformity: "Deformidad en la cabeza / rostro",
  hip_deformity: "Deformidad en la cadera",
  leg_foot_deformity: "Deformidad en las piernas / pies",
  male_genitals: "Genitales masculinos",
  neck_deformity: "Deformidad en el cuello",
  other_deformities: "Otras deformidades",
  shoulder_deformity: "Deformidad en los hombros",
  spinal_deformity: "Deformidad en la columna vertebral",
  delayed_milestones: "Retraso en los hitos del desarrollo",
  genitourinary_deformity: "Deformidad genitourinaria",
  growth_retardation: "Retardo en el crecimiento",
  sexual_maturation_delay_failure: "Retraso / Fallo en la maduración sexual",
  infection: "Infección",
  stroke: "Accidente cerebrovascular (ACV)",
  deafness: "Sordera",
  ear_itching: "Picazón en los oídos",
  ear_wax: "Cera en los oídos",
  other_ear_problems: "Otros problemas en los oídos",
  pain_tenderness: "Dolor / Sensibilidad",
  binge_eating: "Comer en exceso",
  decreased_increased_appetite: "Disminución / aumento del apetito",
  eating_non_food_materials: "Comer materiales no alimenticios",
  feeding_difficulties: "Dificultades para alimentarse",
  increased_decreased_thirst: "Aumento / disminución de la sed",
  taste_problem: "Problemas de gusto",
  abnormal_movement: "Movimiento anormal",
  abnormal_eye_position: "Posición anormal de los ojos",
  colour_blindness: "Daltonismo",
  decreased_vision: "Disminución de la visión",
  double_vision: "Visión doble",
  dry_eyes: "Ojos secos",
  eyelid_eyebrows_eyelash: "Párpado / Ceja / Pestañas",
  intolerance_to_light: "Intolerancia a la luz",
  irritation_itching: "Irritación / Picazón",
  other_eye_problems: "Otros problemas oculares",
  other_visual_problems: "Otros problemas visuales",
  eye_pain_tenderness: "Dolor ocular / Sensibilidad",
  pallor: "Palidez",
  pupilary_problems: "Problemas pupilares",
  rapid_slow_blinking: "Parpadeo rápido / lento",
  redness_of_eye: "Enrojecimiento del ojo",
  eye_swelling_lump: "Ojo - Hinchazón / Bulto",
  trauma_infection: "Trauma / Infección",
  gangrene: "Gangrena",
  abnormal_discharge_to_be_pouplated_based_on_gender:
    "Secreción anormal (a ser poblada según género)",
  abnormal_urine_flow_voiding: "Orina / Flujo / Micción anormal",
  bleeding_: "Sangrado",
  difficulty_urination: "Dificultad para orinar",
  foul_smelling_urine: "Orina con mal olor",
  fullness_pressure_prolapse: "Sensación de llenura / Presión / Prolapso",
  increased_decreased_output: "Aumento / Disminución de la producción",
  itching_dryness: "Picazón / Sequedad",
  menstrual_problems: "Problemas menstruales",
  other_genito_urinary_problem: "Otros problemas genitourinarios",
  genito_urinary_pain_tenderness: "Dolor genitourinario / Sensibilidad",
  genito_urinary_swelling_lump: "Genitourinario - Hinchazón / Bulto",
  urinary_incontinence: "Incontinencia urinaria",
  urine_discoloration: "Descoloración de la orina",
  discoloration_of_nails: "Descoloración de las uñas",
  excessive_body_hair: "Exceso de vello corporal",
  hair_loss: "Pérdida de cabello",
  nail_abnormality: "Anomalía en las uñas",
  other_hair_problems: "Otros problemas capilares",
  other_nail_problems: "Otros problemas de uñas",
  nail_swelling_lump: "Uña - Hinchazón / Bulto",
  hiccups: "Hipo",
  dislocation: "Luxación",
  fracture: "Fractura",
  injury: "Lesión",
  trauma_injury: "Trauma / Lesión",
  bad_breath: "Mal aliento",
  discoloration: "Descoloración",
  dry_mouth: "Boca seca",
  lip_problem: "Problema labial",
  lip_problems: "Problemas labiales",
  other_mouth_dental_problems: "Otros problemas bucales / dentales",
  mouth_or_dental_pain_tenderness: "Dolor o sensibilidad bucal o dental",
  redness_inflammation: "Enrojecimiento / Inflamación",
  mouth_swelling_lump: "Boca - Hinchazón / Bulto",
  tongue_problems: "Problemas en la lengua",
  tooth_problems: "Problemas dentales",
  uncontrolled_excessive_salivation: "Salivación incontrolada / excesiva",
  abnormal_muscle_tone_reflex_flexibility:
    "Tono muscular / Reflejo / Flexibilidad anormal",
  abnormal_position: "Posición anormal",
  abnormal_sounds_joints: "Sonidos anormales - Articulaciones",
  hip_joint_problem: "Problema en la articulación de la cadera",
  instability_weakness: "Inestabilidad / Debilidad",
  joint_swelling: "Hinchazón en las articulaciones",
  muscle_atrophy_wasting: "Atrofia muscular / Desgaste",
  muscle_hypertrophy_muscle_growth:
    "Hipertrofia muscular / Crecimiento muscular",
  muscle_spasm: "Espasmo muscular",
  other_joint_problems: "Otros problemas articulares",
  other_musculoskeletal_problems: "Otros problemas musculoesqueléticos",
  musculostekeletal_pain_tenderness: "Dolor o sensibilidad musculoesquelética",
  restricted_joint_movement_stiffness:
    "Movimiento articular restringido / Rigidez",
  nausea: "Náuseas",
  vomiting: "Vómitos",
  congestion: "Congestión",
  dry_throat: "Garganta seca",
  sore_throat_pain_tenderness: "Dolor de garganta / Sensibilidad",
  stiff_neck: "Rigidez en el cuello",
  neck_or_throat_swelling_lump: "Cuello o garganta - Hinchazón / Bulto",
  voice_problems: "Problemas de la voz",
  other_nose_problems: "Otros problemas nasales",
  other_non_specific_bleeding: "Otros sangrados inespecíficos",
  abdominal_pain: "Dolor abdominal",
  ankle: "Tobillo",
  anorectal_region: "Región anorrectal",
  chest_pain: "Dolor en el pecho",
  facial_pain: "Dolor facial",
  generalised_back_pain: "Dolor generalizado en la espalda",
  generalised_pain: "Dolor generalizado",
  headache: "Dolor de cabeza",
  hip_pain: "Dolor de cadera",
  insect_stings_or_bites: "Picaduras o mordeduras de insectos",
  jaw_pain: "Dolor en la mandíbula",
  limb_pain: "Dolor en las extremidades",
  localised_back_pain: "Dolor localizado en la espalda",
  localised_pain: "Dolor localizado",
  neck_pain: "Dolor de cuello",
  other_pain_tenderness: "Otro dolor / sensibilidad",
  pain_on_chewing: "Dolor al masticar",
  pelvis_groin_pain: "Dolor en la pelvis / ingle",
  heart_rate_increased_decreased_irregular:
    "Frecuencia cardíaca - Aumentada / Disminuida / Irregular",
  other_heart_problems: "Otros problemas cardíacos",
  paralysis: "Parálisis",
  paresis: "Paresia",
  abdomen: "Abdomen",
  chest_abnormal_breathing_sounds:
    "Sonidos respiratorios anormales en el pecho",
  female_genitals: "Genitales femeninos",
  fundoscopic_examination_eye: "Examen fondo de ojo",
  genito_urinary_examination: "Examen genitourinario",
  heart_abnormal_heart_sound: "Corazón - Sonido cardíaco anormal",
  intraocular_pressure_eye: "Presión intraocular - Ojo",
  ophthalmoscopy_eye: "Oftalmoscopía - Ojo",
  other_physical_examination: "Otro examen físico",
  otoscopy_ear: "Otoscopía - Oído",
  percussion: "Percusión",
  pregnancy: "Embarazo",
  pregnancy_problems: "Problemas de embarazo",
  pulse_faint: "Pulso - Débil",
  pulse_irregular: "Pulso - Irregular",
  abnormal_skin: "Piel anormal",
  blister: "Ampolla",
  bluishness_of_skin: "Cianosis (Piel azulada)",
  bruise: "Hematoma",
  eye: "Ojo",
  mole_pimples: "Lunar / Espinillas",
  mouth_dental_lesions_ulcers: "Lesiones / Úlceras bucales o dentales",
  rash: "Erupción",
  reddening_of_skin: "Enrojecimiento de la piel",
  scaling: "Descamación",
  scalp: "Cuero cabelludo",
  scar: "Cicatriz",
  skin_swelling_lump: "Piel - Hinchazón / Bulto",
  ulcer: "Úlcera",
  white_spots: "Manchas blancas",
  xanthoma: "Xantoma",
  yellowishness_of_skin: "Ictericia (Piel amarillenta)",
  abnormal_sexual_desire: "Deseo sexual anormal",
  homosexuality: "Homosexualidad",
  other_sexual_problems: "Otros problemas sexuales",
  drowsiness: "Somnolencia",
  sleep_disturbance: "Alteración del sueño",
  snoring: "Ronquidos",
  sneezing: "Estornudos",
  speech_impairment: "Alteración del habla",
  sweating: "Sudoración",
  other_swelling_lump: "Otra hinchazón / bulto",
  other_swelling_lump_unspecified: "Otra hinchazón / bulto - No especificado",
  swelling_lump_lymph_node: "Hinchazón / bulto - Ganglio linfático",
  generalized_weakness: "Debilidad generalizada",
  malaise: "Malestar",
  weakness: "Debilidad",
  weight_gain_obesity: "Aumento de peso / Obesidad",
  weight_loss: "Pérdida de peso",

  first_level_symptoms: "Síntomas de primer nivel",
  please_click_on_the_body_part_where_you_are_experiencing_problems:
    "Haga clic en la parte del cuerpo donde está experimentando problemas.",
  second_level_symptoms: "Síntomas de segundo nivel",
  please_click_on_the_first_level_symptoms:
    "Haga clic en los síntomas de primer nivel.",
  select_final_symptoms: "Seleccione los síntomas finales",
  select_visual_symptoms: "Seleccione los síntomas visuales",
  at_least_one_of_the_following_must_be_provided_investigation_medication_parameter_lifestyle_or_procedure:
    "Se debe proporcionar al menos uno de los siguientes: investigación, medicación, parámetro, estilo de vida o procedimiento.",
  select_duration: "Seleccionar duración",
  along_with: "Junto con",

  //task

  outgoing_task_overdue: "Tarea Saliente Atrasada",
  outgoing_task_delayed: "Tarea Saliente Retrasada",
  outgoing_task_on_time: "Tarea Saliente a Tiempo",
  outgoing_task_before_time: "Tarea Saliente Antes de Tiempo",
  incoming_task_overdue: "Tarea Entrante Atrasada",
  incoming_task_delayed: "Tarea Entrante Retrasada",
  incoming_task_on_time: "Tarea Entrante a Tiempo",
  incoming_task_complete_before_time:
    "Tarea Entrante Completada Antes de Tiempo",

  taskListHeader: "Crear una nueva reunión para {{taskName}}",
  alongWith: "Junto con",
  please_click_on_the_second_level_symptoms:
    "Por favor, haga clic en los síntomas de segundo nivel.",
  add_symtoms: "Agregar síntomas",
  visual_symptoms: "Síntomas Visuales",
  all_infographics_are_for_illustrative_purposes_only_and_do_not_represent_real_images_or_data:
    "Todos los infografías son solo para fines ilustrativos y no representan imágenes o datos reales.",
  non_medicational_instruction: "Instrucción no medicamentosa",
  medicational_instruction: "Instrucción medicamentosa",
  remove_regime: "Eliminar régimen",
  extract_text_pdf_images_docx_pptx_formats_are_supported:
    "Extraer texto (se admiten formatos pdf, imágenes, docx, pptx)",
  please_fill_corresponding_address_two:
    "Por favor, complete la dirección correspondiente 2",
  please_fill_corresponding_pin:
    "Por favor, complete el código postal correspondiente",
  please_provide_atleast_one_document:
    "Por favor proporcione al menos un documento",
  m: "M",
  g: "G",
  w: "F",
  h: "V",
  l: "L",
  right_click_is_disabled_on_this_page:
    "El clic derecho está deshabilitado en esta página.",
  developer_tools_are_disabled:
    "Las herramientas de desarrollador están desactivadas!",
  saving_this_page_is_disabled: "¡Guardar esta página está deshabilitado!",
  screenshot_functionality_is_restricted:
    "¡La funcionalidad de captura de pantalla está restringida!",
  you_cannot_close_the_drive_until_the_file_upload_is_complete:
    "No puede cerrar la unidad hasta que se complete la carga del archivo.",
  priority_levels_chart: "Cuadro de niveles de prioridad",
  payment_method_selected: "Método de Pago Seleccionado",
  video_call_in_progress: "Videollamada en curso",
  view_stp: "Ver STP",
  mail_id_already_exists: "El ID de correo ya existe",
  biannually: "Semestral",
  annually_consult: "Anual",
  by_acknowledging_and_accepting_all_the_terms_and_conditions_outlined_in_the_above_policies_i_understand_that_any_violation_may_subject_me_to_penalties_as_determined_by_the_organizations_policies_and_applicable_laws:
    "Al reconocer y aceptar todos los términos y condiciones descritos en las políticas anteriores, entiendo que cualquier infracción puede exponerme a sanciones según lo determinen las políticas de la organización y las leyes aplicables.",
  this_is_the_section_where_a_doctor_can_see_appointment_details:
    "Esta es la sección donde un médico puede ver los detalles de las citas.",
  click_here_to_refresh_dashboard_data_and_view_updates:
    "Haz clic aquí para actualizar los datos del panel y ver las últimas actualizaciones.",
  this_chart_shows_breakdown_of_hourly_appointments:
    "Este gráfico muestra un desglose de las citas por hora de hoy.",
  this_number_represents_total_appointments_booked_today:
    "Este número representa el total de citas reservadas hoy.",
  this_bar_chart_displays_available_and_booked_slots_over_time:
    "Este gráfico de barras muestra el número de espacios disponibles y reservados a lo largo del tiempo.",
  click_here_to_view_detailed_appointment_information:
    "Haz clic aquí para ver información detallada de las citas.",
  use_this_button_to_adjust_appointment_booking_settings:
    "Usa este botón para ajustar la configuración de reservas de citas.",
  tab_org_step_one:
    "Aquí puedes ver todos los detalles de la organización, como la dirección de la oficina registrada, el correo electrónico de la organización, el número de contacto de la organización, el tipo de organización, la fecha de constitución y más.",
  tab_org_step_two:
    "Al hacer clic en este botón, puedes editar los detalles de la organización.",
  this_section_displays_all_the_units_in_your_organization_positions:
    "Esta sección muestra todas las unidades en los puestos de tu organización.",
  this_section_allows_you_to_select_a_unit:
    "Esta sección te permite seleccionar una unidad.",
  this_section_allows_you_to_add_a_designation:
    "Esta sección te permite agregar una designación.",
  you_can_add_edit_or_view_departments_here:
    "Aquí puedes agregar, editar o ver departamentos.",
  you_can_select_a_department_from_here:
    "Puedes seleccionar un departamento desde aquí.",
  you_can_select_the_reporting_head_from_here:
    "Puedes seleccionar al jefe de reporte desde aquí.",
  you_can_add_a_new_ctc_band_from_here:
    "Puedes agregar una nueva banda de CTC desde aquí.",
  you_can_select_a_ctc_band_from_here:
    "Puedes seleccionar una banda de CTC desde aquí.",
  you_can_view_job_description_details_here:
    "Puedes ver los detalles de la descripción del trabajo aquí.",
  you_can_add_a_kra_here: "Puedes agregar un KRA aquí.",
  you_can_add_a_new_position_save_a_new_position_or_delete_a_position_here:
    "Puedes agregar un nuevo puesto, guardar un nuevo puesto o eliminar un puesto aquí.",
  this_is_where_you_can_choose_a_website_template_for_your_organization:
    "Aquí es donde puedes elegir una plantilla de sitio web para tu organización.",
  select_the_template_that_best_suits_your_organization_needs_the_selected_template_will_be_applied_to_your_website:
    "Selecciona la plantilla que mejor se adapte a las necesidades de tu organización. La plantilla seleccionada se aplicará a tu sitio web.",
  you_can_edit_the_selected_template_by_clicking_this_icon_this_will_open_the_template_builder_in_a_new_tab:
    "Puedes editar la plantilla seleccionada haciendo clic en este ícono. Esto abrirá el creador de plantillas en una nueva pestaña.",
  this_badge_indicates_that_the_template_is_already_selected_you_can_proceed_with_editing_or_view_a_demo:
    "Esta insignia indica que la plantilla ya está seleccionada. Puedes proceder con la edición o ver una demostración.",
  click_this_button_to_select_a_template_if_you_are_an_admin_a_confirmation_dialog_will_appear_to_confirm_your_selection:
    "Haz clic en este botón para seleccionar una plantilla. Si eres administrador, aparecerá un cuadro de diálogo de confirmación para confirmar tu selección.",
  click_on_the_demo_button_to_see_a_live_preview_of_the_template_before_making_your_selection:
    "Haz clic en el botón de demostración para ver una vista previa en vivo de la plantilla antes de realizar tu selección.",
  this_is_where_you_can_add_a_new_stp:
    "Aquí es donde puedes agregar un nuevo STP.",
  click_here_to_close_the_add_new_stp_section:
    "Haz clic aquí para cerrar la sección 'Agregar nuevo STP'.",
  start_by_selecting_a_disease_along_with_its_icd_code_from_the_list:
    "Comienza seleccionando una enfermedad junto con su código ICD-11 de la lista.",
  here_you_can_select_the_required_investigations:
    "Aquí puedes seleccionar las investigaciones requeridas.",
  here_you_can_select_the_gender: "Aquí puedes seleccionar el género.",
  click_the_add_button_to_add_investigations:
    "Haz clic en el botón 'Agregar' para añadir investigaciones.",
  select_a_regime_from_here: "Selecciona un régimen desde aquí.",
  select_all_age_groups_from_here:
    "Selecciona todos los grupos de edad desde aquí.",
  select_the_gender_from_here: "Selecciona el género desde aquí.",
  enter_non_medication_instructions_here:
    "Introduce aquí las instrucciones no relacionadas con medicamentos.",
  add_all_compounds_accordingly:
    "Agrega todos los compuestos según corresponda.",
  enter_the_parameters_to_be_monitored_here_you_can_select_from_suggestions_or_add_new_ones:
    "Introduce los parámetros a monitorear aquí. Puedes seleccionar de las sugerencias o agregar nuevos.",
  click_here_to_save_or_add_the_selected_parameters_to_be_monitored:
    "Haz clic aquí para guardar o añadir los parámetros seleccionados a monitorear.",
  enter_the_lifestyle_recommendations_here_you_can_select_from_suggestions:
    "Introduce las recomendaciones de estilo de vida aquí. Puedes seleccionar de las sugerencias.",
  click_here_to_save_or_add_the_selected_lifestyle_recommendations:
    "Haz clic aquí para guardar o añadir las recomendaciones de estilo de vida seleccionadas.",
  enter_the_procedure_name_here_you_can_select_from_suggestions:
    "Introduce el nombre del procedimiento aquí. Puedes seleccionar de las sugerencias.",
  please_select_the_gender_for_the_procedure:
    "Por favor, selecciona el género para el procedimiento.",
  click_here_to_save_or_add_the_selected_procedure:
    "Haz clic aquí para guardar o añadir el procedimiento seleccionado.",
  finally_click_here_to_save_or_add_all_the_user_inputs:
    "Finalmente, haz clic aquí para guardar o añadir todas las entradas del usuario.",
  apps_dashboard_instructions: "Por favor, haz clic en el panel de control.",
  website_walkthrough: "Recorrido por el Sitio Web",
  enter_recurring_event_title_here:
    "Ingrese el título del evento recurrente aquí.",
  specify_event_location_here: "Especifique la ubicación del evento.",
  select_event_days: "Seleccione los días en que se llevará a cabo el evento.",
  set_event_start_time: "Establezca la hora de inicio de su evento.",
  set_event_end_time: "Establezca la hora de finalización de su evento.",
  repeat_event_upto_this_date: "Repita el evento hasta esta fecha.",
  add_guests_to_event_here:
    "Agregue invitados a su evento aquí. Puede seleccionar múltiples participantes.",
  dose_per_admin: "Dosis (por administración)",
  maximum_dose_per_day: "Dosis máxima por día",
  you_must_provide_frequency_when_providing_dose:
    "Debe proporcionar la frecuencia al proporcionar la dosis",
  per_day_intake_is_greater_than_maximum_allowed_intake:
    "La ingesta diaria es mayor que la ingesta máxima permitida",
};
