import { FormControl, IconButton, MenuItem, Select } from "@mui/material";
import React, { useState, useEffect } from "react";
import { ArrowRepeat } from "react-bootstrap-icons";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import { Bar, Chart, Doughnut, Line, Pie } from "react-chartjs-2";

import "../../../css/websiteStatusCard.css";
import { Web, WebTwoTone } from "@mui/icons-material";
import Button from "../../../../RTE/ui/Button";
import miscService from "../../../../../services/MiscService";
import DateUtils from "../../../../../utils/DateUtils";
import CardWebsiteStatusLineGraph from "./CardWebsiteStatusLineGraph";
import {
  format,
  subDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
} from "date-fns";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../../common/TourProvider";

export default function CardWebsiteStatus() {
  const { t } = useTranslation();
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );

  const pieOptions = {
    responsive: true,

    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [pieData, setPieData] = useState(null);
  const [websiteStatus, setWebsiteStatus] = useState(null);
  useEffect(() => {
    // console.log("getMassaludWebsiteStatus1");
    miscService.getMassaludWebsiteStatus().then((response) => {
      // console.log("getMassaludWebsiteStatus", response.data);

      if (response && response.data) {
        const monitor = response.data.find(
          (item) => item.url === "https://massalud.zoyel.health"
        );
        if (monitor) {
          setWebsiteStatus(monitor);
          // console.log("getMassaludWebsiteStatus", monitor?.uptime);
          // const uptime = parseFloat(parseFloat(monitor?.uptime) * 100).toFixed(
          //   2
          // );
          // console.log(
          //   "getMassaludWebsiteStatus",
          //   uptime,
          //   parseFloat(100 - uptime).toFixed(2)
          // );
          // setPieData({
          //   labels: ["Uptime", "Downtime"],
          //   datasets: [
          //     {
          //       label: "Website Status",
          //       data: [uptime, parseFloat(100 - uptime).toFixed(2)],
          //       backgroundColor: [
          //         "rgba(75, 192, 192, 0.2)",
          //         "rgba(255, 99, 132, 0.2)",
          //       ],
          //       borderColor: [
          //         "rgba(75, 192, 192, 0.2)",
          //         "rgba(255, 99, 132, 0.2)",
          //       ],
          //       borderWidth: 2,
          //     },
          //   ],
          // });
        }
      }
    });
    // fetchWebsiteSnapshotData();
  }, []);

  // useEffect(()=>{
  //  setBookSteps([
  //   {
  //     target: ".msResponseTime",
  //     content: "This section displays card management response time of massalud.zoyel.health.",
  //     disableBeacon: true,
  //   },
  //     {
  //       target: ".msResponseTimeOne",
  //       content: "Click here to view the detailed status page for the Massalud website.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".msResponseTimeTwo",
  //       content: "Select a time range to view the website's performance over that period.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".msResponseTimeThree",
  //       content: "This card displays the average response time of the website for the selected time range.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".msResponseTimeFour",
  //       content: "This card displays the average response time of the website for the selected time range.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".msResponseTimeFive",
  //       content: "This graph shows the response times over time for the selected nodes.",
  //       disableBeacon: true,
  //     },
  //   ])
  // },[])

  useEffect(() => {
    // const { t } = useTranslation();
  
    setBookSteps([
      {
        target: ".msResponseTime",
        content: t('ms_response_time'),
        disableBeacon: true,
      },
      {
        target: ".msResponseTimeOne",
        content: t('ms_response_time_one'),
        disableBeacon: true,
      },
      {
        target: ".msResponseTimeTwo",
        content: t('ms_response_time_two'),
        disableBeacon: true,
      },
      {
        target: ".msResponseTimeThree",
        content: t('ms_response_time_three'),
        disableBeacon: true,
      },
      {
        target: ".msResponseTimeFour",
        content: t('ms_response_time_four'),
        disableBeacon: true,
      },
      {
        target: ".msResponseTimeFive",
        content: t('ms_response_time_five'),
        disableBeacon: true,
      },
    ]);
  }, []);

  const [websiteSnapshotData, setWebsiteSnapshotData] = useState(null);
  const [selectedRange, setSelectedRange] = useState("Today");

  const [dataRangeOverallAvg, setDataRangeOverallAvg] = useState(null);
  const [responseTimeCurrent, setResponseTimeCurrent] = useState(null);

  const fetchWebsiteSnapshotData = (range) => {
    const dateRange = calculateDateRange(range);
    let reqDto = { field1: dateRange.field1, field2: dateRange.field2 };
    console.log("fetchWebsiteSnapshotData", reqDto);
    miscService.getMassaludWebsiteSnapshotData(reqDto).then((response) => {
      if (response && response.data) {
        const filtered = response.data.filter((dataItem) =>
          ["sao-paulo", "los-angeles", "new_york"].includes(dataItem.node)
        );
        console.log("filtered websitesnapshot::", filtered);
        setWebsiteSnapshotData(filtered);
        const totalSum = filtered.reduce((acc, snapshot) => {
          const avgTimeTotal = parseFloat(snapshot.average_time_total);
          return acc + (isNaN(avgTimeTotal) ? 0 : avgTimeTotal);
        }, 0);

        // Step 2: Calculate the overall average
        const overallAverage = totalSum / filtered.length;

        console.log("overallAverage", overallAverage);

        setDataRangeOverallAvg(overallAverage);
      }
    });
  };

  useEffect(() => {
    fetchWebsiteSnapshotData(selectedRange);
  }, [selectedRange]);

  const calculateDateRange = (range) => {
    const now = new Date();
    let startDate, endDate;

    switch (selectedRange) {
      case "Today":
        startDate = new Date(now.setHours(0, 0, 0, 0));
        endDate = new Date(now.setHours(23, 59, 59, 999));
        break;
      case "Yesterday":
        startDate = new Date(now.setDate(now.getDate() - 1));
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(now.setHours(23, 59, 59, 999));
        break;
      case "Last 7 Days":
        startDate = new Date(now.setDate(now.getDate() - 7));
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        break;
      case "This Week":
        startDate = new Date(now.setDate(now.getDate() - now.getDay()));
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        break;
      case "Last Week":
        const lastWeekStart = new Date(
          now.setDate(now.getDate() - now.getDay() - 7)
        );
        startDate = new Date(lastWeekStart.setHours(0, 0, 0, 0));
        endDate = new Date(now.setDate(lastWeekStart.getDate() + 6));
        endDate.setHours(23, 59, 59, 999);
        break;
      case "This Month":
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        break;
      case "Last Month":
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(now.getFullYear(), now.getMonth(), 0);
        endDate.setHours(23, 59, 59, 999);
        break;
      case "Last 30 Days":
        startDate = new Date(now.setDate(now.getDate() - 30));
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        break;
      case "All Time":
        startDate = new Date(2024, 11, 1, 0, 0, 0, 0);
        endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        break;
      default:
        return;
    }

    return {
      field1: DateUtils.getDateInYYYYMMDDHH24MMForwebsiteStatus(startDate),
      field2: DateUtils.getDateInYYYYMMDDHH24MMForwebsiteStatus(endDate),
    };
  };

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
     <Joyride
        steps={booksteps}
        run={run["manageresponseTimeMassaludAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        styles={{
          overlay: {
            height: "100vh",
          },
        }}
        locale={{
          back: t('back'),
          last: t('finish'),
          next: t('next'),
          skip: t('skip'),
        }}
      />
      <div className="anDashIndCard manageWidth50 msResponseTime">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">
              {t("response_time")} massalud.zoyel.health
              {/* <IconButton
                className={`refreshIcon`}
                // onClick={() => fetchRefreshManagementData()}
              >
                <ArrowRepeat />
              </IconButton> */}
            </div>
            <div className="andCrdUnitCount cardFrom">
              {/* <IconButton className={`refreshIcon`} title="View Web page">
                <WebTwoTone />
              </IconButton> */}
              <FormControl size="small" className="formControl">
                <Button
                  onClick={() => {
                    window.open(
                      "https://status-massalud.zoyel.health/",
                      "_blank"
                    );
                  }}
                  className="dfultPrimaryBtn websiteStatusPgBtn msResponseTimeOne"
                >
                  {t("Status_Page")}
                </Button>
              </FormControl>

              <FormControl size="small" className="formControl w110 msResponseTimeTwo">
                <Select
                  className="formInputField"
                  labelId="filterBy"
                  id="dateRange"
                  value={selectedRange}
                  onChange={(e) => setSelectedRange(e.target.value)}
                >
                  <MenuItem value="Today">{t("Today")}</MenuItem>
                  <MenuItem value="Yesterday">{t("Yesterday")}</MenuItem>
                  <MenuItem value="Last 7 Days">{t("Last_7_Days")}</MenuItem>
                  <MenuItem value="This Week">{t("This_Week")}</MenuItem>
                  <MenuItem value="Last Week">{t("Last_Week")}</MenuItem>
                  <MenuItem value="This Month">{t("This_Month")}</MenuItem>
                  <MenuItem value="Last Month">{t("Last_Month")}</MenuItem>
                  <MenuItem value="Last 30 Days">{t("Last_30_Days")}</MenuItem>
                  <MenuItem value="All Time">{t("All_Time")}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="dashboard-container">
            <div className="dashboard-left">
              <div className="graph-placeholder msResponseTimeFive">
                {websiteSnapshotData && websiteSnapshotData.length > 0 && (
                  <CardWebsiteStatusLineGraph data={websiteSnapshotData} />
                )}
              </div>
            </div>
            <div className="dashboard-right">
              <div className="data-card">
                {dataRangeOverallAvg && (
                  <div className="website-status-card-parent msResponseTimeThree">
                    <div className="website-status-card">
                      <p className="website-status-card__title">
                        {t("Avg_Response_Time")}
                      </p>
                      <div className="website-status-card__value ">
                        <span className="website-status-card__time">
                          {dataRangeOverallAvg?.toFixed(2)}
                        </span>
                        <div className="website-status-card__unit_status">
                          <span className="website-status-card__unit">
                            {t("Seconds")}
                          </span>

                          <span
                            className={`website-status-card__status ${
                              websiteStatus?.status === "online"
                                ? "status-online"
                                : "status-offline"
                            }`}
                          >
                            {websiteStatus?.status === "online"
                              ? t("Online")
                              : t("Offline")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="data-card">
                {websiteStatus &&
                  websiteStatus.response_time &&
                  websiteStatus?.response_time && (
                    <div className="website-status-card-parent msResponseTimeFour">
                      <div className="website-status-card">
                        <p className="website-status-card__title">
                          {t("Response_Time")}
                        </p>
                        <div className="website-status-card__value ">
                          <span className="website-status-card__time">
                            {websiteStatus &&
                              websiteStatus.response_time &&
                              parseFloat(websiteStatus?.response_time).toFixed(
                                2
                              )}
                          </span>
                          <div className="website-status-card__unit_status">
                            <span className="website-status-card__unit_response_time">
                              {t("Seconds")}
                            </span>
                            <span className="website-status-card__status lastChecked">
                              {websiteStatus &&
                                websiteStatus.checked_at &&
                                DateUtils.getDateInDDMMYYYYHH12MMSSMonthNameWebsiteResponseTime(
                                  websiteStatus.checked_at
                                )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
