import React, { useContext } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";

import {
  Accessible,
  Add,
  ManageHistoryRounded,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { Bar, Pie } from "react-chartjs-2";
import { ArrowRepeat, Eye } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { BookAppointmentFromNurseStep } from "../../../../../common/tour_step/BookAppointmentFromNurseStep";
import { ViewAppointmentFromNurseStep } from "../../../../../common/tour_step/ViewAppointmentFromNurseStep";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";
import { useState } from "react";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import { useEffect } from "react";
import { refreshUnitAppointment } from "../../../../../services/AdminService";

export default function CardBookAppointment({
  handelOpenViewBookedAppointmentConsultation,
  handelOpenViewBookAppointmentDrList,
  chartDataOfItem,
  // fetchDashBoardData,
}) {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );

  const { t } = useTranslation();
  const userDetails = GetLoginUserDetails();
  const [bookAppointmentData , setBookAppointmentData]= useState({})
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  
  useEffect(() => {
    setBookAppointmentData(chartDataOfItem || {});
  }, [chartDataOfItem]);

  useEffect(() => {
    if (!isFirstLoad){
      fetchBookAppointmentData();
    }
  }, []);
  

  const fetchBookAppointmentData = () => {
    const reqDto = {
      appointmentUnitId: userDetails.signleUnitId,
      // orgId: userDetails.orgId,
      // userId: userDetails.userId,
      periodDailyMnthly: "",
      // dataLimit: 0,
      feedbackFromPatientDoctor: "",
      fromDate: "",
      toDate: "",
      unitDoctor: "",
      // holidayOrgId: userDetails.orgId,
    };
    refreshUnitAppointment(reqDto).then((response) => {
      console.log("response", response.data);
      setBookAppointmentData(response.data);
    });
  };



  const data = {
    // labels: ["Completed", "Queue", "New", "Review"],
    labels:
      bookAppointmentData.todayDoctorWiseUnitAppointment?.length > 0
        ? bookAppointmentData.todayDoctorWiseUnitAppointment.map(
            (item) => item.label
          )
        : [],
    datasets: [
      {
        label: t("count"),
        // data: [12, 19, 3, 5],
        data:
          bookAppointmentData.todayDoctorWiseUnitAppointment?.length > 0
            ? bookAppointmentData.todayDoctorWiseUnitAppointment.map(
                (item) => item.count
              )
            : [],
        backgroundColor: [
          "rgba(79, 212, 0, 1)",
          "rgba(234, 184, 0, 1)",
          "rgba(241, 61, 60, 1)",
          "rgba(254, 162, 59, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, .6)",
          "rgba(255, 255, 255, .6)",
          "rgba(255, 255, 255, .6)",
          "rgba(255, 255, 255, .6)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: "80%",
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  const barLineData = {
    // labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    labels:
      bookAppointmentData?.todayHourlyUnitAppointment?.length > 0
        ? bookAppointmentData.todayHourlyUnitAppointment.map((item) => item.label)
        : [],
    datasets: [
      {
        label: t("appointment"),
        // data: [12, 19, 3, 5, 2, 3],
        data:
          bookAppointmentData?.todayHourlyUnitAppointment?.length > 0
            ? bookAppointmentData.todayHourlyUnitAppointment.map(
                (item) => item.count
              )
            : [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const barLineOoptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  const [bookOrViewAppointMentNS, setBookOrViewAppointMentNS] = useState([]);

  useEffect(() => {
    setBookOrViewAppointMentNS([
      {
        target: ".nsAppointment",
        content: t("you_can_book_a_doctor_appointment_by_clicking_the_appointment_button_and_view_all_appointments_by_clicking_the_view_appointment_button"),
        disableBeacon: true,
      },
      {
        target: ".nsrefresh",
        content: t("click_here_to_refresh_the_dashboard_and_fetch_the_latest_data"),
        disableBeacon: true,
      },
      {
        target: ".nsAppointmentStepOne",
        content: t("this_chart_displays_todays_unit_wise_appointments_hover_over_the_segments_to_view_details"),
        disableBeacon: true,
      },
      {
        target: ".nsAppointmentStepTwo",
        content: t("this_bar_chart_shows_the_hourly_distribution_of_todays_appointments"),
        disableBeacon: true,
      },
      {
        target: ".nsDataCount",
        content: t("this_shows_the_total_number_of_appointments_for_the_day"),
        disableBeacon: true,
      },
      {
        target: ".nsAppointmentStepThree",
        content: t("click_here_to_view_all_booked_appointments_and_their_details"),
        disableBeacon: true,
      },
      {
        target: ".nsAppointmentStepFour",
        content: t("click_here_to_book_a_new_appointment_with_a_doctor"),
        disableBeacon: true,
      },
    ]);
  }, [t]);

  // const bookOrViewAppointMentNS = [
  //   {
  //     target: ".nsAppointment",
  //     content:
  //       "You can book a doctor appointment by clicking the 'Appointment' button and view all appointments by clicking the 'View Appointment' button.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".nsrefresh",
  //     content: "Click here to refresh the dashboard and fetch the latest data.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".nsAppointmentStepOne",
  //     content:
  //       "This chart displays today's unit-wise appointments. Hover over the segments to view details.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".nsAppointmentStepTwo",
  //     content:
  //       "This bar chart shows the hourly distribution of today's appointments.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".nsDataCount",
  //     content: "This shows the total number of appointments for the day.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".nsAppointmentStepThree",
  //     content: "Click here to view all booked appointments and their details.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".nsAppointmentStepFour",
  //     content: "Click here to book a new appointment with a doctor.",
  //     disableBeacon: true,
  //   },
  // ];

  const { steps, run, setRun } = useContext(TourContext);
  console.log("useContext", run, run["bookOrViewAppointMentNS"] === true);

  const bookAppointmentComp = () => {
    handelOpenViewBookAppointmentDrList();
    if (run["bookOrViewAppointMentNS"] === true) {
      setRun({
        bookappointNs: true,
      });
    }
  };

  const viewAppointmentComp = () => {
    handelOpenViewBookedAppointmentConsultation();
    if (run["bookOrViewAppointMentNS"] === true) {
      setRun({
        viewappointNs: true,
      });
    }
  };

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={bookOrViewAppointMentNS}
        run={run["bookOrViewAppointMentNS"] === true}
        continuous={true}
        showSkipButton={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        styles={{
          overlay: {
            height: "100vh",
          },
        }}
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
      />
      <div className="anDashIndCard nsAppointment">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">
              {t("book_appointment")}
              <IconButton
                className={`refreshIcon nsrefresh`}
                onClick={()=>{setIsFirstLoad(false); fetchBookAppointmentData()}}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount">
              <span title="Attrition">
                {/* <Male /> */}
                {/* This Month: <span className="fbold"> 215421</span> */}
              </span>

              <span title="Tenure">
                {/* <Female /> */}
                {/* This week: <span className="fbold">12154</span> */}
              </span>
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf nsAppointmentStepOne">
              <div className="centTxtShow">
                <Pie
                  className="hw100"
                  type="doughnut"
                  data={data}
                  options={options}
                />
                <div className="highlighted-text">
                  {/* <span>Total</span> */}
                  <span className="highDataCount nsDataCount">
                    {bookAppointmentData.todayUnitAppointmentCount}
                  </span>
                </div>
              </div>
            </div>
            <div className="anDRightGrf nsAppointmentStepTwo">
              <Bar
                className="hw100"
                type="bar"
                data={barLineData}
                options={barLineOoptions}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>

          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            <Button
              className="dfultPrimaryBtn nsAppointmentStepThree"
              startIcon={<Eye />}
              // onClick={() => {handelOpenViewBookedAppointmentConsultation()}}
              onClick={viewAppointmentComp}
            >
              {t("view_appointment")}
            </Button>
            <Button
              className="dfultPrimaryBtn nsAppointmentStepFour"
              startIcon={<Accessible />}
              onClick={() => bookAppointmentComp()}
            >
              {t("appointment")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
