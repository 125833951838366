import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Trash } from "react-bootstrap-icons";
import AddCompound from "./AddCompound";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { CustomModal } from "../../../../task/view/component/Modals/CustomModal";
import IntegerTextField from "../../../../../common/IntegerTextField";
import { getHealthDto } from "../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import DashboardServices from "../../../../../services/DashboardServices";
import SecureIndexedDB from "../../../../../utils/IndexedDB";
import { Edit } from "@mui/icons-material";

export const CompoundItem = ({
  compoundItem,
  compoundType,
  parentId,
  updateCompound,
  compoundList,
  isActionable = true,
  mode = "normal",
}) => {
  const { t } = useTranslation();
  const [showDurationModule, setShowDurationModule] = useState(false);
  const classMap = { or: "compOr", then: "compThen", alongWith: "compAnd" };
  const [durationList, setDurationList] = useState([]);
  const [orThen, setOrThen] = useState("");

  const getDurations = async () => {
    const diseasetring = await SecureIndexedDB.getItem("durationList");
    if (diseasetring) {
      const tempDuration = JSON.parse(diseasetring);
      setDurationList(tempDuration);
      return tempDuration;
    }

    const reqDto = await getHealthDto();
    const response = await DashboardServices.getDurations(reqDto);
    if (response.data) {
      setDurationList(response.data);
      SecureIndexedDB.setItem("durationList", JSON.stringify(response.data));
      return response.data;
    }
  };
  useEffect(() => {
    getDurations();
  }, []);

  return (
    <>
      <div
        className={`compoundIndv ${compoundType ? classMap[compoundType] : ""}`}
      >
        {compoundType && (
          <div className="compChild">
            {t(compoundType)}
            {/* {compoundType === "or"
              ? t("or")
              : compoundType === "then"
              ? t("then")
              : t("and")} */}
          </div>
        )}
        {/* {!compoundItem.isEditing && (
        )} */}
        <div className="compfulDtl">
          <div className="compHeader">
            <div className="compName">
              {compoundItem?.compound?.compoundName}
            </div>
            {isActionable && (
              <div className="orThenCompGrp">
                <div className="formElement">
                  <FormControl className="formControl">
                    <RadioGroup
                      className="formRadioGroup"
                      labelId="setTasktype"
                      name="regimeCondition"
                      // value={orThen}
                      value={compoundItem?.compoundOrThen || ""}
                      // onChange={(e) => {
                      //   const dto = {
                      //     type: "changeCompoundType",
                      //     payload: {
                      //       itemId: compoundItem?.localId,
                      //       data:
                      //         compoundItem?.compoundOrThen === e.target.value
                      //           ? ""
                      //           : e.target.value,
                      //     },
                      //   };
                      //   updateCompound(dto);
                      // }}
                    >
                      {!(compoundItem?.orList?.length > 0) && (
                        <FormControlLabel
                          className="formRadioField "
                          value="or"
                          control={
                            <Radio
                              onClick={(e) => {
                                const dto = {
                                  type: "changeCompoundType",
                                  payload: {
                                    itemId: compoundItem?.localId,
                                    data:
                                      compoundItem?.compoundOrThen ===
                                      e.target.value
                                        ? ""
                                        : e.target.value,
                                  },
                                };
                                updateCompound(dto);
                              }}
                            />
                          }
                          label={t("or")}
                        />
                      )}
                      {!(compoundItem?.thenList?.length > 0) && (
                        <FormControlLabel
                          className="formRadioField"
                          value="then"
                          control={
                            <Radio
                              onClick={(e) => {
                                if (!compoundItem?.compoundOrThen) {
                                  console.log("compound Item =", compoundItem);
                                  if (!compoundItem?.duration) {
                                    setShowDurationModule(true);
                                  }
                                }
                                const dto = {
                                  type: "changeCompoundType",
                                  payload: {
                                    itemId: compoundItem?.localId,
                                    data:
                                      compoundItem?.compoundOrThen ===
                                      e.target.value
                                        ? ""
                                        : e.target.value,
                                  },
                                };
                                updateCompound(dto);
                              }}
                            />
                          }
                          label={t("then")}
                        />
                      )}
                      {!(compoundItem?.andList?.length > 0) && (
                        <FormControlLabel
                          className="formRadioField"
                          value="alongWith"
                          control={
                            <Radio
                              onClick={(e) => {
                                console.log("compound Item =", compoundItem);

                                const dto = {
                                  type: "changeCompoundType",
                                  payload: {
                                    itemId: compoundItem?.localId,
                                    data:
                                      compoundItem?.compoundOrThen ===
                                      e.target.value
                                        ? ""
                                        : e.target.value,
                                  },
                                };
                                updateCompound(dto);
                              }}
                            />
                          }
                          label={t("along_with")}
                        />
                      )}
                    </RadioGroup>
                  </FormControl>
                </div>
                {/* {mode === "edit" && !compoundItem?.isEditing && (
                  <IconButton
                    className="rmvCompound"
                    onClick={() => {
                      const dto = {
                        type: "updateItemdData",
                        payload: {
                          itemId: compoundItem?.localId,
                          data: [{ field: "isEditing", value: true }],
                        },
                      };
                      updateCompound(dto);
                    }}
                  >
                    <Edit />
                  </IconButton>
                )} */}
                <IconButton
                  className="rmvCompound"
                  onClick={() => {
                    updateCompound({
                      type: "deleteCompound",
                      payload: {
                        parentId: parentId,
                        itemId: compoundItem.localId,
                      },
                    });
                  }}
                >
                  <Trash />
                </IconButton>
              </div>
            )}
          </div>
          <div className="medRecStpGrp">
            {compoundItem.form && (
              <div className="medRecStpInd">
                <span>{t("form")}:</span>
                {compoundItem.form.formName}
              </div>
            )}
            {compoundItem.route && (
              <div className="medRecStpInd">
                <span>{t("rout")}:</span>
                {compoundItem.route.route}
              </div>
            )}
            {compoundItem.dose && (
              <div className="medRecStpInd">
                <span>{t("dose")}:</span>
                {compoundItem.dose}
              </div>
            )}
            {compoundItem.doseUnit && (
              <div className="medRecStpInd">
                <span>{t("dose_unit")}:</span>
                {compoundItem.doseUnit}
              </div>
            )}
            {compoundItem.doseLimit && (
              <div className="medRecStpInd">
                <span>{t("dose_limit")}:</span>
                {compoundItem.doseLimit}
              </div>
            )}
            {compoundItem.duration && (
              <div className="medRecStpInd">
                <span>{t("duration")}:</span>
                {compoundItem.duration.durationName}
              </div>
            )}
            {compoundItem.frequency && (
              <div className="medRecStpInd">
                <span>{t("frequency")}:</span>
                {compoundItem.frequency.frequency}
              </div>
            )}
            {compoundItem.note && (
              <div className="medRecStpInd">
                <span>{t("note")}:</span>
                {compoundItem.note}
              </div>
            )}
          </div>
          {/* <div className="medRecStpGrp"></div> */}
        </div>
        {/* s */}

        {compoundItem?.andList?.map((item) => {
          return (
            <CompoundItem
              compoundList={compoundList}
              compoundItem={item}
              parentId={compoundItem?.localId}
              updateCompound={updateCompound}
              compoundType={"alongWith"}
              isActionable={isActionable}
              mode={mode}
            />
          );
        })}

        {compoundItem?.compoundOrThen === "alongWith" && (
          <AddCompound
            compoundList={compoundList}
            handleAddCompound={(data) => {
              if (compoundItem?.compoundOrThen === "or") {
                const dto = {
                  type: "addOr",
                  payload: {
                    itemId: compoundItem.localId,
                    prevIndex: compoundItem.index,
                    data,
                    parentId,
                  },
                };
                updateCompound(dto);
              } else if (compoundItem?.compoundOrThen === "then") {
                const dto = {
                  type: "addThen",
                  payload: {
                    itemId: compoundItem.localId,
                    prevIndex: compoundItem.index,
                    data,
                  },
                };
                updateCompound(dto);
              } else if (compoundItem?.compoundOrThen === "alongWith") {
                const dto = {
                  type: "addInnerAnd",
                  payload: {
                    itemId: compoundItem.localId,
                    prevIndex: compoundItem.index,
                    data,
                  },
                };
                updateCompound(dto);
              }
            }}
          />
        )}

        {compoundItem?.compoundOrThen === "then" && (
          <>
            <div className={`compoundIndv compThen`}>
              {<div className="compChild">{t("then")}</div>}
              <div className="compfulDtl">
                <AddCompound
                  compoundList={compoundList}
                  handleAddCompound={(data) => {
                    if (compoundItem?.compoundOrThen === "or") {
                      const dto = {
                        type: "addOr",
                        payload: {
                          itemId: compoundItem.localId,
                          prevIndex: compoundItem.index,
                          data,
                          parentId,
                        },
                      };
                      updateCompound(dto);
                    } else if (compoundItem?.compoundOrThen === "then") {
                      const dto = {
                        type: "addThen",
                        payload: {
                          itemId: compoundItem.localId,
                          prevIndex: compoundItem.index,
                          data,
                        },
                      };
                      updateCompound(dto);
                    }
                  }}
                />
              </div>
            </div>
          </>
        )}
        {compoundItem?.thenList?.map((item) => {
          return (
            <CompoundItem
              compoundList={compoundList}
              compoundItem={item}
              parentId={compoundItem?.localId}
              updateCompound={updateCompound}
              compoundType={"then"}
              isActionable={isActionable}
              mode={mode}
            />
          );
        })}

        {compoundItem?.orList?.map((item) => {
          return (
            <CompoundItem
              compoundList={compoundList}
              compoundItem={item}
              parentId={compoundItem?.localId}
              updateCompound={updateCompound}
              compoundType={"or"}
              isActionable={isActionable}
              mode={mode}
            />
          );
        })}

        {compoundItem?.compoundOrThen === "or" && (
          <AddCompound
            compoundList={compoundList}
            handleAddCompound={(data) => {
              if (compoundItem?.compoundOrThen === "or") {
                const dto = {
                  type: "addOr",
                  payload: {
                    itemId: compoundItem.localId,
                    prevIndex: compoundItem.index,
                    data,
                    parentId,
                  },
                };
                updateCompound(dto);
              } else if (compoundItem?.compoundOrThen === "then") {
                const dto = {
                  type: "addThen",
                  payload: {
                    itemId: compoundItem.localId,
                    prevIndex: compoundItem.index,
                    data,
                  },
                };
                updateCompound(dto);
              } else if (compoundItem?.compoundOrThen === "and") {
                const dto = {
                  type: "addInnerAnd",
                  payload: {
                    itemId: compoundItem.localId,
                    prevIndex: compoundItem.index,
                    data,
                  },
                };
                updateCompound(dto);
              }
            }}
          />
        )}
      </div>
      {showDurationModule && (
        <CustomModal
          modalTitle={t("Please select duration to add new compound")}
          style={{ color: "#000" }}
          closeModal={() => {
            const dto = {
              type: "changeCompoundType",
              payload: {
                itemId: compoundItem?.localId,
                data: "",
              },
            };
            updateCompound(dto);
            setShowDurationModule(false);
          }}
          className={""}
        >
          <DurationModal
            durationList={durationList}
            compoundItem={compoundItem}
            updateCompound={updateCompound}
            closeModal={() => {
              setShowDurationModule(false);
            }}
          />
        </CustomModal>
      )}
    </>
  );
};

const DurationModal = ({
  durationList,
  compoundItem,
  updateCompound,
  closeModal,
}) => {
  const [selectDuration, setSelectDuration] = useState("");
  const handleChangselectDuration = (event) => {
    setSelectDuration(event.target.value);
  };

  const { t } = useTranslation();
  return (
    <>
      <div className="formElement width13">
        <FormControl className="formControl">
          <InputLabel id="selectDuration">{t("duration")}</InputLabel>
          <Select
            required
            labelId="selectDuration"
            id="select_Duration"
            value={selectDuration}
            label={t("duration")}
            onChange={handleChangselectDuration}
            className="modelSelectFild"
            variant="outlined"
          >
            <MenuItem value={""}>{`-- ${t("select_duration")} --`}</MenuItem>;
            {durationList?.map((item) => {
              return <MenuItem value={item}>{item.durationName}</MenuItem>;
            })}
            {/* <MenuItem value="2day">2 Day</MenuItem> */}
          </Select>
        </FormControl>
      </div>
      <Button
        className="dfultPrimaryBtn"
        onClick={() => {
          console.log("update component Field");
          if (!selectDuration) {
            toast.error("Please select duration");
            return;
          }

          const dto = {
            type: "updateItemdData",
            payload: {
              itemId: compoundItem?.localId,
              data: [{ field: "duration", value: selectDuration }],
            },
          };
          updateCompound(dto);
          closeModal();
        }}
      >
        {t("save")}
      </Button>
    </>
  );
};
