import React, { useState , useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import FemaleBody from "./FemaleBody";
import { useTranslation } from "react-i18next";
import {setReduxSelectedSymptoms, setReduxActiveIds, setReduxActiveLabels, setReduxSelectedSecondLevelKey, setReduxSelectedPart, setReduxSelectedPartForSecond, setReduxSecondStepActiveIds, setreduxActiveLabelsForSecond} from '../../../../../../redux/reducers/symptomsSlice';
import SMCTL1_00001 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00001.svg";
import SMCTL1_00002 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00002.svg";
import SMCTL1_00003 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00003.svg";
import SMCTL1_00004 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00004.svg";
import SMCTL1_00005 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00005.svg";
import SMCTL1_00006 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00006.svg";
import SMCTL1_00007 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00007.svg";
import SMCTL1_00008 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00008.svg";
import SMCTL1_00009 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00009.svg";
import SMCTL1_00010 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00010.svg";
import SMCTL1_00011 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00011.svg";
import SMCTL1_00012 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00012.svg";
import SMCTL1_00013 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00013.svg";
import SMCTL1_00014 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00014.svg";
import SMCTL1_00015 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00015.svg";
import SMCTL1_00016 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00016.svg";
import SMCTL1_00017 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00017.svg";
import SMCTL1_00018 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00018.svg";
import SMCTL1_00019 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00019.svg";
import SMCTL1_00020 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00020.svg";
import SMCTL1_00021 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00021.svg";
import SMCTL1_00022 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00022.svg";
import SMCTL1_00023 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00023.svg";
import SMCTL1_00024 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00024.svg";
import SMCTL1_00025 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00025.svg";
import SMCTL1_00026 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00026.svg";
import SMCTL1_00027 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00027.svg";
import SMCTL1_00028 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00028.svg";
import SMCTL1_00029 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00029.svg";
import SMCTL1_00030 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00030.svg";
import SMCTL1_00031 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00031.svg";
import SMCTL1_00032 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00032.svg";
import SMCTL1_00033 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00033.svg";
import SMCTL1_00034 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00034.svg";
import SMCTL1_00035 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00035.svg";
import SMCTL1_00036 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00036.svg";
import SMCTL1_00037 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00037.svg";
import SMCTL1_00038 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00038.svg";
import SMCTL1_00039 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00039.svg";
import SMCTL1_00040 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00040.svg";
import SMCTL1_00041 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00041.svg";
import SMCTL1_00042 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00042.svg";
import SMCTL1_00043 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00043.svg";
import SMCTL1_00044 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00044.svg";
import SMCTL1_00045 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00045.svg";
import SMCTL1_00046 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00046.svg";
import SMCTL1_00047 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00047.svg";
import SMCTL1_00048 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00048.svg";
import SMCTL1_00050 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00050.svg";
import SMCTL1_00051 from "../../../../images/symptoms/visualSelectionStepOne/SMCTL1_00051.svg";

// second label image/////////////////////////////////////////////////////////////





import SMCTL2_00001 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00001.svg";
import SMCTL2_00002 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00002.svg";
import SMCTL2_00003 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00003.svg";
import SMCTL2_00004 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00004.svg";
import SMCTL2_00005 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00005.svg";
import SMCTL2_00006 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00006.svg";
import SMCTL2_00007 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00007.svg";
import SMCTL2_00008 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00008.svg";
import SMCTL2_00009 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00009.svg";
import SMCTL2_00010 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00010.svg";
import SMCTL2_00011 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00011.svg";
import SMCTL2_00012 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00012.svg";
import SMCTL2_00013 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00013.svg";
import SMCTL2_00014 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00014.svg";
import SMCTL2_00015 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00015.svg";
import SMCTL2_00016 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00016.svg";
import SMCTL2_00017 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00017.svg";
import SMCTL2_00018 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00018.svg";
import SMCTL2_00019 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00019.svg";
import SMCTL2_00020 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00020.svg";
import SMCTL2_00021 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00021.svg";
import SMCTL2_00022 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00022.svg";
import SMCTL2_00023 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00023.svg";
import SMCTL2_00024 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00024.svg";
import SMCTL2_00025 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00025.svg";
import SMCTL2_00026 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00026.svg";
import SMCTL2_00027 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00027.svg";
import SMCTL2_00028 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00028.svg";
import SMCTL2_00029 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00029.svg";
import SMCTL2_00030 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00030.svg";
import SMCTL2_00031 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00031.svg";
import SMCTL2_00032 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00032.svg";
import SMCTL2_00033 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00033.svg";
import SMCTL2_00034 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00034.svg";
import SMCTL2_00035 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00035.svg";
import SMCTL2_00036 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00036.svg";
import SMCTL2_00037 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00037.svg";
import SMCTL2_00038 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00038.svg";
import SMCTL2_00039 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00039.svg";
import SMCTL2_00040 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00040.svg";
import SMCTL2_00041 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00041.svg";
import SMCTL2_00042 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00042.svg";
import SMCTL2_00043 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00043.svg";
import SMCTL2_00044 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00044.svg";
import SMCTL2_00045 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00045.svg";

import SMCTL2_00046 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00046.svg";
import SMCTL2_00047 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00047.svg";
import SMCTL2_00048 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00048.svg";
import SMCTL2_00049 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00049.svg";
import SMCTL2_00050 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00050.svg";
import SMCTL2_00051 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00051.svg";
import SMCTL2_00052 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00052.svg";
import SMCTL2_00053 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00053.svg";
import SMCTL2_00054 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00054.svg";
import SMCTL2_00055 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00055.svg";
import SMCTL2_00056 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00056.svg";
import SMCTL2_00057 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00057.svg";
import SMCTL2_00058 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00058.svg";
import SMCTL2_00059 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00059.svg";
import SMCTL2_00060 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00060.svg";
import SMCTL2_00061 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00061.svg";
import SMCTL2_00062 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00062.svg";
import SMCTL2_00063 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00063.svg";
import SMCTL2_00064 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00064.svg";
import SMCTL2_00065 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00065.svg";
import SMCTL2_00066 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00066.svg";
import SMCTL2_00067 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00067.svg";
import SMCTL2_00068 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00068.svg";
import SMCTL2_00069 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00069.svg";
import SMCTL2_00070 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00070.svg";
import SMCTL2_00071 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00071.svg";
import SMCTL2_00072 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00072.svg";
import SMCTL2_00073 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00073.svg";
import SMCTL2_00074 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00074.svg";
import SMCTL2_00075 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00075.svg";
import SMCTL2_00076 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00076.svg";
import SMCTL2_00077 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00077.svg";
import SMCTL2_00078 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00078.svg";
import SMCTL2_00079 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00079.svg";
import SMCTL2_00080 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00080.svg";
import SMCTL2_00081 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00081.svg";
import SMCTL2_00082 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00082.svg";
import SMCTL2_00083 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00083.svg";
import SMCTL2_00084 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00084.svg";
import SMCTL2_00085 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00085.svg";
import SMCTL2_00086 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00086.svg";
import SMCTL2_00087 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00087.svg";
import SMCTL2_00088 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00088.svg";
import SMCTL2_00089 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00089.svg";
import SMCTL2_00090 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00090.svg";

import SMCTL2_00091 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00091.svg";
import SMCTL2_00092 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00092.svg";
import SMCTL2_00093 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00093.svg";
import SMCTL2_00094 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00094.svg";
import SMCTL2_00095 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00095.svg";
import SMCTL2_00096 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00096.svg";
import SMCTL2_00097 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00097.svg";
import SMCTL2_00098 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00098.svg";
import SMCTL2_00099 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00099.svg";
import SMCTL2_00100 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00100.svg";
import SMCTL2_00101 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00101.svg";
import SMCTL2_00102 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00102.svg";
import SMCTL2_00103 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00103.svg";
import SMCTL2_00104 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00104.svg";
import SMCTL2_00105 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00105.svg";
import SMCTL2_00106 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00106.svg";
import SMCTL2_00107 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00107.svg";
import SMCTL2_00108 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00108.svg";
import SMCTL2_00109 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00109.svg";
import SMCTL2_00110 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00110.svg";
import SMCTL2_00111 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00111.svg";
import SMCTL2_00112 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00112.svg";
import SMCTL2_00113 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00113.svg";
import SMCTL2_00114 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00114.svg";
import SMCTL2_00115 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00115.svg";
import SMCTL2_00116 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00116.svg";
import SMCTL2_00117 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00117.svg";
import SMCTL2_00118 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00118.svg";
import SMCTL2_00119 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00119.svg";
import SMCTL2_00120 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00120.svg";
import SMCTL2_00121 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00121.svg";
import SMCTL2_00122 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00122.svg";
import SMCTL2_00123 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00123.svg";
import SMCTL2_00124 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00124.svg";
import SMCTL2_00125 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00125.svg";
import SMCTL2_00126 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00126.svg";
import SMCTL2_00127 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00127.svg";
import SMCTL2_00128 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00128.svg";
import SMCTL2_00129 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00129.svg";
import SMCTL2_00130 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00130.svg";
import SMCTL2_00131 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00131.svg";
import SMCTL2_00132 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00132.svg";
import SMCTL2_00133 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00133.svg";
import SMCTL2_00134 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00134.svg";
import SMCTL2_00135 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00135.svg";

import SMCTL2_00136 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00136.svg";
import SMCTL2_00137 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00137.svg";
import SMCTL2_00138 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00138.svg";
import SMCTL2_00139 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00139.svg";
import SMCTL2_00140 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00140.svg";
import SMCTL2_00141 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00141.svg";
import SMCTL2_00142F from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00142_F.svg";
import SMCTL2_00142M from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00142_M.svg";
import SMCTL2_00143 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00143.svg";
import SMCTL2_00144 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00144.svg";
import SMCTL2_00145 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00145.svg";
import SMCTL2_00146 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00146.svg";
import SMCTL2_00147 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00147.svg";
import SMCTL2_00148 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00148.svg";
import SMCTL2_00149 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00149.svg";
import SMCTL2_00150 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00150.svg";

import SMCTL2_00151 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00151.svg";
import SMCTL2_00152 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00152.svg";
import SMCTL2_00153 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00153.svg";
import SMCTL2_00154 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00154.svg";
import SMCTL2_00155 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00155.svg";
import SMCTL2_00156 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00156.svg";
import SMCTL2_00157 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00157.svg";
import SMCTL2_00158 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00158.svg";
import SMCTL2_00159 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00159.svg";
import SMCTL2_00160 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00160.svg";
import SMCTL2_00161 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00161.svg";
import SMCTL2_00162 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00162.svg";
import SMCTL2_00163 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00163.svg";
import SMCTL2_00164 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00164.svg";
import SMCTL2_00165 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00165.svg";
import SMCTL2_00166 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00166.svg";
import SMCTL2_00167 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00167.svg";
import SMCTL2_00168 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00168.svg";
import SMCTL2_00169 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00169.svg";
import SMCTL2_00171 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00171.svg";
import SMCTL2_00172 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00172.svg";
import SMCTL2_00173 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00173.svg";
import SMCTL2_00174 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00174.svg";
import SMCTL2_00175 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00175.svg";
import SMCTL2_00176 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00176.svg";
import SMCTL2_00177 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00177.svg";
import SMCTL2_00178 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00178.svg";
import SMCTL2_00179 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00179.svg";
import SMCTL2_00180 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00180.svg";
import SMCTL2_00181 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00181.svg";
import SMCTL2_00182 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00182.svg";
import SMCTL2_00183 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00183.svg";
import SMCTL2_00184 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00184.svg";
import SMCTL2_00185 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00185.svg";
import SMCTL2_00186 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00186.svg";
import SMCTL2_00187 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00187.svg";
import SMCTL2_00188 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00188.svg";
import SMCTL2_00189 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00189.svg";
import SMCTL2_00190 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00190.svg";
import SMCTL2_00191 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00191.svg";
import SMCTL2_00192 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00192.svg";
import SMCTL2_00193 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00193.svg";
import SMCTL2_00194 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00194.svg";
import SMCTL2_00195 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00195.svg";
import SMCTL2_00196 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00196.svg";
import SMCTL2_00198 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00198.svg";
import SMCTL2_00199 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00199.svg";
import SMCTL2_00200 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00200.svg";
import SMCTL2_00201 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00201.svg";
import SMCTL2_00202 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00202.svg";
import SMCTL2_00203 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00203.svg";
import SMCTL2_00204 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00204.svg";
import SMCTL2_00205 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00205.svg";
import SMCTL2_00206 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00206.svg";
import SMCTL2_00207 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00207.svg";
import SMCTL2_00208 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00208.svg";
import SMCTL2_00209 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00209.svg";
import SMCTL2_00210 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00209.svg";
import SMCTL2_00211 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00209.svg";
import SMCTL2_00212 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00212.svg";
import SMCTL2_00213 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00213.svg";
import SMCTL2_00214 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00214.svg";

import SMCTL2_00215 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00215.svg";
import SMCTL2_00216 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00216.svg";
import SMCTL2_00217 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00217.svg";
import SMCTL2_00218 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00218.svg";
import SMCTL2_00219 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00219.svg";
import SMCTL2_00220 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00220.svg";
import SMCTL2_00221 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00221.svg";
import SMCTL2_00222 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00222.svg";
import SMCTL2_00223 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00223.svg";
import SMCTL2_00224 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00224.svg";
import SMCTL2_00225 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00225.svg";
import SMCTL2_00226 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00226.svg";
import SMCTL2_00227 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00227.svg";
import SMCTL2_00228 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00228.svg";
import SMCTL2_00229 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00229.svg";
import SMCTL2_00230 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00230.svg";
import SMCTL2_00231 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00231.svg";
import SMCTL2_00232 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00232.svg";
import SMCTL2_00233 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00233.svg";
import SMCTL2_00234 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00234.svg";
import SMCTL2_00235 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00235.svg";
import SMCTL2_00236 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00236.svg";
import SMCTL2_00237 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00237.svg";
import SMCTL2_00239 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00239.svg";
import SMCTL2_00240 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00240.svg";
import SMCTL2_00241 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00241.svg";
import SMCTL2_00242 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00242.svg";
import SMCTL2_00243 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00243.svg";
import SMCTL2_00244 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00244.svg";
import SMCTL2_00245 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00245.svg";
import SMCTL2_00246 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00246.svg";

import SMCTL2_00247 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00247.svg";
import SMCTL2_00248 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00248.svg";
import SMCTL2_00249 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00249.svg";
import SMCTL2_00250 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00250.svg";
import SMCTL2_00251 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00251.svg";
import SMCTL2_00252 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00252.svg";
import SMCTL2_00253 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00253.svg";
import SMCTL2_00254 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00254.svg";
import SMCTL2_00255 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00255.svg";
import SMCTL2_00256 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00256.svg";
import SMCTL2_00257 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00257.svg";
import SMCTL2_00258 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00258.svg";
import SMCTL2_00259 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00259.svg";
import SMCTL2_00260 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00260.svg";
import SMCTL2_00261 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00261.svg";
import SMCTL2_00263 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00263.svg";
import SMCTL2_00264 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00264.svg";
import SMCTL2_00265 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00265.svg";
import SMCTL2_00266 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00266.svg";
import SMCTL2_00267 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00267.svg";
import SMCTL2_00268 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00268.svg";
import SMCTL2_00269 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00269.svg";
import SMCTL2_00270 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00270.svg";
import SMCTL2_00271 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00271.svg";
import SMCTL2_00272 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00272.svg";
import SMCTL2_00273 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00273.svg";
import SMCTL2_00274 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00274.svg";
import SMCTL2_00275 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00275.svg";
import SMCTL2_00276 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00276.svg";
import SMCTL2_00277 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00277.svg";
import SMCTL2_00278 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00278.svg";
import SMCTL2_00279 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00279.svg";
import SMCTL2_00280 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00280.svg";
import SMCTL2_00281 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00281.svg";
import SMCTL2_00282 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00282.svg";
import SMCTL2_00283 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00283.svg";
import SMCTL2_00284 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00284.svg";
import SMCTL2_00285 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00285.svg";
import SMCTL2_00286 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00286.svg";
import SMCTL2_00287 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00287.svg";
import SMCTL2_00288 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00288.svg";
import SMCTL2_00289 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00289.svg";
import SMCTL2_00290 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00290.svg";
import SMCTL2_00291 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00291.svg";
import SMCTL2_00292 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00292.svg";
import SMCTL2_00293 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00293.svg";
import SMCTL2_00294 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00294.svg";
import SMCTL2_00295 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00295.svg";

import SMCTL2_00296 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00296.svg";
import SMCTL2_00297 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00297.svg";
import SMCTL2_00298 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00298.svg";
import SMCTL2_00299 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00299.svg";
import SMCTL2_00300 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00300.svg";
import SMCTL2_00301 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00301.svg";
import SMCTL2_00302 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00302.svg";
import SMCTL2_00303 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00303.svg";
import SMCTL2_00304 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00304.svg";
import SMCTL2_00305 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00305.svg";
import SMCTL2_00306 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00306.svg";
import SMCTL2_00308 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00308.svg";
import SMCTL2_00309 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00309.svg";
import SMCTL2_00310 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00310.svg";
import SMCTL2_00311 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00311.svg";
import SMCTL2_00312 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00312.svg";
import SMCTL2_00313 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00313.svg";
import SMCTL2_00314 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00314.svg";
import SMCTL2_00315 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00315.svg";
import SMCTL2_00316 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00316.svg";
import SMCTL2_00317 from "../../../../images/symptoms/visualSelectionStepTwoImage/SMCTL2_00317.svg";

import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import MaleBody from "./MaleBody";

// const iconsArray = [
//   { id: "Icon1", src: Icon1, title: "Abnormal Behaviour" },
//   { id: "Icon2", src: Icon2, title: "Abnormal Body Movement / Fits" },
//   { id: "Icon3", src: Icon3, title: "Abnormal Body Sensations" },
//   {
//     id: "Icon4",
//     src: Icon4,
//     title: "Abnormal Body Temperature / Chills / Rigors",
//   },
//   { id: "Icon5", src: Icon5, title: "Abnormal Feelings / Thoughts" },
//   { id: "Icon6", src: Icon6, title: "Abnormal Posture" },
//   { id: "Icon7", src: Icon7, title: "Abnormal Sensation" },
//   { id: "Icon8", src: Icon8, title: "Abnormal Sensorium" },
//   { id: "Icon9", src: Icon9, title: "Abnormal Walking / Gait" },
//   { id: "Icon10", src: Icon10, title: "Acidity / Heartburn" },
//   { id: "Icon14", src: Icon14, title: "Breast Problems" },
//   { id: "Icon23", src: Icon23, title: "Eating / Drinking Problems" },
// ];

// const iconMapping = {
//   item1: ["Icon1", "Icon2"],
//   item2: ["Icon10", "Icon14", "Icon23"],
//   item3: ["Icon1", "Icon2", "Icon3", "Icon4", "Icon5", "Icon6"],
//   item4: ["Icon4"],
//   item5: ["Icon5"],
//   item6: ["Icon6"],
//   item7: ["Icon9", "Icon2"],
//   item8: ["Icon7"],
//   item9: ["Icon8"],
// };

const symptomsList = [
  { id: 1, name: "Fever" },
  { id: 2, name: "Cough" },
  { id: 3, name: "Shortness of breath" },
  { id: 4, name: "Fatigue" },
  { id: 5, name: "Headache" },
  { id: 6, name: "Sore throat" },
  { id: 7, name: "Loss of taste" },
  { id: 8, name: "Loss of smell" },
  { id: 9, name: "Muscle pain" },
  { id: 10, name: "Chills" },
  { id: 11, name: "Runny nose" },
  { id: 12, name: "Nausea" },
  { id: 13, name: "Vomiting" },
  { id: 14, name: "Diarrhea" },
  { id: 15, name: "Chest pain" },
  { id: 16, name: "Dizziness" },
  { id: 17, name: "Sweating" },
  { id: 18, name: "Skin rash" },
  { id: 19, name: "Eye redness" },
  { id: 20, name: "Abdominal pain" },
];



export default function VisualSelection({ patientGender, handelfetchVisualSelectionData}) {

  
  const dispatch = useDispatch();
  
  const {
    reduxSelectedSymptoms,
    reduxActiveIds,
    reduxActiveLabels,
    reduxSelectedSecondLevelKey,
    reduxSelectedPart,
    reduxSelectedPartForSecond,
    reduxSecondStepActiveIds,
    reduxActiveLabelsForSecond
  } = useSelector(state => state.visualSelection);

  
  const { t } = useTranslation();
  const [activeIds, setActiveIds] = useState([]);
  const [activeIdsForSecondStep, setActiveIdsForSecondStep] = useState([]);
  const [selectedPart, setSelectedPart] = useState(null);
  const [selectedPartForSecond, setSelectedPartForSecond] = useState(null);
  const [activeLabels, setActiveLabels] = useState([]);
  const [checked, setChecked] = useState([]);
  const [selectedSecondLevelKey, setSelectedSecondLevelKey] = useState(null);
  const [activeLabelsForSecondLevel, setActiveLabelsForSecondLabel] = useState([]);
  //  const [checked, setChecked] = useState([]);

  // useEffect(() => {
  //   if (reduxSelectedSymptoms.length > 0) {
  //     setChecked(reduxSelectedSymptoms);
  //   }
  //   if (reduxActiveIds.length > 0) {
  //     setActiveIds(reduxActiveIds);
  //   }
  //   if (reduxActiveLabels.length > 0) {
  //     setActiveLabels(reduxActiveLabels);
  //   }
  //   if (reduxSelectedPart) {
  //     setSelectedPart(reduxSelectedPart);
  //   }
  //   if (reduxSelectedPartForSecond) {
  //     setSelectedPartForSecond(reduxSelectedPartForSecond);
  //   }
  //   if (reduxSelectedSecondLevelKey) {
  //     setSelectedSecondLevelKey(reduxSelectedSecondLevelKey);
  //   }
  //   if(reduxSecondStepActiveIds.length > 0){
  //     setActiveIdsForSecondStep(reduxSecondStepActiveIds)
  //   }
  //   if (reduxActiveLabelsForSecond.length > 0) {
  //     setActiveLabelsForSecondLabel(reduxActiveLabelsForSecond);
  //   }
  // }, []);

  const iconsArray = [
    { id: "SMCTL1_00001", src: SMCTL1_00001, title: t("abnormal_behaviour") },
    {
      id: "SMCTL1_00002",
      src: SMCTL1_00002,
      title: t("abnormal_body_movement_fits"),
    },
    {
      id: "SMCTL1_00003",
      src: SMCTL1_00003,
      title: t("abnormal_body_sensations"),
    },
    {
      id: "SMCTL1_00004",
      src: SMCTL1_00004,
      title: t("abnormal_body_temperature_chills_rigors"),
    },
    {
      id: "SMCTL1_00005",
      src: SMCTL1_00005,
      title: t("abnormal_feelings_thoughts"),
    },
    { id: "SMCTL1_00006", src: SMCTL1_00006, title: t("abnormal_posture") },
    { id: "SMCTL1_00007", src: SMCTL1_00007, title: t("abnormal_sensation") },
    { id: "SMCTL1_00008", src: SMCTL1_00008, title: t("abnormal_sensorium") },
    {
      id: "SMCTL1_00009",
      src: SMCTL1_00009,
      title: t("abnormal_walking_gait"),
    },
    { id: "SMCTL1_00010", src: SMCTL1_00010, title: t("acidity_heartburn") },
    { id: "SMCTL1_00011", src: SMCTL1_00011, title: t("anorectal_problems") },
    {
      id: "SMCTL1_00012",
      src: SMCTL1_00012,
      title: t("blood_pressure_problem"),
    },
    { id: "SMCTL1_00013", src: SMCTL1_00013, title: t("bowel_problems") },
    { id: "SMCTL1_00014", src: SMCTL1_00014, title: t("breast_problems") },
    { id: "SMCTL1_00015", src: SMCTL1_00015, title: t("breathing_problems") },
    { id: "SMCTL1_00016", src: SMCTL1_00016, title: t("burn") },
    {
      id: "SMCTL1_00017",
      src: SMCTL1_00017,
      title: t("cognitive_problem_loss_of_memory"),
    },
    { id: "SMCTL1_00018", src: SMCTL1_00018, title: t("cough") },
    { id: "SMCTL1_00019", src: SMCTL1_00019, title: t("deformities") },
    {
      id: "SMCTL1_00020",
      src: SMCTL1_00020,
      title: t("delayed_developmental_milestones"),
    },
    { id: "SMCTL1_00021", src: SMCTL1_00021, title: t("diagnosis_history") },
    { id: "SMCTL1_00022", src: SMCTL1_00022, title: t("ear_problems") },
    {
      id: "SMCTL1_00023",
      src: SMCTL1_00023,
      title: t("eating_drinking_problems"),
    },
    { id: "SMCTL1_00024", src: SMCTL1_00024, title: t("eye_problems") },
    { id: "SMCTL1_00025", src: SMCTL1_00025, title: t("gangrene") },
    {
      id: "SMCTL1_00026",
      src: SMCTL1_00026,
      title: t("genito_urinary_problems"),
    },
    {
      id: "SMCTL1_00027",
      src: SMCTL1_00027,
      title: t("hair_scalp_nail_problems"),
    },
    { id: "SMCTL1_00028", src: SMCTL1_00028, title: t("hiccups") },
    {
      id: "SMCTL1_00029",
      src: SMCTL1_00029,
      title: t("injury_trauma_fracture_dislocation"),
    },
    {
      id: "SMCTL1_00030",
      src: SMCTL1_00030,
      title: t("mouth_dental_problems"),
    },
    {
      id: "SMCTL1_00031",
      src: SMCTL1_00031,
      title: t("musculoskeletal_problems"),
    },
    { id: "SMCTL1_00032", src: SMCTL1_00032, title: t("nausea_vomiting") },
    { id: "SMCTL1_00033", src: SMCTL1_00033, title: t("neck_throat_problems") },
    { id: "SMCTL1_00034", src: SMCTL1_00034, title: t("nose_problems") },
    {
      id: "SMCTL1_00035",
      src: SMCTL1_00035,
      title: t("other_non_specific_bleeding_discharge"),
    },
    { id: "SMCTL1_00036", src: SMCTL1_00036, title: t("pain_tenderness") },
    {
      id: "SMCTL1_00037",
      src: SMCTL1_00037,
      title: t("palpitation_heart_problems"),
    },
    { id: "SMCTL1_00038", src: SMCTL1_00038, title: t("paralysis_paresis") },
    { id: "SMCTL1_00039", src: SMCTL1_00039, title: t("physical_examination") },
    {
      id: "SMCTL1_00040",
      src: SMCTL1_00040,
      title: t("pregnancy_fertility_problems"),
    },
    { id: "SMCTL1_00041", src: SMCTL1_00041, title: t("pulse_problems") },
    {
      id: "SMCTL1_00042",
      src: SMCTL1_00042,
      title: t("rash_and_skin_problems"),
    },
    { id: "SMCTL1_00043", src: SMCTL1_00043, title: t("sexual_problem") },
    { id: "SMCTL1_00044", src: SMCTL1_00044, title: t("sleep_problems") },
    { id: "SMCTL1_00045", src: SMCTL1_00045, title: t("sneezing") },
    { id: "SMCTL1_00046", src: SMCTL1_00046, title: t("speech_problem") },
    { id: "SMCTL1_00047", src: SMCTL1_00047, title: t("sweating") },
    { id: "SMCTL1_00048", src: SMCTL1_00048, title: t("swelling_lump") },
    {
      id: "SMCTL1_00050",
      src: SMCTL1_00050,
      title: t("weakness_lethargy_fatigue"),
    },
    { id: "SMCTL1_00051", src: SMCTL1_00051, title: t("weight_problems") },
  ];

  const maleFirstLevelSymtoms = {
    //("righthand")
    item1: [
      // "SMCTL1_00001",
      // "SMCTL1_00002",
      "SMCTL1_00003",
      "SMCTL1_00004",
      // "SMCTL1_00005",
      "SMCTL1_00006",
      "SMCTL1_00007",
      "SMCTL1_00008",
      "SMCTL1_00012",
      // "SMCTL1_00015",
      "SMCTL1_00016",
      // "SMCTL1_00017",
      // "SMCTL1_00018",
      "SMCTL1_00019",
      "SMCTL1_00020",
      "SMCTL1_00021",
      // "SMCTL1_00023",
      "SMCTL1_00025",
      "SMCTL1_00027",
      // "SMCTL1_00028",
      "SMCTL1_00029",
      "SMCTL1_00031",
      // "SMCTL1_00032",
      "SMCTL1_00035",
      "SMCTL1_00036",
      "SMCTL1_00038",
      "SMCTL1_00039",
      "SMCTL1_00041",
      "SMCTL1_00042",
      // "SMCTL1_00044",
      "SMCTL1_00047",
      "SMCTL1_00048",
      "SMCTL1_00050",
      "SMCTL1_00051",
    ],
    //("lefthand")
    item2: [
      // "SMCTL1_00001",
      // "SMCTL1_00002",
      "SMCTL1_00003",
      "SMCTL1_00004",
      // "SMCTL1_00005",
      "SMCTL1_00006",
      "SMCTL1_00007",
      "SMCTL1_00008",
      "SMCTL1_00012",
      // "SMCTL1_00015",
      "SMCTL1_00016",
      // "SMCTL1_00017",
      // "SMCTL1_00018",
      "SMCTL1_00019",
      "SMCTL1_00020",
      "SMCTL1_00021",
      // "SMCTL1_00023",
      "SMCTL1_00025",
      "SMCTL1_00027",
      // "SMCTL1_00028",
      "SMCTL1_00029",
      "SMCTL1_00031",
      // "SMCTL1_00032",
      "SMCTL1_00035",
      "SMCTL1_00036",
      "SMCTL1_00038",
      "SMCTL1_00039",
      "SMCTL1_00041",
      "SMCTL1_00042",
      // "SMCTL1_00044",
      "SMCTL1_00047",
      "SMCTL1_00048",
      "SMCTL1_00050",
      "SMCTL1_00051",
    ],
    //("rightLeg")
    item3: [
      // "SMCTL1_00001",
      // "SMCTL1_00002",
      "SMCTL1_00003",
      "SMCTL1_00004",
      // "SMCTL1_00005",
      "SMCTL1_00006",
      "SMCTL1_00007",
      "SMCTL1_00008",
      "SMCTL1_00009",
      "SMCTL1_00012",
      // "SMCTL1_00015",
      // "SMCTL1_00015",
      "SMCTL1_00016",
      // "SMCTL1_00017",
      // "SMCTL1_00018",
      "SMCTL1_00019",
      "SMCTL1_00020",
      "SMCTL1_00021",
      // "SMCTL1_00023",
      "SMCTL1_00025",
      "SMCTL1_00027",
      // "SMCTL1_00028",
      "SMCTL1_00029",
      "SMCTL1_00031",
      // "SMCTL1_00032",
      "SMCTL1_00035",
      "SMCTL1_00036",
      "SMCTL1_00038",
      "SMCTL1_00039",
      "SMCTL1_00041",
      "SMCTL1_00042",
      // "SMCTL1_00044",
      "SMCTL1_00047",
      "SMCTL1_00048",
      "SMCTL1_00050",
      "SMCTL1_00051",
    ],
    //("leftLeg")
    item4: [
      // "SMCTL1_00001",
      // "SMCTL1_00002",
      "SMCTL1_00003",
      "SMCTL1_00004",
      // "SMCTL1_00005",
      "SMCTL1_00006",
      "SMCTL1_00007",
      "SMCTL1_00008",
      "SMCTL1_00009",
      "SMCTL1_00012",
      // "SMCTL1_00015",
      "SMCTL1_00016",
      // "SMCTL1_00017",
      // "SMCTL1_00018",
      "SMCTL1_00019",
      "SMCTL1_00020",
      "SMCTL1_00021",
      // "SMCTL1_00023",
      "SMCTL1_00025",
      "SMCTL1_00027",
      // "SMCTL1_00028",
      "SMCTL1_00029",
      "SMCTL1_00031",
      // "SMCTL1_00032",
      "SMCTL1_00035",
      "SMCTL1_00036",
      "SMCTL1_00038",
      "SMCTL1_00039",
      "SMCTL1_00041",
      "SMCTL1_00042",
      // "SMCTL1_00044",
      "SMCTL1_00047",
      "SMCTL1_00048",
      "SMCTL1_00050",
      "SMCTL1_00051",
    ],
    //("lowerAbdoman")
    item5: [
      // "SMCTL1_00001",
      // "SMCTL1_00002",
      "SMCTL1_00003",
      "SMCTL1_00004",
      // "SMCTL1_00005",
      "SMCTL1_00006",
      "SMCTL1_00007",
      "SMCTL1_00008",
      "SMCTL1_00009",
      "SMCTL1_00011",
      "SMCTL1_00012",
      "SMCTL1_00013",
      // "SMCTL1_00015",
      "SMCTL1_00016",
      // "SMCTL1_00017",
      // "SMCTL1_00018",
      "SMCTL1_00019",
      "SMCTL1_00020",
      "SMCTL1_00021",
      // "SMCTL1_00023",
      "SMCTL1_00025",
      "SMCTL1_00026",
      // "SMCTL1_00028",
      "SMCTL1_00029",
      "SMCTL1_00031",
      // "SMCTL1_00032",
      "SMCTL1_00035",
      "SMCTL1_00036",
      "SMCTL1_00039",
      "SMCTL1_00041",
      "SMCTL1_00042",
      "SMCTL1_00043",
      // "SMCTL1_00044",
      "SMCTL1_00047",
      "SMCTL1_00048",
      "SMCTL1_00050",
      "SMCTL1_00051",
    ],
    //("abdoman")
    item6: [
      // "SMCTL1_00001",
      // "SMCTL1_00002",
      "SMCTL1_00003",
      "SMCTL1_00004",
      // "SMCTL1_00005",
      "SMCTL1_00006",
      "SMCTL1_00007",
      "SMCTL1_00008",
      "SMCTL1_00010",
      "SMCTL1_00012",
      "SMCTL1_00013",
      // "SMCTL1_00015",
      "SMCTL1_00016",
      // "SMCTL1_00017",
      // "SMCTL1_00018",
      "SMCTL1_00019",
      "SMCTL1_00020",
      "SMCTL1_00021",
      // "SMCTL1_00023",
      "SMCTL1_00025",
      "SMCTL1_00026",
      // "SMCTL1_00028",
      "SMCTL1_00029",
      "SMCTL1_00031",
      // "SMCTL1_00032",
      "SMCTL1_00035",
      "SMCTL1_00036",
      "SMCTL1_00039",
      "SMCTL1_00041",
      "SMCTL1_00042",
      // "SMCTL1_00044",
      "SMCTL1_00047",
      "SMCTL1_00048",
      "SMCTL1_00050",
      "SMCTL1_00051",
    ],
    //("chest")
    item7: [
      // "SMCTL1_00001",
      // "SMCTL1_00002",
      "SMCTL1_00003",
      "SMCTL1_00004",
      // "SMCTL1_00005",
      "SMCTL1_00006",
      "SMCTL1_00007",
      "SMCTL1_00008",
      "SMCTL1_00010",
      "SMCTL1_00012",
      "SMCTL1_00014",
      "SMCTL1_00015",
      "SMCTL1_00016",
      // "SMCTL1_00017",
      "SMCTL1_00018",
      "SMCTL1_00019",
      "SMCTL1_00020",
      "SMCTL1_00021",
      // "SMCTL1_00023",
      "SMCTL1_00025",
      "SMCTL1_00028",
      "SMCTL1_00029",
      "SMCTL1_00031",
      // "SMCTL1_00032",
      "SMCTL1_00035",
      "SMCTL1_00036",
      "SMCTL1_00037",
      "SMCTL1_00039",
      "SMCTL1_00041",
      "SMCTL1_00042",
      // "SMCTL1_00044",
      "SMCTL1_00047",
      "SMCTL1_00048",
      "SMCTL1_00050",
      "SMCTL1_00051",
    ],
    //("neck")
    item8: [
      // "SMCTL1_00001",
      // "SMCTL1_00002",
      "SMCTL1_00003",
      "SMCTL1_00004",
      // "SMCTL1_00005",
      "SMCTL1_00006",
      "SMCTL1_00007",
      "SMCTL1_00008",
      "SMCTL1_00010",
      "SMCTL1_00012",
      // "SMCTL1_00015",
      "SMCTL1_00016",
      // "SMCTL1_00017",
      "SMCTL1_00018",
      "SMCTL1_00019",
      "SMCTL1_00020",
      "SMCTL1_00021",
      "SMCTL1_00023",
      "SMCTL1_00025",
      // "SMCTL1_00028",
      "SMCTL1_00029",
      "SMCTL1_00031",
      // "SMCTL1_00032",
      "SMCTL1_00033",
      "SMCTL1_00035",
      "SMCTL1_00036",
      "SMCTL1_00039",
      "SMCTL1_00041",
      "SMCTL1_00042",
      // "SMCTL1_00044",
      "SMCTL1_00047",
      "SMCTL1_00048",
      "SMCTL1_00050",
      "SMCTL1_00051",
    ],
    // ("head")
    item9: [
      "SMCTL1_00001",
      "SMCTL1_00002",
      "SMCTL1_00003",
      "SMCTL1_00004",
      "SMCTL1_00005",
      "SMCTL1_00006",
      "SMCTL1_00007",
      "SMCTL1_00008",
      "SMCTL1_00010",
      "SMCTL1_00012",
      "SMCTL1_00015",
      "SMCTL1_00016",
      "SMCTL1_00017",
      "SMCTL1_00018",
      "SMCTL1_00019",
      "SMCTL1_00020",
      "SMCTL1_00021",
      "SMCTL1_00022",
      "SMCTL1_00023",
      "SMCTL1_00024",
      "SMCTL1_00025",
      "SMCTL1_00027",
      "SMCTL1_00028",
      "SMCTL1_00029",
      "SMCTL1_00030",
      "SMCTL1_00031",
      "SMCTL1_00032",
      "SMCTL1_00034",
      "SMCTL1_00035",
      "SMCTL1_00036",
      "SMCTL1_00038",
      "SMCTL1_00039",
      "SMCTL1_00041",
      "SMCTL1_00042",
      "SMCTL1_00044",
      "SMCTL1_00045",
      "SMCTL1_00046",
      "SMCTL1_00047",
      "SMCTL1_00048",
      "SMCTL1_00050",
      "SMCTL1_00051",
    ],
  };

  const femaleFirstLevelSymtoms = {
    //("righthand")
    item1: [
      // "SMCTL1_00001",
      // "SMCTL1_00002",
      "SMCTL1_00003",
      "SMCTL1_00004",
      // "SMCTL1_00005",
      "SMCTL1_00006",
      "SMCTL1_00007",
      "SMCTL1_00008",
      "SMCTL1_00012",
      // "SMCTL1_00015",
      "SMCTL1_00016",
      // "SMCTL1_00017",
      // "SMCTL1_00018",
      "SMCTL1_00019",
      "SMCTL1_00020",
      "SMCTL1_00021",
      // "SMCTL1_00023",
      "SMCTL1_00025",
      // "SMCTL1_00027",
      // "SMCTL1_00028",
      "SMCTL1_00029",
      "SMCTL1_00031",
      // "SMCTL1_00032",
      "SMCTL1_00035",
      "SMCTL1_00036",
      "SMCTL1_00038",
      "SMCTL1_00039",
      // "SMCTL1_00040",
      "SMCTL1_00041",
      "SMCTL1_00042",
      // "SMCTL1_00044",
      "SMCTL1_00047",
      "SMCTL1_00048",
      "SMCTL1_00050",
      "SMCTL1_00051",
    ],
    //("lefthand")
    item2: [
      // "SMCTL1_00001",
      // "SMCTL1_00002",
      "SMCTL1_00003",
      "SMCTL1_00004",
      // "SMCTL1_00005",
      "SMCTL1_00006",
      "SMCTL1_00007",
      "SMCTL1_00008",
      "SMCTL1_00012",
      // "SMCTL1_00015",
      "SMCTL1_00016",
      // "SMCTL1_00017",
      // "SMCTL1_00018",
      "SMCTL1_00019",
      "SMCTL1_00020",
      "SMCTL1_00021",
      // "SMCTL1_00023",
      "SMCTL1_00025",
      // "SMCTL1_00027",
      // "SMCTL1_00028",
      "SMCTL1_00029",
      "SMCTL1_00031",
      // "SMCTL1_00032",
      "SMCTL1_00035",
      "SMCTL1_00036",
      "SMCTL1_00038",
      "SMCTL1_00039",
      // "SMCTL1_00040",
      "SMCTL1_00041",
      "SMCTL1_00042",
      // "SMCTL1_00044",
      "SMCTL1_00047",
      "SMCTL1_00048",
      "SMCTL1_00050",
      "SMCTL1_00051",
    ],
    //("rightLeg")
    item3: [
      // "SMCTL1_00001",
      // "SMCTL1_00002",
      "SMCTL1_00003",
      "SMCTL1_00004",
      // "SMCTL1_00005",
      "SMCTL1_00006",
      "SMCTL1_00007",
      "SMCTL1_00008",
      "SMCTL1_00009",
      "SMCTL1_00012",
      // "SMCTL1_00015",
      // "SMCTL1_00015",
      "SMCTL1_00016",
      // "SMCTL1_00017",
      // "SMCTL1_00018",
      "SMCTL1_00019",
      "SMCTL1_00020",
      "SMCTL1_00021",
      // "SMCTL1_00023",
      "SMCTL1_00025",
      // "SMCTL1_00028",
      "SMCTL1_00029",
      "SMCTL1_00031",
      // "SMCTL1_00032",
      "SMCTL1_00035",
      "SMCTL1_00036",
      "SMCTL1_00038",
      "SMCTL1_00039",
      // "SMCTL1_00040",
      "SMCTL1_00041",
      "SMCTL1_00042",
      // "SMCTL1_00044",
      "SMCTL1_00047",
      "SMCTL1_00048",
      "SMCTL1_00050",
      "SMCTL1_00051",
    ],
    //("leftLeg")
    item4: [
      // "SMCTL1_00001",
      // "SMCTL1_00002",
      "SMCTL1_00003",
      "SMCTL1_00004",
      // "SMCTL1_00005",
      "SMCTL1_00006",
      "SMCTL1_00007",
      "SMCTL1_00008",
      "SMCTL1_00009",
      "SMCTL1_00012",
      // "SMCTL1_00015",
      "SMCTL1_00016",
      // "SMCTL1_00017",
      // "SMCTL1_00018",
      "SMCTL1_00019",
      "SMCTL1_00020",
      "SMCTL1_00021",
      // "SMCTL1_00023",
      "SMCTL1_00025",
      // "SMCTL1_00028",
      "SMCTL1_00029",
      "SMCTL1_00031",
      // "SMCTL1_00032",
      "SMCTL1_00035",
      "SMCTL1_00036",
      "SMCTL1_00038",
      "SMCTL1_00039",
      // "SMCTL1_00040",
      "SMCTL1_00041",
      "SMCTL1_00042",
      // "SMCTL1_00044",
      "SMCTL1_00047",
      "SMCTL1_00048",
      "SMCTL1_00050",
      "SMCTL1_00051",
    ],
    //("lowerAbdoman")
    item5: [
      // "SMCTL1_00001",
      // "SMCTL1_00002",
      "SMCTL1_00003",
      "SMCTL1_00004",
      // "SMCTL1_00005",
      "SMCTL1_00006",
      "SMCTL1_00007",
      "SMCTL1_00008",
      "SMCTL1_00009",
      "SMCTL1_00011",
      "SMCTL1_00012",
      "SMCTL1_00013",
      // "SMCTL1_00015",
      "SMCTL1_00016",
      // "SMCTL1_00017",
      // "SMCTL1_00018",
      "SMCTL1_00019",
      "SMCTL1_00020",
      "SMCTL1_00021",
      // "SMCTL1_00023",
      "SMCTL1_00025",
      "SMCTL1_00026",
      // "SMCTL1_00028",
      "SMCTL1_00029",
      "SMCTL1_00031",
      // "SMCTL1_00032",
      "SMCTL1_00035",
      "SMCTL1_00036",
      "SMCTL1_00039",
      "SMCTL1_00040",
      "SMCTL1_00041",
      "SMCTL1_00042",
      "SMCTL1_00043",
      // "SMCTL1_00044",
      "SMCTL1_00047",
      "SMCTL1_00048",
      "SMCTL1_00050",
      "SMCTL1_00051",
    ],
    //("abdoman")
    item6: [
      // "SMCTL1_00001",
      // "SMCTL1_00002",
      "SMCTL1_00003",
      "SMCTL1_00004",
      // "SMCTL1_00005",
      "SMCTL1_00006",
      "SMCTL1_00007",
      "SMCTL1_00008",
      "SMCTL1_00010",
      "SMCTL1_00012",
      "SMCTL1_00013",
      // "SMCTL1_00015",
      "SMCTL1_00016",
      // "SMCTL1_00017",
      // "SMCTL1_00018",
      "SMCTL1_00019",
      "SMCTL1_00020",
      "SMCTL1_00021",
      // "SMCTL1_00023",
      "SMCTL1_00025",
      "SMCTL1_00026",
      // "SMCTL1_00028",
      "SMCTL1_00029",
      "SMCTL1_00031",
      // "SMCTL1_00032",
      "SMCTL1_00035",
      "SMCTL1_00036",
      "SMCTL1_00039",
      // "SMCTL1_00040",
      "SMCTL1_00041",
      "SMCTL1_00042",
      // "SMCTL1_00044",
      "SMCTL1_00047",
      "SMCTL1_00048",
      "SMCTL1_00050",
      "SMCTL1_00051",
    ],
    //("chest")
    item7: [
      // "SMCTL1_00001",
      // "SMCTL1_00002",
      "SMCTL1_00003",
      "SMCTL1_00004",
      // "SMCTL1_00005",
      "SMCTL1_00006",
      "SMCTL1_00007",
      "SMCTL1_00008",
      "SMCTL1_00010",
      "SMCTL1_00012",
      "SMCTL1_00014",
      "SMCTL1_00015",
      "SMCTL1_00016",
      // "SMCTL1_00017",
      "SMCTL1_00018",
      "SMCTL1_00019",
      "SMCTL1_00020",
      "SMCTL1_00021",
      // "SMCTL1_00023",
      "SMCTL1_00025",
      "SMCTL1_00028",
      "SMCTL1_00029",
      "SMCTL1_00031",
      // "SMCTL1_00032",
      "SMCTL1_00035",
      "SMCTL1_00036",
      "SMCTL1_00037",
      "SMCTL1_00039",
      // "SMCTL1_00040",
      "SMCTL1_00041",
      "SMCTL1_00042",
      // "SMCTL1_00044",
      "SMCTL1_00047",
      "SMCTL1_00048",
      "SMCTL1_00050",
      "SMCTL1_00051",
    ],
    //("neck")
    item8: [
      // "SMCTL1_00001",
      // "SMCTL1_00002",
      "SMCTL1_00003",
      "SMCTL1_00004",
      // "SMCTL1_00005",
      "SMCTL1_00006",
      "SMCTL1_00007",
      "SMCTL1_00008",
      "SMCTL1_00010",
      "SMCTL1_00012",
      // "SMCTL1_00015",
      "SMCTL1_00016",
      // "SMCTL1_00017",
      "SMCTL1_00018",
      "SMCTL1_00019",
      "SMCTL1_00020",
      "SMCTL1_00021",
      "SMCTL1_00023",
      "SMCTL1_00025",
      // "SMCTL1_00028",
      "SMCTL1_00029",
      "SMCTL1_00031",
      // "SMCTL1_00032",
      "SMCTL1_00033",
      "SMCTL1_00035",
      "SMCTL1_00036",
      "SMCTL1_00039",
      // "SMCTL1_00040",
      "SMCTL1_00041",
      "SMCTL1_00042",
      // "SMCTL1_00044",
      "SMCTL1_00047",
      "SMCTL1_00048",
      "SMCTL1_00050",
      "SMCTL1_00051",
    ],
    // ("head")
    item9: [
      "SMCTL1_00001",
      "SMCTL1_00002",
      "SMCTL1_00003",
      "SMCTL1_00004",
      "SMCTL1_00005",
      "SMCTL1_00006",
      "SMCTL1_00007",
      "SMCTL1_00008",
      "SMCTL1_00010",
      "SMCTL1_00012",
      "SMCTL1_00015",
      "SMCTL1_00016",
      "SMCTL1_00017",
      "SMCTL1_00018",
      "SMCTL1_00019",
      "SMCTL1_00020",
      "SMCTL1_00021",
      "SMCTL1_00022",
      "SMCTL1_00023",
      "SMCTL1_00024",
      "SMCTL1_00025",
      "SMCTL1_00027",
      "SMCTL1_00028",
      "SMCTL1_00029",
      "SMCTL1_00030",
      "SMCTL1_00031",
      "SMCTL1_00032",
      "SMCTL1_00034",
      "SMCTL1_00035",
      "SMCTL1_00036",
      "SMCTL1_00038",
      "SMCTL1_00039",
      // "SMCTL1_00040",
      "SMCTL1_00041",
      "SMCTL1_00042",
      "SMCTL1_00044",
      "SMCTL1_00045",
      "SMCTL1_00046",
      "SMCTL1_00047",
      "SMCTL1_00048",
      "SMCTL1_00050",
      "SMCTL1_00051",
    ],
  };

  const iconMapping =
    patientGender === "male"
      ? maleFirstLevelSymtoms
      : patientGender === "female"
      ? femaleFirstLevelSymtoms
      : {};

  const iconsArrayForSecondLevelSymptoms = [
    { id: "SMCTL2_00001", src: SMCTL2_00001, title: t("abnormal_cry") },
    {
      id: "SMCTL2_00002",
      src: SMCTL2_00002,
      title: t("aggression_irritability"),
    },
    { id: "SMCTL2_00003", src: SMCTL2_00003, title: t("apathy") },
    { id: "SMCTL2_00004", src: SMCTL2_00004, title: t("bizarre_behavior") },
    {
      id: "SMCTL2_00005",
      src: SMCTL2_00005,
      title: t("compulsive_obsessive_behaviour"),
    },
    {
      id: "SMCTL2_00006",
      src: SMCTL2_00006,
      title: t("hyperactive_impatient_behavior"),
    },
    { id: "SMCTL2_00007", src: SMCTL2_00007, title: t("self_harm_neglect") },
    { id: "SMCTL2_00008", src: SMCTL2_00008, title: t("bizarre_movement") },
    { id: "SMCTL2_00009", src: SMCTL2_00009, title: t("lack_of_coordination") },
    { id: "SMCTL2_00010", src: SMCTL2_00010, title: t("other_body_movement") },
    { id: "SMCTL2_00011", src: SMCTL2_00011, title: t("seizures_fits") },
    { id: "SMCTL2_00012", src: SMCTL2_00012, title: t("tremor_tics") },
    { id: "SMCTL2_00013", src: SMCTL2_00013, title: t("bizarre_sensation") },
    { id: "SMCTL2_00014", src: SMCTL2_00014, title: t("bizarre_sensation_anal_perianal_area")},
    { id: "SMCTL2_00015", src: SMCTL2_00015, title: t("burning_sensation") },
    { id: "SMCTL2_00016", src: SMCTL2_00016, title: t("itching") },
    {
      id: "SMCTL2_00017",
      src: SMCTL2_00017,
      title: t("loss_of_sense_of_vibration"),
    },
    { id: "SMCTL2_00018", src: SMCTL2_00018, title: t("numbness_tingling") },
    { id: "SMCTL2_00019", src: SMCTL2_00019, title: t("chills_rigors") },
    { id: "SMCTL2_00020", src: SMCTL2_00020, title: t("fever") },
    { id: "SMCTL2_00021", src: SMCTL2_00021, title: t("frost_bite") },
    { id: "SMCTL2_00022", src: SMCTL2_00022, title: t("hot_cold_sensation") },
    { id: "SMCTL2_00023", src: SMCTL2_00023, title: t("hypothermia") },
    {
      id: "SMCTL2_00024",
      src: SMCTL2_00024,
      title: t("aggression_irritability"),
    },
    { id: "SMCTL2_00025", src: SMCTL2_00025, title: t("anxiety_panic") },
    { id: "SMCTL2_00026", src: SMCTL2_00026, title: t("apathy") },
    { id: "SMCTL2_00027", src: SMCTL2_00027, title: t("bizarre_behavior") },
    {
      id: "SMCTL2_00028",
      src: SMCTL2_00028,
      title: t("compulsive_obsessive_feelings_or_thoughts"),
    },
    {
      id: "SMCTL2_00029",
      src: SMCTL2_00029,
      title: t("delusions_hallucination_illusion"),
    },
    { id: "SMCTL2_00030", src: SMCTL2_00030, title: t("depression") },
    { id: "SMCTL2_00031", src: SMCTL2_00031, title: t("phobia") },
    { id: "SMCTL2_00032", src: SMCTL2_00032, title: t("abnormal_posture") },
    {
      id: "SMCTL2_00033",
      src: SMCTL2_00033,
      title: t("abnormal_posture_head_and_neck"),
    },
    { id: "SMCTL2_00034", src: SMCTL2_00034, title: "Title 34" },
    { id: "SMCTL2_00035", src: SMCTL2_00035, title: t("numbness_tingling") },
    { id: "SMCTL2_00036", src: SMCTL2_00036, title: t("throat_swelling_lump") },
    { id: "SMCTL2_00037", src: SMCTL2_00037, title: "Title 37" },
    { id: "SMCTL2_00038", src: SMCTL2_00038, title: t("dizziness") },
    {
      id: "SMCTL2_00039",
      src: SMCTL2_00039,
      title: t("reduced_consciousness"),
    },
    { id: "SMCTL2_00040", src: SMCTL2_00040, title: t("abnormal_walking") },
    { id: "SMCTL2_00041", src: SMCTL2_00041, title: t("pain_on_walking") },
    { id: "SMCTL2_00042", src: SMCTL2_00042, title: t("acidity") },
    { id: "SMCTL2_00043", src: SMCTL2_00043, title: t("bleeding") },
    { id: "SMCTL2_00044", src: SMCTL2_00044, title: t("discharge") },
    { id: "SMCTL2_00045", src: SMCTL2_00045, title: "Title 45" },

    {
      id: "SMCTL2_00046",
      src: SMCTL2_00046,
      title: t("anorectal_pain_tenderness"),
    },
    { id: "SMCTL2_00047", src: SMCTL2_00047, title: "Symptom 47" },
    { id: "SMCTL2_00048", src: SMCTL2_00048, title: t("anorectal_swelling_lump") },
    { id: "SMCTL2_00049", src: SMCTL2_00049, title: "Symptom 49" },
    { id: "SMCTL2_00050", src: SMCTL2_00050, title: t("low_blood_pressure") },
    { id: "SMCTL2_00051", src: SMCTL2_00051, title: "Symptom 51" },
    {
      id: "SMCTL2_00052",
      src: SMCTL2_00052,
      title: t("abnormal_bowel_sounds"),
    },
    { id: "SMCTL2_00053", src: SMCTL2_00053, title: t("abnormal_stools") },
    { id: "SMCTL2_00054", src: SMCTL2_00054, title: t("bowel_incontinence") },
    { id: "SMCTL2_00055", src: SMCTL2_00055, title: t("constipation") },
    { id: "SMCTL2_00056", src: SMCTL2_00056, title: t("diarrhoea") },
    { id: "SMCTL2_00057", src: SMCTL2_00057, title: t("abnormal_discharge") },
    { id: "SMCTL2_00058", src: SMCTL2_00058, title: t("breast_swelling_lump") },
    {
      id: "SMCTL2_00059",
      src: SMCTL2_00059,
      title: t("other_breast_problems"),
    },
    {
      id: "SMCTL2_00060",
      src: SMCTL2_00060,
      title: t("breast_pain_tenderness"),
    },
    { id: "SMCTL2_00061", src: SMCTL2_00061, title: t("choking_gasping") },
    { id: "SMCTL2_00062", src: SMCTL2_00062, title: t("shortness_of_breath") },
    { id: "SMCTL2_00063", src: SMCTL2_00063, title: t("burn") },
    {
      id: "SMCTL2_00064",
      src: SMCTL2_00064,
      title: t("communication_impairment"),
    },
    {
      id: "SMCTL2_00065",
      src: SMCTL2_00065,
      title: t("impaired_intellect"),
    },
    {
      id: "SMCTL2_00066",
      src: SMCTL2_00066,
      title: t("lack_of_concentration"),
    },
    { id: "SMCTL2_00067", src: SMCTL2_00067, title: t("loss_of_memory") },
    { id: "SMCTL2_00068", src: SMCTL2_00068, title: t("cough_abnormal_sound") },
    { id: "SMCTL2_00069", src: SMCTL2_00069, title: "Symptom 69" },
    { id: "SMCTL2_00070", src: SMCTL2_00070, title: t("cough_with_blood") },
    { id: "SMCTL2_00071", src: SMCTL2_00071, title: t("cough_with_sputum") },
    {
      id: "SMCTL2_00072",
      src: SMCTL2_00072,
      title: t("other_cough_related_issues"),
    },
    { id: "SMCTL2_00073", src: SMCTL2_00073, title: "Symptom 73" },
    { id: "SMCTL2_00074", src: SMCTL2_00074, title: "Symptom 74" },
    { id: "SMCTL2_00075", src: SMCTL2_00075, title: t("chest_deformity") },
    { id: "SMCTL2_00076", src: SMCTL2_00076, title: "Symptom 76" },
    { id: "SMCTL2_00077", src: SMCTL2_00077, title: "Symptom 77" },
    {
      id: "SMCTL2_00078",
      src: SMCTL2_00078,
      title: t("hair_scalp_nail_deformity"),
    },
    { id: "SMCTL2_00079", src: SMCTL2_00079, title: t("hand_deformity") },
    {
      id: "SMCTL2_00080",
      src: SMCTL2_00080,
      title: t("head_facial_deformity"),
    },
    { id: "SMCTL2_00081", src: SMCTL2_00081, title: t("hip_deformity") },
    { id: "SMCTL2_00082", src: SMCTL2_00082, title: t("leg_foot_deformity") },
    { id: "SMCTL2_00083", src: SMCTL2_00083, title: "Symptom 83" },
    { id: "SMCTL2_00084", src: SMCTL2_00084, title: t("male_genitals") },
    { id: "SMCTL2_00085", src: SMCTL2_00085, title: t("neck_deformity") },
    { id: "SMCTL2_00086", src: SMCTL2_00086, title: t("other_deformities") },
    { id: "SMCTL2_00087", src: SMCTL2_00087, title: t("shoulder_deformity") },
    { id: "SMCTL2_00088", src: SMCTL2_00088, title: t("spinal_deformity") },
    { id: "SMCTL2_00089", src: SMCTL2_00089, title: t("delayed_milestones") },
    {
      id: "SMCTL2_00090",
      src: SMCTL2_00090,
      title: t("genitourinary_deformity"),
    },

    { id: "SMCTL2_00091", src: SMCTL2_00091, title: t("growth_retardation") },
    { id: "SMCTL2_00092", src: SMCTL2_00092, title: "Symptom Title 92" },
    {
      id: "SMCTL2_00093",
      src: SMCTL2_00093,
      title: t("sexual_maturation_delay_failure"),
    },
    { id: "SMCTL2_00094", src: SMCTL2_00094, title: "Symptom Title 94" },
    { id: "SMCTL2_00095", src: SMCTL2_00095, title: "Symptom Title 95" },
    { id: "SMCTL2_00096", src: SMCTL2_00096, title: "Symptom Title 96" },
    { id: "SMCTL2_00097", src: SMCTL2_00097, title: "Symptom Title 97" },
    { id: "SMCTL2_00098", src: SMCTL2_00098, title: "Symptom Title 98" },
    { id: "SMCTL2_00099", src: SMCTL2_00099, title: t("infection") },
    { id: "SMCTL2_00100", src: SMCTL2_00100, title: "Symptom Title 100" },
    { id: "SMCTL2_00101", src: SMCTL2_00101, title: "Symptom Title 101" },
    { id: "SMCTL2_00102", src: SMCTL2_00102, title: "Symptom Title 102" },
    { id: "SMCTL2_00103", src: SMCTL2_00103, title: "Symptom Title 103" },
    { id: "SMCTL2_00104", src: SMCTL2_00104, title: "Symptom Title 104" },
    { id: "SMCTL2_00105", src: SMCTL2_00105, title: "Symptom Title 105" },
    { id: "SMCTL2_00106", src: SMCTL2_00106, title: t("stroke") },
    { id: "SMCTL2_00107", src: SMCTL2_00107, title: "Symptom Title 107" },
    { id: "SMCTL2_00108", src: SMCTL2_00108, title: t("abnormal_discharge") },
    { id: "SMCTL2_00109", src: SMCTL2_00109, title: t("bleeding") },
    { id: "SMCTL2_00110", src: SMCTL2_00110, title: t("deafness") },
    { id: "SMCTL2_00111", src: SMCTL2_00111, title: t("ear_itching") },
    { id: "SMCTL2_00112", src: SMCTL2_00112, title: t("ear_wax") },
    { id: "SMCTL2_00113", src: SMCTL2_00113, title: t("other_ear_problems") },
    { id: "SMCTL2_00114", src: SMCTL2_00114, title: t("pain_tenderness") },
    { id: "SMCTL2_00115", src: SMCTL2_00115, title: t("binge_eating") },
    {
      id: "SMCTL2_00116",
      src: SMCTL2_00116,
      title: t("decreased_increased_appetite"),
    },
    {
      id: "SMCTL2_00117",
      src: SMCTL2_00117,
      title: t("eating_non_food_materials"),
    },
    { id: "SMCTL2_00118", src: SMCTL2_00118, title: t("feeding_difficulties") },
    { id: "SMCTL2_00119", src: SMCTL2_00119, title: t("increased_decreased_thirst") },
    { id: "SMCTL2_00120", src: SMCTL2_00120, title: t("taste_problem") },
    { id: "SMCTL2_00121", src: SMCTL2_00121, title: t("abnormal_discharge") },
    { id: "SMCTL2_00122", src: SMCTL2_00122, title: t("abnormal_movement") },
    {
      id: "SMCTL2_00123",
      src: SMCTL2_00123,
      title: t("abnormal_eye_position"),
    },
    { id: "SMCTL2_00124", src: SMCTL2_00124, title: t("bleeding") },
    { id: "SMCTL2_00125", src: SMCTL2_00125, title: t("colour_blindness") },
    { id: "SMCTL2_00126", src: SMCTL2_00126, title: t("decreased_vision") },
    { id: "SMCTL2_00127", src: SMCTL2_00127, title: t("double_vision") },
    { id: "SMCTL2_00128", src: SMCTL2_00128, title: t("dry_eyes") },
    {
      id: "SMCTL2_00129",
      src: SMCTL2_00129,
      title: t("eyelid_eyebrows_eyelash"),
    },
    { id: "SMCTL2_00130", src: SMCTL2_00130, title: t("intolerance_to_light") },
    { id: "SMCTL2_00131", src: SMCTL2_00131, title: t("irritation_itching") },
    { id: "SMCTL2_00132", src: SMCTL2_00132, title: t("other_eye_problems") },
    {
      id: "SMCTL2_00133",
      src: SMCTL2_00133,
      title: t("other_visual_problems"),
    },
    { id: "SMCTL2_00134", src: SMCTL2_00134, title: t("eye_pain_tenderness") },
    { id: "SMCTL2_00135", src: SMCTL2_00135, title: t("pallor") },

    { id: "SMCTL2_00136", src: SMCTL2_00136, title: t("pupilary_problems") },
    { id: "SMCTL2_00137", src: SMCTL2_00137, title: t("rapid_slow_blinking") },
    { id: "SMCTL2_00138", src: SMCTL2_00138, title: t("redness_of_eye") },
    { id: "SMCTL2_00139", src: SMCTL2_00139, title: t("eye_swelling_lump") },
    { id: "SMCTL2_00140", src: SMCTL2_00140, title: t("trauma_infection") },
    { id: "SMCTL2_00141", src: SMCTL2_00141, title: t("gangrene") },
    { id: "SMCTL2_00142M", src: SMCTL2_00142M, title: t("abnormal_discharge") },
    { id: "SMCTL2_00142F", src: SMCTL2_00142F, title: t("abnormal_discharge") },
    {
      id: "SMCTL2_00143",
      src: SMCTL2_00143,
      title: t("abnormal_urine_flow_voicing"),
    },
    { id: "SMCTL2_00144", src: SMCTL2_00144, title: t("bleeding") },
    { id: "SMCTL2_00145", src: SMCTL2_00145, title: t("bleeding") },
    { id: "SMCTL2_00146", src: SMCTL2_00146, title: t("difficulty_urination") },
    { id: "SMCTL2_00147", src: SMCTL2_00147, title: t("foul_smelling_urine") },
    {
      id: "SMCTL2_00148",
      src: SMCTL2_00148,
      title: t("fullness_pressure_prolapse"),
    },
    {
      id: "SMCTL2_00149",
      src: SMCTL2_00149,
      title: t("increased_decreased_output"),
    },
    { id: "SMCTL2_00150", src: SMCTL2_00150, title: t("itching_dryness") },
    { id: "SMCTL2_00151", src: SMCTL2_00151, title: t("menstrual_problems") },
    {
      id: "SMCTL2_00152",
      src: SMCTL2_00152,
      title: t("other_genito_urinary_problem"),
    },
    {
      id: "SMCTL2_00153",
      src: SMCTL2_00153,
      title: t("genito_urinary_pain_tenderness"),
    },
    { id: "SMCTL2_00154", src: SMCTL2_00154, title: t("genito_urinary_swelling_lump") },
    { id: "SMCTL2_00155", src: SMCTL2_00155, title: t("urinary_incontinence") },
    { id: "SMCTL2_00156", src: SMCTL2_00156, title: t("urine_discoloration") },
    { id: "SMCTL2_00157", src: SMCTL2_00157, title: t("bleeding") },
    {
      id: "SMCTL2_00158",
      src: SMCTL2_00158,
      title: t("discoloration_of_nails"),
    },
    { id: "SMCTL2_00159", src: SMCTL2_00159, title: t("excessive_body_hair") },
    { id: "SMCTL2_00160", src: SMCTL2_00160, title: t("hair_loss") },
    { id: "SMCTL2_00161", src: SMCTL2_00161, title: "test 161" },
    { id: "SMCTL2_00162", src: SMCTL2_00162, title: t("nail_abnormality") },
    { id: "SMCTL2_00163", src: SMCTL2_00163, title: t("other_hair_problems") },
    { id: "SMCTL2_00164", src: SMCTL2_00164, title: t("other_nail_problems") },
    { id: "SMCTL2_00165", src: SMCTL2_00165, title: t("nail_swelling_lump") },
    { id: "SMCTL2_00166", src: SMCTL2_00166, title: t("hiccups") },
    { id: "SMCTL2_00167", src: SMCTL2_00167, title: t("dislocation") },
    { id: "SMCTL2_00168", src: SMCTL2_00168, title: t("fracture") },
    { id: "SMCTL2_00169", src: SMCTL2_00169, title: t("injury") },
    { id: "SMCTL2_00171", src: SMCTL2_00171, title: t("trauma_injury") },
    { id: "SMCTL2_00172", src: SMCTL2_00172, title: t("bad_breath") },
    { id: "SMCTL2_00173", src: SMCTL2_00173, title: t("bleeding") },
    { id: "SMCTL2_00174", src: SMCTL2_00174, title: t("discoloration") },
    { id: "SMCTL2_00175", src: SMCTL2_00175, title: t("dry_mouth") },
    { id: "SMCTL2_00176", src: SMCTL2_00176, title: t("lip_problem") },
    { id: "SMCTL2_00177", src: SMCTL2_00177, title: t("lip_problem") },
    {
      id: "SMCTL2_00178",
      src: SMCTL2_00178,
      title: t("other_mouth_dental_problems"),
    },
    {
      id: "SMCTL2_00179",
      src: SMCTL2_00179,
      title: t("mouth_or_dental_pain_tenderness"),
    },
    { id: "SMCTL2_00180", src: SMCTL2_00180, title: t("redness_inflammation") },
    { id: "SMCTL2_00181", src: SMCTL2_00181, title: t("mouth_swelling_lump") },
    { id: "SMCTL2_00182", src: SMCTL2_00182, title: t("tongue_problems") },
    { id: "SMCTL2_00183", src: SMCTL2_00183, title: t("tooth_problems") },
    {
      id: "SMCTL2_00184",
      src: SMCTL2_00184,
      title: t("uncontrolled_excessive_salivation"),
    },
    { id: "SMCTL2_00185", src: SMCTL2_00185, title: "test 185" },
    {
      id: "SMCTL2_00186",
      src: SMCTL2_00186,
      title: t("abnormal_muscle_tone_reflex_flexibility"),
    },
    { id: "SMCTL2_00187", src: SMCTL2_00187, title: t("abnormal_position") },
    {
      id: "SMCTL2_00188",
      src: SMCTL2_00188,
      title: t("abnormal_sounds_joints"),
    },
    { id: "SMCTL2_00189", src: SMCTL2_00189, title: t("hip_joint_problem") },
    { id: "SMCTL2_00190", src: SMCTL2_00190, title: t("instability_weakness") },
    { id: "SMCTL2_00191", src: SMCTL2_00191, title: t("joint_swelling") },
    {
      id: "SMCTL2_00192",
      src: SMCTL2_00192,
      title: t("muscle_atrophy_wasting"),
    },
    {
      id: "SMCTL2_00193",
      src: SMCTL2_00193,
      title: t("muscle_hypertrophy_growth"),
    },
    { id: "SMCTL2_00194", src: SMCTL2_00194, title: t("muscle_spasm") },
    { id: "SMCTL2_00195", src: SMCTL2_00195, title: t("other_joint_problems") },
    { id: "SMCTL2_00196", src: SMCTL2_00196, title: t("other_musculoskeletal_problems") },
    {
      id: "SMCTL2_00198",
      src: SMCTL2_00198,
      title: t("musculostekeletal_pain_tenderness"),
    },
    { id: "SMCTL2_00199", src: SMCTL2_00199, title: t("restricted_joint_movement_stiffness") },
    { id: "SMCTL2_00200", src: SMCTL2_00200, title: t("nausea") },
    { id: "SMCTL2_00201", src: SMCTL2_00201, title: t("vomiting") },
    { id: "SMCTL2_00202", src: SMCTL2_00202, title: t("abnormal_discharge") },
    { id: "SMCTL2_00203", src: SMCTL2_00203, title: t("congestion") },
    { id: "SMCTL2_00204", src: SMCTL2_00204, title: t("dry_throat") },
    { id: "SMCTL2_00205", src: SMCTL2_00205, title: t("redness_inflammation") },
    {
      id: "SMCTL2_00206",
      src: SMCTL2_00206,
      title: t("sore_throat_pain_tenderness"),
    },
    { id: "SMCTL2_00207", src: SMCTL2_00207, title: t("stiff_neck") },
    {
      id: "SMCTL2_00208",
      src: SMCTL2_00208,
      title: t("neck_or_throat_swelling_lump"),
    },
    { id: "SMCTL2_00209", src: SMCTL2_00209, title: t("voice_problems") },
    { id: "SMCTL2_00210", src: SMCTL2_00210, title: t("abnormal_discharge") },
    { id: "SMCTL2_00211", src: SMCTL2_00211, title: t("bleeding") },
    { id: "SMCTL2_00212", src: SMCTL2_00212, title: t("other_nose_problems") },
    {
      id: "SMCTL2_00213",
      src: SMCTL2_00213,
      title: t("other_non_specific_bleeding"),
    },
    { id: "SMCTL2_00214", src: SMCTL2_00214, title: "test 214" },
    { id: "SMCTL2_00215", src: SMCTL2_00215, title: t("abdominal_pain") },
    { id: "SMCTL2_00216", src: SMCTL2_00216, title: t("ankle") },
    { id: "SMCTL2_00217", src: SMCTL2_00217, title: t("anorectal_region") },
    { id: "SMCTL2_00218", src: SMCTL2_00218, title: "test 218" },
    { id: "SMCTL2_00219", src: SMCTL2_00219, title: t("chest_pain") },
    { id: "SMCTL2_00220", src: SMCTL2_00220, title: t("facial_pain") },
    {
      id: "SMCTL2_00221",
      src: SMCTL2_00221,
      title: t("generalised_back_pain"),
    },
    { id: "SMCTL2_00222", src: SMCTL2_00222, title: t("generalised_pain") },
    { id: "SMCTL2_00223", src: SMCTL2_00223, title: t("headache") },
    { id: "SMCTL2_00224", src: SMCTL2_00224, title: t("hip_pain") },
    {
      id: "SMCTL2_00225",
      src: SMCTL2_00225,
      title: t("insect_stings_or_bites"),
    },
    { id: "SMCTL2_00226", src: SMCTL2_00226, title: t("jaw_pain") },
    { id: "SMCTL2_00227", src: SMCTL2_00227, title: t("limb_pain") },
    { id: "SMCTL2_00228", src: SMCTL2_00228, title: t("localised_back_pain") },
    { id: "SMCTL2_00229", src: SMCTL2_00229, title: t("localised_pain") },
    { id: "SMCTL2_00230", src: SMCTL2_00230, title: t("neck_pain") },
    {
      id: "SMCTL2_00231",
      src: SMCTL2_00231,
      title: t("other_pain_Tenderness"),
    },
    { id: "SMCTL2_00232", src: SMCTL2_00232, title: t("pain_on_chewing") },
    { id: "SMCTL2_00233", src: SMCTL2_00233, title: t("pelvis_groin_pain") },
    { id: "SMCTL2_00234", src: SMCTL2_00234, title: "test 234" },
    {
      id: "SMCTL2_00235",
      src: SMCTL2_00235,
      title: t("heart_rate_increased_decreased_irregular"),
    },
    { id: "SMCTL2_00236", src: SMCTL2_00236, title: t("other_heart_problems") },
    { id: "SMCTL2_00237", src: SMCTL2_00237, title: t("paralysis") },
    { id: "SMCTL2_00239", src: SMCTL2_00239, title: t("paresis") },
    { id: "SMCTL2_00240", src: SMCTL2_00240, title: t("abdomen") },
    { id: "SMCTL2_00241", src: SMCTL2_00241, title: "test 241" },
    { id: "SMCTL2_00242", src: SMCTL2_00242, title: "test 242" },
    {
      id: "SMCTL2_00243",
      src: SMCTL2_00243,
      title: t("chest_abnormal_breathing_sounds"),
    },
    { id: "SMCTL2_00244", src: SMCTL2_00244, title: "test 244" },
    { id: "SMCTL2_00245", src: SMCTL2_00245, title: t("female_genitals") },
    {
      id: "SMCTL2_00246",
      src: SMCTL2_00246,
      title: t("fundoscopic_examination_eye"),
    },

    { id: "SMCTL2_00247", src: SMCTL2_00247, title: "test 247" },
    {
      id: "SMCTL2_00248",
      src: SMCTL2_00248,
      title: t("genito_urinary_examination"),
    },
    {
      id: "SMCTL2_00249",
      src: SMCTL2_00249,
      title: t("heart_abnormal_heart_sound"),
    },
    {
      id: "SMCTL2_00250",
      src: SMCTL2_00250,
      title: t("intraocular_pressure_Eye"),
    },
    { id: "SMCTL2_00251", src: SMCTL2_00251, title: "Test 251" },
    { id: "SMCTL2_00252", src: SMCTL2_00252, title: t("ophthalmoscopy_eye") },
    { id: "SMCTL2_00253", src: SMCTL2_00253, title: "Test 253" },
    {
      id: "SMCTL2_00254",
      src: SMCTL2_00254,
      title: t("other_physical_examination"),
    },
    { id: "SMCTL2_00255", src: SMCTL2_00255, title: t("otoscopy_ear") },
    { id: "SMCTL2_00256", src: SMCTL2_00256, title: "Test 256" },
    { id: "SMCTL2_00257", src: SMCTL2_00257, title: t("percussion") },
    { id: "SMCTL2_00258", src: SMCTL2_00258, title: t("pregnancy") },
    { id: "SMCTL2_00259", src: SMCTL2_00259, title: "Test 259" },
    { id: "SMCTL2_00260", src: SMCTL2_00260, title: "Test 260" },
    { id: "SMCTL2_00261", src: SMCTL2_00261, title: "Test 261" },
    { id: "SMCTL2_00263", src: SMCTL2_00263, title: "Test 263" },
    { id: "SMCTL2_00264", src: SMCTL2_00264, title: "Test 264" },
    { id: "SMCTL2_00265", src: SMCTL2_00265, title: t("pregnancy_problems") },
    { id: "SMCTL2_00266", src: SMCTL2_00266, title: "Test 266" },
    { id: "SMCTL2_00267", src: SMCTL2_00267, title: t("pulse_faint") },
    { id: "SMCTL2_00268", src: SMCTL2_00268, title: "Test 268" },
    { id: "SMCTL2_00269", src: SMCTL2_00269, title: t("pulse_irregular") },
    { id: "SMCTL2_00270", src: SMCTL2_00270, title: "Test 270" },
    { id: "SMCTL2_00271", src: SMCTL2_00271, title: t("abnormal_skin") },
    { id: "SMCTL2_00272", src: SMCTL2_00272, title: t("blister") },
    { id: "SMCTL2_00273", src: SMCTL2_00273, title: t("blueness_of_skin") },
    { id: "SMCTL2_00274", src: SMCTL2_00274, title: t("bruise") },
    { id: "SMCTL2_00275", src: SMCTL2_00275, title: t("eye") },
    { id: "SMCTL2_00276", src: SMCTL2_00276, title: t("mole_pimples") },
    {
      id: "SMCTL2_00277",
      src: SMCTL2_00277,
      title: t("mouth_dental_lesions_ulcers"),
    },
    { id: "SMCTL2_00278", src: SMCTL2_00278, title: "Test 278" },
    { id: "SMCTL2_00279", src: SMCTL2_00279, title: t("pallor_skin") },
    { id: "SMCTL2_00280", src: SMCTL2_00280, title: t("rash") },
    { id: "SMCTL2_00281", src: SMCTL2_00281, title: t("reddening_of_skin") },
    {
      id: "SMCTL2_00282",
      src: SMCTL2_00282,
      title: t("redness_inflammation_skin"),
    },
    { id: "SMCTL2_00283", src: SMCTL2_00283, title: t("scaling") },
    { id: "SMCTL2_00284", src: SMCTL2_00284, title: t("scalp") },
    { id: "SMCTL2_00285", src: SMCTL2_00285, title: t("scar") },
    { id: "SMCTL2_00286", src: SMCTL2_00286, title: t("skin_swelling_lump") },
    { id: "SMCTL2_00287", src: SMCTL2_00287, title: t("ulcer") },
    { id: "SMCTL2_00288", src: SMCTL2_00288, title: t("white_spots") },
    { id: "SMCTL2_00289", src: SMCTL2_00289, title: t("xanthoma") },
    {
      id: "SMCTL2_00290",
      src: SMCTL2_00290,
      title: t("yellowishness_of_skin"),
    },
    {
      id: "SMCTL2_00291",
      src: SMCTL2_00291,
      title: t("abnormal_sexual_desire"),
    },
    { id: "SMCTL2_00292", src: SMCTL2_00292, title: t("homosexuality") },
    {
      id: "SMCTL2_00293",
      src: SMCTL2_00293,
      title: t("other_sexual_problems"),
    },
    { id: "SMCTL2_00294", src: SMCTL2_00294, title: t("drowsiness") },
    { id: "SMCTL2_00295", src: SMCTL2_00295, title: t("sleep_disturbance") },

    { id: "SMCTL2_00296", src: SMCTL2_00296, title: t("snoring") },
    { id: "SMCTL2_00297", src: SMCTL2_00297, title: t("sneezing") },
    { id: "SMCTL2_00298", src: SMCTL2_00298, title: t("speech_impairment") },
    { id: "SMCTL2_00299", src: SMCTL2_00299, title: t("sweating") },
    { id: "SMCTL2_00300", src: SMCTL2_00300, title: t("other_swelling_lump") },
    {
      id: "SMCTL2_00301",
      src: SMCTL2_00301,
      title: t("other_swelling_lump_unspecified"),
    },
    {
      id: "SMCTL2_00302",
      src: SMCTL2_00302,
      title: t("swelling_lump_lymph_node"),
    },
    { id: "SMCTL2_00303", src: SMCTL2_00303, title: "test 303" },
    { id: "SMCTL2_00304", src: SMCTL2_00304, title: "test 304" },
    { id: "SMCTL2_00305", src: SMCTL2_00305, title: "test 305" },
    { id: "SMCTL2_00306", src: SMCTL2_00306, title: "test 306" },
    { id: "SMCTL2_00308", src: SMCTL2_00308, title: "test 308" },
    { id: "SMCTL2_00309", src: SMCTL2_00309, title: "test 309" },
    { id: "SMCTL2_00310", src: SMCTL2_00310, title: "test 310" },
    { id: "SMCTL2_00311", src: SMCTL2_00311, title: "test 311" },
    { id: "SMCTL2_00312", src: SMCTL2_00312, title: t("generalized_weakness") },
    { id: "SMCTL2_00313", src: SMCTL2_00313, title: t("malaise") },
    { id: "SMCTL2_00314", src: SMCTL2_00314, title: t("weakness") },
    { id: "SMCTL2_00315", src: SMCTL2_00315, title: "test 315" },
    { id: "SMCTL2_00316", src: SMCTL2_00316, title: t("weight_gain_obesity") },
    { id: "SMCTL2_00317", src: SMCTL2_00317, title: t("weight_loss") },
  ];

  //   const iconsArrayForSecondLevelSymptoms = [
  //   { id: "SMCTL2_00001", src: SMCTL2_00001, title: "Abnormal Behaviour" },
  // ];

  const secondLevelMalemapping = {
    SMCTL1_00001: [
      "SMCTL2_00001",
      "SMCTL2_00002",
      "SMCTL2_00004",
      "SMCTL2_00006",
      "SMCTL2_00005",
      "SMCTL2_00003",
      "SMCTL2_00007",
    ],
    SMCTL1_00002: [
      "SMCTL2_00010",
      "SMCTL2_00011",
      "SMCTL2_00012",
      "SMCTL2_00008",
      "SMCTL2_00009",
    ],
    SMCTL1_00003: [
      "SMCTL2_00013",
      "SMCTL2_00014",
      "SMCTL2_00015",
      "SMCTL2_00016",
      "SMCTL2_00018",
      "SMCTL2_00017",
    ],
    SMCTL1_00004: [
      "SMCTL2_00019",
      "SMCTL2_00023",
      "SMCTL2_00022",
      "SMCTL2_00020",
      "SMCTL2_00021",
    ],
    SMCTL1_00005: [
      "SMCTL2_00025",
      "SMCTL2_00026",
      "SMCTL2_00029",
      "SMCTL2_00030",
      "SMCTL2_00024",
      "SMCTL2_00027",
      "SMCTL2_00028",
      "SMCTL2_00031",
    ],
    SMCTL1_00006: ["SMCTL2_00032", "SMCTL2_00033"],
    SMCTL1_00007: ["SMCTL2_00035", "SMCTL2_00036"],
    SMCTL1_00008: ["SMCTL2_00039", "SMCTL2_00038"],
    SMCTL1_00009: ["SMCTL2_00040", "SMCTL2_00041"],
    SMCTL1_00010: ["SMCTL2_00042"],
    SMCTL1_00011: [
      "SMCTL2_00044",
      "SMCTL2_00043",
      "SMCTL2_00046",
      "SMCTL2_00048",
    ],
    SMCTL1_00012: ["SMCTL2_00050"],
    SMCTL1_00013: [
      "SMCTL2_00052",
      "SMCTL2_00053",
      "SMCTL2_00055",
      "SMCTL2_00056",
      "SMCTL2_00054",
    ],
    SMCTL1_00014: ["SMCTL2_00059", "SMCTL2_00057"],
    SMCTL1_00015: ["SMCTL2_00061", "SMCTL2_00062"],
    SMCTL1_00016: ["SMCTL2_00063"],
    SMCTL1_00017: [
      "SMCTL2_00066",
      "SMCTL2_00065",
      "SMCTL2_00064",
      "SMCTL2_00067",
    ],
    SMCTL1_00018: [
      "SMCTL2_00072",
      "SMCTL2_00070",
      "SMCTL2_00071",
      "SMCTL2_00068",
    ],
    SMCTL1_00019: [
      "SMCTL2_00086",
      "SMCTL2_00084",
      "SMCTL2_00075",
      "SMCTL2_00082",
      "SMCTL2_00079",
      "SMCTL2_00080",
      "SMCTL2_00081",
      "SMCTL2_00087",
      "SMCTL2_00088",
      "SMCTL2_00078",
      "SMCTL2_00085",
    ],
    SMCTL1_00020: [
      "SMCTL2_00089",
      "SMCTL2_00093",
      "SMCTL2_00091",
      "SMCTL2_00090",
    ],
    SMCTL1_00021: ["SMCTL2_00099", "SMCTL2_00106"],
    SMCTL1_00022: [
      "SMCTL2_00109",
      "SMCTL2_00110",
      "SMCTL2_00108",
      "SMCTL2_00114",
      "SMCTL2_00111",
      "SMCTL2_00112",
      "SMCTL2_00113",
    ],
    SMCTL1_00023: [
      "SMCTL2_00120",
      "SMCTL2_00116",
      "SMCTL2_00115",
      "SMCTL2_00119",
      "SMCTL2_00118",
      "SMCTL2_00117",
    ],
    SMCTL1_00024: [
      "SMCTL2_00122",
      "SMCTL2_00123",
      "SMCTL2_00132",
      "SMCTL2_00124",
      "SMCTL2_00126",
      "SMCTL2_00125",
      "SMCTL2_00135",
      "SMCTL2_00138",
      "SMCTL2_00136",
      "SMCTL2_00129",
      "SMCTL2_00127",
      "SMCTL2_00128",
      "SMCTL2_00121",
      "SMCTL2_00131",
      "SMCTL2_00134",
      "SMCTL2_00140",
      "SMCTL2_00139",
      "SMCTL2_00130",
      "SMCTL2_00137",
      "SMCTL2_00133",
    ],
    SMCTL1_00025: ["SMCTL2_00141"],
    SMCTL1_00026: [
      "SMCTL2_00143",
      "SMCTL2_00147",
      "SMCTL2_00144",
      "SMCTL2_00146",
      "SMCTL2_00149",
      "SMCTL2_00142M",
      "SMCTL2_00152",
      "SMCTL2_00154",
      "SMCTL2_00153",
      "SMCTL2_00150",
      "SMCTL2_00155",
      "SMCTL2_00156",
    ],
    SMCTL1_00027: [
      "SMCTL2_00160",
      "SMCTL2_00157",
      "SMCTL2_00163",
      "SMCTL2_00164",
      "SMCTL2_00158",
      "SMCTL2_00159",
      "SMCTL2_00162",
      "SMCTL2_00165",
    ],
    SMCTL1_00028: ["SMCTL2_00166"],
    SMCTL1_00029: [
      "SMCTL2_00168",
      "SMCTL2_00167",
      "SMCTL2_00171",
      "SMCTL2_00169",
    ],
    SMCTL1_00030: [
      "SMCTL2_00172",
      "SMCTL2_00173",
      "SMCTL2_00183",
      "SMCTL2_00184",
      "SMCTL2_00178",
      "SMCTL2_00175",
      "SMCTL2_00174",
      "SMCTL2_00182",
      "SMCTL2_00176",
      "SMCTL2_00181",
      "SMCTL2_00179",
      "SMCTL2_00177",
      "SMCTL2_00180",
    ],
    SMCTL1_00031: [
      "SMCTL2_00193",
      "SMCTL2_00186",
      "SMCTL2_00199",
      "SMCTL2_00194",
      "SMCTL2_00198",
      "SMCTL2_00187",
      "SMCTL2_00188",
      "SMCTL2_00189",
      "SMCTL2_00195",
      "SMCTL2_00190",
      "SMCTL2_00191",
      "SMCTL2_00196",
      "SMCTL2_00192",
    ],
    SMCTL1_00032: ["SMCTL2_00200", "SMCTL2_00201"],
    SMCTL1_00033: [
      "SMCTL2_00209",
      "SMCTL2_00202",
      "SMCTL2_00204",
      "SMCTL2_00206",
      "SMCTL2_00208",
      "SMCTL2_00203",
      "SMCTL2_00205",
      "SMCTL2_00207",
    ],
    SMCTL1_00034: ["SMCTL2_00211", "SMCTL2_00210", "SMCTL2_00212"],
    SMCTL1_00035: ["SMCTL2_00213"],
    SMCTL1_00036: [
      "SMCTL2_00215",
      "SMCTL2_00216",
      "SMCTL2_00227",
      "SMCTL2_00221",
      "SMCTL2_00222",
      "SMCTL2_00219",
      "SMCTL2_00220",
      "SMCTL2_00223",
      "SMCTL2_00228",
      "SMCTL2_00233",
      "SMCTL2_00224",
      "SMCTL2_00225",
      "SMCTL2_00232",
      "SMCTL2_00226",
      "SMCTL2_00230",
      "SMCTL2_00229",
      "SMCTL2_00217",
      "SMCTL2_00231",
    ],
    SMCTL1_00037: ["SMCTL2_00236", "SMCTL2_00235"],
    SMCTL1_00038: ["SMCTL2_00239", "SMCTL2_00237"],
    SMCTL1_00039: [
      "SMCTL2_00257",
      "SMCTL2_00240",
      "SMCTL2_00252",
      "SMCTL2_00243",
      "SMCTL2_00254",
      "SMCTL2_00250",
      "SMCTL2_00246",
      "SMCTL2_00249",
      "SMCTL2_00255",
      "SMCTL2_00248",
    ],
    SMCTL1_00041: ["SMCTL2_00267", "SMCTL2_00269"],
    SMCTL1_00042: [
      "SMCTL2_00271",
      "SMCTL2_00286",
      "SMCTL2_00287",
      "SMCTL2_00272",
      "SMCTL2_00275",
      "SMCTL2_00274",
      "SMCTL2_00277",
      "SMCTL2_00280",
      "SMCTL2_00273",
      "SMCTL2_00281",
      "SMCTL2_00290",
      "SMCTL2_00276",
      "SMCTL2_00279",
      "SMCTL2_00282",
      "SMCTL2_00284",
      "SMCTL2_00283",
      "SMCTL2_00285",
      "SMCTL2_00289",
    ],
    SMCTL1_00043: ["SMCTL2_00293", "SMCTL2_00291", "SMCTL2_00292"],
    SMCTL1_00044: ["SMCTL2_00295", "SMCTL2_00294", "SMCTL2_00296"],
    SMCTL1_00045: ["SMCTL2_00297"],
    SMCTL1_00046: ["SMCTL2_00298"],
    SMCTL1_00047: ["SMCTL2_00299"],
    SMCTL1_00048: ["SMCTL2_00300", "SMCTL2_00302", "SMCTL2_00301"],
    SMCTL1_00050: ["SMCTL2_00314", "SMCTL2_00313", "SMCTL2_00312"],
    SMCTL1_00051: ["SMCTL2_00316", "SMCTL2_00317"],
  };

  const secondLevelFemalemapping = {
    SMCTL1_00001: [
      "SMCTL2_00001",
      "SMCTL2_00002",
      "SMCTL2_00004",
      "SMCTL2_00006",
      "SMCTL2_00005",
      "SMCTL2_00003",
      "SMCTL2_00007",
    ],
    SMCTL1_00002: [
      "SMCTL2_00010",
      "SMCTL2_00011",
      "SMCTL2_00012",
      "SMCTL2_00008",
      "SMCTL2_00009",
    ],
    SMCTL1_00003: [
      "SMCTL2_00013",
      "SMCTL2_00014",
      "SMCTL2_00015",
      "SMCTL2_00016",
      "SMCTL2_00018",
      "SMCTL2_00017",
    ],
    SMCTL1_00004: [
      "SMCTL2_00019",
      "SMCTL2_00023",
      "SMCTL2_00022",
      "SMCTL2_00020",
      "SMCTL2_00021",
    ],
    SMCTL1_00005: [
      "SMCTL2_00025",
      "SMCTL2_00026",
      "SMCTL2_00029",
      "SMCTL2_00030",
      "SMCTL2_00024",
      "SMCTL2_00027",
      "SMCTL2_00028",
      "SMCTL2_00031",
    ],
    SMCTL1_00006: ["SMCTL2_00032", "SMCTL2_00033"],
    SMCTL1_00007: ["SMCTL2_00035", "SMCTL2_00036"],
    SMCTL1_00008: ["SMCTL2_00039", "SMCTL2_00038"],
    SMCTL1_00009: ["SMCTL2_00040", "SMCTL2_00041"],
    SMCTL1_00010: ["SMCTL2_00042"],
    SMCTL1_00011: [
      "SMCTL2_00044",
      "SMCTL2_00043",
      "SMCTL2_00046",
      "SMCTL2_00048",
    ],
    SMCTL1_00012: ["SMCTL2_00050"],
    SMCTL1_00013: [
      "SMCTL2_00052",
      "SMCTL2_00053",
      "SMCTL2_00055",
      "SMCTL2_00056",
      "SMCTL2_00054",
    ],
    SMCTL1_00014: [
      "SMCTL2_00060",
      "SMCTL2_00058",
      "SMCTL2_00059",
      "SMCTL2_00057",
    ],
    SMCTL1_00015: ["SMCTL2_00061", "SMCTL2_00062"],
    SMCTL1_00016: ["SMCTL2_00063"],
    SMCTL1_00017: [
      "SMCTL2_00066",
      "SMCTL2_00065",
      "SMCTL2_00064",
      "SMCTL2_00067",
    ],
    SMCTL1_00018: [
      "SMCTL2_00072",
      "SMCTL2_00070",
      "SMCTL2_00071",
      "SMCTL2_00068",
    ],
    SMCTL1_00019: [
      "SMCTL2_00075",
      "SMCTL2_00082",
      "SMCTL2_00079",
      "SMCTL2_00080",
      "SMCTL2_00081",
      "SMCTL2_00087",
      "SMCTL2_00086",
      "SMCTL2_00088",
      "SMCTL2_00078",
      "SMCTL2_00085",
    ],
    SMCTL1_00020: [
      "SMCTL2_00093",
      "SMCTL2_00089",
      "SMCTL2_00091",
      "SMCTL2_00090",
    ],
    SMCTL1_00021: ["SMCTL2_00099", "SMCTL2_00106"],
    SMCTL1_00022: [
      "SMCTL2_00109",
      "SMCTL2_00110",
      "SMCTL2_00108",
      "SMCTL2_00114",
      "SMCTL2_00111",
      "SMCTL2_00112",
      "SMCTL2_00113",
    ],
    SMCTL1_00023: [
      "SMCTL2_00120",
      "SMCTL2_00116",
      "SMCTL2_00115",
      "SMCTL2_00119",
      "SMCTL2_00118",
      "SMCTL2_00117",
    ],
    SMCTL1_00024: [
      "SMCTL2_00122",
      "SMCTL2_00123",
      "SMCTL2_00132",
      "SMCTL2_00124",
      "SMCTL2_00126",
      "SMCTL2_00125",
      "SMCTL2_00135",
      "SMCTL2_00138",
      "SMCTL2_00136",
      "SMCTL2_00129",
      "SMCTL2_00127",
      "SMCTL2_00128",
      "SMCTL2_00121",
      "SMCTL2_00131",
      "SMCTL2_00134",
      "SMCTL2_00140",
      "SMCTL2_00139",
      "SMCTL2_00130",
      "SMCTL2_00137",
      "SMCTL2_00133",
    ],
    SMCTL1_00025: ["SMCTL2_00141"],
    SMCTL1_00026: [
      "SMCTL2_00143",
      "SMCTL2_00147",
      "SMCTL2_00144",
      "SMCTL2_00145",
      "SMCTL2_00142F",
      "SMCTL2_00151",
      "SMCTL2_00154",
      "SMCTL2_00146",
      "SMCTL2_00153",
      "SMCTL2_00149",
      "SMCTL2_00150",
      "SMCTL2_00152",
      "SMCTL2_00155",
      "SMCTL2_00156",
      "SMCTL2_00148",
    ],
    SMCTL1_00027: [
      "SMCTL2_00160",
      "SMCTL2_00157",
      "SMCTL2_00163",
      "SMCTL2_00164",
      "SMCTL2_00158",
      "SMCTL2_00159",
      "SMCTL2_00162",
      "SMCTL2_00165",
    ],
    SMCTL1_00028: ["SMCTL2_00166"],
    SMCTL1_00029: [
      "SMCTL2_00168",
      "SMCTL2_00167",
      "SMCTL2_00171",
      "SMCTL2_00169",
    ],
    SMCTL1_00030: [
      "SMCTL2_00172",
      "SMCTL2_00173",
      "SMCTL2_00183",
      "SMCTL2_00184",
      "SMCTL2_00178",
      "SMCTL2_00175",
      "SMCTL2_00174",
      "SMCTL2_00182",
      "SMCTL2_00176",
      "SMCTL2_00181",
      "SMCTL2_00179",
      "SMCTL2_00177",
      "SMCTL2_00180",
    ],
    SMCTL1_00031: [
      "SMCTL2_00193",
      "SMCTL2_00186",
      "SMCTL2_00199",
      "SMCTL2_00194",
      "SMCTL2_00198",
      "SMCTL2_00187",
      "SMCTL2_00188",
      "SMCTL2_00189",
      "SMCTL2_00195",
      "SMCTL2_00190",
      "SMCTL2_00191",
      "SMCTL2_00196",
      "SMCTL2_00192",
    ],
    SMCTL1_00032: ["SMCTL2_00200", "SMCTL2_00201"],
    SMCTL1_00033: [
      "SMCTL2_00209",
      "SMCTL2_00202",
      "SMCTL2_00204",
      "SMCTL2_00206",
      "SMCTL2_00208",
      "SMCTL2_00203",
      "SMCTL2_00205",
      "SMCTL2_00207",
    ],
    SMCTL1_00034: ["SMCTL2_00211", "SMCTL2_00210", "SMCTL2_00212"],
    SMCTL1_00035: ["SMCTL2_00213"],
    SMCTL1_00036: [
      "SMCTL2_00215",
      "SMCTL2_00216",
      "SMCTL2_00227",
      "SMCTL2_00221",
      "SMCTL2_00222",
      "SMCTL2_00219",
      "SMCTL2_00220",
      "SMCTL2_00223",
      "SMCTL2_00228",
      "SMCTL2_00233",
      "SMCTL2_00224",
      "SMCTL2_00225",
      "SMCTL2_00232",
      "SMCTL2_00226",
      "SMCTL2_00230",
      "SMCTL2_00229",
      "SMCTL2_00217",
      "SMCTL2_00231",
    ],
    SMCTL1_00037: ["SMCTL2_00236", "SMCTL2_00235"],
    SMCTL1_00038: ["SMCTL2_00239", "SMCTL2_00237"],
    SMCTL1_00039: [
      "SMCTL2_00257",
      "SMCTL2_00240",
      "SMCTL2_00252",
      "SMCTL2_00245",
      "SMCTL2_00243",
      "SMCTL2_00254",
      "SMCTL2_00250",
      "SMCTL2_00248",
      "SMCTL2_00246",
      "SMCTL2_00249",
      "SMCTL2_00255",
      "SMCTL2_00258",
    ],
    SMCTL1_00040: ["SMCTL2_00265"],
    SMCTL1_00041: ["SMCTL2_00267", "SMCTL2_00269"],
    SMCTL1_00042: [
      "SMCTL2_00271",
      "SMCTL2_00286",
      "SMCTL2_00287",
      "SMCTL2_00272",
      "SMCTL2_00275",
      "SMCTL2_00274",
      "SMCTL2_00277",
      "SMCTL2_00280",
      "SMCTL2_00273",
      "SMCTL2_00281",
      "SMCTL2_00290",
      "SMCTL2_00276",
      "SMCTL2_00279",
      "SMCTL2_00282",
      "SMCTL2_00284",
      "SMCTL2_00283",
      "SMCTL2_00285",
      "SMCTL2_00289",
      "SMCTL2_00288",
    ],
    SMCTL1_00043: ["SMCTL2_00293", "SMCTL2_00291", "SMCTL2_00292"],
    SMCTL1_00044: ["SMCTL2_00295", "SMCTL2_00294", "SMCTL2_00296"],
    SMCTL1_00045: ["SMCTL2_00297"],
    SMCTL1_00046: ["SMCTL2_00298"],
    SMCTL1_00047: ["SMCTL2_00299"],
    SMCTL1_00048: ["SMCTL2_00300", "SMCTL2_00302", "SMCTL2_00301"],
    SMCTL1_00050: ["SMCTL2_00314", "SMCTL2_00313", "SMCTL2_00312"],
    SMCTL1_00051: ["SMCTL2_00316", "SMCTL2_00317"],
  };

  const iconMappingSecondLevelSymptoms =
    patientGender === "male"
      ? secondLevelMalemapping
      : patientGender === "female"
      ? secondLevelFemalemapping
      : {};

  console.log("iconMappingSecondLevelSymptoms", iconMappingSecondLevelSymptoms);

  // const handleToggle = (value) => {
  //   setChecked((prevChecked) => {
  //     const currentIndex = prevChecked.indexOf(value);
  //     const newChecked = [...prevChecked];

  //     if (currentIndex === -1) {
  //       newChecked.push(value);
  //     } else {
  //       newChecked.splice(currentIndex, 1);
  //     }

  //     return newChecked;
  //   });
  // };

  // const handleToggle = (symptom) => {
  //   const currentIndex = checked.indexOf(symptom);
  //   const newChecked = [...checked];

  //   if (currentIndex === -1) {
  //     newChecked.push(symptom);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }

  //   setChecked(newChecked);
  //   dispatch(setReduxSelectedSymptoms(newChecked));
  //   handelfetchVisualSelectionData(newChecked);
  // };

  const handleToggle = (symptom) => {
    setChecked((prevChecked) => {
      const updatedChecked = [...prevChecked];
  
      const currentIndex = prevChecked.indexOf(symptom);
  
      if (currentIndex === -1) {
        updatedChecked.push(symptom);
      } else {
        updatedChecked.splice(currentIndex, 1);
      }
  
      dispatch(setReduxSelectedSymptoms(updatedChecked));
  
      handelfetchVisualSelectionData(updatedChecked);
  
      return updatedChecked;
    });
  };
  


  console.log("test activeIds" , activeIds);
  console.log("test selectedPart" , selectedPart);

  const handleBodyPartClick = (id) => {
    setActiveIds((prevActiveIds) => {
      const updatedActiveIds = prevActiveIds.includes(id)
        ? prevActiveIds.filter((activeId) => activeId !== id) // Remove if already active
        : [...prevActiveIds, id]; // Add if not active
  
      // Dispatch the updated state
      dispatch(setReduxActiveIds(updatedActiveIds));
      return updatedActiveIds;
    });
  
    setSelectedPart((prevSelectedPart) => {
      const updatedSelectedPart = prevSelectedPart?.includes(id)
        ? prevSelectedPart.filter((selectedId) => selectedId !== id) // Remove if already selected
        : [...(prevSelectedPart || []), id]; // Add if not selected
  
      // Dispatch the updated state
      dispatch(setReduxSelectedPart(updatedSelectedPart));
      return updatedSelectedPart;
    });
  };
  

  // const handleBodyPartClick = (id) => {
  //   setActiveIds(
  //     (prevActiveIds) =>
  //       prevActiveIds.includes(id)
  //         ? prevActiveIds.filter((activeId) => activeId !== id) // Remove if already active
  //         : [...prevActiveIds, id] // Add if not active
  //   );
  //   setSelectedPart(
  //     (prevSelectedPart) =>
  //       prevSelectedPart?.includes(id)
  //         ? prevSelectedPart.filter((selectedId) => selectedId !== id) // Remove if already selected
  //         : [...(prevSelectedPart || []), id] // Add if not selected
  //   );
  //   dispatch(setReduxActiveIds(activeIds));
  //   dispatch(setReduxSelectedPart(selectedPart));
  // };

  const handleLabelClick = (id, testId) => {
    console.log("id for first", id, testId);
  
    setActiveLabels((prev) => {
      const updatedLabels = prev.includes(id)
        ? prev.filter((item) => item !== id)
        : [...prev, id]; 
  
      dispatch(setReduxActiveLabels(updatedLabels));
      return updatedLabels;
    });
  
    setActiveIdsForSecondStep((prevActiveIds) => {
      const updatedActiveIdsForSecondStep = prevActiveIds.includes(testId)
        ? prevActiveIds.filter((activeId) => activeId !== testId)
        : [...prevActiveIds, testId];
  
      dispatch(setReduxSecondStepActiveIds(updatedActiveIdsForSecondStep));
      return updatedActiveIdsForSecondStep;
    });
  
    setSelectedPartForSecond((prevSelectedPart) => {
      const updatedSelectedPartForSecond = prevSelectedPart?.includes(testId)
        ? prevSelectedPart.filter((selectedId) => selectedId !== testId) 
        : [...(prevSelectedPart || []), testId];
  
      dispatch(setReduxSelectedPartForSecond(updatedSelectedPartForSecond));
      return updatedSelectedPartForSecond;
    });
  };
  

  // const handleLabelClick = (id, testId) => {
  //   console.log("id for first", id, testId);
  //   setActiveLabels((prev) =>
  //     prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
  //   );
  //   setActiveIdsForSecondStep(
  //     (prevActiveIds) =>
  //       prevActiveIds.includes(testId)
  //         ? prevActiveIds.filter((activeId) => activeId !== testId) // Remove if already active
  //         : [...prevActiveIds, testId] // Add if not active
  //   );
  //   setSelectedPartForSecond(
  //     (prevSelectedPart) =>
  //       prevSelectedPart?.includes(testId)
  //         ? prevSelectedPart.filter((selectedId) => selectedId !== testId) // Remove if already selected
  //         : [...(prevSelectedPart || []), testId] // Add if not selected
  //   );
  //   dispatch(setReduxActiveLabels(activeLabels));
  //   dispatch(setReduxSelectedPartForSecond(selectedPartForSecond));
  //   dispatch(setReduxSecondStepActiveIds(activeIdsForSecondStep));
    
  // };

  // const handleLabelSecondClick = (id, testId) => {
  //   console.log("id for second", id, testId);

  //   // Toggle active labels
  //   setActiveLabelsForSecondLabel((prev) =>
  //     prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
  //   );

  //   // Manage selected keys for second level
  //   setSelectedSecondLevelKey(
  //     (prevSelectedPart) =>
  //       prevSelectedPart?.includes(testId)
  //         ? prevSelectedPart.filter((selectedId) => selectedId !== testId) // Remove if already selected
  //         : [...(prevSelectedPart || []), testId] // Add if not selected
  //   );
  //   // dispatch(setReduxActiveLabels(activeLabels));
  //   dispatch(setReduxSelectedSecondLevelKey(selectedSecondLevelKey));
  //   dispatch(setreduxActiveLabelsForSecond(activeLabelsForSecondLevel))
  // };

  const handleLabelSecondClick = (id, testId) => {
    console.log("id for second", id, testId);
  
    setActiveLabelsForSecondLabel((prev) => {
      const updatedLabels = prev.includes(id)
        ? prev.filter((item) => item !== id)
        : [...prev, id];
  
      dispatch(setreduxActiveLabelsForSecond(updatedLabels));
      return updatedLabels;
    });
  
    setSelectedSecondLevelKey((prevSelectedPart) => {
      const updatedSelectedKeys = prevSelectedPart?.includes(testId)
        ? prevSelectedPart.filter((selectedId) => selectedId !== testId)
        : [...(prevSelectedPart || []), testId];
  
      dispatch(setReduxSelectedSecondLevelKey(updatedSelectedKeys));
      return updatedSelectedKeys;
    });
  };
  

  const femaleThirdLevelSymtoms = {
    SMCTL2_00001: ["abnormal_crying", "crying", "weak_cry"],
    SMCTL2_00002: [
      "aggressive_behavior",
      "agitation",
      "facial_grimacing",
      "tantrum",
    ],
    SMCTL2_00004: [
      "cross_dressing_practice_of_wearing_clothing_typically_associated_with_the_opposite_gender",
      "odd_behavior",
      "changing_friends",
      "lack_of_coordination",
      "jaw_clenching",
      "mania",
      "nail_biting",
      "personality_or_behavior_change",
      "polysubstance_dependence",
      "lip_puckering",
      "stereotyped_actions",
      "suspicious_behavior",
      "teeth_grinding",
      "thumb_sucking",
    ],
    SMCTL2_00006: [
      "rocking_behavior",
      "unable_to_engage_quietly_in_leisure_activities",
      "irritability",
      "hyperactivity",
      "impatient",
      "inability_to_sit_still_or_restlessness_or_fidgeting",
      "interrupting_others",
      "excessive_talking",
    ],
    SMCTL2_00005: [
      "compulsive_actions",
      "irresistible_urge_to_move_legs",
      "grasping_or_picking_at_imaginary_objects",
      "repetitive_behavior",
      "ritualistic_actions",
    ],
    SMCTL2_00003: [
      "decline_in_daily_activities",
      "difficulty_interacting_with_peers",
    ],
    SMCTL2_00007: [
      "recollection_of_past_events_flashback",
      "selfharm",
      "selfneglect",
    ],
    SMCTL2_00010: [
      "abnormal_jaw_movements",
      "drop_attacks_sudden_falls_with_or_without_loss_of_consciousness",
      "difficulty_passing_stools",
      "dysdiadochokinesis_the_inability_to_perform_rapid_alternating_muscle_movements",
    ],
    SMCTL2_00011: [
      "unusual_movements",
      "abnormal_hand_movement",
      "seizure",
      "febrile_seizures",
      "focal_seizures",
      "tonicclonic_seizures",
    ],
    SMCTL2_00012: [
      "ankle_muscle_spasms",
      "hand_flapping_tremor",
      "head_tremors",
      "intention_tremors",
      "tremor_of_the_jaw",
      "tremor_postural",
      "tremor_at_rest",
      "tics_or_fasciculations",
      "tics_or_muscle_twitches",
      "general_tremor",
    ],
    SMCTL2_00008: [
      "involuntary_writhing_movements_athetosis",
      "automatic_movements_automatisms",
      "rapid_jerky_movements_chorea",
      "emotional_distress",
      "head_nodding",
      "unilateral_spasm_hemiballismus",
      "lipsmacking",
      "stereotyped_movements",
      "involuntary_movement_coordination_synkinesis",
    ],
    SMCTL2_00009: ["poor_muscle_coordination"],
    SMCTL2_00013: [
      "abnormal_sensation",
      "loss_of_sensation",
      "abnormal_sensation_in_the_face",
      "abnormal_sensation_in_the_limbs",
      "goosebumps",
      "hyperaesthesia",
    ],
    SMCTL2_00014: [
      "abnormal_sensation_in_the_anal_perianal_area",
      "protrusion_from_the_anus",
    ],
    SMCTL2_00015: [
      "burning_sensation",
      "burning_sensation_in_arms",
      "burning_sensation_in_fingers",
      "burning_sensation_in_hands",
      "burning_sensation_in_legs_and_feet",
      "burning_sensation_in_toes",
    ],
    SMCTL2_00016: [
      "itching",
      "generalized_itching",
      "itching_of_the_palate",
      "scalp_itching",
      "itching_in_the_throat",
      "perianal_or_anal_itching",
    ],
    SMCTL2_00018: [
      "gangrene",
      "bilateral_loss_of_sensation",
      "unilateral_loss_of_sensation",
      "numb_feeling",
      "numbness_in_the_arms",
      "numbness_in_cheeks",
      "numbness_in_the_face",
      "numbness_upper_limb",
      "intermittent_numbness",
      "numbness_in_legs_and_feet",
      "numbness_lower_limb",
      "numbness_in_the_mouth",
      "onesided_numbness",
      "perianal_numbness",
      "perineal_numbness",
      "shoulder_numbness",
      "tingling_sensation",
      "tingling_sensation_in_arms",
      "tingling_sensation_in_cheeks",
      "tingling_sensation_in_extremities",
      "tingling_sensation_in_hands",
      "tingling_sensation_in_legs_and_feet",
      "tingling_sensation_in_the_mouth",
      "tingling_sensation_in_toes",
    ],
    SMCTL2_00017: ["loss_of_vibration_sense"],
    SMCTL2_00019: ["chills_or_rigors"],
    SMCTL2_00023: [
      "cold_hands_or_feet",
      "cold_sensation_in_fingers",
      "cold_sensation_in_hands",
    ],
    SMCTL2_00022: ["cold_sensations", "hot_sensations"],
    SMCTL2_00020: ["fever"],
    SMCTL2_00021: ["frostbite"],
    SMCTL2_00025: ["anxiety", "panic"],
    SMCTL2_00026: [
      "lack_of_interest",
      "boredom",
      "emotional_dysfunction",
      "lack_of_pleasure",
      "lack_of_drive_or_motivation",
      "social_isolation",
    ],
    SMCTL2_00029: [
      "hearing_hallucinations",
      "delusions",
      "excessive_vanity_and_selfcenteredness",
      "grandiose_delusions",
      "feelings_of_unreality",
      "feelings_of_suspicion_or_mistrust",
      "illusions",
      "irrational_thoughts_or_odd_thinking",
      "visual_hallucinations",
    ],
    SMCTL2_00030: [
      "depression",
      "seasonal_depression",
      "feeling_of_guilt",
      "suicidal_thoughts",
    ],
    SMCTL2_00024: ["feeling_of_disgust"],
    SMCTL2_00027: ["state_of_increased_alertness", "mood_swings"],
    SMCTL2_00028: ["obsession", "undue_concern_about_body_shape_or_weight"],
    SMCTL2_00031: [
      "phobias",
      "fear_of_eating",
      "fear_of_flying",
      "fear_of_water",
      "freezing_due_to_fear",
    ],
    SMCTL2_00032: [
      "abnormal_body_posture",
      "asymmetrical_posture",
      "stooped_posture",
      "bent_posture_while_walking",
      "wrist_drop",
    ],
    SMCTL2_00033: [
      "arching_of_the_neck",
      "head_dropping",
      "head_tilting",
      "involuntary_neck_deviation",
    ],
    SMCTL2_00035: ["numbness_in_fingers", "tingling_sensation_in_fingers"],
    SMCTL2_00036: [
      "sensation_of_a_lump_in_the_throat",
      "swelling_of_the_thyroid_gland",
    ],
    SMCTL2_00039: [
      "coma",
      "reduced_alertness",
      "delirium",
      "state_of_mental_confusion",
      "fainting",
      "decreased_consciousness",
      "stupor_nearunconsciousness",
    ],
    SMCTL2_00038: [
      "dizziness",
      "dizziness_upon_changing_posture",
      "lightheadedness",
    ],
    SMCTL2_00040: [
      "irregular_walking_pattern",
      "widebased_gait",
      "bentover_walking",
      "abnormal_involuntary_eye_movements",
      "dragging_of_the_foot",
      "slapping_of_the_foot",
      "highstepping_walk",
      "limp",
      "balance_loss",
      "shuffling_gait",
      "stifflegged_walk",
      "heeltotoe_walking",
      "tiptoe_walking",
      "unsteady_walk",
      "waddling_walk",
    ],
    SMCTL2_00041: ["antalgic_gait"],
    SMCTL2_00042: [
      "acid_reflux",
      "belching_eructation",
      "excessive_burping_or_belching",
      "burning_sensation_in_the_chest",
      "heartburn_after_meals",
      "stomach_upset_indigestion",
    ],
    SMCTL2_00044: [
      "fracture",
      "enlarged_pharyngeal_lymph_nodes",
      "perianal_fistulas",
    ],
    SMCTL2_00043: ["abnormal_rectal_bleeding"],
    SMCTL2_00046: [
      "anal_or_perianal_or_rectal_pain",
      "perineal_pain",
      "perineal_scarring",
      "tenderness_in_the_rectal_or_anal_area",
    ],
    SMCTL2_00048: [
      "rectal_swelling_that_feels_hard",
      "rectal_pressure",
      "rectal_tumor",
    ],
    SMCTL2_00050: [
      "postural_low_blood_pressure",
      "low_blood_pressure_due_to_shock",
    ],
    SMCTL2_00052: [
      "abnormal_bowel_sounds",
      "abdominal_gurgling_borborygmi",
      "decreased_bowel_sounds",
      "increased_frequency_of_bowel_sounds",
    ],
    SMCTL2_00053: [
      "irregular_stools",
      "aversion_to_food",
      "presence_of_mucus_in_stool",
      "night_terrors",
      "black_and_tarry_stools_melena",
      "foul_smelling_stool",
      "blood_in_stools",
      "worms_in_stool",
    ],
    SMCTL2_00055: [
      "constipation_and_diarrhoea_at_alternate_intervals",
      "constipation",
      "difficulty_defecating",
      "dependent_edema",
      "gas",
      "no_passage_of_stools",
      "painful_bowel_movements",
    ],
    SMCTL2_00056: [
      "constipation_and_diarrhoea_at_alternate_intervals",
      "loose_stools_diarrhoea",
      "chronic_diarrhea",
      "frequent_copious_diarrhea",
      "watery_diarrhea",
      "diarrhea_with_blood",
      "diarrhea_with_blood_and_mucus",
      "enlarged_spleen",
      "feeling_of_incomplete_bowel_emptying",
      "redness_of_oral_mucosa",
    ],
    SMCTL2_00054: [
      "loss_of_bowel_control",
      "change_in_bowel_habits",
      "increased_libido",
      "involuntary_stools",
      "stool_leakage",
    ],
    SMCTL2_00060: ["blood_in_semen", "breast_pain_or_tenderness"],
    SMCTL2_00058: ["lump_or_swelling_in_the_breast"],
    SMCTL2_00059: [
      "reduction_in_breast_size",
      "reduced_milk_production",
      "localized_increase_in_temperature_breast",
      "galactorrhea",
      "inverted_nipple",
    ],
    SMCTL2_00057: [
      "discharge_from_the_breast",
      "green_discharge_from_the_breast",
      "nipple_secretion",
    ],
    SMCTL2_00061: ["choking", "gasping"],
    SMCTL2_00062: [
      "shortness_of_breath_dyspnea",
      "shortness_of_breath_at_night",
      "shortness_of_breath_at_rest",
      "difficulty_inhaling_shortness_of_breath",
      "shortness_of_breath_dyspnea_episodic",
      "shortness_of_breath_dyspnea_on_exertion",
      "shortness_of_breath_while_talking",
    ],
    SMCTL2_00063: [
      "burns",
      "dry_burn",
      "absent_tendon_reflexes",
      "facial_burn",
    ],
    SMCTL2_00066: [
      "deficit_in_attention",
      "careless_errors",
      "difficulty_focusing",
      "incomplete_tasks_or_assignments",
      "lack_of_attention_to_detail",
      "easily_gets_distracted",
    ],
    SMCTL2_00065: [
      "cognitive_decline",
      "delay_in_cognitive_or_intellectual_development",
      "dysentery",
      "dysmetria_inability_to_control_the_distance_speed_and_range_of_motion_necessary_to_perform_smoothly_coordinated_movements",
      "poor_school_performance",
      "poor_work_performance",
      "inability_to_perform_calculations",
      "neurological_difficulties_difficulty_in_construction_tasks",
      "inability_to_read",
      "difficulty_visualizing_and_understanding_space",
      "difficulty_writing",
      "difficulty_recognizing_faces",
      "difficulty_making_decisions",
      "difficulty_learning",
      "loss_of_sense_of_position",
      "intellectual_disability_mental_retardation",
      "impaired_nonverbal_communication",
      "though_disorder_tangentiality_and_derailment",
    ],
    SMCTL2_00064: ["dysarthria_difficulty_speaking"],
    SMCTL2_00067: [
      "frequently_losing_things",
      "memory_loss",
      "longterm_memory_loss",
      "shortterm_memory_loss",
      "gradual_loss_of_language_fluency",
    ],
    SMCTL2_00072: ["cough", "funnel_chest"],
    SMCTL2_00070: ["cough_with_blood"],
    SMCTL2_00071: ["cough_with_phlegm_or_sputum"],
    SMCTL2_00068: ["whooping_cough"],
    SMCTL2_00075: [
      "barrel_chest",
      "broad_chest",
      "chest_deformity",
      "concave_chest",
      "food_intolerance",
      "pigeon_chest",
    ],
    SMCTL2_00082: [
      "bowlegs",
      "clubfoot",
      "flatfoot",
      "foot_drop",
      "knock_knee",
    ],
    SMCTL2_00079: ["claw_hand"],
    SMCTL2_00080: [
      "cleft_lip",
      "cleft_palate",
      "facial_asymmetry",
      "flat_head",
      "enlarged_head",
      "deviated_nasal_septum",
      "rounded_face",
      "small_head",
      "small_mouth",
      "soft_skull_craniotabes",
    ],
    SMCTL2_00081: ["joint_tenderness"],
    SMCTL2_00087: ["rapid_blinking"],
    SMCTL2_00086: ["limb_deformities"],
    SMCTL2_00088: [
      "lordosis",
      "deformity_of_the_spine",
      "bamboo_spine_spinal_deformity",
      "lateral_curvature_of_the_spine",
    ],
    SMCTL2_00078: ["nail_clubbing", "swelling_of_fingers"],
    SMCTL2_00085: ["short_neck", "webbed_neck"],
    SMCTL2_00093: [
      "delayed_or_absent_puberty",
      "late_onset_of_puberty",
      "absent_breast_development_in_girls",
      "very_early_breast_development_in_girls",
    ],
    SMCTL2_00089: [
      "delayed_crawling_or_walking",
      "delayed_motor_skills_development",
      "delayed_language_development",
      "late_walking_onset",
      "developmental_disability",
    ],
    SMCTL2_00091: ["growth_delay"],
    SMCTL2_00090: ["reduced_uterine_size"],
    SMCTL2_00099: ["sudden_hearing_loss"],
    SMCTL2_00106: ["heart_attack"],
    SMCTL2_00109: ["bleeding_from_the_ears"],
    SMCTL2_00110: ["hearing_loss_deafness", "nasal_polyps"],
    SMCTL2_00108: ["discharge_from_the_ear", "bloody_discharge_from_the_ear"],
    SMCTL2_00114: [
      "ear_fullness_or_pressure",
      "ear_pain",
      "tenderness_of_the_tragus",
    ],
    SMCTL2_00111: ["itching_in_the_ear"],
    SMCTL2_00112: ["earwax"],
    SMCTL2_00113: [
      "sensitivity_to_noise",
      "increased_sensitivity_to_one's_own_voice_or_sounds",
      "ringing_in_the_ears_tinnitus",
      "tophi_deposit_of_monosodium_urate_crystals",
    ],
    SMCTL2_00120: [
      "loss_of_taste_ageusia",
      "bad_taste",
      "bitter_taste_in_the_mouth",
      "loss_of_taste_for_food",
      "diminished_taste_sensation",
    ],
    SMCTL2_00116: [
      "vesicular_rash",
      "anorexia",
      "decreased_appetite",
      "feeling_full_after_eating_very_little_food",
      "enhanced_hunger",
      "infrequent_stools",
    ],
    SMCTL2_00115: ["eating_disorder_binge_eating", "binge_eating"],
    SMCTL2_00119: ["decreased_thirst", "heightened_increased_thirst"],
    SMCTL2_00118: [
      "difficulty_chewing",
      "swallowing_difficulty",
      "swallowing_difficulty_for_liquids",
      "swallowing_difficulty_for_solids",
      "swallowing_difficulty_painful",
      "abdominal_pain_after_fatty_meal",
      "feeding_difficulties",
      "explosive_stools",
      "paraplegia",
    ],
    SMCTL2_00117: ["eats_nonfood_items_pica"],
    SMCTL2_00122: [
      "abnormal_eye_movements",
      "word_blindness",
      "enlarged_ovary",
      "loss_or_decreased_movement_of_eye_ball",
    ],
    SMCTL2_00123: ["abnormal_eye_positioning"],
    SMCTL2_00132: [
      "bruise_around_the_eye_black_eye",
      "light_halos",
      "whitecentered_retinal_hemorrhages",
      "nodules_in_the_retina",
      "rust_ring_on_the_cornea",
      "eye_squint",
      "prolonged_staring",
      "eyes_appearing_sunken",
      "gray_or_white_arc_that_appears_in_the_eye_above_and_below_the_outer_cornea",
    ],
    SMCTL2_00124: ["bleeding_from_the_eye"],
    SMCTL2_00126: [
      "presence_of_blind_spots",
      "total_blindness",
      "corneal_cloudiness",
      "reduced_visual_acuity",
      "reduced_visual_acuity_in_both_eyes",
      "reduced_visual_acuity_in_the_left_eye",
      "reduced_visual_acuity_in_the_right_eye",
      "gradual_decline_in_vision",
      "gradual_central_vision_loss",
      "gradual_peripheral_vision_loss",
      "vision_loss",
      "progressive_loss_of_vision",
      "temporary_loss_of_vision",
      "difficulty_seeing_at_night_night_blindness",
      "sudden_painless_vision_loss",
    ],
    SMCTL2_00125: ["color_vision_deficiency_color_blindness"],
    SMCTL2_00135: ["pallor_conjunctiva"],
    SMCTL2_00138: [
      "pink_eye_inflammation_of_the_conjunctiva",
      "cervical_sores",
      "inflammation_of_the_iris",
      "eye_redness",
      "redness_in_both_eyes",
      "left_eye_redness",
      "right_eye_redness",
      "redness_of_the_eyelids",
    ],
    SMCTL2_00136: [
      "constricted_pupils",
      "dilated_pupils",
      "unequal_pupil_sizes",
    ],
    SMCTL2_00129: [
      "eyelid_contractions",
      "crusty_eyelid_or_eyelashes",
      "difficulty_closing_eyelids",
      "skin_crease_eyelid",
      "ulcer_on_the_eyelid_margin",
      "xanthelasma",
      "eyelids_sticking_together_in_the_morning",
      "follicles_on_the_inner_surface_of_the_eyelids",
      "delay_in_eyelid_movement_lid_lag",
    ],
    SMCTL2_00127: ["double_vision"],
    SMCTL2_00128: ["dryness_of_the_eyes"],
    SMCTL2_00121: [
      "discharge_from_the_eye",
      "pus_discharge_from_the_eye",
      "eye_discharge_watery",
    ],
    SMCTL2_00131: ["thin_nails", "itching_of_the_eyes"],
    SMCTL2_00134: [
      "pain_in_the_eyes",
      "pain_in_the_eyelids",
      "eye_pain",
      "tenderness_around_the_eye",
    ],
    SMCTL2_00140: [
      "burns_on_the_eyelids",
      "foreign_body_in_the_conjunctiva",
      "foreign_body_in_the_cornea",
      "sensation_of_a_foreign_body_in_the_eye",
      "fracture_or_trauma_to_the_orbit",
    ],
    SMCTL2_00139: [
      "eyelid_swelling_edema",
      "swelling_of_the_eye",
      "swelling_around_the_eye_area",
      "conjunctival_swelling",
    ],
    SMCTL2_00130: ["sensitivity_to_bright_light", "sensitivity_to_light"],
    SMCTL2_00137: ["difficulty_walking", "reduced_blink_rate"],
    SMCTL2_00133: ["visual_floaters", "visual_perceptual_abnormalities"],
    SMCTL2_00141: [
      "bad_odor",
      "bad_odor_from_fingers",
      "abnormal_rectal_discharge",
    ],
    SMCTL2_00143: [
      "abnormal_urine",
      "pus_in_the_urine",
      "abnormal_urine_flow",
      "weak_urine_stream",
      "urinary_incontinence",
      "urinary_retention",
    ],
    SMCTL2_00147: ["foulsmelling_urine"],
    SMCTL2_00144: ["abnormal_vaginal_bleeding_metrorrhagia", "weak_voice"],
    SMCTL2_00145: [
      "abnormal_vaginal_bleeding",
      "abnormal_vaginal_bleeding_metrorrhagia_painless",
      "abnormal_vaginal_bleeding_following_sexual_intercourse",
      "bleeding_from_the_cervix",
      "uterine_bleeding",
    ],
    SMCTL2_00142F: [
      "abnormal_vaginal_secretions",
      "discharge_from_the_cervix",
      "discharge_from_the_cervix_containing_pus",
      "discharge_from_the_urethra",
    ],
    SMCTL2_00151: [
      "absence_of_menstruation",
      "excessive_menstruation",
      "polymenorrhea_frequent_menses",
      "heavy_menses",
      "bleeding_between_menstrual_cycles",
      "irregular_menstruation",
      "menstrual_irregularities_missed_period",
      "dysmenorrhea_painful_menstrual_periods",
      "infrequent_menstruation",
    ],
    SMCTL2_00154: [
      "adnexal_mass",
      "leg_pain",
      "hardening_or_thickening_of_the_vulva",
      "vulvar_mass",
    ],
    SMCTL2_00146: [
      "burning_sensation_or_difficulty_in_urination",
      "difficulty_urinating",
      "urinary_obstruction",
    ],
    SMCTL2_00153: [
      "cervical_muscle_pain",
      "pain_and_tenderness_in_the_vulva_or_vagina",
      "vulval_or_vaginal_pain_or_tenderness",
    ],
    SMCTL2_00149: [
      "decreased_urine_output_anuria",
      "decreased_urine_output_or_urinary_retention",
      "increased_frequency_or_output_of_urination",
      "increased_urination_frequency",
      "increased_urine_output",
    ],
    SMCTL2_00150: ["dry_vagina_or_vulva", "vulval_or_vaginal_itching"],
    SMCTL2_00152: [
      "urinary_infection",
      "inflammation_or_infection_of_the_glans_penis",
      "uterine_contractions",
    ],
    SMCTL2_00155: [
      "urinary_incontinence_loss_of_bladder_control",
      "urinary_incontinence_with_straining",
    ],
    SMCTL2_00156: ["urine_discoloration"],
    SMCTL2_00148: ["thin_hair", "vaginal_fullness_or_pressure"],
    SMCTL2_00160: [
      "hair_loss_armpit",
      "hair_loss",
      "hair_loss_foot",
      "male_pattern_baldness",
    ],
    SMCTL2_00157: ["bleeding_from_the_nails"],
    SMCTL2_00163: [
      "sinus_congestion",
      "dry_hair",
      "lightly_pigmented_hair",
      "stroke",
    ],
    SMCTL2_00164: ["brittle_nails"],
    SMCTL2_00158: ["discolored_nails"],
    SMCTL2_00159: ["excessive_body_hair", "excessive_hair_growth_on_the_legs"],
    SMCTL2_00162: ["nail_abnormalities"],
    SMCTL2_00165: ["inflamed_nail_folds"],
    SMCTL2_00166: ["frequent_hiccups"],
    SMCTL2_00168: [
      "bone_cracking_crepitus",
      "abnormal_joint_sounds",
      "hip_fracture",
      "inability_to_bear_weight",
      "long_bone_fractures",
      "rib_fractures",
      "fracture_of_the_spine",
      "wrist_fracture",
    ],
    SMCTL2_00167: [
      "finger_dislocation",
      "hip_dislocation",
      "joint_dislocation",
      "shoulder_dislocation",
    ],
    SMCTL2_00171: [
      "gum_injuries",
      "injury_to_the_lip",
      "quadriceps_tendon_rupture",
    ],
    SMCTL2_00169: ["rupture_of_the_patellar_tendon"],
    SMCTL2_00172: ["bad_breath"],
    SMCTL2_00173: [
      "bleeding_from_the_mucous_membranes",
      "bleeding_from_the_palate",
      "bleeding_from_throat",
    ],
    SMCTL2_00183: [
      "dental_cavities_decay",
      "dental_enamel_hypoplasia_or_pits",
      "dental_plaque",
      "loose_teeth",
      "tooth_loss",
      "wear_on_teeth",
    ],
    SMCTL2_00184: [
      "difficulty_controlling_bodily_secretions",
      "drooling",
      "excessive_salivation",
    ],
    SMCTL2_00178: ["drooping_of_mouth_corners"],
    SMCTL2_00175: ["dry_mouth", "dry_mouth_in_the_early_morning"],
    SMCTL2_00174: ["erosion_of_tooth_enamel"],
    SMCTL2_00182: [
      "enlarged_tongue_or_thick_tongue",
      "cracked_or_fissured_tongue",
      "coated_tongue_hairy_tongue",
      "torn_lingual_frenum_tongue_web",
    ],
    SMCTL2_00176: ["cracked_lips"],
    SMCTL2_00181: [
      "swelling_inside_the_mouth_hard_palate",
      "swelling_of_the_lips",
      "oral_mass",
      "swelling_of_the_gums",
    ],
    SMCTL2_00179: [
      "difficulty_opening_the_mouth",
      "pain_in_the_mouth",
      "pain_while_speaking",
      "gum_pain",
      "toothache",
    ],
    SMCTL2_00177: ["lip_discoloration", "torn_lips"],
    SMCTL2_00180: [
      "redness_of_the_buccal_mucosa",
      "red_swollen_gums",
      "quadriplegia",
    ],
    SMCTL2_00193: [
      "arm_muscle_enlargement",
      "calf_muscle_enlargement",
      "jaw_muscle_enlargement",
      "leg_muscle_enlargement",
      "muscle_enlargement_hypertrophy",
      "neck_muscle_enlargement",
    ],
    SMCTL2_00186: [
      "reduced_tendon_reflexes",
      "poor_muscle_tone",
      "reduced_or_absent_ankle_reflex",
    ],
    SMCTL2_00199: [
      "breast_fullness",
      "difficulty_dressing",
      "stiffness_of_the_finger",
      "joint_contracture_in_hands",
      "joint_stiffness",
      "joint_stiffness_small_joints",
      "knee_stiffness",
      "shoulder_stiffness",
    ],
    SMCTL2_00194: [
      "muscle_contractions_dystonia",
      "foot_spasm",
      "hand_spasm",
      "involuntary_muscle_contractions",
      "muscle_contractions_or_spasm",
      "muscle_spasms",
      "sudden_muscle_jerks_myoclonus",
    ],
    SMCTL2_00198: [
      "elbow_pain_or_tenderness",
      "finger_pain",
      "localized_increase_in_temperature_joints",
      "joint_pain",
      "foot_joint_pain",
      "pain_in_large_joints",
      "hand_joint_pain",
      "temporomandibular_joint_pain",
      "pain_in_small_joints",
      "knee_pain_or_tenderness",
      "knee_pain_or_tenderness",
      "muscle_stiffness",
      "tendon_rupture",
      "shoulder_pain",
    ],
    SMCTL2_00187: ["abnormal_position_of_the_hip"],
    SMCTL2_00188: ["hip_abnormal_sounds", "loud_pop_in_knee"],
    SMCTL2_00189: ["hip_dysplasia"],
    SMCTL2_00195: ["hypermobile_joints"],
    SMCTL2_00190: [
      "joint_instability",
      "weakness_in_joints",
      "instability_of_the_knee",
    ],
    SMCTL2_00191: ["swelling_of_joints", "swelling_of_the_joints"],
    SMCTL2_00196: ["loss_of_voluntary_anal_control"],
    SMCTL2_00192: [
      "muscle_atrophy_in_lower_limbs",
      "muscle_atrophy_or_wasting",
      "quadriceps_muscle_wasting",
      "thigh_muscle_atrophy",
      "soft_stools",
    ],
    SMCTL2_00200: ["feeling_of_sickness_nausea"],
    SMCTL2_00201: ["nausea_and_vomiting", "vomiting_blood"],
    SMCTL2_00209: [
      "difficulty_producing_sounds",
      "highpitched_voice",
      "hoarseness",
      "muteness",
      "voice_tremors",
      "neck_pain",
    ],
    SMCTL2_00202: [
      "white_discharge_from_the_tonsils",
      "pharyngeal_follicles",
      "tonsillar_follicles",
    ],
    SMCTL2_00204: ["dryness_of_throat"],
    SMCTL2_00206: ["neck_muscle_spasms", "sore_throat_or_throat_pain"],
    SMCTL2_00208: ["swelling_of_the_neck"],
    SMCTL2_00203: ["hunched_shoulders"],
    SMCTL2_00205: [
      "inflammation_of_the_tonsils",
      "inflammation_of_the_vocal_cords",
    ],
    SMCTL2_00207: ["stiff_neck"],
    SMCTL2_00211: ["bleeding_from_the_nose", "blood_discharge_from_the_nose"],
    SMCTL2_00210: [
      "sinus_discharge",
      "nasal_discharge",
      "copious_nasal_discharge",
      "discharge_from_nose_containing_pus",
      "pharyngeal_congestion",
    ],
    SMCTL2_00212: [
      "redness_and_swelling_of_the_nasal_mucosa",
      "inability_to_converge_eyeballs",
      "nasal_sores",
      "diminished_sense_of_smell",
    ],
    SMCTL2_00213: ["bleeding", "bleeding_from_the_varicose_veins"],
    SMCTL2_00215: [
      "abdominal_colic_or_cramps",
      "rigidity_of_abdominal_muscles",
      "abdominal_or_stomach_pain",
      "abdominal_stomach_pain_or_discomfort",
      "tenderness_in_the_abdomen",
      "abdominal_pain_increases_after_meals",
      "abdominal_pain_relieved_after_food",
      "generalized_abdominal_stomach_pain",
      "generalized_abdominal_stomach_pain_after_food",
      "left_lower_quadrant_llq_abdominal_stomach_pain",
      "left_lower_quadrant_llq_abdominal_tenderness",
      "left_upper_quadrant_luq_abdominal_stomach_pain",
      "left_upper_quadrant_luq_abdominal_tenderness",
      "lower_half_abdominal_stomach_pain",
      "tenderness_in_the_lower_abdomen",
      "right_lower_quadrant_rlq_abdominal_stomach_pain",
      "right_lower_quadrant_rlq_abdominal_tenderness",
      "right_upper_quadrant_ruq_abdominal_stomach_pain",
      "right_upper_quadrant_ruq_abdominal_tenderness",
      "tenderness_in_or_around_umbilical_region",
      "upper_half_abdominal_stomach_pain",
      "tenderness_in_the_upper_abdomen",
    ],
    SMCTL2_00216: ["ankle_pain_or_tenderness"],
    SMCTL2_00227: [
      "bone_tenderness",
      "loss_of_grip_strength",
      "cramping_leg_pain",
      "limb_pain",
      "limb_pain_while_at_rest",
      "hand_limb_pain",
      "limb_pain_heel",
      "unilateral_limb_pain",
      "radiating_limb_pain",
      "muscle_pain",
      "calf_pain",
      "pain_in_the_extremities",
      "pain_radiating_to_the_buttocks",
      "pain_radiating_to_the_left_shoulder",
      "thigh_pain",
      "tenderness_of_the_calf",
      "wrist_pain_or_tenderness",
    ],
    SMCTL2_00221: ["back_pain_or_tenderness", "tenderness_in_the_flank_region"],
    SMCTL2_00222: [
      "bone_pain",
      "hip_deformity",
      "ovarian_tumor",
      "general_pain",
      "pain_induced_by_alcohol",
      "generalised_pain",
      "tenderness",
    ],
    SMCTL2_00219: [
      "chest_pain",
      "acute_chest_pain",
      "chest_pain_at_rest",
      "chest_pain_that_doesnt_improve_with_rest",
      "chest_pain_when_coughing",
      "chest_pain_during_activity",
      "chest_pain_while_breathing",
      "chest_pain_radiating_to_the_back",
      "chest_pain_radiating_to_the_stomach",
      "chest_pain_radiating_to_the_jaw",
      "chest_pain_radiating_to_the_shoulder",
      "chest_pain_radiating_to_the_upper_limb",
    ],
    SMCTL2_00220: ["tenderness_in_the_sinuses", "facial_pain"],
    SMCTL2_00223: [
      "feeling_of_pressure_in_the_sinuses",
      "headache",
      "arm_pain",
      "muscle_tenderness",
      "muscle_tenderness",
    ],
    SMCTL2_00228: [
      "pain_in_the_flank",
      "lower_back_pain",
      "pain_or_tenderness_at_the_costovertebral_angle",
      "trunk_pain",
    ],
    SMCTL2_00233: [
      "groin_pain",
      "radiating_groin_pain",
      "tenderness_in_the_groin_area",
      "pain_in_the_inguinal_area",
      "pelvic_pain",
      "pelvic_pain_or_tenderness",
    ],
    SMCTL2_00224: ["hip_pain_or_tenderness", "hip_pain"],
    SMCTL2_00225: ["insect_bites_or_stings"],
    SMCTL2_00232: ["pain_or_discomfort_in_the_jaw_while_chewing"],
    SMCTL2_00226: ["jaw_pain", "pain_while_chewing"],
    SMCTL2_00230: ["eye_irritation"],
    SMCTL2_00229: ["somatic_pain"],
    SMCTL2_00217: ["coccyx_pain"],
    SMCTL2_00231: ["tenderness_of_tendons"],
    SMCTL2_00236: ["oral_thrush"],
    SMCTL2_00235: ["palpitations"],
    SMCTL2_00239: [
      "weakness_in_extending_the_arm",
      "weakness_in_the_arms",
      "limb_weakness",
      "limb_weakness",
      "difficulty_climbing_stairs",
      "weakness_in_facial_muscles",
      "weakness_in_finger_muscles",
      "general_muscle_weakness",
      "bilateral_generalized_muscle_weakness",
      "lower_limb_generalized_muscle_weakness",
      "unilateral_generalized_muscle_weakness",
      "upper_limb_generalized_muscle_weakness",
      "weakness_of_thenar_muscles_(hand)",
      "weakness_in_hand_muscles",
      "hard_stools",
      "weakness_in_hip_muscles",
      "corneal_inflammation",
      "weakness_of_lower_limbs",
      "bilateral_lower_limb_weakness",
      "unilateral_lower_limb_weakness",
      "muscle_weakness_in_legs",
      "pale_stools",
      "shoulder_weakness",
      "bilateral_shoulder_weakness",
      "unilateral_shoulder_weakness",
      "weakness_in_thigh_muscles",
      "unilateral_weakness_of_limbs",
      "weakness_of_upper_limbs",
      "bilateral_upper_limb_weakness",
      "unilateral_upper_limb_weakness",
    ],
    SMCTL2_00237: [
      "hemiparesis",
      "paralysis",
      "paralysis_of_all_limbs",
      "facial_paralysis",
      "paralysis_of_the_lower_limbs",
      "paralysis_on_one_side",
      "arm_paralysis",
      "paralysis_of_the_bicep",
      "paralysis_of_finger",
      "paralysis_of_the_shoulder",
      "paralysis_of_the_triceps",
      "paralysis_of_the_wrist",
      "paraparesis",
      "poor_feeding",
      "paralysis_of_upper_limbs",
    ],
    SMCTL2_00257: [
      "abdominal_enlargement_or_distension_or_swelling",
      "mass_in_the_abdomen",
      "abdominal_mass",
      "enlarged_liver_and_spleen_hepatosplenomegaly",
      "enlarged_liver",
    ],
    SMCTL2_00240: ["puddles_sign"],
    SMCTL2_00252: ["abnormal_red_reflex"],
    SMCTL2_00245: ["anal_discharge"],
    SMCTL2_00243: [
      "abnormal_breathing_sound",
      "grunting",
      "pleural_rub",
      "rhonchi",
      "squeaks",
      "stridor",
      "abnormal_respiratory_sound",
    ],
    SMCTL2_00254: [
      "dark_circles_below_the_eyes",
      "skin_tag",
      "thickening_of_tendons",
      "thrombosis_(blood_clots)",
    ],
    SMCTL2_00250: [
      "reduced_intraocular_pressure",
      "elevated_intraocular_pressure",
    ],
    SMCTL2_00248: ["uterine_prolapse", "perineal_mass", "rectal_mass"],
    SMCTL2_00246: ["abnormal_fundoscopic_examination"],
    SMCTL2_00249: ["heart_murmur", "tumor_plop"],
    SMCTL2_00255: ["abnormal_otoscopic_test"],
    SMCTL2_00258: ["twin_pregnancy"],
    SMCTL2_00265: [
      "fetal_growth_restriction",
      "miscarriage",
      "premature_preterm_labor",
      "decreased_fetal_movement",
      "still_birth_or_miscarriage",
    ],
    SMCTL2_00267: ["faint_pulse"],
    SMCTL2_00269: ["irregular_pulse"],
    SMCTL2_00271: [
      "abnormal_skin_texture",
      "coarse_skin",
      "dry_skin",
      "moist_skin",
      "oily_skin",
      "abnormal_skin_pigmentation",
      "darkening_of_the_skin",
      "tender_skin",
      "thick_and_rough_skin_on_the_palms",
      "thick_and_rough_skin_on_the_soles",
      "thick_skin",
      "thickening_&_tightening_of_skin_of_fingers_or_toes",
      "facial_wrinkles",
    ],
    SMCTL2_00286: ["pusfilled_lump", "abscess_on_the_skin"],
    SMCTL2_00287: [
      "anal_sores",
      "ulcer_in_the_rectum",
      "scar",
      "ulcer_on_the_fingers",
      "foot_ulcer",
      "ulcer_on_the_hands",
      "ankle_ulcer",
      "ulcer_on_the_upper_thigh",
      "ulcer_on_the_legs",
      "ulcers_on_the_vulva_or_vagina",
    ],
    SMCTL2_00272: ["pustular_rash"],
    SMCTL2_00275: ["blisters_eye"],
    SMCTL2_00274: [
      "bruising",
      "bruise_on_the_head",
      "bruise_on_the_hip",
      "bruise_calf",
      "bruise_on_the_neck",
      "ecchymosis_in_the_inguinal_area",
    ],
    SMCTL2_00277: [
      "lesions_and_sores_on_the_buccal_mucosa",
      "mouth_sores",
      "mouth_sores",
      "mouth_blisters",
      "scalp_sores",
      "oral_lesions",
      "difficulty_opening_jars",
      "palate_sores",
      "ulcers_or_fissures_at_mouth_angles",
      "whitish_patches_in_the_buccal_mucosa",
    ],
    SMCTL2_00280: [
      "blisters",
      "eczema_lesions",
      "lesions_on_the_face",
      "macular_rash",
      "maculopapular_rash",
      "nodular_rash",
      "nodular_rash_on_the_palm",
      "subcutaneous_nodular_rash",
      "papular_rash",
      "papular_rash_on_the_areola",
      "papular_rash_in_the_axilla",
      "papular_rash_on_the_back",
      "papular_rash_on_the_buttocks",
      "papular_rash_on_the_hands_fingers_and_feet",
      "papular_rash_on_the_knees",
      "papular_rash_between_the_fingers",
      "papular_rash_around_the_waist",
      "papular_rash_on_the_wrist",
      "papular_rash_on_the_face_neck_and_palms_soles",
      "plaque_rash",
      "general_rash",
      "bullous_rash",
      "rash_on_the_buttocks",
      "chest_rash",
      "diffused_rash",
      "rash_in_the_diaper_area",
      "rash_on_the_breast",
      "rash_on_the_palms_and_soles",
      "skin_rash_red",
      "facial_rash",
      "ulcer",
      "vesicular_rash_on_the_areola",
      "vesicular_rash_in_the_axilla",
      "vesicular_rash_on_the_back",
      "vesicular_rash_on_the_buttocks",
      "vesicular_rash_on_the_gluteal_region",
      "vesicular_rash_on_the_knees",
      "vesicular_rash_on_the_mucous_membranes",
      "vesicular_rash_on_the_nose",
      "vesicular_rash_on_the_ear",
      "vesicular_rash_on_the_palms_and_soles",
      "vesicular_rash_in_the_perianal_area",
      "vesicular_rash_between_the_fingers",
      "vesicular_rash_on_the_upper_thigh",
      "vesicular_rash_around_the_waist",
      "vesicular_rash_on_the_wrist",
      "vulval_or_vaginal_lesion",
    ],
    SMCTL2_00273: [
      "cyanosis_bluish_skin_color",
      "bluishgrey_flank_discoloration",
      "blue_discoloration_around_the_umbilicus",
    ],
    SMCTL2_00281: [
      "redness_of_the_face",
      "flushing_of_skin",
      "skin_reddening",
      "reddening_of_the_feet",
      "reddening_of_the_fingers",
      "reddening_of_the_heels",
      "localized_skin_reddening",
      "reddening_of_the_nappy_area",
      "reddening_of_the_palms",
      "reddening_of_the_soles",
      "reddening_of_the_toes",
    ],
    SMCTL2_00290: [
      "jaundice_yellowing_of_the_skin",
      "jaundice_present_at_birth",
    ],
    SMCTL2_00276: ["mole", "acne_on_the_face"],
    SMCTL2_00279: ["pallor_paleness", "pallor_fingers", "pallor_limbs"],
    SMCTL2_00282: [
      "perianal_or_anal_inflammation_redness",
      "redness_of_the_breast",
      "redness_of_the_vulva_or_vagina",
    ],
    SMCTL2_00284: ["scalp_rash", "allergy", "scalp_flaking"],
    SMCTL2_00283: ["butterfly_rash", "scaly_skin_hands"],
    SMCTL2_00285: ["skin_scaling"],
    SMCTL2_00289: ["tendinous_xanthomas"],
    SMCTL2_00288: ["vulva_or_vagina_depigmentation"],
    SMCTL2_00293: ["delayed_orgasm", "pain_during_intercourse"],
    SMCTL2_00291: ["hemiplegia", "decreased_libido", "decreased_sexual_desire"],
    SMCTL2_00292: ["samesex_attraction"],
    SMCTL2_00295: [
      "decreased_sleep_need",
      "loss_of_appetite",
      "nightmare",
      "short_sleep_duration",
      "sleep_disturbances",
      "talking_in_sleep",
      "sleepwalking",
      "insomnia",
      "waking_unrefreshed",
      "confusional_arousals",
    ],
    SMCTL2_00294: ["drowsiness", "excessive_sleep", "frequent_yawning"],
    SMCTL2_00296: ["snoring"],
    SMCTL2_00297: ["sneezing"],
    SMCTL2_00298: [
      "change_in_speech_pitch",
      "shift_quickly_between_topics_and_get_distracted_by_internal_and_external_stimuli",
      "sparse_speech_content",
      "pressured_speech",
      "slow_speech_or_speech_delay",
      "soft_or_muffled_speech",
      "speech_disorder",
      "speech_impairment_with_stuttering",
      "temporary_speech_impairment",
      "substitution_of_words_or_phrases_or_sounds_in_speech",
    ],
    SMCTL2_00299: ["excessive_sweating"],
    SMCTL2_00300: [
      "abnormal_bony_growth",
      "ankle_swelling",
      "bone_swelling",
      "calf_swelling",
      "blurred_vision",
      "swelling_of_the_feet",
      "flank_mass",
      "hemorrhoids_distended_rectal_veins",
      "swelling_of_the_hands",
      "facial_lump",
      "induration_hardening",
      "swelling_of_the_knee",
      "left_upper_quadrant_luq_mass_in_the_abdomen",
      "swelling_of_the_legs",
      "lower_mass_in_the_abdomen",
      "swelling_in_the_lower_abdomen",
      "swelling_of_the_lower_limbs",
      "lump_under_the_wrist",
      "swelling_of_muscles",
      "neck_swelling",
      "pelvic_mass",
      "swelling_around_the_anus",
      "masses_or_tumor_in_the_pharynx",
      "swelling_in_the_pharynx",
      "presacral_swelling_or_edema",
      "right_upper_quadrant_ruq_mass_in_the_abdomen",
      "swelling_of_the_salivary_gland",
      "feeling_of_a_mass_in_the_abdomen",
      "skin_lump_mass_or_growth",
      "swelling_of_the_skull",
      "enlargement_of_the_submandibular_salivary_gland",
      "swelling_inside_the_ear",
      "swelling_of_the_forearm",
      "subcutaneous_swelling",
      "swelling_of_the_nose",
      "brittle_hair",
      "swelling_of_the_hip",
      "facial_swelling",
      "umbilical_nodules",
      "upper_mass_in_the_abdomen",
      "swelling_in_the_upper_abdomen",
      "swelling_of_the_upper_limbs",
      "wrist_swelling",
      "wrist_swelling_with_pain",
    ],
    SMCTL2_00302: [
      "swollen_or_enlarged_anterior_cervical_lymph_nodes",
      "swollen_or_enlarged_axillary_lymph_nodes",
      "swollen_or_enlarged_femoral_lymph_nodes",
      "swollen_or_enlarged_inguinal_lymph_nodes",
      "nasal_congestion",
      "swollen_or_enlarged_preauricular_lymph_nodes",
      "swollen_or_enlarged_tonsils",
      "mass_in_the_groin_or_suprapubic_area",
      "swelling_in_the_groin_or_suprapubic_area",
      "swelling_of_the_parotid_gland",
      "swelling_of_the_lymph_nodes",
    ],
    SMCTL2_00301: ["swelling_edema", "swelling_after_vaccination"],
    SMCTL2_00314: ["bone_weakness"],
    SMCTL2_00313: ["general_malaise"],
    SMCTL2_00312: ["weakness_or_lethargy_or_fatigue"],
    SMCTL2_00316: ["excessive_weight", "weight_increase"],
    SMCTL2_00317: ["weight_decrease"],
  };

  const maleThirdLevelSymtoms = {
    SMCTL2_00001: ["abnormal_crying", "crying", "weak_cry"],
    SMCTL2_00002: [
      "aggressive_behavior",
      "agitation",
      "facial_grimacing",
      "tantrum",
    ],
    SMCTL2_00004: [
      "cross_dressing_practice_of_wearing_clothing_typically_associated_with_the_opposite_gender",
      "odd_behavior",
      "changing_friends",
      "lack_of_coordination",
      "jaw_clenching",
      "mania",
      "nail_biting",
      "personality_or_behavior_change",
      "polysubstance_dependence",
      "lip_puckering",
      "stereotyped_actions",
      "suspicious_behavior",
      "teeth_grinding",
      "thumb_sucking",
    ],
    SMCTL2_00006: [
      "rocking_behavior",
      "unable_to_engage_quietly_in_leisure_activities",
      "irritability",
      "hyperactivity",
      "impatient",
      "inability_to_sit_still_or_restlessness_or_fidgeting",
      "interrupting_others",
      "excessive_talking",
    ],
    SMCTL2_00005: [
      "compulsive_actions",
      "irresistible_urge_to_move_legs",
      "grasping_or_picking_at_imaginary_objects",
      "repetitive_behavior",
      "ritualistic_actions",
    ],
    SMCTL2_00003: [
      "decline_in_daily_activities",
      "difficulty_interacting_with_peers",
    ],
    SMCTL2_00007: [
      "recollection_of_past_events_flashback",
      "selfharm",
      "selfneglect",
    ],
    SMCTL2_00010: [
      "abnormal_jaw_movements",
      "drop_attacks_sudden_falls_with_or_without_loss_of_consciousness",
      "difficulty_passing_stools",
      "dysdiadochokinesis_the_inability_to_perform_rapid_alternating_muscle_movements",
    ],
    SMCTL2_00011: [
      "unusual_movements",
      "abnormal_hand_movement",
      "seizure",
      "febrile_seizures",
      "focal_seizures",
      "tonicclonic_seizures",
    ],
    SMCTL2_00012: [
      "ankle_muscle_spasms",
      "hand_flapping_tremor",
      "head_tremors",
      "intention_tremors",
      "tremor_of_the_jaw",
      "tremor_postural",
      "tremor_at_rest",
      "tics_or_fasciculations",
      "tics_or_muscle_twitches",
      "general_tremor",
    ],
    SMCTL2_00008: [
      "involuntary_writhing_movements_athetosis",
      "automatic_movements_automatisms",
      "rapid_jerky_movements_chorea",
      "emotional_distress",
      "head_nodding",
      "unilateral_spasm_hemiballismus",
      "lipsmacking",
      "stereotyped_movements",
      "involuntary_movement_coordination_synkinesis",
    ],
    SMCTL2_00009: ["poor_muscle_coordination"],
    SMCTL2_00013: [
      "abnormal_sensation",
      "loss_of_sensation",
      "abnormal_sensation_in_the_face",
      "abnormal_sensation_in_the_limbs",
      "goosebumps",
      "hyperaesthesia",
    ],
    SMCTL2_00014: [
      "abnormal_sensation_in_the_anal_perianal_area",
      "protrusion_from_the_anus",
    ],
    SMCTL2_00015: [
      "burning_sensation",
      "burning_sensation_in_arms",
      "burning_sensation_in_fingers",
      "burning_sensation_in_hands",
      "burning_sensation_in_legs_and_feet",
      "burning_sensation_in_toes",
    ],
    SMCTL2_00016: [
      "itching",
      "generalized_itching",
      "itching_of_the_palate",
      "scalp_itching",
      "itching_in_the_throat",
      "perianal_or_anal_itching",
    ],
    SMCTL2_00018: [
      "gangrene",
      "bilateral_loss_of_sensation",
      "unilateral_loss_of_sensation",
      "numb_feeling",
      "numbness_in_the_arms",
      "numbness_in_cheeks",
      "numbness_in_the_face",
      "numbness_upper_limb",
      "intermittent_numbness",
      "numbness_in_legs_and_feet",
      "numbness_lower_limb",
      "numbness_in_the_mouth",
      "onesided_numbness",
      "perianal_numbness",
      "perineal_numbness",
      "shoulder_numbness",
      "tingling_sensation",
      "tingling_sensation_in_arms",
      "tingling_sensation_in_cheeks",
      "tingling_sensation_in_extremities",
      "tingling_sensation_in_hands",
      "tingling_sensation_in_legs_and_feet",
      "tingling_sensation_in_the_mouth",
      "tingling_sensation_in_toes",
    ],
    SMCTL2_00017: ["loss_of_vibration_sense"],
    SMCTL2_00019: ["chills_or_rigors"],
    SMCTL2_00023: [
      "cold_hands_or_feet",
      "cold_sensation_in_fingers",
      "cold_sensation_in_hands",
    ],
    SMCTL2_00022: ["cold_sensations", "hot_sensations"],
    SMCTL2_00020: ["fever"],
    SMCTL2_00021: ["frostbite"],
    SMCTL2_00025: ["anxiety", "panic"],
    SMCTL2_00026: [
      "lack_of_interest",
      "boredom",
      "emotional_dysfunction",
      "lack_of_pleasure",
      "lack_of_drive_or_motivation",
      "social_isolation",
    ],
    SMCTL2_00029: [
      "hearing_hallucinations",
      "delusions",
      "excessive_vanity_and_selfcenteredness",
      "grandiose_delusions",
      "feelings_of_unreality",
      "feelings_of_suspicion_or_mistrust",
      "illusions",
      "irrational_thoughts_or_odd_thinking",
      "visual_hallucinations",
    ],
    SMCTL2_00030: [
      "depression",
      "seasonal_depression",
      "feeling_of_guilt",
      "suicidal_thoughts",
    ],
    SMCTL2_00024: ["feeling_of_disgust"],
    SMCTL2_00027: ["state_of_increased_alertness", "mood_swings"],
    SMCTL2_00028: ["obsession", "undue_concern_about_body_shape_or_weight"],
    SMCTL2_00031: [
      "phobias",
      "fear_of_eating",
      "fear_of_flying",
      "fear_of_water",
      "freezing_due_to_fear",
    ],
    SMCTL2_00032: [
      "abnormal_body_posture",
      "asymmetrical_posture",
      "stooped_posture",
      "bent_posture_while_walking",
      "wrist_drop",
    ],
    SMCTL2_00033: [
      "arching_of_the_neck",
      "head_dropping",
      "head_tilting",
      "involuntary_neck_deviation",
    ],
    SMCTL2_00035: ["numbness_in_fingers", "tingling_sensation_in_fingers"],
    SMCTL2_00036: [
      "sensation_of_a_lump_in_the_throat",
      "swelling_of_the_thyroid_gland",
    ],
    SMCTL2_00039: [
      "coma",
      "reduced_alertness",
      "delirium",
      "state_of_mental_confusion",
      "fainting",
      "decreased_consciousness",
      "stupor_nearunconsciousness",
    ],
    SMCTL2_00038: [
      "dizziness",
      "dizziness_upon_changing_posture",
      "lightheadedness",
    ],
    SMCTL2_00040: [
      "irregular_walking_pattern",
      "widebased_gait",
      "bentover_walking",
      "abnormal_involuntary_eye_movements",
      "dragging_of_the_foot",
      "slapping_of_the_foot",
      "highstepping_walk",
      "limp",
      "balance_loss",
      "shuffling_gait",
      "stifflegged_walk",
      "heeltotoe_walking",
      "tiptoe_walking",
      "unsteady_walk",
      "waddling_walk",
    ],
    SMCTL2_00041: ["antalgic_gait"],
    SMCTL2_00042: [
      "acid_reflux",
      "belching_eructation",
      "excessive_burping_or_belching",
      "burning_sensation_in_the_chest",
      "heartburn_after_meals",
      "stomach_upset_indigestion",
    ],
    SMCTL2_00044: [
      "fracture",
      "enlarged_pharyngeal_lymph_nodes",
      "perianal_fistulas",
    ],
    SMCTL2_00043: ["abnormal_rectal_bleeding"],
    SMCTL2_00046: [
      "anal_or_perianal_or_rectal_pain",
      "perineal_pain",
      "perineal_scarring",
      "tenderness_in_the_rectal_or_anal_area",
    ],
    SMCTL2_00048: [
      "rectal_swelling_that_feels_hard",
      "rectal_pressure",
      "rectal_tumor",
    ],
    SMCTL2_00050: [
      "postural_low_blood_pressure",
      "low_blood_pressure_due_to_shock",
    ],
    SMCTL2_00052: [
      "abnormal_bowel_sounds",
      "abdominal_gurgling_borborygmi",
      "decreased_bowel_sounds",
      "increased_frequency_of_bowel_sounds",
    ],
    SMCTL2_00053: [
      "irregular_stools",
      "aversion_to_food",
      "presence_of_mucus_in_stool",
      "night_terrors",
      "black_and_tarry_stools_melena",
      "foul_smelling_stool",
      "blood_in_stools",
      "worms_in_stool",
    ],
    SMCTL2_00055: [
      "constipation_and_diarrhoea_at_alternate_intervals",
      "constipation",
      "difficulty_defecating",
      "dependent_edema",
      "gas",
      "no_passage_of_stools",
      "painful_bowel_movements",
    ],
    SMCTL2_00056: [
      "constipation_and_diarrhoea_at_alternate_intervals",
      "loose_stools_diarrhoea",
      "chronic_diarrhea",
      "frequent_copious_diarrhea",
      "watery_diarrhea",
      "diarrhea_with_blood",
      "diarrhea_with_blood_and_mucus",
      "enlarged_spleen",
      "feeling_of_incomplete_bowel_emptying",
      "redness_of_oral_mucosa",
    ],
    SMCTL2_00054: [
      "loss_of_bowel_control",
      "change_in_bowel_habits",
      "increased_libido",
      "involuntary_stools",
      "stool_leakage",
    ],
    SMCTL2_00059: [
      "reduced_milk_production",
      "localized_increase_in_temperature_breast",
      "galactorrhea",
    ],
    SMCTL2_00057: [
      "discharge_from_the_breast",
      "green_discharge_from_the_breast",
    ],
    SMCTL2_00061: ["choking", "gasping"],
    SMCTL2_00062: [
      "shortness_of_breath_dyspnea",
      "shortness_of_breath_at_night",
      "shortness_of_breath_at_rest",
      "difficulty_inhaling_shortness_of_breath",
      "shortness_of_breath_dyspnea_episodic",
      "shortness_of_breath_dyspnea_on_exertion",
      "shortness_of_breath_while_talking",
    ],
    SMCTL2_00063: [
      "burns",
      "dry_burn",
      "absent_tendon_reflexes",
      "facial_burn",
    ],
    SMCTL2_00066: [
      "deficit_in_attention",
      "careless_errors",
      "difficulty_focusing",
      "incomplete_tasks_or_assignments",
      "lack_of_attention_to_detail",
      "easily_gets_distracted",
    ],
    SMCTL2_00065: [
      "cognitive_decline",
      "delay_in_cognitive_or_intellectual_development",
      "dysentery",
      "dysmetria_inability_to_control_the_distance_speed_and_range_of_motion_necessary_to_perform_smoothly_coordinated_movements",
      "poor_school_performance",
      "poor_work_performance",
      "inability_to_perform_calculations",
      "neurological_difficulties_difficulty_in_construction_tasks",
      "inability_to_read",
      "difficulty_visualizing_and_understanding_space",
      "difficulty_writing",
      "difficulty_recognizing_faces",
      "difficulty_making_decisions",
      "difficulty_learning",
      "loss_of_sense_of_position",
      "intellectual_disability_mental_retardation",
      "impaired_nonverbal_communication",
      "though_disorder_tangentiality_and_derailment",
    ],
    SMCTL2_00064: ["dysarthria_difficulty_speaking"],
    SMCTL2_00067: [
      "frequently_losing_things",
      "memory_loss",
      "longterm_memory_loss",
      "shortterm_memory_loss",
      "gradual_loss_of_language_fluency",
    ],
    SMCTL2_00072: ["cough", "funnel_chest"],
    SMCTL2_00070: ["cough_with_blood"],
    SMCTL2_00071: ["cough_with_phlegm_or_sputum"],
    SMCTL2_00068: ["whooping_cough"],
    SMCTL2_00086: ["abnormal_urethral_opening", "limb_deformities"],
    SMCTL2_00084: ["absent_testicle", "small_testicles", "soft_testicle"],
    SMCTL2_00075: [
      "barrel_chest",
      "broad_chest",
      "chest_deformity",
      "concave_chest",
      "food_intolerance",
      "pigeon_chest",
    ],
    SMCTL2_00082: [
      "bowlegs",
      "clubfoot",
      "flatfoot",
      "foot_drop",
      "knock_knee",
    ],
    SMCTL2_00079: ["claw_hand"],
    SMCTL2_00080: [
      "cleft_lip",
      "cleft_palate",
      "facial_asymmetry",
      "flat_head",
      "enlarged_head",
      "deviated_nasal_septum",
      "rounded_face",
      "small_head",
      "small_mouth",
      "soft_skull_craniotabes",
    ],
    SMCTL2_00081: ["joint_tenderness"],
    SMCTL2_00087: ["rapid_blinking"],
    SMCTL2_00088: [
      "lordosis",
      "deformity_of_the_spine",
      "bamboo_spine_spinal_deformity",
      "lateral_curvature_of_the_spine",
    ],
    SMCTL2_00078: ["nail_clubbing", "swelling_of_fingers"],
    SMCTL2_00085: ["short_neck", "webbed_neck"],
    SMCTL2_00089: [
      "delayed_crawling_or_walking",
      "delayed_motor_skills_development",
      "delayed_language_development",
      "late_walking_onset",
      "developmental_disability",
    ],
    SMCTL2_00093: ["late_onset_of_puberty"],
    SMCTL2_00091: ["growth_delay"],
    SMCTL2_00090: ["reduced_uterine_size", "small_penis"],
    SMCTL2_00099: ["sudden_hearing_loss"],
    SMCTL2_00106: ["heart_attack"],
    SMCTL2_00109: ["bleeding_from_the_ears"],
    SMCTL2_00110: ["hearing_loss_deafness", "nasal_polyps"],
    SMCTL2_00108: ["discharge_from_the_ear", "bloody_discharge_from_the_ear"],
    SMCTL2_00114: [
      "ear_fullness_or_pressure",
      "ear_pain",
      "tenderness_of_the_tragus",
    ],
    SMCTL2_00111: ["itching_in_the_ear"],
    SMCTL2_00112: ["earwax"],
    SMCTL2_00113: [
      "sensitivity_to_noise",
      "increased_sensitivity_to_one's_own_voice_or_sounds",
      "ringing_in_the_ears_tinnitus",
      "tophi_deposit_of_monosodium_urate_crystals",
    ],
    SMCTL2_00120: [
      "loss_of_taste_ageusia",
      "bad_taste",
      "bitter_taste_in_the_mouth",
      "loss_of_taste_for_food",
      "diminished_taste_sensation",
    ],
    SMCTL2_00116: [
      "vesicular_rash",
      "anorexia",
      "decreased_appetite",
      "feeling_full_after_eating_very_little_food",
      "enhanced_hunger",
      "infrequent_stools",
    ],
    SMCTL2_00115: ["eating_disorder_binge_eating", "binge_eating"],
    SMCTL2_00119: ["decreased_thirst", "heightened_increased_thirst"],
    SMCTL2_00118: [
      "difficulty_chewing",
      "swallowing_difficulty",
      "swallowing_difficulty_for_liquids",
      "swallowing_difficulty_for_solids",
      "swallowing_difficulty_painful",
      "abdominal_pain_after_fatty_meal",
      "feeding_difficulties",
      "explosive_stools",
      "paraplegia",
    ],
    SMCTL2_00117: ["eats_nonfood_items_pica"],
    SMCTL2_00122: [
      "abnormal_eye_movements",
      "word_blindness",
      "enlarged_ovary",
      "loss_or_decreased_movement_of_eye_ball",
    ],
    SMCTL2_00123: ["abnormal_eye_positioning"],
    SMCTL2_00132: [
      "bruise_around_the_eye_black_eye",
      "light_halos",
      "whitecentered_retinal_hemorrhages",
      "nodules_in_the_retina",
      "rust_ring_on_the_cornea",
      "eye_squint",
      "prolonged_staring",
      "eyes_appearing_sunken",
      "gray_or_white_arc_that_appears_in_the_eye_above_and_below_the_outer_cornea",
    ],
    SMCTL2_00124: ["bleeding_from_the_eye"],
    SMCTL2_00126: [
      "presence_of_blind_spots",
      "total_blindness",
      "corneal_cloudiness",
      "reduced_visual_acuity",
      "reduced_visual_acuity_in_both_eyes",
      "reduced_visual_acuity_in_the_left_eye",
      "reduced_visual_acuity_in_the_right_eye",
      "gradual_decline_in_vision",
      "gradual_central_vision_loss",
      "gradual_peripheral_vision_loss",
      "vision_loss",
      "progressive_loss_of_vision",
      "temporary_loss_of_vision",
      "difficulty_seeing_at_night_night_blindness",
      "sudden_painless_vision_loss",
    ],
    SMCTL2_00125: ["color_vision_deficiency_color_blindness"],
    SMCTL2_00135: ["pallor_conjunctiva"],
    SMCTL2_00138: [
      "pink_eye_inflammation_of_the_conjunctiva",
      "cervical_sores",
      "inflammation_of_the_iris",
      "eye_redness",
      "redness_in_both_eyes",
      "left_eye_redness",
      "right_eye_redness",
      "redness_of_the_eyelids",
    ],
    SMCTL2_00136: [
      "constricted_pupils",
      "dilated_pupils",
      "unequal_pupil_sizes",
    ],
    SMCTL2_00129: [
      "eyelid_contractions",
      "crusty_eyelid_or_eyelashes",
      "difficulty_closing_eyelids",
      "skin_crease_eyelid",
      "ulcer_on_the_eyelid_margin",
      "xanthelasma",
      "eyelids_sticking_together_in_the_morning",
      "follicles_on_the_inner_surface_of_the_eyelids",
      "delay_in_eyelid_movement_lid_lag",
    ],
    SMCTL2_00127: ["double_vision"],
    SMCTL2_00128: ["dryness_of_the_eyes"],
    SMCTL2_00121: [
      "discharge_from_the_eye",
      "pus_discharge_from_the_eye",
      "eye_discharge_watery",
    ],
    SMCTL2_00131: ["thin_nails", "itching_of_the_eyes"],
    SMCTL2_00134: [
      "pain_in_the_eyes",
      "pain_in_the_eyelids",
      "eye_pain",
      "tenderness_around_the_eye",
    ],
    SMCTL2_00140: [
      "burns_on_the_eyelids",
      "foreign_body_in_the_conjunctiva",
      "foreign_body_in_the_cornea",
      "sensation_of_a_foreign_body_in_the_eye",
      "fracture_or_trauma_to_the_orbit",
    ],
    SMCTL2_00139: [
      "eyelid_swelling_edema",
      "swelling_of_the_eye",
      "swelling_around_the_eye_area",
      "conjunctival_swelling",
    ],
    SMCTL2_00130: ["sensitivity_to_bright_light", "sensitivity_to_light"],
    SMCTL2_00137: ["difficulty_walking", "reduced_blink_rate"],
    SMCTL2_00133: ["visual_floaters", "visual_perceptual_abnormalities"],
    SMCTL2_00141: [
      "bad_odor",
      "bad_odor_from_fingers",
      "abnormal_rectal_discharge",
    ],
    SMCTL2_00143: [
      "abnormal_urine",
      "pus_in_the_urine",
      "abnormal_urine_flow",
      "weak_urine_stream",
      "urinary_incontinence",
      "urinary_retention",
    ],
    SMCTL2_00147: ["foulsmelling_urine"],
    SMCTL2_00144: ["weak_voice"],
    SMCTL2_00146: [
      "burning_sensation_or_difficulty_in_urination",
      "difficulty_urinating",
      "urinary_obstruction",
    ],
    SMCTL2_00149: [
      "decreased_urine_output_anuria",
      "decreased_urine_output_or_urinary_retention",
      "increased_frequency_or_output_of_urination",
      "increased_urination_frequency",
      "increased_urine_output",
    ],
    SMCTL2_00142M: [
      "bloody_discharge_from_the_penis",
      "discharge_from_the_penis",
      "discharge_from_penis_containing_pus",
    ],
    SMCTL2_00152: [
      "urinary_infection",
      "inflammation_or_infection_of_the_glans_penis",
      "accumulation_of_white_matter_at_the_tip_of_the_penis",
    ],
    SMCTL2_00154: [
      "lump_in_the_scrotum",
      "swelling_of_the_penis",
      "swelling_of_the_glans_penis",
      "mass_in_the_scrotum",
      "scrotal_mass",
      "swelling_or_enlargement_of_the_testis",
      "swelling_or_enlargement_of_the_scrotum",
    ],
    SMCTL2_00153: ["penile_pain", "pain_in_the_scrotum", "scrotal_pain"],
    SMCTL2_00150: ["urethral_irritation_or_itching"],
    SMCTL2_00155: [
      "urinary_incontinence_loss_of_bladder_control",
      "urinary_incontinence_with_straining",
    ],
    SMCTL2_00156: ["urine_discoloration"],
    SMCTL2_00160: [
      "hair_loss_armpit",
      "hair_loss",
      "hair_loss_foot",
      "male_pattern_baldness",
    ],
    SMCTL2_00157: ["bleeding_from_the_nails"],
    SMCTL2_00163: [
      "sinus_congestion",
      "dry_hair",
      "lightly_pigmented_hair",
      "stroke",
    ],
    SMCTL2_00164: ["brittle_nails"],
    SMCTL2_00158: ["discolored_nails"],
    SMCTL2_00159: ["excessive_body_hair", "excessive_hair_growth_on_the_legs"],
    SMCTL2_00162: ["nail_abnormalities"],
    SMCTL2_00165: ["inflamed_nail_folds"],
    SMCTL2_00166: ["frequent_hiccups"],
    SMCTL2_00168: [
      "bone_cracking_crepitus",
      "abnormal_joint_sounds",
      "hip_fracture",
      "inability_to_bear_weight",
      "long_bone_fractures",
      "rib_fractures",
      "fracture_of_the_spine",
      "wrist_fracture",
    ],
    SMCTL2_00167: [
      "finger_dislocation",
      "hip_dislocation",
      "joint_dislocation",
      "shoulder_dislocation",
    ],
    SMCTL2_00171: [
      "gum_injuries",
      "injury_to_the_lip",
      "quadriceps_tendon_rupture",
    ],
    SMCTL2_00169: ["rupture_of_the_patellar_tendon"],
    SMCTL2_00172: ["bad_breath"],
    SMCTL2_00173: [
      "bleeding_from_the_mucous_membranes",
      "bleeding_from_the_palate",
      "bleeding_from_throat",
    ],
    SMCTL2_00183: [
      "dental_cavities_decay",
      "dental_enamel_hypoplasia_or_pits",
      "dental_plaque",
      "loose_teeth",
      "tooth_loss",
      "wear_on_teeth",
    ],
    SMCTL2_00184: [
      "difficulty_controlling_bodily_secretions",
      "drooling",
      "excessive_salivation",
    ],
    SMCTL2_00178: ["drooping_of_mouth_corners"],
    SMCTL2_00175: ["dry_mouth", "dry_mouth_in_the_early_morning"],
    SMCTL2_00174: ["erosion_of_tooth_enamel"],
    SMCTL2_00182: [
      "enlarged_tongue_or_thick_tongue",
      "cracked_or_fissured_tongue",
      "coated_tongue_hairy_tongue",
      "torn_lingual_frenum_tongue_web",
    ],
    SMCTL2_00176: ["cracked_lips"],
    SMCTL2_00181: [
      "swelling_inside_the_mouth_hard_palate",
      "swelling_of_the_lips",
      "oral_mass",
      "swelling_of_the_gums",
    ],
    SMCTL2_00179: [
      "difficulty_opening_the_mouth",
      "pain_in_the_mouth",
      "pain_while_speaking",
      "gum_pain",
      "toothache",
    ],
    SMCTL2_00177: ["lip_discoloration", "torn_lips"],
    SMCTL2_00180: [
      "redness_of_the_buccal_mucosa",
      "red_swollen_gums",
      "quadriplegia",
    ],
    SMCTL2_00193: [
      "arm_muscle_enlargement",
      "calf_muscle_enlargement",
      "jaw_muscle_enlargement",
      "leg_muscle_enlargement",
      "muscle_enlargement_hypertrophy",
      "neck_muscle_enlargement",
    ],
    SMCTL2_00186: [
      "reduced_tendon_reflexes",
      "poor_muscle_tone",
      "reduced_or_absent_ankle_reflex",
    ],
    SMCTL2_00199: [
      "breast_fullness",
      "difficulty_dressing",
      "stiffness_of_the_finger",
      "joint_contracture_in_hands",
      "joint_stiffness",
      "joint_stiffness_small_joints",
      "knee_stiffness",
      "shoulder_stiffness",
    ],
    SMCTL2_00194: [
      "muscle_contractions_dystonia",
      "foot_spasm",
      "hand_spasm",
      "involuntary_muscle_contractions",
      "muscle_contractions_or_spasm",
      "muscle_spasms",
      "sudden_muscle_jerks_myoclonus",
    ],
    SMCTL2_00198: [
      "elbow_pain_or_tenderness",
      "finger_pain",
      "localized_increase_in_temperature_joints",
      "joint_pain",
      "foot_joint_pain",
      "pain_in_large_joints",
      "hand_joint_pain",
      "temporomandibular_joint_pain",
      "pain_in_small_joints",
      "knee_pain_or_tenderness",
      "knee_pain_or_tenderness",
      "muscle_stiffness",
      "tendon_rupture",
      "shoulder_pain",
    ],
    SMCTL2_00187: ["abnormal_position_of_the_hip"],
    SMCTL2_00188: ["hip_abnormal_sounds", "loud_pop_in_knee"],
    SMCTL2_00189: ["hip_dysplasia"],
    SMCTL2_00195: ["hypermobile_joints"],
    SMCTL2_00190: [
      "joint_instability",
      "weakness_in_joints",
      "instability_of_the_knee",
    ],
    SMCTL2_00191: ["swelling_of_joints", "swelling_of_the_joints"],
    SMCTL2_00196: ["loss_of_voluntary_anal_control"],
    SMCTL2_00192: [
      "muscle_atrophy_in_lower_limbs",
      "muscle_atrophy_or_wasting",
      "quadriceps_muscle_wasting",
      "testicular_atrophy",
      "thigh_muscle_atrophy",
      "soft_stools",
    ],
    SMCTL2_00200: ["feeling_of_sickness_nausea"],
    SMCTL2_00201: ["nausea_and_vomiting", "vomiting_blood"],
    SMCTL2_00209: [
      "difficulty_producing_sounds",
      "highpitched_voice",
      "hoarseness",
      "muteness",
      "voice_tremors",
      "neck_pain",
    ],
    SMCTL2_00202: [
      "white_discharge_from_the_tonsils",
      "pharyngeal_follicles",
      "tonsillar_follicles",
    ],
    SMCTL2_00204: ["dryness_of_throat"],
    SMCTL2_00206: ["neck_muscle_spasms", "sore_throat_or_throat_pain"],
    SMCTL2_00208: ["swelling_of_the_neck"],
    SMCTL2_00203: ["hunched_shoulders"],
    SMCTL2_00205: [
      "inflammation_of_the_tonsils",
      "inflammation_of_the_vocal_cords",
    ],
    SMCTL2_00207: ["stiff_neck"],
    SMCTL2_00211: ["bleeding_from_the_nose", "blood_discharge_from_the_nose"],
    SMCTL2_00210: [
      "sinus_discharge",
      "nasal_discharge",
      "copious_nasal_discharge",
      "discharge_from_nose_containing_pus",
      "pharyngeal_congestion",
    ],
    SMCTL2_00212: [
      "redness_and_swelling_of_the_nasal_mucosa",
      "inability_to_converge_eyeballs",
      "nasal_sores",
      "diminished_sense_of_smell",
    ],
    SMCTL2_00213: ["bleeding", "bleeding_from_the_varicose_veins"],
    SMCTL2_00215: [
      "abdominal_colic_or_cramps",
      "rigidity_of_abdominal_muscles",
      "abdominal_or_stomach_pain",
      "abdominal_stomach_pain_or_discomfort",
      "tenderness_in_the_abdomen",
      "abdominal_pain_increases_after_meals",
      "abdominal_pain_relieved_after_food",
      "generalized_abdominal_stomach_pain",
      "generalized_abdominal_stomach_pain_after_food",
      "left_lower_quadrant_llq_abdominal_stomach_pain",
      "left_lower_quadrant_llq_abdominal_tenderness",
      "left_upper_quadrant_luq_abdominal_stomach_pain",
      "left_upper_quadrant_luq_abdominal_tenderness",
      "lower_half_abdominal_stomach_pain",
      "tenderness_in_the_lower_abdomen",
      "right_lower_quadrant_rlq_abdominal_stomach_pain",
      "right_lower_quadrant_rlq_abdominal_tenderness",
      "right_upper_quadrant_ruq_abdominal_stomach_pain",
      "right_upper_quadrant_ruq_abdominal_tenderness",
      "tenderness_in_or_around_umbilical_region",
      "upper_half_abdominal_stomach_pain",
      "tenderness_in_the_upper_abdomen",
    ],
    SMCTL2_00216: ["ankle_pain_or_tenderness"],
    SMCTL2_00227: [
      "bone_tenderness",
      "loss_of_grip_strength",
      "cramping_leg_pain",
      "limb_pain",
      "limb_pain_while_at_rest",
      "hand_limb_pain",
      "limb_pain_heel",
      "unilateral_limb_pain",
      "radiating_limb_pain",
      "muscle_pain",
      "calf_pain",
      "pain_in_the_extremities",
      "pain_radiating_to_the_buttocks",
      "pain_radiating_to_the_left_shoulder",
      "thigh_pain",
      "tenderness_of_the_calf",
      "wrist_pain_or_tenderness",
    ],
    SMCTL2_00221: ["back_pain_or_tenderness", "tenderness_in_the_flank_region"],
    SMCTL2_00222: [
      "bone_pain",
      "hip_deformity",
      "ovarian_tumor",
      "general_pain",
      "pain_induced_by_alcohol",
      "generalised_pain",
      "tenderness",
    ],
    SMCTL2_00219: [
      "chest_pain",
      "acute_chest_pain",
      "chest_pain_at_rest",
      "chest_pain_that_doesnt_improve_with_rest",
      "chest_pain_when_coughing",
      "chest_pain_during_activity",
      "chest_pain_while_breathing",
      "chest_pain_radiating_to_the_back",
      "chest_pain_radiating_to_the_stomach",
      "chest_pain_radiating_to_the_jaw",
      "chest_pain_radiating_to_the_shoulder",
      "chest_pain_radiating_to_the_upper_limb",
    ],
    SMCTL2_00220: ["tenderness_in_the_sinuses", "facial_pain"],
    SMCTL2_00223: [
      "feeling_of_pressure_in_the_sinuses",
      "headache",
      "arm_pain",
      "muscle_tenderness",
      "muscle_tenderness",
    ],
    SMCTL2_00228: [
      "pain_in_the_flank",
      "lower_back_pain",
      "pain_or_tenderness_at_the_costovertebral_angle",
      "trunk_pain",
    ],
    SMCTL2_00233: [
      "groin_pain",
      "radiating_groin_pain",
      "tenderness_in_the_groin_area",
      "pain_in_the_inguinal_area",
      "pelvic_pain",
      "pelvic_pain_or_tenderness",
    ],
    SMCTL2_00224: ["hip_pain_or_tenderness", "hip_pain"],
    SMCTL2_00225: ["insect_bites_or_stings"],
    SMCTL2_00232: ["pain_or_discomfort_in_the_jaw_while_chewing"],
    SMCTL2_00226: ["jaw_pain", "pain_while_chewing"],
    SMCTL2_00230: ["eye_irritation"],
    SMCTL2_00229: ["somatic_pain"],
    SMCTL2_00217: ["coccyx_pain"],
    SMCTL2_00231: ["tenderness_of_tendons"],
    SMCTL2_00236: ["oral_thrush"],
    SMCTL2_00235: ["palpitations"],
    SMCTL2_00239: [
      "weakness_in_extending_the_arm",
      "weakness_in_the_arms",
      "limb_weakness",
      "limb_weakness",
      "difficulty_climbing_stairs",
      "weakness_in_facial_muscles",
      "weakness_in_finger_muscles",
      "general_muscle_weakness",
      "bilateral_generalized_muscle_weakness",
      "lower_limb_generalized_muscle_weakness",
      "unilateral_generalized_muscle_weakness",
      "upper_limb_generalized_muscle_weakness",
      "weakness_of_thenar_muscles_hand",
      "weakness_in_hand_muscles",
      "hard_stools",
      "weakness_in_hip_muscles",
      "corneal_inflammation",
      "weakness_of_lower_limbs",
      "bilateral_lower_limb_weakness",
      "unilateral_lower_limb_weakness",
      "muscle_weakness_in_legs",
      "pale_stools",
      "shoulder_weakness",
      "bilateral_shoulder_weakness",
      "unilateral_shoulder_weakness",
      "weakness_in_thigh_muscles",
      "unilateral_weakness_of_limbs",
      "weakness_of_upper_limbs",
      "bilateral_upper_limb_weakness",
      "unilateral_upper_limb_weakness",
    ],
    SMCTL2_00237: [
      "hemiparesis",
      "paralysis",
      "paralysis_of_all_limbs",
      "facial_paralysis",
      "paralysis_of_the_lower_limbs",
      "paralysis_on_one_side",
      "arm_paralysis",
      "paralysis_of_the_bicep",
      "paralysis_of_finger",
      "paralysis_of_the_shoulder",
      "paralysis_of_the_triceps",
      "paralysis_of_the_wrist",
      "paraparesis",
      "poor_feeding",
      "paralysis_of_upper_limbs",
    ],
    SMCTL2_00257: [
      "abdominal_enlargement_or_distension_or_swelling",
      "mass_in_the_abdomen",
      "abdominal_mass",
      "enlarged_liver_and_spleen_hepatosplenomegaly",
      "enlarged_liver",
    ],
    SMCTL2_00240: ["puddles_sign"],
    SMCTL2_00252: ["abnormal_red_reflex"],
    SMCTL2_00243: [
      "abnormal_breathing_sound",
      "grunting",
      "pleural_rub",
      "rhonchi",
      "squeaks",
      "stridor",
      "abnormal_respiratory_sound",
    ],
    SMCTL2_00254: [
      "dark_circles_below_the_eyes",
      "skin_tag",
      "thickening_of_tendons",
      "thrombosis_blood_clots",
    ],
    SMCTL2_00250: [
      "reduced_intraocular_pressure",
      "elevated_intraocular_pressure",
    ],
    SMCTL2_00246: ["abnormal_fundoscopic_examination"],
    SMCTL2_00249: ["heart_murmur", "tumor_plop"],
    SMCTL2_00255: ["abnormal_otoscopic_test"],
    SMCTL2_00248: ["perineal_mass", "rectal_mass"],
    SMCTL2_00267: ["faint_pulse"],
    SMCTL2_00269: ["irregular_pulse"],
    SMCTL2_00271: [
      "abnormal_skin_texture",
      "coarse_skin",
      "dry_skin",
      "moist_skin",
      "oily_skin",
      "abnormal_skin_pigmentation",
      "darkening_of_the_skin",
      "tender_skin",
      "thick_and_rough_skin_on_the_palms",
      "thick_and_rough_skin_on_the_soles",
      "thick_skin",
      "thickening_&_tightening_of_skin_of_fingers_or_toes",
      "facial_wrinkles",
    ],
    SMCTL2_00286: ["pusfilled_lump", "abscess_on_the_skin"],
    SMCTL2_00287: [
      "anal_sores",
      "ulcer_on_the_penis",
      "ulcer_in_the_rectum",
      "scar",
      "ulcer_on_the_fingers",
      "foot_ulcer",
      "ulcer_on_the_hands",
      "ankle_ulcer",
      "ulcer_on_the_upper_thigh",
      "ulcer_on_the_legs",
    ],
    SMCTL2_00272: ["pustular_rash"],
    SMCTL2_00275: ["blisters_eye"],
    SMCTL2_00274: [
      "bruising",
      "bruise_on_the_head",
      "bruise_on_the_hip",
      "bruise_calf",
      "bruise_on_the_neck",
      "ecchymosis_in_the_inguinal_area",
    ],
    SMCTL2_00277: [
      "lesions_and_sores_on_the_buccal_mucosa",
      "mouth_sores",
      "mouth_sores",
      "mouth_blisters",
      "scalp_sores",
      "oral_lesions",
      "difficulty_opening_jars",
      "palate_sores",
      "ulcers_or_fissures_at_mouth_angles",
      "whitish_patches_in_the_buccal_mucosa",
    ],
    SMCTL2_00280: [
      "blisters",
      "eczema_lesions",
      "lesions_on_the_face",
      "macular_rash",
      "maculopapular_rash",
      "nodular_rash",
      "nodular_rash_on_the_palm",
      "subcutaneous_nodular_rash",
      "papular_rash",
      "papular_rash_in_the_axilla",
      "papular_rash_on_the_back",
      "papular_rash_on_the_buttocks",
      "papular_rash_on_the_hands_fingers_and_feet",
      "papular_rash_on_the_knees",
      "papular_rash_on_the_scrotum",
      "papular_rash_between_the_fingers",
      "papular_rash_around_the_waist",
      "papular_rash_on_the_wrist",
      "papular_rash_on_the_face_neck_and_palms_soles",
      "penile_sores",
      "plaque_rash",
      "general_rash",
      "bullous_rash",
      "rash_on_the_buttocks",
      "chest_rash",
      "diffused_rash",
      "rash_in_the_diaper_area",
      "rash_on_the_palms_and_soles",
      "skin_rash_red",
      "facial_rash",
      "ulcer",
      "vesicular_rash_in_the_axilla",
      "vesicular_rash_on_the_back",
      "vesicular_rash_on_the_buttocks",
      "vesicular_rash_on_the_gluteal_region",
      "vesicular_rash_on_the_knees",
      "vesicular_rash_on_the_mucous_membranes",
      "vesicular_rash_on_the_nose",
      "vesicular_rash_on_the_ear",
      "vesicular_rash_on_the_palms_and_soles",
      "vesicular_rash_in_the_perianal_area",
      "vesicular_rash_on_the_scrotum",
      "vesicular_rash_between_the_fingers",
      "vesicular_rash_on_the_upper_thigh",
      "vesicular_rash_around_the_waist",
      "vesicular_rash_on_the_wrist",
    ],
    SMCTL2_00273: [
      "cyanosis_bluish_skin_color",
      "bluishgrey_flank_discoloration",
      "blue_discoloration_around_the_umbilicus",
    ],
    SMCTL2_00281: [
      "redness_of_the_face",
      "flushing_of_skin",
      "skin_reddening",
      "reddening_of_the_feet",
      "reddening_of_the_fingers",
      "reddening_of_the_heels",
      "localized_skin_reddening",
      "reddening_of_the_nappy_area",
      "reddening_of_the_palms",
      "reddening_of_the_soles",
      "reddening_of_the_toes",
    ],
    SMCTL2_00290: [
      "jaundice_yellowing_of_the_skin",
      "jaundice_present_at_birth",
    ],
    SMCTL2_00276: ["mole", "acne_on_the_face"],
    SMCTL2_00279: ["pallor_paleness", "pallor_fingers", "pallor_limbs"],
    SMCTL2_00282: [
      "perianal_or_anal_inflammation_redness",
      "redness_of_the_breast",
      "scrotum_redness",
    ],
    SMCTL2_00284: ["scalp_rash", "allergy", "scalp_flaking"],
    SMCTL2_00283: ["butterfly_rash", "scaly_skin_hands"],
    SMCTL2_00285: ["skin_scaling"],
    SMCTL2_00289: ["tendinous_xanthomas"],
    SMCTL2_00293: [
      "delayed_orgasm",
      "impotence",
      "lack_delayed_absence_of_ejaculation",
      "pain_during_ejaculation",
      "pain_during_intercourse",
      "premature_ejaculation",
      "prolonged_penile_erection",
    ],
    SMCTL2_00291: ["hemiplegia", "decreased_libido", "decreased_sexual_desire"],
    SMCTL2_00292: ["samesex_attraction"],
    SMCTL2_00295: [
      "decreased_sleep_need",
      "loss_of_appetite",
      "nightmare",
      "short_sleep_duration",
      "sleep_disturbances",
      "talking_in_sleep",
      "sleepwalking",
      "insomnia",
      "waking_unrefreshed",
      "confusional_arousals",
    ],
    SMCTL2_00294: ["drowsiness", "excessive_sleep", "frequent_yawning"],
    SMCTL2_00296: ["snoring"],
    SMCTL2_00297: ["sneezing"],
    SMCTL2_00298: [
      "change_in_speech_pitch",
      "shift_quickly_between_topics_and_get_distracted_by_internal_and_external_stimuli",
      "sparse_speech_content",
      "pressured_speech",
      "slow_speech_or_speech_delay",
      "soft_or_muffled_speech",
      "speech_disorder",
      "speech_impairment_with_stuttering",
      "temporary_speech_impairment",
      "substitution_of_words_or_phrases_or_sounds_in_speech",
    ],
    SMCTL2_00299: ["excessive_sweating"],
    SMCTL2_00300: [
      "abnormal_bony_growth",
      "ankle_swelling",
      "bone_swelling",
      "calf_swelling",
      "blurred_vision",
      "swelling_of_the_feet",
      "flank_mass",
      "hemorrhoids_distended_rectal_veins",
      "swelling_of_the_hands",
      "facial_lump",
      "induration_hardening",
      "swelling_of_the_knee",
      "left_upper_quadrant_luq_mass_in_the_abdomen",
      "swelling_of_the_legs",
      "lower_mass_in_the_abdomen",
      "swelling_in_the_lower_abdomen",
      "swelling_of_the_lower_limbs",
      "lump_under_the_wrist",
      "swelling_of_muscles",
      "neck_swelling",
      "pelvic_mass",
      "swelling_around_the_anus",
      "masses_or_tumor_in_the_pharynx",
      "swelling_in_the_pharynx",
      "presacral_swelling_or_edema",
      "right_upper_quadrant_ruq_mass_in_the_abdomen",
      "swelling_of_the_salivary_gland",
      "feeling_of_a_mass_in_the_abdomen",
      "skin_lump_mass_or_growth",
      "swelling_of_the_skull",
      "enlargement_of_the_submandibular_salivary_gland",
      "swelling_inside_the_ear",
      "swelling_of_the_forearm",
      "subcutaneous_swelling",
      "swelling_of_the_nose",
      "brittle_hair",
      "swelling_of_the_hip",
      "facial_swelling",
      "umbilical_nodules",
      "upper_mass_in_the_abdomen",
      "swelling_in_the_upper_abdomen",
      "swelling_of_the_upper_limbs",
      "wrist_swelling",
      "wrist_swelling_with_pain",
    ],
    SMCTL2_00302: [
      "swollen_or_enlarged_anterior_cervical_lymph_nodes",
      "swollen_or_enlarged_axillary_lymph_nodes",
      "swollen_or_enlarged_femoral_lymph_nodes",
      "swollen_or_enlarged_inguinal_lymph_nodes",
      "nasal_congestion",
      "swollen_or_enlarged_preauricular_lymph_nodes",
      "swollen_or_enlarged_tonsils",
      "mass_in_the_groin_or_suprapubic_area",
      "swelling_in_the_groin_or_suprapubic_area",
      "swelling_of_the_parotid_gland",
      "swelling_of_the_lymph_nodes",
    ],
    SMCTL2_00301: ["swelling_edema", "swelling_after_vaccination"],
    SMCTL2_00314: ["bone_weakness"],
    SMCTL2_00313: ["general_malaise"],
    SMCTL2_00312: ["weakness_or_lethargy_or_fatigue"],
    SMCTL2_00316: ["excessive_weight", "weight_increase"],
    SMCTL2_00317: ["weight_decrease"],
  };

  const iconMappingThirdLevelSymptoms =
    patientGender === "male"
      ? maleThirdLevelSymtoms
      : patientGender === "female"
      ? femaleThirdLevelSymtoms
      : {};

  console.log("iconMappingThirdLevelSymptoms", iconMappingThirdLevelSymptoms);

  // console.log("femaleThirdLevelSymtoms[selectedSecondLevelKey]" , femaleThirdLevelSymtoms[selectedSecondLevelKey])
  // console.log("selectedSecondLevelKey" , selectedSecondLevelKey);
  // console.log("checked" , checked);

    // console.log('reduxSelectedSymptoms:', reduxSelectedSymptoms);
    // console.log('reduxActiveIds:', reduxActiveIds);
    // console.log('reduxActiveLabels:', reduxActiveLabels);
    // console.log('reduxSelectedSecondLevelKey:', reduxSelectedSecondLevelKey);
    // console.log('reduxSelectedPart:', reduxSelectedPart);
    // console.log('reduxSelectedPartForSecond:', reduxSelectedPartForSecond);

    // console.log("********selectedPart", selectedPart);
    // console.log("********activeIds" , activeIds);
    // console.log("********iconMapping" , iconMapping);
    // console.log("********iconMapping", iconMapping);

    console.log("*********activeIdsForSecondStep", activeIdsForSecondStep);
    console.log("*********iconMappingSecondLevelSymptoms", iconMappingSecondLevelSymptoms);
    console.log("*********iconsArrayForSecondLevelSymptoms", iconsArrayForSecondLevelSymptoms)
  
  return (
    <>
      <div className="visualSlectionContainer">
        <div className="personBody">
          {patientGender === "female" && (
            <FemaleBody
              onBodyPartClick={handleBodyPartClick}
              selectedPart={selectedPart}
            />
          )}

          {patientGender === "male" && (
            <MaleBody
              onBodyPartClick={handleBodyPartClick}
              selectedPart={selectedPart}
            />
          )}
        </div>
        <div className="levelSymptGrp">
          <div className="lvlSyHeading">{t("first_level_symptoms")}</div>
          <div className="labelSymptoms">
            {!selectedPart && (
              <div className="noDatagrp">
                {/* <div className="btext">{t("oops")}</div> */}
                <div className="subtext">
                  {t(
                    "please_click_on_the_body_part_where_you_are_experiencing_problems"
                  )}
                </div>
              </div>
            )}

            {(() => {
              const renderedIcons = new Set();
              return activeIds.map((id) => {
                const iconIds = iconMapping[id] || [];
                return iconIds.map((iconId, index) => {
                  if (renderedIcons.has(iconId)) return null;

                  const iconData = iconsArray.find(
                    (icon) => icon.id === iconId
                  );
                  console.log("iconData", iconData);
                  if (iconData) {
                    renderedIcons.add(iconId);
                    return (
                      <Tooltip
                        key={`${id}-${index}`}
                        title={iconData.title}
                        followCursor
                        placement="top"
                      >
                        <div
                          className={`labelSympInd ${
                            activeLabels.includes(`${id}-${index}`)
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            handleLabelClick(`${id}-${index}`, iconData.id)
                          }
                        >
                          <img src={iconData.src} alt={iconData.title} />
                        </div>
                      </Tooltip>
                    );
                  }
                  return null;
                });
              });
            })()}

            {/* {activeIds.map((id) => {
              const iconIds = iconMapping[id] || [];
              return iconIds.map((iconId, index) => {
                const iconData = iconsArray.find((icon) => icon.id === iconId);
                return iconData ? (
                  <Tooltip
                    key={`${id}-${index}`}
                    title={iconData.title}
                    followCursor
                    placement="top"
                  >
                    <div
                      className={`labelSympInd ${
                        activeLabels.includes(`${id}-${index}`) ? "active" : ""
                      }`}
                      onClick={() => handleLabelClick(`${id}-${index}`)}
                    >
                      <img src={iconData.src} alt={iconData.title} />
                    </div>
                  </Tooltip>
                ) : null;
              });
            })} */}
          </div>
        </div>
        <div className="levelSymptGrp">
          <div className="lvlSyHeading">{t("second_level_symptoms")}</div>
          <div className="labelSymptoms">
            {!selectedPartForSecond && (
              <div className="noDatagrp">
                {/* <div className="btext">{t("oops")}</div> */}
                <div className="subtext">
                  {t("please_click_on_the_first_level_symptoms")}
                </div>
              </div>
            )}

            {/* {activeIdsForSecondStep.map((id) => {
              const iconIds = iconMappingSecondLevelSymptoms[id] || [];
              return iconIds.map((iconId, index) => {
                const iconData = iconsArrayForSecondLevelSymptoms.find(
                  (icon) => icon.id === iconId
                );
                return iconData ? (
                  <Tooltip
                    key={`${id}-${index}`}
                    title={iconData.title}
                    followCursor
                    placement="top"
                  >
                    <div
                      className={`labelSympInd ${
                        activeLabels.includes(`${id}-${index}`) ? "active" : ""
                      }`}
                      onClick={() => handleLabelSecondClick(`${id}-${index}`, iconData.id)}
                    >
                      <img src={iconData.src} alt={iconData.title} />
                    </div>
                  </Tooltip>
                ) : null;
              });
            })} */}
            {(() => {
              const renderedIcons = new Set();
              return activeIdsForSecondStep.map((id) => {
                const iconIds = iconMappingSecondLevelSymptoms[id] || [];
                return iconIds.map((iconId, index) => {
                  if (renderedIcons.has(iconId)) return null; // Skip if already rendered

                  const iconData = iconsArrayForSecondLevelSymptoms.find(
                    (icon) => icon.id === iconId
                  );

                  if (iconData) {
                    renderedIcons.add(iconId); // Mark as rendered
                    return (
                      <Tooltip
                        key={`${id}-${index}`}
                        title={iconData.title}
                        followCursor
                        placement="top"
                      >
                        <div
                          className={`labelSympInd ${
                            activeLabelsForSecondLevel.includes(`${id}-${index}`)
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            handleLabelSecondClick(
                              `${id}-${index}`,
                              iconData.id
                            )
                          }
                        >
                          <img src={iconData.src} alt={iconData.title} />
                        </div>
                      </Tooltip>
                    );
                  }
                  return null;
                });
              });
            })()}
          </div>
        </div>
        {/* <div className="levelSymptGrp">
          <div className="lvlSyHeading">Select final symptoms</div>
          <div className="finalSymptoms">
            <div className="finalSymptList">
              <List>
                {symptomsList.map((symptom) => {
                  const labelId = `checkbox-list-label-${symptom.id}`;

                  return (
                    <ListItem key={symptom.id} disablePadding>
                      <ListItemButton
                        role={undefined}
                        onClick={handleToggle(symptom.id)}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked.includes(symptom.id)}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={symptom.name} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </div>
        </div> */}
        {/* <div className="levelSymptGrp">
        <div className="lvlSyHeading">Select final symptoms</div>
        <div className="finalSymptoms">
          <div className="finalSymptList">
            <List>
              {(femaleThirdLevelSymtoms[selectedSecondLevelKey] || []).map((symptom, index) => {
                const labelId = `checkbox-list-label-${index}`;
                return (
                  <ListItem key={index} disablePadding>
                    <ListItemButton role={undefined} onClick={handleToggle(symptom)} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.includes(symptom)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={symptom} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </div>
        </div>
        </div> */}
        {/* <div className="levelSymptGrp">
      <div className="lvlSyHeading">Select final symptoms</div>
      <div className="finalSymptoms">
        <div className="finalSymptList">
          <List>
            {selectedSecondLevelKey
              .flatMap((key) => femaleThirdLevelSymtoms[key] || [])
              .map((symptom, index) => {
                const labelId = `checkbox-list-label-${index}`;
                return (
                  <ListItem key={index} disablePadding>
                    <ListItemButton role={undefined} onClick={handleToggle(symptom)} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.includes(symptom)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={symptom} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </List>
        </div>
      </div>
    </div> */}
        <div className="levelSymptGrp">
          <div className="lvlSyHeading">{t("select_final_symptoms")}</div>
          <div className="finalSymptoms">
          {!selectedSecondLevelKey && (
              <div className="noDatagrp">
                {/* <div className="btext">{t("oops")}</div> */}
                <div className="subtext">
                  {t("please_click_on_the_second_level_symptoms")}
                </div>
              </div>
            )}
            <div className="finalSymptList">
              <List>
                {
                  // Ensure selectedSecondLevelKey is valid
                  (selectedSecondLevelKey || []).flatMap((key) => {
                    // Ensure femaleThirdLevelSymtoms[key] exists
                    const symptoms = iconMappingThirdLevelSymptoms[key] || [];
                    return symptoms.map((symptom, index) => {
                      const labelId = `checkbox-list-label-${key}-${index}`;
                      return (
                        <ListItem key={labelId} disablePadding>
                          <ListItemButton
                            role={undefined}
                            onClick={() => handleToggle(symptom)} // Ensure handleToggle handles state updates
                            dense
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={checked.includes(symptom)}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={t(symptom)} />
                          </ListItemButton>
                        </ListItem>
                      );
                    });
                  })
                }
              </List>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
